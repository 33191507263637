

label._3OG2hI0arRNE {
	font-size: 24px;
	line-height: 24px;
	font-weight: 700;

	color: var(--gray80);
}

.cHxIw_yvYFMh {
	max-width: 560px;
	margin: 0 auto;
}

div._1TWeLkdAvnpA {
	margin: 10px 0;
}

/* error text */
._1TWeLkdAvnpA > p {
	bottom: 12px;
}

label._1sAQZn4HY3nE {
	max-width: 550px;
	margin: 21px auto;
	display: flex;
}

span._3rLSm9gZZRMu._2sIxI23UJTv3 {
	color: var(--base);
}
