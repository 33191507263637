

._34-auNYSuWqk {
	display: flex;
}

.B2b1KmRuelaA {
	width: 280px;

	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}

._5WKg2vt82tvl {
	flex: 1;
	padding: 17px 40px 0;

	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;

	background: #ffffff;
}
@media screen and (max-width: 1300px) {
	._5WKg2vt82tvl {
		padding: 20px 40px;
	}
}

._3fce4L8xUagc {
	display: flex;
	justify-content: space-between;
	margin: 0 0 16px;
	padding: 0;

	color: var(--gray80);
	font-weight: 600;
	font-size: 23px;
	line-height: 23px;
}
@media screen and (max-width: 1300px) {
	._3fce4L8xUagc {
		margin-bottom: 10px;
	}
}

a._2da8DDAyX7l0 {
	display: inline-flex;
	align-items: center;

	color: var(--base);
	font-size: 15px;
	font-weight: 400;
	text-decoration: none;
}

._3aUr3eD1XMlL {
	display: grid;
	grid-template: repeat(auto-fill, 30px) / repeat(auto-fill, minmax(230px, 1fr));
	grid-column-gap: 50px;
	margin: 0 0 16px;
	padding: 0;

	color: var(--gray70);
	font-size: 14px;

	list-style-type: none;
}

._3mn66PRRAoAx {
	margin-left: auto;
}

._3mn66PRRAoAx ._2da8DDAyX7l0:nth-of-type(2) {
	margin-left: 16px;
	padding-left: 16px;

	border-left: 1px solid var(--gray20);
}

div._2OTxXM7gXgg4 {
	min-height: auto;
	max-width: 420px;
	margin: 0 auto;
	width: 100%;
}

div._2OTxXM7gXgg4 button {
	display: none;
}

._1EqSIJDNBRa6 {
	display: flex;
	align-items: center;
	padding: 8px 0;

	border-bottom: 1px solid #e4e4e4;
}

._2Xbm_vh0cIrg {
	width: 24px;
	height: 24px;

	color: var(--gray30);
}

._142tSxrEovEK {
	position: relative;
	top: 1px;

	margin-right: 17px;
}

._34TBRgiwJBAz {
	position: relative;
	top: 2px;

	margin-left: auto;
}

._142tSxrEovEK,
._34TBRgiwJBAz {
	color: var(--gray50);
}

._34TBRgiwJBAz .icon_included__check {
	color: #5fca43;
}

div._1tvwDFp3KPUI {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 3;

	background-color: var(--gray05);
}
