.HZ4XJpeflfwr {
	display: flex;
	flex-wrap: wrap;
	margin: 30px -8px -20px;
}

.LDmatpOd1fjL {
	width: calc(50% - 16px);
	margin: 0 8px 20px;
	display: flex;
	align-items: center;
}

.LDmatpOd1fjL > *:not(:first-child) {
	margin-left: 10px;
	margin-top: 0;
}

@media screen and (max-width: 600px) {
	.LDmatpOd1fjL {
		width: calc(100% - 16px);
	}
}

.CH_qew5p-dv5 {
	width: 100%;
}

.CH_qew5p-dv5 input.src-Input-Input-real_input,
.CH_qew5p-dv5 div.src-Input-Input-real_input {
	height: 44px;
}

._2YXoA5agoU2h {
	margin-top: 10px;
	flex-grow: 0;
	width: auto;
}

.b71yeSU9o18_ {
	text-align: right;
}

@media screen and (max-width: 600px) {
	.b71yeSU9o18_ {
		text-align: center;
	}
}

._3CvkhzoOhhBp {
	margin-top: 20px;
}

._3CvkhzoOhhBp span {
	margin-left: 5px;

	color: var(--error-20);
	vertical-align: super;
}

._3CvkhzoOhhBp svg path {
	fill: var(--error-20);
}
