

._3o9dc1mbopFd {
	display: flex;
	align-items: center;
	margin: 0 0 -20px;
	padding: 0 calc((100vw - 1100px) / 2);
	height: 70px;

	font-size: 24px;
	font-weight: 600;
}

._1Sz-EXxPHWP6 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px;
	width: 100%;

	border-radius: 4px;

	font-size: 19px;
	font-weight: 600;
	line-height: 30px;

	background-color: #ffffff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

._2hjxDJzjlzdg {
	margin: 20px auto 0;
	max-width: 1100px;
}
