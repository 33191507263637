

._2iHVWkq4BANz {
	width: 100%;
	height: 30px;
	overflow: hidden;
}

._2rzgXTTCkxwM {
	display: flex;
	flex-direction: column;

	transition: transform 0.3s;
}

._1VpGYty__EJ2 {
	height: 30px;
	min-height: 30px;
}

.BrfObB7SgnnF {
	opacity: 0;

	transition: opacity 0.3s;
}
