div._2lohHY02ujlC {
	padding: 40px;

	max-width: 560px;
	margin: 0 auto;

	font-size: 16px;
	line-height: 24px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

@media screen and (max-width: 600px) {
	div._2lohHY02ujlC {
		padding: 24px 16px 20px;

		box-shadow: none;
	}
}
