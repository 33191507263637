._28ZuOagUGsJz {
	position: relative;

	display: flex;
	height: 180px;
	width: 100%;
}

._2ZvpuoHE9Spa {
	position: absolute;
	z-index: 1;
	right: 20vw;

	display: none;
	align-items: stretch;
	height: 100%;
	width: 370px;
}

._3-BD6X7xOrka {
	width: 140px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTU0IiBoZWlnaHQ9IjE4MCIgdmlld0JveD0iMCAwIDE1NCAxODAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjE0MS40ODgiIHk9Ii0yMS4zNDQyIiB3aWR0aD0iMTMuNjIzMSIgaGVpZ2h0PSIyODIuOTc3IiB0cmFuc2Zvcm09InJvdGF0ZSgzMCAxNDEuNDg4IC0yMS4zNDQyKSIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==) center no-repeat;
	transform: translateX(-110px);
}

.mfXqOBrNTR2A {
	width: 195px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAzIiBoZWlnaHQ9IjE4MCIgdmlld0JveD0iMCAwIDIwMyAxODAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjE0MS40ODgiIHk9Ii00OS41MzI3IiB3aWR0aD0iNzAiIGhlaWdodD0iMjgyLjk3NyIgdHJhbnNmb3JtPSJyb3RhdGUoMzAgMTQxLjQ4OCAtNDkuNTMyNykiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
}

._1wsGx9Dp1cpo {
	position: absolute;
	z-index: 2;

	width: 370px;
	height: 100%;

	background-position: center bottom;
	background-size: contain;
	background-repeat: no-repeat;
}

._3PRZQ9wlgUxb {
	position: relative;

	max-width: 1100px;
	width: 100%;
	margin: 0 auto;
	height: 100%;
}

._1YA1Pcgumjzi {
	height: 100%;
	max-width: 60%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	font-size: 26px;
	line-height: 36px;
	color: #ffffff;
	font-weight: 500;
}

._2PD7io7lDE9o {
	background: var(--seatMap-banner-background);
}

._2gULsZSimac5 {
	background: var(--baggage-banner-background);
}

._2u2QaiXQRI21 {
	background: var(--meals-banner-background);
}

._2WQQYq6RO3xH {
	background: var(--additional-services-banner-background);
}

._2WQQYq6RO3xH ._3PRZQ9wlgUxb {
	background: url('https://cdn.websky.aero/content/frontend/images/banner-extras-services.svg') center bottom
		no-repeat;
	background-size: cover;
}

._2PD7io7lDE9o ._2ZvpuoHE9Spa {
	opacity: 0.3;
}

._2gULsZSimac5 ._2ZvpuoHE9Spa {
	opacity: 0.23;
}

._2u2QaiXQRI21 ._2ZvpuoHE9Spa {
	opacity: 0.27;
}

._2PD7io7lDE9o ._1wsGx9Dp1cpo {
	right: 13vw;

	background-image: url('https://cdn.websky.aero/content/frontend/images/banner-service-seats.svg');
}

._2gULsZSimac5 ._1wsGx9Dp1cpo {
	right: 14vw;

	background-image: url('https://cdn.websky.aero/content/frontend/images/banner-service-baggage.svg');
}

._2u2QaiXQRI21 ._1wsGx9Dp1cpo {
	right: 16vw;

	background-image: url('https://cdn.websky.aero/content/frontend/images/banner-service-meal.svg');
}

._1KQoukspK5kT {
	display: flex;
	max-width: 300px;
	overflow: visible;

	white-space: nowrap;
}

._3aQt7a0q0C8M {
	display: inline-flex;
	align-items: center;
	margin-top: 16px;

	color: #ffffff;
	font-size: 14px;
	line-height: 20px;
	text-decoration: underline;

	cursor: pointer;
}

._3aQt7a0q0C8M a {
	display: flex;
	align-items: center;

	color: inherit;
	text-decoration: none;
}

._1nUuXsQWERSR {
	display: flex;
	align-items: center;
	margin-right: 3px;
}

div.Wtlim5EVQF8Q {
	font-size: 12px;
}

@media (max-width: 1024px) {
	._28ZuOagUGsJz {
		height: 106px;

		overflow: hidden;
	}

	._1wsGx9Dp1cpo {
		margin-top: 11px;
		height: 95px;

		background-position: 75% bottom;
	}

	._2PD7io7lDE9o {
		height: 114px;
		padding-bottom: 8px;
	}

	._2PD7io7lDE9o ._1wsGx9Dp1cpo,
	._2gULsZSimac5 ._1wsGx9Dp1cpo,
	._2u2QaiXQRI21 ._1wsGx9Dp1cpo {
		right: 0;
	}

	._2gULsZSimac5 ._1wsGx9Dp1cpo {
		margin-top: 0;

		background-image: url('https://cdn.websky.aero/content/frontend/images/nordwind-banner-service-baggage.svg');
		background-position: 50% bottom;
	}

	._2u2QaiXQRI21 ._1wsGx9Dp1cpo {
		height: 100px;
		margin-top: 6px;

		background-position: 35% bottom;
	}

	._2ZvpuoHE9Spa {
		right: 0;
	}

	._1YA1Pcgumjzi {
		margin-left: 20px;
	}
}

@media screen and (max-width: 600px) {
	._28ZuOagUGsJz {
		top: -8px;

		margin-bottom: -8px;

		border-radius: 8px 8px 0 0;
	}

	._1wsGx9Dp1cpo {
		background-position: 135% bottom;
	}

	._2gULsZSimac5 ._1wsGx9Dp1cpo {
		background-position: 90% bottom;
	}

	._2u2QaiXQRI21 ._1wsGx9Dp1cpo {
		background-position: 95% bottom;
	}

	._2ZvpuoHE9Spa {
		right: -35vw;
	}

	._2WQQYq6RO3xH ._1YA1Pcgumjzi {
		max-width: 80%;

		line-height: 31px;
	}

	._2WQQYq6RO3xH ._3PRZQ9wlgUxb {
		background-position: 70% bottom;
		background-size: 160% 70%;
	}
}
