._1EqL2huTqgQo {
	padding: 44px 48px;
}

._1okKWQq5vQZE {
	margin-bottom: 28px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 20px;
	line-height: 1.6;
}

._1bxCbUqx8wvn {
	margin-bottom: 40px;

	color: var(--sureModal-text-color);
	font-size: 17px;
	line-height: 1.7;
}

._1xS9ZbEExQA6 {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

@media {
	._1EqL2huTqgQo {
		padding: 24px 28px;
	}
}
