.Hw4IMvK-DNjh {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 40px 48px;
}

.rAkeIHKwa8Z4 {
	margin-top: 64px;
	margin-bottom: 0;

	color: var(--common-gray);
	font-size: 18px;
}

._2cSquFxreOj3 {
	margin: 0;

	font-size: 28px;
	font-weight: 500;
}

@media screen and (max-width: 600px) {
	.Hw4IMvK-DNjh {
		padding: 0;
	}
}
