

._38HhxSmB4wXR {
	margin: 0;
	padding: 0;

	line-height: inherit;
}

._1yS1XftQaj9m {
	max-width: 1100px;
	margin: 20px auto;
}
