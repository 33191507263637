._223QRf28h2rT {
	display: flex;
	padding: 0 44px 44px;
}

button._1irKZZ_W54Mp {
	width: 100%;
	justify-content: flex-start;

	margin-left: 44px;
	padding: 12px 0;

	border-bottom: 1px solid #d9d9d9;

	color: var(--common-blue);
	font-size: 15px;
	line-height: 24px;
}

._1irKZZ_W54Mp:first-of-type {
	border-top: 1px solid #d9d9d9;
}

._3mjXSkycljpj {
	position: absolute;
	left: -20px;

	display: flex;
	align-items: center;

	transform: translateX(-100%);
}

@media screen and (max-width: 600px) {
	._223QRf28h2rT {
		padding: 0;
	}
}
