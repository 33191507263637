._3RYvi9py1BKm {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
}

._840TZpAI7Ws0,
._3ZTGukU3s_4N {
	color: var(--base);
	font-size: 15px;

	cursor: pointer;
}

._840TZpAI7Ws0:before {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin-right: 5px;

	vertical-align: bottom;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2NjUgOS45OTk4NEMxLjY2NjUgNS4zOTk4NCA1LjM5OTg0IDEuNjY2NSA5Ljk5OTg0IDEuNjY2NUMxNC41OTk4IDEuNjY2NSAxOC4zMzMyIDUuMzk5ODQgMTguMzMzMiA5Ljk5OTg0QzE4LjMzMzIgMTQuNTk5OCAxNC41OTk4IDE4LjMzMzIgOS45OTk4NCAxOC4zMzMyQzUuMzk5ODQgMTguMzMzMiAxLjY2NjUgMTQuNTk5OCAxLjY2NjUgOS45OTk4NFpNMTAuODMzMiA5LjE2NjUxVjE0LjE2NjVIOS4xNjY1VjkuMTY2NTFIMTAuODMzMlpNOS45OTk4MyAxNi42NjY1QzYuMzI0ODMgMTYuNjY2NSAzLjMzMzE2IDEzLjY3NDggMy4zMzMxNiA5Ljk5OTgzQzMuMzMzMTYgNi4zMjQ4MyA2LjMyNDgzIDMuMzMzMTcgOS45OTk4MyAzLjMzMzE3QzEzLjY3NDggMy4zMzMxNyAxNi42NjY1IDYuMzI0ODMgMTYuNjY2NSA5Ljk5OTgzQzE2LjY2NjUgMTMuNjc0OCAxMy42NzQ4IDE2LjY2NjUgOS45OTk4MyAxNi42NjY1Wk0xMC44MzMyIDUuODMzMTdWNy40OTk4M0g5LjE2NjVWNS44MzMxN0gxMC44MzMyWiIgZmlsbD0iI0JBQkFCQSIvPgo8L3N2Zz4K);
	content: '';
}

._2rONobnq_lti {
	position: relative;

	width: 100%;
	height: 305px;
	overflow: hidden;

	background: linear-gradient(217.87deg, rgba(26, 26, 26, 0.56) -6.99%, rgba(26, 26, 26, 0.01) 85.67%);
}

._2eYr6ZPeOQqr:after {
	position: absolute;
	top: 0;
	left: 0;

	display: block;
	width: 100%;
	height: 100%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzcyIiBoZWlnaHQ9IjI3MSIgdmlld0JveD0iMCAwIDM3MiAyNzEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2QpIj4KPHBhdGggZD0iTTIuNzQwMjMgMzQuNTM2M1YyLjk1MTE3SDM0LjMyNTQiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMy45NDgxNCIvPgo8cGF0aCBkPSJNMzM1Ljk2MyAyLjk1MTE3TDM2Ny41NDggMi45NTExN0wzNjcuNTQ4IDM0LjUzNjMiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMy45NDgxNCIvPgo8cGF0aCBkPSJNMzY3LjU0OCAyMzUuMTAyTDM2Ny41NDggMjY2LjY4N0wzMzUuOTYzIDI2Ni42ODciIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMy45NDgxNCIvPgo8cGF0aCBkPSJNMzQuMzI1NCAyNjYuNjg3TDIuNzQwMjMgMjY2LjY4N0wyLjc0MDIzIDIzNS4xMDIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMy45NDgxNCIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2QiIHg9IjAuNzY2NjAyIiB5PSIwLjk3NzExMiIgd2lkdGg9IjM3MC4zMzUiIGhlaWdodD0iMjY5LjI2MyIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIvPgo8ZmVPZmZzZXQgZHg9IjEuNTc5MjYiIGR5PSIxLjU3OTI2Ii8+CjxmZUNvbG9yTWF0cml4IHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjQgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvdyIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvdyIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K) no-repeat center;
	background-size: calc(100% - 16px) calc(100% - 16px);
	content: '';
}

._3-TOXAV0h6f6 {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

._3mE9PHwexGp9 {
	display: none;
}

._2rONobnq_lti img {
	position: absolute;
	top: 50%;
	left: 50%;

	max-width: calc(100% - 16px);
	max-height: calc(100% - 16px);

	transform: translate(-50%, -50%);
}

.Cv4uhUoNBieF {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 50px;

	background-color: var(--error90);
}

.O8Es1MmqmE4w {
	text-align: center;
	color: var(--error);
}

.O8Es1MmqmE4w:before {
	content: '';

	display: block;
	height: 40px;
	margin-bottom: 10px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCA0TDQ2IDQySDJMMjQgNFpNMjYgMjBIMjJWMjhIMjZWMjBaTTI2IDM2VjMySDIyVjM2SDI2WiIgZmlsbD0iI0UwMjIyMiIvPgo8L3N2Zz4K) no-repeat center;
	background-size: contain;
}

._3NwJ44sA2WNK {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

._3ZTGukU3s_4N:before {
	display: inline-block;
	width: 18px;
	height: 18px;
	margin-right: 5px;

	vertical-align: bottom;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguMzMzMTcgMy44MzMzN0gzLjMzMzE3QzIuNDE2NSAzLjgzMzM3IDEuNjc0ODQgNC41ODMzNyAxLjY3NDg0IDUuNTAwMDRMMS42NjY1IDE1LjVDMS42NjY1IDE2LjQxNjcgMi40MTY1IDE3LjE2NjcgMy4zMzMxNyAxNy4xNjY3SDE2LjY2NjVDMTcuNTgzMiAxNy4xNjY3IDE4LjMzMzIgMTYuNDE2NyAxOC4zMzMyIDE1LjVWNy4xNjY3MUMxOC4zMzMyIDYuMjUwMDQgMTcuNTgzMiA1LjUwMDA0IDE2LjY2NjUgNS41MDAwNEg5Ljk5OTg0TDguMzMzMTcgMy44MzMzN1oiIGZpbGw9IiNCM0IzQjMiLz4KPC9zdmc+Cg==) no-repeat center;
	background-size: contain;
	content: '';
}

@media screen and (max-width: 600px) {
	._3RYvi9py1BKm,
	._3NwJ44sA2WNK {
		display: block;
	}

	._840TZpAI7Ws0 {
		margin-top: 20px;
	}

	._3ZTGukU3s_4N {
		margin-bottom: 20px;
	}

	._2rONobnq_lti {
		height: 250px;
	}
}
