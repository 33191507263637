.G64DFKwR_jvP {
	display: flex;
	flex-direction: column;
	align-items: center;
}

._22_HPXEZaN_v {
	color: var(--brand-1-0);
}

._1DlwlRM3M980 {
	margin-top: 12px;
	padding: 0 8px;

	color: var(--text-mid);

	font-weight: 500;
	font-size: 13px;
	line-height: 1.5;
	text-align: center;
}

._1xe84esjjl6l {
	max-width: 150px;
	margin-top: 1px;

	font-size: 12px;
	line-height: 1.5;
	text-align: center;

	color: var(--text-light);
}

.G64DFKwR_jvP._7myTpVOPjWSr ._22_HPXEZaN_v {
	color: var(--success-20);
}

@media screen and (max-width: 600px) {
	._1xe84esjjl6l {
		display: none;
	}
}
