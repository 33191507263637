._3sccruJ4Vniv {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1501;
}

._3J8UZtAd17g9 {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	opacity: 0.7;
	background: #333333;

	animation-name: _3wr1GRjrvALR;
	animation-duration: 0.2s;
	animation-timing-function: ease-in;
}

._3ornfFdu2eyW ._3J8UZtAd17g9 {
	animation: _3ERAf6VcUEpf 0.3s 1;
}

._3btIBqfFgCFx {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;

	height: auto;
	max-height: calc(100% - 100px);
	padding: 20px;

	border-top-left-radius: 8px;
	border-top-right-radius: 8px;

	background: #ffffff;
}

._3IZmGfV-a5fZ {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;

	color: var(--gray70);
	font-weight: 700;
	font-size: 20px;
}

@media screen and (max-width: 320px) {
	._3btIBqfFgCFx {
		max-height: calc(100% - 80px);
	}
}

._2TemQulY-QRp {
	padding: 0;
}

._1VtOpY7Gw_T7 {
	overflow-y: auto;
}

@keyframes _3wr1GRjrvALR {
	from {
		opacity: 0;
	}

	to {
		opacity: 0.6;
	}
}

@keyframes _3ERAf6VcUEpf {
	from {
		opacity: 0.6;
	}

	to {
		opacity: 0;
	}
}
