._2jaoq-S0OKOE {
	padding: 24px 48px;

	color: var(--dark-gray);
	font-size: 15px;

	background-color: var(--common-blue);
}

._29fA-GJTsLil {
	padding: 0;
}

._29fA-GJTsLil ._226nK4F7Dhck {
	padding: 24px 48px 5px;
}

@media (max-width: 1024px) {
	._2jaoq-S0OKOE {
		padding: 16px 22px;
	}
}

@media screen and (max-width: 600px) {
	._2jaoq-S0OKOE {
		position: relative;

		width: 100%;
		max-width: none;
		padding: 16px 20px;
	}
}

._3NuSPlIvwxDS {
	position: relative;

	display: flex;
	flex-wrap: wrap;

	max-width: 1100px;
	margin-right: auto;
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	._3NuSPlIvwxDS {
		position: static;

		display: block;
	}
}
