._3sDH_KgGP7WG {
	padding: 12px;
	display: flex;
	max-width: 1100px;
	margin: 0 auto;
	align-items: center;
	justify-content: space-between;

	border-radius: var(--service-border-radius);

	color: #ffffff;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;

	background: #e15c5c;
}

._3sDH_KgGP7WG ._3IAslj-hmkqR {
	margin-right: 12px;
	display: flex;
	align-items: center;
}

._3sDH_KgGP7WG._1rAfznZ9vo4a {
	padding: 12px 20px;
	max-height: 48px;

	color: #b70000;
	font-weight: 400;

	background: #ffffff;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

._3FosF85qRUV4 {
	display: flex;
	align-items: center;
}

button._2hkNpu1vt1bJ {
	border-radius: 0;

	color: #9a9a9a;
	font-size: 13px;
	font-weight: 500;
	line-height: 150%;

	background-color: transparent;

	transform: translateX(20px);
}

button._2hkNpu1vt1bJ:hover {
	background-color: transparent;
}

@media screen and (max-width: 600px) {
	div._3sDH_KgGP7WG,
	._3sDH_KgGP7WG._1rAfznZ9vo4a {
		position: relative;

		display: flex;
		flex-direction: column;
		max-height: unset;

		overflow: hidden;

		border-radius: 0;
	}

	._3sDH_KgGP7WG._1rAfznZ9vo4a:after {
		content: '';

		position: absolute;
		bottom: 0;

		display: block;
		width: 100%;
		height: 10px;

		border-radius: 8px 8px 0 0;

		background-color: var(--checkout-background);
	}

	button._2hkNpu1vt1bJ {
		padding: 10px 0 0;
		margin: 10px 0 20px;
		width: 100%;
		align-self: flex-end;
		justify-content: flex-end;
		height: 20px;

		border-top: 1px solid #e4e4e4;

		text-align: end;

		transform: none;
	}
}
