._1_ZE78m-eCLa {
	display: flex;
	padding: 20px 20px 0;

	border-radius: 8px;

	background-color: #ffffff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);

	transition: box-shadow 0.2s;
}

._1_ZE78m-eCLa:hover {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 3px 10px 29px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 600px) {
	._1_ZE78m-eCLa {
		flex-direction: column;
	}
}

.yPsCYaY432Ik {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	min-width: calc(50% - 10px);
}

.yPsCYaY432Ik:not(:last-of-type) {
	margin-right: 20px;
}

@media screen and (max-width: 600px) {
	.yPsCYaY432Ik:not(:last-of-type) {
		margin-right: 0;
		margin-bottom: 20px;
	}
}

._360wm4HtFKOk {
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 600px) {
	.yPsCYaY432Ik:last-of-type ._360wm4HtFKOk {
		flex-direction: row;
	}

	._27KeETzF_z2Q {
		max-width: 50%;
	}
}

._2EnNCdtGbMCD {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 60px;
}

._2LAG8PAqNSC- {
	display: flex;
	align-items: center;
	margin: 0 0 8px;

	font-size: 18px;
	line-height: 23px;
}

._16_2R_YLu64T {
	color: var(--baggage-block-title-highlighted);
}

._14hnb1y81wiY {
	margin: 0;
	padding-right: 15px;

	font-size: 13px;
	line-height: 150%;
	color: var(--common-gray);
}

._3xf8geyixaEV {
	color: var(--common-blue);

	cursor: pointer;
}

._2BDqCtJpHO4l {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: flex-end;

	margin: 50px 0;
}

@media screen and (max-width: 600px) {
	.yPsCYaY432Ik:last-of-type ._2BDqCtJpHO4l {
		margin: 20px 0;
	}
}

.O5sOivpUa4WM {
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.O5sOivpUa4WM:not(:last-of-type) {
	margin-right: 40px;
}

._2uiBQrPTqLej {
	position: absolute;
	bottom: 0;

	font-size: 18px;
	font-weight: 600;
	line-height: 26px;

	transform: translateY(calc(100% + 9px));
}

@media screen and (max-width: 600px) {
	.yPsCYaY432Ik:last-of-type ._2uiBQrPTqLej {
		position: static;

		margin-top: 8px;

		transform: none;
	}
}

._15mzizyPwE7o {
	display: inline-flex;
	padding: 0 8px;

	border-radius: 20px;

	color: #ffffff;
	line-height: 26px;
	font-size: 13px;
	font-weight: 500;

	background-color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	._15mzizyPwE7o {
		margin-left: 8px;
	}
}

._2nGvxSWRUnrM {
	display: flex;
	align-items: baseline;

	padding-right: 12px;
	margin-right: 8px;

	border-right: 1px solid #d1d1d1;

	font-size: 18px;
	font-weight: 600;
	line-height: 26px;
	color: var(--common-blue);
}

._1UmP0KSAqceX {
	color: #9a9a9a;
}
