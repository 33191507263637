

div.QpWH_2l8vhAi {
	margin-top: 30px;
}

div._3v-96dMSlWmm {
	border: 1px solid var(--gray20);
}

div._3v-96dMSlWmm:before {
	background: var(--white);
}

button.jg0R55PN6nHw,
div.jg0R55PN6nHw {
	margin-top: 30px;

	background: var(--common-orange);
}

button.jg0R55PN6nHw {
	height: 48px;
}

button.jg0R55PN6nHw:hover {
	background: var(--common-orange);
}

div._1pd-tmOikBX- div {
	background: var(--error);
}

div.GDtHKJYdxZcb:not(._1pd-tmOikBX-) div {
	color: var(--white);

	background: var(--success-20);
}

div._2TTVcCNvf7rE {
	top: 86px;
}

div._2TTVcCNvf7rE.dnAGs8OVboPo {
	top: 10px;
}

div._31pcZKogZ-wo {
	position: fixed;
}
