

button._2PYHdAwZelkd {
	font-weight: 500;
	color: #ffffff;

	background-color: var(--base);
}

button._2PYHdAwZelkd:hover,
button._2PYHdAwZelkd:focus {
	background-color: var(--base-15);
}

button._2PYHdAwZelkd:disabled {
	color: var(--gray30);

	background-color: var(--gray10);
}

button.FKZH_a7-Hham {
	font-weight: 500;
	color: var(--gray70);

	background-color: var(--gray05);
}

button.FKZH_a7-Hham:hover,
button.FKZH_a7-Hham:focus {
	background-color: var(--gray10);
}

button.FKZH_a7-Hham:disabled {
	color: var(--gray30);

	background-color: var(--gray10);
}
