div._1QczVKTkXi3h {
	max-width: max-content;
}

.pIhQOP6SWw_w {
	margin: 0 auto 4px;
	max-width: 1100px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);

	transition: box-shadow 0.15s;
}

.pIhQOP6SWw_w:hover {
	box-shadow: 0 2px 13px rgba(0, 0, 0, 0.12);
}

._1-sr6DQpy_9Q {
	display: flex;
	justify-content: space-between;
	padding: 18px 20px;

	border-top: 1px dashed rgba(255, 255, 255, 0.5);

	color: #ffffff;

	background-color: var(--order-paid-actions-background);
}

div._2rx-xbm1xpin {
	box-shadow: none;
}

div._2rx-xbm1xpin:last-child {
	border-radius: 0 0 4px 4px;
}

div._3u_z8epTw858 {
	padding: 0 20px;
}

div.xtQqgsgcIAc4 {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

._1MM9VoJDVmQu > span {
	height: 20px;
	margin-right: 20px;

	font-size: 14px;
	font-weight: 500;
	text-decoration: underline;

	cursor: pointer;
}

._1uGosGVrUBYu {
	font-size: 14px;
	font-weight: 500;
	color: var(--common-gray);
}

._2rx-xbm1xpin > div:first-child > span:last-child {
	padding-right: 20px;
}

div._2rx-xbm1xpin._2fCrPL68hPay {
	margin-top: 0;
}

div._3u_z8epTw858._2fCrPL68hPay {
	margin: 0;
}

div._3u_z8epTw858._3PDjYWoB1Wc1 {
	min-height: 48px;
}

._3u_z8epTw858._3PDjYWoB1Wc1 > div:first-child {
	margin: 12px 0;
}

div._1PBbneoGvbOs {
	padding: 8px 24px 12px;

	border-top: 1px solid #ededed;

	font-family: inherit;
}

._1MM9VoJDVmQu {
	display: flex;
	align-items: center;
}

._3cDrYSowH0U4 {
	display: flex;
	align-items: center;
}

._3cDrYSowH0U4 ._3RnPyL3bRcCR {
	height: 24px;
	display: flex;
	align-items: center;

	font-weight: 500;
	font-size: 14px;

	cursor: pointer;
}

._3RnPyL3bRcCR:first-child {
	margin-right: 20px;
}

._3RnPyL3bRcCR svg {
	margin-right: 8px;
}

._1rHNOmajN1IH {
	height: 24px;
	margin: 0 16px;

	border-right: 1px solid rgba(255, 255, 255, 0.5);
}

tr.LopEAgGayKEh {
	height: 40px;
}

th._2KV90xHDsiUF {
	padding: 0;

	border-bottom: 1px solid var(--dark-gray);

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 14px;
	font-family: inherit;
}

tr.e_6hMfQiEPdf td {
	padding: 12px 0;

	border-bottom: 1px dashed #cdcdcd;

	font-size: 14px;
	color: var(--dark-gray);
	font-family: inherit;
}

tr.e_6hMfQiEPdf:last-child td {
	border-bottom: none;
}

._2npl4149X66e {
	padding-left: 0;
}

._32E5aL7eRkGT {
	font-weight: 600;
}

._2CKwicmL_UdM {
	line-height: 20px;
	font-family: inherit;
}

.Pw3XYesDE_AF {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 22px 14px;

	border-top: 1px dashed rgba(255, 255, 255, 0.5);

	background-color: var(--order-paid-greetings-background);
}

.Pw3XYesDE_AF:last-child {
	border-radius: var(--order-paid-greetings-border-radius);
}

._6Jajq9o9x2OP {
	margin: 0;

	font-size: 15px;
	color: #ffffff;
}

.fvfZDWZD0-yK {
	margin-left: 5px;

	text-decoration: underline;

	cursor: pointer;
}

._3tCm1d0lSvsJ {
	display: flex;
}

button._1sSe3NyzKm6E {
	background-color: rgba(255, 255, 255, 0.2);
}

button._1sSe3NyzKm6E:hover {
	background-color: rgba(255, 255, 255, 0.3);
}

button._1Nz8VvbX1vsa {
	margin-right: 12px;

	background-color: var(--common-orange);
}

button._1Nz8VvbX1vsa:hover {
	background-color: var(--dark-orange);
}

a._14vY52DhbXnp {
	margin-right: 12px;
	padding: 0 20px;
	height: 40px;

	border-radius: 100px;

	font-weight: 500;
	font-size: 15px;
	line-height: 40px;
	color: #ffffff;

	background-color: rgba(255, 255, 255, 0.2);

	transition: background-color 0.15s, opacity 0.15s;
}

a._14vY52DhbXnp:hover {
	background-color: rgba(255, 255, 255, 0.4);
}

.j7M-MupRVCLK {
	position: relative;
	top: -10px;

	width: 40px;
	height: 40px;
	margin-left: 20px;

	border-radius: 50%;

	background-color: var(--common-blue);
}

._1g_pUPGlFXwc {
	background-color: var(--common-green);
}

.P2zWT-vHn0zO {
	width: 40px;
	height: 40px;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKKSURBVHgBtZZLaxNRGIa/ialak9pag9p6KwUrgqI7K7pQoeBC0IULXQguBUE3/gD/gVtdCC5diRdciAtRXChSWy/tLohSJQqi0dJom2R83pwTiZGYTDvzwsMJme9877nNnC+wDhSG4SaaERiCflgFy2AePkMBpoMgyLfLFbQx2kEzCn2QhpQ3Svu2+fc3uI/xs0iGGGVpjsB6b1KGlzADRRIWfdxKmkHYCbsh5wfwFS4T96WtIUm2+Vl1wxw8pOMH60D03UtzFNbBT7hO34mWhnTYTHPY3PJMwwQdflkEkUMDPQZj5lbnGjme1J+nGgIzNPugCi8IehrVrDaDICjBDX7e9flPkzv3jyE6ZH5mdJi0JYoct2geQA9c/MuQEWyl0QH4Aa8sPt0EHZxhPA7+MUTbzS3la0a2YDGJXDp0V8yt3KmaIc5rzc+OgHcWs8g5Ze7EbsBrl2aod60CHR39ReqeuVmOyjDjDQuWnN6Y2749jYbfLTl9gi4YkKFe/jJrXbaERG4ZyqtX3z2dztCSl7xqrjpBFU7QCktI/jLQoSnJsGTuNlhuyWnEG+ZlqKtEy9pjyUmfzXSjoU5pzpJT/QZ6lOIEzZq790J/Y8Qqch6nUYlSwGu8/i3VS69lHSAgZTGJXNqmc+Zmd1X/1ZLjrFpkzj/ot/h0HraYm93t5tF0wbCnz5YoclyAPIzDxlZBWRhSqQGrIYjoY77fJXgPb2GsXYduGATtZy7KB4HY/fAcPsIUnGiOaVUmai/X+Od6ZXQpq+hdYC+qDXGqFLRHB+AsZH28qoYzxM5YFGl20OuXKaM61O91AFWYhxLMQhEm4eT/cna0R37GIvRUPKpXVN4/hjuN5WAr/QakWztb10DkIQAAAABJRU5ErkJggg==) center no-repeat;

	animation: jtKKKIvb88A0 0.9s infinite linear;

	pointer-events: none;
}

._1JQYF9Cmc0Xw {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
}

._2vdTez0Caf9L {
	display: flex;
	align-items: center;

	color: #b70000;
	font-size: 17px;
	font-weight: 500;
	line-height: 20px;
}

._2vdTez0Caf9L svg {
	margin-right: 12px;
}

@keyframes jtKKKIvb88A0 {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}

@media screen and (max-width: 600px) {
	.pIhQOP6SWw_w {
		margin-bottom: 0;
	}

	.j7M-MupRVCLK {
		position: static;

		margin: 24px auto 0;
	}

	div.t8vf7AsMPP6R {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	div.t8vf7AsMPP6R div._1QczVKTkXi3h {
		margin: 0;
		width: 100%;

		border-radius: 0;
	}
}
