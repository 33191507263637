
/* stylelint-disable */
._1LKe3pBNFuIA {
	display: flex;
	justify-content: center;
}

._39FmLjMVP4Zm {
}

/* stylelint-enable */
._1bOatcCo-dLa {
	z-index: 1;

	width: 100%;
}

._2iCBulARygT9 {
	position: relative;
}
