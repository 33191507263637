._3HYXFpkHJlyQ {
	padding-bottom: 50px;

	background: var(--gray05);
	overflow: hidden;
}

.LcnxKd0OQHMB {
	min-height: 1660px;
}

._2-SvMLQQp6fj {
	min-height: 2100px;
}

@media (max-width: 1024px) {
	.LcnxKd0OQHMB,
	._2-SvMLQQp6fj {
		min-height: auto;
	}
}

._9M0mRE-ucIrw {
	display: flex;
	justify-content: center;

	border-bottom: 0;
}

._2kjUStm162il {
	padding-top: 78px;
}

._3FMtdfOC0qa7 {
	align-items: center;
	width: 30vw;
	padding-top: 60px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	min-height: 430px;

	text-align: center;
}

._1o7jUjpWf0Fa {
	margin-top: 0;
}

.sYeFlENWCTF7 {
	max-width: 50px;
}

.QWrC3VLf7tfN {
	margin: 40px 0;

	font-weight: 500;
	font-size: 21px;
	line-height: 27px;
}

button._3vQ7Bi8G8z21 {
	max-width: 200px;
	padding: 0 15px;
	margin-bottom: 30px;

	border-radius: 0;
}

._3Ibt1DrRD5vq {
	position: relative;

	/* max-width: 1100px; */
	min-height: 570px;
	margin: 0 auto 20px auto;
}

._1ednPhz0aVkT {
	position: absolute;
	top: 20px;
	right: 0;
	left: 0;
	z-index: 2;

	display: flex;
	justify-content: space-between;
	max-width: 1140px;
	height: 0;
	margin: 0 auto;
}

._3Q9EfmY4fI2R ._1wnzQhwD3S7E {
	opacity: 0.2;

	transition: opacity 0.2s;
}

._3Ibt1DrRD5vq div._1T0D7mvrDLYi {
	z-index: 1;

	margin: 0;

	border-bottom: 1px solid #d9d9d9;

	box-shadow: none;
}

._3Ibt1DrRD5vq div._21x9zqaDfqpD:before {
	opacity: 1;
}

._3Ibt1DrRD5vq ._1T0D7mvrDLYi:before {
	opacity: 1;
}

._3Ibt1DrRD5vq ._1T0D7mvrDLYi > ._1bcNe9Ermx5m {
	min-height: 54px;

	padding: 0 20px;
}

._1FiK1rdM5psE > div {
	display: flex;
	width: 375px;
	margin: 0 auto;
}

.hmZczCt1SmdA > div:first-child {
	border: none;
}

div.qRCqglqMyGHK > div:first-child {
	padding-left: 20px;
}

div._28xnodGAXWRJ > div:last-child {
	opacity: 0.5;
}

div._28xnodGAXWRJ > div > div:not(:first-child) > div {
	font-weight: 500;
	font-size: 13px;
	color: #313131;
}

._3Ibt1DrRD5vq ._1T0D7mvrDLYi > ._1bcNe9Ermx5m > div:first-child {
	margin: 20px 0;
}

._3Ibt1DrRD5vq ._1T0D7mvrDLYi .QvtNJrocfsZC {
	display: flex;
	flex-direction: column;
	padding: 16px 20px 28px;

	border-top: 1px dashed #d9d9d9;
}

._3GjOEE7WbzSG {
	display: flex;
}

._36My0G3hgbvL {
	position: relative;
}

._3cmZfmiPbUwp {
	position: relative;

	width: 310px;

	transition: opacity 0.2s;
}

._3Q9EfmY4fI2R ._3cmZfmiPbUwp {
	opacity: 0.2;
}

._1CRTuMU5e_bs {
	transform: translateX(50%);
}

._2XhJFX9qRlOb {
	padding: 0 10px 20px;
}

._2q-BVoQDv9u0 {
	display: flex;
	align-items: center;

	font-size: 14px;
	font-weight: 600;
	color: var(--common-gray);
}

._3ouX4ot4OQ0d:before {
	width: 32px;
	height: 33px;
	margin-right: 15px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMiAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9InBhdGgtMS1pbnNpZGUtMSIgZmlsbD0id2hpdGUiPgo8cGF0aCBkPSJNMCA0LjVDMCAyLjI5MDg2IDEuNzkwODYgMC41IDQgMC41SDI4QzMwLjIwOTEgMC41IDMyIDIuMjkwODYgMzIgNC41VjI4LjVDMzIgMzAuNzA5MSAzMC4yMDkxIDMyLjUgMjggMzIuNUg0QzEuNzkwODYgMzIuNSAwIDMwLjcwOTEgMCAyOC41VjQuNVoiLz4KPC9tYXNrPgo8cGF0aCBkPSJNMCA0LjVDMCAyLjI5MDg2IDEuNzkwODYgMC41IDQgMC41SDI4QzMwLjIwOTEgMC41IDMyIDIuMjkwODYgMzIgNC41VjI4LjVDMzIgMzAuNzA5MSAzMC4yMDkxIDMyLjUgMjggMzIuNUg0QzEuNzkwODYgMzIuNSAwIDMwLjcwOTEgMCAyOC41VjQuNVoiIGZpbGw9IiNFREVERUQiLz4KPHBhdGggZD0iTTQgMS41SDI4Vi0wLjVINFYxLjVaTTMxIDQuNVYyOC41SDMzVjQuNUgzMVpNMjggMzEuNUg0VjMzLjVIMjhWMzEuNVpNMSAyOC41VjQuNUgtMVYyOC41SDFaTTQgMzEuNUMyLjM0MzE1IDMxLjUgMSAzMC4xNTY5IDEgMjguNUgtMUMtMSAzMS4yNjE0IDEuMjM4NTggMzMuNSA0IDMzLjVWMzEuNVpNMzEgMjguNUMzMSAzMC4xNTY5IDI5LjY1NjkgMzEuNSAyOCAzMS41VjMzLjVDMzAuNzYxNCAzMy41IDMzIDMxLjI2MTQgMzMgMjguNUgzMVpNMjggMS41QzI5LjY1NjkgMS41IDMxIDIuODQzMTUgMzEgNC41SDMzQzMzIDEuNzM4NTggMzAuNzYxNCAtMC41IDI4IC0wLjVWMS41Wk00IC0wLjVDMS4yMzg1OCAtMC41IC0xIDEuNzM4NTggLTEgNC41SDFDMSAyLjg0MzE1IDIuMzQzMTUgMS41IDQgMS41Vi0wLjVaIiBmaWxsPSIjRDlEOUQ5IiBtYXNrPSJ1cmwoI3BhdGgtMS1pbnNpZGUtMSkiLz4KPHJlY3QgeD0iMC41IiB5PSIxIiB3aWR0aD0iMzEiIGhlaWdodD0iMjQiIHJ4PSIzLjUiIGZpbGw9IiNFREVERUQiIHN0cm9rZT0iI0Q5RDlEOSIvPgo8L3N2Zz4K) center no-repeat;
	content: '';
}

._25-7-IMwMinu {
	position: relative;

	margin: 0 !important;

	padding: 18px 0;
}

._30sxUUq2q_9a {
	position: fixed !important;
	top: 0;
	z-index: 2;

	width: 100%;
	margin: 0 !important;

	background: #ffffff;
}

._1FiK1rdM5psE {
	display: flex;
}

._1FiK1rdM5psE ._31GqgYAHu9lQ {
	display: flex;
	width: 187px;
	margin: 0;
}

.yTj2FpU7uM_Z {
	position: fixed;
	bottom: 0;
	z-index: 3;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 12px;
	height: 48px;
	width: 100vw;

	color: #ffffff;

	background-color: var(--dark-orange);
}

.yTj2FpU7uM_Z button > span:first-child {
	color: #ffffff;
	font-size: 17px;
	font-weight: 500;
	text-transform: none;
}

.yTj2FpU7uM_Z button:first-child > span:first-child {
	font-weight: 400;

	opacity: 0.7;
}

div._1hTpUBiXjzPc {
	display: flex;
	height: 56px;
	margin: 8px 20px;

	border-radius: 4px;

	background: linear-gradient(180deg, #57a4fe 0%, var(--common-blue) 100%);
}

div._28xnodGAXWRJ {
	border: 1px solid var(--common-blue);

	background: #ffffff;
}

div._28xnodGAXWRJ > div > div > svg > g {
	opacity: 1;
}

div._28xnodGAXWRJ > div > div > svg > g > path {
	fill: var(--common-blue);
}

div._17HJz6nrox0b {
	padding: 0;
	margin-top: 48px;
}

div.qRCqglqMyGHK {
	height: 48px;
	padding-left: 20px;

	font-size: 18px;
	font-weight: 600;
	line-height: 48px;
	color: #313131;
	text-align: left;
}

div._1O_ywSdgNPO2 {
	position: fixed;
	top: 20px;
}

div._1gR9jAb1n7a2 {
	position: relative;
	top: -8px;

	margin-bottom: -8px;
	overflow: hidden;

	border-radius: 8px 8px 0 0;
}

div._1gR9jAb1n7a2._2jLsa22LY2Zr:after {
	content: '';

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	background: rgba(0, 0, 0, 0.5);
}

button._3CgrIYrSFKl_ {
	max-width: 300px;
}

div.zhlXBdFPgfC6 {
	overflow-y: auto;

	background: var(--gray05);
}

@media (max-width: 1024px) {
	._1ednPhz0aVkT {
		left: 5%;
	}

	.F_RsAV8lV0E7 {
		overflow-y: auto;

		justify-content: space-between;
		padding: 0;
	}

	._389d1QEKXjpo {
		position: relative;
	}
}

@media screen and (max-width: 600px) {
	._3HYXFpkHJlyQ {
		overflow-x: visible;

		background: #ffffff;
	}

	._3GjOEE7WbzSG {
		justify-content: space-between;
	}

	._2XhJFX9qRlOb {
		padding: 0;
	}

	._3Ibt1DrRD5vq {
		margin: 0;
	}

	div ._25-7-IMwMinu {
		margin-top: 0;
		padding-top: 6px;
	}

	div ._25-7-IMwMinu:after,
	div ._25-7-IMwMinu:before {
		content: '';

		position: absolute;
		z-index: 6;

		height: 61px;
		width: 23px;
	}

	div ._25-7-IMwMinu:after {
		right: 0;
		top: 0;

		background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
	}

	div._25-7-IMwMinu:before {
		left: 0;
		top: 0;

		background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
	}

	._25-7-IMwMinu ._3CgrIYrSFKl_ {
		margin: 0;
		padding: 6px 16px 0;
	}

	._1O_ywSdgNPO2 {
		position: fixed;
		top: 0;
		z-index: 3;

		width: 100vw;
	}

	._3CgrIYrSFKl_ span > span > span > div > div:first-child {
		font-size: 14px;
		color: #3c3c3c;
	}
}

.yK1v_UBtcETa {
	margin-left: 5px;
}

._1OYCy091ghAi {
	display: block;
}

@media (max-width: 1024px) {
	._3GjOEE7WbzSG {
		flex-direction: column;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 600px) {
	._3FMtdfOC0qa7 {
		width: 60vw;
		padding-top: 30px;
	}
}
