

.I0h-h7YFwOU3 {
	flex-grow: 0;
	margin-left: auto;
	min-width: 110px;
}

._3AudqWqKomp8 ._1A7jlqsAbD_O {
	padding-bottom: 9px;
}
