

div._1AEoxobcmja3 {
	padding-right: 8px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 18px;
}

div._3XjVjRV_oSeP {
	font-size: 18px;
	font-weight: 700;
	color: var(--text-pale);
}
