._30RgdVg93xQw {
	display: flex;
	justify-content: space-between;
}

.O5ziX8LNbNWO {
	display: flex;
	align-items: center;
}

._2qgHCoz05dpT {
	position: relative;

	display: block;
	margin-right: 10px;
}

._1kWdTJAudwMR {
	margin-left: 4px;
	display: flex;
	align-items: center;
}

._3rRkMZbtE4eY {
	color: var(--dark-gray);
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
}

._1FAkKUe3Mjae {
	display: inline-flex;
	align-items: center;

	color: var(--light-gray);

	font-weight: 500;
	font-size: 13px;
	line-height: 20px;

	transition: color 0.15s;
}

._1FAkKUe3Mjae:hover {
	color: var(--common-gray);

	cursor: help;
}

._1FAkKUe3Mjae svg path {
	transition: fill 0.15s;
}

._1FAkKUe3Mjae:hover ._1kWdTJAudwMR path {
	fill: var(--common-gray);
}

button._2SJR5PIVgcK-,
button._1KeRsQ1ZKW2Y {
	display: flex;
	justify-content: center;
	align-items: center;

	width: calc(12px * 2);
	height: calc(12px * 2);
	padding: 0;

	border: none;

	border-radius: 50%;

	color: #ffffff;

	cursor: pointer;

	background-color: var(--common-blue);

	transition: background-color 0.15s;

	-webkit-appearance: none;

	        appearance: none;
}

._2SJR5PIVgcK- svg,
._1KeRsQ1ZKW2Y svg {
	fill: #ffffff;
}

button._2SJR5PIVgcK-:hover,
button._1KeRsQ1ZKW2Y:hover {
	background-color: var(--glassy-common-blue);
}

button._2SJR5PIVgcK-:disabled,
button._1KeRsQ1ZKW2Y:disabled {
	background-color: #ededed;
	cursor: default;
}

._2SJR5PIVgcK-:disabled svg,
._1KeRsQ1ZKW2Y:disabled svg {
	fill: #cdcdcd;
}

._14RUnfxN10nh {
	width: 38px;

	color: var(--common-blue);

	font-weight: 500;
	font-size: 18px;
	text-align: center;

	transition: color 0.15s;
}

._1hirv_McMZkg {
	color: #cdcdcd;
}

@media screen and (max-width: 600px) {
	._30RgdVg93xQw {
		font-size: 16px;
	}

	._3rRkMZbtE4eY {
		font-size: 16px;
	}

	button._2SJR5PIVgcK-,
	button._1KeRsQ1ZKW2Y {
		width: 32px;
		height: 32px;
	}

	._2qgHCoz05dpT {
		flex-basis: 45%;
	}

	._1FAkKUe3Mjae:hover {
		color: var(--light-gray);

		cursor: text;
	}
}
