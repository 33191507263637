._1LpPCLtcQt2M {
	display: flex;
	margin-top: 45px;
	justify-content: space-between;
}

._3RAMVI1WU4v8 {
	display: flex;
	flex: 1;
}

._2jpshYBNILfZ {
	display: flex;
	align-items: center;
}

._2DFkPj1pwct7 {
	width: 1px;
	height: 24px;
	margin: 0 12px;

	background: var(--gray20);
}

._1zmrq6cZ-uad {
	font-weight: 700;
	font-size: 22px;
	line-height: 1.4;
}

._34upl_0Ob8aS {
	font-weight: 500;
	font-size: 15px;
	line-height: 1.4;
	color: var(--base);

	cursor: pointer;
}

._3_Yh-oIx9IBM button._1Z5KuBKQqQB5 {
	width: 160px;
	margin-left: auto;
}

._3_Yh-oIx9IBM button._1vw9om3vvAit {
	width: 160px;
	margin-left: 12px;
}

@media screen and (max-width: 600px) {
	._1LpPCLtcQt2M {
		flex-direction: column;
		align-items: flex-end;
		margin-top: 15px;
	}

	._3RAMVI1WU4v8 {
		margin-top: 20px;
		width: 100%;
	}

	._3_Yh-oIx9IBM button._1Z5KuBKQqQB5,
	button._1vw9om3vvAit {
		flex: 1;
		width: 100%;
	}

	._2jpshYBNILfZ {
		margin-top: 15px;
	}
}
