

div._2hvhbaKDydCv {
	z-index: 1501;
}

div.W0HLgOiBNoea {
	overflow: visible;
}

._1FvUZVuPJZWS {
	display: flex;
	max-width: 1100px;
	height: 400px;

	border-radius: 4px;

	background-color: #f6f6f6;
	overflow: visible;
}

.KJdkxZl0Omd3 {
	flex-basis: 70%;
	padding: 24px 40px;
}

.YqtHxuqxvS5J {
	display: flex;
	flex-flow: column wrap;

	width: 100%;
	max-height: 94%;
	padding-bottom: 40px;
}

._2ylI_aZq8CHW {
	max-width: 140px;
	margin-right: 40px;

	padding: 8px;

	font-weight: 500;
	font-size: 12px;
	line-height: 12px;
	text-transform: uppercase;

	cursor: pointer;
}

.JYPp-Ut3JlmP {
	padding: 8px;

	border-radius: 2px;

	color: #ffffff;

	background-color: var(--autocomplete-group-country-active);
}

._1lRLjbloi3tE {
	flex-grow: 3.5;
	padding: 24px 40px;
	min-width: 300px;

	border-radius: 0 4px 4px 0;

	background-color: #ffffff;
	overflow-y: auto;
}

.OxJxx4tqYN29 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 7px 12px;
	max-width: 208px;

	border-bottom: 1px solid #d9d9d9;

	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	text-transform: capitalize;

	cursor: pointer;

	transition: background-color 0.2s;
}

.OxJxx4tqYN29:hover {
	background-color: #f6f6f6;
}

._2EWRus__o6Vj {
	line-height: normal;
	color: var(--common-blue);
	font-size: 10px;

	text-transform: uppercase;
}

.NMTlShwEVqtz {
	font-weight: 500;
	font-size: 12px;
	text-align: right;
	text-transform: uppercase;
	color: #9a9a9a;
}

._2-Fo_51PcKX7 {
	margin-bottom: 20px;

	font-weight: 600;
	font-size: 19px;
	line-height: 38px;
}

._2f_8AzjvIyLU {
	position: absolute;
	top: -8px;
	right: -10px;

	width: 24px;
	height: 24px;

	transform: translate(100%, -100%);
	cursor: pointer;
}
