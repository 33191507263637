

ul._3doItUkPRCMl {
	padding-top: 0;
	padding-bottom: 0;
}

._18B7ULV8xMB7:first-of-type ._3y1he0i-pTSP {
	border-top: none;
}

ul._3doItUkPRCMl ._18B7ULV8xMB7:last-of-type ._3y1he0i-pTSP {
	border-bottom: none;
}
