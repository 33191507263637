._3MScPbIarimy {
	display: flex;
	align-items: center;

	color: var(--gray80);
	font-size: 14px;
	text-align: left;
}

._3MScPbIarimy svg {
	margin-right: 6px;
}

._3dOp37gkWf_L {
	position: relative;

	display: inline-block;

	white-space: nowrap;
}
