span._2gdk7WJWakFS._6b7bmCEYqnqZ {
	color: var(--common-blue);
}

span._3P1sdjXzgc5O {
	font-family: inherit;
}

._337ybtUSYsMv {
	padding: 0 20px 20px;

	background-color: #ffffff;
}

@media screen and (max-width: 600px) {
	._337ybtUSYsMv {
		padding: 20px;
	}

	label._3x9cbGQslrRS {
		padding-left: 20px;
	}
}

._2evouF7f4TOd {
	padding: 12px;

	border-radius: 4px;

	background-color: #f0f0f0;
}

div.WCwTSCc6qild {
	z-index: 80;
}
