._2t3529zS9ysq {
	padding: 0 0 100px;
}

@media screen and (max-width: 600px) {
	div.EQr4tHbyZOuu {
		margin-top: 47px;

		border-radius: 12px 12px 0 0;
	}
}

button._3Yz0Ie6Nc55E {
	flex-shrink: 0;

	color: var(--order-backButton-color);

	background-color: #ededed;
}

@media (min-width: 1025px) {
	._37EXszeDIXgL {
		margin-bottom: -6px;
	}
}

button._3Yz0Ie6Nc55E:hover {
	background-color: #cdcdcd;
}

/* stylelint-disable */
button._1t5pXSHmrhzm {
}

button.nogwks4RDz5V {
}

/* stylelint-enable */

/* stylelint-disable */
button._1y79Ih6nzwhO {
}

/* stylelint-enable */
._2N8jFMBJ51iY {
	flex-grow: 1;
	display: flex;
	align-items: center;
}

._1og2-zPyz4Vv {
	display: flex;
	flex-direction: column-reverse;
}

button.z9Mqxg4buBlx,
button.z9Mqxg4buBlx:hover {
	position: relative;

	display: flex;
	height: auto;
	margin-bottom: 4px;
	padding: 12px 44px 12px 66px;

	border-radius: 4px;

	color: #ffffff;
	font-size: 15px;
	line-height: 18px;
	white-space: normal;
	text-align: left;

	background: #ffb13c;
}

.z9Mqxg4buBlx:before,
.z9Mqxg4buBlx:after {
	position: absolute;
	top: 50%;

	display: inline-block;

	transform: translate(0, -50%);
	content: '';
}

.z9Mqxg4buBlx:before {
	left: 15px;

	width: 33px;
	height: 30px;

	color: #ffffff;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC42Ij4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjUgM0gzMS41QzMzLjE1IDMgMzQuNSA0LjM1IDM0LjUgNlYyNEMzNC41IDI1LjY1IDMzLjE1IDI3IDMxLjUgMjdIMjFMMjQgMzEuNVYzM0gxMlYzMS41TDE1IDI3SDQuNUMyLjg1IDI3IDEuNSAyNS42NSAxLjUgMjRWNkMxLjUgNC4zNSAyLjg1IDMgNC41IDNaTTQuNSAyMUgzMS41VjZINC41VjIxWiIgZmlsbD0iI2ZmZmZmZiIvPgo8L2c+Cjwvc3ZnPgo=) center no-repeat;
}

.z9Mqxg4buBlx:after {
	right: 12px;

	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIGQ9Ik0yLjc3NDA1IDAuNjAwMDk4TDAuODAwMDQ5IDIuNTc0MUw3LjIxMjA1IDkuMDAwMUwwLjgwMDA0OSAxNS40MjYxTDIuNzc0MDUgMTcuNDAwMUwxMS4xNzQgOS4wMDAxTDIuNzc0MDUgMC42MDAwOThaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) center no-repeat;
	content: '';
}

._2BFYsxUUNwoy {
	margin: 0 auto;
}

._1g2Hed4vmo8V {
	display: flex;
	margin: 0 auto;
	max-width: 1100px;
	justify-content: space-between;
}

.h0pWlBk6u_BY {
	display: flex;
	justify-content: space-between;
	max-width: 1100px;
	width: 100%;
	margin: 8px auto 0;
}

.u7K5qE62NX-4 {
	padding: 0 calc((100% - 1100px) / 2);
}

._3drh1C6s5BDl {
	margin-bottom: 20px;
}

._3drh1C6s5BDl:last-child {
	margin-bottom: 0;
}

@media screen and (max-width: 600px) {
	._1g2Hed4vmo8V {
		display: none;
	}

	button._3Yz0Ie6Nc55E {
		padding: 0 20px;
		justify-content: flex-start;
		min-width: auto;

		font-size: 17px;
		font-weight: 400;
	}

	button._3Yz0Ie6Nc55E,
	button._3Yz0Ie6Nc55E:hover {
		background: transparent;
	}

	.h0pWlBk6u_BY {
		margin-top: 16px;
		flex-direction: column;
	}

	._498lPSE6eOH8 {
		margin-top: 4px;
	}

	._2t3529zS9ysq {
		position: relative;

		padding: 0;

		background-color: var(--common-blue);
	}

	._39r_FYHrDPCm {
		padding-bottom: 0;

		background-color: transparent !important;
	}

	._2BFYsxUUNwoy {
		position: relative;

		z-index: 3;

		max-width: 100% !important;

		margin-top: 0;
		padding: 20px 20px 100px;

		border-radius: 8px 8px 0 0;

		background: #ededed;
	}

	._3jXZdL4_GK8S {
		position: relative;

		top: -3px;
		z-index: 4;

		margin-top: 0 !important;
	}
}

@media (max-width: 1024px) {
	._2BFYsxUUNwoy {
		max-width: 560px;
	}
}
