

._3-Ozjtrn6ELm {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-width: 0;
	min-height: 26px;
}

._2nbxNVKNFiK- {
	display: flex;
	align-items: center;
}

._2J4t6218tLoJ,
._1ZgMIb1mxKVB {
	min-width: 22px;
	max-width: 22px;
	min-height: 22px;
	max-height: 22px;

	cursor: pointer;

	transition: opacity 0.1s linear;
}

._2J4t6218tLoJ svg,
._1ZgMIb1mxKVB svg {
	display: block;
	width: 22px;
	height: 22px;
}

._2J4t6218tLoJ:hover,
._1ZgMIb1mxKVB:hover {
	opacity: 0.8;
}

.xGOXtxGK5zfY {
	position: static;

	margin: 0 13px;

	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	color: var(--gray80);

	transform: none;
}

._2FmIvfW9kP6y {
	display: none;
}

._3ghV9D9gN4lD._3-Ozjtrn6ELm {
	opacity: 0.6;
}

._3ghV9D9gN4lD ._2J4t6218tLoJ,
._3ghV9D9gN4lD ._1ZgMIb1mxKVB {
	cursor: pointer;

	pointer-events: none;
}
