

._2bv-rX-z0mL6 {
	position: relative;
}

._2bv-rX-z0mL6 div._2LlO_SJVDuzR {
	margin: 0;

	border: 1px solid var(--brand-1-3);
	border-radius: 4px;
}

._2bv-rX-z0mL6 div._2LlO_SJVDuzR._2GSFLCQe67ou {
	border-color: var(--line-separator);
}

input._34XQCqfazQSx {
	width: 100%;
	height: 54px;

	border: none;

	color: var(--text-base);
	font-weight: 400;
	font-size: 16px;
}

._2bv-rX-z0mL6 label + ._2LlO_SJVDuzR ._34XQCqfazQSx {
	padding: 29px 9px 8px;
}

._2LlO_SJVDuzR input {
	border: 0 !important;
}

._2bv-rX-z0mL6 label._1CzERtHmbbYy {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
	color: var(--text-light);
}

._2bv-rX-z0mL6 label._1CzERtHmbbYy._233FPvGCqSBj {
	transform: translate(10px, 8px) scale(0.8);
}

._2bv-rX-z0mL6 label._1CzERtHmbbYy._233FPvGCqSBj:not(.hTfv-w7_F-YJ):not(._3SoU52D-YNaE) {
	color: var(--brand-1-0);
}

._2bv-rX-z0mL6 fieldset {
	display: none;
}

._2bv-rX-z0mL6 .D_3PfhfIGZwC._2LlO_SJVDuzR input {
	border-radius: 4px;

	background: var(--brand-1-5);
}

.D_3PfhfIGZwC._2LlO_SJVDuzR._9P30Xrdg90QK,
._2bv-rX-z0mL6 div._9P30Xrdg90QK {
	border: 1px solid var(--success-icon);
}

div._9P30Xrdg90QK:before {
	background: var(--success-bg);
}

div._2LlO_SJVDuzR.D_3PfhfIGZwC {
	border: 1px solid var(--brand-1-1);
}

div._27cixFO-Q2TL.D_3PfhfIGZwC._2LlO_SJVDuzR {
	border: 1px solid transparent;
}

.D_3PfhfIGZwC._2LlO_SJVDuzR._9P30Xrdg90QK:before {
	background: var(--success-bg);
}

._2bv-rX-z0mL6 label._1CzERtHmbbYy._233FPvGCqSBj._4do2G7CgJocn:not(.hTfv-w7_F-YJ):not(._3SoU52D-YNaE) {
	color: var(--success-icon);
}

.D_3PfhfIGZwC._2LlO_SJVDuzR._3Pfkbk99vwAv,
._2bv-rX-z0mL6 div._3Pfkbk99vwAv {
	border: 1px solid var(--warning-stroke-hover);
}

div._3Pfkbk99vwAv:before {
	background: var(--warning-bg);
}

._2bv-rX-z0mL6 .D_3PfhfIGZwC._2LlO_SJVDuzR._3Pfkbk99vwAv input {
	background: none;
}

.D_3PfhfIGZwC._2LlO_SJVDuzR._3Pfkbk99vwAv:before {
	background: var(--warning-bg);
}

._2bv-rX-z0mL6 ._2LlO_SJVDuzR.MKn8Fn9CH2gk,
._2bv-rX-z0mL6 .D_3PfhfIGZwC._2LlO_SJVDuzR.MKn8Fn9CH2gk,
div.MKn8Fn9CH2gk {
	border: 1px solid var(--error-icon);
}

div.MKn8Fn9CH2gk:before {
	background: var(--error-bg);
}

.D_3PfhfIGZwC._2LlO_SJVDuzR.MKn8Fn9CH2gk:before {
	background: white;
}

label._2kEH5SLqY9cM,
._2bv-rX-z0mL6 label._1CzERtHmbbYy._233FPvGCqSBj._2kEH5SLqY9cM:not(.hTfv-w7_F-YJ):not(._3SoU52D-YNaE) {
	color: var(--error-text);
}

div._2GSFLCQe67ou {
	border: none;

	font-size: inherit;
}

div._2LlO_SJVDuzR._2GSFLCQe67ou:before,
div._34XQCqfazQSx._2GSFLCQe67ou:before {
	background: var(--gray05);
}

input._34XQCqfazQSx._2GSFLCQe67ou,
div._34XQCqfazQSx._2GSFLCQe67ou {
	padding-left: 10px;
}

input._34XQCqfazQSx._2GSFLCQe67ou {
	color: var(--gray50);
}

.MKn8Fn9CH2gk input._34XQCqfazQSx,
.MKn8Fn9CH2gk div._34XQCqfazQSx,
._3Pfkbk99vwAv input._34XQCqfazQSx,
._3Pfkbk99vwAv div._34XQCqfazQSx,
._9P30Xrdg90QK input._34XQCqfazQSx,
._9P30Xrdg90QK div._34XQCqfazQSx {
	border: 1px solid transparent;
}

div._27cixFO-Q2TL._2LlO_SJVDuzR {
	border: 1px solid var(--gray10);
}

._2bv-rX-z0mL6 ._12HknN-Z9PN2 {
	position: absolute;
	top: 4px;

	margin: 0;

	color: var(--error-icon);
	font-weight: 400;
	font-size: 13px;

	transform: translate(0, 53px);
}
