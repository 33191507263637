._1dW5NMSvZKir {
	position: relative;

	display: flex;
	justify-content: space-around;
	width: 100%;
}

._3Xr0Bp7D-GTl {
	flex: 1;
	padding: 16px 8px;
	min-width: 120px;

	border-radius: 4px;

	text-align: center;

	background: var(--bg-fill);
}

._3Xr0Bp7D-GTl + ._3Xr0Bp7D-GTl {
	margin-left: 8px;
}

._3OfcKNxQhiBC {
	margin-top: 6px;
}

.w0oHUw-qS_lA {
	margin-top: 2px;
}

._3MTmxF2xrDhg {
	margin-top: 8px;

	color: var(--text);
	font-weight: 700;
	font-size: 13px;
}

._2njwlszpZNsA {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.q2SyAf2jgZBM {
	padding: 0 10px;
}

._3q0QHu5ZfRss {
	position: absolute;
	top: calc(50% - 12px);
	left: -12px;

	width: 28px;
	height: 28px;
	padding: 3px 2px;

	border-radius: 100px;

	box-shadow: 4px 7px 26px var(--gray10);
	cursor: pointer;
	background: var(--white);
}

._2ohH3L3zAHt7 {
	position: absolute;
	top: calc(50% - 12px);
	right: -12px;

	width: 28px;
	height: 28px;
	padding: 3px 2px;

	border-radius: 100px;

	box-shadow: 4px 7px 26px var(--gray10);
	cursor: pointer;
	background: var(--white);
}

._3q0QHu5ZfRss > svg {
	color: var(--gray50);

	transform: rotate(180deg) scale(0.8);
}

._2ohH3L3zAHt7 > svg {
	color: var(--gray50);

	transform: scale(0.8);
}

._1MbP8QSIK-pD {
	display: flex;
	height: 100%;
}

._1MbP8QSIK-pD > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

._1dW5NMSvZKir._2RvGzgssATek {
	max-width: 444px;
	flex: 1;
}

._1dW5NMSvZKir._2RvGzgssATek > div:first-child {
	overflow-x: hidden;
}

._1dW5NMSvZKir._2RvGzgssATek > div:first-child,
._1dW5NMSvZKir._2RvGzgssATek > div:first-child > div {
	margin: auto;
	height: 100%;
	width: 100%;
}

@media screen and (max-width: 600px) {
	._1dW5NMSvZKir {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 8px;
	}

	._1dW5NMSvZKir._2RvGzgssATek {
		display: flex;
	}

	._3Xr0Bp7D-GTl + ._3Xr0Bp7D-GTl {
		margin-left: 0;
	}
}
