

._3Y-XwvSaXi3b {
	display: flex;
	align-items: center;
	margin-left: 28px;
}

.zrpj7a6MYDsP {
	margin-right: 4px;

	color: var(--gray50);
	font-size: 14px;
	line-height: 14px;
}

button.-zfHXU_ghY7c {
	margin: 0 4px;

	border-color: #ffffff;

	font-size: 14px !important;
}

._2ggG9o2mkM10 {
	position: relative;
}

.C7wfBE2k6ni4 {
	position: absolute;
	bottom: -86px;
	left: 100%;
	z-index: 3;

	width: 360px;
	max-width: 360px;
	padding: 16px;

	border-radius: 8px;

	box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
	transform: translateX(-100%);
	background: #ffffff;
}

.-zfHXU_ghY7c._1pTmjTwMatoJ,
.-zfHXU_ghY7c._1pTmjTwMatoJ:hover {
	border-color: var(--base);

	color: #ffffff;

	background: var(--base);
}

div.JNpNXyUx8Aep > div:before,
div.JNpNXyUx8Aep > div:after {
	display: none;
}

div._11etcxZXj60w {
	margin-left: -16px;

	transform: translate(-10px, 1px);

	cursor: pointer;
}

._2JKkOqYwRzEc svg {
	width: 17px;
	height: 17px;
}

button.YMa6KAwA8nPe {
	width: 130px;
	min-width: 130px;
	max-width: 150px;
}

._2bJkUllqW8n1 {
	display: inline-block;
	overflow: hidden;

	text-overflow: ellipsis;
	white-space: nowrap;
}

button._3IPZTIhkYUZv {
	position: relative;

	padding-right: 25px;

	text-transform: uppercase;
}

._2JKkOqYwRzEc.vASUfvFS6qFc {
	position: absolute;
	right: 5px;
	top: 50%;

	transform: translateY(-41%);
}

div.JNpNXyUx8Aep input {
	padding: 11px 30px 11px 11px;

	border: 1px solid var(--base);

	color: var(--gray80);
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;

	background: var(--gray05);
}

div.JNpNXyUx8Aep input:-ms-input-placeholder {
	text-transform: none;
}

div.JNpNXyUx8Aep input::placeholder {
	text-transform: none;
}
