:root {
	--base-30: #2365b2;
	--base-25: #256cbf;
	--base-15: #2a7ad9;
	--base: #3290ff;
	--base10: #3290ff;
	--base15: #51a1ff;
	--base25: #65acff;
	--base50: #98c7ff;
	--base80: #d6e9ff;
	--base90: #eaf4ff;

	--brand1-3: #add3ff;
	--brand1-5: #eaf4ff;
	--brand1-4: #d6e9ff;
	--brand1: #3290ff;

	--line-separator: #d9d9d9;
	--line-dot-line: #c2c2c2;
	--gray-line: #d9d9d9;
	--text-base: #262626;
	--text-light: #808080;
	--bg-fill: #f6f6f6;
	--text: #262626;

	--gray: #ffffff;
	--gray01: #f9f9f9;
	--gray05: #f2f2f2;
	--gray10: #e5e5e5;
	--gray15: #dadada;
	--gray20: #cccccc;
	--gray30: #b3b3b3;
	--gray40: #b6b6b6;
	--gray45: #9a9a9a;
	--gray50: #808080;
	--gray70: #4d4d4d;
	--gray80: #333333;
	--gray90: #1a1a1a;

	--gray-bg: #f6f6f6;

	--active-50: #ff6d06;
	--active-30: #ff8108;
	--active: #ff9e0c;
	--active30: var(--active);
	--active80: #ffecce;
	--active90: #fff5e7;

	--brand1: #3290ff;
	--brand1-5: #eaf4ff;
	--brand1-3: #add3ff;

	--brand-1-0: var(--brand1);
	--brand-1-3: var(--brand1-3);
	--brand-1-5: var(--brand1-5);
	--brand-3-0: #ff8a00;
	--brand-3-1: #ffa133;
	--brand-3-3: #ffd099;

	--base2: #61579f;
	--base20: #61579f;

	--brand3: #ff8a00;
	--brand3-1: #ff6700;
	--brand3--3: #ffd099;

	--text-mid: #4d4d4d;

	--input-outline: var(--line-separator);
	--input-outline-active: var(--brand-1-1);
	--input-background: none;
	--input-background-active: var(--brand-1-5);
	--input-color: var(--text-light);
	--input-color-active: var(--text-base);
	--input-background-disabled: var(--bg-fill);
	--input-border-radius: 4px;
	--input-error-background-filled: var(--error-bg);
	--input-error-border: var(--error);

	--success-20: #4ca236;
	--success: #5fca43;
	--success20: #7fd569;
	--success60: #bfeab4;
	--success90: #dff4d9;

	--selected: #3290ff;
	--selected2: #5fca43;

	--error-text: #be1d1d;
	--error-20: #b31b1b;
	--error: #e02222;
	--error20: #e64e4e;
	--error60: #f3a7a7;
	--error90: #fce9e9;
	--errorBG: #fdf2f2;

	--error-bg: #fdf2f2;
	--error-icon: #e02222;

	--input-def: #d9d9d9;
	--input-focus: #3290ff;
	--input-error: #e02222;

	--warning: #ffe601;
	--warningBG: #fffdcc;
	--warning20: #fffacc;

	--text-pale: #b3b3b3;
	--text-mid: #4d4d4d;

	--bg-elements: #e9e9e9;

	--shdw-header: 0 7px 20px rgba(0, 0, 0, 0.03);

	--dark-red: #b70000;
	--light-orange: #ffb800;
	--common-orange: #ffa113;
	--dark-orange: #f48910;

	--white: #ffffff;
	--half-transparent-gray: #ededed;
	--light-gray: #a5a5a5;
	--common-gray: #777777;
	--dark-gray: #313131;

	--light-blue: #a7d3fb;
	--glassy-common-blue: #6fb1ff;
	--bright-blue: #2290f5;
	--common-blue: #3290ff;
	--dark-violet: #5c61ca;

	--light-emerald: #a0dbb7;
	--common-emerald: #2fd0bd;
	--dark-emerald: #4ca197;

	--common-green: #55d590;

	--seat-standart: #aad1ff;
	--seat-business: #ccc5ff;
	--seat-extra: #a0dba2;
	--seat-front: #ffd15b;
	--seat-busy: #e5e5e5;
	--seat-hover: var(--selected);
	--seat-occupaied: var(--selected);
	--seat-occupaied-hover: var(--base-15);

	--primary-button-hover: #5ba6ff;
	--link-color: var(--common-blue);

	--button-border-radius: 20px;
	--button-flat-border-radius: 4px;

	--autocomplete-option-label: var(--dark-gray);
	--autocomplete-option-code: var(--light-gray);
	--autocomplete-option-code-weight: 500;
	--autocomplete-dropdown-allDirections-button-fz: 12px;
	--autocomplete-group-label: var(--common-blue);
	--autocomplete-group-country-active: var(--common-blue);
	--datepicker-current-selected-date-color: var(--common-orange);
	--datepicker-day-selected-background: var(--common-blue);
	--datepicker-day-period-background: #d6e9ff;
	--datepicker-directions-background: var(--white);
	--datepicker-directions-current-direction-color: var(--dark-gray);
	--datepicker-directions-default-color: var(--dark-gray);
	--datepicker-day-active-color: #3290ff;
	--start-search-button: var(--common-orange);
	--start-search-button-hover: var(--dark-orange);

	--fareGroup-option-bg: var(--base);
	--fareGroup-option-svg-color: #ffffff;
	--fareGroup-option-svg-size: 40px;
	--fareGroup-option-text-color: #ffffff;
	--fareGroup-recommended-border-color: var(--active);
	--fareGroup-header-color: var(--active);
	--fareGroup-upgrade-option-bg: var(--active90);
	--fareGroup-upgrade-option-bdc: rgba(205, 32, 44, 0.2);
	--fareGroup-upgrade-option-bdrs: 0;
	--fareGroup-upgrade-option-link-color: var(--base);

	--switch-default-background: #5ba6ff;

	--tab-color-active: var(--common-blue);

	--weekcalendar-day-selected-background: var(--common-blue);
	--weekcalendar-day-selected-color: var(--white);

	--pricegraph-min-price: #5fca43;
	--pricegraph-airplane-icon: './src/PriceGraph/img/local_airport.svg';
	--pricegraph-day: var(--base80);
	--pricegraph-dropdown-default-progress: #cee5ff;
	--pricegraph-dropdown-progress: var(--pricegraph-dropdown-default-progress);
	--pricegraph-dropdown-text-color: #727272;
	--pricegraph-price-border-left-color: #98c7ff;

	--results-flight-price-underline-background: var(--common-blue);
	--results-flight-price-business-underline-background: var(--dark-violet);
	--results-flight-left-col-flex-basis: 190px;
	--results-flight-price-economy-group: var(--white);
	--results-flight-price-economy-group-color: var(--common-blue);
	--results-flight-price-business-group: var(--white);
	--results-flight-price-business-group-color: #506dd2;
	--results-flight-price-business-group-color-opened: #ffffff;
	--results-flight-price-prefix: var(--white);

	--results-flight-price-economy-group-opened: var(--common-blue);
	--results-flight-price-business-group-opened: #506dd2;
	--results-flight-price-separator-background: var(--line-separator);
	--results-flight-price-separator-height: 44px;
	--results-flight-header-underline-background: var(--common-blue);
	--results-desktop-flightinfo-select-fare-button: var(--common-orange);
	--results-desktop-flightinfo-select-fare-button-hover: var(--dark-orange);
	--results-mobileFlight-price-color: #eaf4ff;
	--results-mobileFlight-price-text-color: #2365b2;
	--results-mobileFlight-price-price-color: #2365b2;
	--results-faregroup-economy: var(--bright-blue);
	--results-faregroup-economy-title: #ffffff;
	--results-faregroup-business: var(--dark-violet);
	--results-faregroup-option-color: #ffffff;
	--results-faregroup-button-economy: var(--active);
	--results-faregroup-button-economy-hover: var(--active-30);
	--results-faregroup-button-business: var(--active);
	--results-faregroup-button-business-hover: var(--active-30);
	--results-loader-progress-background: var(--white);
	--results-loader-progress-color: var(--common-blue);

	--mobileSummaryFlight--background: linear-gradient(180deg, #57a4fe 0%, #3290ff 100%);
	--mobileSummaryFlight--background-flat: #ffffff;

	--promo-loader-background: var(--common-blue);
	--promo-loader-loader-color: var(--white);

	--summary-flight-features: rgba(50, 144, 255, 0.1);
	--summary-flight-features-color: var(--dark-gray);

	--results-flight-info-header-primary-color: var(--dark-gray);
	--results-flight-info-header-secondary-color: var(--common-gray);

	--stepbar-background-color: #3290ff;

	--mobileStepbar-background: var(--base);
	--mobileStepbar-index-backround: #52cb6d;
	--mobileStepbar-index-color: #ffffff;
	--mobileStepbar-header-flights-color: var(--common-blue);
	--mobileStepbar-segment-dates-color: #292929;
	--mobileStepbar-passenger-color: #292929;
	--mobileStepbar-passenger-info-color: #292929;
	--mobileStepbar-header-baggage-color: var(--common-blue);
	--mobileStepbar-baggage-color: #292929;
	--mobileStepbar-extras-color: var(--common-blue);
	--mobileStepbar-extras-money-notSelected-color: var(--common-blue);
	--mobileStepbar-footer-color: var(--common-blue);

	--tooltip-background: #353535;
	--tooltip-color: var(--white);

	--checkout-background: #ededed;
	--checkout-padding-top: 12px;

	--seatMap-banner-background: #45c1ec;
	--seatMap-passenger-background: #ededed;
	--seatMap-passenger-color: var(--dark-gray);
	--seatMap-passenger-price-color: var(--common-blue);
	--seatMap-passenger-cancel-color: #9a9a9a;
	--seatMap-passenger-seat-color: var(--common-gray);
	--seatMap-passenger-selected-seat-color: var(--seatMap-passenger-price-color-selected);
	--seatMap-seat-number-color: #9b776b;

	--seatMap-passenger-background-selected: #ededed;
	--seatMap-passenger-border-selected: var(--common-blue);
	--seatMap-passenger-color-selected: var(--common-blue);
	--seatMap-passenger-price-color-selected: var(--seatMap-passenger-price-color);
	--seatMap-passenger-seat-color-selected: var(--common-blue);
	--seatMap-seat-serviceInfo-price-color: var(--common-blue);
	--seatMap-service-economy-color: #ffffff;
	--seatMap-service-business-color: #ffffff;

	--quickSearchForm-background: #ffffff;
	--quickSearchForm-background-mobile: var(--common-blue);
	--quickSearchForm-summary-route-color: #727272;
	--quickSearchForm-summary-route-fontWeight: 400;
	--quickSearchForm-summary-passengers-color: #727272;
	--quickSearchForm-summary-passengers-fontWeight: 500;
	--quickSearchForm-summary-trigger-color: #727272;
	--quickSearchForm-summary-trigger-border-color: #bbbbbb;
	--quickSearchForm-summary-trigger-fontWeight: 400;

	--counter-background: rgba(50, 144, 255, 0.1);
	--counter-border-color: rgba(50, 144, 255, 0.3);
	--thanks-banner-color: var(--common-blue);
	--thanks-banner-font-size: 24px;
	--order-header-background: linear-gradient(to right, var(--common-blue) 35%, rgba(255, 255, 255, 0));
	--order-paid-actions-background: var(--common-blue);
	--order-paid-greetings-background: var(--common-blue);
	--order-paid-greetings-border-radius: 0 0 4px 4px;
	--order-backButton-color: var(--common-gray);
	--order-header-longTrip-background: linear-gradient(180deg, rgba(50, 144, 255, 0.8) 0%, var(--common-blue) 100%);
	--order-header-border-radius: 4px 4px 0 0;

	--loyalty-card-background: linear-gradient(315.32deg, rgba(50, 144, 255, 0.8) 9.81%, #3290ff 74.72%);

	--meals-banner-background: #f4a242;
	--meals-overlay-background: linear-gradient(180deg, #57a4fe 0%, var(--common-blue) 100%);
	--meals-complex-addButton-color: var(--common-blue);
	--meals-complex-addButton-background: var(--counter-background);
	--meals-complex-addButton-check-background: var(--common-blue);
	--meals-segments-background: var(--white);
	--meals-header-background: var(--white);
	--meal-selected-border: 1px solid transparent;
	--meal-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	--meal-box-shadow-active: 4px 18px 35px rgba(0, 0, 0, 0.09);
	--meals-overlay-arrow: var(--common-blue);
	--meals-tabs-border-top: 1px solid #d9d9d9;
	--meals-tabs-margin: 15px auto 0;
	--meal-categories-button-background: #ffffff;
	--meal-categories-button-background-selected: var(--common-blue);

	--additional-services-banner-background: #5ba6ff;
	--additional-services-head-primary: linear-gradient(180deg, #57a4fe 0%, #3290ff 100%);
	--additional-services-insurance-head: linear-gradient(180deg, #0098a2 0%, #006479 100%);
	--additional-services-transfer-head: linear-gradient(180deg, #ff7c32 0%, #ff3232 100%);
	--additional-services-lines-display: block;
	--additional-services-card-border-radius: 4px;
	--additional-services-add-button: var(--common-blue);
	--additional-services-add-button-color: var(--white);
	--additional-services-boarding-overlap: linear-gradient(90deg, #3290ff 0%, rgba(50, 144, 255, 0) 74.58%);

	--additional-card-head-border-radius: 4px 4px 0 0;
	--additional-card-footer-border-radius: 0 0 4px 4px;

	--baggage-banner-background: #6fcca5;
	--baggage-head-background: var(--white);
	--baggage-head-title-color: var(--dark-gray);
	--baggage-head-subtitle-color: var(--common-gray);
	--baggage-service-icon-opacity: 0.5;
	--baggage-service-background: var(--additional-services-head-primary);
	--baggage-service-passenger-background: rgba(255, 255, 255, 0.1);
	--baggage-service-button-padding: 8px 10px;
	--baggage-service-not-included: rgba(255, 255, 255, 0.1);
	--baggage-checkbox-not-active: #ffffff;
	--baggage-checkbox-active: var(--common-blue);
	--baggage-service-button-background: rgba(255, 255, 255, 0.3);
	--baggage-service-button-background-hover: rgba(255, 255, 255, 0.4);
	--baggage-service-button-border: 2px solid transparent;
	--baggage-service-button-border-add: 2px solid rgba(255, 255, 255, 0.3);
	--baggage-service-button-background-add-desktop: transparent;
	--baggage-service-button-background-add-mobile: rgba(91, 166, 255, 0.2);
	--baggage-service-button-background-add-mobile-hover: rgba(91, 166, 255, 0.3);
	--baggage-service-button-background-included-desktop: rgba(255, 255, 255, 0.3);
	--baggage-service-button-background-included-mobile: #f0f0f0;
	--baggage-service-button-border-included-desktop: 2px solid transparent;
	--baggage-service-button-border-included-mobile: 2px solid transparent;
	--baggage-additional-service-icon-opacity: 1;
	--baggage-switcher-border-right: none;
	--baggage-passenger-icon-display: none;
	--baggage-passenger-font-size: 33px;
	--baggage-service-small-font-size: 15px;
	--baggage-service-big-font-size: 15px;
	--baggage-service-segment-direction-text-transform: uppercase;
	--baggage-services-border-radius: 8px 8px 0 0;
	--baggage-services-border-radius-simple: 8px;
	--baggage-service-values-opacity: 0.7;
	--baggage-service-selected-values-opacity: 1;
	--baggage-additional-money-color: var(--common-blue);
	--baggage-additional-wrapped-border-radius: 16px;
	--baggage-additional-selected-money-color: var(--common-blue);
	--baggage-additional-header-font-size: 17px;
	--baggage-header-price-display: block;
	--baggage-bag-weight-color: var(--common-blue);
	--baggage-bag-weight-color-included: #1ca039;
	--baggage-block-title-highlighted: #1ca039;

	--service-background-color: var(--common-blue);
	--service-border-radius: 0;
	--service-hasProduct-border: 1px solid rgba(50, 144, 255, 0.6);
	--service-hasProduct-color: var(--dark-gray);
	--service-hasProduct-text-decoration: none;
	--service-hasProduct-background: rgba(50, 144, 255, 0.15);
	--service-icon-color: var(--white);
	--service-totalPrice-color: var(--dark-gray);

	--passengers-input-bg: var(--cabinet-action);

	--payment-limit-margin-top: 8px;
	--payment-wrapper-border-radius: 12px;
	--payment-wrapper-padding: 28px;

	--warnings-background: var(--common-blue);
	--warnings-warning-border-bottom-color: rgba(255, 255, 255, 0.5);
	--warnings-text-color: var(--white);
	--warnings-icon-color: rgba(255, 255, 255, 0.5);

	--selected-tab-color: var(--common-blue);
	--tabs-route-font-weight: 600;

	--cabinet-action: var(--common-blue);
	--cabinet-action--hover: var(--primary-button-hover);
	--cabinet-button-border: 20px;

	--toggleableFlights-button-fontWeight: 500;
	--toggleableFlights-button-plane-display: flex;
	--toggleableFlights-button-background: #5fca43;
	--toggleableFlights-button-background-hover: #5aab3f;

	--compareFares-serviceClass-display: inline;
	--compareFares-heading-item-font-weight: 600;

	--orderSummary-timelimit-background: #ffe601;

	--checkin-additional-price-selected-color: rgba(38, 38, 38, 1);

	--login-wrapper-border-radius: 12px;
	--login-alternative-display: block;
	--offer-social-buttons-color: var(--common-blue);

	--modalHeading-color: var(--common-gray);

	--sureModal-text-color: #595959;
	--sureModal-cancel-color: #414141;
	--sureModal-cancel-bg: #f0f0f0;

	--radio-color: #9a9a9a;
	--radio-checked-color: var(--base);
	--radio-disabled-color: #cccccc;

	/* Color styles */
	--brand-1-0: #3290ff;
	--brand-1-1: #51a1ff;
	--brand-1-2: #84bcff;
	--brand-1-3: #add3ff;
	--brand-1-4: #d6e9ff;
	--brand-1-5: #eaf4ff;
	--brand-1--2: #256cbf;
	--brand-1--1: #2a7ad9;
	--brand-2-0: #61579f;
	--brand-2-1: #7970ad;
	--brand-2-2: #a09ac5;
	--brand-2-3: #c0bcd9;
	--brand-2-4: #dfddec;
	--brand-2-5: #efeef5;
	--brand-2--2: #494177;
	--brand-2--1: #524a87;
	--brand-3-0: #ff8a00;
	--brand-3-1: #ffa133;
	--brand-3-2: #ffb966;
	--brand-3-3: #ffd099;
	--brand-3-4: #ffe8cc;
	--brand-3-5: #fff3e5;
	--brand-3--2: #ff4400;
	--brand-3--1: #ff6700;
	--bg-white: #ffffff;
	--bg-fill: #f6f6f6;
	--bg-elements: #e9e9e9;
	--line-separator: #d9d9d9;
	--line-dot-line: #c2c2c2;
	--text-base: #262626;
	--text-mid: #4d4d4d;
	--text-light: #808080;
	--text-pale: #b3b3b3;
	--text-link: #3290ff;
	--seat-unavailable: #eeeeee;
	--seat-hover-choosed: #5fca43;
	--seat-choosed-hover: #4ca236;
	--seat-economy-base: #aad1ff;
	--seat-business: #cbc4f5;
	--seat-premium: #e3d9bf;
	--seat-economy-front: #ffd15b;
	--seat-economy-extra-legroom: #a0dba2;
	--additional-aeroexpress: #e6494c;
	--additional-baggage: #4fcda0;
	--additional-capsule-hotel: #65bfdb;
	--additional-ffp: #61579f;
	--additional-insurance: #7cb8ee;
	--additional-meal: #ff942d;
	--additional-priority-boarding: #e46f6f;
	--additional-sms: #f28d6d;
	--additional-seats: #37beed;
	--additional-checkin: #ffe601;
	--success-bg: #e7f7e3;
	--success-stroke-hover: #bfeab4;
	--success-icon: #5fca43;
	--success-text: #479732;
	--error-bg: #fdf2f2;
	--error-stroke-hover: #f3a7a7;
	--error-icon: #e02222;
	--error-text: #be1d1d;
	--recommend-bg: #ffecce;
	--recommend-stroke-hover: #ffc56d;
	--recommend-icon: #ff9e0c;
	--warning-bg: #fffdcc;
	--warning-stroke-hover: #fffa80;
	--warning-icon: #efea6c;

	--desktop-win-border-radius: 20px;
	--desktop-win-background: var(--white);
}
