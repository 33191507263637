

div.LhkxYfAPynGP {
	padding: 40px;

	color: var(--gray80);
	font-size: 17px;
	line-height: 140%;
}

._1kHFDTbchAHB {
	min-width: 600px;
}

._3oSv2z8_Pejr {
	margin-bottom: 28px;

	font-weight: 500;
	font-size: 21px;
}

._3a5S-Nl0Ot2y {
	display: flex;
	justify-content: flex-end;
	margin-top: 28px;
}

button.YkPdTNOJNmmh {
	border: 1px solid var(--light-blue);

	color: var(--common-blue);
	line-height: 38px;

	background-color: var(--white);
}

button.YkPdTNOJNmmh:hover {
	background-color: var(--white);
}
