

._2mtfu3Cy0Ogs {
	background-color: var(--common-blue);
}

._1yXdcGuguW-6 {
	display: flex;

	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.dg0uD9vldng1 {
	display: flex;
	align-items: center;
	max-width: 650px;
	width: 100%;
	padding: 28px 0 25px;

	color: #ffffff;
}

._2mWy0073Q2jT {
	display: flex;
	align-items: center;
	margin-left: 40px;
	margin-right: 16px;
	height: 68px;

	font-size: 82px;
	font-weight: 500;
}

._1ddNIN4BZ_P4 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

._1w8tybw9tG7Q {
	margin-bottom: 3px;

	font-size: 22px;
	font-weight: 500;
	line-height: 26px;
}

._2hqiDQXjjy0N {
	max-width: 362px;

	font-size: 15px;
	line-height: 130%;
	color: rgba(255, 255, 255, 0.8);
}

._1sJhe3Qmk-vk {
	display: flex;
	max-width: 450px;
	width: 100%;
}

button._1dyKwr5jEuVe {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;

	border-radius: 0;
	border-left: 1px solid rgba(255, 255, 255, 0.3);

	font-size: 14px;
	line-height: 150%;
	font-weight: 400;
}

._2pcSG92BAGhT {
	height: 24px;
	margin-bottom: 14px;
}

div._3DK3aYGr3-Eo {
	margin-left: 40px;
	height: 56px;
	margin-top: 0;
}

._3DK3aYGr3-Eo button._3YSnzrvkfl8u {
	max-width: 160px;
	width: 100%;
	height: 56px;
	margin: 0;

	color: #ffffff;

	background-color: rgba(255, 255, 255, 0.3);

	opacity: 1;

	transition: background-color, color 150ms;
}

button._3YSnzrvkfl8u div {
	color: #ffffff;
}

button._3YSnzrvkfl8u._2tpA7IEqq0iv {
	background-color: #ffffff;
}

span._1kscbgxB02kH,
._3YSnzrvkfl8u ._3o2C2Cqyu2pf {
	display: none;
}

._3YSnzrvkfl8u ._2sIfSn_wCjbZ {
	font-weight: 500;
	font-size: 16px;
	text-transform: none;
}

a._1QJNw7WQoAxG {
	text-decoration: none;
}
