

._2Id230M5fUBa {
	display: inline-block;
	padding: 10px;
	margin-left: auto;

	color: var(--base);
	text-decoration: none;
}

._2Id230M5fUBa:before {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin-right: 5px;

	vertical-align: bottom;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi42NjcgMy4zMzM0MUgxNC4wMjUzTDEyLjUwMDMgMS42NjY3NUg3LjUwMDMzTDUuOTc1MzMgMy4zMzM0MUgzLjMzMzY2QzIuNDE2OTkgMy4zMzM0MSAxLjY2Njk5IDQuMDgzNDEgMS42NjY5OSA1LjAwMDA4VjE1LjAwMDFDMS42NjY5OSAxNS45MTY3IDIuNDE2OTkgMTYuNjY2NyAzLjMzMzY2IDE2LjY2NjdIMTYuNjY3QzE3LjU4MzcgMTYuNjY2NyAxOC4zMzM3IDE1LjkxNjcgMTguMzMzNyAxNS4wMDAxVjUuMDAwMDhDMTguMzMzNyA0LjA4MzQxIDE3LjU4MzcgMy4zMzM0MSAxNi42NjcgMy4zMzM0MVpNMTAuMDAwMyAxMi42NjY3QzExLjQ3MzEgMTIuNjY2NyAxMi42NjcgMTEuNDcyOCAxMi42NjcgMTAuMDAwMUMxMi42NjcgOC41MjczMiAxMS40NzMxIDcuMzMzNDEgMTAuMDAwMyA3LjMzMzQxQzguNTI3NTcgNy4zMzM0MSA3LjMzMzY2IDguNTI3MzIgNy4zMzM2NiAxMC4wMDAxQzcuMzMzNjYgMTEuNDcyOCA4LjUyNzU3IDEyLjY2NjcgMTAuMDAwMyAxMi42NjY3Wk01LjgzMzY2IDEwLjAwMDFDNS44MzM2NiAxMi4zMDAxIDcuNzAwMzMgMTQuMTY2NyAxMC4wMDAzIDE0LjE2NjdDMTIuMzAwMyAxNC4xNjY3IDE0LjE2NyAxMi4zMDAxIDE0LjE2NyAxMC4wMDAxQzE0LjE2NyA3LjcwMDA4IDEyLjMwMDMgNS44MzM0MSAxMC4wMDAzIDUuODMzNDFDNy43MDAzMyA1LjgzMzQxIDUuODMzNjYgNy43MDAwOCA1LjgzMzY2IDEwLjAwMDFaIiBmaWxsPSIjQjNCM0IzIi8+Cjwvc3ZnPgo=) no-repeat center;
	background-size: contain;
	content: '';
}
