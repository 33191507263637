._2aVJSz5jNsK3 {
	min-height: 44px;
	display: flex;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
}

._1v4-iIP_UgFa {
	display: flex;
	flex: 1;
	align-items: center;

	line-height: normal;
}

._2qqZYzCuw4I5 {
	width: 21px;
	height: 21px;
	margin-right: 15px;

	color: #b3b3b3;
}

._2qqZYzCuw4I5 svg {
	width: 21px;
	height: 21px;
}

._2aVJSz5jNsK3:not(:first-child) {
	border-top: 1px solid #e3e3e3;
}

._2lR7VNLbCaLE {
	display: flex;
	align-items: center;
}

@media (max-width: 768px) {
	._2aVJSz5jNsK3 {
		flex-direction: column;
		align-items: flex-start;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	._1v4-iIP_UgFa {
		align-items: flex-start;
	}

	._2aVJSz5jNsK3._3AsXNsVqTO7s:first-of-type {
		padding-top: 0;
	}

	._2lR7VNLbCaLE {
		margin-left: 36px;
		margin-top: 12px;
	}

	._2aVJSz5jNsK3._1iXutjeYXvyu:last-child {
		border-radius: 0 0 8px 8px;
	}
}
