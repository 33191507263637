._3TX9CpCKf8Nz {
	width: max-content;
}

.qbT-spKRsDvk {
	z-index: 1501 !important;
}

.SFT9Usrq9x2b {
	width: 728px;
	padding: 0 34px 40px;
}

._38aLRyg4QwLw {
	display: flex;
}

._1zIuOeTyqolj {
	margin-top: 20px;
}

._2IgUmw49msGN {
	position: relative;

	width: 100%;
	padding: 30px 35px;

	color: var(--text);
	font-weight: 700;
	font-size: 22px;
}

._3c8BQjq_Xzri {
	position: absolute;
	top: 28px;
	right: 32px;

	margin: auto;

	cursor: pointer;
}

._3591W1CM1cep {
	padding-left: 0;
}

@media screen and (max-width: 600px) {
	._38aLRyg4QwLw {
		display: block;
	}

	.SFT9Usrq9x2b {
		width: 100vw;
		padding: 12px;
	}

	._2IgUmw49msGN {
		position: relative;

		display: flex;
		align-items: center;
		height: 56px;
		padding: 0 15px;

		color: #262626;
		font-weight: 600;
		font-size: 18px;

		box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
	}

	._3c8BQjq_Xzri {
		position: absolute;
		right: 12px;
		top: 12px;

		cursor: pointer;
	}
}
