._1m0cOY-83ssA {
	padding: 40px 60px;
}

._1m0cOY-83ssA .rules_1-1:after {
	display: none;
}

._1UE6PNaZvMVo {
	margin: 0;

	font-size: 20px;
	line-height: 28px;
}

.WoUQXoLKOwjT {
	margin: 24px 0;

	font-size: 14px;
	line-height: 21px;
}

._3RbZ1dBHLCAC {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

button._2XeZXR3wcdKc {
	background-color: var(--common-orange);
}

button._2XeZXR3wcdKc:hover {
	background-color: var(--dark-orange);
}

span._3rU1mHg9saXC {
	padding-right: 60px;

	font-size: 17px;
	font-family: inherit;
	line-height: 24px;
	color: var(--common-gray);
}

span._3cnI_9fYztL5._15EPMakj9zVO {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	._1m0cOY-83ssA {
		padding: 20px 40px 80px 20px;
	}

	._3RbZ1dBHLCAC {
		display: block;
	}

	span._3rU1mHg9saXC {
		padding-right: 0;
	}

	button._2XeZXR3wcdKc {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;

		width: 100%;
		height: 60px;

		border-radius: 0;

		font-size: 17px;
	}
}
