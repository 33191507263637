

._3TjWoHdCrh4S {
	position: relative;

	display: inline-block;
}

.bd7nopRY8H5L {
	color: inherit;
}

._1fI7xb35HHVm {
	cursor: pointer;
}

._3ykOJnhyQ2xP {
	position: absolute;
	top: 40px;
	left: 12px;

	z-index: 10;

	min-width: 150px;
	padding: 8px 0;

	border-radius: 4px;

	transform: translateX(-50%);
	background: #ffffff;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
}

._3ykOJnhyQ2xP:after {
	position: absolute;
	top: -16px;
	right: 0;
	left: 0;

	width: 0;
	margin: auto;

	border: 8px solid;
	border-color: transparent transparent #ffffff transparent;
	content: '';
}

._2dmA5bXG0liE {
	display: flex;
	align-items: center;
	width: 100%;
	height: 40px;
	padding: 8px 16px;

	color: var(--dark-gray);

	font-size: 16px;

	cursor: pointer;

	transition: background-color 0.15s;
}

._2dmA5bXG0liE._1Hp8bq-EezmA {
	font-weight: 600;
}

._2dmA5bXG0liE:hover {
	background: #f9f9f9;
}

._2dmA5bXG0liE:not(:last-child) {
	border-bottom: 1px solid #ededed;
}

._2xpcGFWiAIEJ {
	margin-right: 13px;
}
