

.Vw7LMODJt0RA {
	padding: 14px 16px 14px 20px;

	border-top: 1px dashed rgba(255, 255, 255, 0.4);

	color: #ffffff;
}

._25Apnxl2Rwtv {
	opacity: 0.5;
}

._1N4bgYt4HZ_L {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

._1Pkyn2wY827j {
	display: flex;
	align-items: center;
}

._22usIlEqpWeK {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 24px;
	width: 24px;
	margin-right: 16px;

	border-radius: 50%;
	border: 2px solid #ffffff;

	color: var(--mobileStepbar-index-color);
	font-size: 13px;

	background: var(--mobileStepbar-index-backround);
}

._25Apnxl2Rwtv ._22usIlEqpWeK {
	color: #3290ff;

	background: #ffffff;
}

._2nl9Ht5c_ock {
	font-size: 16px;
	line-height: 24px;

	color: #ffffff;
}

.Qm2B6FcbJEMC {
	display: flex;
	align-items: center;
}

._2aXpp1nYhlNh {
	height: 24px;
	width: 24px;
	margin-left: 8px;

	color: #ffffff;

	transition: transform 0.3s;
}

._2wApg1dxC2nH {
	transform: rotate(-180deg);
}

._3BkVrzoGYtjj {
	margin-top: 14px;
	width: 100%;
}
