._1HxiPGlV_6E1 {
	display: flex;
	align-items: center;
	padding: 18px;

	border: 1px solid #efea6c;
	border-radius: 4px;

	background-color: #fffdcc;
}

._2riC2dpsC80C {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 16px;

	color: #b3b3b3;
}

._27M9r0N85ONE {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

._2oFQK9QrOvUU {
	color: #cd202c;
}

._1fW3Dt_i3QWX {
	text-transform: capitalize;
}

._1fKvXsqYkz_H {
	display: flex;
	align-items: center;
	margin-left: auto;
}

button._3bKE11jzCSOM {
	position: relative;

	margin-right: 24px;

	color: #cd202c;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;

	cursor: pointer;
}

button._3bKE11jzCSOM:after {
	content: '';

	position: absolute;
	top: 0;
	right: -13px;

	height: 100%;
	width: 1px;

	background-color: var(--gray30);

	pointer-events: none;
}

button._3bKE11jzCSOM:last-child {
	margin-right: 0;
}

button._3bKE11jzCSOM:last-child:after {
	display: none;
}

div._22A9VaTDDmJG {
	max-width: 480px;
}

button._37U3WlCxyPPn {
	margin-right: 12px;
}

button._37U3WlCxyPPn:last-child {
	margin-right: 0;
}

button._37U3WlCxyPPn._2ATms78wvh-- {
	color: var(--sureModal-text-color);

	background-color: var(--sureModal-cancel-bg);
}

button._37U3WlCxyPPn._2ATms78wvh--:hover {
	color: var(--sureModal-text-color);

	background-color: var(--gray10);
}

@media screen and (max-width: 600px) {
	._1HxiPGlV_6E1 {
		flex-wrap: wrap;
	}

	._27M9r0N85ONE {
		width: 86%;
	}

	._1fKvXsqYkz_H {
		flex-wrap: wrap;
		margin-left: 0;
		margin-top: 16px;
	}

	._3bKE11jzCSOM {
		margin-right: 20px;
	}

	._3bKE11jzCSOM:after {
		right: -11px;
	}

	div._22A9VaTDDmJG {
		width: 100%;
		min-width: auto;
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}

	div._1agDElKdX1SK {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}
