._2gvkeL6xWUqN {
	padding: 40px;
}

@media (max-width: 1024px) {
	._2gvkeL6xWUqN {
		max-height: 80vh;
		overflow-y: scroll;

		padding-right: 60px;
		width: 320px;
	}

	._1KQUZOofIAh8 {
		margin-bottom: 50px;
	}
}

@media (min-width: 1025px) {
	._2E846UWJvDux {
		display: flex;
	}

	._2E846UWJvDux > *:not(:last-of-type) {
		margin-right: 40px;
	}

	._1KQUZOofIAh8 {
		width: 405px;
	}

	._27QqnVxGVsUK {
		width: 500px;
	}
}

._1KQUZOofIAh8,
._27QqnVxGVsUK {
	position: relative;

	flex-shrink: 0;
	padding-bottom: 70px;
}

._2jiYN2XmqjNV {
	position: absolute;
	bottom: 0;

	width: 100%;
}

@media screen and (max-width: 600px) {
	._1KQUZOofIAh8,
	._27QqnVxGVsUK {
		padding-bottom: 0;
	}

	._2jiYN2XmqjNV {
		position: static;

		margin-top: 30px;
	}
}

._2jZPhO4QQxsc {
	font-weight: 500;
	font-size: 21px;
	color: var(--gray80);
}
