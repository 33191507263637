.tj42BDNzLa1w {
	display: flex;
	flex-grow: 1;
}

@media (max-width: 1024px) {
	.tj42BDNzLa1w {
		flex-wrap: wrap;
	}
}

._3wsnL-1nclJM {
	position: relative;
}

.hFqtxG2r4xGr {
	display: flex;
}

._1Tu3aw6dYuR1 {
	position: relative;
}

.hFqtxG2r4xGr:not(:first-of-type) {
	margin-top: 4px;
}

@media screen and (max-width: 600px) {
	.hFqtxG2r4xGr {
		display: block;
	}

	.hFqtxG2r4xGr:not(:first-of-type) {
		margin-top: 24px;
	}
}

.iwglb2F_fv07 {
	display: flex;
	flex-grow: 1;
	flex-basis: 226px;
}

.iwglb2F_fv07:not(:last-of-type) {
	margin-right: 4px;
}

@media (max-width: 1024px) and (min-width: 601px) {
	.iwglb2F_fv07 {
		flex-basis: 50%;
	}

	.iwglb2F_fv07:not(:last-of-type) {
		padding-right: 4px;
		margin-right: 0;
	}
}

._2RvhJ1zGyc_7 {
	flex-grow: 2.5;
}

._1bMPdah9bUQ5 {
	display: flex;
}

._3b7YkHXhXZqR {
	flex-grow: 3;
	flex-basis: 272px;
}

@media (max-width: 1024px) {
	._3b7YkHXhXZqR {
		height: 48px;
		flex-grow: 0;
		flex-basis: 50%;
		padding-right: 4px;
		margin-top: 4px;
	}

	._3wsnL-1nclJM ._3b7YkHXhXZqR {
		flex-basis: 100%;
	}
}

@media screen and (max-width: 600px) {
	._3b7YkHXhXZqR {
		flex-grow: 1;
		flex-basis: 100%;
		padding-right: 0;
		margin-top: 0;
	}
}

._2YHzHstzNkuI {
	flex-basis: 230px;
	flex-grow: 0;
}

@media screen and (max-width: 600px) {
	.iwglb2F_fv07:not(:last-of-type) {
		flex-basis: 100%;
		margin-right: 0;
		margin-bottom: 6px;
	}
}

._1wrIgqDUwXE- {
	width: 100%;
	height: var(--input-height);

	padding: 0 16px;

	border: none;
	border-radius: 2px;

	color: #ffffff;
	font-weight: 500;

	font-size: 15px;
	line-height: var(--input-height);
	text-align: left;

	background-color: rgba(255, 255, 255, 0.2);

	transition: background-color 0.15s, color 0.15s;

	-webkit-appearance: none;

	        appearance: none;
}

._1wrIgqDUwXE-:-ms-input-placeholder {
	color: #ffffff;

	opacity: 0.7;
}

._1wrIgqDUwXE-::placeholder {
	color: #ffffff;

	opacity: 0.7;
}

._3NlUnhFSNe4c,
._1wrIgqDUwXE-:focus {
	color: var(--dark-gray);

	background-color: #ffffff;
}

@media screen and (max-width: 600px) {
	._3NlUnhFSNe4c,
	._1wrIgqDUwXE-:focus {
		color: #ffffff;

		background-color: rgba(255, 255, 255, 0.2);
	}
}

._3NlUnhFSNe4c:-ms-input-placeholder, ._1wrIgqDUwXE-:focus:-ms-input-placeholder {
	color: var(--dark-gray);

	opacity: 1;
}

._3NlUnhFSNe4c::placeholder,
._1wrIgqDUwXE-:focus::placeholder {
	color: var(--dark-gray);

	opacity: 1;
}

._1wrIgqDUwXE-:not(:focus):not(._3NlUnhFSNe4c):hover {
	background-color: rgba(255, 255, 255, 0.4);
}

._2Jl1IJJTZlCJ {
	position: relative;

	width: 100%;
}

._2tlcNWrx89_X {
	position: absolute;
	right: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 40px;
	height: var(--input-height);

	color: #ffffff;

	transform: translateX(100%);
	opacity: 0.4;

	transition: opacity 0.15s;
}

._2tlcNWrx89_X:hover {
	opacity: 1;
	cursor: pointer;
}

._2tlcNWrx89_X svg {
	width: 20px;
	height: 20px;
}

._1zheqY4sZN79._2tlcNWrx89_X {
	top: 0;
	bottom: 0;

	width: 36px;
	height: 36px;
	margin: auto;

	border-radius: 50%;

	transform: translateX(calc(100% + 8px));
	opacity: 1;
	background: rgba(255, 255, 255, 0.2);
}

._1zheqY4sZN79._2tlcNWrx89_X._3bly75dEy-tt {
	opacity: 0.4;
}

@media screen and (max-width: 600px) {
	._1ScjncgZyVo2 ._2RvhJ1zGyc_7 {
		flex-basis: 50%;
		flex-grow: 2;
	}

	._1ScjncgZyVo2 ._2RvhJ1zGyc_7:first-of-type {
		padding-right: 3px;
	}

	._1ScjncgZyVo2 div._2RvhJ1zGyc_7:nth-child(2) {
		padding-left: 3px;
	}

	._1ScjncgZyVo2 ._2RvhJ1zGyc_7 ._1wrIgqDUwXE- {
		height: 72px;

		line-height: 72px;
		text-align: center;
	}

	._1ScjncgZyVo2 ._3b7YkHXhXZqR {
		height: 72px;
	}

	._1ScjncgZyVo2 ._3wsnL-1nclJM {
		flex-wrap: nowrap;
	}

	._1ScjncgZyVo2.hFqtxG2r4xGr:not(:first-of-type) {
		margin-top: 0;
	}

	._1ScjncgZyVo2 ._3wsnL-1nclJM ._1s6E2iot52L- {
		margin-top: 0;
	}

	._1ScjncgZyVo2 ._3wsnL-1nclJM ._2RvhJ1zGyc_7 {
		flex-basis: 100%;
		height: 52px;
	}

	._1ScjncgZyVo2 ._3wsnL-1nclJM ._2RvhJ1zGyc_7:first-of-type {
		padding-right: 0;
	}

	._1ScjncgZyVo2 ._3wsnL-1nclJM ._2RvhJ1zGyc_7:nth-child(2) {
		padding-left: 0;
	}

	._1ScjncgZyVo2 ._3wsnL-1nclJM ._2RvhJ1zGyc_7 ._1wrIgqDUwXE- {
		height: 52px;

		line-height: 52px;
	}

	._1ScjncgZyVo2 ._3wsnL-1nclJM ._3b7YkHXhXZqR {
		height: 52px;
	}

	._1ScjncgZyVo2 ._3wsnL-1nclJM ._1wrIgqDUwXE-:only-child {
		position: relative;

		color: transparent;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxnIG9wYWNpdHk9IjAuNSI+CgkJPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDlDNSA1LjEzIDguMTMgMiAxMiAyQzE1Ljg3IDIgMTkgNS4xMyAxOSA5QzE5IDE0LjI1IDEyIDIyIDEyIDIyQzEyIDIyIDUgMTQuMjUgNSA5Wk05LjUgOUM5LjUgMTAuMzggMTAuNjIgMTEuNSAxMiAxMS41QzEzLjM4IDExLjUgMTQuNSAxMC4zOCAxNC41IDlDMTQuNSA3LjYyIDEzLjM4IDYuNSAxMiA2LjVDMTAuNjIgNi41IDkuNSA3LjYyIDkuNSA5WiIgZmlsbD0id2hpdGUiLz4KCTwvZz4KPC9zdmc+Cg==) center no-repeat rgba(255, 255, 255, 0.2);
	}
}

@media (max-width: 1024px) {
	._3wsnL-1nclJM ~ ._2tlcNWrx89_X {
		width: 19px;
	}

	._1Tu3aw6dYuR1 ._3wsnL-1nclJM ~ ._2tlcNWrx89_X {
		width: 36px;
	}
}
