div._28mtpXMUpMAU {
	min-width: 240px;

	border-radius: 0;

	background: transparent;
	overflow: visible;
}

._2V8TWbzFWFd6,
._1GAaJA6XTmcU,
._3-6Qq2Q5GHR- {
	font-size: 14px;
	line-height: 100%;
}

._2KwQL8YO-2EN {
	display: flex;
	flex: 2;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	height: 100%;
}

._1ZkL4x2hiWuB {
	position: relative;

	display: flex;
	flex: 1;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

._1ZkL4x2hiWuB > span {
	display: flex;
	flex-direction: column;
}

._3nWMb3l4wwS6 {
	flex: 1;
	padding: 0 7px;

	font-size: 14px;
	font-weight: 500;

	white-space: nowrap;

	text-align: center;
}

@media screen and (max-width: 600px) {
	._3nWMb3l4wwS6 {
		font-size: 17px;
	}
}

._1ZkL4x2hiWuB:after,
._1ZkL4x2hiWuB:before {
	top: 50%;

	flex: 1;

	border-top: 1px solid var(--gray20);

	content: '';
}

._1ZkL4x2hiWuB:before {
	left: 8%;
}

._1ZkL4x2hiWuB:after {
	right: 8%;
}

.JAdcvmPR86Rn:after,
.JAdcvmPR86Rn:before {
	position: absolute;
	top: 0;
	bottom: 0;

	left: 100%;

	display: block;
	width: 8px;
	height: 8px;
	margin: auto;

	border: 1px solid var(--gray20);
	border-radius: 50%;
	content: '';
}

.JAdcvmPR86Rn:before {
	right: 100%;
	left: auto;
}

._3i1Q_v-_6PjQ {
	color: var(--gray80);
	font-weight: 600;
}

.j_CiBvpCEgim {
	color: var(--gray50);
}

._38CxmjnngDOn {
	position: relative;

	display: flex;
	flex: 1;
	flex-direction: column;

	color: var(--gray90);
	font-weight: 600;

	font-size: 29px;
	line-height: 100%;
	text-align: center;
}

._3lHvasaR7WXM {
	padding-right: 20px;
}

._3VXX292tCqzp {
	position: relative;

	padding-left: 20px;
}

._1GAaJA6XTmcU {
	position: relative;
	top: 6px;

	display: flex;
	justify-content: center;
	max-width: 80px;

	color: var(--gray80);
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
}

._3-6Qq2Q5GHR- {
	position: absolute;
	top: 22px;
	left: 50%;

	color: var(--gray50);
	text-align: center;

	transform: translate(-50%, 0);
}

._28gWD326Vd-X {
	padding-top: 4px;

	color: #808080;
	font-size: 14px;
	text-align: center;
}

._13ZAa2sOIshh,
._3CVk2UNMIDq5 {
	color: var(--error-20);
	font-size: 14px;
}

._13ZAa2sOIshh {
	white-space: nowrap;
}

._3CVk2UNMIDq5 {
	position: absolute;
	top: -10px;
	right: -17px;
}

._1S6-_BnwWmVZ {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 5px;
}

._3A7gKqvau9T2 ._3VXX292tCqzp,
._3byXoy4FKk_l ._3lHvasaR7WXM {
	color: var(--gray30);
}

._3A7gKqvau9T2 ._3nWMb3l4wwS6,
._3byXoy4FKk_l ._3nWMb3l4wwS6 {
	color: var(--gray50);
	font-weight: 400;
}

@media screen and (max-width: 320px) {
	._1S6-_BnwWmVZ {
		padding: 0;
	}
}

._6Vqc0LvTKhd5 {
	padding: 0 0 14px 0;
}

.qE0EDyvPDk91 {
	padding-bottom: 3px;
	height: 100%;
}

._3Nj-4WqncSQ5 {
	margin-top: 3px;
}

.Pv0s6J5ORiH3 {
	position: relative;
}

.Pv0s6J5ORiH3 .recommended,
.Pv0s6J5ORiH3 .selected {
	position: absolute;
	bottom: -45px;
	left: 50%;

	transform: translate(-50%, 0);
}

.Pv0s6J5ORiH3 .recommended__circle {
	color: #ff9e0c;
}

.Pv0s6J5ORiH3 .recommended__like {
	color: #ffffff;
}

.Pv0s6J5ORiH3 .selected__circle {
	color: #5fca43;
}

.Pv0s6J5ORiH3 .selected__check {
	color: #ffffff;
}

._2ZWhVdW02eaz {
	margin-top: 24px;
	display: flex;
	justify-content: center;

	text-align: center;
}

.zwGHX4k8Be83 {
	margin-top: 10px;

	font-weight: 600;
	font-size: 20px;
	line-height: 100%;
}

._1jTxyNvZITd7 {
	display: inline-block;
	margin: 10px 0;

	color: var(--base-15);
	font-size: 16px;

	cursor: pointer;
}

._3_MTKdvcAsjM {
	margin: 0 auto;
	max-width: 240px;
	width: 100%;
}

.wuKumqy-bH_Q {
	display: flex;
	flex-direction: column;
}

._2CYdYcBCoqqc {
	max-width: 30px;
	width: 100%;
	margin: 0 auto;
}

div._2zGJoz7m3vZh {
	z-index: 1502 !important;
}

@media (max-width: 319px) {
	div._28mtpXMUpMAU {
		min-width: 0;
	}

	._38CxmjnngDOn {
		font-size: 20px;
	}

	._2KwQL8YO-2EN {
		display: none;
	}
}
