

.G6IuYUExzBs- {
	padding: 32px;
}

.ICLKl_joId2S {
	margin-bottom: 20px;

	font-size: 16px;
	line-height: 1.3;
}

._3lyhIH2ZrM0Q {
	display: flex;
	align-items: center;
}

button._24gOx11n37V1 {
	flex: 1;
	margin-right: 10px;
}

button._24gOx11n37V1:last-child {
	margin-right: 0;
}

button._24gOx11n37V1._1rxB8472iCEp {
	color: var(--text-base);

	background: var(--gray05);
}

button._24gOx11n37V1._1rxB8472iCEp:hover {
	background: var(--gray10);
}
