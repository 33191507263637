.w7-8Px2oN7PM {
	max-width: 560px;
	margin: 0 auto;
	padding-top: 40px;
}

._1qlOlD-npl0W {
	display: flex;
	align-items: center;
	margin-bottom: 28px;

	color: var(--gray80);
	font-weight: 600;
	font-size: 24px;
}

._2Lmobclj0b8N {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	margin-right: 16px;

	border-radius: 50%;

	background: var(--base);
}

.bg5a5BrW5MpJ {
	display: flex;
	justify-content: center;
	margin-top: 28px;
}

button._2FZu5mFpa-Xm {
	background: var(--active);
}

button._2FZu5mFpa-Xm:hover {
	background: var(--active);
}

button._1nDkLH3EcVWK,
button._1nDkLH3EcVWK:hover {
	margin-right: 12px;

	color: var(--gray50);

	background: #ffffff;
}

@media screen and (max-width: 600px) {
	.w7-8Px2oN7PM {
		width: 100%;
		padding: 0;
	}

	._1qlOlD-npl0W {
		margin-bottom: 0;
		padding: 16px;

		border-radius: 8px 8px 0 0;

		color: #ffffff;
		font-weight: 500;
		font-size: 16px;

		background: var(--base);
	}

	._2Lmobclj0b8N {
		width: 24px;
		height: 24px;

		background: none;
	}
}
