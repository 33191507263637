a._27LKRRlbnDv1 {
	display: inline-block;
	margin-right: 20px;
	margin-left: auto;

	color: var(--base);
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-decoration: none;
}
