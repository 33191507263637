

button._1P9vhWFllk9j {
	display: flex;
	width: 32px;
	min-width: auto;
	height: 32px;
	padding: 0;

	border-radius: 50%;

	color: var(--gray30);

	background: var(--bg-fill);
}

button._1P9vhWFllk9j:hover {
	background: var(--bg-fill);
}

button._1P9vhWFllk9j:focus {
	background: var(--bg-fill);
}

button._1P9vhWFllk9j:active {
	background: var(--bg-fill);
}

button._1P9vhWFllk9j.Lm5hwvCwwU8l {
	position: absolute;
	top: 8px;
	right: 8px;
}

._11mNcGa5n5jE {
	padding: 32px;
}

.PDRi12eecHRO {
	margin-bottom: 20px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
}

._2TvJTTfME84l {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}
