.eBXEdLYYHIsl {
	position: relative;

	margin: 25px 0;
	padding: 0 16px 0 70px;

	color: #292929;
}

.eBXEdLYYHIsl:before {
	position: absolute;
	top: 50%;
	left: 0;

	width: 48px;
	height: 48px;

	border-radius: 50%;

	color: #ffffff;

	transform: translate(0, -50%);

	content: '';
}

._3miPNwBE3Ttt:before {
	background: #5fca43 url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMDAwMzkgMTEuMkwxLjgwMDM5IDYuOTk5OThMMC40MDAzOTEgOC4zOTk5OEw2LjAwMDM5IDE0TDE4LjAwMDQgMS45OTk5OEwxNi42MDA0IDAuNTk5OTc2TDYuMDAwMzkgMTEuMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
}

._2p6V939X4KcZ:before {
	display: none;
}

._3zrVzdz30rZK {
	margin: 0 0 8px;

	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
}

._3ClKzmdRNZ1j {
	margin: 0;

	font-size: 18px;
	line-height: 21px;
}

@media screen and (max-width: 600px) {
	.eBXEdLYYHIsl {
		padding: 0;
		margin: 0 0 20px 0;
	}

	._3miPNwBE3Ttt:before {
		top: 0;

		transform: translate(0, -25%);
	}

	._3zrVzdz30rZK {
		display: inline-flex;
		align-items: center;
		min-height: 48px;
		padding-left: 60px;
		margin-bottom: 12px;
	}

	._3ClKzmdRNZ1j {
		font-size: 16px;
		line-height: 19px;
	}
}

@media screen and (max-width: 463px) {
	.eBXEdLYYHIsl:before {
		transform: translate(0, 0);
	}
}
