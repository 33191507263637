.oZCf24pi1hC6 {
	position: absolute;
	left: 0;

	width: 100%;
	margin-bottom: 20px;
}

div._23XgIiKMe5xt {
	position: absolute;

	width: 100%;
	height: 4px;

	background-color: var(--results-loader-progress-background);

	transform: none;
}

div.t25Ty-Rne5tq {
	background-color: var(--results-loader-progress-color);
}

._1Gmbh46dYPo2 {
	padding-top: 40px;

	color: #9a9a9a;
	font-size: 26px;
	font-weight: 300;
	text-align: center;
}

._1jnvz_Rl0ay3 {
	position: relative;

	width: auto;
	max-width: 580px;
	margin: 40px auto 0;

	background-color: #ffffff;
}

._2ftVF8s485di {
	position: absolute;
	top: 20px;
	left: 20px;

	margin: 0;

	color: #ffffff;
	font-weight: 600;
	font-size: 42px;
}

.PpwHfIxHO2Xu {
	color: inherit;
}

._1X4rwWY9T-w- {
	padding: 28px;

	color: #414141;

	line-height: 130%;
	font-size: 17px;
}

._1X4rwWY9T-w- h2 {
	max-width: 65%;
	margin-top: 0;
	margin-bottom: 12px;

	color: #266ec3;

	font-weight: 500;

	font-size: 27px;
	line-height: 120%;
}

._2sBI1KFPHx0Z {
	position: relative;

	width: 100%;
	height: 285px;

	background-repeat: no-repeat;

	background-position: center top;
	background-size: cover;
}

._1FbswcJ-b_ry {
	position: relative;
}

._2sBI1KFPHx0Z:after {
	position: absolute;
	right: 28px;
	bottom: -30px;

	width: 64px;
	height: 64px;

	border-radius: 50%;

	box-shadow: 12px 14px 31px rgba(0, 0, 0, 0.25);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMzIiIGN5PSIzMiIgcj0iMzIiIGZpbGw9IiM1RkNBNDMiLz4KPHBhdGggZD0iTTE4LjUgMzQuNjY0NkwyNi44MzYgNDNMNDcuMDAwNSAyMiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);
	content: '';
}

@media screen and (max-width: 600px) {
	._1jnvz_Rl0ay3 {
		margin-top: 32px;

		border-radius: 5px;

		background-color: #ffffff;
		box-shadow: 0 24px 34px rgba(0, 0, 0, 0.2);
	}

	._1X4rwWY9T-w- h2 {
		max-width: 100%;

		color: var(--dark-gray);
		font-size: 19px;
	}

	._2sBI1KFPHx0Z {
		height: 192px;

		border-radius: 5px 5px 0 0;
	}

	._2sBI1KFPHx0Z:after {
		right: 16px;
		bottom: -25px;

		width: 54px;
		height: 54px;

		background-size: cover;
	}

	._1X4rwWY9T-w- {
		font-size: 15px;
		text-align: center;
		line-height: 140.62%;
		color: var(--common-gray);
	}

	._23XgIiKMe5xt {
		display: none;
	}

	._1nmTNuXT6fQw {
		display: flex;
		justify-content: center;
	}

	._3sQdeCM5ND3I {
		color: var(--promo-loader-loader-color) !important;
	}

	._1Gmbh46dYPo2 {
		padding-top: 20px;

		color: var(--promo-loader-loader-color);
		font-size: 14px;
	}

	.oZCf24pi1hC6 {
		position: absolute;
		top: 0;

		min-height: 100vh;
		min-width: 100vw;
		padding: 20px;
		margin-bottom: 0;

		background-color: var(--promo-loader-background);
	}
}
