._3pnbTIPGLzYy {
	display: flex;
	margin-top: 28px;
}

form._1diFuNGhsedG {
	padding: 12px 40px 0;
}

form > ._3pnbTIPGLzYy._1WpPHNRp95R_ {
	flex-direction: row;
	justify-content: flex-end;
}

._3pnbTIPGLzYy._1WpPHNRp95R_ > button:last-child {
	margin-left: 12px;

	background-color: var(--cabinet-action);
}

._3pnbTIPGLzYy._1WpPHNRp95R_ > button:last-child:hover {
	background-color: var(--cabinet-action--hover);
}

._3pnbTIPGLzYy ._1HECnmG9BE1n {
	color: #9a9a9a;
	font-weight: 400;

	background-color: #f9f9f9;
}

._3pnbTIPGLzYy ._1HECnmG9BE1n:hover {
	background-color: #f9f9f9;
}

._2KEWnczjt7Ll {
	display: flex;
	flex: 1;
	justify-content: space-between;
}

._2KEWnczjt7Ll > div > label {
	font-family: inherit;
}

._2KEWnczjt7Ll > div > div:before {
	border-bottom-color: #cdcdcd;
}

._2KEWnczjt7Ll > div > div:after {
	border-bottom-color: var(--cabinet-action);
}

._2KEWnczjt7Ll:first-child {
	margin-right: 10px;
}

._2KEWnczjt7Ll:last-child {
	margin-left: 10px;
}

._3r2Uqf-Q-yjW {
	max-width: 160px;
}

._2iRhWe4IfjdZ {
	align-self: center;
	height: 36px;
	width: 100%;
}

._16FH6LKiPTB4 {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 40px;
	padding: 32px 40px;

	border-radius: 0 0 4px 4px;

	background-color: #5f5f5f;
}

._16FH6LKiPTB4._2lTxULpNXdZm {
	flex-direction: column;
}

._1RQx2S_4AOip > div > div,
._1RQx2S_4AOip input {
	font-size: 17px;
	font-weight: 400;
}

._16FH6LKiPTB4._2lTxULpNXdZm > div > div > div {
	margin-bottom: 32px;
	min-width: 275px;
	flex: 0 0 48%;
}

._16FH6LKiPTB4._2lTxULpNXdZm > div > div:first-child {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

._2Jr7CDUybCp9 {
	display: flex;
	align-items: center;
	margin: -20px 0 0;

	font-size: 13px;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.5);

	cursor: pointer;
}

._16FH6LKiPTB4._2lTxULpNXdZm > div:last-child ._2Jr7CDUybCp9 {
	margin-top: 15px;
}

._16FH6LKiPTB4._2lTxULpNXdZm > div:last-child > div > div {
	margin-top: 20px;
	margin-bottom: 0;
}

li.Og4AbpShPSkN {
	font-size: 14px;
	font-weight: 500;
	font-family: inherit;
	text-transform: capitalize;
}

div.v8qpdPhF-dws {
	font-family: inherit;
}

form ._2KsgXtk2ByEj._3EDfxSv3uDUo {
	color: var(--cabinet-action);
}

.PZ9QZky9z4mI ._1RQx2S_4AOip:after {
	border-bottom: 2px solid var(--common-blue);
}

._2Jr7CDUybCp9 svg {
	margin-right: 4px;
}

._2Jr7CDUybCp9 svg path {
	fill: rgba(255, 255, 255, 0.5);
}

._1hXjo558XCJA {
	position: relative;

	width: 40px;
	height: 40px;
	margin-left: 20px;

	border-radius: 50%;

	background-color: var(--common-blue);
}

._1vHjv9LI47h7 {
	background-color: var(--common-green);
}

.KO1sTT-0eVZl {
	width: 40px;
	height: 40px;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKKSURBVHgBtZZLaxNRGIa/ialak9pag9p6KwUrgqI7K7pQoeBC0IULXQguBUE3/gD/gVtdCC5diRdciAtRXChSWy/tLohSJQqi0dJom2R83pwTiZGYTDvzwsMJme9877nNnC+wDhSG4SaaERiCflgFy2AePkMBpoMgyLfLFbQx2kEzCn2QhpQ3Svu2+fc3uI/xs0iGGGVpjsB6b1KGlzADRRIWfdxKmkHYCbsh5wfwFS4T96WtIUm2+Vl1wxw8pOMH60D03UtzFNbBT7hO34mWhnTYTHPY3PJMwwQdflkEkUMDPQZj5lbnGjme1J+nGgIzNPugCi8IehrVrDaDICjBDX7e9flPkzv3jyE6ZH5mdJi0JYoct2geQA9c/MuQEWyl0QH4Aa8sPt0EHZxhPA7+MUTbzS3la0a2YDGJXDp0V8yt3KmaIc5rzc+OgHcWs8g5Ze7EbsBrl2aod60CHR39ReqeuVmOyjDjDQuWnN6Y2749jYbfLTl9gi4YkKFe/jJrXbaERG4ZyqtX3z2dztCSl7xqrjpBFU7QCktI/jLQoSnJsGTuNlhuyWnEG+ZlqKtEy9pjyUmfzXSjoU5pzpJT/QZ6lOIEzZq790J/Y8Qqch6nUYlSwGu8/i3VS69lHSAgZTGJXNqmc+Zmd1X/1ZLjrFpkzj/ot/h0HraYm93t5tF0wbCnz5YoclyAPIzDxlZBWRhSqQGrIYjoY77fJXgPb2GsXYduGATtZy7KB4HY/fAcPsIUnGiOaVUmai/X+Od6ZXQpq+hdYC+qDXGqFLRHB+AsZH28qoYzxM5YFGl20OuXKaM61O91AFWYhxLMQhEm4eT/cna0R37GIvRUPKpXVN4/hjuN5WAr/QakWztb10DkIQAAAABJRU5ErkJggg==) center no-repeat;

	animation: _2XwSZjoPrNdu 0.9s infinite linear;

	pointer-events: none;
}

.-kbPOR3EqwwC {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
}

._2QuSX6dOtA8u {
	width: 100%;
}

@keyframes _2XwSZjoPrNdu {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}

@media screen and (max-width: 600px) {
	._2m5lLo_gCP00 {
		margin: 27px 20px 7px;

		font-size: 22px;
		font-weight: 500;
	}

	._2iRhWe4IfjdZ {
		margin-left: 20px;
	}

	._3pnbTIPGLzYy {
		flex-direction: column;
		margin-top: 0;
	}

	form ._3pnbTIPGLzYy._1WpPHNRp95R_.o-xzCLAPsj12 {
		justify-content: space-between;
		flex-direction: row;
	}

	form ._3pnbTIPGLzYy._1WpPHNRp95R_.o-xzCLAPsj12 > button {
		margin: 0;
	}

	form._1diFuNGhsedG {
		padding: 0 20px;
	}

	form > ._3pnbTIPGLzYy._1WpPHNRp95R_ {
		margin-top: 20px;
	}

	form > ._3pnbTIPGLzYy._1WpPHNRp95R_ > button:last-child {
		margin-left: auto;
	}

	._2KEWnczjt7Ll {
		width: 100%;
		min-width: auto;
		margin-top: 20px;
	}

	._16FH6LKiPTB4 {
		margin-top: 28px;
		padding: 0 15px 20px;
	}

	._16FH6LKiPTB4 > div {
		width: 100%;
	}

	._16FH6LKiPTB4 > div > div {
		display: flex;
	}

	._16FH6LKiPTB4 > div > div > div {
		margin-top: 20px;
		width: 100%;
		max-width: 370px;
	}

	._2KEWnczjt7Ll:first-child {
		margin-right: 0;
	}

	._2KEWnczjt7Ll:last-child {
		margin-left: 0;
	}

	._16FH6LKiPTB4._2lTxULpNXdZm > div > div > div {
		margin-bottom: 0;
		flex: 1 0 100%;
	}

	._2Jr7CDUybCp9 {
		margin: 20px 0 0;
	}
}
