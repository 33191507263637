

._1_ejeDx-eTep {
	position: fixed;
	bottom: 0;
	z-index: 90;

	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 5px 10px;

	background: var(--dark-orange);
}

button._3cGq48tq6CCi {
	height: 36px;
	min-width: 0;
	padding: 5px 10px;

	line-height: 26px;
	font-size: 17px;
	font-family: inherit;
	color: #ffffff;
	text-transform: none;
}

button._16ZMkuxsJ13F {
	font-weight: 400;
}

button._2iim2qkpAN6c {
	margin-left: auto;

	font-weight: 500;
}
