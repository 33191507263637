.rz2tony5J0Dx {
	padding: 0 50px 40px;
}

._32A7UZVJYrOR {
	display: flex;
	align-items: flex-end;
	height: 50px;
	padding: 0;
}

._32A7UZVJYrOR ._3YSU5FV5JkBA {
	margin-left: 20px;
}

._32A7UZVJYrOR ._3UtZZXVR-TzM {
	opacity: 0.5;
}

._32A7UZVJYrOR label._2Mh3kmbDHSSa {
	color: #f44336;
}

._2DTJu14AZPoz > p {
	position: absolute;
	bottom: -20px;

	font-family: inherit;
}

._3_hVr9lAonpy {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-top: 40px;
	padding: 0;
}

._2WslLc5HW_Dg {
	display: flex;
	align-items: center;
}

.jprvMnQVlvF1 {
	color: var(--common-gray);
	font-size: 15px;
}

.yoSrex0OoeHX {
	display: flex;
	justify-content: space-between;

	margin-left: 12px;
}

._2IBx7XMFyWM4 {
	display: var(--login-alternative-display);

	color: var(--common-blue);
	font-size: 15px;
}

._2140jskxQUke {
	width: 100%;
}

@media screen and (max-width: 600px) {
	._32A7UZVJYrOR {
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		height: auto;
	}

	.rz2tony5J0Dx {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 65vh;
		padding: 100px 0;
	}

	._2vFtgY4isPdk {
		height: auto;
		padding: 0;
	}

	._32A7UZVJYrOR ._3YSU5FV5JkBA {
		margin-left: 0;
		margin-top: 32px;
		width: 100%;

		border-radius: 4px;
	}

	._2WslLc5HW_Dg {
		display: block;
		margin-top: 32px;
	}

	.yoSrex0OoeHX {
		margin: 12px 0 12px 0;
	}

	.jprvMnQVlvF1 {
		margin-bottom: 12px;

		color: #9a9a9a;
	}

	._3_hVr9lAonpy {
		flex-direction: column;
		align-items: flex-start;
	}

	._2IBx7XMFyWM4 {
		margin-top: 50px;
	}
}
