

._2c9R-1RRNiW9 {
	position: relative;

	display: inline-flex;
	justify-content: flex-end;
}

div._2c9R-1RRNiW9 {
	flex-grow: 0;
}

._2KHXG5cWe4DI {
	position: relative;

	height: 44px;
	width: 56px;
	padding-top: 4px;
	padding-bottom: 2px;

	border: 1px solid var(--gray10);

	color: var(--gray30);

	background: var(--gray05);

	cursor: pointer;
}

._2KHXG5cWe4DI + ._2KHXG5cWe4DI {
	margin-left: 3px;
}

._2KHXG5cWe4DI input {
	position: absolute;

	opacity: 0;
}

._1iFw1qYtzXE4 {
	z-index: 1;

	border-color: var(--base50);

	color: var(--base);

	background: var(--base80);
}

._2KHXG5cWe4DI:first-of-type {
	margin-right: -1px;
	padding-left: 5px;

	border-radius: 22px 0 0 22px;
}

._2KHXG5cWe4DI:last-of-type {
	padding-right: 5px;

	border-radius: 0 22px 22px 0;
}

._2KHXG5cWe4DI svg {
	position: absolute;
	top: 9px;

	display: block;
}

._2KHXG5cWe4DI:first-of-type svg {
	right: 15px;
}

._2KHXG5cWe4DI:last-of-type svg {
	left: 15px;
}

._11kelx1a-NK_ {
	position: absolute;
	bottom: 0;

	width: 100%;
	max-width: 130px;

	margin: 0;

	color: #f44336;

	font-size: 0.75rem;
	line-height: 0.75rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;

	transform: translateY(140%) translateX(0);
	overflow: hidden;
}

._358zpA0x3zPE {
	border-color: #f44336;
}

._2c9R-1RRNiW9._1VrtU13JjPVw ._2KHXG5cWe4DI {
	cursor: default;
}
