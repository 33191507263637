

._2XvDMD8rI-gD:not(.XnZbOdSrOIPw) {
	order: 2;
}

._2UHM0BPIeB1- {
	margin-right: 10px;
	padding-right: 10px;

	border-right: 1px solid var(--line-separator);

	color: var(--text-link);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
}

._2UHM0BPIeB1-:last-child {
	margin-right: 0;
	padding-right: 0;

	border-right: none;
}
