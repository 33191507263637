.Tr4-KHcPXoXp {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;

	background: #ffffff;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.Tr4-KHcPXoXp:first-of-type {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.Tr4-KHcPXoXp:last-of-type {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.Tr4-KHcPXoXp:not(:last-of-type) {
	border-bottom: 1px dashed #d9d9d9;
}

.rtrPTCIwjsNV {
	display: flex;
	align-items: center;
	width: 33%;
	min-width: 150px;
	padding-right: 20px;

	color: #1d1d1d;
	font-weight: 600;

	font-size: 21px;
	line-height: 25px;
	overflow: hidden;
}

._1dGQKiaF3gRd {
	display: flex;
	align-items: center;
	margin-right: 20px;
}

._2WIueiEuyQIt {
	width: 100%;

	text-overflow: ellipsis;
	overflow: hidden;

	text-transform: capitalize;
}

.cZXrasefpAna {
	display: flex;
	align-items: center;
}

._1TmVouSUkx1o {
	display: flex;
	align-items: center;
}

._1tJLZE9L9JZs {
	position: relative;
}

.cZXrasefpAna,
._1TmVouSUkx1o {
	flex: 1;
}

._3VYF0m5oeogx {
	display: flex;
	height: 100%;
	align-items: flex-start;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 0;

	font-size: 14px;
	line-height: 20px;
	color: #777777;
}

.zmHJ2pwPFB-H:not(:last-child) {
	margin-bottom: 2px;
}

.zmHJ2pwPFB-H span:first-child {
	margin-right: 5px;
}

._3VYF0m5oeogx:not(:last-child) {
	margin-right: 28px;
	padding-right: 28px;

	border-right: 1px solid #d9d9d9;
}

._1NtMyPz1ey7o {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 8px;
	padding: 5px;

	color: #dadada;

	cursor: pointer;

	transition: color 0.15s ease-in-out;
}

._1NtMyPz1ey7o:hover {
	color: #595959;
}

._1NtMyPz1ey7o span._32IvSx7LksWG {
	color: var(--cabinet-action);
}

@media screen and (max-width: 600px) {
	.Tr4-KHcPXoXp {
		position: relative;

		flex-direction: column;
		align-items: flex-start;
		margin: 0;
		padding: 20px 22px;
	}

	.Tr4-KHcPXoXp:first-of-type {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	.Tr4-KHcPXoXp:last-of-type {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.cZXrasefpAna,
	._1TmVouSUkx1o,
	._3VYF0m5oeogx,
	.zmHJ2pwPFB-H {
		width: 100%;
	}

	.rtrPTCIwjsNV {
		width: 100%;
		margin-bottom: 12px;

		padding-right: 0;

		font-size: 18px;
	}

	._1TmVouSUkx1o {
		flex-direction: column;
		align-items: flex-start;
	}

	._3VYF0m5oeogx:not(:last-child) {
		margin-right: 0;
		padding-right: 0;

		border-right: none;
	}

	.zmHJ2pwPFB-H {
		display: flex;
		justify-content: space-between;
		margin-bottom: 4px;
	}

	.zmHJ2pwPFB-H:not(:last-child) {
		margin-bottom: 5px;
	}

	.zmHJ2pwPFB-H > span {
		line-height: 22px;
	}

	.zmHJ2pwPFB-H > span:first-child {
		display: flex;
		flex: 3;
		margin-right: 6px;
	}

	.zmHJ2pwPFB-H > span:last-child {
		display: flex;
		flex: 2;
		margin-left: 6px;

		color: #313131;
	}

	._1NtMyPz1ey7o {
		position: absolute;
		right: 15px;
		top: 15px;
	}

	._1NtMyPz1ey7o:active {
		color: #9a9a9a;
	}
}
