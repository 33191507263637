

._3Dn-sUuKlqDv {
	margin: 0 auto;
	max-width: 1100px;
}

._1cJzgjwS0LQV {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 64px;
	padding: 20px;

	color: var(--white);
	font-weight: 500;

	background: var(--base);
}

button.eX5-LuZ01Tnv,
button._2kx0MIdZHktv {
	padding: 0 5px;
	min-width: auto;
}

button._2kx0MIdZHktv {
	margin-left: 15px;
}

._2kx0MIdZHktv > svg {
	transform: rotate(90deg);
}

._2kx0MIdZHktv._3o39W2tXcLbe > svg {
	transform: rotate(-90deg);
}
