

.ItGzdH5KTTlo {
	position: relative;

	width: 100%;
	padding-right: 30px;
	padding-left: 28px;
}

.yOS3h-r2Kl5w {
	max-width: 100%;
	width: 100%;
	min-width: 0;
	overflow: hidden;
}

._1ddD0ZSoH_If {
	position: relative;
	left: 0;

	display: inline-flex;
	min-width: 100%;
}

._2HvWv_A5uAJW {
	transition: 0.3s left ease-in;
}

._1qpjZ9M2UJyk {
	display: flex;
	min-width: 100%;
}

._2gjje4AFFUWF:after {
	content: '';

	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.MpjZalfQ7IkU,
._2zKw2bNJBMAq {
	position: absolute;
	top: 0;

	width: 26px;
	height: 100%;

	cursor: pointer;
	background: #cccccc;
}

.MpjZalfQ7IkU {
	position: absolute;
	right: 0;
}

._2brHKhfJQRpG {
	display: none;
}

.MpjZalfQ7IkU:after {
	transform: rotate(-180deg);
}

._2zKw2bNJBMAq {
	position: absolute;
	left: 0;
}

._3pvschxJyHrV {
	opacity: 0.5;
	cursor: default;
}
