

.hXRUiWRoZiiY > svg {
	width: 70%;
	height: 70%;

	transform: translateY(-20%);
}

._17uCqAwnHydT {
	display: flex;
	flex-direction: column;
}

._3KRxpyR6PV53 {
	margin-bottom: 16px;
}

._3KRxpyR6PV53:last-child {
	margin-bottom: 10px;
}
