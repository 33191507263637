._1T_WvZYlxmH2 {
	padding: 40px;

	color: var(--gray80);
}

._Esypq0Z818- {
	font-weight: 600;
	font-size: 30px;
}

._2bvCAdtJL-ir {
	margin-top: 40px;
	margin-bottom: 20px;

	font-size: 17px;
}

._2bvCAdtJL-ir span {
	font-weight: 600;
}

._1lE30InL8Yda {
	display: flex;
	align-items: center;
	margin-top: 20px;
	padding: 12px;

	border-radius: 4px;

	color: var(--error-20);
	font-size: 16px;

	background: var(--error90);
}

._1lE30InL8Yda svg {
	margin-right: 12px;
	flex-shrink: 0;
}

._3mNkEizgoULN {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 15px;
}

._3mNkEizgoULN a {
	text-decoration: none;
}

._3XKD5jZGF7wK {
	display: flex;
	align-items: center;
}

._34yLxF5IyiGl {
	margin-left: 20px;
	padding-left: 20px;

	border-left: 1px solid var(--gray20);

	color: #292929;
	font-size: 14px;
}

._3xgKZIB3SiL7 {
	justify-content: flex-start;

	color: #292929;
	font-size: 16px;
}

._1iek53qCijnU {
	display: flex;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 30px;

	font-size: 17px;
	line-height: 140%;
}

._1iek53qCijnU svg {
	margin-right: 12px;
	flex-shrink: 0;
}

div.hbqYYjZlf-NP:not(:first-child) {
	margin-top: 25px;
}

@media screen and (max-width: 600px) {
	._3mNkEizgoULN {
		flex-direction: column;
		align-items: flex-start;
	}

	._3mNkEizgoULN button {
		margin-top: 20px;
		height: 49px;
		width: 100%;

		border-radius: 4px;
	}
}
