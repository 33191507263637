

.QsBbCMw9bJXP:last-child {
	margin-bottom: 16px;
}

._1aoF6ArilGZE {
	margin: 0;

	transform: translateY(16px);
}

._26xhOg66pV6I {
	padding: 0 16px 16px 16px;
}
