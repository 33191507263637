

label.CKCZUmyXbW3x {
	display: flex;
	margin-right: 0;
	margin-left: 0;
}

span._1om14Fr9QHWj {
	display: flex;
	align-items: center;
	width: 100%;

	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
}

.iZLH7AltAKC2 {
	display: none;
}

._3VWXo33S_p1S {
	display: flex;
	align-items: center;
	margin-right: 10px;
	width: 20px;
	height: 20px;
}

.rcMGSnqGUzmr {
	color: var(--base);
}

.rvXp4c0O3Ltb {
	text-decoration: none;
}

._2tyOJ8kfLEva {
	border-radius: 50%;

	color: #ffffff;

	background: var(--success);
}

._2tyOJ8kfLEva > svg {
	transform: scale(0.7) translateY(-1px);
}

._3UH3-KVZUaOB {
	margin-left: auto;
	display: flex;

	color: #dadada;
}
