.XnyNdDrukqMs {

	padding: 60px 0;
}

@media screen and (max-width: 600px) {
	.XnyNdDrukqMs {
		position: relative;

		padding-top: 40px;
	}
}

._3BnoklanZDt7 {
	width: 200px;
	height: 24px;
	margin-left: 15px;

	border-radius: 4px;

	background: rgba(205, 205, 205, 0.5);
}

._1GrdPUrHkmqS {

	padding-right: 28px;
}

@media screen and (max-width: 600px) {
	._1GrdPUrHkmqS {
		padding: 0;
	}
}

._9QzdavyU2aML {
	position: relative;

	height: 100%;
	overflow: hidden;
}

._9QzdavyU2aML:after {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;

	width: 100%;

	transform: translateX(100%);
	opacity: 0.7;
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.8) 50%,
		rgba(128, 186, 232, 0) 99%,
		rgba(125, 185, 232, 0) 100%
	);

	animation: _1pW3jOIqCSAC 1s infinite 0.5s;
	content: '';
}

._20YNQyHw_Koa {

	margin-top: 15px;
	margin-left: 100px;
}

@media screen and (max-width: 600px) {
	._20YNQyHw_Koa {
		height: 100%;
	}
}

._6ANR8ID5-Gwa {

	cursor: default;
}

._1NA637ftcMhO {
	height: 100px;

	cursor: default;
}

@media screen and (max-width: 600px) {
	._1NA637ftcMhO {
		height: 100%;
	}
}

.rVVc8UEN5SCw {

	height: 90%;

	opacity: 1;
	background: rgba(205, 205, 205, 0.5);
}

._6ANR8ID5-Gwa:hover .rVVc8UEN5SCw {
	opacity: 1;
	background: rgba(205, 205, 205, 0.5);
}

._1cq-_dUME9gs {
	right: 0;
	bottom: -23px;
	left: 0;

	width: 14px;
	height: 14px;
	margin: auto;

	border-radius: 4px;

	opacity: 0.5;
	background: rgba(205, 205, 205, 0.5);
}

._16JYerPs7ERg {

	left: 70px;
}

._2VEj5jZicx9a {
}

._1HUo9HVQZzps {
	top: -8px;

	width: 50px;
	height: 15px;

	border-radius: 15px;

	background: rgba(205, 205, 205, 0.5);
}

@keyframes _1pW3jOIqCSAC {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}
