.c4DDrsmSSyqA {
	max-width: 360px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	border-radius: var(--service-border-radius);

	background-color: #ffffff;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.07);
}

._2ZyO8bhxtMk7 {
	width: 100%;
	min-height: 210px;
	max-width: none;

	flex-direction: row-reverse;
}

.c4DDrsmSSyqA._2R8aHRLkR1es {
	width: 100%;
	max-width: 350px;
	min-height: 410px;
}

._2q1vTObIAPCc {
	width: 100%;
	height: 180px;

	border-radius: var(--order-header-border-radius);

	background-repeat: no-repeat;
	background-position: right center;
	background-size: cover;
}

._2R8aHRLkR1es ._2q1vTObIAPCc {
	height: 143px;
}

._2ZyO8bhxtMk7 ._2q1vTObIAPCc {
	width: 450px;
	height: auto;
	flex-shrink: 0;

	border-radius: 0 4px 4px 0;

	background-size: cover;
}

.iWaRJHIfmNNt {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

._2ckAksx2OK5a {
	padding: 20px 24px;
	flex-grow: 1;
}

._2R8aHRLkR1es ._2ckAksx2OK5a {
	padding: 22px 24px 22px;

	border-bottom: 1px dashed #d9d9d9;
}

.lQ9e-ZOKekMW {
	margin: 0 0 24px 0;

	color: var(--dark-gray);
	font-size: 20px;
	font-weight: 600;
	line-height: 23px;
}

._2R8aHRLkR1es .lQ9e-ZOKekMW {
	margin-bottom: 12px;

	font-size: 24px;
	font-weight: 500;
	line-height: 150%;
}

._2_uqH9_FmuIw {
	font-size: 15px;
	font-weight: 400;
	line-height: 23px;
	text-align: justify;
}

@media screen and (max-width: 600px) {
	.c4DDrsmSSyqA {
		max-width: unset;
		overflow: hidden;

		border-radius: 8px 8px 0 0;
	}

	.c4DDrsmSSyqA._2R8aHRLkR1es {
		max-width: unset;
	}
}
