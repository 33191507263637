

._3pryQGvGs4jH {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999999;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
}

._1cptU50yfhF5 {
	background-color: #eff1f4;
}

._37102Y4sT7vj {
	position: relative;

	flex-basis: 917px;

	border-radius: 5px;

	background-color: #ffffff;
}

._1cptU50yfhF5 ._37102Y4sT7vj {
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.21);
}

._3FXGIyjZfD5E {
	margin: 104px 0 100px;

	text-align: center;
}

._2m8CK0i_rhAk {
	width: 100%;
	height: 4px;
}

._1-PTz8ejYb0B {
	position: absolute;

	width: 100%;
	height: 4px;

	background-color: #2196f3;
	background-image: repeating-linear-gradient(135deg, #90caf9, #90caf9 20px, #2196f3 0, #2196f3 40px);
	background-size: 228px 32px;

	transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);

	animation: _2aY2m2Q2GE8K 0.5s infinite linear;
}

.x3P5h5-rZdhe {
	margin: 48px 0 134px;

	text-align: center;
}

.BVUZZWTxAXz2 {
	margin-bottom: 62px;
}

._2M1X79qU-DBS {
	margin: 0;
}

._1cptU50yfhF5 ._2M1X79qU-DBS {
	color: #79909b;
	font-family: inherit;
	font-size: 12px;
	font-weight: 600;
	line-height: 27px;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

button._1tWG8BHo3VSR {
	margin-top: 24px;
}

._1cptU50yfhF5 ._1tWG8BHo3VSR {
	border-radius: 5px;

	font-size: 14px;
	letter-spacing: 0.03em;

	background: #2b98f0;
}

@keyframes ghJYK66VErt3 {
	0% {
		width: 0;
	}

	to {
		width: 100%;
	}
}

@keyframes _2aY2m2Q2GE8K {
	0% {
		background-position: 0 0;
	}

	100% {
		background-position: -56px 0;
	}
}
