._1vSkbDmGLYnU {
	display: flex;
}

button.igdO013uD4F1 {
	height: 36px;
	width: 36px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50%;
}

button._38R2mHZkEig1 {
	flex-grow: 1;
	width: auto;
	height: 48px;

	border-radius: 4px;
}

button.igdO013uD4F1:not(:last-of-type) {
	margin-right: 12px;
}

@media screen and (max-width: 600px) {
	button.igdO013uD4F1 {
		width: 44px;
		height: 44px;
	}

	button._38R2mHZkEig1 {
		flex-grow: 1;
		width: auto;
		height: 48px;

		border-radius: 4px;
	}

	button.igdO013uD4F1:not(:last-of-type) {
		margin-right: 16px;
	}

	button._38R2mHZkEig1:not(:last-of-type) {
		margin-right: 14px;
	}
}

button._1CmrBUW1rhEV {
	color: #ffffff;
}

button._12rdJ88Gunlw {
	border: 1px solid #d9d9d9;

	background-color: #ffffff;
}

button._3I7Yc9nbTfaz {
	background-color: #3c5b96;
}

button._3l8FpDux0W6i {
	background-color: #59adeb;
}
