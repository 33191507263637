

div.fO61BtRc7DP9 {
	border-radius: 0;

	box-shadow: none;
}

div.fO61BtRc7DP9:first-child {
	border-radius: 0;
}

div.fO61BtRc7DP9._16uY3IKyGXiA {
	margin: 5px 0;
}

div._1dgq7YAcM1ZX {
	padding: 0;
}

.l640n0elLnod {
	background: var(--line-separator);
}

div._35S6n7ONlPnc {
	display: flex;
	width: 100%;

	border-bottom: 1px solid var(--line-separator);
}

div._14k1z3FInqVK {
	font-weight: 700;
}

div._3lvogt75UVXo {
	margin: 0;
}

div._3lvogt75UVXo.NUNOlWC86Lgw {
	margin: 0;
}

div._3S-inYAaTcT- {
	padding: 0;
	display: block;
}

div.alIQk81JhZZ- {
	color: #ffffff;
	font-weight: 700;
	font-size: 14px;

	background: var(--base);
}
