._3KarheJR67S5 {
	display: grid;

	grid-column-gap: 32px;
	grid-template:
		'icon title' min-content
		'icon description' min-content
		/ 76px 1fr;

	padding: 20px 28px;
}

._3sJZMLc8Om8T {
	grid-area: icon;
}

._3PCzWUSefgr- {
	grid-area: title;

	font-size: 20px;
	font-weight: 700;
	line-height: 26px;
	color: var(--gray80);
}

._2DG2UqcnB-vk {
	position: relative;
	top: 6px;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	margin-right: 12px;

	border-radius: 50%;

	color: #ffffff;

	background: var(--success);
}

._2DG2UqcnB-vk svg {
	transform: scale(0.6);
}

.QY_BGEOBmWLO {
	grid-area: description;

	white-space: pre-line;

	font-size: 16px;
	line-height: 21px;
	color: #595959;
}

@media screen and (max-width: 600px), (max-width: 768px) {
	._3KarheJR67S5 {
		grid-template-areas: 'icon title' 'description description';
		grid-gap: 18px 20px;
		grid-template-columns: 56px 1fr;

		align-items: center;

		padding: 22px 20px 27px;
	}

	._3sJZMLc8Om8T svg {
		width: 56px;
		height: 56px;
	}

	._3PCzWUSefgr- {
		font-size: 19px;
		line-height: 130%;
	}

	.QY_BGEOBmWLO {
		font-size: 15px;
		line-height: 140%;
	}
}
