

._1YAWAbRLH2Pt {
	padding: 48px 20px 28px 20px;
	min-height: 196px;
}

button._46FJBbWVddj7 {
	width: 40px;
	min-width: 40px;
	height: 40px;

	margin-right: 12px;
	padding: 0;

	color: #ffffff;
}

/* loading */
div._46FJBbWVddj7 {
	margin-right: 12px;

	background: var(--gray50);
}

button._2-qr5Qqozbox {
	border-radius: 50%;

	background: var(--selected2);
}

button._1QFJ55LAEbOn {
	border-radius: 50%;

	background: var(--active30);
}

._1QFJ55LAEbOn svg {
	position: relative;
	top: -1px;
}

button._3PJKhaCdpSdn {
	border-radius: 50%;
}

._23HdfONYve40 {
	margin-top: 25px;
	display: flex;
	align-items: center;
}

._3AuQv9UHGsAZ {
	color: var(--gray50);
}

.e2bwze8e_UMw {
	color: var(--active);
}
