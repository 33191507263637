

._1D81vYWnhnld {
	display: flex;
}

._3tzCcVVsPGq_ {
	position: relative;

	flex-grow: 1;

	color: var(--results-flight-info-header-primary-color);
	overflow: hidden;
}

.ANpD4RimLpZr {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 20px;
}

._3tzCcVVsPGq_:after {
	width: 100%;
}

._2WpjlxUnYWej {
	flex-basis: 100px;
}

._2WpjlxUnYWej:first-of-type {
	flex-basis: var(--results-flight-left-col-flex-basis);
}

._2WpjlxUnYWej._29Ah8kFAo9S_._3bHP3L8j__bn {
	flex-basis: 300px;
}

._2WpjlxUnYWej:last-of-type {
	flex-basis: 170px;
}

._1-s_I-KoZNYB {
	line-height: 25px;
	font-size: 20px;
	white-space: nowrap;
}

._33uukIPxuz60 {
	flex-basis: 100px;
}

.GMQhTTHXZT4Y {
	color: var(--results-flight-info-header-secondary-color);
	line-height: 21px;
	font-size: 14px;
	white-space: nowrap;
}

._1yDtV4RK5ykL {
	position: absolute;
	top: 0;
	left: 20px;
}

._1on5n9Hckana {
	cursor: pointer;

	transition: background-color 0.15s;
}

._1on5n9Hckana:after {
	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 4px;

	opacity: 0;
	background-color: var(--results-flight-header-underline-background);

	transition: opacity 0.15s;

	pointer-events: none;
	content: '';
}

._2KLVMSgcsKZJ:after,
._1on5n9Hckana:hover:after {
	opacity: 1;
}

._2b1gjWL_u_JX {
	position: relative;

	font-weight: 600;
	font-size: 22px;
}

._2b1gjWL_u_JX._1FIAJkAzS_1x {
	font-size: 20px;
}

.bcbGOImXUiaw {
	margin: 0 5px;

	font-weight: 600;
	line-height: 28px;
	font-size: 22px;
}

._2dlWOc4wEu-G {
	position: absolute;
	top: 0;

	color: var(--dark-red);
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-align: right;

	transform: translate(3px, -25%);
}

._1QfMzkCrFqRa {
	position: relative;

	display: inline;
}

._1QfMzkCrFqRa:after {
	position: absolute;
	top: 50%;
	right: 0;

	display: block;

	width: 10px;
	height: 20px;

	transform: translate(15px, -50%);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDEwIDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+Cgk8cGF0aCBkPSJNMCAwTDUgNUwxMCAwTDAgMFoiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==) center no-repeat;

	transition: transform 0.15s;
	content: '';
}

._1zqE6HQqo810:after {
	transform: translate(15px, -50%) rotate(-180deg);
}

._2v5Lsk0kJfvd {
	display: flex;
}

.CUdjX6oO8gsc {
	height: 100%;
}

._3bHP3L8j__bn {
	display: flex;
	align-items: flex-end;
}

._3bHP3L8j__bn > div:first-child {
	font-weight: 600;
	line-height: 25px;
}

._29Ah8kFAo9S_ > div:last-child {
	margin-left: 30px;
}

._1YGJjV_cY_oE {
	flex-basis: 200px;
}

._1YGJjV_cY_oE > div:last-child {
	margin-left: 12px;
}

._3bHP3L8j__bn.hkfGpnhK9bFA {
	flex-basis: 300px;
	display: flex;
	justify-content: space-between;
	margin-right: 20px;
}

._3bHP3L8j__bn.hkfGpnhK9bFA > div:last-child {
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 12px;
}
