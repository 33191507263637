

._18ehPzh6O1xX {
	padding: 20px 24px;
	display: flex;
	align-items: center;
}

._3Qy1PQLSTSx9 {
	display: inline-flex;
	align-items: center;
	height: 48px;

	color: var(--offer-social-buttons-color);
}

._3Qy1PQLSTSx9:not(:last-of-type) {
	margin-right: 12px;
}
