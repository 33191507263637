._3d5g-KMEQ79m {
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	overflow: hidden;

	border-radius: var(--payment-wrapper-border-radius);

	background: #ffffff;
	box-shadow: 2px 1px 40px rgba(0, 0, 0, 0.03);
}

._3d5g-KMEQ79m.nDc9pYpGpr6P {
	flex-direction: row-reverse;
}

._3-60iIVoeb-6 {
	flex-shrink: 0;
	flex-basis: 300px;
	min-height: 400px;
	padding: 28px 40px;

	background-color: #f4f4f4;
}

.TY68SSS36RKj {
	margin: 0;

	font-size: 24px;
	line-height: 40px;
}

._2D5N4w0QvznR {
	margin: 40px 0 0;
	padding: 0;
}

button._3P7wimPJJPyY {
	width: 100%;
	margin-top: 24px;

	border-radius: 24px;
}

._2kkZI0aBCVAr {
	flex-grow: 1;
	padding: 28px 40px;
}

._2Ths3ltxD30u {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 24px 0;

	font-weight: 500;
	font-size: 20px;
	line-height: 40px;
	color: var(--common-gray);
}

.nDc9pYpGpr6P ._2Ths3ltxD30u {
	color: var(--dark-gray);
	font-size: 26px;
	line-height: 150%;
}

._1Rg7_hldLaNT {
	font-weight: 400;
	font-size: 12px;
}

._3gtMVWRFoxWZ {
	margin: 0;
	padding: 0;
}

.yaPBUd2-NqNm {
	display: none;
}

._3EGpmIAB4kge {
	margin: 0 0 40px;

	font-size: 15px;
	line-height: 139.45%;
	color: var(--common-gray);
}

._2suOVO5tv3Av {
	display: flex;
	align-items: center;
}

._8K_W3Nyzs58u {
	height: 28px;
}

._2yB1gL9t_5B3 {
	height: 22px;
}

._2trsVR2qAR6W {
	margin-left: 10px;
	height: 17px;
}

._3CAKPHGThFUz {
	margin-left: 13px;
	height: 16px;
}

._172yAJlui1lf {
	height: 20px;

	transform: translateY(4px);
}

._3VdxYrP9QQJw {
	height: 18px;
}

@media screen and (max-width: 600px) {
	._3d5g-KMEQ79m {
		display: flex;
		flex-direction: column;
		padding: var(--payment-wrapper-padding) 0 0 0;

		background: unset;
	}

	.nDc9pYpGpr6P._3d5g-KMEQ79m {
		flex-direction: column;
	}

	._3-60iIVoeb-6 {
		min-height: auto;
		flex-basis: unset;
		padding: 4px 20px 24px;

		background: unset;
	}

	.TY68SSS36RKj {
		text-align: right;

		line-height: 150%;
	}

	._2D5N4w0QvznR {
		margin: 20px 0 0;
	}

	._2kkZI0aBCVAr {
		padding: 20px;

		border-radius: 8px 8px 0 0;

		background: #ffffff;
	}

	._2Ths3ltxD30u {
		justify-content: flex-start;

		font-size: 17px;
		font-weight: 500;
		line-height: 150%;
		color: #292929;
	}

	.nDc9pYpGpr6P ._2Ths3ltxD30u {
		justify-content: space-between;
	}

	._1Rg7_hldLaNT {
		display: flex;
		margin-right: 16px;

		line-height: 25px;

		transform: rotate(180deg);
	}

	._2suOVO5tv3Av {
		margin-bottom: 15px;
	}

	.iVKW2KLEgti1 {
		position: absolute;

		margin-bottom: 0;
	}

	._8K_W3Nyzs58u {
		position: static;
	}
}
