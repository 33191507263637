._33yCCg3yDlHV {
	max-width: 1100px;
	margin: 0 auto;
	padding-bottom: 100px;
}

h3.eIZPB6SMS6Rg {
	margin-top: 29px;
	margin-bottom: 9px;

	font-size: 24px;
	line-height: 28px;
}

._3yaAnUBEVxHn {
	margin-top: 12px;
}

._2_HzrfurKqwD {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	height: 80px;
	padding: 0 calc((100vw - 1100px) / 2);

	background-color: #ffffff;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

button._3uvATGtvzHl- {
	background-color: var(--common-orange);
}

button._3uvATGtvzHl-:hover {
	background-color: var(--dark-orange);
}

button._2Aivc-kivdEd {
	background-color: var(--light-gray);
}

._21qYwzyzN4lc {
	margin-top: 10px;
}

._1WqOBMpy3oel {
	display: flex;
	margin-top: 12px;
}

._28bgxFSpEWJ0 {
	display: flex;
	align-items: center;
	margin-bottom: 25px;

	color: #c21212;
	font-size: 18px;
}

._2XaSrQh6jjxd {
	display: flex;
	margin-right: 8px;
}

._2JQN2skvMzX8 {
	display: flex;
	align-items: center;
}

._3saXB_wRp2ey {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 28px;
}

._2m7zI3Aac9rf {
	display: flex;
	margin-bottom: 20px;

	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
	text-align: center;

	color: var(--dark-gray);
}

._3rPICdhQOf6b {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media screen and (max-width: 600px) {
	.eIZPB6SMS6Rg {
		margin-top: 0;
		margin-bottom: 20px;
	}

	._33yCCg3yDlHV {
		padding-bottom: 60px;
	}

	._1WqOBMpy3oel {
		flex-direction: column;
	}

	._1j8kfER6SDBL {
		padding: 20px;

		background: #ffffff;
	}

	._3yaAnUBEVxHn {
		margin: 0;
		padding: 20px;
	}

	._2_HzrfurKqwD {
		height: auto;

		box-shadow: none;
	}

	button._3uvATGtvzHl- {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;

		width: 100%;
		height: 60px;

		border-radius: 0;

		font-size: 17px;
	}
}
