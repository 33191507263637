.HEqJWIKn-4Vj {
	display: flex;
	margin: 48px 0 0 20px;
	align-items: center;
}

.HEqJWIKn-4Vj h1 {
	margin: 0;
}

.HEqJWIKn-4Vj span {
	margin-right: 12px;
}

.JQhVkfUzs8Vs {
	margin: 28px 48px 32px 56px;
}

.JQhVkfUzs8Vs form {
	display: flex;
	flex-direction: column;
}

.JQhVkfUzs8Vs form label {
	font-family: inherit;
}

.JQhVkfUzs8Vs form > div {
	margin-bottom: 20px;
}

.qAqnGpY86VzH > div > div,
.qAqnGpY86VzH input {
	font-size: 14px;
	font-weight: 500;

	font-family: inherit;
}

.qAqnGpY86VzH > p {
	font-family: inherit;
}

._3LRkQfYLAYqb > div:first-child {
	margin-right: 33px;
}

._3LRkQfYLAYqb > div:last-child {
	max-width: 200px;
}

.JQhVkfUzs8Vs form > div:last-child {
	margin-bottom: 0;
}

.JQhVkfUzs8Vs form > div:first-child > div {
	font-family: inherit;
}

.JQhVkfUzs8Vs .qAqnGpY86VzH:after {
	border-bottom: 2px solid var(--common-blue);
}

.JQhVkfUzs8Vs div.qAqnGpY86VzH:before {
	border-bottom-color: #cdcdcd;
}

._3LRkQfYLAYqb {
	display: flex;
	flex-direction: row;
}

li.KQsn98NlFQt8 {
	font-size: 14px;
	font-weight: 500;
	font-family: inherit;
	text-transform: capitalize;
}

.giICTz_gwP_T {
	display: flex;
	flex-direction: row-reverse;
}

.JQhVkfUzs8Vs .-wpzjWRsBRU3._2kIVpnoP4JBN {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.HEqJWIKn-4Vj {
		margin: 0;
	}

	.HEqJWIKn-4Vj h1 {
		display: flex;
		align-items: center;

		font-size: 20px;
	}

	.HEqJWIKn-4Vj > h1 > span {
		display: flex;
		align-items: center;
	}

	.JQhVkfUzs8Vs {
		margin: 20px 0 0;
	}

	.JQhVkfUzs8Vs form > div {
		margin-bottom: 0;
	}

	._3LRkQfYLAYqb {
		flex-direction: column;
	}

	._3LRkQfYLAYqb > .qAqnGpY86VzH {
		margin-top: 20px;
	}

	._3LRkQfYLAYqb > .qAqnGpY86VzH:first-child {
		margin-right: 0;
	}

	._3LRkQfYLAYqb > .qAqnGpY86VzH:last-child {
		max-width: unset;
	}

	.giICTz_gwP_T {
		margin-top: 20px;
	}
}
