._1Z3vOuInscd4:before {
	opacity: 1;
}

._1Z3vOuInscd4:after {
	background: var(--brand1);
}

.nvHZG5WHeVHp {
	display: flex;
	align-items: center;
	grid-column: 1 / grid-end;

	color: var(--text-light);
	font-size: 16px;
}

.DQZmhJU8j6Ao {
	color: var(--text-light);
	font-size: 16px;

	opacity: 1;
}

._17qesfvlSzvy {
	padding-left: 12px;
	margin-left: 12px;

	border-left: 1px solid var(--line-dot-line);
}

._17qesfvlSzvy a {
	text-decoration: none;
}

._3xJoBx2Tkopv {
	grid-column: 1 / 3;
}

._1nwqslItOgOQ {
	grid-column: 3 / grid-end;
}

@media (max-width: 1024px) {
	div._1j2RwhdRgxeR {
		grid-column: 1;
		display: block;
	}

	._1Z3vOuInscd4 {
		margin-bottom: 12px;
	}

	.nvHZG5WHeVHp {
		display: block;
	}

	._17qesfvlSzvy {
		padding-left: 0;
		margin-left: 0;

		border-left: none;
	}
}

@media screen and (max-width: 600px) {
	.DQZmhJU8j6Ao {
		padding: 28px;
	}
}
