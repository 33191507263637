._19xI02Cih7WG {
	position: relative;

	display: inline-block;
	width: 100%;
	height: 100%;

	line-height: normal;
}

._2l8h-D8O5G_E {
	position: relative;

	display: flex;
	align-items: center;
}

._27LZko_D_YxL {
	position: relative;

	display: inline-block;
	margin-right: 20px;
	padding-right: 20px;

	color: var(--gray90);
	font-weight: 600;
	font-size: 26px;
	line-height: 100%;
}

._27LZko_D_YxL:after {
	position: absolute;
	right: 0;

	width: 1px;
	height: 100%;

	border-right: 1px solid var(--gray20);
	content: '';
}

@media (max-width: 1024px) {
	._27LZko_D_YxL {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	._19xI02Cih7WG {
		position: absolute;
		top: 0;
		bottom: 0;

		display: flex;
		flex-direction: column;
	}

	._27LZko_D_YxL {
		display: none;
	}
}

._34vvBJep6C0h {
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 600px) {
	._34vvBJep6C0h {
		flex-grow: 1;
		display: flex;
		padding: 0 50px;
		padding-right: 0;
	}
}

._3WhMim5myxZ- {
	position: relative;

	height: 100%;
}

@media screen and (max-width: 600px) {
	._3WhMim5myxZ- {
		min-height: 100%;
		width: 100%;
	}
}

._2ZZbOM5n5Oin {
	display: flex;
}

.F1t9DUd5Ke7H {
	display: flex;
	margin-top: 43px;
	margin-bottom: 43px;
	padding: 0 15px;

	border-bottom: 1px solid #cdcdcd;
}

@media (max-width: 1024px) {
	.F1t9DUd5Ke7H {
		padding: 0;
		margin-bottom: 0;
	}
}

._3amAdyFmTfpt select {
	border: none;

	background: none;
}

._2nZc2glzjQ1m {
	display: inline-block;
	padding-left: 20px;

	font-size: 14px;
	color: var(--common-blue);
}

.om15VjvDQX7w {
	position: relative;

	display: flex;
	align-items: center;
	width: 100%;
	min-height: 58px;
	padding: 0 12px;

	border-radius: 2px;

	color: #ffffff;

	background: var(--common-blue);
}

.om15VjvDQX7w:after {
	position: absolute;
	bottom: 0;
	left: 52px;

	width: 0;
	height: 0;

	border-width: 8px 6px 0 6px;
	border-style: solid;
	border-color: var(--common-blue) transparent transparent transparent;

	transform: translateY(calc(100% - 1px));
	content: '';
}

._2T3RdxINQti2 {
	flex-grow: 1;
	flex-basis: 100%;
}

._1UTJFqkGe3a7 {
	flex-grow: 1;
	flex-basis: 100%;
	height: 100%;
	padding: 8px 0;

	border-left: 1px dashed var(--pricegraph-price-border-left-color);

	text-align: right;
}

.VOS3F2e79-ub {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

._178IzHBYeJYm {
	font-size: 12px;

	opacity: 0.6;
}

.WNsXl0Ov8fnT {
	font-size: 22px;
	font-weight: 600;
}

._1DSyntXiBQDb {
	font-size: 14px;
}

._2MWyyk9oQKgu,
.Jm2TeSu0mFdt {
	position: relative;

	padding-left: 25px;

	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
}

.Jm2TeSu0mFdt {
	margin-top: 5px;
}

._2MWyyk9oQKgu:before,
.Jm2TeSu0mFdt:before {
	content: '';

	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;

	margin: auto;
	width: 20px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjMzMzcgMTYuMzMzM0gxMC42NjdMNi41MDAzMyA5LjY2NjY3SDEuOTE2OTlDMS4yMjUzMyA5LjY2NjY3IDAuNjY2OTkyIDkuMTA4MzMgMC42NjY5OTIgOC40MTY2N0MwLjY2Njk5MiA3LjcyNSAxLjIyNTMzIDcuMTY2NjcgMS45MTY5OSA3LjE2NjY3SDYuNTAwMzNMMTAuNjY3IDAuNUwxMi4zMzM3IDAuNUwxMC4yNTAzIDcuMTY2NjdIMTQuODMzN0wxNi4wODM3IDUuNUgxNy4zMzM3TDE2LjUwMDMgOC40MTY2N0wxNy4zMzM3IDExLjMzMzNMMTYuMDgzNyAxMS4zMzMzTDE0LjgzMzcgOS42NjY2N0gxMC4yNTAzTDEyLjMzMzcgMTYuMzMzM1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
}

._2MWyyk9oQKgu:before {
	transform: rotate(180deg);
}

.g6yzcmGj-bXL {
	opacity: 0.7;
}
