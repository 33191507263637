

._2Bjsj_hRfNfg {
	font-size: inherit;
}

._2OuW3D_wZds1 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 5px;

	color: inherit;
}

._2OuW3D_wZds1 svg * {
	fill: #ffffff;
}
