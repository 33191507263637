._1U2lfxdq6qAZ {
	position: relative;

	width: 78px;
	height: 78px;

	border-radius: 50%;

	color: var(--base);

	cursor: pointer;
	background-color: #eaf4ff;
}

._1U2lfxdq6qAZ._3MRZ7qtz9OxV {
	color: var(--white);

	background-color: var(--base);
}

._1U2lfxdq6qAZ > svg {
	position: absolute;
	top: 50%;
	left: 50%;

	width: 60%;
	height: 60%;

	transform: translate(-50%, -50%);
}

._3I5dm--f_PDy {
	position: absolute;
	top: 0;
	right: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 26px;
	height: 26px;

	border: 3px solid var(--white);
	border-radius: 50%;

	color: var(--white);

	transform: translate(10%, -10%);
	background-color: var(--success);
}

._3I5dm--f_PDy > svg {
	width: 70%;
	height: 70%;
}

@media screen and (max-width: 600px) {
	._1U2lfxdq6qAZ {
		width: 58px;
		height: 58px;
	}

	._3I5dm--f_PDy {
		width: 22px;
		height: 22px;
	}
}
