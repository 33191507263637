._38T3ldy8qFAq {

	margin-bottom: 8px;
}

._38T3ldy8qFAq:first-of-type {
	margin-top: 20px;
}

._38T3ldy8qFAq:last-of-type {
	margin-bottom: 20px;
}

._38T3ldy8qFAq:hover,
._2ECUGe-TenKy {
	box-shadow: 0 2px 13px rgba(0, 0, 0, 0.12);
}

._31sNDGg9VnkA {
	border-top: 1px solid #d9d9d9;
}

._1nqsHUSjI8I4 {
	display: flex;
	justify-content: space-between;
	align-self: center;
	margin: 0 20px 20px 20px;
	padding: 12px 16px;

	border-radius: 4px;

	color: var(--summary-flight-features-color);

	background-color: var(--summary-flight-features);
}

._1GGiNCgKX8u5 {
	display: flex;
}

._1pzpl01HaCz6 {
	display: flex;
	align-items: center;

	font-size: 13px;
}

._1pzpl01HaCz6 a:not(:last-of-type) {
	margin-right: 20px;
}

._3KaCl1D5l2KP {
	display: flex;
	align-items: center;
}

._3KaCl1D5l2KP:not(:last-of-type) {
	margin-right: 20px;
}

._1ivgtYzjhPxV {
	position: relative;
	top: -1px;

	display: flex;
	align-items: center;

	color: #9a9a9a;
}

._1tFZg6YOtpRz {
	margin-left: 8px;

	font-size: 13px;
}

._10OJiySt3SLV {
	z-index: 1501 !important;
}
