
/* stylelint-disable */
._2XZgV_6UwOII {
}

._2ckzhcW8yb0v {
}

/* stylelint-enable */
._12oh8iDSoGrM {
	display: inline-block;
	width: 30px;
	height: 30px;

	line-height: 30px;
	text-align: center;

	cursor: pointer;
}

._12oh8iDSoGrM._2KLJ0aotZjiE {
	color: #ffffff;

	background: #84d2ff;
}

._2NQqEqRZSTrS {
	opacity: 0.6;
	cursor: default;
}

._1tn322Kdjnmk,
._1yMQPHBFKoWv {
	color: #ffffff;

	background: #84d2ff;
}

._3FkcWmRykfly > div,
._1D_QBAJJQsZS > div {
	position: relative !important;

	z-index: 2;

	border-radius: 50%;

	background: var(--datepicker-day-period-background);
}

._3FkcWmRykfly,
._1D_QBAJJQsZS {
	position: relative !important;
	z-index: 1;
}

._3FkcWmRykfly:after {
	position: absolute;
	top: 0;
	right: 0;

	width: calc(40px / 2);
	height: 36px;

	background: var(--datepicker-day-period-background);
	content: '';
}

._1D_QBAJJQsZS:before {
	position: absolute;
	top: 0;
	left: 0;

	width: calc(40px / 2);
	height: 36px;

	background: var(--datepicker-day-period-background);
	content: '';
}

.FNgIrbkkzgUz {
	color: #ffffff;

	background: #5bc3ff;
}

._2hYQd4gMKQdL {
	color: #cccccc;
}

.Tqu8wfBJScMW {
	color: #84d2ff;
}

.H_RJr6PO0Dzj {
	color: #84d2ff;
}

.Tqu8wfBJScMW._2KLJ0aotZjiE,
.Tqu8wfBJScMW.FNgIrbkkzgUz,
.Tqu8wfBJScMW.H_RJr6PO0Dzj,
._2KLJ0aotZjiE.H_RJr6PO0Dzj {
	color: #ffffff;
}
