._2XdJxSNG-GhY {
	max-width: 280px;
	min-width: 280px;
	height: -moz-fit-content;
	height: fit-content;

	border-radius: 4px;

	background-color: var(--common-blue);
}

._2XdJxSNG-GhY ._2NyEGpdV2bxl {
	padding: 18px 22px;

	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

._2XdJxSNG-GhY div._3Y9rGdrix3re {
	background-color: rgba(0, 0, 0, 0.08);
}

._2XdJxSNG-GhY ._2NyEGpdV2bxl span {
	color: #ffffff;
	font-size: 15px;
	font-weight: 500;
	font-family: inherit;
}

._2XdJxSNG-GhY ._2NyEGpdV2bxl ._1HIjf-phPyyZ {
	margin-right: 0;
	min-width: 50px;

	font-size: 24px;
	color: #ffffff;
}

._2NyEGpdV2bxl ._31vdCx42CKkE {
	padding: 0 22px 0 0;

	color: #ffffff;
	overflow: hidden;
	text-overflow: ellipsis;
}

._1OEqM2c8oYoa:first-of-type ._2NyEGpdV2bxl {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}

._2KhGcQYxooB0 {
	transform: rotate(90deg);
}

a {
	text-decoration: none;
}

@media (max-width: 1024px) {
	ul._2XdJxSNG-GhY {
		padding-top: 0;
		padding-bottom: 0;
		min-width: 75vw;
		max-width: unset;
	}

	._2XdJxSNG-GhY ._2NyEGpdV2bxl {
		padding: 30px 22px;
	}
}
