

._2ZCwLiaWrqFa {
	display: flex;
	margin-top: 20px;
	min-height: 475px;
}

._21aC97qvndee {
	margin: 54px auto 0;
	width: 1100px;
}

._2zqHDC2hGBCq {
	width: 648px;
	padding: 46px 36px 0 36px;

	background-color: var(--gray);
}

._2zqHDC2hGBCq h3 {
	margin: 0 0 24px 0;
}

._2h718-Tmt-Ae {
	width: 452px;
	padding: 46px 36px 0 36px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	text-align: right;

	background-color: var(--gray01);
}

._2h718-Tmt-Ae h3 {
	margin: 0;
	padding-bottom: 24px;

	border-bottom: 2px solid var(--gray50);
}

._365c3U3C3t8q {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 576px;
	min-height: 88px;
	padding: 0 17px 0 22px;

	background-color: var(--gray05);
}

._1uTWbZXL7OEm {
	border: 1px solid var(--base25);
}

._365c3U3C3t8q:not(:first-child) {
	margin-top: 10px;
}

._2kBgLCTuXJJj div {
	color: var(--selected);
}

div._2ypKq6FpKgNv {
	margin-bottom: 20px;
}

input._18J3LPfLGfXL {
	border: 0;

	background: var(--bg-white);
}

._1dctCS0wLh7E {
	padding: 25px 0 25px 0;

	border-bottom: 1px dashed var(--gray40);
}

._1dctCS0wLh7E > div:not(:first-child) {
	margin-top: 9px;
}

._3GPySeu7_ni2 {
	padding: 9px 0 25px 0;
}

._3GPySeu7_ni2 > div:not(:first-child) {
	margin-top: 9px;
}

.Ge75Rf5nln0m {
	margin-top: 20px;
	margin-bottom: 48px;
	display: flex;
	justify-content: space-between;
}

button._3ZxZOBe1fnLP,
button._3ZxZOBe1fnLP:hover {
	background-color: var(--active);
}

._1Pm2aUHuzbDx {
	font-size: 17px;
	font-weight: 400;
}

div._1Wo5Y-pEPgVB {
	border: 1px solid var(--error60);

	font-weight: 500 !important;

	background-color: var(--error90) !important;
}

._2NPXAfrzewG1 {
	display: inline-block;
	width: 80px;
}

._1Ub3pmS6oMlU {
	display: none;
}

._1qM6bq4zuucN {
	display: block;
	padding-bottom: 3px;
}
