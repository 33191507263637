._mc9gwauXwFR {
	display: flex;
	margin-right: 20px;
}

.cWNUG0MKwQD4 {
	font-weight: 500;
	font-size: 14px;
	color: var(--cabinet-action);

	cursor: pointer;
}

._3P0hvfDiNIed {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 600px) {
	._mc9gwauXwFR {
		margin-right: 0;
	}
}
