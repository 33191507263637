

._3UEdIFWjSnQz {
	position: relative;

	min-height: 88px;
	height: 100%;

	display: flex;
	flex-direction: column;

	text-align: center;

	cursor: pointer;
	background: #ffffff;
}

.nDkmQukEac1n {
	justify-content: center;
}

._1kG9qmBaJAKK {
	cursor: default;
}

.c-KivWzaQmxx._3UEdIFWjSnQz {
	border-bottom: 3px solid var(--active);
}

._24_TznBsQv6d {
	padding-top: 8px;

	color: #4d4d4d;
	font-weight: 600;
	font-size: 16px;
}

._1kG9qmBaJAKK ._24_TznBsQv6d {
	color: var(--gray30);
}

.c-KivWzaQmxx ._24_TznBsQv6d {
	font-size: 19px;
}

._3WJKwdOmFMGe {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
}

.I-iacZ2404vS {
	position: relative;

	margin: 14px 25px 0 25px;
	padding-bottom: 8px;

	line-height: 16px;
}

.I-iacZ2404vS:after {
	position: absolute;
	right: 0;
	left: 0;
	z-index: 1;

	display: none;

	width: 76px;
	margin: auto;

	border-top: 1px dashed var(--gray20);
	content: '';
}

._1chIVgKnC323 {
	position: absolute;
	top: -7px;
	right: 0;
	left: 0;
	z-index: 2;

	margin: auto;

	font-weight: 500;
	font-size: 12px;
	text-transform: capitalize;
}

._1chIVgKnC323 span {
	padding: 0 4px;

	color: var(--gray30);

	background: #ffffff;
}

._1kG9qmBaJAKK ._1chIVgKnC323 {
	color: #cdcdcd;
}

._2c_SCGxC_ppX {
	font-weight: 600;
	font-size: 26px;
	color: var(--base-30);
}

.c-KivWzaQmxx ._2c_SCGxC_ppX {
	font-size: 31px;
}

._3UEdIFWjSnQz._38ypGP_a8QfE ._2c_SCGxC_ppX {
	color: var(--success-20);
}
