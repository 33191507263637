

.URo2LCdVjbjI {
	display: flex;
	justify-content: center;
	padding: 50px 0;
}

div._2vgTcUimYwj0 {
	background: var(--gray05);
}

._2vgTcUimYwj0 span._33O5H_2ETYFh {
	top: 13px;
	right: 13px;

	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;

	background: #ffffff;
}

.FBU5al4Dd7Hw {
	margin-bottom: 12px;
}
