._2b03T2gSA-rU {
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	._2b03T2gSA-rU {
		margin-left: 0;
	}

	._2b03T2gSA-rU[class*='applied'] {
		padding-left: 28px;
	}
}
