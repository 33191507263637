

.c01r0xX87dTH {
	margin: 0 -8px;
	padding: 0 8px;
}

.c01r0xX87dTH:not(:last-of-type) {
	padding-bottom: 6px;

	border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
}

.c01r0xX87dTH:not(:first-of-type) {
	padding-top: 8px;
}

.h2RpYJLeb3MS {
	display: flex;
	justify-content: space-between;

	list-style-type: none;
}

._182cbkyZgJhD {
	color: #ffee92;
}

.SRm0P4FlIjj6 {
	margin-left: 20px;

	color: #ffffff;
	font-weight: 500;
	text-align: right;
}

._182cbkyZgJhD,
.SRm0P4FlIjj6 {
	font-size: 13px;
	line-height: 20px;
}
