

div._1T2eeGgvhZdM {
	position: relative;
}

label._2GGvB2_DnOp1 {
	width: 100%;

	color: var(--gray50);
	font-family: inherit;
	font-size: 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	transform: translate(10px, 20px) scale(0.9);
}

label._2yW-NuYpMu5h {
	transform: translate(0, -1.5px) scale(0.75);
}

label._2GGvB2_DnOp1._2IPjBgkSh8H7._1UC4_Onw7_Yl:not(._2-ZL8Cp1yS9N) {
	color: var(--cabinet-action);
}

div._2sko3M4odvDb.ivmbktLKYn6a:before,
div.YdtJmjyQtCm3.ivmbktLKYn6a:before {
	background: var(--input-background-disabled);
}

div.YdtJmjyQtCm3.ivmbktLKYn6a {
	color: var(--gray90);
}

div._2sko3M4odvDb {
	position: relative;

	font-family: inherit;
}

div._2sko3M4odvDb:before {
	content: '';

	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;

	display: block;
	height: 54px;

	background: var(--input-background);
}

input.YdtJmjyQtCm3,
div.YdtJmjyQtCm3 {
	box-sizing: border-box;
	height: 54px;
	padding: 12px 9px 9px;

	border: 1px solid transparent;

	font-size: 16px;
	line-height: 20px;
	color: var(--gray90);
}

._2Qvg8_zaB63I {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;

	margin: auto;
}

p._2b0fPkxMAqep {
	margin: 4px 0 0;
}

p._3UX5mIq0N70M {
	position: absolute;
	bottom: 0;

	margin-top: 0;
	width: 100%;

	font-family: inherit;
	white-space: nowrap;
	text-overflow: ellipsis;
	letter-spacing: normal;
	line-height: inherit;

	transform: translateY(140%);
	overflow: hidden;
}

.QrI4bREieY5a._2sko3M4odvDb:before {
	background: transparent;
}

.QrI4bREieY5a:not(.rpXwN9uKPg7B) input.YdtJmjyQtCm3,
.QrI4bREieY5a:not(.rpXwN9uKPg7B) div.YdtJmjyQtCm3 {
	background: var(--input-background-active);
}

div._1UC4_Onw7_Yl {
	border-radius: var(--input-border-radius);
}

div._2sko3M4odvDb._1QV-1x_gxxZB {
	border-radius: var(--input-border-radius);
}

div._2sko3M4odvDb._1QV-1x_gxxZB:not(.ivmbktLKYn6a) {
	background: var(--input-background);
}

div._2sko3M4odvDb._1QV-1x_gxxZB.ivmbktLKYn6a {
	background: var(--input-background-disabled);
}

div._2sko3M4odvDb._1QV-1x_gxxZB.ivmbktLKYn6a input {
	color: var(--text-light);
}

fieldset.rpXwN9uKPg7B,
div._2sko3M4odvDb._1QV-1x_gxxZB .YdtJmjyQtCm3,
div._2sko3M4odvDb.ivmbktLKYn6a .rpXwN9uKPg7B {
	border: 1px solid var(--input-outline);
	border-radius: var(--input-border-radius);
}

label + div._2sko3M4odvDb._1QV-1x_gxxZB .YdtJmjyQtCm3,
label + div._2sko3M4odvDb._1t33j9-fifOF .YdtJmjyQtCm3 {
	padding: 26px 12px 9px;
}

div._2sko3M4odvDb.QrI4bREieY5a .rpXwN9uKPg7B,
div._2sko3M4odvDb.QrI4bREieY5a._1QV-1x_gxxZB .YdtJmjyQtCm3 {
	border: 1px solid var(--input-outline-active);
	border-radius: var(--input-border-radius);
}

label.IO-E6p_EktaE.BWum7iffDQAU:not(._2-ZL8Cp1yS9N),
label.IO-E6p_EktaE.BWum7iffDQAU._2-ZL8Cp1yS9N,
label._1xNClWvtuV-g.BWum7iffDQAU {
	color: var(--input-color);
}

label.IO-E6p_EktaE._22J64deaer97 {
	color: var(--text-light);
}

div._2sko3M4odvDb._1PeYcSBUd-D-._1QV-1x_gxxZB .YdtJmjyQtCm3,
div._2sko3M4odvDb._1PeYcSBUd-D-._1QV-1x_gxxZB.QrI4bREieY5a .YdtJmjyQtCm3 {
	border-color: var(--input-error-border);

	background: var(--input-error-background-filled);
}

div._2sko3M4odvDb.rpXwN9uKPg7B._1PeYcSBUd-D- fieldset.rpXwN9uKPg7B {
	border-color: var(--input-error-border);
}

div._1JdOINjrEtZ8:before {
	border-color: var(--input-outline);
}

div._1JdOINjrEtZ8:after {
	border-color: var(--input-outline-active);
}

div._2sko3M4odvDb._1JdOINjrEtZ8 {
	margin-top: 0;
}

label._2GGvB2_DnOp1.BWum7iffDQAU._1xNClWvtuV-g,
label._1xNClWvtuV-g._2yW-NuYpMu5h {
	z-index: 1;

	transform: translate(12px, 10px) scale(0.75);
}
