.AVEVAOmaw7Kt {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

button._12hThmDsCyhv {
	width: 49%;
	height: 49px;

	line-height: 49px;

	background: var(--active);
}

button._12hThmDsCyhv:hover {
	background: var(--active);
}

button._12hThmDsCyhv._3W_jy9s823Oj {
	color: var(--gray70);

	background: #ffffff;
}

button._12hThmDsCyhv:only-child {
	width: 100%;
}

@media (max-width: 768px) {
	.AVEVAOmaw7Kt {
		position: fixed;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1007;

		height: 69px;
		padding: 0 20px;

		box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
		background: #ffffff;
	}

	button._12hThmDsCyhv._3W_jy9s823Oj {
		background: var(--gray05);
	}
}
