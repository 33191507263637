

._3n6wrdZn1mUg {
	display: flex;

	cursor: pointer;
}

._2yzx448UoAwx {
	padding: 18px 0 18px 20px;

	display: flex;
	align-items: center;

	flex-basis: 33%;
	flex-grow: 1;

	line-height: 28px;
}

._2yzx448UoAwx:nth-child(1) {
	flex-basis: 364px;
}

._2yzx448UoAwx.WkNVhaA9pzSo {
	padding-right: 20px;
	overflow: hidden;
}

._2yzx448UoAwx:nth-child(2) {
	flex-basis: 432px;
}

div._2yzx448UoAwx:nth-child(2) {
	border-right: none;
}

._2yzx448UoAwx:nth-child(3) {
	flex-basis: 301px;

	color: #ffffff;

	background: #3290ff;
}

._15zpxiUyCDRZ:nth-child(3) {
	flex-basis: 33%;
}

._2yzx448UoAwx:last-of-type {
	position: relative;

	justify-content: space-between;
	padding-right: 40px;
}

._2yzx448UoAwx:not(:last-of-type) {
	border-right: 1px dashed #cdcdcd;
}

.cu-AdoXaAxko {
	margin-right: 14px;
	display: flex;
	align-items: center;

	transform: rotate(180deg);
}

.mL2rW0uKIlY7 {
	transform: rotate(0deg);
}

.xZ_bnlOV7J90 {
	font-size: 20px;
	font-weight: 600;
}

.WkNVhaA9pzSo .xZ_bnlOV7J90 {
	max-width: 100%;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;
}

.xT7jvAaIt0B2 {
	margin-left: 12px;
	margin-right: 12px;

	color: #a9a9a9;
	font-size: 20px;
	font-weight: 600;
}

._2QgIdPWh_yiD {
	font-size: 22px;
	font-weight: 600;
}

._1s9bv4O7WKpV {
	position: absolute;
	right: 20px;

	color: #ffffff;

	transition: transform 0.15s;
}

.SU9qYXAe9pAn {
	transform: rotate(-180deg);
}

._1K9XFsNQWhs3 {
	display: flex;
	align-items: center;
}

._2ONsGG-CGtee {
	height: 20px;
	width: 20px;
	margin-right: 8px;
}

._2ONsGG-CGtee img {
	width: 100%;
	height: 100%;
}

._2j5SPwPUyYQb {
	color: #ffffff;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}

._1CFVKfDpzJLk {
	color: var(--common-blue);
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}
