._1Cba6sZf9Qb0 {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 1100px;

	color: var(--gray80);
}

@media screen and (max-width: 600px) {
	div._14UgiSZGe35D,
	div._1Cba6sZf9Qb0 {
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
	}
}

._2SMTa6XXAvih {
	background: transparent;
}

._14UgiSZGe35D {
	display: flex;
	flex-direction: column;
}

._1xxZiOxeAXwn {
	display: flex;
	align-items: center;
	padding: 16px;
}

@media screen and (max-width: 600px) {
	div._1xxZiOxeAXwn {
		flex-direction: column;
		align-items: flex-start;
		padding: 10px;
	}
}

.ROFBfAfGvNRi {
	margin: 0;
	padding: 0;

	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
}

@media screen and (max-width: 600px) {
	.ROFBfAfGvNRi {
		flex: 0 1 50%;

		font-size: 16px;
		font-weight: 500;
	}
}

@media screen and (max-width: 600px) {
	._3DwoWNZaPss8 {
		display: none;
	}
}

._2G65UikTwih8 {
	margin: 0 0 0 10px;
	padding: 0 0 0 10px;

	border-left: 1px solid var(--gray20);

	color: var(--gray50);
	font-size: 14px;
	line-height: 150%;
}

@media screen and (max-width: 600px) {
	._2G65UikTwih8 {
		flex: 0 1 50%;
		align-self: flex-end;
		margin: -21px 0 0;
		padding: 0;

		border-left: 0;
	}
}

._30s_G1NyKSHz {
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	._30s_G1NyKSHz {
		margin-left: 0;
		margin-top: 6px;
		margin-bottom: 6px;
	}
}

._7ayv3dDJ0zHQ {
	padding: 16px;

	border-top: 1px solid var(--gray05);
	border-bottom: 1px solid var(--gray05);
}

@media screen and (max-width: 600px) {
	._7ayv3dDJ0zHQ {
		padding: 10px;
	}
}

.eYiEqOllKWcV,
._3Zbb1Z4xXK7J {
	margin: 0;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
}

._1asvIKfbB2i8,
._1uFRjd05C3ph {
	margin-top: 8px;
	margin-bottom: 0;

	font-size: 15px;
	line-height: 19px;
}

._22faih8hJ6Qi {
	padding: 0 16px 16px;
}

@media screen and (max-width: 600px) {
	._22faih8hJ6Qi {
		padding: 0;
		flex-direction: column;
	}

	div._3FHW6hCRwHCk {
		width: 100%;
		margin-right: 0;
		padding: 16px;

		border-radius: 0;

		color: #ffffff;

		background: var(--base10);
	}

	._22faih8hJ6Qi > div._3FHW6hCRwHCk:not(:last-of-type) {
		margin-right: 0;

		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	}
}

._2T8zTabfidNZ {
	padding: 16px;
}

@media screen and (max-width: 600px) {
	._2T8zTabfidNZ {
		padding: 10px;
	}
}

._1aIontPQvPsP {
	margin: 12px auto;
	max-width: 1100px;
}

@media screen and (max-width: 600px) {
	div._1aIontPQvPsP {
		padding: 10px;
		margin-top: 0;

		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
	}
}

div._3nikwPM5Hntg {
	padding: 25px 16px 16px 20px;
	margin: 12px 0;
	max-width: none;

	box-shadow: none;
}

@media screen and (max-width: 600px) {
	div._3nikwPM5Hntg {
		margin: 0;
	}
}

._4r719G0fPgL6 {
	display: flex;
	align-items: center;

	font-size: 16px;

	color: var(--base10);
}

@media screen and (max-width: 600px) {
	._4r719G0fPgL6 {
		line-break: anywhere;
	}
}

._3m3ZB-HRJOYV {
	margin-right: 7px;
}

._1iqtaFFvmNGA {
	max-width: 1100px;
	margin: 0 auto;
}

@media screen and (max-width: 600px) {
	._1iqtaFFvmNGA > div {
		display: flex;
		flex-direction: column;
	}

	._1iqtaFFvmNGA > div button:last-of-type {
		margin-top: 8px;
		margin-left: 0;
	}
}

button._1BRhGg9C7831 {
	min-width: 250px;
}

button._38dXU4wRIMe2 {
	margin-left: 12px;
}

button._3fK2RKM5-P6l {
	color: var(--error);
	font-weight: 400;

	background: #ffffff;
}

@media screen and (max-width: 600px) {
	div.erRYHaeU8dsX {
		display: block;
		padding-right: 15px;

		border-radius: 0;
	}

	div.erRYHaeU8dsX:not(:last-of-type) {
		border: 1px solid rgba(255, 255, 255, 0.3);
	}

	._1Py-j2xR9fez {
		max-height: 115px;
		display: block;
		overflow: hidden;

		text-overflow: ellipsis;
	}

	._1MhLgOpW-UoO {
		margin: 0;

		font-size: 14px;
		line-height: 19px;
		font-weight: 400;
	}
}
