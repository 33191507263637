._3myKWDxvFN5Z {
	position: relative;

	height: 376px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	padding-bottom: 28px;

	border-radius: var(--baggage-additional-wrapped-border-radius);

	background: #ffffff;
	box-shadow: var(--meal-box-shadow);

	cursor: pointer;

	transition: box-shadow 0.2s;
}

._3myKWDxvFN5Z:after {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	content: '';

	border-radius: var(--baggage-additional-wrapped-border-radius);
	border: var(--meal-selected-border);

	transition: border 0.2s;

	pointer-events: none;
}

._3myKWDxvFN5Z:hover {
	box-shadow: var(--meal-box-shadow-active);
}

._3myKWDxvFN5Z:hover:after {
	border-color: var(--base50);
}

._2ijo9JlxtwWa {
	height: 376px;

	flex-direction: row;

	padding-bottom: 0;
}

._3XSZMCJK5ueK {
	position: relative;
	z-index: 1;
}

._2ijo9JlxtwWa ._3XSZMCJK5ueK {
	position: static;

	flex-basis: 550px;
	flex-grow: 1;
}

._3wbzrR0SvW01 {
	height: 200px;

	border-radius: var(--baggage-additional-wrapped-border-radius) var(--baggage-additional-wrapped-border-radius) 0 0;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

._1Pq-Dw114Ojl {
}

._1Pq-Dw114Ojl svg {
	width: 100px;
	height: 100px;

	color: var(--brand-1-2);
}

._2ijo9JlxtwWa ._3wbzrR0SvW01 {
	height: 100%;
	width: 100%;

	border-radius: var(--baggage-additional-wrapped-border-radius) 0 0 var(--baggage-additional-wrapped-border-radius);
}

._1D6HSZuCElT8 {
	position: absolute;
	top: 0;
	left: 50%;

	padding: 4px 16px;

	border-radius: 100px;

	color: #ffffff;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;

	background-color: var(--error);

	transform: translate(-50%, -50%);
}

._2ijo9JlxtwWa ._1D6HSZuCElT8 {
	position: static;

	display: inline-block;

	transform: none;
}

.P_PXwcSIq2Dp {
	margin-top: 20px;
	padding: 0 16px;

	text-align: center;
}

._2ijo9JlxtwWa .P_PXwcSIq2Dp {
	padding: 0;

	text-align: left;
}

._3u13RTV6Dz65 {
	color: var(--gray80);
	font-weight: 600;
	font-size: 16px;
	line-height: 130%;
	text-transform: uppercase;
}

._2ijo9JlxtwWa ._3u13RTV6Dz65 {
	font-size: 27px;
}

._32489z92X9Fy {
	margin-top: 4px;

	color: var(--gray50);
	font-weight: 500;
	font-size: 16px;
	line-height: 130%;
}

._2ijo9JlxtwWa ._32489z92X9Fy {
	margin-top: 20px;
}

._3zjPRR-TbjLp {
	margin-top: 20px;
}

._2ijo9JlxtwWa ._3zjPRR-TbjLp {
	margin-top: 0;
	padding: 50px 40px 40px;
}

._1O7HX-o9QQPl {
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;
}

._2ijo9JlxtwWa ._1O7HX-o9QQPl {
	margin-top: 40px;

	justify-content: flex-start;
}

._1HKWy-HIfYh- {
	padding: 4px 22px;

	border-radius: 100px;

	font-weight: 600;
	font-size: 22px;
	line-height: 150%;
	color: var(--base-30);

	background-color: var(--base90);

	transition: background-color 0.3s, color 0.3s;
}

._3myKWDxvFN5Z:hover ._1HKWy-HIfYh- {
	color: #ffffff;

	background-color: var(--base);
}

._1EVTxFfpaZFq {
	margin-right: 10px;
	padding-left: 0;

	font-weight: 600;
	font-size: 20px;
	line-height: 150%;

	color: #c21212;
	text-decoration: line-through;
}

._1oOTR81YYHV5 {
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 10px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 600;
	font-size: 16px;

	background-color: var(--success);
}

div.AKJDOa4XHXfy {
	z-index: 1650 !important;
}

div._3puZLgkHJC-g {
	width: 100%;

	border-radius: var(--baggage-additional-wrapped-border-radius);

	background-color: transparent;
}

.H8hr7xQYLwQv {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 1;
}

@media screen and (max-width: 600px) {
	div._3puZLgkHJC-g {
		border-radius: 0;
	}
}
