

.HezXUMFhWkOW {
	display: flex;
	flex-direction: column;
	padding: 25px 20px 20px;

	border-radius: 12px;

	background-color: var(--white);
}

._2PGR3Grcxxp3 {
	display: flex;
	align-items: center;
	margin-bottom: 18px;
	padding-bottom: 20px;

	border-bottom: 1px dashed #d9d9d9;
}

._2PGR3Grcxxp3._3KPpZ1vgI0aH {
	padding-bottom: 0;

	border-bottom: none;
}

._2Qk0U6ie5WhP {
	margin-bottom: 4px;

	color: var(--text);
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
}

._27HFbOtMOhp7 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	margin-right: 20px;

	flex-shrink: 0;

	border-radius: 50%;

	color: var(--base);

	background-color: #f6f6f6;
}

._27HFbOtMOhp7 > svg {
	width: 60%;
	height: 60%;
}

.PjlC6KrDBcWG {
	display: flex;
	flex-direction: column;
}

._3ReJOgqXmVkJ {
	font-size: 16px;
	line-height: 1.3;
	color: var(--gray50);
}

.oj0Spdq9mtVN {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

._1efn3bJpHctK {
	margin-right: 12px;

	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
}

button._2PGA_TG4HZMf {
	min-width: auto;
	height: 34px;
	padding: 0 16px;

	border: 1px solid transparent;

	color: var(--white);
	font-weight: 500;

	font-size: 14px;
	line-height: 34px;

	background: var(--base);
}

button._2PGA_TG4HZMf:hover {
	color: var(--white);

	background: var(--base);
}

button._2PGA_TG4HZMf.aR-AO2n_zI8r {
	border-color: #add3ff;

	color: var(--base);

	background: var(--base90);
}

button._2PGA_TG4HZMf.aR-AO2n_zI8r:hover {
	opacity: 0.8;
}
