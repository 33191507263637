

div._2Ch72-R4E6qY {
	width: 36px;
	height: 36px;
}

div.WIzUHTL0pDF2 > span {
	border-bottom: none;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 16px;
}
