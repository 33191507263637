._3N9_c6lFx0qL {
	display: flex;
	align-items: center;
	padding: 17px 0 14px 13px;

	border-radius: 4px;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;

	background-color: rgba(50, 144, 255, 0.1);
}

._3N9_c6lFx0qL:not(:first-child) {
	margin-top: 20px;
}

._3qUs0w0c1FBg {
	display: flex;
	align-items: center;
	flex: 1;
}

._2DNU5C2dnPf0 {
	margin-left: auto;
}

._3N9_c6lFx0qL + ._2fL94DBUihJI {
	border-top: none;
}

._3qUs0w0c1FBg._3vKD25Ov3_NJ {
	max-width: 31.5%;
}

.Oy-J-FFH3Sc5 {
	display: flex;
	align-items: center;
	flex: 1;
}

._3vPolN1rG0gU {
	flex: 1;

	text-align: center;
}

._3aJR__N4xX_3 {
	flex: 1;
}

@media screen and (max-width: 600px) {
	._3N9_c6lFx0qL:not(:first-child) {
		margin-top: 25px;
	}

	.Oy-J-FFH3Sc5 {
		justify-content: flex-end;
	}

	._3vPolN1rG0gU {
		text-align: right;
	}
}
