._6p0T2mEB5AO8 {
	display: flex;
}

._239ZYAdL7LgX {
	justify-content: flex-start;
}

._3D9tH6a0OyQu {
	justify-content: center;
}

._3eogQ-FEJrIL {
	justify-content: flex-end;
}

button._2MCMzSQ52a3K,
button._3tzqOlOye6BO {
	min-width: 160px;
}

button._3tzqOlOye6BO {
	margin-right: 12px;
}

@media screen and (max-width: 600px) {
	button._2MCMzSQ52a3K {
		width: 100%;
	}
}
