._13zvToc3P6Ek {
	padding: 14px 0 20px;

	color: var(--white);

	background: var(--base);
}

._2uNzAOCJ17jk {
	padding-right: 20px;

	text-align: right;
}

._3A8DWSjUhVUG:before {
	opacity: 1;
}

._3A8DWSjUhVUG:after {
	background: var(--gray90);
	opacity: 0.2;
}

._1ENruzc8MBQJ {
	margin-right: 4px;

	font-size: 18px;
	font-weight: 700;
	line-height: 30px;

	opacity: 1;
}

._3SzH3EvvnrGi:before {
	background: var(--base);
}

._3SzH3EvvnrGi:after {
	background: var(--white);
	opacity: 1;
}

.x4_MHN5N3xML {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	margin-top: 21px;
	padding-right: 20px;
}

.mQQUIxhIEpj7 {
	margin-right: 4px;

	font-size: 18px;
	line-height: 1.2;
}

._1wwG_CIc_1nS {
	font-size: 30px;
	font-weight: 700;
	line-height: 1;
}

@media screen and (max-width: 600px), (max-width: 768px) {
	._13zvToc3P6Ek {
		display: flex;

		padding: 10px 20px;
	}

	._2uNzAOCJ17jk {
		padding-right: 0;
	}

	._3A8DWSjUhVUG {
		padding: 0;
	}

	.x4_MHN5N3xML {
		margin-top: 0;
		margin-left: auto;

		padding-right: 0;
		padding-top: 8px;

		align-items: initial;
	}

	._1wwG_CIc_1nS {
		font-size: 23px;
	}
}
