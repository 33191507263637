

._2gz_w-1VT4Uu {
	color: var(--white);
	text-decoration: underline;

	cursor: pointer;
}

div._4teLPHbV9jKe {
	max-width: 1100px;

	border-radius: 20px;
}

._2EmfUbEvyBjH {
	padding: 32px;

	border-radius: 20px;

	background: var(--white);
}

._2VB_fvJZ-yc5 {
	margin-bottom: 32px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 20px;
	line-height: 1.3;
}

._37v5xUKoBVsc {
	display: flex;
	align-items: center;
	margin-bottom: 32px;
}

._2TpQ379IKikx {
	position: relative;

	padding-top: 62.35%;
}

._2TpQ379IKikx iframe {
	position: absolute;
	top: 0;
	left: 0;
}

._394-5TLnPYdS {
	position: absolute;
	top: 8px;
	right: 8px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--text-pale);

	cursor: pointer;
	background: var(--bg-fill);
}

._394-5TLnPYdS:before,
._394-5TLnPYdS:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 40%;
	height: 2px;

	background: currentColor;
	transform: translate(-50%, -50%) rotate(45deg);
}

._394-5TLnPYdS:after {
	transform: translate(-50%, -50%) rotate(-45deg);
}
