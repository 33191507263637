

.Ix8t7WYPsIp8 {
	position: relative;

	height: 100px;

	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;

	color: #ffffff;
}

.aTyNMSb4AppF {
	position: absolute;
	right: 0;
	top: 0;
}

._2AAM8nk_zu7c {
	padding: 35px 0 35px 20px;

	font-size: 22px;
	font-weight: 700;
}

.hwyKMXzSEfZF {
	margin-left: 10px;
	margin-right: 5px;
}

._1xstyYjvvZc9 {
	display: flex;
	justify-content: space-between;
	padding: 10px 16px;

	font-weight: 700;
}

._3DSef6BTC24m {
	width: 290px;

	border-radius: 8px;

	background: #ffffff;
}

div.SzlX-1BueS60 {
	width: 100%;
}

._3MYnZFAMioZq {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - 100px);
	min-height: 160px;

	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;

	color: var(--gray70);
}

div._2xsOoRCse4L5 {
	position: relative;

	color: var(--white);
}

._37y5vd03wvDD {
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;
}

._2gS3XdQM2bpy {
	max-width: 57%;

	color: var(--gray80);
	font-weight: 700;
	font-size: 15px;
	word-wrap: break-word;
}

._2SuY_EuUHmh- {
	color: var(--brand-1-0);
	font-weight: 700;
}

._3euJTlAiRznp {
	color: var(--gray90);
	font-weight: 700;
}

._37tyuHR0q3Nc {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 6px;

	font-size: 14px;
}

._1H2lwI_H7YDr {
	margin-right: 5px;
	max-width: 65%;

	word-wrap: break-word;
}

.eD5iD9W3onTK {
	width: 19px;

	line-height: 12px;

	transform: scale(0.7);
}

.eD5iD9W3onTK > svg > path {
	fill: #5fca43;
}

._2I7Smck1-W8k {
	width: 19px;

	line-height: 10px;

	color: var(--error);

	transform: scale(0.7);
	cursor: pointer;
}

._3F-ls0XljtA0 {
	display: flex;
	align-items: center;
}

._2QXsuHIBkDNj {
	display: flex;
	padding: 0 16px 16px 16px;
}

._1zYDHmMTZz-k {
	color: var(--text-base);
	font-size: 25px;
	font-weight: 700;
	line-height: 1.3;
}

._3k561exaeWvw {
	display: flex;
	align-items: center;
	margin-left: auto;
}

._280G6l1AsVe3 {
	margin-right: 5px;

	color: var(--text-base);

	font-size: 13px;
	line-height: 1.3;
}

button._3uDpFk2X_pA6 {
	margin-top: 6px;
	padding-right: 0;
	padding-left: 0;

	font-size: 13px;
}

button._2WcvpBViPsv3,
button._2WcvpBViPsv3:hover {
	color: var(--brand-1-0);

	background: var(--bg-fill);
}

._3Vy_N3Nn9OEq {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

span._2-eyM2bL-80A {
	margin-left: 16px;

	color: var(--gray45);
}

span._2-eyM2bL-80A._2R0qLz7wKN3y {
	color: var(--base);
}

span._3PjDteP3Fdze {
	margin-top: 3px;

	color: var(--base);
	font-size: 15px;
	font-weight: 700;
}

.MP9IA27vRLkC {
	width: 100%;
	height: 47px;
	margin-bottom: 16px;
	padding-left: 16px;

	color: var(--white);
	font-weight: 700;
	line-height: 49px;

	background: var(--gray70);
}

._3OGK5jzlgbCC {
	padding: 16px;

	border-bottom: 1px dashed #bababa;
}

.azi_G5r6VWfl {
	margin-bottom: 12px;
}

.azi_G5r6VWfl:last-child {
	margin-bottom: 0;
}

._9O02wr8ROhUj {
	padding: 0 16px;
}

._9O02wr8ROhUj ._3OGK5jzlgbCC {
	border-bottom: 0;
}

._9O02wr8ROhUj ._3OGK5jzlgbCC:last-child {
	margin-bottom: 0;
}

.CHydoWmZldxn > div:not(:last-child) {
	border-bottom: 1px dashed #bababa;
}

._1VTUyRrZ9zO4 {
	display: flex;
	align-items: center;
	margin-top: 13px;
}

._2z-TJR26YGuQ {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	padding-right: 16px;
	padding-bottom: 16px;

	border-top: 1px dashed #bababa;
}

._2y8wUF2y7nIb {
	font-size: 13px;
	font-weight: 500;
}

._3ImfKfXMI5sI {
	font-size: 25px;
	font-weight: 700;
}

._2-J3P9B0hBh- {
	margin-top: 20px;

	text-align: center;
}

.ljuSa8TkDxj8 {
	margin-top: 16px;
	margin-left: 20px;
}

._34PDuU10Yuhz {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-right: 20px;
	margin-bottom: 20px;
}

._1v-1JRTt6d5r {
	margin-right: 12px;

	font-weight: 500;
	font-size: 18px;
}

.sVqp-mCN6kRP {
	color: var(--base-15);
	font-weight: 700;
}
