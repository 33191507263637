

label._19WCNPc-UgPV.ErXqflYk0Yaf {
	border: 1px solid var(--leo-input-success-color);

	color: var(--leo-input-success-color);

	background: var(--leo-input-bg-success-color);
}

label._19WCNPc-UgPV._32oueCWKEuJI {
	border: 1px solid var(--warning-stroke-hover);

	color: var(--error-text);

	background: var(--warning-bg);
}
