

._2onarGXyLL4J {
	position: relative;

	min-width: 190px;
	min-height: 170px;
	margin-right: 2px;
	margin-bottom: 2px;
	padding: 11px;

	box-shadow: none;
	background-color: var(--gray);

	transition: box-shadow 150ms ease;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

._3xuWkKlPKZqY {
	display: none;
}

._3ts7PdEx9tPZ {
	background-color: var(--base90);
}

._2onarGXyLL4J:not(._1B72H5SlHYxZ):hover {
	box-shadow: inset 0 0 0 2px var(--base);
}

._1B72H5SlHYxZ {
	opacity: 0.4;
}

._7sMwpTbVXHVW {
	display: flex;
	align-items: flex-end;

	font-size: 24px;
	line-height: 1;
	font-weight: 700;
	color: var(--gray30);
}

._7sMwpTbVXHVW span {
	display: inline-block;
	margin-left: 4px;
}

._1Tpp4b_j17kT {
	display: grid;
	grid-template-columns: auto;
	grid-auto-flow: column;
	min-height: 82px;
	grid-gap: 3px;
	width: 100%;
	margin-top: 14px;
}

._1_ZiMKSoENgC {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: flex-start;
	padding: 6px;

	background: var(--bg-fill);
}

._1A5bjn8cIe1d {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
}

.hfAVLD88e-wC {
	line-height: 20px;
	font-weight: 400;
	font-size: 14px;
	color: var(--gray50);
	text-decoration: none;
}

a._1A5bjn8cIe1d:hover,
a.hfAVLD88e-wC:hover,
a._2h-R4HM6wF1g:hover {
	color: var(--active-50);
	text-decoration: underline;
}

._3ICTGYgF9nRO {
	display: inline-block;
	width: 100%;
	margin-top: 8px;

	border: 0;

	color: var(--base);
	font-weight: 500;
	font-size: 16px;
	text-align: right;
	text-decoration: none;

	cursor: pointer;
	background-color: transparent;
	background-color: var(--schedule-calendar-day-background, transparent);
}

._3ICTGYgF9nRO:hover {
	text-decoration: underline;
}

._3cYChafXtsMP {
	color: var(--active-50);
}

._2onarGXyLL4J._3g6Hcbq6fInw {
	color: var(--error);
}

._3cYChafXtsMP * {
	color: var(--active-50);
}

._2onarGXyLL4J._3g6Hcbq6fInw * {
	color: var(--error);
}

.fv1yoBmy-N_E ._3uoZdIDOEjsq span svg {
	color: var(--text-pale);

	transform: scale(0.5);
}

.dRzwJGWPghpB {
	width: 100%;
	height: 49px;

	font-weight: 700;
	font-size: 20px;
}

._3uoZdIDOEjsq {
	padding: 32px;
	width: 653px;
}

.knVnX6sMbiNL {
	display: grid;
	grid-gap: 2px;

	width: 100%;
	margin-bottom: 4px;
	padding: 8px;

	cursor: pointer;
	background-color: var(--bg-fill);
}

._3-fvSzGbLC64 {
	color: var(--text-link);
}

.knVnX6sMbiNL:hover {
	background-color: var(--brand-1-5);
}

.stxQuz63NLBC {
	width: 70px;

	font-weight: 700;
	font-size: 18px;
}

.HZwjAP_1cxsQ {
	width: 70px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 14px;
}

._1lfeJh2EnMMr {
	flex-grow: 1;
	width: 241px;

	color: var(--text-pale);
	font-weight: 700;
	font-size: 19px;
	text-align: end;
}

._1lfeJh2EnMMr > span {
	margin-right: 13px;
}

._2l6Ed03EdkMO {
	display: flex;
	align-items: center;

	line-height: normal;

	line-height: initial;
}

._2l6Ed03EdkMO:not(:first-child) .stxQuz63NLBC {
	color: var(--text-pale);
}

._1tdLccZmqmyd {
	text-align: center;
	font-size: 14px;
	color: var(--error-text);
}

._2yEisl69ZpBi {
	position: relative;
	top: -8px;
	left: 2px;

	color: var(--error-text);
	font-size: 12px;
}

.SiOjAPz_YGgQ {
	display: flex;
	align-items: center;
}

@media (max-width: 1400px) {
	._2onarGXyLL4J {
		padding: 20px 15px;
	}

	._1_ZiMKSoENgC {
		font-size: 14px;
	}
}
