._1W3o27d-OEYN {
	font-size: 0;
}

._1RUR4ZzvZgwf {
	padding: 40px;

	font-size: 36px;
	font-weight: 300;
	color: var(--common-gray);
}

._3-LssJkaN6Cv {
	padding: 45px 40px 15px 40px;
}

._34qG7qqkInP6 {
	display: block;
	margin-top: 2px;
}

.MNdx7GVlXWH1 {
	margin: 30px 40px 0;
	padding: 25px 0 !important;

	border-top: 1px solid #cdcdcd !important;
	border-bottom: 1px solid #cdcdcd !important;
	border-radius: 0 !important;

	color: var(--dark-gray);
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
	text-align: justify;

	box-shadow: none !important;
}

._34alMkFKQfEN {
	display: flex;
	justify-content: space-between;
	padding: 25px 40px !important;

	font-size: 20px;
}

.YmVcE22kYmHX {
	display: flex;
	flex-flow: column;
	width: 49%;

	font-size: 13px !important;
	line-height: 24px !important;
}

li._3bGlfTPjkQPj {
	padding: 0;
	justify-content: space-between;
	margin-right: 0;
	width: 100%;
}

li._3bGlfTPjkQPj:nth-of-type(n + 6) {
	margin-right: 0;
}

li._3bGlfTPjkQPj:nth-of-type(n + 9) {
	margin-left: 20px;
}

._1D8J9VgijbAM {
	padding-right: 20px;

	color: var(--dark-gray);
	white-space: pre;
}

._3jNSvu8YUkwj {
	flex: 1;
	max-width: 330px;

	font-weight: 300;
	text-align: right;
}

._1LMLW0_kjOet {
	width: 100%;
}

._2LUqvXtz5eLK {
	height: 100%;
	min-height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

._2kE4kle9YW4S {
	color: #b7b7b7 !important;
}

@media screen and (max-width: 600px) {
	._3-LssJkaN6Cv {
		padding: 0;
	}

	._1D8J9VgijbAM {
		flex: 1;

		white-space: normal;
	}

	._3jNSvu8YUkwj {
		flex: 1;
	}

	._34alMkFKQfEN {
		flex-direction: column;
	}

	ul.YmVcE22kYmHX {
		max-height: none;
		width: 100%;

		padding: 0;

		columns: 1;
	}

	li._3bGlfTPjkQPj {
		margin: 5px;
	}

	li._3bGlfTPjkQPj:nth-of-type(n + 9) {
		margin-left: 0;
	}
}

@media (max-width: 1024px) and (min-width: 601px) {
	.YmVcE22kYmHX {
		columns: 1;
	}
}
