

._1eFdysBa6CP4 {
	display: flex;
}

._3oaUfHDPRE3F {
	position: relative;

	height: 33px;

	font-weight: 500;
	font-size: 17px;
	line-height: 1.3;

	opacity: 0.7;

	transition: opacity 0.1s linear;
}

._3oaUfHDPRE3F:before {
	content: '';

	position: absolute;
	bottom: 1px;
	left: 50%;

	display: block;
	width: 0;
	height: 2px;

	background: var(--white);

	transform: translate(-50%);

	transition: width 0.1s linear;
}

._3oaUfHDPRE3F + ._3oaUfHDPRE3F {
	margin-left: 31px;
}

._2VEQY0CvH3Tp {
	opacity: 1;
}

._2VEQY0CvH3Tp:before {
	width: calc(100% - 24px);
}

._1dBniYM-dkWi {
	color: inherit;
	text-decoration: none;
}
