._35avxD5eUR1V {
	position: absolute;
	top: 12px;
	right: 12px;
	z-index: 1;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;

	border-radius: 50%;

	cursor: pointer;

	background: rgba(0, 0, 0, 0.1);
}

._35avxD5eUR1V:before,
._35avxD5eUR1V:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 45%;
	height: 2px;

	background-color: var(--white);
	transform: translate(-50%, -50%) rotate(-45deg);
}

._35avxD5eUR1V:after {
	transform: translate(-50%, -50%) rotate(45deg);
}

._2XrDvNQw4X5P {
	position: relative;

	padding: 32px 40px;

	border-radius: 8px 8px 0 0;

	color: var(--white);

	background-color: var(--gray20);
}

._2XrDvNQw4X5P:after {
	content: '';

	position: absolute;
	top: 50%;
	right: 20px;

	max-width: 30%;
	width: 100%;
	height: 75%;

	transform: translateY(-50%);
}

._3HF5jNNgdPUS {
	display: flex;
	flex-direction: column;
}

._2XFsJIl8eLuB {
	max-width: 65%;

	font-weight: 700;
	font-size: 28px;
	line-height: 1.2;
}

._2TCgS_t7bOH9 {
	margin-top: 4px;
}

@media screen and (max-width: 600px) {
	._2XrDvNQw4X5P {
		padding: 20px;
	}
}
