._1s_uSryOwIJw {
	height: 32px;

	line-height: 32px;
}

@media screen and (max-width: 600px) {
	._1s_uSryOwIJw {
		height: auto;

		line-height: 24px;
	}
}

._1Hq58AlRG5tL {
	position: relative;

	display: inline-block;
	padding-right: 15px;

	font-weight: 500;
	font-size: 14px;

	cursor: pointer;
}

._1Hq58AlRG5tL:after {
	position: absolute;
	top: 50%;

	width: 10px;
	height: 5px;

	transform: translate(5px, -50%);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDEwIDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+Cgk8cGF0aCBkPSJNMCAwTDUgNUwxMCAwTDAgMFoiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==) right;

	transition: transform 0.15s;
	content: '';
}

div._3mPOlwt_DJWc {
	padding: 0;
}

@media screen and (max-width: 600px) {
	._1Hq58AlRG5tL {
		padding-left: 32px;

		color: var(--common-blue);
	}

	._1Hq58AlRG5tL:before {
		position: absolute;
		top: 50%;
		left: 0;

		width: 24px;
		height: 24px;

		transform: translate(0, -50%);
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMyA2VjhIMjFWNkgzWk05Ljk5OTkzIDE4SDEzLjk5OTlWMTZIOS45OTk5M1YxOFpNMTggMTNINlYxMUgxOFYxM1oiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==);

		transition: transform 0.15s;
		content: '';
	}

	._1Hq58AlRG5tL:after {
		display: none;
	}

	._1VeIqnngDj3h {
		display: none;
	}
}

._2MYHRz8xTMng:after {
	transform: translate(5px, -50%) rotate(180deg);
}

._1ykyfdZ9W3nV {
	color: var(--common-blue);
}

div._15U-Eu7f3krY {
	min-width: 185px;
	margin-top: 40px;

	border-radius: 8px;
}

li._3HHmrTLxk4TS {
	padding: 4px 16px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 14px;
	font-family: inherit;
}

li._3HHmrTLxk4TS._1hrRxkxMn2iq {
	position: relative;

	color: var(--common-blue);

	background: none;
}

li._3HHmrTLxk4TS:hover,
li._1hrRxkxMn2iq {
	background: #ededed;
}

._1hrRxkxMn2iq:after {
	position: absolute;
	top: 0;
	right: 8px;
	bottom: 0;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNTk5OTggMTQuNEw1LjM5OTk4IDEwLjJMMyAxMi44TDkuNTk5OTggMTkuNEwyMS42IDcuMzk5OTlMMTkuMiA1TDkuNTk5OTggMTQuNFoiIGZpbGw9IiMzMjkwRkYiLz4KPC9zdmc+Cg==) center no-repeat;
	content: '';
}

.BPjYNB8deahF {
	display: flex;
	flex-direction: column;
}

.kBta_BQtA9zJ {
	display: flex;
}

button._2qPeIdND55Si {
	margin-right: 18px;
	margin-bottom: 20px;
	margin-left: auto;

	font-weight: 600;
	font-size: 19px;
	line-height: 20px;
}

._1_3AqrJwDRDY {
	margin: 0;
	padding: 0;
	width: 100%;
}

.NqmcO6vijIjZ {
	display: inline;
	display: initial;
}

._2adzE1d83uVt {
	display: inline;
	display: initial;
}

._1_3AqrJwDRDY:not(:last-of-type) {
	margin-right: 20px;
}

._1LMbNJ_Oed8Z {
	position: relative;

	padding: 10px 20px;

	border-radius: 12px 12px 0 0;

	color: #414141;
	font-weight: 500;
	font-size: 20px;
	line-height: 34px;

	background: #f0f0f0;
}

._2C8h8qvjlu_D {
	position: absolute;
	top: 50%;
	right: 20px;

	display: inline-block;
	width: 24px;
	height: 24px;

	transform: translate(0, -50%);
	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjkwOTkgMS40MDk5NUwxMC40OTk5IC01LjM0MDU4ZS0wNUw1Ljk5OTk1IDQuNTlMMS40MDk5NSAtNS4zNDA1OGUtMDVMLTUuMzQwNThlLTA1IDEuNDA5OTVMNC41ODk5NSA2TC01LjM0MDU4ZS0wNSAxMC41OUwxLjQwOTk1IDEyTDUuOTk5OTUgNy40MUwxMC41ODk5IDEyTDExLjk5OTkgMTAuNTlMNy40MDk5NSA2TDExLjkwOTkgMS40MDk5NVoiIGZpbGw9IiM5QTlBOUEiLz4KPC9zdmc+Cg==) center no-repeat;
	content: '';
}

._2tFsmcV-wmvm {
	list-style-type: none;
}

._2tFsmcV-wmvm:not(:first-of-type) {
	border-top: 1px solid #e4e4e4;
}

button._1aWS8JZbaWD3 {
	position: relative;

	justify-content: flex-start;
	width: 100%;
	height: auto;
	padding: 16px 20px;

	border-radius: 0;

	color: #ffffff;
	font-weight: 500;
	line-height: 24px;
	font-size: 16px;
}

button._1aWS8JZbaWD3:hover {
	background: #3e90ff;
}

button._1aWS8JZbaWD3:not(._1Qee9jPdtB1w):before {
	position: absolute;
	right: 20px;

	display: inline-block;
	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS40NjQxIDE3LjY3NzNMMjIuMDcwNyA3LjA3MDY2TDE5Ljk0OTMgNC45NDkzNEw5LjM0Mjc0IDE1LjU1NTlMNC4zOTM0IDEwLjYwNjZMMi4yNzIwOCAxMi43Mjc5TDcuMjIxNDIgMTcuNjc3M0w5LjM0Mjc0IDE5Ljc5ODZMOS4zNDMxNSAxOS43OTlMMTEuNDY0NSAxNy42Nzc3TDExLjQ2NDEgMTcuNjc3M1oiIGZpbGw9IiNDNEM0QzQiLz4KPC9zdmc+Cg==) no-repeat center;
	content: '';
}

button._1Qee9jPdtB1w {
	color: #414141;

	background-color: #ffffff;
}

button._1Qee9jPdtB1w:hover {
	background-color: #ffffff;
}

.lNvpCxn6q6zB {
	padding: 28px 20px 32px 20px;

	border-top: 1px solid #e4e4e4;
}

._2iEFBOvIOTUp {
	width: 100%;
	padding-left: 0;

	color: #727272;
	line-height: 24px;
}

._23MyKWGxd7n_ {
	font-size: 16px;
}

._2iEFBOvIOTUp:after {
	background: #dadada;
}

._2eTmHY1YvQle:after {
	background: var(--switch-default-background);
}
