

._2rH-JsqzX7Tw {
	margin-bottom: 20px;

	font-weight: 600;
	font-size: 28px;
}

._1tFweRVXqVJ3 {
	width: 780px;
	padding: 40px;
	box-sizing: border-box;
}

._3UmuWxaE6mYT {
	font-size: 19px;
	font-weight: 500;
}
