

div._3wVrH153r2F4 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 16px;
}

div._1GZq-HX16RWg {
	color: var(--text-pale);
}

div._3wVrH153r2F4._186DJ8-DcqWF {
	border-radius: 50%;

	color: var(--bg-white);

	background: var(--brand-1-0);
}
