.vuo0zDC5k01I {
	padding: 40px 0;

	border-top: 1px solid #b6b6b6;

	background: #ededed;
}

._2obuIxdRp1RP {
	display: flex;
	overflow-y: auto;
}

._2obuIxdRp1RP > div {
	margin-right: 20px;
}

._2obuIxdRp1RP > div:first-child {
	margin-left: 40px;
}

._2obuIxdRp1RP > div:last-child {
	margin-right: 40px;
}

._2obuIxdRp1RP:after {
	content: '';

	min-width: 1px;
	height: 1px;
}

._2-X1WW9RB6Bn {
	display: flex;
	justify-content: space-between;
}

div.Xk7w48MqNCU1 {
	max-width: 1100px;
	overflow: visible;

	width: -moz-fit-content;

	width: fit-content;
}

div._1rI4lQT2YpzQ {
	position: relative;
}

a._2V8cofeEgMkq {
	display: flex;
	align-items: center;

	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	text-decoration: none;
}

a._2V8cofeEgMkq svg {
	padding-right: 4px;
}

.H_ljIG_QS8xa {
	padding: 20px 40px;

	display: flex;
	align-items: center;
}

._1LUnfJcUgeeu {
	flex: 1;

	text-align: right;
}

._2APzL7ddROi6 {
	padding: 10px 20px 34px;
	margin: 0 40px;
	margin-top: 20px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
	background: #ffffff;
}

div.epOge8dC-bNT {
	background: #f0f0f0;
}

button._2fW0NUsw_sxS,
button.bwlMDUX3hQJP {
	width: 183px;
	height: 48px;

	border-radius: 2px;

	font-size: 14px;
}

button.bwlMDUX3hQJP {
	margin-right: 7px;

	color: #9a9a9a;

	background-color: #ededed;
}

button.bwlMDUX3hQJP:hover {
	background-color: #dadada;
}

@media screen and (max-width: 600px) {
	.vuo0zDC5k01I {
		margin-top: 20px;
		padding: 0;
		padding-bottom: 20px;

		border-top: none;

		background: none;
	}

	._2obuIxdRp1RP > div:first-child {
		margin-left: 20px;
	}

	._2obuIxdRp1RP > div:last-child {
		margin-right: 20px;
	}

	._2APzL7ddROi6 {
		padding: 0 25px;
	}
}
