

.yAksatzPkKbw {
	color: #292929;
	line-height: 22px;
	font-size: 18px;
	font-weight: 500;
}

._1FRM2HKjaAMl {
	color: #9a9a9a;
	font-size: 13px;
	font-weight: 400;
}

._15SBcYBCNANA {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
	padding: 12px 23px 12px 20px;

	border-radius: 8px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
	background: #ffffff;
}

._2XX_fG-bnPvt,
._1JqHA0ierxk9 {
	display: flex;
	flex-direction: column;
}

.Dyis0XSi2ep1 {
	position: relative;
	top: 3px;

	display: inline-block;
	width: 16px;
	height: 16px;
	margin: 0 8px;

	color: #5ba6ff;
}

._2XX_fG-bnPvt,
._29-9aULfniug {
	flex-grow: 1;
}

._2XX_fG-bnPvt {
	padding-right: 17px;
	max-width: 130px;
}

._29-9aULfniug {
	display: flex;
	justify-content: flex-end;
	margin-top: 1px;
	max-width: 121px;
	padding-left: 17px;
	margin-right: -11px;
}

._1JqHA0ierxk9 {
	position: relative;

	flex: 1;
	padding-left: 20px;
}

._1JqHA0ierxk9:before,
._1JqHA0ierxk9:after {
	position: absolute;
	top: 50%;

	width: 1px;
	height: 46px;

	transform: translate(0, -50%);
	background: #d1d1d1;
	content: '';
}

._1JqHA0ierxk9:before {
	left: -1px;
}

._1JqHA0ierxk9:after {
	right: -1px;
}

._2Y5bFAB0nmQi {
	display: flex;
}

._32D_VJhYC_4A,
._2j23GY4RRAf3,
._3vEOCVzOB8fL,
._2W4Guy4yBST1 {
}

._2W4Guy4yBST1 {
	font-size: 18px;
	font-weight: 500;
}

._3vEOCVzOB8fL {
	font-size: 14px;
	line-height: 17px;

	cursor: pointer;
}

span._3Tlp1UZX5i7P {
	position: relative;
	top: -1px;
}

span._3cZivsBG_qnA._3Tlp1UZX5i7P {
	color: var(--common-blue);
}
