

.SSb4AHCi2Tsm:not(:first-of-type) {
	margin-top: 16px;
}

._17Oh81HQTy8q {
	display: flex;
	align-items: center;
}

.xpsnXPoF_6dl {
	position: relative;

	color: #1f1f1f;
	font-weight: 700;
	font-size: 22px;
	font-family: 'Roboto Condensed', sans-serif;
}

.xpsnXPoF_6dl:first-of-type {
	padding-right: 30px;
}

.xpsnXPoF_6dl:first-of-type:after {
	position: absolute;
	top: 7px;
	left: 100%;
	z-index: 1;

	width: 7px;
	height: 7px;

	border: 1px solid #a5a5a5;
	border-radius: 50%;

	background: #ffffff;
	content: '';
}

.xpsnXPoF_6dl:last-of-type {
	padding-left: 30px;
}

.xpsnXPoF_6dl:last-of-type:after {
	position: absolute;
	top: 7px;
	right: 100%;
	z-index: 1;

	width: 7px;
	height: 7px;

	border: 1px solid #a5a5a5;
	border-radius: 50%;

	background: #ffffff;
	content: '';
}

._1KO84Hd6vSG7 {
	position: relative;

	flex-basis: 100%;

	font-size: 14px;
	text-align: center;
}

._1KO84Hd6vSG7:before {
	position: absolute;
	top: 7px;
	right: 0;
	left: 0;

	border-bottom: 1px solid #a5a5a5;
	content: '';
}

._3qTHyY4CYX1U {
	position: relative;

	padding: 0 8px;

	font-weight: 500;
	font-size: 15px;

	background: #ffffff;
}

._3Ya3bFLEpCZo {
	display: flex;
	margin-top: 5px;
	justify-content: space-between;
}

._2tILlawQn0EU {
	max-width: 50%;

	color: #1f1f1f;
	font-weight: 500;
	font-size: 14px;
}

.Yjg9wzcjc-Yw {
	line-height: 20px;
}

._2xg4-23KKdc8 {
	font-size: 13px;
	line-height: 16px;
	font-weight: 400;

	opacity: 0.7;
}

._2tILlawQn0EU:last-child {
	text-align: right;
}

._36crd4nLu7jq {
	margin-top: 12px;

	border-top: 1px dashed #d9d9d9;
}

._1ClmXHaDj9Wk {
	font-size: 13px;

	opacity: 0.7;
}

._1ClmXHaDj9Wk span:not(:first-child) {
	padding-left: 5px;
}

._1ClmXHaDj9Wk span:not(:first-child):before {
	content: '\2022';

	padding-right: 5px;
}

._2TxuErUofgAE {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px 20px;

	font-size: 13px;
	line-height: 20px;
}

._2TxuErUofgAE span:last-of-type:not(:first-of-type) {
	margin-top: 4px;
	margin-left: 0;
}

._2TxuErUofgAE svg {
	position: relative;
	top: 2px;
}
