.Uev4_ZLK10rV {
	height: 300px;

	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

@media screen and (max-width: 600px) {
	.Uev4_ZLK10rV {
		height: 200px;
	}
}

._3AqVCklFASua {
	background-color: #f0f0f0;
}

._1y_5EhXh_IeY {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 40px;

	font-size: 15px;
}

@media screen and (max-width: 600px) {
	._1y_5EhXh_IeY {
		padding: 20px;
	}
}

._1ayVCKxIdgfC {
	padding: 24px 40px;

	border-top: 1px solid #d9d9d9;

	color: var(--common-gray);
	font-size: 16px;
	line-height: 150%;
}

@media screen and (max-width: 600px) {
	._1ayVCKxIdgfC {
		padding: 24px 20px;
	}
}

._3ugw8HBi1jbk {
	margin-top: 12px;
}

._3Nt6cegRb4zf {
	font-weight: 600;
}

._3-y9YY6PlstB {
	margin: 0;

	font-size: 15px;
	font-weight: 600;
	line-height: 150%;
	text-transform: uppercase;
}

._2jxUbnzdds2O {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-left: 25px;

	line-height: 23px;
}

.ZHwKwUES-w-- {
	padding-left: 0;

	font-weight: 600;
	color: var(--common-blue);
	text-transform: uppercase;
}

._1BVvHcLVe4AA {
	padding: 24px 40px 12px;

	color: var(--common-gray);
	font-size: 16px;
	line-height: 150%;
}

@media screen and (max-width: 600px) {
	._1BVvHcLVe4AA {
		padding: 24px 20px;
	}
}
