._3iOMmaeLcs1e {
	margin-top: 15px;
	justify-content: space-between;
	align-items: center;
}

._1U1PGBwoJdHM {
	display: flex;
}

@media screen and (max-width: 600px) {
	._3iOMmaeLcs1e {
		position: relative;

		padding-bottom: 60px;
	}
}
