._2KqvTsopVA-v {
	max-width: 1100px;
	margin: 0 auto;

	border-radius: 4px;

	color: #ffffff;

	background: var(--mobileSummaryFlight--background);
}

._2KqvTsopVA-v._1J2CBIXoMdRd {
	background: linear-gradient(180deg, #6a86e6 0%, #3b56b2 100%);
}

._164RusbQmY-v {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;

	border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
}

._3sy5zMPlUqNM {
	padding: 20px 20px 34px;
}

._1-4YMTUllN5y {
	display: flex;
	align-items: center;
}

._2IRaFsLAeCA4 {
	margin: 0 20px;

	transform: rotate(180deg) translateY(-5px);
}

.NxltXxOfdShf {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.Q6eEdlFJ6fRt {
	margin-bottom: 5px;

	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
}

.iyNhKSlQYupo {
	font-size: 40px;
	line-height: 48px;
}

._3J5QbxS8XK7d {
	font-weight: 600;
}

._2Rga2tBnuWJJ {
	margin-left: 8px;

	font-weight: 300;
}

.mfG9f-3GUJWw {
	position: absolute;
	bottom: 10px;

	display: flex;
	align-items: center;

	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}

._2IS6Wf4qLMxu {
	display: flex;
	align-items: center;
	margin-right: 6px;
}

.S6nPtQIqG1oE {
	display: flex;
	align-items: center;
}

._1baMzu90vyA3 {
	display: flex;
	flex-direction: column;
}

._1baMzu90vyA3:last-child {
	margin-left: 61px;
}

._1PlrCuQ6V7V_ {
	margin-bottom: 5px;

	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}

._2Ru8iPh61rap {
	font-size: 40px;
	font-weight: 300;
	line-height: 48px;
}

.UDAKopfmDlly {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
}

.z4jHaP71blPv {
	display: flex;
	align-items: center;
}

._1SI3_IlydkYc {
	display: inline-block;
	height: 28px;
	width: 28px;
	margin-right: 12px;

	background-position: center;
	background-size: cover;
}

.xEjOYv1m3kL5 {
	font-size: 16px;
	line-height: 19px;
	text-transform: capitalize;
}

.z4jHaP71blPv > span:first-child {
	display: flex;
	align-items: center;
}

._1055DwAzXTsG {
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
}

._1055DwAzXTsG:not(:last-child) {
	padding-right: 16px;
	margin-right: 16px;

	border-right: 1px solid rgba(255, 255, 255, 0.5);
}

@media (max-width: 1024px) {
	.UDAKopfmDlly {
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
	}

	.z4jHaP71blPv {
		justify-content: space-between;
		width: 100%;
		padding: 16px 20px;

		border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
	}

	.UDAKopfmDlly > div:last-of-type {
		padding: 16px 20px;
		display: flex;
		width: 100%;
	}

	.xEjOYv1m3kL5 {
		font-size: 14px;
		line-height: 16px;
	}

	.UDAKopfmDlly ._1055DwAzXTsG {
		display: flex;
		flex-direction: column;

		border-right: none;
	}

	.UDAKopfmDlly ._1055DwAzXTsG:nth-child(2) {
		margin-right: auto;
	}

	._1055DwAzXTsG > span:first-child {
		margin-bottom: 9px;

		font-size: 14px;
		line-height: 16px;
	}

	._1055DwAzXTsG > span:last-child {
		font-size: 20px;
		line-height: 150%;
		font-weight: 500;
	}
}

@media screen and (max-width: 768px) {
	._1-4YMTUllN5y {
		width: 100%;
		justify-content: space-between;
	}
}
