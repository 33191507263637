

._3daar48K0Ulm {
	display: flex;
	flex-direction: column;
	margin-bottom: 4px;

	border-radius: 4px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);

	overflow: hidden;
}

.IqwUshuyCXtE {
	display: flex;
	flex-direction: column;
	padding: 12px;

	background: #ffffff;
}

._3NhseLjW3DJ- {
	margin-bottom: 11px;

	font-size: 16px;
	line-height: 150%;
	color: var(--dark-gray);
	font-weight: 500;
}

.-ifFYqATkpiw {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}

._3Z5yWSqjUkeX {
	height: 24px;
	width: 24px;
	display: flex;
	align-items: center;
	margin-right: 9px;

	color: var(--common-blue);
}

._3WCDxxIoNz0g {
	font-size: 13px;
	line-height: 150%;
	color: var(--dark-gray);
}

._1A_GdS17sdMB {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 12px;

	color: var(--common-blue);
	font-size: 13px;
	font-weight: 400;
	line-height: 15px;
	text-decoration: underline;
}

div._2MhwNSvCcQ5- {
	max-width: 100%;
	height: 55px;
	padding-left: 0;

	border-bottom: none;

	font-weight: 500;

	font-size: 20px;
	line-height: 150%;

	background: var(--white);
	overflow: hidden;
}

div._3-q2dIfvvQJA {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;

	border-radius: 50%;

	color: var(--gray80);

	background-color: var(--gray05);
}

div._3-q2dIfvvQJA > svg {
	width: 10px;
	height: 10px;
}

._2MhwNSvCcQ5- > div > div {
	display: flex !important;
	align-items: center;
}

div._1_HRLvGuRvPo {
	margin-top: 55px;
}
