._2O5gt0wnccJj {
	display: flex;
	justify-content: space-between;
	margin-top: 32px;
	margin-bottom: 36px;
}

._1RybkiLKuWCO {
	display: flex;
	align-items: center;

	color: var(--gray80);
	font-weight: 600;
	font-size: 24px;
}

._1RybkiLKuWCO svg {
	margin: 0 10px;

	color: var(--base);
}

._1x2eNEHzoWlE {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin-right: 14px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 600;
	font-size: 17px;

	background: #51a1ff;
}

._2svAb1i_ry80 {
	margin-bottom: 24px;
}

.D8kX1nmhHfgW {
	padding: 25px 0;
}

.lCfy9ZhD3ff2 {
	margin: 25px 0;
}

@media (max-width: 1024px) {
	._1RybkiLKuWCO {
		margin-bottom: 16px;

		color: var(--gray80);
		font-size: 20px;
	}

	._2E5FplNV1Cd2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		padding: 0 12px;

		border-bottom: 1px solid #ffffff;
		border-radius: 8px 8px 0 0;

		color: #ffffff;
		font-weight: 500;
		font-size: 15px;

		background: #2a7ad9;
	}

	.BJ3TUPQUwoRs {
		height: 50px;
		display: flex;
	}
}
