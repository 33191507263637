

._3_c1hLwLZh4E {
	display: inline-block;
	padding-right: 15px;
}

._3_c1hLwLZh4E:last-child {
	padding-right: 0;
}

._1KuC917_QCEd {
	display: flex;
}
