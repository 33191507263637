div._2vP8ZseG9Cvk {
	padding: 40px;
	width: 100%;
	max-width: 1390px;

	border-radius: 16px;

	background: var(--gray05);
}

div._2mVqTvHRqosl {
	max-width: 100% !important;
	min-height: auto !important;
	overflow: auto !important;

	border-radius: 0 !important;
}

@media screen and (max-width: 1390px) {
	div._2vP8ZseG9Cvk {
		max-width: 1230px;
	}
}

@media (min-width: 1025px) {
	div._2vP8ZseG9Cvk {
		min-height: 780px;
	}
}

@media (max-width: 1024px) {
	div._2vP8ZseG9Cvk {
		width: 1024px;

		border-radius: 0;
	}

	div._2gABUS1Iyds3 {
		padding: 0;
	}
}

@media screen and (max-width: 600px) {
	div._2vP8ZseG9Cvk {
		width: 100%;
		padding: 0;
	}
}

div._2vP8ZseG9Cvk > span {
	background: #ffffff !important;
	transform: scale(0.9);
}

.hBPTS77qbf5C svg {
	transform: scale(0.6);
}

@media screen and (max-width: 600px) {
	.hBPTS77qbf5C {
		width: 28px;
		height: 28px;
	}

	.hBPTS77qbf5C svg {
		position: relative;
		top: -1px;

		transform: scale(0.9);
	}
}
