

._10JdeR_ty7KI {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding: 11px 28px;
}

._10JdeR_ty7KI:hover {
	cursor: pointer;
	background: var(--base90);
}

._26ePW8rd-nPp {
	color: var(--gray50);
	line-height: 21px;
}

._35wrAujH8asV {
	font-weight: 500;
	color: var(--gray50);
	text-decoration: none !important;
}

._26ePW8rd-nPp:hover,
._26ePW8rd-nPp:hover ._35wrAujH8asV {
	color: var(--base-15);
}

._2Ch-hYZBv9ky ._26ePW8rd-nPp ._35wrAujH8asV,
._2Ch-hYZBv9ky ._26ePW8rd-nPp:hover ._35wrAujH8asV {
	color: var(--base-30);
}

.eqFDSS2xTrPF {
	color: var(--brand-1-0);

	line-height: 130%;

	-webkit-text-decoration-line: underline;

	        text-decoration-line: underline;
	font-weight: 700;
	font-size: 22px;
}

div._3auCJwOK77CR {
	padding: 12px;

	transform: translateY(5px) !important;
}

div._3auCJwOK77CR._9daXC6evdjRf {
	transform: translateY(40px) !important;
}

._3auCJwOK77CR:after {
	top: -14px;
	bottom: auto;

	transform: translate(-50%, calc(100% - 1px)) rotate(180deg);
}

._23OoInIkBS3C {
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;

	color: #ffffff;
	font-size: 14px;
	line-height: 18px;
}

._23OoInIkBS3C._3PYzeP8YvSFX {
	color: var(--warning);
}

._1h7tfXGu6KLg {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	color: inherit;
	font-weight: 400;
	text-decoration: none;
}

._2Ch-hYZBv9ky ._1h7tfXGu6KLg {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	color: var(--base-30);
}

._1h7tfXGu6KLg:hover {
	text-decoration: none;
}

._1h7tfXGu6KLg:hover ._38BaqtP2APvh {
	text-decoration: underline;
}

.xDwD_6xwZcmg {
	color: var(--gray90);
	font-weight: 700;
	font-size: 18px;
	line-height: 130%;
}

._2vMevtgkIClB {
	margin-bottom: 3px;

	color: var(--text-link);
	font-weight: 500;
	font-size: 14px;
}

._115ZArnTKGfC {
	font-size: 24px;
	font-weight: 700;
	color: var(--text-base) !important;
}

._3xPHKZxBOfF8 {
	font-size: 14px;
	font-weight: 700;
}

._2lVWbXMPZpxQ {
	display: flex;
	justify-content: flex-start;
	grid-gap: 5px;
	gap: 5px;
	margin: 10px 0;
	padding-bottom: 10px;
	align-items: center;

	border-bottom: 1px dashed var(--text-light);
}

._2lVWbXMPZpxQ span {
	min-width: 24px;
	min-height: 30px;
	display: inline-flex;
	justify-content: center;
}

._2lVWbXMPZpxQ svg path {
	color: var(--additional-checkin);
}
