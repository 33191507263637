._17W7aj_hDWsd {
	position: relative;

	padding: 30px 32px 32px;

	border-radius: 20px;

	background-color: var(--white);
	overflow: hidden;
}

._3knlXOzFu1gv {
	position: absolute;
	top: 30px;
	right: 32px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	cursor: pointer;
	background-color: #f6f6f6;
}

._3knlXOzFu1gv:before,
._3knlXOzFu1gv:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 50%;
	height: 1px;

	background-color: var(--gray80);
	transform: translate(-50%, -50%) rotate(-45deg);
}

._3knlXOzFu1gv:after {
	transform: translate(-50%, -50%) rotate(45deg);
}

._3Cq0XoUOyh_1 {
	margin-bottom: 22px;
	padding-right: 40px;

	color: #262626;
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

._3r8zDjm9LGup {
	display: flex;
	flex-direction: column;
}

._1j3miMJiBmaH {
	display: flex;

	border-bottom: 1px solid #d9d9d9;
}

._1j3miMJiBmaH:nth-child(even) {
	background-color: #f6f6f6;
}

._3FKoaPX_H54V {
	flex: 1;
	padding: 12px;
}

._3r8zDjm9LGup ._3FKoaPX_H54V:last-child {
	flex: 2;
}

._3NOsgp7YReq7 {
	display: flex;
	flex-direction: column;

	color: var(--white);
	font-size: 13px;
	font-weight: 700;
	line-height: 1.3;
}

._3NOsgp7YReq7 ._1j3miMJiBmaH {
	background-color: var(--base);
}

@media screen and (max-width: 600px) {
	._17W7aj_hDWsd {
		padding: 20px;
	}

	._3knlXOzFu1gv {
		top: 20px;
		right: 20px;
	}

	._3r8zDjm9LGup {
		overflow: scroll;
	}

	._1j3miMJiBmaH {
		min-width: 780px;
	}
}
