.UdXr_TM7PmkC {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px;

	width: 100%;

	border-radius: 4px;

	color: var(--gray50);

	background-color: #ffffff;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
}

._36AOlO8BNNKW {
	display: flex;
	justify-content: space-between;
}

.IWQsNk7gClpX {
	display: flex;
	align-items: center;

	font-size: 14px;
}

._1sjZJVG73Xo_ {
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

@media screen and (max-width: 320px) {
	._1e1ZZg1wjgis {
		display: none;
	}
}

._2jnmIPTDJRle {
	display: flex;
	align-items: center;
}

._1O_efYarV32j:not(:last-of-type) {
	margin-right: 12px;
}

._3Umzh9jctvE6 {
	font-size: 13px;
}

.ivV6bz26BYO6 {
	margin-top: 4px;

	font-size: 20px;
	font-weight: 600;
}

._3I3bxagDV48q {
	text-align: center;
}

.JP02Lz6QhnSb {
	width: 70%;
}

@media screen and (max-width: 600px) {
	.JP02Lz6QhnSb {
		width: 90%;
	}
}

._3p_wbL5D6tuf {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

._2IYD6utlGhjI {
	justify-content: center;
}

@media screen and (max-width: 320px) {
	._3p_wbL5D6tuf {
		justify-content: center;
	}
}

.SC-9NlJvte6X {
	display: flex;
	align-items: center;

	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
}

@media screen and (max-width: 600px) {
	.SC-9NlJvte6X {
		margin-left: auto;
	}
}

._2PER1cx_U026 {
	color: var(--base);

	cursor: pointer;
}

@media screen and (max-width: 320px) {
	._30F8PvNIwDRu {
		display: none;
	}
}

._1wP5poSpy1H6 {
	position: relative;
	top: -1px;

	display: flex;
	align-items: center;
	margin-left: 4px;

	color: var(--gray30);

	cursor: help;
}
