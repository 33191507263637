

._9fvq-Pvo_8kN {
	display: flex;
	align-items: center;
	min-height: 20px;
}

._1LyLbMGdMSsR {
	display: inline-flex;

	color: var(--common-blue);

	cursor: help;
}

._1LyLbMGdMSsR:not(:last-of-type) {
	margin-right: 5px;
}

._3enMoSxPXfru {
	color: var(--light-gray);
}
