._1ClQMDwNueaj {
	position: relative;

	width: 100%;
}

div.gfEVpcgmAEns {
	position: absolute;

	width: 100%;
	height: 4px;

	background-color: var(--results-loader-progress-background);

	transform: none;
}

div._2FGL0g6nnKJw {
	background-color: var(--results-loader-progress-color);
}

.rZrb3gf0TqL8 {
	position: relative;

	margin-bottom: 15px;
}

._15edQn01bXir {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;

	border-radius: 2px;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);

	transform: translateY(calc(100% + 4px));

	background: #ffffff;
}

._2bVCmeXJhUki {
}

._2bVCmeXJhUki > div {
	font-size: 15px;
}

._3mt-lKDix-HZ {
	margin: 18px 28px 18px;

	font-size: 13px;
	color: var(--common-gray);
}

div._2k3JEKMBVeGF {
	z-index: 1;

	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 40px;

	color: #ffffff;
}

._21HhJDDyRoPd {
	margin: 0;

	font-weight: 500;
	font-size: 57px;
	line-height: 85px;
}

._2AKkCqTE2gU- {
	margin: 36px 0;

	font-size: 17px;
	line-height: 25px;
	text-align: center;
}

button.hc2nZampKKHv {
	text-transform: uppercase;
}

@media (max-width: 1024px) {
	div.r4_mVnhU1RIM {
		z-index: 1501;

		padding-right: 0 !important; /* for .mui-fixes */
	}

	._15edQn01bXir {
		position: relative;

		display: flex;
		height: 100%;
		max-height: 100%;
		padding-top: 99px;

		box-shadow: none;
		transform: none;
	}

	.arvWe0nyJ5SP {
		padding-top: 56px;
	}
}

._1EwSvprQcnGh {
	display: flex;
	margin-right: 28px;
}

._1EwSvprQcnGh:only-child {
	margin-top: 12px;
	margin-bottom: 16px;
	justify-content: flex-end;
	width: 100%;
}

button._2UX1-OUjjnq8 {
	color: #9a9a9a;

	background-color: #ededed;
}

button._2UX1-OUjjnq8:hover {
	background-color: #e1e1e1;
}

button.iXB30USdb4NP {
	margin-left: 5px;

	background: var(--common-blue);
}

button.q43sRgh57wrO,
button._3Y2RkoxVNaLP {
	opacity: 0.3;
	cursor: default;
}

@media (max-width: 1024px) {
	div._1EwSvprQcnGh {
		position: absolute;
		bottom: 0;
		z-index: 5;

		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 48px;
		margin: 0;
		padding: 0 20px;

		background: #ededed;
	}

	div._1EwSvprQcnGh:only-child {
		margin: 0;
		justify-content: space-between;
	}

	button._2UX1-OUjjnq8,
	button.iXB30USdb4NP {
		width: auto;

		font-size: 17px;

		opacity: 1;
		background: none;
	}

	button._2UX1-OUjjnq8 {
		padding-left: 20px;

		color: var(--common-gray);
		font-weight: 400;
	}

	button.iXB30USdb4NP {
		padding-right: 20px;

		color: var(--common-blue);
		font-weight: 600;
	}

	button._2UX1-OUjjnq8:hover,
	button.iXB30USdb4NP:hover {
		background: none;
	}
}

@media screen and (max-width: 600px) {
	div._1EwSvprQcnGh {
		position: fixed;

		background: #4f5861;
	}

	div._2UX1-OUjjnq8,
	div.iXB30USdb4NP {
		color: #ffffff;
	}

	div.iXB30USdb4NP {
		margin-right: 20px;
	}

	div._2UX1-OUjjnq8 {
		margin-left: 20px;

		opacity: 0.7;
	}
}

._26nYVKhPd_7M,
._1kn9M38RtBlF,
.Uk9ABHGB2GM5 {
	position: relative;

	display: inline-block;
	margin-right: 27px;
	padding-left: 14px;

	color: var(--light-gray);
	font-size: 11px;
}

._26nYVKhPd_7M:before {
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: 0;

	width: 10px;
	height: 10px;
	margin: auto;

	border-radius: 50%;

	opacity: 0.5;
	background: var(--common-emerald);
	content: '';
}

._1kn9M38RtBlF:before {
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: 0;

	width: 10px;
	height: 10px;

	border-radius: 50%;

	opacity: 0.5;
	background: #1e77ca;
	content: '';
}

.Uk9ABHGB2GM5:before {
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: 0;

	width: 10px;
	height: 10px;

	border-radius: 50%;

	opacity: 0.5;
	background: var(--dark-red);
	content: '';
}

._3sQ46tjg4eWW:before {
	width: 18px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNiAySDE1VjBIMTNWMkg1VjBIM1YySDJDMC44OSAyIDAuMDEgMi45IDAuMDEgNEwwIDE4QzAgMTkuMSAwLjg5IDIwIDIgMjBIMTZDMTcuMSAyMCAxOCAxOS4xIDE4IDE4VjRDMTggMi45IDE3LjEgMiAxNiAyWk00IDlWMTFINlY5SDRaTTEwIDlIOFYxMUgxMFY5Wk0xNCA5VjExSDEyVjlIMTRaTTIgMThIMTZWN0gyVjE4WiIgZmlsbD0iI0E1QTVBNSIvPgo8L3N2Zz4K) center center;
}

._2yauyjVEL7wN:before {
	width: 16px;
	height: 16px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02IDE2SDEwVjBINlYxNlpNMCAxNkg0VjhIMFYxNlpNMTIgMTZWNUgxNlYxNkgxMloiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==) center center;
}

._2Jgmy_LFJnyq:before {
	width: 24px;
	height: 22px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgc3R5bGU9Im1peC1ibGVuZC1tb2RlOm11bHRpcGx5Ij4KPHBhdGggZD0iTTguNiAxNEg5LjhMMTIuOCA5LjM2ODQySDE2LjFDMTYuNTk4IDkuMzY4NDIgMTcgOC45ODA1MyAxNyA4LjVDMTcgOC4wMTk0NyAxNi41OTggNy42MzE1OCAxNi4xIDcuNjMxNThIMTIuOEw5LjggM0g4LjZMMTAuMSA3LjYzMTU4TDYuOCA3LjYzMTU4TDUuOSA2LjQ3MzY4TDUgNi40NzM2OEw1LjYgOC41TDUgMTAuNTI2M0g1LjlMNi44IDkuMzY4NDJIMTAuMUw4LjYgMTRaIiBmaWxsPSIjQTVBNUE1Ii8+CjxwYXRoIGQ9Ik0xNS40IDIySDE0LjJMMTEuMiAxNy4zNjg0SDcuOUM3LjQwMiAxNy4zNjg0IDcgMTYuOTgwNSA3IDE2LjVDNyAxNi4wMTk1IDcuNDAyIDE1LjYzMTYgNy45IDE1LjYzMTZIMTEuMkwxNC4yIDExSDE1LjRMMTMuOSAxNS42MzE2SDE3LjJMMTguMSAxNC40NzM3SDE5TDE4LjQgMTYuNUwxOSAxOC41MjYzSDE4LjFMMTcuMiAxNy4zNjg0SDEzLjlMMTUuNCAyMloiIGZpbGw9IiNBNUE1QTUiLz4KPC9nPgo8L3N2Zz4K) center center;
}

._23l0uuxBybAI {
	position: relative;

	min-width: 1080px;
}

@media (max-width: 1024px) {
	._23l0uuxBybAI {
		width: 100%;
		max-width: 450px;
		min-width: 0;
		overflow: auto;
	}
}

@media screen and (max-width: 600px) {
	._23l0uuxBybAI {
		max-width: 100%;
	}
}

.IBTuWyVDxxd6 {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;

	border-top: 1px solid #ededed;
}

._33te3Dw8u6SK {
	display: inline-block;
	margin-left: 14px;
}

span._3no1ymny3sC6 {
	top: 0;
	bottom: auto;

	height: 3px;

	background-color: var(--common-blue);
}

button._1ob_K35YNphM {
	min-width: auto;
	margin: 0 16px;
	height: 43px;
	min-height: 43px;

	font-size: 13px;
	font-weight: 500;
	text-transform: none;
	color: #9a9a9a;
}

@media (min-width: 960px) {
	button._1ob_K35YNphM {
		padding-left: 0;
		padding-right: 0;

		font-family: inherit;
	}
}

button.o54YR8x3NZWc {
	color: var(--common-blue);
}

@media (max-width: 1024px) {
	.IBTuWyVDxxd6 {
		position: absolute;
		top: 56px;
		left: 0;
		right: 0;
	}

	._33te3Dw8u6SK {
		margin-left: 0;
		width: 100%;
	}

	span.gnO4OnWN8XBK {
		display: flex;
		flex-direction: row;
	}

	span.gnO4OnWN8XBK svg {
		margin-right: 10px;
	}

	button._1ob_K35YNphM {
		flex-grow: 1;
		max-width: 100%;
		width: auto;
		margin: 0;

		font-family: inherit;
		color: #777777;
		font-size: 12px;
		font-weight: 500;

		background: #ededed;
	}

	._1ob_K35YNphM._3ThGmfCaVbpf {
		display: none;
	}

	.o54YR8x3NZWc._1ob_K35YNphM {
		background: #ffffff;
	}

	button.o54YR8x3NZWc {
		color: #777777;
	}

	._3no1ymny3sC6 {
		display: none;
	}
}

.xxOoz5VHVnNb {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;

	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 56px;
	padding: 0 14px;

	color: #313131;

	box-shadow: none;
	background-color: #ededed;
}

._10mEW6vRR-2W {
	font-size: 16px;
	font-weight: 500;
}

._3or4t1nksaDo:before {
	content: ' - ';

	color: #313131;
}

.xjoC3__LgzSL {
	color: var(--datepicker-day-selected-background);
}

._3-yRGDrgx6ba {
	position: absolute;
	right: 10px;

	width: 24px;
	height: 24px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5IDYuNDFMMTcuNTkgNUwxMiAxMC41OUw2LjQxIDVMNSA2LjQxTDEwLjU5IDEyTDUgMTcuNTlMNi40MSAxOUwxMiAxMy40MUwxNy41OSAxOUwxOSAxNy41OUwxMy40MSAxMkwxOSA2LjQxWiIgZmlsbD0iIzlBOUE5QSIvPgo8L3N2Zz4K) no-repeat center;
}
