

.pGuj7gl5Ydcq .slick-arrow {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px;

	border-radius: 50px;

	text-align: center;

	background: #ffffff;
}

.pGuj7gl5Ydcq .slick-arrow:before {
	display: none;
}

.pGuj7gl5Ydcq .slick-arrow svg {
	position: absolute;

	left: 50%;
	top: 50%;

	transform: translate(-35%, -50%);
}

.pGuj7gl5Ydcq .slick-next {
	right: 25px;
}

.pGuj7gl5Ydcq .slick-prev {
	left: 25px;
	z-index: 1;

	transform: translate(0, -50%) rotate(180deg);
}

.pGuj7gl5Ydcq .slick-dots {
	bottom: 20px;
}

.pGuj7gl5Ydcq .slick-dots li {
	margin: 0;
	width: auto;
	height: auto;
}

.pGuj7gl5Ydcq .slick-dots li button {
	padding: 0;
	width: auto;
	height: auto;
}

.pGuj7gl5Ydcq .slick-dots li:not(:last-child) button {
	margin-right: 15px;
}

.pGuj7gl5Ydcq li button:before {
	width: 6px;
	height: 6px;

	border: 1px solid #ffffff;
	border-radius: 50%;

	opacity: 1;
	content: '';
}

.pGuj7gl5Ydcq li.slick-active button:before {
	background-color: #ffffff;
}
