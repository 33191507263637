._2USIv69i0NP9 {
	font-size: 16px;
	font-weight: 500;
}

._2USIv69i0NP9._1Y5YU2_m-fbI._1m_KVdksCdIM {
	background: var(--warning);
}

._2USIv69i0NP9._1Y5YU2_m-fbI._36srk7yvoH9G {
	color: var(--white);

	background: var(--error);
}

._2USIv69i0NP9._1Y5YU2_m-fbI._2O00qdw7-Sbl,
._2O00qdw7-Sbl ._1v8-Vy95Plf8 {
	color: var(--success);
}

._2USIv69i0NP9.jsHGh9297WJ0._1m_KVdksCdIM {
	background: var(--warning);
}

._2USIv69i0NP9.jsHGh9297WJ0._36srk7yvoH9G {
	color: #ffffff;

	background: var(--error);
}

._2USIv69i0NP9.jsHGh9297WJ0._2O00qdw7-Sbl {
	color: var(--success);
}

._2USIv69i0NP9 svg {
	display: block;
}

._2RnGMMiXAnLn {
	display: flex;
	align-items: center;
	width: 100%;
}

.TcUq1c2h3xZ0 {
	margin-left: 8px;

	font-weight: 500;
}

._2guB9i_yOn9d {
	margin-left: auto;
}

._2_NGFqVwSIAf {
	display: none;

	color: var(--base);
	font-size: 15px;

	cursor: pointer;
}

@media (max-width: 768px) {
	._2_NGFqVwSIAf {
		font-weight: 400;
	}
}
