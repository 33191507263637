.HNgWOr8D1Sw1 {
	max-width: 1100px;
	margin: 28px auto 20px;

	border-radius: 4px;

	background: var(--warnings-background);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

._22RBSmtjMVjH {
	display: flex;
	align-items: center;
	padding: 24px 28px;
}

._22RBSmtjMVjH:not(:last-child) {
	border-bottom: 1px solid var(--warnings-warning-border-bottom-color);
}

._3F0KT__KDQ2O {
	display: flex;
	align-items: center;

	color: var(--warnings-text-color);
	font-size: 14px;
	line-height: 21px;
}

._2QtMjOYSy77D {
	min-height: 24px;
	min-width: 24px;
	margin-right: 20px;
	display: flex;
	align-items: center;

	color: var(--warnings-icon-color);
}

._10DiPY6Wh08- {
	margin-left: 14px;

	font-size: 15px;
	color: var(--warnings-text-color);
}

@media screen and (max-width: 600px) {
	.HNgWOr8D1Sw1 {
		margin-top: 0;
	}

	._22RBSmtjMVjH {
		flex-direction: column;
		align-items: flex-start;
	}

	._2QtMjOYSy77D {
		margin-bottom: 13px;
	}
}
