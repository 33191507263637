._2yLVFcJIbSJg {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	width: 98px;
	height: 146px;
}

._2L7qw1k_xfKR {
	position: relative;
}

._2L7qw1k_xfKR svg {
	display: block;
}

._2GALHvYqmb8Y {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

._2SBOpWGFIW8k {
	font-weight: 900;
	font-size: 24px;
	line-height: 1;
}

._2JIX1rClNicH {
	font-size: 13px;
	line-height: 1;
}

._2yLVFcJIbSJg.ddScKMym8m2e ._2L7qw1k_xfKR {
	padding-bottom: 6px;
}

._2yLVFcJIbSJg.ddScKMym8m2e ._2GALHvYqmb8Y {
	display: flex;
	align-items: center;
	justify-content: center;
}

._2yLVFcJIbSJg.ddScKMym8m2e ._2JIX1rClNicH {
	margin-left: 4px;
}

._2yLVFcJIbSJg.UEgWRqSPVCMv ._2GALHvYqmb8Y {
	display: flex;
	flex-direction: column;
	align-items: center;
}

._2yLVFcJIbSJg.ddScKMym8m2e._1YrDNg7vSrGa ._2GALHvYqmb8Y {
	bottom: 22px;
}

._2yLVFcJIbSJg.UEgWRqSPVCMv._1YrDNg7vSrGa ._2GALHvYqmb8Y,
._2yLVFcJIbSJg.UEgWRqSPVCMv.XDRPM3gyC-KW ._2GALHvYqmb8Y {
	bottom: 24px;
}

._2yLVFcJIbSJg.UEgWRqSPVCMv._1szr_2sYVUcU ._2GALHvYqmb8Y {
	bottom: 28px;

	display: flex;
	flex-direction: column;
	align-items: center;
}

._2yLVFcJIbSJg.R8MOcMivHF21 {
	width: auto;
	height: auto;
}

._2yLVFcJIbSJg._3PG1J3QTeJ6G {
	height: auto;
}

._2yLVFcJIbSJg._3PG1J3QTeJ6G ._2L7qw1k_xfKR ._2GALHvYqmb8Y {
	position: relative;
	left: auto;
	right: auto;
	bottom: auto;

	margin-top: 10px;
}

._2yLVFcJIbSJg._3PG1J3QTeJ6G ._2L7qw1k_xfKR ._2GALHvYqmb8Y ._2SBOpWGFIW8k {
	position: relative;

	display: inline;

	font-size: 22px;
}

._2yLVFcJIbSJg._3PG1J3QTeJ6G ._2L7qw1k_xfKR ._2GALHvYqmb8Y ._2SBOpWGFIW8k:before {
	content: '+';
}

._2yLVFcJIbSJg._3PG1J3QTeJ6G ._2L7qw1k_xfKR ._2GALHvYqmb8Y ._2JIX1rClNicH {
	display: inline;
	margin-left: 3px;
}

@media screen and (max-width: 600px) {
	._2yLVFcJIbSJg._3PG1J3QTeJ6G {
		width: 58px;
	}

	._2yLVFcJIbSJg._3PG1J3QTeJ6G ._2L7qw1k_xfKR > svg {
		width: 58px;
	}

	._2yLVFcJIbSJg._3PG1J3QTeJ6G ._2L7qw1k_xfKR ._2GALHvYqmb8Y {
		display: none;
	}
}

.ddScKMym8m2e._1szr_2sYVUcU:not(.R8MOcMivHF21) ._2GALHvYqmb8Y {
	top: 0;
}
