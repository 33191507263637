._1R9T4gTs9aIz {
	width: 100%;

	border-collapse: collapse;

	font-size: 15px;
	line-height: 23px;
}

._1R9T4gTs9aIz th {
	padding: 0 0 16px;

	font-weight: 500;
}

._1R9T4gTs9aIz td {
	padding: 12px 0;

	border-top: 1px solid #e4e4e4;

	color: #595959;
}

._1R9T4gTs9aIz th,
._1R9T4gTs9aIz td {
	text-align: right;
}

._1R9T4gTs9aIz tfoot td {
	color: var(--common-blue);
	font-weight: 500;

	text-align: right;
}

._1R9T4gTs9aIz th:nth-child(2),
._1R9T4gTs9aIz td:nth-child(2) {
	padding-right: 15px;
}

._1R9T4gTs9aIz thead th:first-of-type,
._1R9T4gTs9aIz tbody td:first-of-type {
	text-align: left;
}

@media screen and (max-width: 320px) {
	._1R9T4gTs9aIz td:first-of-type {
		max-width: 120px;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

._3AFqWIiecKkD {
	padding-left: 0;

	color: var(--common-blue);
	font-weight: 500;
}
