._2Hqh8RSUWVpV {
	font-size: 20px;
	line-height: 1.2;
}

._38gx0SmhCo_M {
	display: inline-flex;
	align-items: center;
	margin-left: 10px;
	padding: 6px 10px;

	border-radius: 6px;

	color: var(--gray70);

	cursor: pointer;
	background-color: var(--gray05);
}

._38gx0SmhCo_M svg {
	width: 16px;
	height: 16px;
	margin-left: 10px;
}

@media screen and (max-width: 600px) {
	._2Hqh8RSUWVpV {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	._38gx0SmhCo_M {
		margin-top: 6px;
		margin-left: 0;
	}
}
