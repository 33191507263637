

._3jRW3frVDyxf {
	padding: 32px;

	border-radius: 20px;

	background: var(--white);
}

._2J9Wql27HwGS {
	margin-bottom: 20px;

	color: #262626;
	font-weight: 700;
	font-size: 20px;
	line-height: 1.3;
}

._3EF9VJDSM7oT {
	display: flex;
	flex-direction: column;
}

.hsEGV3MOvaET {
	margin-bottom: 16px;
}

.hsEGV3MOvaET fieldset {
	display: none;
}

._1S268-TJRGZ3._1nkiZXH6qNrT {
	border: 1px solid #add3ff;

	background: var(--base90);
}

._1S268-TJRGZ3._1nkiZXH6qNrT:focus {
	border-radius: inherit;

	background: var(--base90);
}

.hsEGV3MOvaET:last-child {
	margin-bottom: 0;
}

li._10-MTC8o6A9L:hover,
li._10-MTC8o6A9L.-en0z6z2FYdK,
li._10-MTC8o6A9L.-en0z6z2FYdK:hover {
	background: var(--base90);
}

._60GdezHMzGUd {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 18px;
}

button.IS0fpkY7vqXa,
button.IS0fpkY7vqXa:hover {
	border-radius: 4px;

	color: var(--white);

	background: var(--brand-3-0);
}

div.IS0fpkY7vqXa {
	background: var(--brand-3-0);
}
