

._3BUo34-U1ySZ {
	position: relative;

	display: flex;
	flex: 1;
	align-items: center;
	padding: 20px 16px;
}

._3BUo34-U1ySZ._3il9j6fALgUA {
	border-top: 1px solid var(--gray10);
}

.UgjDnbeGEiNa {
	display: flex;
	align-items: center;
	margin-right: 20px;

	font-size: 14px;
}

._1faz2RoBv31O {
	padding-bottom: 8px;

	color: var(--gray80);
	font-weight: 600;
}

._3-QbFuuoPEqe {
	color: #808080;
}

._3bKg7rc5xmPU ._3-QbFuuoPEqe {
	font-size: 16px;
	color: var(--base-15);
}

img._3uIk2sh7lSAL {
	width: 36px;
	height: 36px;
	margin-right: 12px;

	border-radius: 2px;
}

.Y4OlF_CnWZjz {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.Piukxi4AObJm ._1faz2RoBv31O {
	color: var(--error-20);
}

.gdANGdw60xiN {
	flex: 1;

	color: var(--gray90);
}

._3bqCDFHKGibH {
	margin: 0 20px;
	display: flex;
}

._3bqCDFHKGibH img {
	width: 150px;
	height: auto;
	max-height: 60px;
}

._2PGTAVlJWLVX {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 12%;
	height: 100%;

	color: var(--gray50);
	font-size: 14px;
}

._4MCYkfZpylGk {
	position: relative;

	max-width: 380px;

	flex: 1;
	padding: 0 30px;
	display: flex;
	height: 100%;
}

._3OjoVPVbZVw1 {
	flex: 0;
	min-width: 30%;
	max-width: 30%;

	font-size: 24px;
	white-space: nowrap;
	text-overflow: ellipsis;
}

._3OjoVPVbZVw1 span {
	text-overflow: ellipsis;
	overflow: visible;
}

._3wbdnOicsR3l {
	position: absolute;
	bottom: -13px;
	left: 0;

	width: 100%;
	height: 31px;

	color: #1a1a1a;
	font-weight: 600;
	font-size: 14px;
	text-align: center;

	transform: translateY(150%);
}
