

._1aVKfFYPV0oy {
	flex: 1;
	overflow: auto;
}

.fPpvfJLlcX0b {
	display: grid;
	grid-gap: 12px;
	grid-template-columns: 1fr 1fr;
	height: 65px;
	padding: 12px;

	box-shadow: 0 -7px 20px rgba(0, 0, 0, 0.03);
	background: #ffffff;
}

button._1ytwKwJ6dWuL,
button._1ytwKwJ6dWuL:hover {
	border-radius: 4px;

	color: var(--text-light);
	font-weight: 400;

	background: var(--bg-fill);
}

button._24mjyiwWiW8A,
button._24mjyiwWiW8A:hover {
	background: var(--brand3);
}

button._24mjyiwWiW8A._3px-mSZ7_MYH {
	background: var(--brand3--3);
}
