

._28sZ8bVyTR83 {
	display: flex;
}

._1tEERIAVg05g {
	display: flex;
}

.VAthVpNAylH4 {
	display: block;
	height: 20px;
	width: 20px;
	margin-bottom: 12px;
}

._293Fyvpa5_Pk {
	display: block;
}
