.THp1az0-RPc7 {
	position: relative;
	z-index: 1;

	max-width: 320px;
}

._3D1VFBo9Z3Yw {
	z-index: 1002;

	min-width: 320px;
}

._3_qLNerjtqCA {
	display: flex;
}

._1L64rB3EORCF {
	position: fixed;
	top: 65px;
	z-index: 1;
}

._2M5fW4uRd2J7 {
	top: auto;
	position: absolute;
	bottom: 20px;
}

._16w2XA1L3oHp {
	z-index: 1;

	display: flex;
	align-items: center;
	max-width: 375px;
	height: 48px;
	margin-top: 8px;
	padding: 0 40px 0 12px;

	border: 1px solid var(--seatMap-passenger-background);

	border-radius: 4px;

	color: var(--seatMap-passenger-color);
	font-weight: 500;
	font-size: 15px;
	line-height: 48px;

	cursor: pointer;
	background: var(--seatMap-passenger-background);

	transition: border-color 0.15s;
}

.adPm8YEAyCEH {
	flex-grow: 1;
}

._3Vd8PlIgnZwm {
	margin-left: 5px;

	font-size: 12px;
}

.vA5gtKgxNVqK {
	display: block;
	max-width: 130px;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;
}

._29Rt-7z0OyeG {
	position: absolute;
	right: 10px;
	z-index: 2;

	width: 24px;
	height: 24px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40NyA2LjQ3IDIgMTIgMkMxNy41MyAyIDIyIDYuNDcgMjIgMTJDMjIgMTcuNTMgMTcuNTMgMjIgMTIgMjJDNi40NyAyMiAyIDE3LjUzIDIgMTJaTTE1LjU5IDE3TDE3IDE1LjU5TDEzLjQxIDEyTDE3IDguNDFMMTUuNTkgN0wxMiAxMC41OUw4LjQxIDdMNyA4LjQxTDEwLjU5IDEyTDcgMTUuNTlMOC40MSAxN0wxMiAxMy40MUwxNS41OSAxN1oiIGZpbGw9IiM5QTlBOUEiLz4KPG1hc2sgaWQ9Im1hc2swIiBtYXNrLXR5cGU9ImFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIyIiB5PSIyIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIgMTJDMiA2LjQ3IDYuNDcgMiAxMiAyQzE3LjUzIDIgMjIgNi40NyAyMiAxMkMyMiAxNy41MyAxNy41MyAyMiAxMiAyMkM2LjQ3IDIyIDIgMTcuNTMgMiAxMlpNMTUuNTkgMTdMMTcgMTUuNTlMMTMuNDEgMTJMMTcgOC40MUwxNS41OSA3TDEyIDEwLjU5TDguNDEgN0w3IDguNDFMMTAuNTkgMTJMNyAxNS41OUw4LjQxIDE3TDEyIDEzLjQxTDE1LjU5IDE3WiIgZmlsbD0id2hpdGUiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swKSI+CjwvZz4KPC9zdmc+Cg==) center no-repeat;
}

.FS9I0xcnWfT7 {
	margin-left: 5px;

	font-size: 15px;
	color: var(--seatMap-passenger-seat-color);
}

._2-_T71Q-_In7 {
	font-size: 15px;
	color: var(--seatMap-passenger-price-color);
	font-weight: 600;
}

.aHAFGQmlcI2f {
	border: 1px solid var(--seatMap-passenger-border-selected);

	color: var(--seatMap-passenger-color-selected);

	background-color: var(--seatMap-passenger-background-selected);
}

.aHAFGQmlcI2f ._2-_T71Q-_In7 {
	color: var(--seatMap-passenger-price-color-selected);
}

.aHAFGQmlcI2f .FS9I0xcnWfT7 {
	color: var(--seatMap-passenger-selected-seat-color);
}

@media screen and (max-width: 600px) {
	.FS9I0xcnWfT7 {
		margin-left: 0;
	}

	.aHAFGQmlcI2f .FS9I0xcnWfT7 {
		color: var(--seatMap-passenger-seat-color-selected);
	}
}

.aHAFGQmlcI2f:before {
	position: absolute;
	right: 10px;

	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5ODQgNkw4LjU4OTg0IDcuNDFMMTMuMTY5OCAxMkw4LjU4OTg0IDE2LjU5TDkuOTk5ODQgMThMMTUuOTk5OCAxMkw5Ljk5OTg0IDZaIiBmaWxsPSIjMzI5MEZGIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

._2SPnyZFd5rPK {
	display: none;
}

.b4y00RDZ1-cE {
	display: none;
}

.aHAFGQmlcI2f ._29Rt-7z0OyeG {
	display: none;
}

._2hyvNfHeFLp-._3RdpMWen6s9H ._29Rt-7z0OyeG {
	display: none;
}

._2hyvNfHeFLp-:not(._3RdpMWen6s9H):hover ._29Rt-7z0OyeG {
	display: block;
}

._2hyvNfHeFLp-:not(._3RdpMWen6s9H):hover:before {
	display: none;
}

._2LxGf_mv1nXj {
	width: 320px;
}

._2LxGf_mv1nXj > p {
	margin: 26px 0 0;

	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
}

._2tCZY8nX5quh {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
}

button._2b0I6zm0Z8Oq {
	width: 49%;

	height: 49px;

	font-weight: 600;

	font-size: 14px;
	line-height: 49px;

	background: var(--active);
}

._2b0I6zm0Z8Oq.cPcpOytRRBQc,
._2b0I6zm0Z8Oq.cPcpOytRRBQc:hover {
	color: #9a9a9a;
	font-weight: 500;

	background: var(--half-transparent-gray);
}

._2b0I6zm0Z8Oq.cPcpOytRRBQc[disabled] {
	background: var(--half-transparent-gray);
}

button._2b0I6zm0Z8Oq:hover {
	background: var(--active);
}

button._2b0I6zm0Z8Oq:only-child {
	width: 100%;
}

._2BViOWVjPzMv {
	display: block;
	height: 20px;
	width: 20px;
	margin-bottom: 12px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC43Ij4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2Njk5IDkuOTk5OTZDMS42NjY5OSA1LjM5OTk2IDUuNDAwMzMgMS42NjY2MyAxMC4wMDAzIDEuNjY2NjNDMTQuNjAwMyAxLjY2NjYzIDE4LjMzMzcgNS4zOTk5NiAxOC4zMzM3IDkuOTk5OTZDMTguMzMzNyAxNC42IDE0LjYwMDMgMTguMzMzMyAxMC4wMDAzIDE4LjMzMzNDNS40MDAzMyAxOC4zMzMzIDEuNjY2OTkgMTQuNiAxLjY2Njk5IDkuOTk5OTZaTTEwLjgzMzcgOS4xNjY2M1YxNC4xNjY2SDkuMTY2OTlWOS4xNjY2M0gxMC44MzM3Wk0xMC4wMDAzIDE2LjY2NjZDNi4zMjUzMiAxNi42NjY2IDMuMzMzNjUgMTMuNjc1IDMuMzMzNjUgOS45OTk5NkMzLjMzMzY1IDYuMzI0OTYgNi4zMjUzMiAzLjMzMzI5IDEwLjAwMDMgMy4zMzMyOUMxMy42NzUzIDMuMzMzMjkgMTYuNjY3IDYuMzI0OTYgMTYuNjY3IDkuOTk5OTZDMTYuNjY3IDEzLjY3NSAxMy42NzUzIDE2LjY2NjYgMTAuMDAwMyAxNi42NjY2Wk0xMC44MzM3IDUuODMzMjlWNy40OTk5Nkg5LjE2Njk5VjUuODMzMjlIMTAuODMzN1oiIGZpbGw9IiMzMjkwRkYiLz4KPC9nPgo8L3N2Zz4K) center no-repeat;
}

.njEfZxC1tmml {
	display: block;
}

._2xyp-7cfPfhA {
	display: flex;
	justify-content: center;
	align-items: center;

	margin: 16px 0;

	color: var(--base);
	font-weight: 500;
	font-size: 16px;

	cursor: pointer;
}

._3laLbxrEK9__ {
	margin-right: 8px;

	color: var(--active);
}

._8alQR1eCGIV9 {
	margin-bottom: 20px;
	padding: 20px;

	border-radius: 4px;

	background: #ffffff;
}

.BoBeOj2Ey_hl {
	display: flex;
	align-items: center;
	margin-bottom: 7px;

	color: var(--gray50);
	font-size: 15px;
}

.BoBeOj2Ey_hl svg {
	margin-right: 8px;

	color: var(--active);
}

._2oQz5GyXtDV7 {
	margin-bottom: 16px;

	color: var(--gray80);
	font-weight: 500;
	font-size: 21px;
}

._3FBdZt0ECPB4 {
	margin-top: 16px;

	color: var(--gray80);
	font-weight: 700;
	font-size: 18px;
	text-align: right;
}

@media screen and (max-width: 600px) {
	.THp1az0-RPc7 {
		display: flex;
		justify-content: space-between;
		align-items: center;

		width: 100%;
		min-height: 56px;
		padding: 0 10px;

		border-bottom: 1px solid #d9d9d9;

		background-color: #ffffff;
	}

	._19GrGGUr7Ttj {
		font-size: 14px;
	}

	._16w2XA1L3oHp {
		flex-direction: column;
		justify-content: center;
		width: unset;
		height: unset;
		margin-bottom: 0;
		margin-left: 10px;

		padding: 0;

		border: none;

		background: inherit;
	}

	._1L64rB3EORCF {
		position: static;
	}

	.adPm8YEAyCEH {
		display: flex;
		margin-bottom: 5px;
		flex-grow: unset;

		color: #bababa;
	}

	.FS9I0xcnWfT7 {
		display: flex;
		height: 15px;

		line-height: 1;
	}

	._29Rt-7z0OyeG {
		display: none;
	}

	.aHAFGQmlcI2f:before {
		display: none;
	}

	.aHAFGQmlcI2f svg path {
		fill: var(--common-blue);
	}
}

@media screen and (max-width: 600px) {
	.adPm8YEAyCEH {
		margin-bottom: 0;
	}

	.THp1az0-RPc7 {
		margin-top: 0;
	}
}
