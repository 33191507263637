

div.zZAFyTVBpHBm {
	display: flex;
	flex-direction: column;
	max-height: calc(100% - 200px);
	padding: 0;
}

div._2Vmd3npzRY8m {
	background: none;
}

.nLWB2RwbwL-w {
	flex: 1;
	padding: 20px;
	overflow-y: auto;

	background: var(--gray05);
}

._1Cu33xJ3PJ2X {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;

	color: var(--gray70);
	font-weight: 700;
	font-size: 20px;
}

._2Z2yHpLqSJCf {
	display: flex;
	align-items: center;
}

._7GBEf8q02m9t {
	margin-right: 8px;

	color: var(--active);
}

._2yyL2gJKEZ5K {
	display: flex;
	align-items: flex-end;

	color: var(--base);
	font-weight: 400;
	font-size: 15px;
}

._2AFdNadJsXwu {
	color: var(--base);
	font-weight: 700;
	font-size: 24px;
}

.vSsEJcvuALM2 {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}

._364iz6OKHKGR {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	margin-right: 12px;

	border-radius: 4px;

	color: #ffffff;
	font-weight: 700;
	font-size: 22px;

	cursor: pointer;
	background: var(--selected);
}

._3bVTxfYX5Aqb {
	background: var(--selected2);
}

._2uzaT2OBtUkP {
	display: flex;
	align-items: center;
	min-height: 69px;
	padding: 0 20px;

	box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
	background: #ffffff;
}

._2uzaT2OBtUkP button {
	flex: 1;
	height: 49px;

	line-height: 49px;
}

._2uzaT2OBtUkP button:not(:last-child) {
	margin-right: 12px;
}

button._3V7i2ZO-NBDR {
	color: var(--gray70);

	background: var(--gray05);
}

button._3Yi8Fekq0WRL {
	color: #ffffff;

	background: var(--active);
}
