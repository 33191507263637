._2LvlSxxOnQmT {
	max-width: 550px;
	margin: 0 auto 38px;
}

._2_EIKOGeLNnp {
	margin-top: 28px;
}

div._1luCepztMuHa {
	margin-bottom: 12px;
}

@media screen and (max-width: 600px) {
	._1VMyZ9-bd-4H ._1k7qyACcHXPJ:not(:first-of-type):not(._2zRbQyn-u9qt) {
		margin-top: 8px;
	}

	div._1V_gonx-bQOq {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	div._2U7Q-6_vWyBy {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	div._20lYL1KoIptt {
		border-top: 1px solid rgba(255, 255, 255, 0.3);
	}

	._3_TTJ0FuQLvi {
		margin: 0;

		font-size: 14px;
		line-height: 19px;
		font-weight: 400;
	}
}
