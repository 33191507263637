.ReGlrJkDDvng {
	display: flex;
	height: 100vh;
	width: 100vw;

	background-image: url('https://cdn.websky.aero/content/frontend/images/stub-404.jpeg');
	background-position: center 70%;
	background-size: cover;
	transform: matrix(-1, 0, 0, 1, 0, 0);
}

._20sl4nXgKWYh {
	height: 100vh;
	width: 100vw;
	padding: 170px;

	color: #ffffff;

	transform: matrix(-1, 0, 0, 1, 0, 0);
}

._2jD4uZPtEzRF {
	height: 52px;
	display: flex;
	align-items: center;
	margin: 0;

	font-size: 37px;

	text-shadow: 3px 1px 20px rgba(0, 0, 0, 0.14);
}

.gubzCPDbS8I0 {
	margin: 0;
	display: flex;
	align-items: center;

	text-transform: uppercase;
	font-weight: 600;
	font-size: 80px;

	text-shadow: 3px 1px 20px rgba(0, 0, 0, 0.14);
}

._20sl4nXgKWYh ._3YokgHX4sUWn {
	height: 71px;
	margin-top: 70px;

	font-size: 24px;
	font-weight: 500;

	box-shadow: 8px 16px 38px rgba(0, 0, 0, 0.11);

	background-color: var(--common-orange);
}

._20sl4nXgKWYh ._3YokgHX4sUWn:hover {
	background-color: var(--dark-orange);
}

@media screen and (max-width: 600px) {
	._20sl4nXgKWYh {
		padding: 50px;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}

	._2jD4uZPtEzRF {
		height: auto;
		justify-content: center;

		text-align: center;
	}

	.gubzCPDbS8I0 {
		font-size: 50px;

		text-align: center;
	}

	._20sl4nXgKWYh ._3YokgHX4sUWn {
		margin-top: 0;
	}
}
