._29cfZ5ccF_mW {
	padding: 0;
	margin: 0;
}

._2FI2nHTAFmO_ {
	list-style-type: none;
}

._2FI2nHTAFmO_:before {
	display: none;
}

._2FI2nHTAFmO_:not(:first-of-type) {
	padding-top: 12px;
}

._2FI2nHTAFmO_:not(:last-of-type) {
	padding-bottom: 12px;

	border-bottom: 1px solid #d9d9d9;
}
