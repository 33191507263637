

._1XKgrNCMkQH3 {
	cursor: inherit;
}

.YUccDOC73Vft {
	display: grid;
	width: 100%;
}

.YUccDOC73Vft.LxbPduHf37gg {
	padding-bottom: 18px;
}

._1wa1q1Hg_BEW {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 16px;

	border-bottom: 1px solid var(--gray10);
}

._1wa1q1Hg_BEW:only-child {
	border-bottom: none;
}

.zmHSEnCk7g9L {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	min-width: 34px;
	height: 44px;
	margin: 2px;
	padding: 5px;

	border-radius: 4px;

	font-weight: 700;
	font-size: 13px;
	line-height: 1;

	background: var(--base90);
}

._2PF9ri1qOMut {
	margin-bottom: 4px;

	color: var(--base);
}

._2TfjnHo_HuSq {
	color: var(--gray50);
}
