._2_MOjg4Q5Xkl {
	flex-basis: 0;
	flex-grow: 1;
	margin-right: 4px;
}

._2Ef_wZO9nP2y {
	flex-basis: 100%;
	margin-right: 0;
}

@media (max-width: 1024px) {
	._2Ef_wZO9nP2y {
		margin-bottom: 68px;
	}
}

@media screen and (max-width: 600px) {
	._2_MOjg4Q5Xkl {
		margin-right: 0;
		margin-bottom: 0;
	}
}

@media screen and (max-width: 600px) {
	.tmprxNWiVUO1 {
		display: flex;
		margin-top: 6px;
	}

	button.pF-4c7EF9gcn,
	button._2qfRFlw65c7x {
		width: 100%;
		flex-grow: 1;
	}

	button._2qfRFlw65c7x {
		display: block;
		margin-left: 4px;
	}

	._2JxWIcTdMFL5 .tmprxNWiVUO1 {
		margin-top: 0;
	}

	._2JxWIcTdMFL5 button.pF-4c7EF9gcn,
	._2JxWIcTdMFL5 button._2qfRFlw65c7x {
		border-radius: 0;
	}
}
