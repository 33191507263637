.QpRXpW9ThAJi {
	position: relative;

	min-width: 320px;
	max-width: 320px;

	margin-bottom: 16px;

	border-radius: 4px;

	text-align: center;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);

	background-color: #ffffff;

	transition: all 0.15s;

	overflow: hidden;
}

._1GRkQxSCJAG2 {
	display: none;
}

._3jzSGDhwwSZK {
	padding: 12px 20px 20px;

	text-align: center;
}

._3VldetkQ4kkK {
	display: flex;
	justify-content: center;
	align-items: center;

	color: var(--common-blue);
	font-weight: 500;
	font-size: 16px;
}

._3HtSdExlDF2x {
	width: 20px;
	height: 20px;
	margin-bottom: 2px;
	margin-left: 5px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC43Ij4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2Njk5IDkuOTk5OTZDMS42NjY5OSA1LjM5OTk2IDUuNDAwMzMgMS42NjY2MyAxMC4wMDAzIDEuNjY2NjNDMTQuNjAwMyAxLjY2NjYzIDE4LjMzMzcgNS4zOTk5NiAxOC4zMzM3IDkuOTk5OTZDMTguMzMzNyAxNC42IDE0LjYwMDMgMTguMzMzMyAxMC4wMDAzIDE4LjMzMzNDNS40MDAzMyAxOC4zMzMzIDEuNjY2OTkgMTQuNiAxLjY2Njk5IDkuOTk5OTZaTTEwLjgzMzcgOS4xNjY2M1YxNC4xNjY2SDkuMTY2OTlWOS4xNjY2M0gxMC44MzM3Wk0xMC4wMDAzIDE2LjY2NjZDNi4zMjUzMiAxNi42NjY2IDMuMzMzNjUgMTMuNjc1IDMuMzMzNjUgOS45OTk5NkMzLjMzMzY1IDYuMzI0OTYgNi4zMjUzMiAzLjMzMzI5IDEwLjAwMDMgMy4zMzMyOUMxMy42NzUzIDMuMzMzMjkgMTYuNjY3IDYuMzI0OTYgMTYuNjY3IDkuOTk5OTZDMTYuNjY3IDEzLjY3NSAxMy42NzUzIDE2LjY2NjYgMTAuMDAwMyAxNi42NjY2Wk0xMC44MzM3IDUuODMzMjlWNy40OTk5Nkg5LjE2Njk5VjUuODMzMjlIMTAuODMzN1oiIGZpbGw9IiMzMjkwRkYiLz4KPC9nPgo8L3N2Zz4K) center no-repeat;
}

._1UA88TivEYO1 {
	width: 220px;
	min-height: 71px;
	margin-bottom: 20px;
}

._3PLaXb2voO8e {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 52px;
	padding: 0 28px;

	border-top: 1px dashed #d1d1d1;

	color: var(--gray80);
	font-size: 14px;
}

.sNRwU6mUOtjN {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 44px;

	color: var(--gray80);
	font-weight: 500;
	font-size: 14px;

	cursor: pointer;
	background: var(--base80);
}

@media (max-width: 768px) {
	div.QpRXpW9ThAJi {
		margin: 20px auto;
		max-width: 394px;
	}
}

@media screen and (max-width: 600px) {
	div.QpRXpW9ThAJi {
		padding: 0 20px;

		box-shadow: none;
	}

	._14mzl00DqLoA {
		margin: 17px 20px;

		color: var(--gray70);
		font-weight: 700;
		font-size: 20px;
		text-align: center;
	}
}
