

._3beoCDoa7Ggt {
	position: absolute;
	top: -18px;
	right: 10px;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

svg._3beoCDoa7Ggt {
	color: var(--active);

	cursor: pointer;
}

div._31muYw9SaE-x {
	margin-top: -7px;
}

._1H9wvz0fxlfK {
	color: inherit;
}

._3Zd4a2lvPQZ4 {
	color: inherit;
}

._38lzDTipPM6I {
	color: inherit;
}
