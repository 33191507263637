

.l0VpJ3nIoT2Y {
	padding: 40px 60px;
}

._1HZ2MzJHkR4a {
	height: 100%;
	margin-left: 28px;
}

._2K7q8Io0EKmY:last-child {
	display: none;
}

._1ezNuocej1vl {
	margin-bottom: 28px;
}
