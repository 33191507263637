._1Pp1KQfaWe45 {
	position: relative;

	display: flex;
	flex-basis: 100%;
	flex-flow: wrap;
	min-width: 23px;
	margin-right: 5px;
}

._1Pp1KQfaWe45:hover {
	cursor: pointer;
}

@media (max-width: 1024px) {
	._1Pp1KQfaWe45 {
		width: 17px;
		min-width: 17px;
	}
}

.n9MRL6ZcyB0- {
	position: absolute;
	bottom: 0;

	width: 100%;
	height: 100px;

	background-color: var(--pricegraph-day);
	opacity: 0.6;
}

._3DNMVsRhHTUT .n9MRL6ZcyB0- {
	height: 10px;

	border: 1px solid #d9d9d9;
	border-bottom: none;

	background: none;
}

@media (max-width: 1024px) {
	._3DNMVsRhHTUT .n9MRL6ZcyB0- {
		height: 3px;

		opacity: 1;
	}
}

.NL7TZS-sfATh .n9MRL6ZcyB0- {
	background: var(--pricegraph-day);
}

@media (min-width: 1024px) {
	._1Pp1KQfaWe45:hover .n9MRL6ZcyB0- {
		opacity: 1;
		background: var(--common-blue);
	}
}

._3ikAIU4x0ksS {
	position: absolute;
	top: 100%;

	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 5px;
	padding-bottom: 5px;

	font-size: 13px;
	text-align: center;
}

@media screen and (max-width: 600px) {
	._3ikAIU4x0ksS {
		display: none;
	}
}

._3DNMVsRhHTUT:hover ._3ikAIU4x0ksS {
	background: none;
}

._2B9ch5xq157G {
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	color: var(--light-gray);
}

._3AJ3HSyIElSE {
	display: none;

	color: #cdcdcd;
	font-size: 10px;
}

._281rQ-tDINIz {
	position: relative;

	min-height: 100px;
	height: 100%;
	width: 100%;
}

@media (max-width: 1024px) {
	._281rQ-tDINIz {
		height: 300px;
	}
}

@media screen and (max-width: 600px) {
	._281rQ-tDINIz {
		height: 100%;
	}
}

._3DNMVsRhHTUT._1Pp1KQfaWe45:hover {
	cursor: default;
	background: none;
}

._3DNMVsRhHTUT:hover .n9MRL6ZcyB0- {
	background: none;
}

._3-gWtTm-XgZk {
	position: absolute;
	bottom: calc(101% + 45px);
	left: -51%;
	z-index: 3;

	display: none;
	width: calc(100% * 2);

	color: var(--dark-violet);
	font-size: 12px;
	white-space: nowrap;
	text-align: center;

	background: #ffffff;
}

._1Pp1KQfaWe45:first-child ._3-gWtTm-XgZk {
	left: 0;

	width: auto;
}

._1Pp1KQfaWe45:last-child ._3-gWtTm-XgZk {
	right: 0;

	width: auto;
}

._1Pp1KQfaWe45:hover ._3-gWtTm-XgZk {
	display: block;
}

._3RQwyXJ8Sypy {
	position: absolute;
	top: -30px;

	display: none;
	padding-left: 8px;

	border-left: 1px solid;

	color: var(--light-gray);
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
	text-transform: uppercase;
}

@media (max-width: 1024px) {
	._3RQwyXJ8Sypy {
		display: block;
	}
}

.w6dGDOGTbTwH:before {
	content: ', ';
}

._29JrC2wqvV9U {
	color: var(--bright-blue);
}

@media (min-width: 1024px) {
	.QYGQ3vj8a5QO._1Pp1KQfaWe45 .n9MRL6ZcyB0-,
	._167VgjGCNSGB._1Pp1KQfaWe45 .n9MRL6ZcyB0- {
		background: var(--common-blue);
		opacity: 1;
	}

	.QYGQ3vj8a5QO._1Pp1KQfaWe45:after,
	._167VgjGCNSGB._1Pp1KQfaWe45:after {
		position: absolute;
		right: 0;
		bottom: -42px;
		left: 0;

		width: 16px;
		height: 17px;
		margin: auto;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNjY2MzQgMTYuMzMzM0g3LjMzMzAxTDExLjQ5OTcgOS42NjY2N0gxNi4wODNDMTYuNzc0NyA5LjY2NjY3IDE3LjMzMyA5LjEwODMzIDE3LjMzMyA4LjQxNjY3QzE3LjMzMyA3LjcyNSAxNi43NzQ3IDcuMTY2NjcgMTYuMDgzIDcuMTY2NjdIMTEuNDk5N0w3LjMzMzAxIDAuNUw1LjY2NjM0IDAuNUw3Ljc0OTY3IDcuMTY2NjdIMy4xNjYzNEwxLjkxNjM0IDUuNUgwLjY2NjM0TDEuNDk5NjcgOC40MTY2N0wwLjY2NjM0IDExLjMzMzNMMS45MTYzNCAxMS4zMzMzTDMuMTY2MzQgOS42NjY2N0g3Ljc0OTY3TDUuNjY2MzQgMTYuMzMzM1oiIGZpbGw9IiMzMjkwRkYiLz4KPC9zdmc+Cg==);
		content: '';
	}

	._167VgjGCNSGB._1Pp1KQfaWe45:after {
		transform: rotate(180deg);
	}
}

div._1L9fL15TvEz2 {
	padding: 8px;
	margin-top: 12px;

	background-color: var(--common-blue);
}

div._1QwpAD4aaWmO:after {
	position: absolute;
	bottom: 120%;
	left: 50%;

	width: 0;
	height: 0;

	border-width: 0 6px 8px 6px;
	border-style: solid;
	border-color: transparent transparent var(--common-blue) transparent;

	transform: translate(-50%);
	content: '';
}

div._12taS_rqkB28 {
	opacity: 1;
}

._1QwpAD4aaWmO {
	position: relative;

	padding-left: 30px;

	font-size: 14px;
	font-weight: 500;
	color: #ffffff;
}

._1QwpAD4aaWmO:before {
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: 6px;

	width: 16px;
	height: 17px;
	margin: auto;

	transform: rotate(180deg);
	opacity: 0.6;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjMzMzcgMTYuMzMzM0gxMC42NjdMNi41MDAzMyA5LjY2NjY3SDEuOTE2OTlDMS4yMjUzMyA5LjY2NjY3IDAuNjY2OTkyIDkuMTA4MzMgMC42NjY5OTIgOC40MTY2N0MwLjY2Njk5MiA3LjcyNSAxLjIyNTMzIDcuMTY2NjcgMS45MTY5OSA3LjE2NjY3SDYuNTAwMzNMMTAuNjY3IDAuNUwxMi4zMzM3IDAuNUwxMC4yNTAzIDcuMTY2NjdIMTQuODMzN0wxNi4wODM3IDUuNUgxNy4zMzM3TDE2LjUwMDMgOC40MTY2N0wxNy4zMzM3IDExLjMzMzNMMTYuMDgzNyAxMS4zMzMzTDE0LjgzMzcgOS42NjY2N0gxMC4yNTAzTDEyLjMzMzcgMTYuMzMzM1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

._3ncalUNobgbt {
	opacity: 0.6;
}

._1ZFjpkYsqyeK:before {
	transform: rotate(0deg);
}

._1Xiu5i5KAarn {
	padding-left: 0;
}

._1Pp1KQfaWe45._3DKgrqaej2V3:not(._3DNMVsRhHTUT) .n9MRL6ZcyB0- {
	background: var(--common-blue);
	opacity: 1;
}

._1Pp1KQfaWe45._3DKgrqaej2V3:not(._3DNMVsRhHTUT):after {
	content: '';

	position: absolute;
	top: -44px;
	bottom: 0;
	left: 0;
	right: 0;

	margin: auto;
	width: 1px;

	background: var(--common-blue);
}
