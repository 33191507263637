

._1GMdwVKI00el {
	display: flex;
}

._1gpP2ZeizU3M {
	width: 35%;
}

._3itdKBb4Ho_9 {
	margin-left: 4px;

	padding: 12px 0 12px 16px;

	border-left: 2px solid #cdcdcd;

	color: var(--common-gray);
	line-height: 21px;
}

._2GQJaSD58OuJ {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 15%;
	margin-left: 30px;

	font-weight: 500;
}

._3rCZeEYYZGzI {
	display: flex;
	align-self: center;
	width: 100%;
}

._2taxX2ynJlva {
	margin-right: 10px;
	display: inline-flex;
	align-self: center;

	color: var(--common-gray);
}

._1HwzxDSmDJ7a {
	display: inline-flex;
	align-self: center;
}

._1T2IfXCPpuWA {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
}

._2zWr-czBRzZQ {
	display: flex;
	margin-left: 4px;

	line-height: 20px;

	cursor: help;

	opacity: 0.4;

	transition: opacity 0.15s;
}

._2zWr-czBRzZQ:hover {
	opacity: 1;
}

._3wspox8x2OYj {
	display: flex;
	margin-left: 4px;

	line-height: 20px;

	cursor: help;
}

._1MbaUBi1-k7_ {
	display: flex;
	align-items: center;
}

._2z7LhuwbYZ_0 {
	height: 20px;
	width: 20px;
	margin-right: 8px;
}

._2z7LhuwbYZ_0 img {
	width: 100%;
	height: 100%;
}

.VKeGoHBv6zc_ {
	width: 210px;
	height: 70px;
	overflow: hidden;
}

._1sY5a0hgLHjs {
	width: 100%;
}

._3G-WUU-qF1yW {
	width: 210px;
	height: 70px;

	background-image: url('https://cdn.websky.aero/content/frontend/images/mock-plane.png');
	background-repeat: no-repeat;
}

.lVS8MHgybtT8 {
	margin: 20px 0;
}

._28plMv8g4sCt {
	margin-left: 12px;

	color: #c21212;
}

._1gcbXgOoKBke {
	display: flex;
}

._2zDVUCMHWbTU {
	display: none;
}
