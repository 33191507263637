._3VxjTE7hOIOE {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;

	z-index: 3;

	min-height: 80px;
	padding: 16px 0;

	background: #ffffff;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

._3EQIzAhj0nQL {
	max-width: 1100px;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

._2vm2jepYTfwW {
	display: flex;
	align-items: center;
	min-height: 48px;
}

._333Ox4esZZJS {
	flex-grow: 1;
}

._3SFKMlbmm6BO {
	margin-left: auto;
}

@media (max-width: 1024px) {
	._3EQIzAhj0nQL {
		padding: 0 20px;
	}
}

@media screen and (max-width: 600px) {
	._3EQIzAhj0nQL {
		padding: 0;
	}

	._2vm2jepYTfwW {
		width: 100%;
		padding: 0 20px;
		justify-content: space-between;
	}
}

._1BI6RNbCW9Mx {
	margin-right: 24px;

	font-weight: 600;
	font-size: 22px;

	text-align: right;
}

._3TgTI4gs_pLc .YChzo1bLKIOv {
	padding-left: 6px;

	font-weight: 500;
}

button._3vCpCOWN1jX7 {
	flex-shrink: 0;

	color: var(--order-backButton-color);

	background-color: #ededed;
}

button._3vCpCOWN1jX7:hover {
	background-color: #cdcdcd;
}

div._3Ho0icttcbrV,
button._3Ho0icttcbrV {
	background-color: var(--common-orange);
}

div._3Ho0icttcbrV,
button._3Ho0icttcbrV:hover {
	background-color: var(--dark-orange);
}

div._3Ho0icttcbrV,
button._3YMlphb5j74M {
	background-color: var(--light-gray);
}

.evrQUSD9Zw8A {
	display: flex;
	align-items: center;
	margin-top: 5px;

	color: #595959;
	font-weight: 400;
	font-size: 14px;

	white-space: nowrap;
}

._3tpUwgyPRkNW {
	padding-right: 5px;
}

._2NHzNXRORbOu {
	width: 20px;
	height: 20px;
	margin-bottom: 1px;
	margin-left: 8px;

	font-size: 16px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2NjUgOS45OTk4NEMxLjY2NjUgNS4zOTk4NCA1LjM5OTg0IDEuNjY2NSA5Ljk5OTg0IDEuNjY2NUMxNC41OTk4IDEuNjY2NSAxOC4zMzMyIDUuMzk5ODQgMTguMzMzMiA5Ljk5OTg0QzE4LjMzMzIgMTQuNTk5OCAxNC41OTk4IDE4LjMzMzIgOS45OTk4NCAxOC4zMzMyQzUuMzk5ODQgMTguMzMzMiAxLjY2NjUgMTQuNTk5OCAxLjY2NjUgOS45OTk4NFpNMTAuODMzMiA5LjE2NjUxVjE0LjE2NjVIOS4xNjY1VjkuMTY2NTFIMTAuODMzMlpNOS45OTk4MyAxNi42NjY1QzYuMzI0ODMgMTYuNjY2NSAzLjMzMzE2IDEzLjY3NDggMy4zMzMxNiA5Ljk5OTgzQzMuMzMzMTYgNi4zMjQ4MyA2LjMyNDgzIDMuMzMzMTcgOS45OTk4MyAzLjMzMzE3QzEzLjY3NDggMy4zMzMxNyAxNi42NjY1IDYuMzI0ODMgMTYuNjY2NSA5Ljk5OTgzQzE2LjY2NjUgMTMuNjc0OCAxMy42NzQ4IDE2LjY2NjUgOS45OTk4MyAxNi42NjY1Wk0xMC44MzMyIDUuODMzMTdWNy40OTk4M0g5LjE2NjVWNS44MzMxN0gxMC44MzMyWiIgZmlsbD0iI0JBQkFCQSIvPgo8L3N2Zz4K);
}
