._18v1tjHOyshf {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 40px 50px 28px;
}

._2-k6Rp5TP_l0 {
	font-size: 28px;
	font-weight: 500;
}

._3NJ7-IKvEl8V {
	display: flex;
	align-items: center;
	height: 34px;
}

.neU08_8UUIBC {
	font-size: 15px;
	color: #9a9a9a;
}

._3NJ7-IKvEl8V ._1npr0dTEzsPL {
	height: 34px;
	width: 34px;
	min-width: unset;
	margin-right: 10px;

	border-radius: 50%;

	background-color: rgba(0, 0, 0, 0.08);
}

@media screen and (max-width: 600px) {
	._18v1tjHOyshf {
		margin-bottom: 20px;
		padding: 0;
	}

	._2-k6Rp5TP_l0 {
		font-size: 22px;
	}
}
