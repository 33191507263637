

._89cnYxaVUhy8 {
	margin-bottom: 13px;

	color: var(--fareGroup-header-color);
	font-weight: 500;
	font-size: 24px;
	line-height: 100%;
}

div._2OVMaeVbobPv {
	box-shadow: 0 0 0 1px var(--fareGroup-recommended-border-color);
}

._3m0k69DwGYRo {
	display: flex;
	flex-wrap: wrap;
	margin: 4px -4px 4px auto;
}

._15A0mVaG_y3J {
	display: flex;
	flex: 1 0 48%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	margin-top: 4px;
	margin-right: 4px;
	padding: 5px;

	color: var(--fareGroup-option-text-color);
	font-size: 14px;
	line-height: 21px;
	text-align: center;

	background: var(--fareGroup-option-bg);
}

._2IE3zAlO1Bg0 {
	font-size: 13px;
}

._15A0mVaG_y3J._1jAFfMs-IYGs {
	flex: 1 0 23%;
	padding: 5px;

	font-size: 13px;
}

._1jAFfMs-IYGs._32CHEk8RH74T {
	margin-right: 0;
}

._1jAFfMs-IYGs ._1WrJD4wpb08B {
	display: flex;
	align-items: center;
	justify-content: center;
}

._1WrJD4wpb08B svg {
	color: var(--fareGroup-option-svg-color);
}

._1jAFfMs-IYGs ._1WrJD4wpb08B svg {
	width: 15px;
	height: 15px;
}

.c9XgMPHScSuy {
	max-width: 113px;
	max-height: 90px;

	text-overflow: ellipsis;
	overflow: hidden;
}

._1jAFfMs-IYGs .c9XgMPHScSuy {
	max-width: 50px;
}

._3XG3xuppPN10,
._3gnWK71wwzNM {
	opacity: 0.6;
}

._15A0mVaG_y3J ._35d4JS4oB0Dc svg {
	width: var(--fareGroup-option-svg-size);
	height: var(--fareGroup-option-svg-size);

	color: var(--fareGroup-option-svg-color);
}

._1jAFfMs-IYGs ._35d4JS4oB0Dc {
	display: flex;
	flex-direction: column;
	align-items: center;
}

._15A0mVaG_y3J._1jAFfMs-IYGs ._35d4JS4oB0Dc svg {
	transform: scale(0.8);
}

._2DL9wfsYAgPR {
	padding: 8px 12px;

	border: 1px solid var(--fareGroup-upgrade-option-bdc);
	border-radius: var(--fareGroup-upgrade-option-bdrs);

	color: var(--gray70);
	font-size: 14px;
	line-height: 18px;

	cursor: pointer;
	background: var(--fareGroup-upgrade-option-bg);
}

.KFpjKO-V6h7w {
	display: block;
	margin-top: 5px;

	color: var(--fareGroup-upgrade-option-link-color);
	font-weight: 600;
	line-height: 14px;
	text-decoration: none;
}

.KFpjKO-V6h7w path {
	color: var(--fareGroup-upgrade-option-link-color);
}
