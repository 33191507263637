._1YbiAu2GBkeO {
	margin-top: 0;
	margin-bottom: 24px;

	font-size: 28px;
	color: var(--dark-gray);
	font-weight: 500;
}

div.iXtedgxXR5Z3 {
	overflow: visible;

	min-width: 600px;
}

.I4_gm2p0A098 {
	padding: 40px 40px 0 40px;
}

.uy38zFAxTyvd {
	display: flex;
	flex-direction: column;
}

._3m9G0WOhEl0m {
	margin-top: 24px;
}

.SHKRbIpSVoWD {
	display: flex;
	flex-direction: column;
	padding: 20px 40px 60px 40px;

	color: #9a9a9a;
}

._22OkFllIVAL3 {
	display: flex;
}

._22OkFllIVAL3 button {
	position: relative;

	display: flex;
	align-items: flex-end;
	width: 160px;
	height: 44px;

	font-size: 13px;
}

._1wSKde_AtjPk {
	margin-right: 8px;
	display: flex;
	align-items: center;
}

._2TGIHibimgWK {
	position: relative;
}

._2TGIHibimgWK:nth-child(2) {
	margin: 0 30px;
}

._2PChrjxB_9qm {
	position: absolute;
	right: -14px;
	top: 7px;

	z-index: 1000;

	height: 28px;

	border-radius: 50%;
	border: 4px solid #ffffff;

	cursor: pointer;

	background-color: #ffffff;
}

._2PChrjxB_9qm svg {
	height: 20px;
	width: 20px;
}

._22OkFllIVAL3 button._2zgXoNvH266v {
	border-color: #3c5b96;

	color: #ffffff;

	background-color: #3c5b96;
}

._22OkFllIVAL3 button.y7LY3ujR_9Ry {
	border-color: #59adeb;

	color: #ffffff;

	background-color: #59adeb;
}

._3lLn6KCc0eCx {
	display: none;
}

._3IOHB7jQ_mZP {
	display: flex;
	align-items: center;
	padding: 20px;

	border-top: 1px solid #d9d9d9;

	color: var(--common-gray);
}

._3IOHB7jQ_mZP p {
	margin: 0;
}

._3IOHB7jQ_mZP .ms8KMo5gkNuc {
	display: flex;
}

._3IOHB7jQ_mZP .ms8KMo5gkNuc._2IBkzIqMudtO {
	color: var(--cabinet-action);
}

.hYleruAu9w7f {
	display: flex;
	justify-content: space-between;
	padding: 20px 30px;

	font-size: 13px;
	color: var(--common-gray);

	background-color: #f9f9f9;
}

.hYleruAu9w7f p {
	margin: 0;
	height: 28px;
	display: flex;
	align-items: center;
}

.hYleruAu9w7f span {
	display: flex;
	align-items: center;

	cursor: pointer;
}

.hYleruAu9w7f > p:first-child:not(:only-child) > span:first-child {
	padding-right: 12px;
	margin-right: 12px;

	border-right: 1px solid #d9d9d9;
}

.hYleruAu9w7f svg {
	margin-right: 20px;
	min-width: 24px;
}

.hYleruAu9w7f ._12ySWpZnHAzv {
	height: 100%;
	width: 1px;
	margin: 0 12px;

	border-right: 1px solid #d9d9d9;
}

._1Wf79u_AOPby {
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: #ffffff;
}

._55P1VwcE0Ox8 {
	position: absolute;
}

@media screen and (max-width: 600px) {
	.I4_gm2p0A098,
	.SHKRbIpSVoWD,
	._3IOHB7jQ_mZP {
		background-color: #ffffff;
	}

	._1zOOSz79fM0Y {
		margin: 16px 20px 0;
	}

	._1YbiAu2GBkeO {
		font-size: 22px;
	}

	.I4_gm2p0A098 {
		padding: 20px 20px 0;
	}

	.SHKRbIpSVoWD {
		padding: 20px;
	}

	._22OkFllIVAL3 {
		flex-direction: column;
	}

	._3IOHB7jQ_mZP {
		padding: 5px;
	}

	._3IOHB7jQ_mZP p {
		font-size: 14px;
	}

	.hYleruAu9w7f {
		flex-direction: column;
		padding: 20px;
	}

	.hYleruAu9w7f p {
		height: auto;
		margin: 0;
	}

	.hYleruAu9w7f p:last-child {
		margin-top: 20px;
	}

	.hYleruAu9w7f svg {
		margin-right: 18px;
	}
}
