

._2WM0luWkdUGz {
	display: flex;
	align-items: center;
	padding-bottom: 16px;

	border-bottom: 1px dashed #d9d9d9;
}

._2xZv7fNaiGUf {
	flex-shrink: 0;
	width: 44px;
	height: 44px;
	margin-right: 8px;
}

._2zGemdfS271n {
	text-transform: capitalize;
}

._2zGemdfS271n:after {
	content: ',';

	margin-right: 4px;
}

._1qENSDWi5bwN:after {
	content: ',';

	margin-right: 4px;
}

.YYMHsbQXyFi8 {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;
	margin-right: 16px;

	border-radius: 50%;

	color: var(--white);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
	text-align: center;

	background-color: var(--success);
}

._2PSxHjOPy7PY {
	color: var(--text);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

._2Ph6C0kDBBDB {
	color: var(--success-20);
}
