

.jjb5DA8kv_8X {
	font-size: 12px;
}

._3jUQi7KLlZFi {
	margin: 10px 0 !important;
}

._273_b3Xxoo8j {
	display: none !important;
}

._2pZ324HNydHH .MuiOutlinedInput-multiline {
	padding: 12px 9px 9px;

	background-color: #f2f2f2;
}

._2pZ324HNydHH .MuiOutlinedInput-multiline.Mui-focused {
	background-color: #ffffff;
}

._2X99pIh3dpF4 {
	position: absolute;
	right: 30px;
	top: 18px;
}

._2X99pIh3dpF4 div {
	background: none;
}
