._38yXYHBsyPHi {
	display: flex;
	align-items: center;
}

.Q-50S0ytlimK {
	position: relative;

	display: flex;
	justify-content: space-between;
	max-width: 554px;
	padding: 8px 21px;

	border-radius: 100px;

	color: var(--base-15);
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	white-space: nowrap;
	text-overflow: ellipsis;

	background: var(--base90);
	overflow: hidden;
}

._24NCajmtIQQ4 {
	display: block;
	overflow: hidden;

	text-overflow: ellipsis;
}

@media screen and (max-width: 600px) {
	.Q-50S0ytlimK {
		display: inline-flex;
	}
}

.Q-50S0ytlimK._8h2IX5XUkXqQ {
	padding-right: 44px;
}

._311wZ0pBETgg {
	display: inline-flex;
	width: 24px;
	height: 24px;
	margin-left: 12px;
	margin-right: -9px;

	border-radius: 50%;

	color: #ffffff;

	cursor: pointer;

	background: #ffffff;
}

@media screen and (max-width: 600px) {
	._311wZ0pBETgg {
		position: static;
		top: 0;

		margin-left: 17px;

		transform: none;
	}
}

._38yXYHBsyPHi > .Q-50S0ytlimK:not(:last-of-type) {
	margin-right: 12px;
}

._311wZ0pBETgg svg {
	transform: scale(0.6);
}

._311wZ0pBETgg path {
	color: var(--gray80);
}
