._1jjhc882sqjh {
	margin: 12px auto 0;
	max-width: 1100px;
	display: flex;
}

._1jjhc882sqjh > div:not(:last-of-type) {
	margin-right: 13px;
}

._1l_EafReDQV8 {
	padding: 20px 24px;
}

button.gUMgADkDgI5f {
	padding: 0 25px;
}

@media screen and (max-width: 600px) {
	._1jjhc882sqjh {
		margin: 0;
		flex-direction: column;

		background: #ffffff;
	}

	._1jjhc882sqjh > div:not(:last-of-type) {
		margin-right: 0;
	}
}
