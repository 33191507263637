.YGT5k6N8jfm5 {
	font-weight: 700;
	font-size: 21px;
	line-height: 30px;
	color: var(--gray80);
}

._3jL69drkB9jr {
	margin-top: 12px;

	font-size: 13px;
	line-height: 1.5;
	color: #727272;
}

._2GmZslzl5zrh {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 33%;
}

._2GmZslzl5zrh.pgk3JNRLaGIM {
	margin-left: auto;

	text-align: right;
}

._1vb3oYaxpWhe {
	margin-top: auto;
}

._3Vh1KSStZ6EQ {
	flex: 1;
}

._3cvEQGbPSWjA {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: 100%;

	padding: 16px 0;

	border-radius: 4px;

	background: #f6f6f6;
}

._3cvEQGbPSWjA:not(:last-child) {
	margin-right: 12px;
}

._3M_qysSjV36w {
	font-weight: 400;
	font-size: 13px;
	line-height: 1.4;
	color: #808080;
}

._1me-mWhLm7Ne {
	margin-top: auto;

	font-weight: 700;
	font-size: 13px;
	line-height: 1.4;
	color: var(--text);
}

._1me-mWhLm7Ne.Kaf74yS7gkXM {
	color: var(--success);
}

._2mXlNwBjDxgf {
	display: flex;

	color: #262626;
	font-weight: 600;
	font-size: 13px;
	line-height: 130%;
}

._2mXlNwBjDxgf.JYcFCJrxzUZs {
	justify-content: flex-end;
}

._3Lnvyc7F1NuM {
	margin-left: 12px;
	padding-left: 8px;

	border-left: 1px solid #d9d9d9;

	font-size: 16px;
}

._3DrTCsemWBR9 {
	display: flex;
	flex-wrap: wrap;
}

._2_74ytCiSQog {
	display: flex;
	align-items: center;
	flex: 1;
}

._16TkVkPXWsyZ {
	margin-top: 8px;
	padding: 12px 10px;

	border: 1px solid var(--warning);
	border-radius: 4px;

	color: var(--text-mid);
	font-size: 11px;

	background: var(--warning20);
}

.N5yF4uqTLf_g {
	width: calc(100% + 68px);
	display: flex;
	align-items: center;
	margin-left: -34px;
	margin-bottom: 24px;
}

.N5yF4uqTLf_g div[class='slick-list'] {
	padding-left: 34px;
}

.N5yF4uqTLf_g div[class='slick-track'] > div {
	display: flex;
	width: 78px;
	margin-right: 10px;
}

.N5yF4uqTLf_g div[class='slick-track'] > div > div {
	width: 78px;
}

.GwHnd8i7WBj9 {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

._1za-tdbBvAA_ {
	font-size: 16px;
	font-weight: 700;
	color: var(--text);
}

.PA_RsfzFhMeX {
	display: flex;
	flex-direction: column;
}

._3VpWXzULJwUT {
	margin-bottom: 4px;

	color: var(--base);
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
}

.O473d4naCQxw {
	color: #4d4d4d;
	font-size: 13px;
	line-height: 1.3;
}

@media screen and (max-width: 600px) {
	.zFMpG5JUR93z {
		display: none;
	}

	._3DrTCsemWBR9 {
		display: block;
	}

	._3cvEQGbPSWjA {
		margin-bottom: 12px;
		padding-bottom: 12px;

		border-bottom: 1px solid #d9d9d9;

		background: none;
	}

	._25EKdXoBWlxN {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.T1QvlBS7hJNP {
		display: flex;
		align-items: center;
		width: 100%;
	}

	._3jL69drkB9jr {
		margin-left: 20px;

		color: var(--text-light);
		font-size: 14px;
	}

	._20CDEKceUEO4 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		color: var(--brand1);
		font-weight: 500;
		font-size: 14px;
	}

	._1me-mWhLm7Ne {
		margin-top: 0;

		color: var(--text);
		font-size: 16px;
	}

	._2mXlNwBjDxgf {
		margin-bottom: 8px;
	}

	.N5yF4uqTLf_g div[class='slick-track'] > div {
		width: 58px;
		margin-right: 16px;
	}

	.N5yF4uqTLf_g div[class='slick-track'] > div > div {
		width: 58px;
	}

	.GwHnd8i7WBj9 {
		margin-bottom: 12px;
		padding-bottom: 14px;

		border-bottom: 1px solid #d9d9d9;
	}

	.O473d4naCQxw {
		display: none;
	}
}
