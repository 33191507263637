div._2iHUECYl9p9o {
	background: var(--gray05);
	overflow: auto;
}

div._1UAXFGoWtswe {
	padding: 0;
}

div.a9MFit0hMER3 {
	position: absolute;
	z-index: 6;
	top: 26px;
	right: calc(50vw - 550px);
}

div._3myrVITsn1Y2 {
	position: fixed;
}

._1LrGZK9VfTo1 {
	min-width: -moz-fit-content;
	min-width: fit-content;

	color: var(--text-base);
	font-weight: 600;
	font-size: 24px;
	line-height: 80px;
}

.fJcnEq94d6EW {
	position: absolute;
	left: calc(50vw - 550px);

	display: flex;
	justify-content: center;
}

.cG5ue7RMHPg_ {
	left: calc(50vw - 550px);
	position: absolute;
}

._211JMsgY73rL {
	position: relative;
	top: 0;
	z-index: 5;

	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 80px;
	padding-right: calc(50vw - 550px + 50px);

	padding-left: calc(50vw - 550px);

	color: var(--text-base);

	background-color: var(--bg-white);
}

._3KvuI39OfLJ6 {
	position: fixed;

	height: 0;
}

._3uyMPCTFABnk {
	justify-content: flex-start;
}

._3BYUCuYHlAVK {
	position: fixed;
}

.vRBxIIcojJdy {
	margin-top: 80px;
}

@media (max-width: 1024px) {
	div.a9MFit0hMER3 {
		right: 5px;
	}
}
