

._2B1tX9EPK0Ix {
	padding: 44px 48px;
	max-width: 600px;
}

.DubpV9tIVBrF {
	font-weight: 600;
	font-size: 28px;
	color: #1d1d1d;
}

.Xht0lCpt92E2 {
	display: flex;
	margin-top: 40px;
}

.YXJJ2DYSuhqU {
	display: flex;
	align-items: center;
	margin-top: 10px;
}

._2a7gCwd2C6Gq {
	flex: 1;
	min-width: 50%;
	margin-bottom: 8px;
}

._2a7gCwd2C6Gq:only-child {
	max-width: 100%;
}

.YXJJ2DYSuhqU ._2a7gCwd2C6Gq:not(:last-child) {
	padding-right: 20px;
}

._2a7gCwd2C6Gq > div {
	width: 100%;
}

._2a7gCwd2C6Gq._1Y2PpuC1BOaM {
	flex: 0;
	min-width: 120px;
}

._36cfgKw4Infm {
	display: flex;
	margin-top: 20px;
	align-items: center;
	justify-content: space-between;
}

._36cfgKw4Infm a {
	text-decoration: none;
	font-size: 15px;
}

._1zIns44N-LOX {
	display: flex;
	flex-direction: column;
}

._1zIns44N-LOX a:not(:last-child) {
	margin-bottom: 10px;
}

._1UaRyqgCFZpA {
	display: flex;
	flex-shrink: 0;
	align-items: center;

	color: var(--gray30);
	font-size: 15px;
}

._1UaRyqgCFZpA ._2N1i8JNscc64 {
	margin-right: 15px;
}

._1RpUj9YM24O6 {
	margin: 20px 0;

	color: var(--error);
	font-size: 14px;
}

._3l6riiDlJjda {
	margin-top: 40px;

	color: #333333;
	font-size: 16px;
}

._3l6riiDlJjda ._36cfgKw4Infm {
	justify-content: flex-end;
}
