

._34zcLGdKfc2I svg {
	color: var(--error);
}

._1rSXQ4MkjmqT {
	height: 52px;
	padding: 16px 20px;

	font-weight: 500;
	font-size: 16px;

	background: var(--white);
}

._1rSXQ4MkjmqT td {
	border-bottom: 1px solid var(--checkout-background);
}

._3XQwILjd30x4 td {
	border-bottom: none;
}

._3XQwILjd30x4 {
	height: 40px;
}

._3_LEAgRYyJ-J {
	height: auto;
}

.gPUwiP5I6yDM {
	width: 100%;

	border-spacing: 0;
}

.gPUwiP5I6yDM th {
	padding: 0;
}

._1hennmagypXH {
	width: 5%;
}

._9kglR40hDB1O {
	min-width: 70%;
}

.cF8LvN9aEkW- {
	min-width: 10%;
}

._1_xUVD5fEn2W {
	width: 10%;
}

._1_RDnm3TGkse {
	width: 5%;
}

._1K6Ic5oU9Q8W {
	padding-left: 20px;
}

._366GmAZHlvaB,
._1DDPJ3LAqQ-z {
	text-align: right;
}

._2GVKL0t2nTtF {
	color: var(--base-30);
	font-weight: 700;
}

._9vKVCAWIVgYM {
	color: var(--gray50);
	font-weight: 400;
}

._3G-Ubaoyw_p0 {
	padding-left: 20px;
}

._3_LEAgRYyJ-J td {
	padding-left: 20px;
	padding-bottom: 10px;

	font-weight: 400;
}

._1DDPJ3LAqQ-z {
	padding-right: 20px;
}
