

._2nZAQf-1zxOv {
	position: relative;

	cursor: pointer;
}

.SuK45qOx_8WS {
	position: absolute;
	top: 40px;
	left: -78px;

	width: 190px;
	display: inline-flex;

	border-radius: 4px;

	background-color: #ffffff;

	box-shadow: 4px 0 14px rgba(0, 0, 0, 0.2);
}

.SuK45qOx_8WS ._32FXVgocTwTI {
	display: flex;
	width: 100%;
	padding: 0;
	flex-direction: column;
}

.yWIPkGTsQaoD {
	display: flex;
	flex-wrap: nowrap;
	height: 40px;

	border-bottom: 1px solid #ededed;

	color: var(--dark-gray);
	font-size: 16px;
	line-height: 125%;

	font-weight: 400;

	cursor: pointer;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.yWIPkGTsQaoD:last-child {
	border-bottom: none;
}

.yWIPkGTsQaoD:hover {
	background-color: #ededed;
}

.SuK45qOx_8WS:before {
	position: absolute;
	right: 44%;
	top: -22px;

	display: block;
	content: '';

	height: 0;
	width: 0;

	border: 11px solid transparent;
	border-bottom-color: #ffffff;

	pointer-events: none;
}
