

._1WTRM6nm93mF {
	background: linear-gradient(180deg, #ff9255 0%, #6c75c4 100%);
}

._1WTRM6nm93mF svg {
	position: relative;
	right: -20px;

	width: 150px;
	height: 113px;

	opacity: 0.8;
}

.RrVayP5c1Fh1 {
	white-space: pre;
}
