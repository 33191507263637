.k0PeLep1WNff {
	position: absolute;

	margin-top: 20px;
}

@media screen and (max-width: 600px) {
	.k0PeLep1WNff {
		position: relative;

		display: flex;
		margin-top: 6px;
	}
}

button._314V_8OjBWrx,
button._1NxWEd8Tmkmj {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 138px;
	height: var(--input-height);
	padding: 0;

	border: none;
	border-radius: 2px;

	cursor: pointer;

	background-color: rgba(255, 255, 255, 0.2);

	transition: background-color 0.15s, opacity 0.15s;
}

button._314V_8OjBWrx:disabled {
	opacity: 0.4;
	cursor: not-allowed;
}

button._1NxWEd8Tmkmj {
	position: relative;

	display: none;
}

button._1NxWEd8Tmkmj svg {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;

	margin: auto;
}

button._314V_8OjBWrx:not(:disabled):hover,
button._1NxWEd8Tmkmj:hover {
	background-color: rgba(255, 255, 255, 0.35);
}

@media (max-width: 1024px) and (min-width: 601px) {
	._314V_8OjBWrx {
		width: 68px;
	}
}

@media screen and (max-width: 600px) {
	button._314V_8OjBWrx,
	button._1NxWEd8Tmkmj {
		width: 100%;
		flex-grow: 1;
	}

	button._1NxWEd8Tmkmj {
		display: block;
		margin-left: 4px;
	}

	._38NaobDrWizG {
		margin-top: 0;
	}

	button._1a4t9dqgYM3d,
	button._2BWJqEZMVy50 {
		border-radius: 0;
	}
}
