.SEH68UULDVFX {
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 600px) {
	.SEH68UULDVFX {
		padding: 0 20px 20px;
	}
}

._1wOXo-YQPUJU {
	position: relative;

	display: flex;
	align-items: center;
	min-height: 122px;
	margin-bottom: 50px;
	padding: 40px 130px;

	background: #ffffff;
}

.uJMRdvfQAVnA {
	margin-top: -50px;
}

@media screen and (max-width: 600px) {
	._1wOXo-YQPUJU {
		padding: 20px 0;
		margin-bottom: 0;
		min-height: auto;

		background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;

		background: initial;
	}
}

.lSi-pIPnmzH0 {
	margin: 0 27px 0 0;

	color: var(--gray50);
	font-size: 34px;
	line-height: 34px;
}

@media screen and (max-width: 600px) {
	.lSi-pIPnmzH0 {
		font-size: 21px;
		line-height: 31px;
		color: var(--gray80);
	}
}

._3PuwS8JZyerO {
	position: absolute;
	top: 28px;
	right: 28px;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;

	border-radius: 50%;

	color: var(--gray80);

	cursor: pointer;
	background: var(--gray05);
}

._3_U1uB-6DafT {
	margin-top: 50px;
}

@media screen and (max-width: 600px) {
	._3PuwS8JZyerO {
		right: 0;
		top: 50%;

		transform: translateY(-50%);
	}
}

._3PuwS8JZyerO svg {
	transform: scale(0.6);
}
