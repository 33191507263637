

._1LYfhxK4bivH {
	flex: 1;
	flex-basis: 33%;
	padding-right: 1px;
}

._3YHQScB3TT9O {
	position: relative;

	margin-right: 2px !important;

	border-radius: 0;

	box-shadow: none;
}

._2D37FwcyRRIx {
	position: absolute;
	bottom: 0;
	left: 40px;

	width: 50%;

	padding: 5px 15px !important;

	background: var(--bright-blue) !important;
}

._1BBsto5X817u {
	font-weight: 500 !important;

	font-size: 16px !important;
	line-height: 22px !important;
	color: #ffffff !important;
}

._2hWZDfIYu4ZI {
	padding: 0 !important;
}

._3V9CJzwEtuv9 {
	padding: 20px 40px 0 !important;
}

.ErFwLLBEnZa5 {
	padding: 5px 0 !important;

	color: var(--dark-gray) !important;
	line-height: 26px;
	font-size: 14px;
	font-weight: 300;
}

._1LYfhxK4bivH ._3V9CJzwEtuv9 {
	border-right: 1px dashed #d9d9d9;
}

._1LYfhxK4bivH:last-of-type,
._1LYfhxK4bivH:nth-of-type(3n + 3) {
	padding-right: 0 !important;
}

._1LYfhxK4bivH:last-of-type ._3V9CJzwEtuv9,
._1LYfhxK4bivH:nth-of-type(3n + 3) ._3V9CJzwEtuv9 {
	border-right: none;
}

._1D8qbhn7oD7u {
	height: 250px;

	background-size: cover;
}
