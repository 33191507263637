

._2UM3DmPeyWfi {
	position: absolute;
	right: 80px;
}

button._1ZrdMKUaDwKv {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	min-width: auto;
	height: 44px;
	padding: 10px;

	border-radius: 50%;

	color: var(--gray70);

	background: var(--gray05);
}

._1ZrdMKUaDwKv:first-of-type {
	margin-right: 10px;
}

._1ZrdMKUaDwKv:hover {
	color: var(--gray);
	color: var(--schedule-type-selector-button-hover-color, var(--gray));
}

button._3xQ0Gox3JQPp {
	color: var(--gray);
	color: var(--schedule-type-selector-button-active-color, var(--gray));

	background: var(--base);
}
