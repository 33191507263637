

._2ebUWB33EUKl {
	display: flex;
}

._2ebUWB33EUKl ._27r-2Zb4UbZh,
._2ebUWB33EUKl button._27r-2Zb4UbZh {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 71px;
	min-width: 71px;
	max-width: 71px;
	height: var(--input-height);
	margin-right: 4px;

	border-radius: 4px;

	background: var(--start-search-button);
}
