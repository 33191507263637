._3PBrFYIGxCoL {
	position: relative;

	text-align: center;
	overflow: hidden;
}

._1hxXruWyzcad {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;

	background: rgba(0, 0, 0, 0.5);
}

.PX2tcU99KkNI {
	position: relative;

	display: inline-block;
	margin-bottom: 10px;

	border-bottom: 9px solid #ededed;
}

._290Aa698mX8u {
	padding-bottom: 340px;
}

._3vVt1JZ_FkOn {
	position: absolute;
	top: 1px;
	right: 0;

	left: 0;

	display: none;

	width: 208px;
	height: 190px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjA4IiBoZWlnaHQ9IjE5MCIgdmlld0JveD0iMCAwIDIwOCAxOTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjA3Ljc0IDE5MEMyMDcuMTA1IDE3MS4wNDMgMjAzLjkzMyAxNDIuNDc0IDE5My44NyAxMTUuODQ4QzE4Mi4zNyA4NS40MTc3IDE0OC44NyAwIDEwMy44NyAwQzU4Ljg3MDEgMCAyNS4zNzAxIDg1LjQxNzcgMTMuODcwMSAxMTUuODQ4QzMuODA3NTYgMTQyLjQ3NCAwLjYzNTcxNSAxNzEuMDQzIDAgMTkwSDguODcwMjhDOC45MjgwNCAxNzEuNzA2IDI2LjA0OTEgMTI4LjIzNCAyNi4wNDkxIDEyOC4yMzRDMjYuMDQ5MSAxMjguMjM0IDYyLjczNTIgODguNjQzNyAxMDMuODcgODguMDA3N1Y4OEMxMDQuMDM3IDg4IDEwNC4yMDQgODguMDAwNiAxMDQuMzcgODguMDAxOUMxMDQuNDQ1IDg4LjAwMTQgMTA0LjUyIDg4LjAwMDkgMTA0LjU5NSA4OC4wMDA2QzEwNC42ODcgODguMDAwMiAxMDQuNzc4IDg4IDEwNC44NyA4OFY4OC4wMDc3QzE0Ni4wMDUgODguNjQzNyAxODIuNjkxIDEyOC4yMzQgMTgyLjY5MSAxMjguMjM0QzE4Mi42OTEgMTI4LjIzNCAxOTkuODEyIDE3MS43MDYgMTk5Ljg3IDE5MEgyMDcuNzRaIiBmaWxsPSIjRURFREVEIi8+Cjwvc3ZnPgo=) center no-repeat;
	background-size: contain;
}

.lrNMQvKhv9gU {
	position: absolute;
	right: 0;
	bottom: 0;

	left: 0;

	display: none;

	width: 208px;
	height: 123px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjA4IiBoZWlnaHQ9IjEyMyIgdmlld0JveD0iMCAwIDIwOCAxMjMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS40MzUyNSAwSDBWMTQuMzQ5MkMwIDM2LjQ1NDIgNSA4Ni44NzQ1IDIyLjUgMTQ4Ljc0OUMyNy40NjggMTY2LjMxNCAzNi4yNjQgMTg1LjkyMyA0Ni4zMTQzIDIwNUgxNjEuNjg2QzE3MS43MzYgMTg1LjkyMyAxODAuNTMyIDE2Ni4zMTQgMTg1LjUgMTQ4Ljc0OUMyMDMgODYuODc0NSAyMDggMzYuNDU0MiAyMDggMTQuMzQ5MlYwSDE5OS41NjVDMTk2LjI2OSAxOC4xNzc3IDE3NS4zNzIgMjQgMTc1LjM3MiAyNEgxMDVIMTA0SDMzLjYyNzZDMzMuNjI3NiAyNCAxMi43MzA4IDE4LjE3NzcgOS40MzUyNSAwWiIgZmlsbD0iI0VERURFRCIvPgo8L3N2Zz4K) center no-repeat;
	background-size: contain;
}

._2YoZeDQeOSUm {
	display: none;
}

._2A-yiyRDgd1i {
	position: relative;

	display: inline-block;
	min-width: 208px;
	width: 394px;
	padding: 0 17px;

	background: #ffffff;
}

._3HVo9m1ezzh6 {
	position: relative;

	display: flex;
	justify-content: center;

	width: 100%;
	padding: 0 6px;

	background: #ffffff;
}

._1_Zp1-RgoMPC {
	position: relative;
}

._2XCgSZLQcISy {
	position: absolute;

	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
}

._2KUXAFlu5yAK {
	padding-top: 40px;
}

.dY5iT9NR9ZdU {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;

	height: 21px;

	border-bottom: 1px solid #aad1ff;
}

._1pQVxxHVFpdq {
	display: inline-block;
	min-width: 0;
	max-width: 100%;
	margin: 0 auto;
	padding: 0 10px;

	border-radius: 4px 4px 0 0;

	color: var(--gray70);
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;

	white-space: nowrap;

	text-transform: uppercase;
	text-overflow: ellipsis;

	background: #aad1ff;
	overflow: hidden;
}

._3gTeCnztMtJu {
	text-transform: none;
}

.dY5iT9NR9ZdU.J1EEBx__diiX {
	border-color: var(--seat-business);
}

.dY5iT9NR9ZdU.J1EEBx__diiX ._1pQVxxHVFpdq {
	background: var(--seat-business);
}

.dY5iT9NR9ZdU._1p1uUvYPMcjZ {
	border-color: var(--seat-extra);
}

.dY5iT9NR9ZdU._1p1uUvYPMcjZ ._1pQVxxHVFpdq {
	background: var(--seat-extra);
}

._3tv2V83_NFLf {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-bottom: 12px;

	width: 100%;
	flex: 1;
}

.da9FruiiKM-O {
	flex: 0;
}

._3tv2V83_NFLf._1v0CJS0JKCrk {
	position: static;
}

.q_ZYvFh84cdz {
	border-radius: 0;
}

._3EUWzhs72-rf {
	border-radius: 0;
}

._2V90RDtZ5MLI ._3tv2V83_NFLf:first-child ._2XCgSZLQcISy {
	border-bottom-left-radius: 5px;
}

._2V90RDtZ5MLI ._3tv2V83_NFLf:last-child ._2XCgSZLQcISy {
	border-bottom-right-radius: 5px;
}

._2ucooqwIFDcV ._3tv2V83_NFLf:first-child ._2XCgSZLQcISy {
	border-top-left-radius: 5px;
}

._2ucooqwIFDcV ._3tv2V83_NFLf:last-child ._2XCgSZLQcISy {
	border-top-right-radius: 5px;
}

._2ucooqwIFDcV ._3EUWzhs72-rf {
	border-top-left-radius: 5px;
}

._2ucooqwIFDcV .q_ZYvFh84cdz {
	border-top-right-radius: 5px;
}

._2V90RDtZ5MLI .q_ZYvFh84cdz {
	border-bottom-right-radius: 5px;
}

._2V90RDtZ5MLI ._3EUWzhs72-rf {
	border-bottom-left-radius: 5px;
}

._2EPIQG0NP697 {
	padding: 0 0 15px 0;
	justify-content: space-between;
}

.Y7BLp3T-XfRS,
._1RH-UeGj05lC,
.gj1gwYGynB4_,
._1P4iRjeaNqjL,
._3hzZ_XPVz2bi,
._3ki9rawLTYdR {
	opacity: 0;
}

._1P4iRjeaNqjL {
	background-color: #e6eaf9;
}

._3hzZ_XPVz2bi {
	background-color: #dcecff;
}

._3ki9rawLTYdR {
	background-color: #f9f5f4;
}

div._2XCgSZLQcISy[data-highlight='true'] {
	opacity: 1;
}

.Y7BLp3T-XfRS {
	background-color: #e8f3df;
}

._1RH-UeGj05lC {
	background-color: #fef4e9;
}

.gj1gwYGynB4_ {
	background-color: #ebf6fe;
}

.ON4BFSC65-rh {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;

	height: 40px;

	background: #ffffff;
}

._2KJta3SzMD5u {
	position: relative;

	font-size: 14px;
	color: #9a9a9a;
}

._2KJta3SzMD5u:before {
	content: '';

	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;

	margin: auto;
	width: 20px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjQxIDcuNDFMMTQgNkw4IDEyTDE0IDE4TDE1LjQxIDE2LjU5TDEwLjgzIDEyTDE1LjQxIDcuNDFaIiBmaWxsPSIjQjNCM0IzIi8+Cjwvc3ZnPgo=) center no-repeat;
}

._2KJta3SzMD5u:first-child {
	padding-left: 25px;
}

._2KJta3SzMD5u:last-child {
	padding-right: 25px;
}

._2KJta3SzMD5u:last-child:before {
	right: 0;
	left: auto;

	transform: rotate(180deg);
}

._2Fbgk2ad0UUP {
	position: absolute;
	left: 0;
	right: 0;
}

.kKOAfS3xUNsv {
	bottom: 20px;
}

._3y3ND6oIntnw {
	top: 45%; /* because we didn't start from top */

	transform: translate(0, -50%);
}

.kKOAfS3xUNsv:before {
	left: 94.01408450704226%;
}

.kKOAfS3xUNsv:after {
	left: 17px;
}

._2Fbgk2ad0UUP:after {
	position: absolute;
	left: 0;

	width: 1171px;
	height: 778px;

	transform: translateX(-100%);

	background: #e4e4e4;
	-webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE3MSIgaGVpZ2h0PSI3NzgiIHZpZXdCb3g9IjAgMCAxMTcxIDc3OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMDAxLjUgMTM0Ljk0QzEwMDEuNjIgMTI3LjEzMyAxMDAxLjY4IDExOS4yNjQgMTAwMS42OCAxMTEuMzQyQzEwMDEuNjggNzguNzE5NiAxMDAwLjYzIDQ3LjAxODYgOTk4LjYzOCAxNi42ODk3Qzk5OC4wMTkgNy4yNjQ3MyA5OTAuMTQ5IDAgOTgwLjcwMyAwSDgxMC42OTRDODAxLjI0OCAwIDc5My4zNzggNy4yNjQ3MyA3OTIuNzYgMTYuNjg5N0M3OTAuNzcgNDcuMDE4NiA3ODkuNzE0IDc4LjcxOTYgNzg5LjcxNCAxMTEuMzQyQzc4OS43MTQgMTUyLjIxNCA3OTEuMzcxIDE5MS42NCA3OTQuNDQ3IDIyOC43MzRMMCA1ODguNjE2Vjc3OEwxMTcxIDQ3Ni40NTZWNTguMTU2MUwxMDAxLjUgMTM0Ljk0WiIgZmlsbD0iI0U0RTRFNCIvPgo8L3N2Zz4K);
	        mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE3MSIgaGVpZ2h0PSI3NzgiIHZpZXdCb3g9IjAgMCAxMTcxIDc3OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMDAxLjUgMTM0Ljk0QzEwMDEuNjIgMTI3LjEzMyAxMDAxLjY4IDExOS4yNjQgMTAwMS42OCAxMTEuMzQyQzEwMDEuNjggNzguNzE5NiAxMDAwLjYzIDQ3LjAxODYgOTk4LjYzOCAxNi42ODk3Qzk5OC4wMTkgNy4yNjQ3MyA5OTAuMTQ5IDAgOTgwLjcwMyAwSDgxMC42OTRDODAxLjI0OCAwIDc5My4zNzggNy4yNjQ3MyA3OTIuNzYgMTYuNjg5N0M3OTAuNzcgNDcuMDE4NiA3ODkuNzE0IDc4LjcxOTYgNzg5LjcxNCAxMTEuMzQyQzc4OS43MTQgMTUyLjIxNCA3OTEuMzcxIDE5MS42NCA3OTQuNDQ3IDIyOC43MzRMMCA1ODguNjE2Vjc3OEwxMTcxIDQ3Ni40NTZWNTguMTU2MUwxMDAxLjUgMTM0Ljk0WiIgZmlsbD0iI0U0RTRFNCIvPgo8L3N2Zz4K);
	content: '';
}

._2Fbgk2ad0UUP:before {
	position: absolute;
	left: 100%;

	width: 1171px;
	height: 778px;

	background: #e4e4e4;
	-webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE3MSIgaGVpZ2h0PSI3NzgiIHZpZXdCb3g9IjAgMCAxMTcxIDc3OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNjkuNTAyIDEzNC45NEMxNjkuMzc5IDEyNy4xMzMgMTY5LjMxNyAxMTkuMjY0IDE2OS4zMTcgMTExLjM0MkMxNjkuMzE3IDc4LjcxOTYgMTcwLjM3MiA0Ny4wMTg2IDE3Mi4zNjIgMTYuNjg5N0MxNzIuOTgxIDcuMjY0NzMgMTgwLjg1MSAwIDE5MC4yOTcgMEgzNjAuMzA2QzM2OS43NTIgMCAzNzcuNjIyIDcuMjY0NzMgMzc4LjI0IDE2LjY4OTdDMzgwLjIzIDQ3LjAxODYgMzgxLjI4NiA3OC43MTk2IDM4MS4yODYgMTExLjM0MkMzODEuMjg2IDE1Mi4yMTQgMzc5LjYyOSAxOTEuNjQgMzc2LjU1MyAyMjguNzM0TDExNzEgNTg4LjYxNlY3NzhMMCA0NzYuNDU2VjU4LjE1NjFMMTY5LjUwMiAxMzQuOTRaIiBmaWxsPSIjRTRFNEU0Ii8+Cjwvc3ZnPgo=);
	        mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE3MSIgaGVpZ2h0PSI3NzgiIHZpZXdCb3g9IjAgMCAxMTcxIDc3OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNjkuNTAyIDEzNC45NEMxNjkuMzc5IDEyNy4xMzMgMTY5LjMxNyAxMTkuMjY0IDE2OS4zMTcgMTExLjM0MkMxNjkuMzE3IDc4LjcxOTYgMTcwLjM3MiA0Ny4wMTg2IDE3Mi4zNjIgMTYuNjg5N0MxNzIuOTgxIDcuMjY0NzMgMTgwLjg1MSAwIDE5MC4yOTcgMEgzNjAuMzA2QzM2OS43NTIgMCAzNzcuNjIyIDcuMjY0NzMgMzc4LjI0IDE2LjY4OTdDMzgwLjIzIDQ3LjAxODYgMzgxLjI4NiA3OC43MTk2IDM4MS4yODYgMTExLjM0MkMzODEuMjg2IDE1Mi4yMTQgMzc5LjYyOSAxOTEuNjQgMzc2LjU1MyAyMjguNzM0TDExNzEgNTg4LjYxNlY3NzhMMCA0NzYuNDU2VjU4LjE1NjFMMTY5LjUwMiAxMzQuOTRaIiBmaWxsPSIjRTRFNEU0Ii8+Cjwvc3ZnPgo=);
	content: '';
}

.uyW0aaC_9fdN {
	width: 76px;
	height: 40px;

	border: 1px solid #d9d9d9;

	background-color: #ededed;
	background-repeat: no-repeat;
	background-position: center;
}

._22pfPtjFHjF8 {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEzSURBVHgB7VWxbsIwEH1tqdQMHbJUajtUapZKHhsGJL6D/2DhF/gF/oWVEUZGGFmQYGCAgYE74SiHuUsgygLiSU+52Ge/xO9sA7eOl4I+R/wjbog7pf+N2CJ+EBfWJA2jPSV2fNwm9pWcLjEWYiNtomfocCKOkU+UIQraHAxYAjMRr4jroH9LnIv3KQw8wcY/jl86hu4B/4HzYhNrEs3kzLx34ivxxwstldxv4h5XmizNkxjgdFlkHj+HypgzDzRDMyQi/g3y2sYY0+QypMF75EVrE0iUNleXwCf0ZUxRk0DTaFeXqYqAK+hLygR4x/ZwWo4MPou4DIuqjHG2TA1chwh5vfOffPl4jPw44Y26qyqwQL5r40Bgrg2oWqYX4yFQCstkNk1eOlslhy+ZrHLWuFscAEBIKetjOHj1AAAAAElFTkSuQmCC);
}

._3ZWcl9Y8ydIx {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFbSURBVHgB7ZTNbYNAEIWHJRJXSnAJTgVxOnAqCK4g+MDPLTkiLiEVxKkgcQd0kHSQLYEjIAF5Y0GEhDesVz7k4E9asaDZecvs2yG6cA6CIFhGUfRMBlg6QSwghPhs2/a66zoX82WappnOWqEVJMQNP23bPiS3LOuBNNESAD6GTJIk75NLzXV0pRm3QGm2wxwiG811en+A5G+j17xpmoLOje/7LhkwcVEYhmt2Cp0IylbAWR+zAvB7R2Zw2b4wZFVV2yzLDmUUikAjAfz5Hs+V4zjvw8eJAIIkmSH58vVuWykFwJ4MQOIcRmAL86WUSoG6rrkFnFwmJH5Cab4x9dBS7n6/HwuO49jDjl5JP/kt9bsuy7IYDlgpwMCuPhbOdVA+2M0xeyoF0Dm90c4Wfe9xFclfBlOo7sGkF6Fb3tPIBX/gIukjxvC+w5gX4EaGQ1ordq2igDl2dOFf8gMNoYXc4iOElgAAAABJRU5ErkJggg==);
}

@media (max-width: 1024px) {
	._3PBrFYIGxCoL {
		display: flex;
		justify-content: flex-end;
	}

	.PX2tcU99KkNI {
		margin-right: 10%;
	}
}

@media (max-width: 768px) {
	._3PBrFYIGxCoL {
		justify-content: center;
	}

	.PX2tcU99KkNI {
		margin-right: 0;
	}
}

@media screen and (max-width: 600px) {
	.PX2tcU99KkNI {
		top: 0;

		padding: 0;

		margin: 0;

		width: 100%;
	}

	._2A-yiyRDgd1i {
		width: 100%;
	}

	.kKOAfS3xUNsv {
		bottom: calc(50% + 200px);
	}

	._2YoZeDQeOSUm {
		bottom: -123px;

		display: unset;
	}

	._3OvBPxNaXc_k ._2O66FdPHgGAc {
		width: 23px;

		background-size: 80%;
	}

	.lrNMQvKhv9gU {
		display: none;
	}
}
