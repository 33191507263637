

._1pf13rPOxaLR {
	margin-bottom: 10px;
	padding-bottom: 10px;

	border-bottom: 1px solid var(--gray10);

	font-weight: 700;
	font-size: 20px;
}

._2GTVuXMytdnd {
	display: flex;
	justify-content: space-between;
}

.hqzLIHbBKLrt {
	flex: 1;
}

._2IgsBK6lM2Sl {
	font-weight: 700;
}

._3MGETQev0y_q {
	margin: 10px 0;

	border-top: 1px dashed var(--gray40);
}
