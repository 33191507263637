

._12aOGn8nsGiy {
	display: flex;
	flex-direction: column;

	padding: 44px 20px 20px;

	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

._1r3FXEaVpJ5W {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 20px;
}

button._1oAuU9aPiHaT,
button._1oAuU9aPiHaT:hover {
	color: var(--text-light);

	background: var(--bg-fill);
}

._2jnDOtEOmJQo {
	position: absolute;
	top: 12px;
	right: 12px;
}
