

._2dlQJlD2WK2u {
	padding: 40px;
	width: 500px;
}

._2dlQJlD2WK2u > span > svg {
	transform: scale(0.6) !important;
}

._1MwcDwW6UUcV {
	width: 420px;
	height: 0;

	margin-top: 18px;
	margin-bottom: 18px;

	border: 1px solid var(--gray10);
}

._2kygknFz32dC {
	margin-top: 28px;
	display: flex;
	justify-content: flex-end;
}

._1FAdUEbeP8gc {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

._1FAdUEbeP8gc div {
	white-space: nowrap;
}

._1FAdUEbeP8gc div:first-child {
	overflow: hidden;

	max-width: 60%;

	text-overflow: ellipsis;
}

._1FAdUEbeP8gc div:last-child {
	text-align: end;
}

._1u67dLqyc8Lv {
	margin-bottom: 30px;

	font-weight: 500;
	font-size: 21px;
}

div.L2unvUd54NfM {
	margin-bottom: 20px;
}

div.L2unvUd54NfM + div._2OIMe3IIw-Ud {
	margin-top: 20px;
	margin-bottom: 32px;
}

._3nLOvAqG2KOG > span {
	font-weight: 500;
}

._2dEqjb1lNGjY,
._2dEqjb1lNGjY span:not(:first-child) {
	width: 96%;
	flex: 0 0 auto;
}

._2dEqjb1lNGjY > ._1vZUW7_NI9lY svg {
	color: var(--base);
}
