

.gtKC6TiI1V9n {
	padding: 40px;
	display: flex;
	flex-direction: column;
}

._15EwQb1C5AtZ {
	margin: 0;
	padding-bottom: 30px;
}

button.G_y6o8ktmypT {
	margin-left: auto;
}
