

.ZrD6lnLZGDL7 {
	border-radius: 8px;

	background: #f0f0f0;
}

._3R6U_jvBgk-f {
	margin-bottom: 20px;
	padding: 28px 40px;

	border-top-left-radius: 8px;
	border-top-right-radius: 8px;

	color: #ffffff;
	font-weight: 700;
	font-size: 24px;

	background: #5ba6ff;
}

._29Q8-Sbvezpq {
	margin: 20px 0;
	padding: 0 40px;
	display: flex;
	width: 100%;
	justify-content: flex-end;
}

div._2sk6vjbnEIzY {
	width: 760px;

	border-radius: 8px;

	background: #f0f0f0;
}
