

._2_EcfCWMSg5F {
	display: flex;
	flex: 1;
}

._1UaKRBqaIBfp,
._1BGolkEQhL_9 {
	flex: 1;
	justify-content: center;
	display: flex;
	align-items: center;

	border: 1px dashed var(--gray20);
	border-top: 0;
	border-bottom: 0;
	border-left: 0;
}

div.cdwk_7OnOrX_ {
	flex-basis: 660px;
}

._1BGolkEQhL_9 {
	border-right: 0;
}
