

._2k_o2Qk62ckj {
	position: relative;
}

._2S11scrHuahB {
	margin-bottom: 4px;

	color: var(--gray90);

	color: var(--input-number-color, var(--gray90));
}

.HT0eCkEyVcuC {
	position: absolute;
	top: -21px;
}

._2XPnYopZLPuE,
.xiA7fvgPn4C_ {
	width: 36px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	overflow: hidden;

	background: var(--gray10);

	background: var(--input-number-inc-dec-bg, var(--gray10));
	cursor: pointer;
}

._2XPnYopZLPuE {
	border-top-right-radius: 2px;

	transition: opacity 0.15s;
}

._2XPnYopZLPuE.sQRLCra5Thxk {
	opacity: 0.4;

	pointer-events: none;
}

._2XPnYopZLPuE svg {
	color: var(--gray90);
	color: var(--input-number-color, var(--gray90));

	transform: rotate(-90deg);
}

.xiA7fvgPn4C_ svg {
	color: var(--gray90);
	color: var(--input-number-color, var(--gray90));

	transform: rotate(90deg);
}

.xiA7fvgPn4C_ {
	border-bottom-right-radius: 2px;
}

p._2MoE6j9xhSBC {
	position: absolute;
	bottom: 0;
	left: -12px;

	width: 100%;

	white-space: nowrap;
	text-overflow: ellipsis;
	letter-spacing: normal;
	line-height: inherit;

	transform: translateY(140%);
	overflow: hidden;
}

._13XhMBYvlUQg {
	display: flex;
	align-items: center;
	height: 48px;
	padding-left: 12px;

	border-radius: 2px;

	background: var(--white);

	background: var(--input-number-bg, var(--white));
}

._13XhMBYvlUQg > div:first-child {
	flex-direction: initial;
}

.ynPd0-JtkPma {
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-row-gap: 2px;
	row-gap: 2px;
	height: 100%;

	visibility: hidden;
}

._13XhMBYvlUQg:hover .ynPd0-JtkPma {
	visibility: visible;
}

._13XhMBYvlUQg input[type='number'] {
	-moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
}

._13XhMBYvlUQg input[type='number']::-webkit-outer-spin-button,
._13XhMBYvlUQg input[type='number']::-webkit-inner-spin-button {
	margin: 0;

	-webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
}

._2OJon2aIZNt- {
	padding: 0 !important;
	margin-right: 12px !important;
}

label._12iTtjGz1QmR {
	width: 100%;

	color: var(--gray50);

	color: var(--input-number-label-color, var(--gray50));
	font-family: inherit;
	font-size: 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	transform: translate(0, 3px) scale(0.9);
}

label._12iTtjGz1QmR.plgrCFAm1cR7:not(._3I4Ua1a5dCqi) {
	color: var(--common-blue);
	color: var(--input-number-focused-color, var(--common-blue));
}

label.A1eKJ9_SLBu4 {
	transform: translate(-10px, -30px) scale(0.75);
}

div.sQRLCra5Thxk {
	border-bottom: 1px solid var(--gray10);
	border-bottom: 1px solid var(--input-number-inc-dec-bg, var(--gray10));

	font-size: inherit;
}

div._13AvBAU9-hhg.sQRLCra5Thxk:before,
div._2OJon2aIZNt-.sQRLCra5Thxk:before {
	background: var(--white);
	background: var(--input-number-bg, var(--white));
}

input._2OJon2aIZNt-.sQRLCra5Thxk {
	padding-left: 0;
}

div._2OJon2aIZNt-.sQRLCra5Thxk {
	padding-left: 0;

	color: var(--gray90);

	color: var(--input-number-color, var(--gray90));
}

._1nh0VUK53NGt._13AvBAU9-hhg:before {
	background: transparent;
}

._1nh0VUK53NGt input._2OJon2aIZNt-,
._1nh0VUK53NGt div._2OJon2aIZNt- {
	border-color: var(--base);
	border-color: var(--input-number-focused-color, var(--base));
}
