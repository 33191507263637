

._2XNAXuEZ-7zY {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 32px;
	margin-bottom: 20px;
}

._3HM1GUdeG4Fd {
	display: flex;
	align-items: center;
}
