._3KRzkekEaW5y {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr [content-end] 1fr [grid-end];
	grid-column-gap: 12px;
}

._3KRzkekEaW5y:not(:last-child) {
	margin-bottom: 20px;
}

div._3zf0fR-8o-5g {
	z-index: 1;

	grid-column-start: content-end;
	grid-row-start: 1;
	width: 50%;
}

._1Pjt06hHqUqT {
	z-index: 0;

	grid-column-start: content-end;
	grid-row-start: 1;
	align-items: center;
}

._3KRzkekEaW5y ._2bJ2cU0laWKb p {
	margin-left: 0;

	transform: translateY(120%);
}

@media (max-width: 1024px) {
	._3KRzkekEaW5y {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 16px 28px;
	}

	._3KRzkekEaW5y._15VXuDR8_cRY {
		grid-template-rows: 1fr 1fr 1fr [row-content-end] 1fr;
	}

	div._1Pjt06hHqUqT,
	div._3zf0fR-8o-5g {
		grid-column: 1;
		grid-row: row-content-end;
	}
}
