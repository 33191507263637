._3_HZGD-Y5Clm {
	height: 100%;

	background: var(--gray05);
}

._3FKxy2Pkaxg1 > svg {
	display: block;
	box-sizing: content-box;
	padding: 5px;

	border-radius: 50%;

	color: #ffffff;

	background: var(--error20);
}

._1YILygcdlyji {
	position: absolute;
	top: 28px;
	right: 28px;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;

	border-radius: 50%;

	color: var(--gray80);

	cursor: pointer;
	background: var(--gray05);
}

._1YILygcdlyji svg {
	transform: scale(0.6);
}

.z4y0kO_pp_z8 {
	top: 26px;
	right: calc(50vw - 550px);
}

._1Y_FTprhRIgl {
	position: relative;

	display: flex;
	padding: 40px 0 30px;

	font-weight: 600;
	font-size: 34px;

	background: #ffffff;
}

._34f2OO2rFEGj {
	max-width: 1100px;
	width: 1100px;
	margin: 0 auto;
	display: flex;
}

.nB5DLXkVsMxl {
	margin-right: 32px;

	color: var(--gray50);
}

.t5ARc_ss4Llr {
	max-width: 1100px;
	margin: 0 auto;
}

.qrXnrb1jb4L9 {
	font-size: inherit;
}

@media (max-width: 1024px) {
	.t5ARc_ss4Llr {
		width: 100%;
		padding: 20px;
		padding-top: 0;
	}

	._1Y_FTprhRIgl {
		padding: 20px;

		font-size: 21px;

		background: none;
	}

	.nB5DLXkVsMxl {
		margin-bottom: 24px;
	}

	._34f2OO2rFEGj {
		flex-direction: column;
	}
}
