._39YteDljynLy {
	position: absolute;
	right: 0;
	bottom: 43px;
	left: 76px;

	height: 100%;
}

._2-VgXT4qH-E0 {
	white-space: nowrap;
}

@media (max-width: 1024px) {
	._39YteDljynLy {
		bottom: 2px;
		left: 0;
	}
}

@media screen and (max-width: 600px) {
	._39YteDljynLy {
		bottom: 0;
	}
}

._208mYe_D3taQ {
	position: relative;

	height: 100%;
}

._3tttaUPdYJ8U {
	position: absolute;
	bottom: 39%;

	width: 100%;
	height: 1px;

	border-top: 1px dashed #cdcdcd;
}

._1TsdWEAHoPHD {
	position: absolute;
	top: -9px;
	right: 100%;

	padding: 0 8px;

	color: var(--light-gray);
	font-size: 12px;
}

._3tttaUPdYJ8U ._2-VgXT4qH-E0 {
	color: var(--gray70);
	font-weight: 600;
	font-size: 14px;
}

._2jYlozH5XO2Q ._2-VgXT4qH-E0 {
	padding: 2px 8px;

	border-radius: 10px;

	color: var(--success-20);
}

.Cw_ln0PnXpvO ._2-VgXT4qH-E0 {
	padding: 2px 8px;

	border-radius: 10px;
}

._2jYlozH5XO2Q ._1TsdWEAHoPHD,
.Cw_ln0PnXpvO ._1TsdWEAHoPHD {
	padding: 0;
}

@media (max-width: 1024px) {
	._2GS0lkCWzuMH {
		display: none;
	}

	.Cw_ln0PnXpvO ._2-VgXT4qH-E0,
	._2jYlozH5XO2Q ._2-VgXT4qH-E0 {
		border-radius: 0;

		background: none;
	}

	.Cw_ln0PnXpvO ._2-VgXT4qH-E0 {
		color: #9a9a9a;
	}

	._2jYlozH5XO2Q ._2-VgXT4qH-E0 {
		color: var(--pricegraph-min-price);
	}
}

._1MYyUa_ho3Fx {
	position: absolute;
	bottom: 1px;

	width: 100%;
	height: 1px;

	border-top: 1px solid #cdcdcd;
}

._1GB_EypsrGFf {
	bottom: 0;
}

._1GB_EypsrGFf,
._2c8BZ7_Xm2To,
.IliGqDuSIlpL {
	position: absolute;
}
