

._3p0aPZs0zy_v {
	width: 340px;

	border-radius: 8px;

	background: linear-gradient(345.9deg, #f7f7f7 -2.27%, #ffffff 79.43%);
	box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.FXf84h_3DP-H {
	display: flex;
	padding: 22px 14px 0;
}

.l3Z67QRaeayc {
	margin-left: 12px;
}

._1ORqlNaDessh {
	display: flex;
	align-items: center;
	height: 20px;
	margin: 0;

	color: #9a9a9a;
	font-size: 14px;
	font-weight: 500;
}

._1994K2SmsUlw {
	display: flex;
	align-items: center;
	height: 25px;
	margin: 0;

	line-height: normal;
	font-size: 15px;
	color: var(--dark-gray);
	text-transform: uppercase;
}

._3NdwJwu9i_2C {
	display: flex;
	padding: 38px 14px 16px;
}

.PZaxRe7kTPY5 {
	display: flex;
	flex-direction: column;
}

.PZaxRe7kTPY5:first-child {
	margin-right: 25px;
}

._1EWG0cNMlzzF {
	display: flex;
	align-items: center;
	height: 20px;
	margin: 0;

	font-size: 13px;
	color: #9a9a9a;
}

._1ZKvz9aR_olI {
	display: flex;
	align-items: center;
	height: 20px;
	margin: 0;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 15px;
}

._2Sq26h3uqECG {
	padding: 10px 10px 10px 20px;

	display: flex;
	flex-direction: row-reverse;

	border-top: 1px dashed #d9d9d9;
}

._2Sq26h3uqECG p {
	display: flex;
	align-items: center;
	margin: 0;
	height: 20px;

	font-weight: 500;
	color: var(--common-blue);

	cursor: pointer;
}

._2Sq26h3uqECG > p > span {
	margin-right: 6px;

	color: #9a9a9a;
}

._2Sq26h3uqECG ._3-wNBMD9Szgm {
	min-width: 35px;

	font-size: 13px;
	color: var(--cabinet-action);
	text-transform: none;

	font-family: inherit;
}

._2Sq26h3uqECG ._3-wNBMD9Szgm ._3-eaoR5tHcd_ {
	font-family: inherit;
}

._3-wNBMD9Szgm:first-child {
	color: #9a9a9a;
}

._1nU891BZbtbU {
	background: var(--loyalty-card-background);
}

._1nU891BZbtbU .FXf84h_3DP-H ._1ORqlNaDessh,
._1nU891BZbtbU .FXf84h_3DP-H ._1994K2SmsUlw {
	color: #ffffff;
}

._1nU891BZbtbU ._3NdwJwu9i_2C .PZaxRe7kTPY5 ._1EWG0cNMlzzF,
._1nU891BZbtbU ._3NdwJwu9i_2C .PZaxRe7kTPY5 ._1ZKvz9aR_olI {
	color: #ffffff;
}

._1nU891BZbtbU ._2Sq26h3uqECG p button {
	color: #ffffff;
}

._8cv3CMt4XBj- ._2Sq26h3uqECG > p > button > span > span > svg > path {
	fill: var(--cabinet-action);
}

._3p0aPZs0zy_v._1nU891BZbtbU > ._2Sq26h3uqECG > p > button > span > span > svg > path {
	fill: #ffffff;
}

._3p0aPZs0zy_v._8cv3CMt4XBj- {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

._8cv3CMt4XBj- ._2Sq26h3uqECG > p > button > span > span {
	display: flex;
	align-items: center;
	margin-left: 6px;
	margin-right: 0;
}

._8cv3CMt4XBj- ._2Sq26h3uqECG > p > button {
	color: var(--cabinet-action);
}

._8cv3CMt4XBj-._1nU891BZbtbU ._2Sq26h3uqECG > p > button > span {
	color: #ffffff;
}

._1nU891BZbtbU ._2Sq26h3uqECG > p > button {
	color: #ffffff;
}

._1nU891BZbtbU ._2Sq26h3uqECG > p > button:first-child {
	color: rgba(255, 255, 255, 0.5);
}

._8cv3CMt4XBj- ._3NdwJwu9i_2C .PZaxRe7kTPY5 ._1ZKvz9aR_olI {
	color: #d9d9d9;
}

._8cv3CMt4XBj-._1nU891BZbtbU ._3NdwJwu9i_2C .PZaxRe7kTPY5 ._1ZKvz9aR_olI {
	color: rgba(255, 255, 255, 0.5);
}

._8cv3CMt4XBj- ._2Sq26h3uqECG {
	padding: 10px 14px;
}

div._3h0FITFpg_S_ {
	padding: 0;
}

._3h0FITFpg_S_:first-child {
	padding: 0 !important;
}
