

.LhNZGb_JflL4 {
	margin: 0 6px;

	font-size: 19px;
	font-weight: 700;
	line-height: 19px;
	color: var(--text-pale);
}

._2kNnekQ-zpBm {
	color: var(--base);
}
