

._1NuoieZKXDy8 {
	display: flex;
	align-items: center;
}

._1NuoieZKXDy8 svg {
	display: block;
}

._3uCtmubdQP6F a {
	color: inherit;
}

._3uCtmubdQP6F + ._3uCtmubdQP6F {
	margin-left: 13px;
}
