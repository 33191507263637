

._3hcdi1YZPAFw {
	width: 100%;
}

label._35iJbXXuPTx- {
	color: var(--success-icon);
}

._3hcdi1YZPAFw label._2OBwf17bA1Hx {
	color: var(--error-text);
}

div._2pe_zj2e6zlI {
	border: 1px solid var(--gray20);
}

div._2pe_zj2e6zlI > div {
	border-bottom: none;
}

div._2pe_zj2e6zlI:before {
	background: var(--white);
}

input._2pe_zj2e6zlI._3NNxMu6gOpJS,
div._2pe_zj2e6zlI._3NNxMu6gOpJS {
	padding-left: 10px;

	color: var(--gray50);
}

input._2pe_zj2e6zlI._3NNxMu6gOpJS:before,
div._2pe_zj2e6zlI._3NNxMu6gOpJS:before {
	background: var(--gray05);
}

div._2pe_zj2e6zlI._1r7cwrUjNwoA {
	border: 1px solid var(--success-icon);
}

div._2pe_zj2e6zlI._1r7cwrUjNwoA:before {
	background: var(--success-bg);
}

div._2pe_zj2e6zlI._3w3CYdRcovHj {
	border: 1px solid var(--warning-stroke-hover);
}

div._2pe_zj2e6zlI._3w3CYdRcovHj:before {
	background: var(--warning-bg);
}

div._3wW64h2LCnev:before {
	background: var(--warning-bg);
}

div._3wW64h2LCnev {
	border: 1px solid var(--error);
}
