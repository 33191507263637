

._27_nSZq6KM4m {
	display: flex;
	align-items: center;
	width: 100%;
}

button._2hoU0JQKZR5f {
	border: 1px solid var(--brand-1-3);

	color: var(--text-link);

	background: var(--white);
}

button._2hoU0JQKZR5f:hover {
	border-color: var(--brand-1-4);

	background: var(--brand-1-4);
}

button.JesnMXzk2InD {
	margin-left: 12px;

	border-radius: 4px;

	color: var(--white);
	font-weight: 700;
	font-size: 14px;

	background: var(--brand-3-0);
}

button.JesnMXzk2InD:hover {
	color: var(--white);

	background: var(--brand-3-1);
}

input._2BHfxvj4ZPrP,
div.jXPrinHTUic3:before {
	height: 39px;
}

.f-Dw7UcACS_w {
	flex: 1;
}

.f-Dw7UcACS_w p._2PHeOJDKWCIu {
	color: var(--text-light);

	transform: translate(6px, 42px);
}

.f-Dw7UcACS_w p._2PHeOJDKWCIu._3YDRxePJHxfe {
	color: var(--error-icon);
}
