._1b9uoxzmsZl7 {
	display: grid;
	grid-template-columns: 1fr max-content;
	align-items: center;
}

._2b2qnZH2XGxW {
	display: flex;
	align-items: center;

	min-width: 0;
}

.spcHSk95q_VD {
	margin-right: 8px;
}

._3yIAmeRtBdyV {
	max-width: calc(100% - 32px);

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.mm2mt7HlgwQF {
	color: var(--base);
	font-size: 15px;

	cursor: pointer;
}

@media (max-width: 768px) {
	._1b9uoxzmsZl7.MPow33rUTSsA {
		font-weight: 500;
	}
}
