.MFuZTsoljgvS {
	padding: 20px;

	background: #f9f9f9;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.MFuZTsoljgvS:first-of-type {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.MFuZTsoljgvS:last-of-type {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

._36q9NIETsVGg {
	display: flex;
	margin: 10px 0;
	align-items: center;

	color: var(--cabinet-action);
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;

	cursor: pointer;
}

.-wjqXKErPq_i {
	display: flex;
	align-items: center;
	margin-right: 20px;
}

.d079Pl5w0m3Z {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	padding: 10px 0;
}

._31Y-yb1na6Ab {
	display: flex;
	align-items: center;
	width: 100%;
}

div._26049KmFdwgs {
	margin-right: 25px;

	transform: translateY(-10px);
}

.kc57rmrSdHW3 {
	display: flex;
	margin-right: 20px;
}

._31Y-yb1na6Ab ._1vP9eayxUGaO {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 3px;
	margin-right: 0;
	min-width: 105px;

	border-radius: 20px;
}

@media screen and (max-width: 600px) {
	.MFuZTsoljgvS {
		position: relative;

		background-color: #ffffff;
	}

	.MFuZTsoljgvS:first-of-type {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	.MFuZTsoljgvS:last-of-type {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	._36q9NIETsVGg {
		margin: 0;

		font-size: 16px;
	}

	.-wjqXKErPq_i {
		margin-right: 12px;
	}

	.d079Pl5w0m3Z {
		align-items: flex-start;
		flex-direction: column;
	}

	._31Y-yb1na6Ab {
		flex-direction: column;
		align-items: flex-start;
	}

	._31Y-yb1na6Ab > div {
		margin-right: 0;
		margin-top: 20px;
	}

	._31Y-yb1na6Ab ._1vP9eayxUGaO {
		align-self: flex-end;
		margin-top: 28px;
		margin-left: 0;
	}

	._31Y-yb1na6Ab._2t1sYmuRgZWz {
		margin-bottom: 0;
	}

	._37pQiymdheVt {
		font-size: 22px;
		font-weight: 500;
		line-height: 24px;
	}
}
