

.ZJAaLfGg_6Ef {
	display: flex;
	align-items: center;

	color: var(--gray50);
	font-size: 14px;
	line-height: 14px;
}

._3TVRJclF3IUr {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;
	margin: 0 8px;

	border-radius: 50%;

	cursor: pointer;
	background: #ffffff;
}

._3TVRJclF3IUr._5LIZ07W6x0gu {
	color: #ffffff;

	background: var(--base);
}

._3TVRJclF3IUr._5LIZ07W6x0gu svg {
	border-radius: 50%;
}
