.QiwOvjJFqMSC {
	width: 100%;
	margin-top: 12px;
	padding: 20px;

	border-radius: 4px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

._2rTrAuj1DCYB {
	margin-top: 12px;
}

._15V7ZRhSuIin {
	margin-top: 4px;
}

@media screen and (max-width: 600px) {
	._2rTrAuj1DCYB {
		margin-top: 0;
	}
}
