

._2UnSBKb48yCQ {
	border-radius: 4px;
}

div._34AsAdpSilkt {
	padding: 6px 5px 6px 12px;
	box-sizing: border-box;

	color: inherit;

	background: transparent;

	background: var(--services-cards-select-bg, transparent);
}

._1M2MMl6brcj- {
	padding: 0;
}

div._2r55oU_f7sBf._2r55oU_f7sBf {
	padding-right: 22px;
}

svg.ZQ-4meX1xzbD {
	right: 2px;
}

li._3ph-_pCc-CNV {
	justify-content: center;

	border-top: 1px solid var(--gray10);

	border-top: var(--services-cards-item-border, 1px solid var(--gray10));
	border-bottom: 1px solid var(--gray10);
	border-bottom: var(--services-cards-item-border, 1px solid var(--gray10));
}

li._3ph-_pCc-CNV._1Xcn3AnhdNJz,
li._3ph-_pCc-CNV._1Xcn3AnhdNJz:hover {
	background: transparent;
	background: var(--services-cards-item-selected-hover-bg, transparent);
}

li._3ph-_pCc-CNV:not(:last-of-type) {
	border-bottom: 0;
}
