

._2p3jqdtxUZHv {
	display: flex;
	width: 100%;
	height: 100%;
	margin: 84px auto;
	max-width: 1100px;

	color: inherit;
}

._2jc5Dk35-XSA {
	margin-left: 40px;
	flex: 1;
}
