

._2-fWraK8xqpK {
	display: flex;
	flex-direction: column;
	padding: 12px;

	border-radius: 8px;

	background: #ededed;
}

.PWgQ22xQjNsI {
	background: #ffe601;
}

.qVnpmQwWkNUA {
	padding-bottom: 10px;

	border-bottom: 1px dashed #bababa;
}

._3btbk9dt8u14 {
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 14px;
	line-height: 150%;
	font-weight: 400;
}

._2Mc-XXCalc5s {
	display: flex;
	justify-content: space-between;
	margin-top: 12px;

	font-size: 14px;
	font-weight: 500;
	line-height: 150%;
}

.-dKSS1TEIAd- {
	display: flex;
	justify-content: space-between;
	max-width: 205px;
	width: 100%;
}

._3uSEoVzQxeJk {
	max-width: 185px;
}
