._2Fkg0eU0nMhr {
	position: relative;

	width: 100%;

	background-color: var(--stepbar-background-color);
}

._3pvcGIdeDrlA {
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
}

@media screen and (max-width: 600px) {
	._3pvcGIdeDrlA {
		display: none;
	}
}

@media (max-width: 1024px) {
	._3pvcGIdeDrlA {
		padding: 0 20px;
	}
}
