._2whfmbVIPfUH:before {
	opacity: 1;
}

._2obBl47yo3MX {
	opacity: 1;
}

._3MMgCfteNCqi._2whfmbVIPfUH:after {
	background: var(--gray20);
}

._3MMgCfteNCqi._2YvyXpGExZ3U:after {
	background: var(--base);
}

._6aWAr1KcV6ky._2whfmbVIPfUH:after {
	background: var(--gray90);
	opacity: 0.2;
}

._6aWAr1KcV6ky._2YvyXpGExZ3U:after {
	background: var(--white);
	opacity: 1;
}

._6aWAr1KcV6ky._2YvyXpGExZ3U:before {
	background: var(--base);
}

@media (max-width: 1024px) {
	._2whfmbVIPfUH {
		padding: 0;
	}
}
