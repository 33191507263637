

.hl8oNWvVB-Mv {
	display: flex;
	align-items: center;
	height: 38px;
	padding: 0 16px;

	border-radius: 19px;

	color: var(--white);

	font-weight: 500;
	font-size: 14px;
	line-height: 1.2;

	cursor: pointer;

	background: var(--base-25);
}

.Jy-be8d_EkFn {
	position: relative;
}

.Jy-be8d_EkFn + .Jy-be8d_EkFn {
	margin-left: 20px;
}

._2nDnpX4KtFQi {
	margin-left: 8px;
}

._2nDnpX4KtFQi svg {
	display: block;
}

.dD9RFfANmtZn svg {
	color: var(--white);

	transform: scale(0.75);
	opacity: 0.5;
	background: var(--base-25);
}

.dD9RFfANmtZn {
	display: flex;

	line-height: 38px;
}

.dD9RFfANmtZn:nth-child(3),
.dD9RFfANmtZn:nth-child(5) {
	margin-left: 10px;
}
