.Hn2LiO3hIW3v {
	position: relative;

	display: flex;
	flex-direction: column;

	padding: 40px 48px 32px 48px;
}

[role='document'] > div {
	overflow-y: visible;
}

._1ACAd5YSfltL {
	height: 40px;

	margin: 0 0 18px;

	font-size: 28px;
	font-weight: 500;
	color: var(--dark-gray);
}

._2LFq20IlYd39 {
	margin-bottom: 40px;
}

._1edziysD8K6b {
	display: flex;
	flex-direction: row-reverse;
}

.Hn2LiO3hIW3v ._1edziysD8K6b button {
	background-color: var(--dark-red);
}

.Hn2LiO3hIW3v ._1edziysD8K6b button:hover {
	background-color: var(--dark-red);
}

._1JyNs3qMi8YB {
	position: absolute;
	top: -32px;
	right: -32px;

	color: #ffffff;

	cursor: pointer;
}

._1RDmi3jol8-C {
	opacity: 0.5;
}

.Hn2LiO3hIW3v ._1edziysD8K6b button._qfYpUxigpbm {
	margin-right: 14px;

	color: var(--common-gray);

	background-color: #ededed;
}

@media screen and (max-width: 600px) {
	.Hn2LiO3hIW3v {
		padding: 0;
	}

	._1GibZkWb2BO8 {
		display: none;
	}
}
