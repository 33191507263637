

._2bXJ2QI80klM {
	position: relative;

	display: inline-flex;
	align-items: center;
	justify-content: center;
}

._1kBj-kn2DF8r {
	display: inline-flex;
}

._2YZOfqtdiEWP {
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--baggage-bag-weight-color);
}

._1HlgRlxF6Asc ._2YZOfqtdiEWP {
	color: var(--baggage-bag-weight-color-included);
}

._3Iy7Gd59VHS0 {
	position: absolute;

	margin-left: -1px;

	text-align: center;
	color: var(--baggage-bag-weight-color);

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

._1HlgRlxF6Asc ._3Iy7Gd59VHS0 {
	color: var(--baggage-bag-weight-color-included);
}

._1kBj-kn2DF8r ._3Iy7Gd59VHS0 {
	display: flex;
}

/* Weight block position, depending on bag size and type. */
._1kBj-kn2DF8r.I5-hwcT9Zao5 ._3Iy7Gd59VHS0 {
	bottom: 11px;

	line-height: 22px;
}

._1kBj-kn2DF8r._2o0FPjq-X00j ._3Iy7Gd59VHS0,
._1kBj-kn2DF8r._2ZWes_S3z7ES ._3Iy7Gd59VHS0 {
	bottom: 18px;

	line-height: 24px;
}

.nasK8hZhmYSx.I5-hwcT9Zao5 ._3Iy7Gd59VHS0 {
	bottom: 16px;
}

.nasK8hZhmYSx._2o0FPjq-X00j ._3Iy7Gd59VHS0 {
	bottom: 19px;
}

.nasK8hZhmYSx._2ZWes_S3z7ES ._3Iy7Gd59VHS0 {
	bottom: 24px;
}

/* Weight amount */
._2p65UXFB0oZ7 {
	margin: 0;

	font-size: 20px;
	font-weight: 600;
	line-height: 90%;
}

._1kBj-kn2DF8r.I5-hwcT9Zao5 ._2p65UXFB0oZ7 {
	line-height: 22px;
}

._1kBj-kn2DF8r._2o0FPjq-X00j ._2p65UXFB0oZ7,
._1kBj-kn2DF8r._2ZWes_S3z7ES ._2p65UXFB0oZ7 {
	line-height: 24px;
}

/* Weight amount measurement */
._1kqbYCXWotUS {
	margin: 0;

	font-size: 13px;
}

._1kBj-kn2DF8r ._1kqbYCXWotUS {
	position: relative;
	bottom: -2px;

	margin-left: 1px;

	font-size: 16px;
}
