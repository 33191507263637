._35KarnySxM3J {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	height: 36px;

	border-radius: 4px;

	background: #ffffff;
}

._35KarnySxM3J svg {
	width: 100px;
	height: 20px;
}

button._1HxVRRRJmMzm {
	min-width: 120px;
	height: 44px;

	border-radius: 24px;

	line-height: 44px;
}

div._2oauv6ftNr74 {
	padding: 40px 28px 28px;
	overflow: visible;

	border-radius: 8px;
}

div._2mEmPOS-nAlK {
	padding: 20px;

	border-radius: 16px;
}

.vZqv1fLop-37 {
	display: flex;
	align-items: center;
}

._21qxiOeUAhs8 {
	display: inline-block;

	width: 24px;
	height: 24px;

	margin-left: 6px;

	cursor: pointer;
}

._1zXh9jmZ9RdP {

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEwQzAgNC40NyA0LjQ3IDAgMTAgMEMxNS41MyAwIDIwIDQuNDcgMjAgMTBDMjAgMTUuNTMgMTUuNTMgMjAgMTAgMjBDNC40NyAyMCAwIDE1LjUzIDAgMTBaTTEzLjU5IDE1TDE1IDEzLjU5TDExLjQxIDEwTDE1IDYuNDFMMTMuNTkgNUwxMCA4LjU5TDYuNDEgNUw1IDYuNDFMOC41OSAxMEw1IDEzLjU5TDYuNDEgMTVMMTAgMTEuNDFMMTMuNTkgMTVaIiBmaWxsPSIjOUE5QTlBIi8+Cjwvc3ZnPgo=) center no-repeat;
}

._1H3SYfO-WgDs {
	position: relative;
	top: 1px;

	color: #9a9a9a;

	transform: rotate(-90deg) scale(0.85);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIGZpbGw9IiM5YTlhOWEiLz4KPHBhdGggZD0iTTE2LjU5IDguNTkwMDlMMTIgMTMuMTcwMUw3LjQxIDguNTkwMDlMNiAxMC4wMDAxTDEyIDE2LjAwMDFMMTggMTAuMDAwMUwxNi41OSA4LjU5MDA5WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
}

._2oauv6ftNr74 ._3ToCvn2GPJXD {
	position: absolute;
	top: -26px;
	right: -26px;

	width: 24px;
	height: 24px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1LjMzMzIgOC41NDY2M0wyMy40NTMyIDYuNjY2NjNMMTUuOTk5OCAxNC4xMkw4LjU0NjUgNi42NjY2M0w2LjY2NjUgOC41NDY2M0wxNC4xMTk4IDE2TDYuNjY2NSAyMy40NTMzTDguNTQ2NSAyNS4zMzMzTDE1Ljk5OTggMTcuODhMMjMuNDUzMiAyNS4zMzMzTDI1LjMzMzIgMjMuNDUzM0wxNy44Nzk4IDE2TDI1LjMzMzIgOC41NDY2M1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

._3GLLxUzd01ph {
	margin: 0;

	font-weight: 500;
	font-size: 22px;
	line-height: 32px;
}

._3xsUjoHH7NOC {
	margin: 10px 0 0 0;

	font-size: 15px;
	line-height: 20px;
	color: var(--common-gray);
}

.FHqek3AdnM1B {
	margin-top: 28px;

	display: flex;
	justify-content: flex-end;
}

.FHqek3AdnM1B button:first-of-type {
	margin-right: 12px;

	color: var(--common-gray);
	font-weight: 400;

	background-color: #ededed;
}

._1U1KIGnskCyT {
	padding: 16px 20px 12px;
}

div._3FKYp1eWDBNj {
	max-width: 900px;
}

@media screen and (max-width: 600px) {
	._2oauv6ftNr74 ._3ToCvn2GPJXD {
		top: -38px;
		right: 8px;
	}

	._3GLLxUzd01ph,
	._3xsUjoHH7NOC {
		text-align: center;
	}

	._3GLLxUzd01ph {
		font-size: 18px;
		line-height: 27px;
	}

	._3xsUjoHH7NOC {
		line-height: 22px;
	}

	.FHqek3AdnM1B {
		flex-direction: column-reverse;
		align-items: center;
	}

	.FHqek3AdnM1B button {
		width: 100%;
	}

	.FHqek3AdnM1B button:first-of-type {
		margin-right: 0;
		margin-top: 12px;
	}
}
