

div._3ywxSilZWJ1C {
	background: var(--gray05);
	overflow: auto;
}

div.CT8Nl2Wqb1-I {
	position: fixed;
	z-index: 10;
	top: 26px;
}
