

._2UoJ-5xSJZVc {
	font-size: 16px;
	text-transform: none;
	line-height: 1.2;

	background: var(--success);
}

._2UoJ-5xSJZVc.I9MfXD2OsFOL {
	color: var(--white);

	opacity: 1;
	background: var(--success);
}

.JqiaSWOQ4WbF {
	margin-left: 0;
}

._3R68-yyrzJcs {
	margin-left: 6px;
}

._3R68-yyrzJcs svg {
	display: block;
}
