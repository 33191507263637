

.bKkNG4MbHD_x {
	max-width: 1100px;
	margin: 28px auto 0;
}

div._2OYpJtmwq4kJ:not(:last-child),
div._1vUlpBm-jOZV {
	margin-bottom: 8px;
}

div._2OYpJtmwq4kJ:only-child:not(._1vUlpBm-jOZV),
div._2OYpJtmwq4kJ:last-child:not(._1vUlpBm-jOZV) {
	margin-bottom: 28px;
}

._2B4Y6BP-4g25 {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;

	max-width: 1100px;
	width: 100%;
	height: 60px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.PFtdR2-xDkeD {
	height: 100%;

	border-radius: 0;

	font-weight: var(--toggleableFlights-button-fontWeight);
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;

	background: var(--toggleableFlights-button-background);
}

button.PFtdR2-xDkeD:hover {
	background: var(--toggleableFlights-button-background-hover);
}

.UthSUrSVRF0R {
	display: flex;
	align-items: center;
	margin-left: 4px;

	transition: transform 0.15s;
}

._3_jn1KLphz3Q {
	transform: rotate(-180deg);
}

._3OfA8H2LMIJ7 {
	background: #ededed;
}

._3qUQNaj-UaHo {
	display: var(--toggleableFlights-button-plane-display);
	align-items: center;
	margin-right: 10px;

	transform: rotate(180deg);
}

._3qUQNaj-UaHo svg {
	height: 20px;
	width: 20px;
}
