

._2qI30XelivjD {
	position: relative;

	padding: 23px 23px 35px;
}

._8RwcceaNFVm {
	border-top: 1px dashed var(--gray20);
}

._1Mmc54_53i20 {
	position: relative;

	display: flex;
	flex: 1;
	min-width: 65%;
}

._23AcN2zqkE-l {
	display: flex;
	align-items: flex-end;
}

._9rR3q-10Oqph {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

._9rR3q-10Oqph img {
	height: 92px;
	margin-bottom: 18px;
}

._1eUq86kDdXRj {
	font-size: 20px;
	font-weight: 600;
	color: var(--gray90);
}

._2x51E2saJwB_ {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 10px;
}

._2bzDS1ueMREY {
	margin-right: 20px;

	color: var(--base-15);
	font-size: 16px;
	white-space: nowrap;
}

._2jxSSKaZ3Ah6 {
	margin-top: 6px;
}

div._2ziyemBYo9I6 {
	bottom: -16px;
}

.mDaQX-2Sys3- {
	position: absolute;
	bottom: -16px;
	left: 0;

	width: 100%;
	height: 31px;
	padding: 0 30px;

	color: #1a1a1a;
	font-weight: 600;
	font-size: 14px;
	text-align: center;

	transform: translateY(150%);
}
