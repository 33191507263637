

._3jOW9NDvhohu {
	width: 100%;
	padding-bottom: 5px;

	background-color: var(--mobileStepbar-background);
}

._19yad_6nBUXc {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 56px;
	padding: 0 16px 0 20px;

	color: #ffffff;
	font-weight: 600;
	font-size: 16px;

	cursor: pointer;
}

._1eaTYnHqv5gI {
	display: flex;
	align-items: center;
}

.XYA_HyCF5GPz {
	width: 24px;
	height: 24px;
	margin-left: 8px;

	border-radius: 50%;

	background: rgba(255, 255, 255, 0.3);

	transition: transform 0.3s;
}

._3Ejkgys1OhZC {
	transform: rotate(-180deg);
}
