

button._2YgdQwT62G0E {
	margin-top: 22px;
	width: 174px;
	align-self: flex-end;
}

button._2YgdQwT62G0E,
button._2YgdQwT62G0E:hover {
	background-color: var(--active);
}

div._3MU4k1-_p-23 {
	padding: 40px 40px;
	display: flex;
	flex-direction: column;
}

._1RPTNcEe7GDP {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

._1RPTNcEe7GDP div {
	margin-right: 10px;
}
