._2F2eVrS3bkgo {
	display: inline-block;
	height: 32px;
	min-width: 32px;
	padding: 8px 0;

	border-radius: 50%;

	font-weight: 700;
	font-size: 16px;
	color: var(--white);
	text-align: center;

	background: var(--success);
}
