.pJbfqcx2tFJt {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	border-radius: 4px;
}

.pJbfqcx2tFJt:first-child {
	margin-top: 20px;
}

.d1OfYDenCMbx {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 64px;
	padding: 0 calc((100% - 1100px) / 2);

	color: var(--gray80);
}

._3pUFQ7pswyuU {
	display: flex;
	align-items: center;
}

._184XYoZQ1hWt {
	display: flex;
	align-items: center;

	font-weight: 600;
	font-size: 24px;
}

._184XYoZQ1hWt svg {
	flex-shrink: 0;

	margin: 0 8px;

	color: var(--base);
}

._3qy9hDVqaXwx {
	display: flex;
	align-items: center;

	transform: rotate(180deg);
}

.vzYn1BWsbPcT {
	padding: 54px 40px;
}

div._2PbWBjaMqFHe {
	border-radius: 16px;
}

._38eQ2bgpm-W1 {
	top: 15px;
	right: 10px;
	z-index: 3;

	display: flex;
	align-items: center;
	width: 28px;
	height: 28px;

	border-radius: 50%;

	color: #333333;

	background: var(--gray05);
}

._38eQ2bgpm-W1 svg {
	position: relative;

	width: 28px;
	height: 28px;
}

._38eQ2bgpm-W1 > svg {
	height: 20px;
}

._1a3qSrKqFrsD {
	transform: rotate(0deg);
}

._2T350VYZ8cjE {
	width: 100%;
	max-width: 1100px;
}

._3uBrc4l8Q5t3 {
	padding: 16px 0;
	max-width: 1100px;
	width: 100%;
}

.Ut3k_CbBkcJN {
	display: flex;
	align-items: center;

	white-space: nowrap;
}

._1dJ4VmFb7CPP {
	height: 100%;
}

._5603JyX7Q452 {
	flex-shrink: 0;

	width: 32px;
	height: 32px;
	margin-right: 15px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 600;
	font-size: 17px;
	line-height: 32px;
	text-align: center;

	background: var(--base15);
}

button._34wrY60sF92Z {
	display: inline-flex;
	height: 37px;
	padding: 0 16px;

	border-radius: 100px;

	color: var(--common-blue);
	font-weight: 500;
	font-size: 15px;
	line-height: 37px;
}

button._34wrY60sF92Z svg {
	margin-left: 6px;
}

button.O1TJCs4ewZ_H {
	min-width: 0;
	padding: 0 12px;

	border-bottom: 3px solid transparent;

	color: #9a9a9a;
	font-family: inherit;
	font-weight: 500;
	font-size: 13px;
	text-transform: none;

	opacity: 1;

	transition: background-color 0.15s, border-bottom-color 0.15s;
}

button._6q-ZKLxAvy8Q {
	color: var(--tab-color-active);
}

span.QT0__qb61_L9 {
	height: 3px;

	background-color: var(--tab-color-active);
}

._2yemNqfbyySk {
	padding: 0;
}

@media screen and (max-width: 600px) {
	._2T350VYZ8cjE {
		margin-bottom: 20px;
	}
}
