._2vHf0N2a5l2O {
	display: inline-block;
}

._2E4pJ1zyElk_ {
	position: relative;

	display: inline-block;
	padding-right: 15px;

	color: var(--dark-gray);

	font-weight: 600;

	font-size: 14px;

	cursor: pointer;
}

._2jkmNV7l9M7x {
	text-transform: capitalize;
}

._2cLqodIyrcpG {
	padding-left: 5px;

	color: var(--common-gray);
}

._2E4pJ1zyElk_:after {
	position: absolute;
	top: 2px;
	right: 0;

	width: 12px;
	height: 12px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgNEw2IDlMMTEgNEwxIDRaIiBmaWxsPSIjQTVBNUE1Ii8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

.KwDtd4Suzcr7 {
	position: absolute;

	top: 100%;
	z-index: 3;

	width: 320px;
	padding-top: 8px;
	padding-bottom: 8px;

	box-shadow: 2px 1px 9px rgba(0, 0, 0, 0.15);
	transform: translate(90%, 0);
	background: #ffffff;
}

@media (max-width: 1024px) {
	.KwDtd4Suzcr7 {
		position: relative;
		top: 0;

		width: 100%;
		padding: 10px 0;

		box-shadow: none;
		transform: none;
	}

	._2E4pJ1zyElk_ {
		margin-top: 20px;
		margin-bottom: 16px;

		font-size: 16px;
	}
}

._1kmfyHyDGIdw {
	display: flex;
	align-items: center;
	height: 32px;
	padding-right: 16px;
	padding-left: 16px;

	text-transform: capitalize;

	cursor: pointer;
}

._1kmfyHyDGIdw:hover {
	background: #3290ff1a;
}

@media (max-width: 1024px) {
	._1kmfyHyDGIdw {
		padding: 0;
	}

	._1kmfyHyDGIdw:hover {
		background: none;
	}
}

._3AlX-htlPILS,
._1Sd2oUzdVIVj {
	width: 107px;

	color: ;
	font-weight: 500;
	font-size: 12px;
}

._3AlX-htlPILS {
	min-width: 95px;
}

._1Sd2oUzdVIVj {
	width: 70px;

	text-align: right;
	white-space: nowrap;
}

._3wJY8Sov1rd1 {
	position: relative;

	flex-basis: 100%;
	width: 130px;
	height: 3px;
}

._1_LXyCboCAgV {
	position: absolute;
	top: -2px;
	left: 0;

	height: 6px;

	background: var(--pricegraph-dropdown-default-progress);
}

._4PCQWL7Lo7co ._1_LXyCboCAgV {
	background: var(--pricegraph-min-price);
	opacity: 1;
}

._oZAyQ1eRAKH ._1_LXyCboCAgV {
	background: var(--pricegraph-dropdown-progress);
	opacity: 1;
}

.NFJncyDeMX_W {
	font-size: 20px;
	font-weight: 500;
	color: #9a9a9a;
}
