._1WkM76gGLhq6 {
	border-radius: 8px;
	overflow: hidden;

	background-color: #ffffff;
}

.RCzkgSzZ0I4S {
	border-radius: inherit;
}

@media screen and (max-width: 600px) {
	._1WkM76gGLhq6 {
		border-radius: 8px 8px 0 0;
		overflow: hidden;
	}

	.RCzkgSzZ0I4S {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		border-radius: 0;
	}
}

.J1vNfOir1pYS {
	display: flex;
	justify-content: flex-end;
	padding: 30px 40px 40px;
}

@media screen and (max-width: 600px) {
	.J1vNfOir1pYS {
		padding: 30px 20px 12px;
	}
}

.U8wmwrUHYsML {
	width: 163px;
}

.U8wmwrUHYsML:not(:last-of-type) {
	margin-right: 15px;
}

@media screen and (max-width: 600px) {
	.U8wmwrUHYsML {
		flex-grow: 1;
	}

	.U8wmwrUHYsML:not(:last-of-type) {
		margin-right: 8px;
	}
}

@media screen and (max-width: 320px) {
	button.U8wmwrUHYsML {
		font-size: 13px;
	}
}

button._2UyFcydUp19G {
	color: #727272;

	background-color: #f0f0f0;

	transition: color 0.15s, background-color 0.15s;
}

button._2UyFcydUp19G:hover {
	color: #414141;

	background-color: #f0f0f0;
}
