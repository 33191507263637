._1iOra9RDU_hG {
	width: 100%;
	height: 100%;
	padding-bottom: 80px;

	display: flex;
	flex-direction: column;

	background-color: #ededed;
}

._2QAtXdKkNwn4 {
	padding: 25px 0;

	border-bottom: 1px solid #d9d9d9;

	background: #ffffff;
}

.OH2xnhFwfxLI {
	position: relative;

	max-width: 1100px;
	margin: 0 auto;
}

._3d2xPAhetfcS {
	padding-bottom: 8px;

	font-weight: 600;
	font-size: 28px;
}

._29DYxbRXV6Lw {
	font-size: 16px;
	line-height: 28px;
}

.tgvM264HrqB8 {
	display: inline-flex;
	flex-wrap: wrap;
	max-width: 1100px;
	min-width: 1100px;
	margin: 0 auto;
	justify-content: center;
}

._2n32ujE6Tcdx {
	display: flex;
	flex: 1;
	margin-top: 25px;
	flex-wrap: wrap;
}

.tgvM264HrqB8._3fNOy6up6EC0 {
	padding-top: 15px;
	width: 100%;
}

.nZQogCkUj5rQ {
	flex: 1;
}

.nZQogCkUj5rQ:not(:last-child) {
	margin-right: 15px;
}

._2n32ujE6Tcdx > div {
	flex: 1;
	margin-bottom: 15px;
}

._2n32ujE6Tcdx > div:not(:last-child) {
	margin-right: 15px;
}

._12VjqrepLcF5 {
	position: relative;
	top: -11px;
	z-index: 1;

	margin-bottom: 1px;
}

._2gzFQn5Nsgvc {
	position: relative;
}

._1cPawS-00viR {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: auto;

	height: 20px;
	margin: auto;
	padding-left: 24px;

	color: var(--common-blue);
	font-weight: 500;
	font-size: 14px;

	cursor: pointer;
}

._1cPawS-00viR:after {
	position: absolute;
	top: -2px;
	left: 0;

	width: 20px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2NjUgOS45OTk5NkMxLjY2NjUgNS4zOTk5NiA1LjM5OTg0IDEuNjY2NjMgOS45OTk4NCAxLjY2NjYzQzE0LjU5OTggMS42NjY2MyAxOC4zMzMyIDUuMzk5OTYgMTguMzMzMiA5Ljk5OTk2QzE4LjMzMzIgMTQuNiAxNC41OTk4IDE4LjMzMzMgOS45OTk4NCAxOC4zMzMzQzUuMzk5ODQgMTguMzMzMyAxLjY2NjUgMTQuNiAxLjY2NjUgOS45OTk5NlpNMTAuODMzMiA5LjE2NjYzVjE0LjE2NjZIOS4xNjY1VjkuMTY2NjNIMTAuODMzMlpNOS45OTk4MyAxNi42NjY2QzYuMzI0ODMgMTYuNjY2NiAzLjMzMzE2IDEzLjY3NSAzLjMzMzE2IDkuOTk5OTZDMy4zMzMxNiA2LjMyNDk2IDYuMzI0ODMgMy4zMzMyOSA5Ljk5OTgzIDMuMzMzMjlDMTMuNjc0OCAzLjMzMzI5IDE2LjY2NjUgNi4zMjQ5NiAxNi42NjY1IDkuOTk5OTZDMTYuNjY2NSAxMy42NzUgMTMuNjc0OCAxNi42NjY2IDkuOTk5ODMgMTYuNjY2NlpNMTAuODMzMiA1LjgzMzI5VjcuNDk5OTZIOS4xNjY1VjUuODMzMjlIMTAuODMzMloiIGZpbGw9IiMzMjkwRkYiLz4KPC9zdmc+Cg==) center no-repeat;
	content: '';
}

._2waJ0BjIrS2m {
	z-index: 4;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	height: 50px;

	border-radius: 8px 8px 0 0;

	color: #ffffff;
	text-transform: capitalize;

	background: var(--common-blue);
}

._3UltWy870anw {
	display: flex;
	align-items: center;
}

._3QPuu6q1JBhz {
	width: 24px;
	height: 24px;
	margin-left: 8px;

	opacity: 0.5;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5ODQgNkw4LjU4OTg0IDcuNDFMMTMuMTY5OCAxMkw4LjU4OTg0IDE2LjU5TDkuOTk5ODQgMThMMTUuOTk5OCAxMkw5Ljk5OTg0IDZaIiBmaWxsPSIjZmZmZmZmIi8+Cjwvc3ZnPgo=) center no-repeat;

	transform: rotate(90deg);

	transition: all 0.15s;
}

._3fKaYESd2sXT ._3QPuu6q1JBhz {
	transform: rotate(-90deg);
}

@media (max-width: 1024px) {
	.tgvM264HrqB8 {
		display: block;
		min-width: auto;
		max-width: 100%;
		width: 100%;
		margin-bottom: 50px;
		padding: 12px;
	}

	._2n32ujE6Tcdx > div {
		margin-bottom: 12px;
	}

	._2QAtXdKkNwn4 {
		padding: 25px 20px;

		background-color: var(--baggage-head-background);
	}

	._3d2xPAhetfcS {
		font-size: 20px;

		color: var(--baggage-head-title-color);
	}

	._29DYxbRXV6Lw {
		font-size: 14px;
		color: var(--baggage-head-subtitle-color);
	}

	._2n32ujE6Tcdx {
		flex-direction: column;
	}
}

@media screen and (max-width: 600px) {
	._1iOra9RDU_hG {
		padding-bottom: 0;
	}
}
