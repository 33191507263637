

._3NwPbVnLoB3H {
	padding: 32px;
}

._1ZklKcwl764C {
	margin-bottom: 20px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 20px;
	line-height: 1.3;
}

div._3-0MZi-Oykf1 {
	margin-bottom: 10px;
}

div._2CzEMFssxz6n:before {
	background: transparent;
}

div.qQHDuagOpDk6 {
	border-bottom: none;
}

div.qQHDuagOpDk6 fieldset {
	border-color: var(--gray30) !important;
}

div._2CzEMFssxz6n fieldset,
div._2CzEMFssxz6n fieldset:focus {
	border-width: 1px !important;
	border-color: var(--base15) !important;
}

input.JYGZEXsmG5Vg {
	height: 56px;
	padding: 9px 12px;
}

input.JYGZEXsmG5Vg:focus {
	border: 1px solid transparent;
}

div.qQHDuagOpDk6 input.JYGZEXsmG5Vg {
	padding: 9px 12px;
}

._1e_zTJZDspJG {
	display: flex;
	flex-direction: column;
	max-height: 360px;
	padding: 4px 0;

	border: 1px solid #e9e9e9;
	border-radius: 4px;
	overflow-y: auto;
}

._1zVoIv0bNkOU {
	padding: 10px 16px;

	border-bottom: 1px solid #e9e9e9;

	color: var(--text-base);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;

	cursor: pointer;

	transition: background 0.3s;
}

._1zVoIv0bNkOU:last-child {
	border-bottom: none;
}

._1zVoIv0bNkOU:hover {
	background: var(--base90);
}

._2ATrOL9-3mcS {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 16px;

	text-align: center;
}
