

._2dXYglZt8eTe {
	display: flex;
	flex-direction: column;
	margin: 12px 0;
	padding: 16px;

	color: var(--gray80);

	background: transparent;
}

._3fTqn2QkZKjL {
	background: #ffffff;
}

._2S5CTrvoiui3 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 6px 0;

	font-size: 16px;
}

._2S5CTrvoiui3._3slglXKeycU0 {
	border-bottom: 1px solid var(--gray50);

	font-weight: 700;
}

._2S5CTrvoiui3._2aFSHTP7XzsV {
	order: -1;
}

._2S5CTrvoiui3.O-Mn8aoskZyN {
	border-bottom: 1px dashed var(--gray40);

	font-weight: 700;
}
