

._2q-m9y9To1WT {
	background: #4fcda0;
}

._1gAM5q5pTNBG {
	color: #ffffff;

	background: #4fcda0;
}
