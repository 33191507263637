

._2vVMNAgjHwdC {
	position: relative;

	width: 100%;
}

._2vVMNAgjHwdC > div {
	width: 100%;
}

._1S4A_DaZsu3B {
	display: inline-block;
}
