.S4Zt3ZVYw7Bg {
	display: inline-block;
	height: var(--input-height);

	flex-basis: 128px;
	flex-shrink: 0;
}

.S4Zt3ZVYw7Bg._2Kwq37Lsf-IH {
	display: flex;
	flex-basis: 270px;
}

@media (max-width: 1024px) {
	.S4Zt3ZVYw7Bg {
		flex: 3 0 auto;
	}
}

.cB913VZYGWR2 {
	flex-basis: 230px;
}

@media (max-width: 1024px) {
	.cB913VZYGWR2 {
		flex-basis: auto;
	}
}

.S4Zt3ZVYw7Bg button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	background-color: var(--start-search-button);
}

.S4Zt3ZVYw7Bg:hover:not(._2Kwq37Lsf-IH) button {
	background-color: var(--start-search-button-hover);
}

.S4Zt3ZVYw7Bg._2Kwq37Lsf-IH button:hover {
	background-color: var(--start-search-button-hover);
}

@media screen and (max-width: 600px) {
	.S4Zt3ZVYw7Bg {
		position: absolute;
		right: 20px;
		bottom: 16px;
		left: 20px;

		display: block;
		margin-top: 24px;
	}

	._2Kwq37Lsf-IH.S4Zt3ZVYw7Bg {
		position: relative;
		left: 0;
	}
}
