._3SRG2GV1jxD6 {
	margin-top: 28px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 15px;
	color: var(--gray50);
}

button._2z_Qx_qLXKlP,
button._2z_Qx_qLXKlP:hover {
	border-color: #ffffff;

	color: var(--error);

	background: #ffffff;
}

button._1w49wo0LJu3e,
button._1w49wo0LJu3e:hover {
	border-color: var(--active);

	color: #ffffff;

	background: var(--active);
}

._3SRG2GV1jxD6 button:not(:last-of-type) {
	margin-left: auto;
	margin-right: 15px;
}

@media screen and (max-width: 600px) {
	._3SRG2GV1jxD6 {
		flex-direction: column;
	}

	.s9tYrJxicUlY {
		margin-bottom: 20px;
	}

	button._1w49wo0LJu3e,
	button._2z_Qx_qLXKlP {
		width: 100%;
	}
}
