._1bqfYV2_a-lk {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 600px) {
	._1bqfYV2_a-lk {
		padding: 0 20px;
		height: 46px;
	}
}

._3BW69daBLwdH {
	position: relative;

	width: 200px;
	height: 20px;

	background-color: rgba(255, 255, 255, 0.2);
	overflow: hidden;
}

@media screen and (max-width: 600px) {
	._3BW69daBLwdH {
		width: 100px;
	}
}

._2uylrcroTrIw {
	display: flex;
	align-items: center;
	height: 28px;
	margin-left: 15px;
	padding-left: 15px;

	border-left: 1px solid rgba(255, 255, 255, 0.5);
}

._1s3FYAB9zusn {
	position: relative;

	width: 120px;
	height: 20px;

	background-color: rgba(255, 255, 255, 0.2);
	overflow: hidden;
}

@media screen and (max-width: 600px) {
	._1s3FYAB9zusn {
		width: 40px;
	}
}

._3BW69daBLwdH:after,
._1s3FYAB9zusn:after {
	position: absolute;
	top: 0;
	z-index: 1;

	width: 100%;
	height: 20px;

	transform: translateX(100%);
	opacity: 0.7;
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.3) 50%,
		rgba(128, 186, 232, 0) 99%,
		rgba(125, 185, 232, 0) 100%
	);

	animation: _1eYtZpXf7LA3 1s infinite 0.5s;
	content: '';
}

@keyframes _1eYtZpXf7LA3 {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}
