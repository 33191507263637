

._1MDfXianbr6g {
	margin: 0 15px;
}

._1MDfXianbr6g svg {
	color: var(--gray15);
}

._3cY1UV-29_Rn {
	height: 90px;
	width: 100%;
	padding-top: 20px;
}

._2jaoqVUttGcA {
	margin: 0 auto;
}

._2jaoqVUttGcA,
._1fk11USD5w3c {
	width: 1100px;
}

._1DaVw4LPiRAS input,
._1DaVw4LPiRAS {
	height: 48px;

	border: none !important;

	background-color: var(--brand-1-5);
}

._1DaVw4LPiRAS > fieldset {
	border: 0;
}

._2Gk9DMUQZijn,
._2RBwujHy5sTr {
	line-height: 14px;
}

button._2Gk9DMUQZijn {
	margin-right: 19px;
}

._1VolnICFItZB svg {
	transform: rotate(180deg);
}

button._2RBwujHy5sTr {
	margin-left: 15px;
	margin-right: 32px;
}

.Y-ULnTHdQH3J svg {
	color: var(--gray80);
}
