div._32Wm1HJK82to {
	max-width: 460px;
}

.y4h2AI3MFY7A {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 20px;

	font-size: 20px;
	line-height: 28px;
	color: var(--gray80);
	text-align: center;
}

._3m3ciZLICIwg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 38px;
	height: 38px;

	border-radius: 50%;

	color: #ffffff;

	background: var(--success);
}

button._26SK2JB_-qGx {
	font-weight: 700;
	font-size: 14px;
}

button._175pb-egnS2W {
	margin-right: 12px;

	color: var(--gray50);

	background: var(--gray05);
}

button._175pb-egnS2W:hover,
button._175pb-egnS2W:active,
button._175pb-egnS2W:focus {
	background: var(--gray05);
}

@media screen and (max-width: 600px) {
	div._32Wm1HJK82to {
		top: 50%;

		min-height: auto !important;

		transform: translateY(-50%);
	}
}
