

._2RQX48FihOC0 {
	position: relative;

	padding-left: 22px;

	font-weight: 600;
	white-space: nowrap;
}

._2RQX48FihOC0:before {
	position: absolute;
	top: 50%;
	left: 0;

	box-sizing: border-box;

	width: 10px;
	height: 10px;

	border: 2px solid #cdcdcd;
	border-radius: 50%;

	transform: translateY(-50%);
	content: '';
}

._2RQX48FihOC0 span {
	margin-right: 8px;
}

a._LgX50oW5Rf1 {
	margin-right: 0;

	font-weight: 400;
}

span.SNEQP3tbqqY8 {
	margin-right: 4px;
}

._1ky1G2KsLCn1 {
	color: var(--common-gray);
	font-weight: 400;
}
