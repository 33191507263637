div._1OaxBJ2iL-lf {
	background: transparent;
}

._2So3C1vS83zZ {
	display: flex;
}

._2dcNWBZKZFxa {
	margin-bottom: 10px;
}

.JcudHMdAVaBi {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

	grid-column-gap: 24px;

	column-gap: 24px;
}

._2hjkX7lmwImW {
	margin-top: 20px;
	margin-bottom: 20px;
}

button._3bCf3SQ8MTsM {
	border: 1px solid var(--gray10);

	color: var(--gray70);
	font-size: 16px;
	line-height: 19px;

	background: #ffffff;

	transition: none;
}

@media screen and (max-width: 600px) {
	button._3bCf3SQ8MTsM {
		flex: 1;
	}

	._2So3C1vS83zZ button._3bCf3SQ8MTsM:first-of-type {
		margin-right: 12px;
	}
}

button._3bCf3SQ8MTsM:hover {
	color: #ffffff;
}

._2So3C1vS83zZ button._3bCf3SQ8MTsM:first-of-type {
	margin-right: 12px;
}

@media screen and (max-width: 600px) {
	button._2vbUBQTG0Wn7._3DvNpBKDM9k6 {
		border-color: var(--base20);

		color: #ffffff;

		background: var(--base20);
	}
}

button._3DvNpBKDM9k6 {
	color: #ffffff;

	background: var(--base);
}

button._1Ys7gpud1Gj7 {
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	._-3DbdCyVRZya {
		padding: 24px 21px 0;
	}
}

._1UrYNwHoxlcT {
	margin: 0 0 24px;

	font-size: 30px;
	font-weight: 600;
	line-height: 100%;
	color: var(--gray80);
}

@media screen and (max-width: 600px) {
	._1UrYNwHoxlcT {
		font-weight: 700;
		font-size: 20px;
		line-height: 20px;
	}
}

._14As7_aDslCZ {
	top: 20px;
	right: 20px;

	border-radius: 50%;

	background: #ffffff;
}

.WhxNKJpIJw8C {
	position: relative;

	margin-right: -24px;
}

@media screen and (max-width: 600px) {
	.WhxNKJpIJw8C {
		margin-right: 0;
	}
}

._37P3mJ0khEkO {
	padding: 21px;
}

@media screen and (max-width: 600px) {
	._37P3mJ0khEkO {
		padding-top: 10px;
	}
}

._2NyG16IVOzek {
	position: absolute;
	top: 20px;

	max-width: 280px;
	padding: 0;
}

._2mLJmwRMkor6 {
	min-height: 440px;
}

@media (max-width: 1024px) {
	._2NyG16IVOzek {
		display: none;
	}
}

._2NyG16IVOzek svg {
	color: inherit;
}

.ITlyLoubdmTt {
	width: 100%;

	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

._2HMqP85LVxjW {
	padding: 16px;
}

._1lPJksyepf-w {
	display: flex;
	justify-content: flex-start;
	max-width: 100px;

	color: var(--base);
}

._3glTMxxtOEM9 svg {
	transform: translateY(2px);
}

._1lPJksyepf-w svg:not(:last-of-type) {
	margin-right: 13px;
}

._28A-rYLBfMqB ._1lPJksyepf-w {
	color: var(--base2);
}

.hckkJ-HM4aKA {
	margin: 15px 0 0;
	padding: 0;

	list-style-type: none;
}

._2aEraTnIxCfS {
	padding: 2px 0;

	font-size: 14px;
	line-height: 18px;
}

.VMhld3VGv9nG {
	position: absolute;
	bottom: -5px;
	left: 50%;

	transform: translate(-50%, 0);
}

._1OaxBJ2iL-lf .slick-slider {
	padding-bottom: 40px;
}

@media screen and (max-width: 600px) {
	._1OaxBJ2iL-lf .slick-slider {
		padding-bottom: 20px;
	}
}

._1OaxBJ2iL-lf .slick-list {
	padding-top: 20px;
	padding-left: 2px;
	padding-bottom: 2px;
}

@media screen and (max-width: 600px) {
	._1OaxBJ2iL-lf .slick-list {
		margin-left: 20px;
	}
}

._1OaxBJ2iL-lf .slick-arrow {
	top: auto;

	bottom: -30px;

	width: 36px;
	height: 36px;

	border: 1px solid var(--gray05);
	border-radius: 50%;

	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	background: #ffffff;
}

@media screen and (max-width: 600px) {
	._1OaxBJ2iL-lf button.slick-arrow {
		display: none !important;
	}
}

.BymeRdXrO2A7 {
	max-width: 80px;
	margin: 0 auto;
}

@media (min-width: 1025px) {
	._1OaxBJ2iL-lf .slick-slider {
		padding-left: 300px;
	}
}

._1OaxBJ2iL-lf .slick-arrow:before {
	display: none;
}

._1OaxBJ2iL-lf .slick-prev {
	left: 45%;

	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjQxIDcuNDFMMTQgNkw4IDEyTDE0IDE4TDE1LjQxIDE2LjU5TDEwLjgzIDEyTDE1LjQxIDcuNDFaIiBmaWxsPSIjNEQ0RDREIi8+Cjwvc3ZnPgo=) center no-repeat;
}

._1OaxBJ2iL-lf .slick-next {
	right: 45%;

	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5OTcgNkw4LjU4OTk3IDcuNDFMMTMuMTcgMTJMOC41ODk5NyAxNi41OUw5Ljk5OTk3IDE4TDE2IDEyTDkuOTk5OTcgNloiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) center no-repeat;
}

._1OaxBJ2iL-lf .slick-prev:hover,
._1OaxBJ2iL-lf .slick-prev:focus,
._1OaxBJ2iL-lf .slick-prev:active {
	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjQxIDcuNDFMMTQgNkw4IDEyTDE0IDE4TDE1LjQxIDE2LjU5TDEwLjgzIDEyTDE1LjQxIDcuNDFaIiBmaWxsPSIjNEQ0RDREIi8+Cjwvc3ZnPgo=) center no-repeat;
}

._1OaxBJ2iL-lf .slick-next:hover,
._1OaxBJ2iL-lf .slick-next:focus,
._1OaxBJ2iL-lf .slick-next:active {
	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5OTcgNkw4LjU4OTk3IDcuNDFMMTMuMTcgMTJMOC41ODk5NyAxNi41OUw5Ljk5OTk3IDE4TDE2IDEyTDkuOTk5OTcgNloiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) center no-repeat;
}

._1xx-HuckVdLr {
	display: flex;
}

._3glTMxxtOEM9 {
	display: inline-flex;
	align-items: center;
	margin-left: auto;

	color: var(--base);
	font-size: 15px;
	line-height: 21px;
	text-decoration: none;

	transform: translateY(-18px);
}

@media screen and (max-width: 600px) {
	._1xx-HuckVdLr {
		margin: 15px 21px 0;
		padding: 8px 10px;

		border-radius: 4px;

		background: #ffffff;
	}

	._3glTMxxtOEM9 {
		margin: 0 auto;

		transform: none;
	}

	._1xx-HuckVdLr svg {
		transform: translateY(4px) scale(0.9);
	}
}

@media (max-width: 1024px) {
	._1OaxBJ2iL-lf .slick-prev {
		left: 42%;
	}

	._1OaxBJ2iL-lf .slick-next {
		right: 42%;
	}
}

@media (max-width: 768px) {
	._1OaxBJ2iL-lf .slick-prev {
		left: 40%;
	}

	._1OaxBJ2iL-lf .slick-next {
		right: 40%;
	}
}

div.oabTyjlRbgIq {
	min-height: 470px;
}

@media screen and (max-width: 600px) {
	._1OaxBJ2iL-lf .slick-prev {
		left: 28%;
	}

	._1OaxBJ2iL-lf .slick-next {
		right: 28%;
	}
}

._1OaxBJ2iL-lf .slick-slide > div > div {
	margin-right: 24px;
}

@media screen and (max-width: 600px) {
	._1OaxBJ2iL-lf .slick-slide > div > div {
		margin-right: 5px;
	}
}
