._1tDYfx_AFNNL {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	margin-bottom: 8px;
	padding: 0 10px;

	border-radius: 4px;

	color: var(--gray80);

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	cursor: pointer;
	background: #ffffff;
}

.g3oYS5CBpyDL {
	color: #ffffff;

	box-shadow: none;
	background: var(--selected);
}

._1a9hudeENEYE {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	margin-right: 10px;

	flex-shrink: 0;

	border-radius: 50%;

	color: var(--gray50);
	font-weight: 500;
	font-size: 14px;

	background: var(--gray05);
}

.g3oYS5CBpyDL ._1a9hudeENEYE {
	color: #ffffff;

	background: rgba(255, 255, 255, 0.2);
}

._30hAeSHxTZtX {
	display: flex;
	align-items: center;
}

._2zFfyclwW8TZ {
	font-weight: 500;
}

._1tDYfx_AFNNL._2vIAXkA3WuAA {
	color: #ffffff;

	background: var(--selected);
}

._1tDYfx_AFNNL.g3oYS5CBpyDL._2vIAXkA3WuAA {
	background: var(--selected2);
}

._2vIAXkA3WuAA ._1a9hudeENEYE {
	color: var(--selected);

	background: #ffffff;
}

._1tDYfx_AFNNL.g3oYS5CBpyDL._2vIAXkA3WuAA ._1a9hudeENEYE {
	color: var(--selected2);
}

.kS_lgkSbbSia {
	display: flex;
	align-items: center;

	font-weight: 400;
	font-size: 15px;
}

.kS_lgkSbbSia svg {
	color: var(--gray30);
}

._10ulojlYTbAC {
	color: var(--gray30);
}

._3wcOzLDy_Bs1 {
	color: var(--base);
}

._1tDYfx_AFNNL.g3oYS5CBpyDL ._3wcOzLDy_Bs1,
._1tDYfx_AFNNL.g3oYS5CBpyDL ._10ulojlYTbAC,
._1tDYfx_AFNNL.g3oYS5CBpyDL svg,
._1tDYfx_AFNNL._2vIAXkA3WuAA ._3wcOzLDy_Bs1,
._1tDYfx_AFNNL._2vIAXkA3WuAA ._10ulojlYTbAC,
._1tDYfx_AFNNL._2vIAXkA3WuAA svg {
	color: #ffffff;
}

._3Xv2SpgyONNB {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	margin-right: 16px;

	border-radius: 4px;

	color: var(--gray70);
	font-weight: 500;
	font-size: 14px;

	background: var(--seat-standart);
}

button._10ulojlYTbAC {
	background: var(--base);
}

button._3Ry8yqU4YjaP {
	border: 1px solid var(--active);

	color: var(--active);

	background: #ffffff;
}

@media (max-width: 768px) {
	._1tDYfx_AFNNL {
		height: 68px;
		padding: 0;

		border-bottom: 1px solid var(--gray10);
		border-radius: 0;

		box-shadow: none;
		background: none;
	}
}
