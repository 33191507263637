

._28cYy0q7E_eP {
	display: inline-block;
	width: 30px;
	height: 30px;

	line-height: 30px;
	text-align: center;

	cursor: pointer;
}

._38YAcD8Vx8VH span {
	border-bottom: 1px dashed #656565;

	color: #656565;
	font-weight: 600;
}
