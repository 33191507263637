

._35JhHcVJMvGp {
	position: relative;
}

._30h2FWJbCSs5 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 68px;

	color: var(--white);

	background: var(--base-15);
}

._2oL8IumMKO0S {
	padding: 0 20px;
}

.BFrF0X3DShrO {
	padding: 0 20px;
}
