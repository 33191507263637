._2vqcaFbkpESG {
	padding: 28px 28px 35px 28px;
}

._1CRDjkXoHCdQ {
	margin: 0 0 17px 0;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 22px;
	line-height: 31px;
}

._13X9pKq1sZzU {
	margin: 0 0 14px 0;

	color: var(--common-gray);
	font-size: 15px;
	line-height: 21px;
}

.bEpSEoRR9yKq {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

._3DV7hDgdDxmG {
	width: 100%;
}

button._3Eo4YRRydAMy,
div._3Eo4YRRydAMy {
	margin-left: 50px;
}

@media screen and (max-width: 600px) {
	._2vqcaFbkpESG {
		padding-top: 11px;
	}

	.bEpSEoRR9yKq {
		flex-direction: column;
		align-items: flex-start;
	}

	button._3Eo4YRRydAMy,
	div._3Eo4YRRydAMy {
		margin-left: auto;
		margin-top: 20px;
	}
}
