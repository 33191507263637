._1ohvIE-FyKpj {
	padding: 28px;

	border-radius: 4px;

	background-color: var(--white);
	overflow: hidden;
}

._1xxnnwlBbaa- {
	margin-bottom: 20px;

	color: #1d1d1d;
	font-weight: 500;
	font-size: 22px;
	line-height: 1.2;
}

._3AiPigq3kzeK {
	display: flex;
	flex-direction: column;
}

._2k3jdIbOmZHT,
._3kfne4jo-rJH {
	display: flex;

	border-bottom: 1px solid var(--gray10);
}

._36i7wqg4kdBF {
	flex: 0 1 100%;
	padding: 10px;

	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;
}

.p3ZGSVcySvW3 {
	flex: 0 1 100%;
	padding: 10px;

	font-size: 14px;
	line-height: 1.3;
}

._36i7wqg4kdBF:first-child,
.p3ZGSVcySvW3:first-child {
	flex: 1 1 40%;
}

._36i7wqg4kdBF:nth-child(2),
.p3ZGSVcySvW3:nth-child(2) {
	flex: 1 1 200%;
}

._36i7wqg4kdBF:nth-child(3),
.p3ZGSVcySvW3:nth-child(3) {
	flex: 1 1 120%;
}

._36i7wqg4kdBF:nth-child(3),
._36i7wqg4kdBF:nth-child(4),
.p3ZGSVcySvW3:nth-child(3),
.p3ZGSVcySvW3:nth-child(4) {
	text-align: right;
}

.p3ZGSVcySvW3:first-child span,
.p3ZGSVcySvW3:nth-child(2) span {
	font-weight: 500;
}

._3mNf7ykIo-xP {
	color: #29a644;
}

._1aaC1lADJYvL {
	color: #c21212;
}

._3F6esi4SgDfR {
	display: inline-block;
	margin-left: 20px;
}

.OAI5j6ed_hJD {
	white-space: nowrap;
}

@media screen and (max-width: 600px) {
	._1xxnnwlBbaa- {
		padding: 20px 20px 0;
	}

	._1ohvIE-FyKpj {
		padding: 0;
	}

	._3oXHDjjKkJzT {
		overflow-y: scroll;
	}

	._3AiPigq3kzeK {
		width: 1000px;
		padding: 0 20px 20px;
	}
}
