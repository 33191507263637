

._2DM-wJcq4jpA {
	display: flex;
	flex-direction: column;
}

._3qNEKoYFB_Yu {
	margin: 32px 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

._2Zc9PVeXPNrQ {
	display: flex;
	align-items: center;
}

.m7s66xU-aZep {
	margin: 0 0 0 5px;
}

.Hlyj6VLr6328 {
	display: flex;
	flex-direction: column;
}

div._2dKzHhjZC5Ih {
	margin: unset;
}
