

button._1eC0KKzdR9Ey {
	width: 32px;
	min-width: 32px;
	height: 32px;
	min-height: 32px;

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

button._1eC0KKzdR9Ey,
div._1eC0KKzdR9Ey {
	margin-left: 8px;
}

button._1eC0KKzdR9Ey:hover {
	color: var(--brand-1-0);

	background: var(--brand-1-4);
}

button._1eC0KKzdR9Ey svg {
	width: 65%;
	height: 65%;
}
