

._1aWQ3_H9Rdj3 {
	font-size: 14px;
}

.yew25N2qCLb_ {
	padding: 28px 20px;
}

._3zhpxPoUFckk {
	padding: 20px;

	border-top: 1px solid #d9d9d9;

	text-align: right;
}

/* stylelint-disable */
._3-Ok1RPa40Ha {
}

/* stylelint-enable */
button._3oF0R6HGYoqJ {
	background-color: var(--results-desktop-flightinfo-select-fare-button);
}

button._3oF0R6HGYoqJ:hover {
	background-color: var(--results-desktop-flightinfo-select-fare-button-hover);
}
