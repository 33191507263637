._2a1vuuoNcC4j {
	display: flex;
	width: 100%;
	margin: 84px auto;
	max-width: 1100px;

	color: inherit;
}

._1ykVz1bxIWeS {
	color: var(--warnings-text-color);
}

div._2eQzYTWwgsY7 {
	margin-top: 0;
}

._3OihU0PRle6m {
	margin: 100px auto;
	max-width: 600px;
	width: 100%;
	overflow: hidden;

	border-radius: var(--login-wrapper-border-radius);

	background-color: #ffffff;

	box-shadow: 2px 4px 40px rgba(0, 0, 0, 0.05);
}

._2gD4sERTehet {
	margin-left: 40px;
	flex-grow: 1;
}

._1--d5TSX14Nb {
	margin: 0 0 24px;

	font-weight: 500;
	font-size: 28px;
	color: var(--dark-gray);
}

@media (max-width: 1024px) {
	._2gD4sERTehet {
		position: relative;
		top: -8px;
		z-index: 1;

		margin-left: 0;
		max-width: 100%;
		padding: 16px 20px 0;

		border-radius: 8px 8px 0 0;

		overflow: hidden;

		background: #ededed;
	}

	._2a1vuuoNcC4j {
		flex-direction: column;
		margin-top: 0;
	}
}

._36na19Y9y1rp {
	margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
	._3z4860PgbiF- {
		padding: 0;
	}
}

@media screen and (max-width: 600px) {
	._1--d5TSX14Nb {
		font-size: 22px;
	}

	._1--d5TSX14Nb._1rHg2DVzGwG0 {
		font-size: 18px;
	}

	._3OihU0PRle6m {
		height: 100vh;
		padding: 40px 30px 60px;
		margin: 0;

		border-radius: 0;
	}
}
