._2ArR3NlPBjiN {

	padding-right: 40px;
}

._3JYtPrhqX9Zp {
	margin-top: 4px;
	display: flex;
}

._2TJhvYZKVkc_ {
	margin-top: 0;
}

@media (max-width: 1024px) {
	._2ArR3NlPBjiN {
		padding-right: 0;
	}
}
