

.ZxLl9FVw9z_5 {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
}

._2acfkUjl6h7_ {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 2;

	text-align: justify;
}

._20Ue6tNxVQyk {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 45%;
	padding: 8px 0;

	border-bottom: 1px dashed #d9d9d9;

	text-align: justify;
}

._20Ue6tNxVQyk:first-of-type {
	padding-top: 0;
}

.h0xOIa7rU2AC {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

._3Q9GSBahP-3E,
._MWCCs2WwftB {
	font-size: 15px;

	color: var(--dark-gray);
}

._3Q9GSBahP-3E {
	text-transform: lowercase;
}

._2Cz_J3PdNOUo {
	text-transform: capitalize;
}

._1mkEsUIYeJGv {
	font-weight: 600;
	color: var(--common-blue);
}

._3_dwcZVKq7yM {
	font-size: 15px;
}

._3CQ2meF4bq4B {
	margin-top: 10px;

	font-size: 15px;
}
