._1BKf6l6oGM14 {
	display: flex;
	flex-basis: 358px;
	flex-direction: column;
	max-width: 358px;

	border-radius: var(--additional-services-card-border-radius);

	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.07);

	overflow: hidden;
}

._1BKf6l6oGM14._3KedUz_rTH6T {
	flex-basis: auto;
	flex-direction: row;
	max-width: unset;
	width: 100%;
}

._1BKf6l6oGM14:not(:last-child) {
	margin-right: 13px;
}

.OsPwWDPZK59a {
	position: relative;

	min-height: 180px;
	padding: 24px 28px;

	overflow: hidden;
}

.OsPwWDPZK59a._39r9wHF5m3X1 {
	min-height: unset;
	min-width: 180px;
}

._3CV-ZY5TuDce {
	background: var(--additional-services-insurance-head);
}

._2ug7YYvw0Q3Y {
	background: var(--additional-services-transfer-head);
}

._3aGjMHJzr523 {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 120px;
	margin-bottom: 8px;
	padding: 5px 0;

	border-radius: 4px;

	background: #ffffff;
}

._3QKev1AaP6fy {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	display: var(--additional-services-lines-display);
	align-items: stretch;
	height: 100%;
	width: 100%;

	opacity: 0.14;
}

.AMGpPQNmYw1x {
	position: absolute;

	width: 13px;
	height: 200%;

	background: #ffffff;
	transform: rotate(30deg) translate(85px, -110px);
}

._1imlW6fkqXu9 {
	position: absolute;

	width: 70px;
	height: 200%;

	background: #ffffff;
	transform: rotate(30deg) translate(9px, -110px);
}

._3KedUz_rTH6T ._1imlW6fkqXu9 {
	width: 50px;

	transform: rotate(30deg) translate(-5px, -40px);
}

._3KedUz_rTH6T .AMGpPQNmYw1x {
	width: 10px;

	transform: rotate(30deg) translate(50px, -80px);
}

._3jexCJS1xgOM {
	position: absolute;
	bottom: -4px;
	right: 0;
}

._3KedUz_rTH6T ._3jexCJS1xgOM {
	bottom: 0;
	top: auto;

	height: 135px;
	width: 135px;
}

._3KedUz_rTH6T ._3jexCJS1xgOM svg {
	height: 135px;
	width: 135px;
}

._3KedUz_rTH6T ._3jexCJS1xgOM svg path {
	opacity: 0.8;
}

._3e9W83be0ygI {
	z-index: 2;

	color: #ffffff;
	font-weight: 600;
	font-size: 36px;
}

._2bIVpgEMYGcK {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 20px 24px;

	color: var(--dark-gray);
	font-size: 15px;
	line-height: 23px;

	background: #ffffff;
}

._3KedUz_rTH6T ._2bIVpgEMYGcK {
	padding: 20px 28px;
}

._13xomH0L4wbs {
	margin-bottom: 2px;

	font-size: 24px;
	font-weight: 500;
	line-height: 150%;

	color: var(--dark-gray);
}

._3mjcvoNaj5uo {
	margin-bottom: 24px;

	color: var(--dark-gray);
	font-weight: 600;
	font-size: 20px;
}

._3KedUz_rTH6T ._3mjcvoNaj5uo {
	margin-bottom: 0;

	font-size: 15px;
	line-height: 19px;
	font-weight: 400;
}

._23WGW3Oiblxw {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 24px;

	border-top: 1px dashed #d9d9d9;

	background: #ffffff;
}

._3KedUz_rTH6T ._23WGW3Oiblxw {
	justify-content: flex-end;

	border-top: 0;
}

button._3LV2O-Jj_v0_ {
	border-radius: 24px !important;

	color: #ffffff;
	font-size: 13px;
	line-height: 18px;

	background-color: var(--additional-services-add-button);
}

._3KedUz_rTH6T button._3LV2O-Jj_v0_ {
	height: 48px;
	width: 120px;
}

._3KedUz_rTH6T .GLJJ2GYFmSt8 {
	margin-right: 24px;
}

button._3LV2O-Jj_v0_._1zZgnIt94gsW {
	font-weight: 600;

	color: #353535;

	background-color: #ededed;
}

._1mJjT5h3bVkn {
	display: inline-flex;
	align-items: center;
	height: 48px;
	padding: 0 20px;

	border-radius: 24px;

	color: var(--additional-services-add-button-color);

	cursor: pointer;
	background: var(--additional-services-add-button);
}

._33CtPP9k7l0L {
	height: 100%;
	margin-right: 20px;
	padding-right: 20px;

	border-right: 1px dashed rgba(255, 255, 255, 0.4);

	font-weight: 600;
	font-size: 15px;
	line-height: 48px;
}

span.GLJJ2GYFmSt8 {
	padding-left: 0;

	font-size: 20px;
	font-weight: 600;
	color: var(--baggage-additional-selected-money-color);
	color: var(--checkin-additional-price-selected-color, var(--baggage-additional-selected-money-color));
}

span._1FoRoC3c8SyI {
	color: var(--cabinet-action);
}

@media screen and (max-width: 600px) {
	._1BKf6l6oGM14 {
		width: 100%;
		max-width: unset;
		flex-basis: unset;
	}

	._1BKf6l6oGM14:not(:last-child) {
		margin-right: 0;
		margin-bottom: 13px;
	}

	.OsPwWDPZK59a ._3aGjMHJzr523 {
		display: none;
	}
}
