._2ej9h3amwCtD {
	width: 100%;
}

._2QcOXnzBm4CR {
	position: relative;

	height: 350px;

	border-radius: var(--baggage-additional-wrapped-border-radius) var(--baggage-additional-wrapped-border-radius) 0 0;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

._2gxvKfP_W1Zc {
	position: absolute;
	top: 20px;
	left: 20px;

	padding: 4px 16px;

	border-radius: 100px;

	color: #ffffff;
	font-size: 16px;
	line-height: 150%;
	font-weight: 600;

	background-color: var(--error);
}

.WcrPa6XqqxlO {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 16px 0;
}

.WcrPa6XqqxlO:first-of-type {
	border-bottom: 1px dashed var(--gray20);
}

._3VH6gE-fLMqM {
	color: var(--gray80);
	font-weight: 600;
	font-size: 20px;
	line-height: 130%;
	text-transform: uppercase;
}

._3Bkemskmht02 {
	margin-left: 40px;

	color: var(--gray50);
	font-size: 16px;
	line-height: 130%;
	white-space: nowrap;
}

._3mIxy7joItiY {
	padding: 0 40px;

	background-color: var(--gray05);
}

._5Jc_mAxlv4rS {
	display: flex;
	align-items: center;

	color: var(--base);
	font-size: 16px;
	line-height: 130%;

	cursor: pointer;
}

._19H2mlpnDlNP {
	display: flex;
	align-items: center;

	margin-left: 4px;

	transition: transform 0.15s;
}

._1yXBgHQPdgk- {
	transform: rotate(180deg);
}

._1_eKrUKYHS0U {
	color: var(--gray80);
	font-weight: 600;
	font-size: 20px;
	line-height: 150%;
}

._1n8XJdgWibK8 {
	margin-right: 8px;

	color: #c21212;
	font-weight: 600;
	font-size: 18px;
	line-height: 170%;
	text-decoration: line-through;
}

.ajcBEMIMX-y0 {
	padding-bottom: 16px;

	border-top: 1px dashed var(--gray20);

	color: var(--gray70);
	font-size: 16px;
	line-height: 140%;
}

._3diFwxxEaOOI {
	margin-top: 16px;
}

._BT1LWD-iNA6 {
	font-weight: 600;
}

._2bqhZ6rc3Hsz {
	padding: 16px 40px 40px;

	background-color: #ffffff;
}

._3RRyhig2j5nM {
	margin: 0;
	padding: 0;
}

.yLYwZFZ_EJBB {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 16px 0;

	border-bottom: 1px solid var(--gray10);

	list-style-type: none;
}

.bOLhd6Z6qKB8 {
	display: flex;
	align-items: center;

	color: var(--gray80);
	font-weight: 500;
	font-size: 15px;
}

._1UoTieazv2-S {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 28px;
	height: 28px;
	margin-right: 8px;

	border-radius: 50%;

	color: var(--gray50);
	font-weight: 500;
	font-size: 14px;
	line-height: 100%;

	background-color: var(--gray05);
}

button._3w7FWxrRsCUq {
	opacity: 0.5;
	background: var(--base);
}

button._3w7FWxrRsCUq:hover {
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
	background: var(--base-15);
}

button._3w7FWxrRsCUq:focus {
	background: var(--base-30);
}

button._3w7FWxrRsCUq:hover,
button._3w7FWxrRsCUq:focus,
button._3w7FWxrRsCUq:active {
	opacity: 1;
}

._1XUNaK8piexY {
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-top: 16px;
}

._3t4_mwxgA4CB {
	display: flex;
	align-items: center;
}

.B2LEE5Je--EC {
	opacity: 1;
}

._3H-GXLfbLHc2 {
	justify-content: flex-end;
}

._2Y3HJTem-ubu {
	padding-left: 0;

	color: var(--gray80);
	font-weight: 600;
	font-size: 20px;
	line-height: 130%;
}

._1aXYeaAK6BHX {
	position: relative;
	top: 1px;

	font-size: 16px;
}

.OL8MbD4fc1Mq {
	align-self: center;
	margin-left: 12px;
	padding-left: 12px;

	border-left: 1px solid var(--gray10);

	color: var(--base);
	font-size: 16px;
	line-height: 130%;

	cursor: pointer;
}

._3f9JQ42Wh5rB {
	margin-left: 0;
	padding-left: 0;

	border-left: none;
}

@media screen and (max-width: 600px) {
	._2ej9h3amwCtD {
		min-height: 100%;

		background-color: #ffffff;
	}

	._2QcOXnzBm4CR {
		height: 250px;

		border-radius: 0;
	}

	._3mIxy7joItiY {
		padding: 0 20px;
	}

	._2bqhZ6rc3Hsz {
		padding: 16px 20px 20px;
	}
}

._1SeLNBwWn7ge {
}

._1SeLNBwWn7ge svg {
	color: var(--brand-1-2);
}
