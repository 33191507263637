

form._1wqfhjf6KVGR {
	padding-bottom: 12px;
	display: flex;
}

div._29pbRuY8w3Nz {
	display: block;
	flex: 1;
}

div._29pbRuY8w3Nz:not(:last-child) {
	margin-right: 20px;
}

div._29pbRuY8w3Nz > div,
div._29pbRuY8w3Nz > div:last-child {
	margin: 0 0 20px 0;
}

._3ETyIQOGzQ0W {
	font-size: 24px;
	font-weight: 700;
	color: var(--text-base);
}
