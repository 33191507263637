._28De-geV1VFS {
	padding: 40px 28px 28px;
}

._3hjezkpxZYBK {
	font-weight: 500;
	font-size: 22px;
	line-height: 32px;

	color: var(--dark-gray);
}

._12nwmmA6VP6C {
	font-size: 15px;
	line-height: 28px;
	color: var(--common-gray);
}

@media screen and (max-width: 600px) {
	._3hjezkpxZYBK {
		font-size: 18px;
	}

	._12nwmmA6VP6C {
		margin-top: 16px;

		font-size: 14px;
	}
}

.RTIA0d76cGfw {
	display: flex;
	justify-content: flex-end;
	margin-top: 28px;
}

@media screen and (max-width: 600px) {
	._28De-geV1VFS {
		padding: 20px;
	}

	.RTIA0d76cGfw button {
		font-size: 13px;
	}
}
