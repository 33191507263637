

div._2hjX58OM9xJx {
	display: block;
	width: max-content;
}

.sFMBrjwdaaXH {
	width: 830px;
	padding: 43px 40px 40px;
}

._32E9AKNDP6bN {
	font-weight: 300;
	font-size: 36px;
	line-height: 42px;
	color: var(--common-gray);
}

._3PajiGr1XwWl {
	margin-top: 32px;
}

._2XlWZN2YkcAh {
	display: flex;
	align-items: center;

	border-bottom: 1px solid var(--gray10);
}

.kJyZd-w56Zrz {
	width: 200px;
	flex-shrink: 0;
	padding: 20px 12px 15px;

	white-space: pre-line;
	font-weight: 700;
	font-size: 15px;
	line-height: 130%;
	color: var(--gray90);
}

._2ck4jDF8mpq2 {
	flex-grow: 1;
	padding: 20px 12px 15px;

	white-space: pre-line;
	font-size: 14px;
	line-height: 130%;
	color: var(--dark-gray);
}

._2XlWZN2YkcAh.ypBlvC2b9eoY {
	border-bottom: none;
}

.ypBlvC2b9eoY .kJyZd-w56Zrz,
.ypBlvC2b9eoY ._2ck4jDF8mpq2 {
	padding: 15px 12px 12px;

	font-weight: 700;
	font-size: 15px;
	line-height: 130%;
	color: var(--white);

	background: var(--base);
}
