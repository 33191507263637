

._4MR8RMTV1qj1 {
	display: flex;
	flex-direction: column;
	height: 100%;

	border-radius: 8px;

	border: 1px solid transparent;

	box-shadow: 4px 10px 38px rgba(0, 0, 0, 0.1);

	transition: border-color 0.3s;
	overflow: hidden;
}

._4MR8RMTV1qj1.BvdxWsHqYvhJ {
	border: 1px solid var(--active-30);
}

._1YGKhzukFGuP {
	display: flex;
	align-items: center;
	padding: 12px 16px;

	color: var(--white);

	background-color: var(--additional-insurance);

	transition: background-color 0.3s;
}

._4MR8RMTV1qj1.BvdxWsHqYvhJ ._1YGKhzukFGuP {
	background-color: var(--active-30);
}

.uIwq4-9m6pEx {
	font-size: 18px;
	font-weight: 700;
	line-height: 1;
}

._6l01gUowb-ay {
	margin-top: 4px;

	font-size: 12px;
	line-height: 1;
}

._3fHN2itoqd35 {
	display: flex;
	flex-direction: column;
}

._2zqfpJZkijDz {
	display: flex;
	align-items: center;
	margin-left: auto;

	font-weight: 700;
	font-size: 18px;
}

._2zqfpJZkijDz > svg {
	margin-left: 8px;
}

._3aF0zxEQ5xsZ {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	padding: 20px 16px;
}

._3aF0zxEQ5xsZ > ul {
	width: 100%;
	margin-top: 0;
	margin-bottom: 14px;
	padding-left: 0;

	list-style: none;
}

._3aF0zxEQ5xsZ > ul > li {
	position: relative;

	margin-bottom: 14px;
	padding-bottom: 14px;
	padding-left: 14px;

	border-bottom: 1px solid var(--gray10);

	color: var(--text-base);

	font-size: 14px;
	line-height: 1.4;
}

._3aF0zxEQ5xsZ > ul > li:before {
	position: absolute;
	top: 6px;
	left: 0;

	width: 5px;
	height: 5px;

	border-radius: 50%;

	background-color: var(--line-separator);
	content: '';
}

._3aF0zxEQ5xsZ > ul > li:last-of-type {
	margin-bottom: 0;

	border-bottom: none;
}

button._3HmTzYYFfrHt,
button._1crF2Cs5CNGe {
	margin-top: auto;

	border-radius: 4px;
}

button._3HmTzYYFfrHt,
button._3HmTzYYFfrHt:hover {
	border: 1px solid var(--brand-1-0);

	color: var(--brand-1-0);
	font-weight: 500;

	background-color: transparent;
}

button._1crF2Cs5CNGe,
button._1crF2Cs5CNGe:hover {
	color: var(--text-light);
	font-weight: 400;

	background-color: var(--bg-fill);
}
