._305fSGmZHKTt {
	max-width: 600px;
	padding: 40px 56px 32px;
	flex-grow: 1;

	border-radius: 12px;

	background-color: #ffffff;
	box-shadow: 2px 4px 40px rgba(0, 0, 0, 0.05);
}

.fEMDdh8en5kw {
	position: relative;

	margin: 0;

	font-weight: 500;
	font-size: 28px;
	line-height: 40px;
}

._1fJ4BFyt7hKx {
	position: absolute;

	display: flex;
	align-items: center;
	height: 40px;

	transform: translateX(calc(-100% - 12px));
}

._1mQYB84gBwZD {
	margin-top: 28px;
}

._3-J2Gd2V5o7T {
	margin-top: 50px;
}

._3-J2Gd2V5o7T:first-of-type {
	margin-top: 0;
}

._3-J2Gd2V5o7T:last-of-type {
	margin-top: 24px;

	display: flex;
	justify-content: flex-end;
}

button._1g470NWqaT7x._1gyIjUrsaqYi {
	color: var(--gray30);

	background-color: var(--gray05);
}

@media screen and (max-width: 600px) {
	._305fSGmZHKTt {
		padding: 20px;

		border-radius: 0;

		box-shadow: none;
	}

	._1mQYB84gBwZD {
		margin-top: 15px;
	}

	._3-J2Gd2V5o7T {
		margin-top: 30px;
	}

	.fEMDdh8en5kw {
		font-size: 24px;
	}

	._1fJ4BFyt7hKx {
		display: none;
	}

	div._2iuYuA8e7F45 {
		margin-top: -34px !important;
		margin-left: -140px;
	}
}
