

._2bEgvfuM-9GB {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 16px;
	align-items: center;
}

._2mJ7bPReZ7df {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	height: 36px;

	border-radius: 4px;

	background: #ffffff;
}

._2mJ7bPReZ7df svg {
	width: 100px;
	height: 20px;
}

._1s2WAIBZlLKv {
	margin: 0;

	font-weight: 600;
	font-size: 15px;
}

._39bE6Z7_4xpt {
	margin-top: 10px;
	width: 100%;
}

._1l71Xe1SusKu {
	display: flex;
	align-items: center;
}

._2ig9bkrOuzV- {
	font-weight: 600;
}

span._3WgPCDMve0wa {
	position: relative;
	top: -1px;

	display: inline-flex;
	align-items: center;
	width: 24px;
	height: 24px;
}

._2Qplk1zowu20 {
	margin: 0 auto;
}

div.khF0hVI-JZr_ {
	color: var(--mobileStepbar-extras-color);
}

._2ig9bkrOuzV-.Us-ybfIGhdff,
._3WgPCDMve0wa {
	color: var(--mobileStepbar-extras-color);
}

div._2wayjleBwTNH {
	padding: 40px 28px 28px;
	overflow: visible;

	border-radius: 8px;
}

div._3R17HIXaGmzg {
	padding: 20px;

	border-radius: 16px;
}
