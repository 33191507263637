

button._1VbVB4aflVTg {
	min-width: 120px;

	padding: 14px 45px;

	border-radius: 4px;

	font-weight: 600;
	font-size: 17px;
	line-height: 120%;

	transition: background-color 0.2s, box-shadow 0.2s;
}

button._1VbVB4aflVTg:hover,
button._1VbVB4aflVTg:focus {
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
}

button._1VbVB4aflVTg:disabled {
	cursor: not-allowed;
}

button.KEOPCOHLeZ4a {
	padding: 12px 16px;

	font-size: 14px;
}
