

._2dqgIQaGvmtn {
	padding: 40px;
	width: 1100px;
}

._1b3_IyiLvru3 {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 1;
}

._1b3_IyiLvru3 > div {
	background-color: var(--gray05);
}

._2geGcKaHZ2z6 {
	font-size: 20px;
	font-weight: 700;
}

._2Vl-uim6mEtk {
	margin: 20px 0;

	border: 1px solid var(--line-separator);
}

._1d43eSwnz9WQ {
	margin-bottom: 30px;
}

._3zzubPqHBqpf {
	margin-bottom: 16px;

	font-weight: 700;
	font-size: 18px;
}

._3OeIUpY-e2nZ {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	align-items: center;

	grid-column-gap: 12px;

	column-gap: 12px;
	grid-row-gap: 20px;
	row-gap: 20px;
}

._1atMNK6Gg-5u {
	display: flex;
	justify-content: flex-end;

	grid-column-gap: 12px;

	column-gap: 12px;
}

button._3HmevqaiGA5a,
button._3HmevqaiGA5a:hover {
	color: var(--gray30);

	background: var(--terminalForm-cancelButton-background);
}

button._3lH4aw2XWPiY,
button._3lH4aw2XWPiY:hover {
	background: var(--brand-3-0);
}

._23Aq3V153NJa,
div._2AfbTOTwVmHk {
	box-sizing: border-box;
	height: 56px;

	border-radius: 4px;
}

div._2AfbTOTwVmHk {
	background: var(--base90);
}

div > div._1DlCN_n4lEYU {
	margin-top: 10px;

	border: 0;
}

label + div._23Aq3V153NJa {
	margin-top: 0;
}

input._3nVEUopolQPg {
	border: 0;
}

div._23Aq3V153NJa:before {
	font-size: 16px;

	background: var(--base90);
}

.IZAHlCNfBED7 > label {
	transform: translate(10px, 20px);
}

div._2AfbTOTwVmHk,
.IZAHlCNfBED7 {
	max-width: 512px;
}

._2AfbTOTwVmHk > label.V22QXLTa7kRU {
	transform: translate(8px, 4px) scale(0.9);
}

._2AfbTOTwVmHk > div._1DlCN_n4lEYU:before {
	background: inherit;
}

._2AfbTOTwVmHk > ._3Q3Ui_amPn3o {
	font-size: 16px;

	transform: translate(10px, 20px);
}

label._3QsLO2WMRnCM {
	transform: translate(10px, 6px) scale(0.9);
}

._1IX67OCIefPu {
	margin-bottom: 2px;

	font-size: 15px;
}

._3oKY_rhHXEOR,
._36VNMl9MHsKa {
	height: 56px;

	background: var(--base90);
}

._3oKY_rhHXEOR {
	height: 54px;
}

._36VNMl9MHsKa {
	border: 1px solid var(--line-separator);
	border-radius: 4px;
}

._2Ujxh3fjtWBU {
	border-color: var(--brand-1-1);
}

.sa-Aol3rDm6U {
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.sa-Aol3rDm6U div {
	background-color: var(--base90);
}

.sa-Aol3rDm6U div:first-child {
	border-top-right-radius: 4px;
}

.sa-Aol3rDm6U div:last-child {
	border-bottom-right-radius: 4px;
}

.sa-Aol3rDm6U svg {
	color: var(--gray50);
}

li._2llENS1OrYL8 {
	font-weight: 500;
}

li._2llENS1OrYL8:hover {
	background-color: var(--base90);
}

li._2llENS1OrYL8:not(:first-child) {
	border-top: 1px solid var(--gray05);
}

li._2llENS1OrYL8:not(._3fKjUE1o7i0O):not(:hover) {
	background-color: var(--white);
}

li._3fKjUE1o7i0O._2llENS1OrYL8,
li._3fKjUE1o7i0O._2llENS1OrYL8:hover {
	background-color: var(--base90);
}
