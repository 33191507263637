

._3fVqTJ52bCpU {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 30px;
}

button._358tkHYWgIXX {
	border-color: var(--active);
	border-radius: 4px;

	color: var(--white);

	background: var(--active);
}

button._358tkHYWgIXX:hover {
	border-color: var(--active);

	background: var(--active);
}

._1p3bF3gKxzvD {
	margin-top: 20px;
}
