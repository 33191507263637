

._2NIlEad39Htz {
	display: flex;
	height: 80px;
	align-items: center;
	justify-content: space-between;
}

._1WXsXN6u7QY8 {
	display: flex;
	align-items: center;
}

button._1sRcbxafXQCW {
	margin-right: 19px;
	padding-right: 16px;

	border-right: 1px solid var(--gray10);

	color: var(--error);
}

div._3LF4ISDRiZv5,
button._3LF4ISDRiZv5,
button._3LF4ISDRiZv5:hover {
	background: var(--brand-3-0);
}

button.u8EXPLpKQy1s {
	background: var(--brand-3-3);
}

.Aw9NzYV6qm0Q {
	display: flex;
	align-items: center;

	color: var(--base);
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
}

.Aw9NzYV6qm0Q > div {
	height: 32px;
	padding: 0 19px;

	line-height: 32px;
}

.Aw9NzYV6qm0Q > div:first-child {
	padding-left: 0;
}

.Aw9NzYV6qm0Q > div:not(:first-child) {
	border-left: 1px solid var(--gray10);
}
