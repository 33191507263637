._3Y_yuV8VTjq2 {
	display: flex;
	padding: 14px 0;
	width: 100%;
	align-items: center;
	margin: 0;
}

._3Y_yuV8VTjq2 > p {
	display: flex;
	width: 100%;
	align-items: baseline;
	justify-content: space-between;
}

._3Y_yuV8VTjq2 > p > h1 {
	margin: 0;

	font-size: 18px;
	font-weight: 500;
	color: var(--dark-gray);
}

._3Y_yuV8VTjq2._1-BvE88U11ms > p > h1 {
	color: var(--cabinet-action);
}

._3Y_yuV8VTjq2._1-BvE88U11ms > span > svg > path {
	fill: var(--cabinet-action);
}

._3Y_yuV8VTjq2 > span {
	display: flex;
	align-items: center;
	margin-right: 16px;
	margin-left: -8px;
}

._3Y_yuV8VTjq2 > p > span:first-child {
	margin: 0;

	font-size: 18px;
	font-weight: 500;
	color: var(--dark-gray);
}

._3Y_yuV8VTjq2._1-BvE88U11ms > p > span {
	color: var(--common-blue);
}

div._2NVOqjiqUZ_2 {
	max-width: 780px;
	margin: 0;
	margin-bottom: 8px;

	border-radius: 4px;
}

div._2NVOqjiqUZ_2:before {
	opacity: 0;
}

._2NVOqjiqUZ_2 .oTvyYvqfwjYC {
	min-height: 64px;
}

._2NVOqjiqUZ_2 .oTvyYvqfwjYC > div {
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

._2NVOqjiqUZ_2 .oTvyYvqfwjYC > div p {
	margin: 0;

	font-size: 15px;
	font-weight: 400;
	color: var(--common-gray);
}

._2NVOqjiqUZ_2 ._1SBDbaD2-JT9 {
	display: flex;
	justify-content: center;
	padding: 0;
}

._2NVOqjiqUZ_2 ._1SBDbaD2-JT9 > div {
	width: 100%;
}

@media screen and (max-width: 600px) {
	._3Y_yuV8VTjq2 > p {
		flex-direction: column;
	}

	._3Y_yuV8VTjq2 > p > span {
		margin-top: 4px;
	}
}
