

.tsuxBbTEYd8S {
	position: relative;

	margin-bottom: 8px;
	padding: 17px 17px 17px 50px;

	border: 1px solid var(--warning);

	color: var(--gray80);
	font-size: 15px;
	line-height: 130%;

	background: var(--warning20);
}

.rkGjFvlAXOJf {
	font-weight: 600;
	font-size: 16px;
	line-height: 130%;
}

.x8qexWspDN1m {
	position: absolute;
	top: 18px;
	left: 18px;
}
