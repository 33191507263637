._1sVsJ3LTfoBV {
	z-index: 3;

	display: flex;
	align-items: center;
	height: 100%;
	padding-right: 20px;

	cursor: pointer;
}

@media screen and (max-width: 600px) {
	._1sVsJ3LTfoBV {
		padding: 0 20px;
		width: 100%;
		height: 46px;
	}
}

@media (max-width: 1024px) {
	._1sVsJ3LTfoBV {
		padding: 0 20px;
		width: 100%;
		height: 46px;
	}
}

._3Em0056b-Lkx {
	display: flex;
	align-items: center;

	color: var(--quickSearchForm-summary-route-color);
	font-weight: var(--quickSearchForm-summary-route-fontWeight);
	font-size: 14px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

._13cr5UcGMZJb {
	display: flex;

	margin: 0 8px;
}

@media screen and (max-width: 600px) {
	._3Em0056b-Lkx {
		font-size: 14px;
		line-height: 16px;
		color: #ffffff;
	}
}

._1kyGpLll6bYO {
	margin-left: 15px;
	padding-left: 15px;

	border-left: 1px solid var(--quickSearchForm-summary-trigger-border-color);

	color: var(--quickSearchForm-summary-passengers-color);
	line-height: 28px;
	font-size: 14px;
}

@media screen and (max-width: 600px) {
	._1kyGpLll6bYO {
		display: flex;
		align-items: center;
		margin-left: 0;
		padding-left: 0;

		border-left: none;

		color: #ffffff;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
	}
}

._1Rzo7exfGBAr {
	display: inline-flex;
	align-items: center;
	margin-left: 15px;
	min-width: 90px;

	padding-left: 15px;

	border-left: 1px solid var(--quickSearchForm-summary-trigger-border-color);

	color: var(--quickSearchForm-summary-trigger-color);
	font-weight: var(--quickSearchForm-summary-trigger-fontWeight);
	font-size: 14px;
	line-height: 28px;
}

@media screen and (max-width: 600px) {
	._1Rzo7exfGBAr {
		height: 16px;
		padding-left: 8px;
		margin-left: 8px;

		border-left-color: rgba(255, 255, 255, 0.3);

		line-height: 16px;
	}
}

._3xkgb3qSH8LS {
	display: inline-flex;
	align-items: center;
	margin-left: 5px;

	transition: transform 0.15s;
}

@media screen and (max-width: 600px) {
	._3xkgb3qSH8LS {
		position: absolute;
		right: 20px;

		margin-left: 0;

		color: #ffffff;
	}
}

._36CUynJBJJ_d {
	transform: rotate(-180deg);
}
