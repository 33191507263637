

._3LnmwJsxGuVC {
	height: 100%;

	border-top: 1px solid var(--line-separator);

	background: var(--gray05);
}

.is1zW-9Jj-Lq {
	display: flex;
	padding: 20px 0;

	background: #ffffff;
}

div._3toaU_P0Zlff {
	max-width: 1100px;
	margin: 0 auto;
}

.B3uw_6hfU1ne {
	background-color: var(--brand-1-5);
}

._3fuONcsyG73b {
	padding-left: 12px;
}

._3fuONcsyG73b path {
	fill: var(--gray15);
}

._1pCcMjxwM3Sk {
	max-width: 1100px;
	margin: 0 auto;
	padding: 23px 0;
}

.LTqx-LH_u5nr {
	padding-right: 12px;

	color: var(--gray50);

	cursor: pointer;
}
