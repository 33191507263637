

._2sMJRPR-mBaC {
	position: relative;

	width: 100%;
	padding: 15px 20px;

	color: #ffffff;
	font-weight: 700;
	font-size: 20px;

	background: #262626;
}

._154WWBfEhGCk {
	position: absolute;
	top: 15px;
	right: 20px;

	cursor: pointer;
}

._5IRj03W6Tfcd {
	width: 100%;

	color: #ffffff;
	font-size: 16px;

	background: var(--text-base);
}

._1YcwJ4d1d7hO {
	position: relative;

	width: 100%;
	padding: 10px 20px;

	color: #ffffff;
}

._1YcwJ4d1d7hO:nth-child(even) {
	background: #262626;
}

._1YcwJ4d1d7hO:nth-child(odd) {
	background: #393939;
}

._1YcwJ4d1d7hO:before {
	position: absolute;
	top: 10px;
	left: 20px;

	width: 7px;
	height: 14px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDggMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjAwMTc2IDE1LjAxMzZDMC43NjgxMDggMTUuMDE0MSAwLjU0MTY3MiAxNC45MzI3IDAuMzYxNzYgMTQuNzgzNkMwLjI2MDUwMSAxNC42OTk3IDAuMTc2OCAxNC41OTY2IDAuMTE1NDQ4IDE0LjQ4MDJDMC4wNTQwOTU2IDE0LjM2MzkgMC4wMTYyOTkzIDE0LjIzNjYgMC4wMDQyMjM0MiAxNC4xMDU2Qy0wLjAwNzg1MjQ0IDEzLjk3NDYgMC4wMDYwMjk1OCAxMy44NDI1IDAuMDQ1MDc0MSAxMy43MTY5QzAuMDg0MTE4NSAxMy41OTEzIDAuMTQ3NTU4IDEzLjQ3NDcgMC4yMzE3NiAxMy4zNzM2TDQuNzExNzYgOC4wMTM2MUwwLjM5MTc2IDIuNjQzNjFDMC4zMDg2OTQgMi41NDEzMiAwLjI0NjY2MyAyLjQyMzYzIDAuMjA5MjMxIDIuMjk3MjlDMC4xNzE3OTkgMi4xNzA5NSAwLjE1OTcwNSAyLjAzODQ2IDAuMTczNjQ1IDEuOTA3NDNDMC4xODc1ODQgMS43NzY0IDAuMjI3MjgxIDEuNjQ5NDIgMC4yOTA0NTQgMS41MzM3OEMwLjM1MzYyOCAxLjQxODE1IDAuNDM5MDMzIDEuMzE2MTQgMC41NDE3NiAxLjIzMzYxQzAuNjQ1MjI2IDEuMTQyNTggMC43NjYzOTIgMS4wNzM5MSAwLjg5NzY1NCAxLjAzMTkyQzEuMDI4OTIgMC45ODk5MyAxLjE2NzQ0IDAuOTc1NTI3IDEuMzA0NTMgMC45ODk2MTFDMS40NDE2MyAxLjAwMzcgMS41NzQzMyAxLjA0NTk3IDEuNjk0MzEgMS4xMTM3N0MxLjgxNDI5IDEuMTgxNTcgMS45MTg5NyAxLjI3MzQ0IDIuMDAxNzYgMS4zODM2MUw2LjgzMTc2IDcuMzgzNjFDNi45Nzg4NCA3LjU2MjU1IDcuMDU5MjUgNy43ODY5OSA3LjA1OTI1IDguMDE4NjFDNy4wNTkyNSA4LjI1MDI0IDYuOTc4ODQgOC40NzQ2OCA2LjgzMTc2IDguNjUzNjFMMS44MzE3NiAxNC42NTM2QzEuNzMxNDQgMTQuNzc0NiAxLjYwNDAxIDE0Ljg3MDMgMS40NTk4IDE0LjkzMjhDMS4zMTU1OSAxNC45OTU0IDEuMTU4NjcgMTUuMDIzMSAxLjAwMTc2IDE1LjAxMzZaIiBmaWxsPSIjNUZDQTQzIi8+Cjwvc3ZnPgo=);
	content: '';
}

._51elbCTuoHVJ {
	min-height: 39px;
}

._2M2fF8Tvx6QX {
	height: 100%;
	min-height: calc(100vh - 56px);
}

._2M2fF8Tvx6QX._3k0LmJLcX6Vg ._1YcwJ4d1d7hO:nth-child(even) {
	background: #393939;
}

._2M2fF8Tvx6QX._3k0LmJLcX6Vg ._1YcwJ4d1d7hO:nth-child(odd) {
	background: #262626;
}
