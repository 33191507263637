._1d6w686ZGP_s {
	min-width: 335px;
}

._2OwkBzTu1y02 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	margin-bottom: 9px;

	border-bottom: 1px dashed var(--gray10);

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 14px;
}

._2OwkBzTu1y02 a {
	display: flex;
	align-items: center;

	color: var(--base);
}

._2OwkBzTu1y02 svg {
	margin-left: 8px;
}

@media screen and (max-width: 600px) {
	._1d6w686ZGP_s {
		width: 100%;
		min-width: 0;
	}
}
