div._3xIpdF3CZBmi {
	position: relative;

	width: 335px;
	height: 112px;

	border-radius: 0;

	box-shadow: none;
}

div._2CTw8aHSZfG4 {
	background: linear-gradient(180deg, #57a4fe 0%, #3290ff 100%);
}

.LeJ9mtuYm3_V {
	color: var(--white);
}

.FFIM2CdFPJiw {
	width: 100%;
	height: 112px;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

div._1ToRDZ2Rm375 {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	min-width: 93px;
	min-height: 88px;

	border-radius: 0;

	box-shadow: none;
}

._3cvaPWrqyXcl {
	position: absolute;
	left: 0;
	top: 0;

	width: 100%;
	height: 112px;

	display: flex;
	justify-content: space-between;
	padding: 12px;
}

._3VRBeAvS_9jq {
	margin: 15px 0 0 12px;
}

._3VV68B9qTTve {
	padding-right: 8px;

	letter-spacing: -3px;

	font-weight: 300;
	font-size: 36px;
	line-height: 54px;
	text-align: right;
}

.KEwGfErj27zd {
	align-self: flex-end;

	margin-left: auto;

	color: #ffffff;

	font-size: 15px;
	line-height: 22px;
}

._2Dm0RsxqLMRl {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-left: 12px;
}

._1ycS_7o_oF54 {
	font-weight: 200;
	font-size: 72px;
	line-height: 76px;

	opacity: 0.7;
}

.c2NLXJdWm6Yv {
	margin-left: auto;
}

._1DdSZtA2-8Qr {
	position: relative;
	top: 7px;

	display: none;
}
