._1SymvMubafZq {
	width: 40px;
	height: 36px;
	padding: 0 2px;

	color: #b0b0b0;
	font-size: 13px;
	line-height: 36px;

	transition: padding 0.1s ease-in-out;
}

@media screen and (max-width: 600px) {
	._1SymvMubafZq {
		font-size: 13px;
	}
}

._2PSuOZqPQGO5 > div,
._1k6djrKQUbR5 > div {
	border-radius: 0;

	background: none;
}

._3wSs9pG3DFHv {
	opacity: 0;

	pointer-events: none;
}

._1jRIN3ijycxK,
._2QV-z1v0Qw9P._1jRIN3ijycxK {
	color: var(--common-blue);
	font-weight: 500;
}

._3sTd3Y7a-bEP:after {
	position: absolute;
	top: 0;

	width: calc(40px / 2);
	height: 36px;

	background: var(--datepicker-day-period-background);
	content: '';
}

._35lSt8j6rpCI {
	font-size: inherit;
}

._MDQMtSMUZvU:before {
	position: absolute;
	top: 0;
	left: 0;

	width: calc(40px / 2);
	height: 36px;

	background: var(--datepicker-day-period-background);
	content: '';
}

._3Dg80sbb0TV9 {
	position: relative;

	background: none;
}

._1SymvMubafZq._3Dg80sbb0TV9 {
	background: none;
}

._3sTd3Y7a-bEP._MDQMtSMUZvU:before {
	display: none;
}

._MDQMtSMUZvU._3sTd3Y7a-bEP:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 1px;
	z-index: 2;

	width: 33.85px;
	height: 33.85px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzNCAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41NzcxNDgiIHk9IjAuNTc3MTQ4IiB3aWR0aD0iMzIuODQ2MSIgaGVpZ2h0PSIzMi44NDYxIiByeD0iMTYuNDIzMSIgc3Ryb2tlPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMSAxOUg0TDIgMTFMMSAxNVYxOVoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMyAxOEgyOS42NjY3TDMxIDI1LjVMMzMgMjJWMThaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) center no-repeat;
	content: '';
}

._3l1kFOCRPZPr {
	height: 36px;

	border: 1px solid transparent;
	border-radius: 50%;
}

._3Dg80sbb0TV9 ._3l1kFOCRPZPr {
	position: relative;
	z-index: 1;

	width: 36px;
	height: 36px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 600;

	background: var(--datepicker-day-selected-background);
}

._1SymvMubafZq._1LV2RcQA2ZHt,
._1SymvMubafZq._2QV-z1v0Qw9P._1LV2RcQA2ZHt {
	position: relative;

	border-radius: 0;

	color: #b0b0b0;

	background: var(--datepicker-day-period-background);
}

._1LV2RcQA2ZHt:last-child:after {
	display: none;
}

._1LV2RcQA2ZHt:first-child:before {
	display: none;
}

._1LV2RcQA2ZHt._34CMlTZ1YUm- div {
	font-weight: 600;
}

/* calendar with prices */
._2wVbaLItm47P {
	display: flex;
	justify-content: center;
	align-items: flex-start;

	width: 44px;
	height: 44px;

	padding-top: 6px;

	border: 1px solid transparent;

	color: #000000;
	font-size: 13px;
	line-height: 16px;

	background: #ffffff;
}

.jIm3173hz_Au {
	display: block;

	margin-top: 1px;
	padding-left: 0 !important;

	color: #808080;

	font-size: 9px;
	font-weight: 400;
}

._3l1kFOCRPZPr:hover {
	box-sizing: border-box;
	width: 36px;
	height: 36px;

	border: 1px solid var(--datepicker-day-selected-background);
}

._2wVbaLItm47P._3Dg80sbb0TV9 {
	padding-top: 13px;

	border-radius: 6px;

	background: var(--common-orange);
}

._2wVbaLItm47P:not(._2PSuOZqPQGO5):not(._3rqvxjErfhD8):not(._3Dg80sbb0TV9):hover {
	border: 1px solid var(--common-orange);
}

._2wVbaLItm47P._3Dg80sbb0TV9 > div {
	color: #ffffff;
}

._1LV2RcQA2ZHt._34CMlTZ1YUm-:not(._3Dg80sbb0TV9) div {
	color: var(--dark-gray);
	font-weight: 600;

	background: none;
}

._2wVbaLItm47P._3Dg80sbb0TV9 .jIm3173hz_Au {
	display: none;
}

._2wVbaLItm47P._1LV2RcQA2ZHt {
	position: relative;
}

._2wVbaLItm47P._3sTd3Y7a-bEP:after,
._2wVbaLItm47P._MDQMtSMUZvU:before {
	display: none;
}

._2wVbaLItm47P._3sTd3Y7a-bEP {
	border-radius: 6px 0 0 6px;
}

._2wVbaLItm47P._MDQMtSMUZvU {
	border-radius: 0 6px 6px 0;
}

._2wVbaLItm47P._MDQMtSMUZvU._1LV2RcQA2ZHt,
._2wVbaLItm47P._3sTd3Y7a-bEP._1LV2RcQA2ZHt {
	border-radius: 0;
}

._2wVbaLItm47P._3sTd3Y7a-bEP._2wVbaLItm47P._MDQMtSMUZvU {
	border-radius: 6px;
}

._2wVbaLItm47P.jIm3173hz_Au {
	margin-top: 1px;
	padding-left: 0;

	color: var(--common-blue);

	font-size: 10px;
	font-weight: 400;
}

div._1k6djrKQUbR5,
div._2PSuOZqPQGO5 {
	border: 1px solid var(--common-orange);

	background: var(--datepicker-day-period-background);
}

._2PSuOZqPQGO5:before,
._1k6djrKQUbR5:after {
	display: none;
}
