._3EWxq26Pdwbq {
	display: flex;
	min-height: 200px;
}

._25dJSKHeBbQe {
	position: relative;

	display: flex;
	flex: 1;
	flex-direction: column;

	color: var(--gray70);
	font-weight: 400;

	background: var(--white);
}

._3EWxq26Pdwbq._3Hz4r1D1jWQB {
	margin-bottom: 12px;
}

._3EWxq26Pdwbq._2O0Ao8rTQTc0 ._25dJSKHeBbQe {
	overflow: hidden;

	font-size: 16px;
	line-height: 1.3;
}

._3EWxq26Pdwbq._2O0Ao8rTQTc0 button {
	height: 33px;
	margin-top: 12px;
	margin-bottom: 12px;

	line-height: 33px;
}

._3EWxq26Pdwbq._3Hz4r1D1jWQB ._25dJSKHeBbQe {
	border-radius: 0 6px 6px 0;

	font-size: 16px;
	line-height: 1.3;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	overflow: hidden;
}

._121bmHBAGaAm {
	width: 357px;
}

._3EWxq26Pdwbq._3Hz4r1D1jWQB ._121bmHBAGaAm,
._3EWxq26Pdwbq._3Hz4r1D1jWQB ._121bmHBAGaAm > div {
	border-radius: 6px 0 0 6px;
}

.Vdbt-h_YLI6_,
.gpudRgqrvI_T,
._3cqevLg3CqeG,
._12C5d58B33MH {
	min-width: 0;
	padding: 0 20px;
}

._1rcKVRUc8pJh {
	display: flex;
	align-items: center;
	min-height: 44px;
}

._1rcKVRUc8pJh:not(:first-child) {
	border-top: 1px solid #e3e3e3;
}

.Vdbt-h_YLI6_ {
	grid-area: status;
}

.gpudRgqrvI_T {
	grid-area: booking;
}

._12C5d58B33MH {
	grid-area: passengers;
}

._2_lYQ9PZNJ7- {
	margin-top: 24px;
	display: flex;
	justify-content: flex-end;
}

._12eJ2iKLAK3r {
	flex: 1;
	padding: 16px 12px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	border-top: 1px solid #e3e3e3;
}

button._3vkLIoD7cQz1,
button._3vkLIoD7cQz1:hover {
	color: var(--base);
	font-weight: 500;

	background: var(--brand1-4);
}

@media (max-width: 768px) {
	._3EWxq26Pdwbq {
		flex-direction: column;
	}

	._121bmHBAGaAm {
		width: 100%;
		height: 200px;
	}

	._3EWxq26Pdwbq._2O0Ao8rTQTc0 ._25dJSKHeBbQe {
		overflow: visible;
	}

	._3EWxq26Pdwbq._2O0Ao8rTQTc0 ._25dJSKHeBbQe:after {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: 10;

		height: 12px;

		border-radius: 12px 12px 0 0;

		transform: translateY(-100%);
		background: #ffffff;
		content: '';
	}

	._3EWxq26Pdwbq._3Hz4r1D1jWQB {
		border-radius: 8px;
	}

	._3EWxq26Pdwbq._3Hz4r1D1jWQB ._25dJSKHeBbQe {
		border-radius: 0 0 8px 8px;
	}

	._3EWxq26Pdwbq._3Hz4r1D1jWQB ._121bmHBAGaAm {
		border-radius: 8px 8px 0 0;
	}
}
