._2tb8eI8OR-ye {
	position: relative;

	display: flex;
	align-items: center;
	min-width: 168px;
	padding: 14px;

	border-radius: 2px;

	color: var(--white);
	font-weight: 500;
	white-space: nowrap;

	background-color: var(--base15);
}

._2tb8eI8OR-ye > svg {
	margin-right: 10px;
}

._1UQEJFHxVu7t {
	position: absolute;
	bottom: 0;
	left: 50%;
	z-index: 10;

	color: var(--gray80);

	transform: translate(-50%, calc(100% + 10px));
}

@media screen and (max-width: 600px) {
	._2tb8eI8OR-ye {
		min-width: 120px;
		padding: 12px 14px;
	}

	._1UQEJFHxVu7t {
		left: auto;
		right: 0;

		transform: translateY(calc(100% + 10px));
	}
}

._3ZCpieTy7bU8 {
	margin: 0;
	padding: 20px 30px;
}

.rLfudkwtpFUB {
	max-width: 36px;
	margin: 2px 0;
}

._2lzHXE33JeLI._1Bhs2b3p_jsb:after {
	left: 2px;

	width: 36px;
	height: 36px;
}

/* stylelint-disable */
._3he_sSQYfqGy {
}

.D44w2eCKgH0_ {
}

div._2TX3aCQ4dLho {
	border-radius: 50%;

	background: #d6e9ff;
}

div._2TX3aCQ4dLho > div {
	color: #292929;
	font-weight: 500;
}
