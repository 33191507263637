

._1_3Q1AlhifEq {
	position: relative;
}

._3RXmGFrbvy6e {
	padding: 12px 1px 0 1px;
	height: 100%;
}

._1qSyXD490d_3._3RXmGFrbvy6e {
	padding-top: 0;
}
