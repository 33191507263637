._3L1eanG77Iw3 {
	position: relative;
}

.kH_XsPodzUjU {
	display: flex;

	cursor: pointer;
}

.aA5jWBTXqR03 {
	font-size: 14px;
	color: #ffffff;

	opacity: 0.7;
}

._3M8M3sxKKyzS {
	display: none;
}

@media screen and (max-width: 600px) {
	._3L1eanG77Iw3 {
		padding-bottom: 8px;

		border-bottom: 1px solid rgba(255, 255, 255, 0.2);

		line-height: 28px;
	}
}
