._3hrW6OShfWxZ {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;

	border-radius: 8px;

	background: var(--white);
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);
}

._1pvgEk6edUTP {
	display: flex;
	width: 100%;
	flex: 1;
}

._3XZAV18ACppn {
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
}

._3hVJUa1h2Oqk {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	margin-left: 12px;
}

._4YN7ADHDTEir {
	margin-bottom: 20px;

	font-weight: 700;
	font-size: 18px;
	line-height: 1.4;
}

._4YN7ADHDTEir h2 {
	position: relative;

	display: flex;
	align-items: center;
	margin: 0;
	padding-left: 28px;

	font-size: 18px;
}

._4YN7ADHDTEir span {
	position: absolute;
	top: 0;
	left: 0;

	flex: 0;
	margin-right: 8px;

	color: var(--success);
}

._3-SVJRqbXBOu {
	flex: 1;
	display: inline-flex;
	justify-content: center;
}

._2kawSA1N7BBj {
	padding-top: 16px;
	flex: 0;
}

._3hVJUa1h2Oqk ._2kawSA1N7BBj {
	display: flex;
	justify-content: flex-end;
	margin-top: auto;
}

._2FVn3Ma9SfMC {
	display: inline-block;

	margin-bottom: 4px;
	padding: 9px 12px;

	border: 1px solid var(--brand1-3);

	border-radius: 100px;

	color: var(--brand1);

	font-size: 14px;
	line-height: 1;

	background: var(--brand1-5);
}

@media screen and (max-width: 600px) {
	._3hrW6OShfWxZ {
		padding: 16px 12px;
	}

	._1pvgEk6edUTP {
		flex-direction: column;
	}

	._3hVJUa1h2Oqk {
		margin: 24px 0;
		width: 100%;
	}
}
