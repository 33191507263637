

._3LmbhW75JxuE {
	display: flex;
	align-items: center;
	margin-top: 10px;

	text-decoration: none;
}

._3LmbhW75JxuE svg {
	margin-right: 5px;

	color: var(--common-blue);
}

.QQz2TWClhoKq {
	cursor: not-allowed;
}

._3LmbhW75JxuE span {
	font-size: 14px;
	line-height: 14px;
}

._1w8d74_fcVqJ {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 5;

	width: 28px;
	height: 28px;

	border: 1px solid transparent;
	border-radius: 50%;

	color: var(--gray50);

	transform: inherit;
	cursor: pointer;
	background: var(--gray05) !important;

	transition: color 0.15s, border 0.15s;
}

._1w8d74_fcVqJ:hover {
	border: 1px solid var(--gray10);

	color: var(--gray80);
}

._1w8d74_fcVqJ:before,
._1w8d74_fcVqJ:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 46%;
	height: 1px;

	background: currentColor;
	transform: translate(-50%, -50%) rotate(45deg);
}

._1w8d74_fcVqJ:after {
	transform: translate(-50%, -50%) rotate(-45deg);
}
