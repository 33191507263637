

.eqqdyESH6E41 {
	color: var(--gray);
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
}

.eqqdyESH6E41 a._3_NQKWjdDV4p {
	color: inherit;
	text-decoration: none;
}

._1a9GW5saVko5,
._26_Z-T5xRU0O {
	display: flex;
	align-items: center;
}

._2dycwwcKvAWv a._3_NQKWjdDV4p,
.jad-XZNP1k4v a._3_NQKWjdDV4p {
	opacity: 0.8;
}

.eqqdyESH6E41 ._1Iv5oJX4JLk3 {
	position: relative;
}

._1a9GW5saVko5 {
	position: relative;
}

._1zUoKAeJwsOi ._1Iv5oJX4JLk3 {
	position: absolute;
	left: -30px;
	top: 50%;

	margin-right: 10px;

	color: var(--selected2);

	transform: scale(0.9) translate(0, -50%);
}

._2ehZf_aLW4KR {
	margin-right: 20px;
	margin-left: 12px;

	opacity: 0.8;
}

div.O4g9oJzJGopq > span {
	transform: scale(0.7);
}

._1t_0RzvofoVl {
	width: 32px;
	height: 32px;
}

a._1Xj5qO1kgCSX {
	position: relative;

	display: inline-flex;
	align-items: center;

	color: #ffffff;
	font-weight: 500;
	font-size: 14px;
	text-decoration: none;
}

._1OIeZ-g9roc4 {
	position: relative;
	top: 7px;

	color: var(--active);
}

._1OIeZ-g9roc4 svg {
	transform: scale(0.7);
}

._1GlpFQj-tJd5 {
	position: absolute;
	left: 100%;
	top: 50%;

	transform: scale(0.8) translate(0, -53%);
}

button._1mB9H9LgIkgp {
	position: absolute;
	top: 20px;
	right: 16px;

	width: 32px;
	min-width: auto;
	height: 32px;
	padding: 0;

	border-radius: 50% !important;
}

button._1mB9H9LgIkgp,
button._1mB9H9LgIkgp:hover {
	color: var(--gray80);

	background: var(--gray05);
}

button._1mB9H9LgIkgp svg {
	transform: scale(0.9);
}
