

._3W0TKCa_EFZT {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1100px;
	min-height: 60px;
	margin: 0 auto;
	margin-bottom: 12px;
	padding: 0 20px;

	color: var(--gray80);
	font-weight: 700;
	font-size: 16px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

._3W0TKCa_EFZT svg {
	margin-right: 9px;
	flex-shrink: 0;
}

._15iq9IBdOylP {
	display: flex;
	align-items: center;
}

button._24CGb4UrQcZv,
button._24CGb4UrQcZv:hover {
	color: #ffffff;

	background: var(--base);
}
