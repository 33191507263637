

div.RoaMo5lN3ZHi {
	padding: 0 0 0 0;
	min-height: 75px;
}

.t9VYxXUWshO2 {
	min-height: 60px;
}

._1tYbGPvCc2YA {
	display: flex;
	align-self: stretch;

	padding: 0 18px;

	border-right: 1px dashed #cccccc;

	font-size: 15px;
	text-transform: uppercase;
}

._2-rqyubLz5sd {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 85px;

	color: var(--gray90);
	font-weight: 700;
	font-size: 15px;
}

._31go6zX4TLNS {
	margin-bottom: 8px;

	text-transform: capitalize;
	white-space: nowrap;
}

._3FwYXgAu_tsK {
	margin-top: 1px;

	color: var(--base);
	font-weight: 500;
	font-size: 13px;
	line-height: 17px;
	text-transform: none;
}

._1usQQAld8MmU {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	align-self: stretch;
	width: 67px;

	border-right: 1px dashed #cccccc;

	font-weight: 700;
	font-size: 15px;
	text-align: center;
}

._2mLcgHGniW_K {
	color: var(--gray50);
	white-space: nowrap;
}

div._3BIat4tvcpCm {
	justify-content: center;
	padding: 0 19px;

	border-left: 1px dashed #cccccc;
}

div._1UdjCRQVIndz {
	padding: 0 20px;
}

._3BIat4tvcpCm > div {
	margin-bottom: 8px;

	color: var(--gray90);
	font-weight: 500;
}

div._3Jnl0xXRBptZ {
	display: none;
}

div._3QXIcJwpa0vW {
	position: relative;

	align-items: center;

	color: var(--gray90) !important;
	line-height: 25px;
	font-size: 25px;
}

._1RyRlMnAtbsC,
div._1uf0-egQscvw ._1RyRlMnAtbsC,
div._2qnjSAzKN6tM ._1RyRlMnAtbsC {
	color: var(--gray80);
	font-weight: 500;
}

.Rb2C9Wyc62BF {
	display: none;
}

span._1vVpMhU4SJou {
	position: absolute;
	top: unset;
	bottom: -20px;

	font-size: 12px;

	color: var(--gray50);
}

div._2hrdb6vlmHDz,
div._3Xg0kCpFaSng {
	bottom: 8px;
}
