._3eWuHcmEAcBi {
	position: relative;

	display: flex;
	align-items: center;
	height: 120px;
	padding: 20px 50% 20px 40px;

	background: var(--seatMap-banner-background);
	background-image: url('https://cdn.websky.aero/content/frontend/images/banner-preselected-service-seats.svg');
	background-repeat: no-repeat;
	background-position: calc(100% + 115px) 20px;
}

._2_hTrtKSvblH {
	background-position: calc(100% + 75px) 20px;
}

@media screen and (max-width: 600px) {
	._3eWuHcmEAcBi {
		padding-left: 20px;
	}
}

._3eWuHcmEAcBi h1 {
	position: relative;
	z-index: 1;

	margin: 0;

	color: #ffffff;
	font-size: 24px;
	font-weight: 400;
	line-height: 130%;
}

._1ZkRfHqoE6jl {
	padding: 24px 40px;

	color: var(--common-gray);
	font-size: 17px;
	line-height: 150%;
}

._2F_tHmtK_iU_ {
	padding: 0 40px;
}

@media screen and (max-width: 600px) {
	._1ZkRfHqoE6jl {
		padding: 24px 20px;
	}

	._2F_tHmtK_iU_ {
		padding: 0 20px;
	}
}

@media screen and (max-width: 320px) {
	._3eWuHcmEAcBi h1 {
		font-size: 20px;
	}

	._1ZkRfHqoE6jl {
		display: none;
	}

	._2F_tHmtK_iU_ {
		padding: 20px 20px 0;
	}
}

._2yL6NMW0U7aP {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 12px;

	border-bottom: 1px solid #e4e4e4;
}

@media screen and (max-width: 600px) {
	._2yL6NMW0U7aP {
		padding-bottom: 0;

		border-bottom: none;
	}
}

._25CR-3KnqZU3 {
	line-height: 48px;
	font-size: 32px;
	font-weight: 500;
}

.WP-QsibzAg2w {
	line-height: 30px;
	font-weight: 500;
	font-size: 20px;
	color: var(--common-blue);
}
