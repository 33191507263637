.OlOjtKBV9fmf {
	padding: 16px;

	color: var(--text-base);
	font-weight: 600;
	font-size: 18px;
}

@media (max-width: 1024px) {
	._2mLtWgNvBig8 {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 28px;
	}
}

@media screen and (max-width: 600px) {
	._2mLtWgNvBig8 {
		grid-template-columns: 1fr;
		padding: 12px;
		grid-gap: 0;
	}
}
