

.hRnNxCiis5aM {
	border-radius: 8px;

	color: #ffffff;

	background: var(--mobileSummaryFlight--background);
}

._3YhbltE6trCW {
	background: linear-gradient(180deg, #6a86e6 0%, #3b56b2 100%);
}

._2xw-_orI-_ye {
	display: flex;
	flex-direction: column;
	padding: 12px 20px 8px;
}

._1POIHO-j0SJR {
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 40px;
	font-weight: 600;
	line-height: 150%;
}

._2Yf6ZzfcWDeA {
	display: flex;
	align-items: center;
	margin: 0 8px;

	transform: rotate(180deg);

	overflow: hidden;
}

._2Yf6ZzfcWDeA:after,
._2Yf6ZzfcWDeA:before {
	content: '...............................';

	max-width: 45%;

	font-size: 24px;
	line-height: 24px;
	letter-spacing: 3px;

	transform: translateY(-6px);
	overflow: hidden;
}

._2Yf6ZzfcWDeA svg {
	margin: 0 4px;
	min-width: 24px;
}

._1_NSBFeCLr2h,
.BzrEEx5C2xFk {
	display: flex;
	justify-content: space-between;

	font-size: 13px;
	line-height: 150%;
}

._1_NSBFeCLr2h {
	margin-bottom: 4px;

	text-decoration: underline;
}

._1_NSBFeCLr2h > span,
.BzrEEx5C2xFk > span {
	max-width: calc(50% - 8px);
}

._1_NSBFeCLr2h > span:last-child,
.BzrEEx5C2xFk > span:last-child {
	text-align: right;
}

._3jpNQYxM5iij {
	display: flex;
	justify-content: space-between;

	font-size: 28px;
	line-height: 150%;
	font-weight: 500;
}

._2XMtQFAnm2Kp {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
}

._2K-IUPVru74d {
	display: flex;
	align-items: center;

	font-size: 14px;
	line-height: 16px;
}

._1a22CNklrk2T {
	display: flex;
	align-items: center;
	margin-right: 6px;
}

._3yq7Jfb0T3vf {
	display: flex;
	align-items: center;
	padding: 10px 20px;

	border-top: 1px dashed rgba(255, 255, 255, 0.4);
	border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
}

.v9Vte0pgaKqJ {
	height: 36px;
	width: 36px;
	margin-right: 18px;

	border-radius: 2px;

	background-position: center;
	background-size: cover;
}

._3cdCnvecOhUM {
	display: flex;
	flex-direction: column;

	font-size: 12px;
	line-height: 150%;
}

._3cdCnvecOhUM ._2UxlCVNxPgJC {
	margin-top: 2px;

	font-size: 16px;
	font-weight: 500;
}

._1rUuxYyXNk_s {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px 12px;
}

._1lICp-U2v6mc {
	display: flex;
}

._2RoLz4WhoOn5 {
	display: flex;
	flex-direction: column;

	font-size: 20px;
	line-height: 150%;
	font-weight: 500;
}

._1TKuFZm8LRSe {
	margin-bottom: 2px;

	font-size: 12px;
	line-height: 150%;
	font-weight: 400;
}

._1lICp-U2v6mc ._2RoLz4WhoOn5:first-child {
	margin-right: 20px;
}
