

._37YN7ZpA9_q7.lfZcstelgTxJ {
	order: 1;

	border-top: 1px solid var(--line-separator);
}

._37YN7ZpA9_q7.lfZcstelgTxJ.sUJIrGfCVs6A {
	color: var(--success-text);
}

._37YN7ZpA9_q7 ._24mFFgp_oEta {
	display: flex;
}

.sUJIrGfCVs6A ._24mFFgp_oEta {
	color: var(--success-icon);
}
