
/* stylelint-disable */
.jUxl8c6nBsQl {
}

._2cuzbgaP2Xhw {
}

/* stylelint-enable */
._2fAAhJzhmIvk {
	height: 30px;

	line-height: 30px;
	text-align: center;
}

.fJD132_8pPNU {
	display: inline-block;
}

._3eiwm--hOORC {
	padding-right: 5px;
}
