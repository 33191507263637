label._3B6J8zkZjTjG {
	color: var(--input-color);
	font-size: 16px;

	font-family: inherit;
}

label._3B6J8zkZjTjG._-59AfXIRHExg {
	color: var(--passengers-input-bg);
}

div._2VAC_zkACDVU:before,
div._2VAC_zkACDVU:after {
	display: none;
}

div._257OExMczkF9 {
	align-items: flex-end;
	padding: 8px 12px;

	font-family: inherit;
}

div._257OExMczkF9:not(._1cK1ciYNg5Ut) {
	padding: 0;

	border: 1px solid var(--input-outline);
	border-radius: var(--input-border-radius);
}

label._3B6J8zkZjTjG + div._257OExMczkF9 {
	margin-top: 0;
}

div._257OExMczkF9._3wgdZSzKSYFU {
	border-color: var(--input-outline-active);
}

div._257OExMczkF9:before {
	content: '';

	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;

	display: block;
	height: 100%;
}

div._3Ln3wvW3OQ-f {
	height: 100%;
	box-sizing: border-box;
	padding: 26px 12px 8px 12px;
}

div._3Ln3wvW3OQ-f:focus {
	background: none;
}

p._2ML4vOzNO71I {
	position: absolute;
	bottom: 0;

	margin-top: 0;

	font-family: inherit;
	letter-spacing: normal;
	line-height: inherit;

	transform: translateY(140%);
}

li._3rSDFneW5GvX {
	font-family: inherit;
}

svg._2L76Fz3l4_QK {
	top: calc(50% - 14px);
	right: 8px;
}

@media (max-width: 1024px) {
	label._3B6J8zkZjTjG {
		max-width: 100%;
		padding-right: 20px;
		overflow: hidden;

		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
