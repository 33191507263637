._1LDSHt4db2Vg {
	margin-bottom: 12px;
	padding: 28px;

	background: #ffffff;

	transition: box-shadow 0.15s;
}

._1LDSHt4db2Vg:hover {
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.07), 0 0 25px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 600px) {
	._1LDSHt4db2Vg {
		padding: 0 20px;

		background: none;
	}

	._1LDSHt4db2Vg:hover {
		box-shadow: none;
	}
}
