

._1qNq9FN3ZGau {
	max-width: 1100px;
	margin: 0 auto;

	border-radius: 4px;

	color: #ffffff;

	background: var(--mobileSummaryFlight--background);
}

._1qNq9FN3ZGau._2leopFFUjHpU {
	background: linear-gradient(180deg, #6a86e6 0%, #3b56b2 100%);
}

._3CtSOa2pvsov {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 24px 15px 20px;

	border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
}

._1GJ85Q4rj9Ov {
	display: flex;
	align-items: center;

	font-size: 24px;
	font-weight: 500;
	line-height: 33px;
}

._2eLoz8KeEXOg:not(:last-child) {
	padding-right: 20px;
	margin-right: 20px;

	border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.HQKo-qmH8nFB {
	display: flex;
	align-items: center;
	margin-right: 12px;

	transform: rotate(180deg);
}

._1O6f5qbtO9Sp {
	display: flex;
	align-items: center;

	font-size: 16px;
	font-weight: 500;
}

._1L_Hi492D00P {
	display: flex;
	height: 28px;
	width: 28px;
	margin-right: 12px;

	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

._1_CALUtOL0cO {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 20px 21px;
}

._1n8T1uHNQ0Yr {
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
}

.BBMKD9dsGA95:not(:last-child) {
	margin-right: 20px;
	padding-right: 20px;

	border-right: 1px solid rgba(255, 255, 255, 0.5);
}
