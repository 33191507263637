.OwB7nLDrmvsR {
	padding: 20px 40px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

._3tOk3-3gGPSW {
	margin-bottom: 24px;

	color: var(--gray80);
	font-weight: 600;
	font-size: 24px;
}

._2uhMqDlJTO0D {
	display: flex;
}

div._2SqSoXMAkmtd {
	flex: 1;
	margin-bottom: 35px;
}

._2uhMqDlJTO0D ._2SqSoXMAkmtd:not(:last-child) {
	margin-right: 40px;
}

._34qnjy2MgnWa {
	margin-top: 28px;
	display: flex;
	justify-content: space-between;
}

button._3sM1bYworUqQ,
button._3sM1bYworUqQ:hover {
	color: #ffffff;

	background: var(--active);
}

span._3yRotBFkthjz {
	color: #9a9a9a;
}

span._3yRotBFkthjz._39DBFGwckup2 {
	color: var(--base);
}

@media screen and (max-width: 600px) {
	._3tOk3-3gGPSW {
		display: none;
	}

	._2uhMqDlJTO0D {
		flex-direction: column;
	}

	._2SqSoXMAkmtd {
		margin-right: 0;
	}

	.OwB7nLDrmvsR {
		padding: 20px;
	}

	._34qnjy2MgnWa {
		flex-direction: column;
	}
}
