

._2mcuGjDICiNt {
	width: 28px;
	height: 28px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50%;
	border: 1px solid transparent;

	color: var(--gray50);

	background-color: #ffffff;

	cursor: pointer;

	transition: color 0.15s, border-color 0.15s;
}

._2mcuGjDICiNt:hover {
	border-color: var(--gray10);

	color: var(--gray80);
}

._27GLVlmhBRUH {
	background-color: var(--gray05);
}

._2tAWCcgsNG4x {
	background-color: var(--line-separator);
	cursor: auto;
	cursor: initial;
}

.sl7SPk4hAs9d {
	width: 40px;
	height: 40px;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKKSURBVHgBtZZLaxNRGIa/ialak9pag9p6KwUrgqI7K7pQoeBC0IULXQguBUE3/gD/gVtdCC5diRdciAtRXChSWy/tLohSJQqi0dJom2R83pwTiZGYTDvzwsMJme9877nNnC+wDhSG4SaaERiCflgFy2AePkMBpoMgyLfLFbQx2kEzCn2QhpQ3Svu2+fc3uI/xs0iGGGVpjsB6b1KGlzADRRIWfdxKmkHYCbsh5wfwFS4T96WtIUm2+Vl1wxw8pOMH60D03UtzFNbBT7hO34mWhnTYTHPY3PJMwwQdflkEkUMDPQZj5lbnGjme1J+nGgIzNPugCi8IehrVrDaDICjBDX7e9flPkzv3jyE6ZH5mdJi0JYoct2geQA9c/MuQEWyl0QH4Aa8sPt0EHZxhPA7+MUTbzS3la0a2YDGJXDp0V8yt3KmaIc5rzc+OgHcWs8g5Ze7EbsBrl2aod60CHR39ReqeuVmOyjDjDQuWnN6Y2749jYbfLTl9gi4YkKFe/jJrXbaERG4ZyqtX3z2dztCSl7xqrjpBFU7QCktI/jLQoSnJsGTuNlhuyWnEG+ZlqKtEy9pjyUmfzXSjoU5pzpJT/QZ6lOIEzZq790J/Y8Qqch6nUYlSwGu8/i3VS69lHSAgZTGJXNqmc+Zmd1X/1ZLjrFpkzj/ot/h0HraYm93t5tF0wbCnz5YoclyAPIzDxlZBWRhSqQGrIYjoY77fJXgPb2GsXYduGATtZy7KB4HY/fAcPsIUnGiOaVUmai/X+Od6ZXQpq+hdYC+qDXGqFLRHB+AsZH28qoYzxM5YFGl20OuXKaM61O91AFWYhxLMQhEm4eT/cna0R37GIvRUPKpXVN4/hjuN5WAr/QakWztb10DkIQAAAABJRU5ErkJggg==) center no-repeat;
	background-size: 60%;

	animation: _2kww0KaabVuy 0.9s infinite linear;

	pointer-events: none;
}

@keyframes _2kww0KaabVuy {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
