

._30f5xeGsA8ub {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;

	height: 48px;
	padding-left: 20px;

	border-bottom: 1px solid #d9d9d9;

	color: #1f1f1f;
	font-weight: 400;

	font-size: 20px;
	line-height: 48px;
	text-align: left;

	background: #ededed;
}

._2wmaK5YgGbZv {
	position: absolute;
	top: 0;
	right: 17px;
	bottom: 0;

	display: flex;
	width: 14px;
	height: 14px;
	margin: auto;

	color: #a5a5a5;

	cursor: pointer;
	content: '';
}

._2AwxaSd0zsmy {
	margin-top: 48px;
	max-height: 100%;
	overflow: auto;
	padding: 20px;

	height: 100%;
}

/* stylelint-disable */
div._2GIljuvbqN_s {
	padding-right: 0 !important; /* for .mui-fixes */
}

/* stylelint-enable */
