

._1BIWWA2li53h {
	margin-right: auto;
}

.l4qOuIppmNxK._1D3CbvmaZ--Y {
	background: #ffffff;
}

div button._3cOfI-jXol7W {
	border: 1px solid #e5e5e5;
}

button._3cOfI-jXol7W:disabled {
	color: #b3b3b3;

	opacity: inherit;
}
