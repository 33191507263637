._34ho_jX4BnAL {

	font-size: 12px;
}

._2R3M0gBP9jrT {
	cursor: default;
}

._2R3M0gBP9jrT span,
._34Aw-OzMmbOU span {
	border-bottom: 0;

	color: var(--light-gray);
	font-weight: 400;
}

._2R3M0gBP9jrT:nth-child(6n) span,
._2R3M0gBP9jrT:nth-child(7n) span,
._34Aw-OzMmbOU:nth-child(6n) span,
._34Aw-OzMmbOU:nth-child(7n) span {
	font-weight: 400;
}

._34Aw-OzMmbOU {
	width: 44px;
	height: 44px;

	cursor: default;
}
