

._36nHQt8aCngI {
	position: relative;

	margin-top: -8px;

	border-radius: 8px 8px 0 0;

	background: #f0f0f0;

	overflow: hidden;
}

._YHByT6J6uV5 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 20px;

	border-radius: 8px 8px 0 0;
	border-bottom: 1px dashed #bababa;

	background: #f0f0f0;
}

._3LU46XLO7nCO {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	font-size: 15px;
	line-height: 150%;
	color: #727272;
}

button._7rftYGAhPfMm {
	min-width: 120px;
}

._3XaX1IzaC19R {
	border-radius: 8px 8px 0 0;

	background: #ffffff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);

	overflow: hidden;
}

._1pAyGapyRI45 {
	display: flex;
	width: 100%;
}

._1pAyGapyRI45:not(:last-child) {
	border-bottom: 1px dashed #e4e4e4;
}

._1pAyGapyRI45 .uyKwo2mnL_Wq {
	width: 100%;

	background: transparent;

	box-shadow: none;
}

._1pAyGapyRI45 div._2rhTxZxj9N4n {
	padding: 20px;
	min-height: auto;

	font-size: 14px;
	font-weight: 500;
	line-height: 150%;
}

._1pAyGapyRI45 ._2rhTxZxj9N4n > div {
	margin: 0;
	max-width: 100%;
}

._2ALr2i4Bfrxt {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 12px;
}

div._3wO8X-6crBTV {
	padding: 0;
}

._25NeK-Rd1YkL {
	max-width: 100%;
	overflow: hidden;

	text-overflow: ellipsis;
	white-space: nowrap;
}

._1pAyGapyRI45 ._1hdEPF8sigBq {
	display: flex;
	justify-content: center;
	padding: 0;
}

._3AbMIGnamAMS {
	width: 100%;
	margin: 0 20px 20px;

	border-radius: 4px;
}

._3XaX1IzaC19R ._3AbMIGnamAMS > div {
	background: #f0f0f0;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

._1jWbb0WFH60D {
	display: flex;
}

._1jWbb0WFH60D ._3AbMIGnamAMS {
	margin-bottom: 12px;

	background: #ffffff;
}

._1RWwqZOrwmSv {
	display: flex;
	align-items: center;
	padding: 12px 20px 20px;
	margin-bottom: -8px;

	color: #ffffff;
	font-size: 14px;
	font-weight: 500;

	background: #c21212;
}

._3_vW9uA5GP_A {
	margin-right: 16px;
	height: 24px;
	width: 24px;
}
