.MaOUNRLokxgG {
	position: relative;

	flex-grow: 1;
	margin-right: 4px;
}

._2H6SAAyp90mH {
	flex-basis: 230px;
	flex-grow: 0;
}

@media (max-width: 1024px) {
	._2H6SAAyp90mH {
		flex-basis: auto;
	}
}

@media screen and (max-width: 600px) {
	.MaOUNRLokxgG {
		width: 100%;
		margin-right: 0;
		margin-top: 6px;
	}

	._2H6SAAyp90mH {
		margin-top: 24px;
	}
}

.zSXNC_tXmhLy {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;

	cursor: pointer;
	overflow: hidden;
}

.uWmCzHdKXijl {
}

._2mdQTUEXGrKB {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: calc(12px * 2);
	height: calc(12px * 2);

	color: #ffffff;

	cursor: pointer;

	transition: transform 0.15s;

	pointer-events: none;
}

._2apsX6kMm9_W {
	transform: rotate(180deg);
}

._2apsX6kMm9_W svg path {
	fill: var(--light-gray);
}

@media screen and (max-width: 600px) {
	._2mdQTUEXGrKB {
		display: none;
	}
}

._16ZvUHqaT1Qr {
	white-space: nowrap;
	overflow: hidden;

	text-overflow: ellipsis;

	pointer-events: none;
}

@media screen and (max-width: 600px) {
	.zSXNC_tXmhLy {
		width: 100%;
		padding-left: 8px;
	}
}

@media screen and (max-width: 600px) {
	._16ZvUHqaT1Qr {
		padding-left: 36px;
	}
}

._1Gu5mTq76o9z {
	position: absolute;
	z-index: 3;

	width: 100%;

	min-width: 230px;
	margin-top: 4px;

	padding: 16px;

	border-radius: 2px;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);

	background-color: #ffffff;
}

._1riqbJBhVhLQ {
	padding: 0;
	margin: 0;
}

.mdmBNrUDeB8A {
	display: flex;
	justify-content: space-between;

	list-style-type: none;
}

.mdmBNrUDeB8A:not(:first-of-type) {
	padding-top: 12px;
}

.mdmBNrUDeB8A:not(:last-of-type) {
	padding-bottom: 12px;

	border-bottom: 1px solid #d9d9d9;
}

._2K90m4SWcVKV {
	display: flex;
	align-items: center;
}

._3crTy7jKPDQh {
	position: relative;

	display: block;
	margin-right: 10px;
}

._1Ny0RjB-URQ1 {
	margin-left: 4px;
	display: flex;
	align-items: center;
}

._3aCSMo4ea6ro {
	color: var(--dark-gray);
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
}

._3QTgNIT15VHG {
	display: inline-flex;
	align-items: center;

	color: var(--light-gray);

	font-weight: 500;
	font-size: 13px;
	line-height: 20px;

	transition: color 0.15s;
}

._3QTgNIT15VHG:hover {
	color: var(--common-gray);

	cursor: help;
}

._3QTgNIT15VHG svg path {
	transition: fill 0.15s;
}

._3QTgNIT15VHG:hover ._1Ny0RjB-URQ1 path {
	fill: var(--common-gray);
}

.TUYp104RMfcU,
._3jq__yOuWV7m {
	display: flex;
	justify-content: center;
	align-items: center;

	width: calc(12px * 2);
	height: calc(12px * 2);
	padding: 0;

	border: none;

	border-radius: 50%;

	cursor: pointer;

	background-color: var(--common-blue);

	transition: background-color 0.15s;

	-webkit-appearance: none;

	        appearance: none;
}

.TUYp104RMfcU svg,
._3jq__yOuWV7m svg {
	fill: #ffffff;
}

.TUYp104RMfcU:hover,
._3jq__yOuWV7m:hover {
	background-color: var(--glassy-common-blue);
}

.TUYp104RMfcU:disabled,
._3jq__yOuWV7m:disabled {
	background-color: #ededed;
	cursor: default;
}

.TUYp104RMfcU:disabled svg,
._3jq__yOuWV7m:disabled svg {
	fill: #cdcdcd;
}

.TFeYK6h8Qwo0 {
	width: 38px;

	color: var(--common-blue);

	font-weight: 500;
	font-size: 18px;
	text-align: center;

	transition: color 0.15s;
}

._3Uu0YbNAFX8M {
	color: #cdcdcd;
}

._1Wy6LeSCyXL3 {
	font-size: 16px;
}

._1Wy6LeSCyXL3 ._3aCSMo4ea6ro {
	font-size: 16px;
}

._1Wy6LeSCyXL3 .TUYp104RMfcU,
._1Wy6LeSCyXL3 ._3jq__yOuWV7m {
	width: 32px;
	height: 32px;
}

._1Wy6LeSCyXL3 ._3crTy7jKPDQh {
	flex-basis: 45%;
}

._1Wy6LeSCyXL3 ._3QTgNIT15VHG:hover {
	color: var(--light-gray);

	cursor: text;
}

._1Wy6LeSCyXL3 ._3QTgNIT15VHG:hover ._1Ny0RjB-URQ1 path {
	cursor: text;

	fill: #cdcdcd;
}

._1Wy6LeSCyXL3 ._1Ny0RjB-URQ1 {
	position: absolute;
	top: 50%;
	right: 0;

	margin-left: 0;

	transform: translateY(-50%);
}

.-LN4sOv4eYZu {
	margin: 0 0 20px 0;

	color: #9a9a9a;
	font-weight: 500;
	font-size: 20px;
}

._1ZtoMR9Ov3dn {
	position: absolute;

	display: flex;
	align-items: center;
}

@media screen and (max-width: 600px) {
	.xr69SbOWtk1H ._1ZtoMR9Ov3dn {
		display: none;
	}

	.xr69SbOWtk1H ._16ZvUHqaT1Qr {
		padding: 0;
		width: 100%;

		text-align: center;
		font-size: 16px;
		font-weight: 600;
	}

	.xr69SbOWtk1H .zSXNC_tXmhLy {
		padding: 0;

		height: 56px;
	}
}
