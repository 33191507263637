

button._3ygicnuc3GdF {
	min-width: auto;
	height: 34px;
	min-height: 34px;
	margin-right: 8px;
	padding: 0 13px;
}

button._3ygicnuc3GdF:last-child {
	margin-right: 0;
}

button._3ygicnuc3GdF._3e28Q3aCkhCF {
	border-color: var(--brand-1-3);

	color: var(--text-link);

	background: var(--white);
}
