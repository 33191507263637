

._37eUMxk_sUjm {
	position: absolute;
	left: 20px;
	top: 16px;
}

._37eUMxk_sUjm._1-kgPMgai3ZQ {
	color: var(--error);
}

._37eUMxk_sUjm._2v9cpl_w_dL7 {
	color: var(--gray50);
}

._3OzU8qVZIKWx {
	position: relative;

	max-width: 1100px;
	margin: 12px auto;
	padding: 17px 20px 17px 50px;

	border: 1px solid var(--warning);

	color: var(--gray80);

	background: var(--warning20);
}

._2Y7TBGFZHmJ9 {
	display: flex;
	margin: 0;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
}

.HAR8B9VBxNr8 {
	margin: 0;

	font-size: 15px;
	line-height: 19px;
	white-space: pre-wrap;
}
