

._3_OCMvUEcb1M {
	font-weight: 500;
	font-size: 21px;
}

._2BIOPDv6sMaV {
	padding: 40px;
	width: 500px;
}

button._3vNCCMURLJkg {
	width: 120px;
	height: 41px;
}

._16C6OVv-cLaS {
	justify-content: flex-start;
}

._2NhHfb-N5KWr {
	margin-top: 28px;
	display: flex;
	justify-content: space-between;
}

button._1uIUFi9swstA {
	padding: 0;
}

.HhCRd-SANqP9 {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 1;
}

.HhCRd-SANqP9 > div {
	background-color: var(--gray05);
}
