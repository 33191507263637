

label._1Rc-pkYA2CNW {
	font-weight: 500;
	color: var(--gray80);
}

fieldset._1nty1xpPHfy4 {
	display: block;
}

label._1Rc-pkYA2CNW._3jr2FQlnGeb- {
	color: var(--gray80);
}

._3l2lqan3Rv8l ._1IewkWGzY8Nc {
	padding: 20px 0;

	border-bottom: 1px solid var(--gray10);
}

._3l2lqan3Rv8l ._1IewkWGzY8Nc:last-of-type {
	border-bottom: none;
}
