

.XbixSrWu5Oc8 {
	display: flex;
	flex-direction: column;
}

._3KuENTArSJ01 {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	padding-bottom: 16px;

	border-bottom: 1px dashed #d9d9d9;
}

._3KuENTArSJ01:last-child {
	margin-bottom: 10px;
}

._3yYDb0n0n9-f {
	flex-shrink: 0;
	width: 44px;
	height: 44px;
	margin-right: 8px;
}

.ZJrKigh_XiMK {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;
	margin-right: 16px;

	border-radius: 50%;

	color: var(--white);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
	text-align: center;

	background-color: var(--success);
}

._1ansktbp8oek {
	color: var(--text);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.pLvEUZBGlf0z {
	color: var(--success-20);
}
