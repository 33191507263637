

._1NaMiksM0fnp {
	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
}

._1NaMiksM0fnp button._39dyCR9fZ_ly {
	display: flex;
	align-items: center;

	color: var(--text-base);

	box-shadow: var(--shdw-card);
	background: var(--bg-white);
}

._1NaMiksM0fnp button._39dyCR9fZ_ly:active,
._1NaMiksM0fnp button._39dyCR9fZ_ly:hover {
	background: var(--bg-fill);
}

._1NaMiksM0fnp button._39dyCR9fZ_ly._39YdOhgMYsBq {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

.tpikyESMA3-p {
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
}

._1TzAW1e3wneb {
	display: flex;
	align-items: center;
}

._1TzAW1e3wneb > svg {
	width: 16px;
	height: 16px;
}

div._1aBpTC5eoQPa {
	overflow: visible;

	margin-top: 8px;
}

._34tROs5WYkbV {
	display: flex;
	flex-direction: column;
	max-width: 520px;
	padding: 28px;

	border-radius: 4px;

	box-shadow: var(--swdw-dropdown);
}

._2W2WNZCcBnbN {
	display: grid;
	grid-gap: 16px 12px;
	gap: 16px 12px;
	grid-template:
		'airlineCode flightNum'
		'beginPeriod endPeriod'
		'startWeekDays endWeekDays';
}

div._2IclKhA2A5XL {
	flex: 0 1 48.5%;
	margin-bottom: 16px;
}

._1QaFo6OqY7vP input,
div._2IclKhA2A5XL.HCSmyiYSBoei input {
	border: 0 !important;
}

._1URu0OgHXNDP {
	grid-area: startWeekDays / startWeekDays / endWeekDays / endWeekDays;
}

button.l_Hex27aS8OX {
	align-self: end;

	min-width: 140px;
	padding: 10px 24px;

	border-radius: 4px;

	color: var(--bg-white);
	font-weight: 700;
	font-size: 14px;

	background: var(--brand-3-0);
}

button.l_Hex27aS8OX:hover,
button.l_Hex27aS8OX:active {
	padding: 10px 24px;

	border-radius: 4px;

	color: var(--bg-white);
	font-weight: 700;
	font-size: 14px;

	background: var(--brand-3-1);
}

button.l_Hex27aS8OX:disabled {
	background: var(--brand-3-3);
}
