

button._3sAZYHlOd1hz,
button._3sAZYHlOd1hz:hover,
button._2mk2BJVE8_hE,
button._2mk2BJVE8_hE:hover {
	background-color: var(--brand-3-0);
}

button.Qz9uf2YqL6Og,
button.Qz9uf2YqL6Og:hover {
	margin-right: 12px;

	color: var(--text-light);

	background-color: var(--bg-fill);
}

._2srC8QJLBPlS {
	margin-top: 32px;
	display: flex;
	justify-content: flex-end;
}

._3HLVQSsl1xXX {
	margin-bottom: 10px;

	font-weight: 700;
}
