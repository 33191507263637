

._3HyS8opdr_PZ {
	padding: 44px 48px;
}

._2izaeaCp0boh {
	color: #1d1d1d;
	font-size: 28px;
	font-weight: 600;
}

._1WXQWw-AWDm6 {
	display: flex;
	justify-content: flex-end;
	margin-top: 40px;
}

._1mZFsW48fW-m {
	margin-top: 28px;

	color: #595959;
	font-size: 17px;

	white-space: break-spaces;
}

._2p1RRWM1kESA > div {
	margin-top: 20px;
}

._3ouPY5_RWF32 {
	width: 100%;
}

._2wBqrt8yxM0P {
	margin: 15px 0;
}
