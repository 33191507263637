

._1sV-7WCi1V1l {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 28px 40px;

	border-bottom: 1px solid var(--light-gray);

	font-size: 24px;
	font-weight: 500;

	background: var(--half-transparent-gray);
}

._3Cu_4yqB790t {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

._1LLn5-4Uqqy8 {
	font-weight: 600;
}

.CYOy8wY56bXM {
	display: flex;
	flex-direction: column;

	padding: 40px 40px 0;
}

._3k2YBzyALz0K {
	display: flex;
	flex-direction: row;

	box-sizing: content-box;
	padding: 20px 0;

	border-bottom: 1px solid #d9d9d9;

	font-weight: 400;
}

.CbF-pRvtmqk0 {
	display: flex;
	margin-left: 20px;
}

.CbF-pRvtmqk0 > div {
	font-family: inherit;
}

._1p7LhtBnJg5M {
	font-weight: 500;
}

.T5YzQo_YsqAk {
	font-weight: 500;
	font-size: 15px;

	color: var(--common-gray);
}

.T5YzQo_YsqAk,
._3UPVcuPP4Va7,
._1p7LhtBnJg5M {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	font-size: 17px;
	font-weight: 500;
}

._3UPVcuPP4Va7 {
	margin-left: 20px;
}

._31C6EwHUeJ5F {
	height: 24px;
	margin-left: 10px;
}

._31C6EwHUeJ5F._2VszTlIpSqei {
	transform: rotate(45deg);

	fill: #dadada;
}

._31C6EwHUeJ5F._2Zzul5UHDm-x {
	fill: #ffffff;
}

._2LxCy35hun1E {
	margin-left: 3px;

	transform: rotate(90deg) scale(0.7) translate(0, 1px);

	fill: var(--common-blue);
}

button._24lTroHP6hSr {
	justify-content: flex-end;
	padding-right: 10px;
	margin-left: auto;

	max-width: 195px;

	border-radius: 24px;

	line-height: 19px;

	font-weight: 400;
}

/* loading button */
div._24lTroHP6hSr {
	margin-left: auto;

	background-color: var(--cabinet-action);
}

._2AS4B0-4-u7o {
	max-width: 680px;
}

._1M7skszWTPiE:before {
	display: none;
}

._1S_XCjC_RoMV {
	display: flex;
	align-items: center;
}

._3axSxj8R_l7W {
	color: var(--common-blue);
	font-size: 13px;
	line-height: 20px;
	font-weight: 400;
}

._3OPVZ4VC2unZ {
	display: flex;
	align-items: center;
	padding: 0 0 0 20px;
}

._2W1c3W-dywaj {
	padding: 6px;

	line-height: 20px;
}

._2W1c3W-dywaj svg {
	width: 17px;
	height: 17px;
}

._2W1c3W-dywaj path:nth-child(2) {
	fill: var(--common-blue);
}

._2zjnSkhCfv5P {
	position: relative;

	padding: 0 0 0 20px;
	margin-left: 20px;

	font-size: 24px;

	font-weight: 600;
}

._2zjnSkhCfv5P:before {
	position: absolute;

	top: 50%;
	left: -1px;

	content: '';

	height: 120%;
	width: 1px;

	transform: translate(0, -50%);

	background: var(--light-gray);
}

._1tJDEq_0OBw4 {
	position: relative;
	left: 4px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 17px;
}

._3nw3WjE-ZtgW {
	color: #ffffff;
}

svg._1XgOcIjQ1F3_._3VCUPRWzKqrJ {
	display: none;
}
