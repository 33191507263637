

._2eaU4p48eWc0 {
	padding: 32px;

	border: none;
	border-radius: 0;

	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);
}

._3jcwZBXOBSTH {
	margin-bottom: 16px;

	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
}

._1fikGRuz4yyM {
	color: #262626;
}

._2WLaHAC5m-Dm {
	color: var(--gray30);
}

.a41LkzIoO0sP {
	left: 32px;

	color: transparent;
}

.a41LkzIoO0sP:before {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 40%;
	height: 40%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjQxIDEuNDFMNiAwTDAgNkw2IDEyTDcuNDEgMTAuNTlMMi44MyA2TDcuNDEgMS40MVoiIGZpbGw9IiM4MDgwODAiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;
	transform: translate(-50%, -50%);
}

._1eUCfAkvtTgQ {
	right: 32px;

	color: transparent;
}

._1eUCfAkvtTgQ:before {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 40%;
	height: 40%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjk5OTg0IDBMMC41ODk4NDQgMS40MUw1LjE2OTg0IDZMMC41ODk4NDQgMTAuNTlMMS45OTk4NCAxMkw3Ljk5OTg0IDZMMS45OTk4NCAwWiIgZmlsbD0iIzgwODA4MCIvPgo8L3N2Zz4K) no-repeat 50% 50% / contain;
	transform: translate(-50%, -50%);
}

.sVJrfoMNWM8r {
	width: 36px;
	height: 36px;
	margin: 0 4px;
}

.sVJrfoMNWM8r span {
	border: none;

	color: var(--gray30);
	font-weight: 500;
	font-size: 16px;
	line-height: 1;
}

._3W_3NFNHhc4p {
	width: 36px;
	height: 36px;
	margin: 4px;

	border-radius: 50%;
}

._3W_3NFNHhc4p ._1sjhjQd8GMyO {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;

	color: var(--gray30);
	font-weight: 500;
	font-size: 16px;
}

._3W_3NFNHhc4p._3X7aU73U3ipY {
	background: var(--base);
}

._3W_3NFNHhc4p._2Gknm60xUtsW ._1sjhjQd8GMyO {
	color: #262626;
}

._3W_3NFNHhc4p._3X7aU73U3ipY ._1sjhjQd8GMyO {
	color: var(--white);
}

._3W_3NFNHhc4p._3jl36GCyS8RY {
	pointer-events: none;
}

._3W_3NFNHhc4p._3jl36GCyS8RY ._1sjhjQd8GMyO {
	color: var(--gray30);
}

._3W_3NFNHhc4p._1IuKMltaSUv8 {
	position: relative;
}

._3W_3NFNHhc4p._1IuKMltaSUv8:before {
	content: '';

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	border: 1px solid #c2c2c2;
	border-radius: 50%;
}
