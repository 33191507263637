._2qOgoUVk3NCy {
	display: flex;
	align-items: center;
	margin-bottom: 28px;

	line-height: 130%;
}

._2qOgoUVk3NCy._1QiYF92AOT-G {
	margin-bottom: 0;

	opacity: 0.4;
}

._2qOgoUVk3NCy svg {
	margin-left: 8px;
	flex-shrink: 0;
}

._32a5XgJ2cMOw {
	flex-shrink: 0;
	width: 40px;
	height: 40px;
	margin-right: 16px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 600;
	font-size: 18px;
	line-height: 40px;
	text-align: center;

	background: var(--brand-1-0);
}

._3tKXzUsLJabr {
	color: var(--text-mid);
	font-size: 16px;
	line-height: 130%;
	font-weight: 400;
}

._23RiIRSrMhdf {
	color: var(--text-base);
	font-weight: 600;
	font-size: 22px;
}

@media screen and (max-width: 600px) {
	._2qOgoUVk3NCy {
		padding: 16px;

		border: 1px dashed var(--line-dot-line);
		border-radius: 16px;
	}

	._23RiIRSrMhdf {
		font-size: 18px;
	}

	._2qOgoUVk3NCy._3vz5iPRyq1-F {
		align-items: flex-start;

		border: none;

		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
		background: #ffffff;
	}

	._2qOgoUVk3NCy._3vz5iPRyq1-F ._32a5XgJ2cMOw {
		background: var(--success);
	}

	._2qOgoUVk3NCy._3vz5iPRyq1-F svg {
		display: none;
	}
}
