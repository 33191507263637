

._1gFbV5BN7gqd div._26qiItsN11fh._3dBe9cBL5ISu {
	height: 48px;

	border: 1px solid transparent;

	background: var(--brand-1-5);
}

._1gFbV5BN7gqd fieldset._28yGz5cJfZBo,
._1gFbV5BN7gqd div._26qiItsN11fh._3dBe9cBL5ISu ._3XDKDRbgZKeH,
._1gFbV5BN7gqd div._26qiItsN11fh._31wvAljCN8WE ._28yGz5cJfZBo {
	border: none;
}

._1gFbV5BN7gqd div._26qiItsN11fh._24U_igmwLxz8 ._28yGz5cJfZBo,
._1gFbV5BN7gqd div._26qiItsN11fh._24U_igmwLxz8._3dBe9cBL5ISu ._3XDKDRbgZKeH {
	border: 1px solid var(--brand-1-1);
}

._1gFbV5BN7gqd input._3XDKDRbgZKeH,
._1gFbV5BN7gqd div._3XDKDRbgZKeH {
	height: 48px;

	border: none;
}
