

.CfwfaNXgNO7b {
	display: flex;
	align-items: flex-start;

	font-family: monospace;
}

.CfwfaNXgNO7b.nMvo8W7AFZ9q {
	padding-bottom: 20px;
}

.qJvcm39fOpNU {
	flex: 1;
}

._1cdUgyYyw2j2 {
	padding-left: 12px;

	color: var(--success);
	font-size: 16px;
}

._1cdUgyYyw2j2 span {
	padding: 0 8px;
	display: inline-block;
}

._18DfpjmzqD88 {
	margin-top: 4px;

	color: #ffffff;
}

.PrszlNZruoSH span {
	padding: 0 4px;
}

._18DfpjmzqD88 span {
	white-space: pre-wrap;
}

._2vRI0CRCzeMr span {
	flex: 1;
	height: 1px;

	background: var(--white);
}

._18DfpjmzqD88._29VbINRmFZpF span {
	background: #ae0000;
}

._1DUTsFdm5GPg {
	display: grid;
	grid-column-gap: 10px;
	grid-template-columns: 1fr 1fr 1fr;

	color: #ffffff;
}

._51raOdNwfIIO {
	color: var(--success);
}

._2vRI0CRCzeMr {
	position: absolute;
	left: 20px;
	right: 20px;
	bottom: 2px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.jh4MDYOGcHci {
	margin: 0 10px;
}
