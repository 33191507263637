

button._3-OvdaSeUNox {
	position: relative;

	padding: 0 16px;
	height: 41px;
	min-width: unset;
	display: flex;
	align-items: center;

	border-radius: 100px;

	font-size: 16px;
	font-weight: 500;
	line-height: 120%;
	color: var(--gray70);
	text-transform: none;
	letter-spacing: normal;
	white-space: nowrap;

	background-color: var(--gray05);

	transition: all 300ms;
}

button._3-OvdaSeUNox._2tMXedJvrCs- {
	color: #ffffff;

	background-color: var(--base);
}

button._3-OvdaSeUNox:not(:last-child) {
	margin-right: 12px;
}

button._3-OvdaSeUNox._2sNwdjorBa3s {
	opacity: 0.5;

	cursor: not-allowed;
}

.RE_7vLSXeYGf {
	display: flex;
	align-items: center;
	margin: 0 6px;

	color: #b3b3b3;
}

._2tMXedJvrCs- .RE_7vLSXeYGf {
	color: #ffffff;
}

.PX-RCGN-K-cz {
	position: absolute;
	left: 50%;
	top: 0;
	z-index: 1;

	transform: translate(-50%, -50%);
}
