

._1_XrcA13Et86 {
	margin-top: 20px;
	padding-top: 12px;

	border-top: 2px solid var(--gray90);
}

._15pPyqCSCLri + ._15pPyqCSCLri {
	margin-top: 12px;
	padding-top: 12px;

	border-top: 1px solid var(--gray20);
}

._1Bj-PlngBEBT {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;

	color: var(--gray80);
	text-align: right;
}

._1Bj-PlngBEBT:not(:first-child) {
	margin-top: 12px;
}

.jrCN7izZs1rg {
	display: inline-block;
	flex: 1;
	margin-right: 12px;

	font-size: 15px;
	line-height: 22px;
	font-weight: 400;
}

._5juHmiK1TGuK {
	flex: 1 0 95px;
	max-width: 95px;

	font-weight: 700;
	font-size: 22px;
	line-height: 22px;
	white-space: nowrap;
}
