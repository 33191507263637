

button._2gbi24Zr57pU {
	display: flex;
	justify-content: space-between;
	width: 100%;
	min-width: 260px;
	padding: 16px 20px;

	border-left: 1px solid #d9d9d9;
}

button.eUNezlUHNqy3 {
	position: relative;

	border-left: none;

	transition: background-color 0.15s;
}

.eUNezlUHNqy3:before {
	content: '';

	position: absolute;
	left: 0;

	width: 1px;
	height: var(--results-flight-price-separator-height);

	background-color: var(--results-flight-price-separator-background);
}

.eUNezlUHNqy3:after {
	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 4px;

	opacity: 0;
	background-color: var(--results-flight-price-underline-background);

	transition: opacity 0.15s;

	pointer-events: none;
	content: '';
}

.eUNezlUHNqy3._2jC5MW19s5lo:after {
	background-color: var(--results-flight-price-business-underline-background);
}

.Y43ADiqZ53Gl.eUNezlUHNqy3:after,
.eUNezlUHNqy3:hover:after {
	opacity: 1;
}

button.R-oaSj6hEKju {
	color: var(--results-flight-price-economy-group);

	background: var(--results-flight-price-economy-group-color);
}

button._2jC5MW19s5lo {
	color: var(--results-flight-price-business-group);

	background: var(--results-flight-price-business-group-color);
}

button.R-oaSj6hEKju.Y43ADiqZ53Gl {
	background: var(--results-flight-price-economy-group-opened);
}

button._2jC5MW19s5lo.Y43ADiqZ53Gl {
	color: var(--results-flight-price-business-group-color-opened);

	background: var(--results-flight-price-business-group-opened);
}

button._1MKtIfR9Yihp {
	border-left: none;

	color: #ffffff;
}

._1MKtIfR9Yihp.R-oaSj6hEKju {
	background-color: var(--common-blue);
}

._1MKtIfR9Yihp._2jC5MW19s5lo {
	background-color: var(--dark-violet);
}

.eUNezlUHNqy3.R-oaSj6hEKju:hover {
	background-color: rgba(50, 144, 255, 0.1);
}

.eUNezlUHNqy3._2jC5MW19s5lo:hover {
	background-color: rgba(80, 109, 210, 0.1);
}

button.C53Qx-AiAF5e {
	min-width: 210px;
	max-width: 210px;
}

.hd1FWxJ6ixED {
	display: flex;
	justify-content: center;
	flex-direction: column;

	text-align: left;
}

._1ReIFoeQW61b {
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	text-transform: capitalize;
}

.Sgk3PECFWBsU {
	display: none;
}

.CA0SwhnzLJM9 {
	margin-top: 8px;

	color: var(--results-flight-price-prefix);
	font-size: 14px;
	line-height: 16px;

	opacity: 0.7;
}

._2GMLFCLOb7mH {
	margin-top: 0;
}

._1envRh1hZYmB {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 600;
	color: inherit;
}

._1MKtIfR9Yihp .CA0SwhnzLJM9 {
	color: rgba(255, 255, 255, 0.7);
}

._3WMN7_yw98OC {
	position: relative;
	top: 5px;

	display: flex;
	align-items: center;

	align-self: flex-end;

	font-weight: 600;
	font-size: 34px;
	white-space: nowrap;
}

._2zKOyaZiwzxS {
	position: static;

	align-self: center;
}

._3rUxbrKv02yG {
	display: flex;
	align-items: center;
	margin-left: 10px;

	color: #ffffff;

	transition: transform 0.15s;
}

.Y43ADiqZ53Gl ._3rUxbrKv02yG {
	transform: rotate(-180deg);
}

._13Lejlze6Z3w {
	font-size: 32px;
}

._2aH-Ob1WeLr1 {
	font-size: 25px;
}

button._13lmA7fWRssm {
	cursor: not-allowed;
}

button.eUNezlUHNqy3._13lmA7fWRssm:hover {
	background-color: inherit;
}

button._13lmA7fWRssm:after {
	display: none;
}

button._13lmA7fWRssm .hd1FWxJ6ixED {
	opacity: 0.4;
}
