

._3_slHox-We_E {
	display: block;
}

div._1vxAWMWGihh1 {
	z-index: 1502 !important;
}

._1AXlck0NrMWl {
	display: flex;
	align-items: center;
}

._1rPW86j7m8pY {
	position: relative;

	display: flex;
	align-items: center;

	color: #1f1f1f;
	font-weight: 700;
	font-size: 24px;
	line-height: 150%;
	font-family: 'Roboto Condensed', sans-serif;
}

._2xiP3mOa2VbJ ._1rPW86j7m8pY {
	color: #ffffff;
}

._2xiP3mOa2VbJ div._1rPW86j7m8pY:after {
	border: 1px solid #ffffff;

	background: transparent;
}

._1rPW86j7m8pY:first-of-type:after {
	margin-left: 10px;
}

._1rPW86j7m8pY:last-of-type {
	flex-direction: row-reverse;
}

._1rPW86j7m8pY:last-of-type:after {
	margin-right: 10px;
}

._1rPW86j7m8pY:first-of-type:after,
._1rPW86j7m8pY:last-of-type:after {
	display: flex;

	width: 7px;
	height: 7px;

	border: 1px solid #a5a5a5;
	border-radius: 50%;

	background: #ffffff;
	content: '';
}

._3bAeZ5T6SkZP {
	position: absolute;
	top: -10px;
	right: -11px;

	color: var(--dark-red);
	font-weight: 600;
	font-size: 12px;
	line-height: 20px;
}

._3hYrTBo4T2Fg {
	position: relative;

	display: flex;
	align-items: center;

	flex-basis: 100%;

	font-size: 14px;
	line-height: 1.15;
	text-align: center;
}

._3hYrTBo4T2Fg:before,
._3hYrTBo4T2Fg:after {
	display: flex;
	flex: 1;
	height: 1px;

	background: #a5a5a5;
	content: '';
}

._2xiP3mOa2VbJ ._3hYrTBo4T2Fg:before,
._2xiP3mOa2VbJ ._3hYrTBo4T2Fg:after {
	background: #ffffff;
}

._3rHWB-HwMBXF {
	position: relative;

	padding: 0 8px;

	font-weight: 500;

	background: #ffffff;
}

._2xiP3mOa2VbJ ._3rHWB-HwMBXF {
	color: #ffffff;

	background: transparent;
}

._2RnMl_BkBbE9 {
	padding-top: 8px;

	color: #777777;
	font-size: 14px;
	text-align: center;
}

._2xiP3mOa2VbJ ._2RnMl_BkBbE9 {
	color: #ffffff;
}

.BHXDwQnBoQ_z {
	display: flex;
	margin-top: 5px;
	justify-content: space-between;
}

.DKSEBiIy8kzi {
	max-width: 50%;

	color: #1f1f1f;
	font-weight: 500;
	font-size: 14px;
}

._1ex-aJnBu1KX {
	line-height: 20px;
}

.oRbtgCdo4F5Q,
._3z8g0qPaYLNl {
	font-size: 13px;
	line-height: 16px;
	font-weight: 400;

	opacity: 0.7;
}

.DKSEBiIy8kzi:last-child {
	text-align: right;
}

._3xnVeOnTHqi6 {
	margin-top: 12px;

	border-top: 1px dashed #d9d9d9;
}

.Yf9Uxrfwzl1D {
	display: flex;
	align-items: center;

	color: var(--common-gray);
	font-size: 13px;
	line-height: 1.15;

	opacity: 1;
}

.kdPgziW7Ya2J,
._2t4V6ThyHSJL {
	display: inline-flex;
	align-items: center;
}

.kdPgziW7Ya2J {
	width: 20px;
	height: 20px;
}

.kdPgziW7Ya2J img {
	width: 100%;
	height: 100%;
}

.Yf9Uxrfwzl1D span:not(:first-child) {
	padding-left: 5px;
}

.Yf9Uxrfwzl1D span:not(:first-child):before {
	content: '\2022';

	padding-right: 5px;
}

.boFzMliPPCsr {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 40px;
	margin-bottom: 10px;

	cursor: pointer;
}

._39dYGr4SsUCX {
	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMi42ODM2IDEuNDFMMTEuMTk5MSAwTDYuMzY2NzYgNC41OEwxLjUzNDM5IDBMMC4wNDk5MzMgMS40MUw2LjM2Njc2IDcuNDFMMTIuNjgzNiAxLjQxWiIgZmlsbD0iIzlBOUE5QSIvPgo8L3N2Zz4K) center no-repeat;

	transition: transform 0.15s;
}

._306O3DtCfxiT ._39dYGr4SsUCX {
	transform: rotate(180deg);
}

._3KCLXDVJEs8- {
	display: block;
	margin-bottom: 20px;
}

._3mByP_0_dzNg {
	margin: 0 0 16px;
	padding: 8px 12px;

	border-radius: 3px;

	background-color: #ededed;
}

._26aHv3yLewrX {
	display: flex;
	justify-content: space-between;

	font-size: 13px;
	line-height: 20px;
}

.nB_GHFsi6kZ7 {
	color: var(--common-gray);
}

._2lB6kv3gTcJf {
	color: #464646;
}

button.n0m7QJD3c69x {
	display: block;
	width: 100%;

	text-align: left;
}

._1CPTwTSsbg7Y {
	color: #1f1f1f;
	font-size: 16px;
	font-weight: 500;
}

._1O8B5KCfJm4s {
	height: auto;
	max-width: 85vw;
	margin: 10px auto 20px;
}

._2zCDDl8L6WII {
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

._25z84V7AhOeQ {
	position: relative;

	padding-right: 30px;

	color: var(--common-gray);
	font-size: 14px;

	cursor: pointer;
}

._25z84V7AhOeQ:after {
	position: absolute;
	top: -4px;
	right: 0;

	display: block;
	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxjaXJjbGUgcj0iMTIiIHRyYW5zZm9ybT0ibWF0cml4KDAgLTEgLTEgMCAxMiAxMikiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xMC40MSA2TDkgNy40MUwxMy41OCAxMkw5IDE2LjU5TDEwLjQxIDE4TDE2LjQxIDEyTDEwLjQxIDZaIiBmaWxsPSIjNzc3Nzc3Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==) center no-repeat;
	content: '';
}

.P1W1TGAnOxef {
	margin-top: 25px;
	margin-bottom: 10px;

	color: #1f1f1f;
	font-weight: 500;
	font-size: 16px;
}

._3WKTGxGWWkRY {
	position: relative;

	width: 100%;
	min-width: 93px;

	display: flex;

	background-color: var(--common-blue);
}

button._3seUtfpEhG2W {
	position: relative;

	flex-grow: 1;

	color: #ffffff;

	background-image: url('https://cdn.websky.aero/content/frontend/images/mock-airport.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	cursor: pointer;
}

._3I_gPSmFxcp3 {
	position: absolute;
	right: 10px;
	bottom: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
}

._1g4JxCpulu0F {
	position: relative;

	height: 100%;
	width: 102px;
}

.l0hr2b_ET443 {
	position: absolute;
	right: 8px;
	bottom: 5px;

	color: #ffffff;
	font-weight: 300;
	font-size: 40px;
}

._3ypn-1ChEmEk {
	position: absolute;
	top: 6px;
	left: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: #ffffff;
}
