._3IEj4QlJkKP1 {
	display: flex;
	align-items: stretch;

	max-width: 1100px;
	margin: 0 auto 12px;

	background: var(--white);
}

.s5pFeZUqgaF4 {
	flex-grow: 1;
}

.mqK6o-pig3bR {
	min-width: 200px;
}

@media screen and (max-width: 600px), (max-width: 768px) {
	._3IEj4QlJkKP1 {
		flex-direction: column;
	}

	.mqK6o-pig3bR {
		width: auto;
	}
}
