._3aHkPy8CjaiP {
	display: flex;
	max-width: 1100px;
	margin: 0 auto;
	padding: 12px 0;
}

.JPf1ArXtBFVT {
	flex-direction: column;
	padding: 0;
}

.JPf1ArXtBFVT > div:not(:first-child) {
	margin-top: 20px;
}

@media (max-width: 1024px) {
	._3aHkPy8CjaiP {
		justify-content: space-between;
		padding: 12px 20px;
	}

	.JPf1ArXtBFVT {
		padding: 0;
	}
}

@media screen and (max-width: 600px) {
	._3aHkPy8CjaiP {
		flex-direction: column;
		align-items: center;
	}

	.JPf1ArXtBFVT > div:not(:last-child) {
		margin-bottom: 0;
	}

	.JPf1ArXtBFVT > div:not(:first-child) {
		margin: 0;
	}
}
