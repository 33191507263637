

._1r0qUE7GLisr {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 100px;
}

._3LylPaPHf7kH {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	padding: 0;

	min-width: 28px;
	max-width: 28px;
	min-height: 28px;
	max-height: 28px;

	border: none;
	border-radius: 50%;

	color: var(--base);

	cursor: pointer;
	background-color: var(--base80);

	transition: all 0.15s;
	transition-property: background-color, opacity;
}

.Pr2nS5vCmfV_ {
	color: var(--gray20);

	background-color: var(--gray05);

	cursor: default;
}

._1ZaP2P39B8vp,
._2-yZ63tUmfgv {
	opacity: 0;
	cursor: default;
}

._3LylPaPHf7kH:not(.Pr2nS5vCmfV_):hover {
	color: #ffffff;

	background-color: var(--base);
}

.uWPMMoSUKtT2 {
	position: absolute;
	left: 50%;

	color: var(--gray80);
	font-size: 20px;
	font-weight: 600;
	text-align: center;

	transform: translateX(-50%);

	transition: opacity 0.15s, color 0.15s;
}

._2op0UvpnZuGY {
	color: var(--gray05);
}
