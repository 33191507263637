._1muHMuOxsRdX {
	display: flex;
}

.V8S6uU72MWAF {
	display: flex;
	width: 360px;
	margin-right: 20px;
}

.V8S6uU72MWAF div:after {
	border-bottom-color: var(--cabinet-action);
}

.V8S6uU72MWAF .react-phone-number-input {
	flex-grow: 1;
}

div._3A4SBxGoP6kA {
	font-family: inherit;
}

._3fHKVEA2UySy {
	position: relative;

	display: flex;
	flex-direction: column;
}

._3fHKVEA2UySy > p {
	position: absolute;
	bottom: -20px;

	margin: 0;

	font-size: 0.75rem;
	color: var(--common-gray);
}

._3fHKVEA2UySy > p > span {
	color: var(--cabinet-action);

	cursor: pointer;
}

._1muHMuOxsRdX ._1xk-8TxqtcWD {
	width: 230px;
}

._1xk-8TxqtcWD div:after {
	border-bottom-color: var(--cabinet-action);
}

._1xk-8TxqtcWD > p {
	font-size: 13px;
}

.vi1Ug5d3xCrb {
	padding: 0;
	height: 20px;

	border: none;

	color: var(--cabinet-action);
	font-size: 13px;

	background-color: inherit;

	cursor: pointer;
}

._3b-H1nBg9mDt {
	height: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
}

._1diKaHSlYU9z {
	width: 22px;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJcSURBVHgBrVRdq2FhFF627RyzxcShOeUjQxlx4STJpXLnZ/glfoLiQrhyJ7kWpdy50wxmGDTKoZAZoTG+m7XeOJ05n47mqdfeve9ej7We91lLBE8gGAzyMplMv1qtrpbLpWK32wnb7RZwTTebzR+RSDQIh8O9p2JFDzdCodAHDLxZr9cSJAIkhftPIqbngfh7IpHoPUsYiUTsGPCRAtRqNRgMBjCZTCCXy9n5cDiEfr8PhUIBBoMBI0Y0k8lk8xFhLBaz4AcWenc4HGC1WuElFItFyGazsFgsAKv5kU6nv9G+mH7i8bgOH/aLiwvw+Xyg1+vhNRiNRrDZbFAqlah8JSYxrdVqvzk6lEgkn3ieB7vdDkqlEk6FVqsFv9/PSsdMb7xeLy/OZDK6/X6vFwQBXC4XvBVmsxmq1SqMRiOxVCpdcVj/NWX3mmYvwel0sttHi11xqJtA2ikUCjgXbrebLoZI3/OoH2M6WuMckMUoQ8Q7XiwWw//AwZPAoX4L0pBSPhetVuvYSVPScEYazmYzOBedTueuHTms/RdlSI4/F9gYjBDXgEMj36KOG0p5Pp/DW5FKpVh/o5cXlUqlx6GZN5jhF8pyMpnciXsKGo0GRKNRpj8uNiBY66GPBkjUoffxeHxS+ThhIBAIsCQQzXa7zcbYP+OrXq/TtLGQlS4vL4HakXC0FpWWz+chl8tBuVyGw3edbrf79cjxaMCiBXQ4OC1oeIECSApygUqlYn9Ce7TwnKT6jD08vB8veq6kXq+n4zjuGl8F6iaNRkPkC7IZXsBPj8dzizNx+zDuL+21NUIz0btFAAAAAElFTkSuQmCC) center no-repeat;

	animation: _106Kog9OvyUR 0.9s infinite linear;

	pointer-events: none;
}

._2ofi2CZXWAW9 {
	position: relative;

	width: 100%;
}

div._3dFZq-MIR_vf {
	position: absolute;
	top: -12px;
	left: 380px;

	max-width: calc(100% - 380px);
	padding: 12px !important;

	border: 1px solid #ffcb13;

	color: #000000 !important;
	font-family: inherit;

	background-color: #fff6d4 !important;
}

._3dFZq-MIR_vf h1 {
	margin: 0;

	font-size: 13px;
	line-height: 150%;
	color: var(--dark-gray);
}

._3dFZq-MIR_vf p {
	margin: 0;

	font-size: 13px;
	font-weight: 400;
	line-height: 150%;
	color: var(--common-gray);
}

._3dFZq-MIR_vf:after,
._3dFZq-MIR_vf:before {
	position: absolute;
	right: 100%;
	top: 45%;

	display: block;
	content: '';

	height: 0;
	width: 0;

	border: solid transparent;

	pointer-events: none;
}

._3dFZq-MIR_vf:after {
	margin-top: 1px;

	border-right-color: #fff6d4;
	border-width: 7px;
}

._3dFZq-MIR_vf:before {
	border-right-color: #ffcb13;
	border-width: 8px;
}

._3dFZq-MIR_vf.n5epf4TzLHPI {
	top: 14px;
}

._3GvRP2ejwHoB:after,
._3GvRP2ejwHoB:before {
	top: 20% !important;
}

.rc-4JDsPRaqV > div > div > svg {
	height: 16px;
	width: 16px;
	margin-right: 4px;
}

.rc-4JDsPRaqV > div > div > span > svg {
	height: 16px;
	width: 16px;
	margin-right: 4px;
}

@keyframes _106Kog9OvyUR {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}

@media screen and (max-width: 600px) {
	._1muHMuOxsRdX {
		width: 100%;
		flex-direction: column;
	}

	._1muHMuOxsRdX .V8S6uU72MWAF {
		margin-right: 0;
		width: 100%;
	}

	._1muHMuOxsRdX .V8S6uU72MWAF._3JhkznT2VvHG {
		margin-bottom: 50px;
	}

	._3RX65dc4RI0T {
		top: 40px !important;
		left: -4px !important;

		display: flex;
		justify-content: center;

		width: 100%;
	}

	div._3dFZq-MIR_vf,
	._3dFZq-MIR_vf.n5epf4TzLHPI {
		top: unset;
		bottom: -10px;
		left: 0;

		margin: 0;
		max-width: unset;
		width: 100%;
	}

	._3dFZq-MIR_vf:after {
		border-width: 8px;
	}

	._3dFZq-MIR_vf:not(._3GvRP2ejwHoB) {
		margin-top: 40px;
	}

	._3dFZq-MIR_vf:before,
	._3dFZq-MIR_vf:after {
		top: -16px !important;
		right: 5%;

		transform: rotate(90deg);
	}

	._3RX65dc4RI0T > ._3dFZq-MIR_vf:after {
		margin-top: 2px;
		margin-right: 1px;
	}

	._1muHMuOxsRdX ._1xk-8TxqtcWD {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}
