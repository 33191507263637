._2b-jAHaMzV_F {
	display: flex;
	flex-basis: 33.3%;
}

@media (max-width: 1024px) {
	._2b-jAHaMzV_F {
		position: absolute;
		top: 52px;
		right: 2px;

		width: 50%;
		padding-right: 6px;
	}

	._2FZhdXPWwAj0._2b-jAHaMzV_F {
		position: relative;

		align-items: flex-start;
		flex-direction: column;
	}

	._2FZhdXPWwAj0._2b-jAHaMzV_F > div {
		width: 100%;
	}

	._2FZhdXPWwAj0._2b-jAHaMzV_F > div:not(:last-child) {
		margin-bottom: 12px;
	}
}

@media screen and (max-width: 600px) {
	._2b-jAHaMzV_F {
		position: static;

		flex-basis: 100%;
		width: 100%;
		padding-right: 0;
	}

	._2b-jAHaMzV_F._2FZhdXPWwAj0 {
		margin-top: 12px;
	}
}

.Mso1zPgWdYrE {
	margin-top: 20px;
	flex-basis: 100%;
	justify-content: flex-end;
}

._2FZhdXPWwAj0 {
	justify-content: space-between;
	align-items: flex-end;
	margin-top: 0;
}

@media (max-width: 1024px) {
	.Mso1zPgWdYrE {
		top: auto;
		bottom: 0;

		margin-top: 0;
		padding-right: 0;
	}
}
