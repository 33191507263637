._3vgvElBJBaiL {
	display: flex;
	flex-direction: column;
	max-width: 1100px;
	margin: 0 auto;
}

._2pTeA02F68NV {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

._3zCkMWIlE0a6 {
	margin: 28px 0 24px;

	font-weight: 500;
	font-size: 27px;
	line-height: 40px;
	color: var(--dark-gray);
}

._3FG-43vsNzLx {
	overflow: visible;
	overflow: initial;
}

._2MXxMX-L6ycy {
	min-height: 100%;
	height: auto;
}

._2KO4SPh-luu8 {
	padding-bottom: 25px;
	height: -moz-fit-content;
	height: fit-content;
	min-height: 100%;

	background: #ededed;
}

._2KO4SPh-luu8 > div > div {
	border-radius: 0;
}

@media screen and (max-width: 600px) {
	._3vgvElBJBaiL {
		background-color: #ffffff;
	}

	._2pTeA02F68NV {
		flex-wrap: wrap;
		margin-bottom: 0;
	}
}
