

.JDN8pcNtdQAn {
	display: inline-block;

	font-size: 13px;
	font-weight: 500;
	line-height: 20px;
}

div._23GLoaswJ6WJ {
	padding: 8px;
	margin-top: 0;
	margin-bottom: 0;

	color: var(--tooltip-color);
	font-family: inherit;

	background-color: var(--tooltip-background);

	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.35);
}

._23GLoaswJ6WJ:after {
	position: absolute;
	bottom: 0;
	left: 50%;

	width: 0;
	height: 0;

	border-width: 8px 6px 0 6px;
	border-style: solid;
	border-color: var(--tooltip-background) transparent transparent transparent;

	transform: translate(-50%, calc(100% - 1px));
	content: '';
}

._1oVJ87NpEyeb:after {
	top: 50%;
	left: auto;

	border-width: 5px 0 5px 8px;
	border-color: transparent transparent transparent var(--tooltip-background);

	transform: translate(101%, -50%);
}

._2mFrwLVqj7Of:after {
	bottom: auto;
	top: 0;

	transform: translate(-50%, -100%) rotate(180deg);
}

div._3nJZnXcNrdps {
	z-index: 1501;

	opacity: 1;
}
