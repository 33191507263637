

._3kczGfP7M2EL {
	padding: 40px;
	width: 830px;
}

._2-ZqCZfx4orP {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 1;
}

._2-ZqCZfx4orP > div {
	background-color: var(--gray05);
}

._3bRn-MnetKnG {
	margin: 28px 0;
}

._1f6dizVE780y {
	font-size: 36px;
	font-weight: 300;
	line-height: 42px;
}

._3huyAE-4E9j0 {
	width: 100%;
	margin-right: 98px;

	border-spacing: 0;

	font-size: 14px;
}

._3huyAE-4E9j0 td,
._3huyAE-4E9j0 th {
	height: 47px;
	padding: 0 12px;
}

._8sf-SmqvuIGH,
._38ZzjIzNJhfF {
	text-align: left;
}

.lNThIhC8vFnJ,
._37SQJvg-15Mu {
	text-align: right;
}

._31PwXGHlTfm2,
._3bEZk1SkpElk {
	text-align: center;
}

._3huyAE-4E9j0 thead {
	height: 47px;

	color: var(--white);
	font-weight: 500;

	background: var(--base);
}

._3huyAE-4E9j0 tbody tr {
	color: var(--gray90);

	background: var(--white);
}

._3huyAE-4E9j0 tbody tr._16e4iQ-zMxCa {
	background: var(--success90);
}

._1qQDbxKBdotK:not(:first-child) > td {
	border-top: 1px solid var(--gray90);
}

._3huyAE-4E9j0 tbody tr td:first-child {
	font-weight: 700;
}

._2f15-loTyM3f {
	color: var(--base);
	font-size: 15px;

	cursor: pointer;
}
