._2SyeMLeY5weJ {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999999999;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #ffffff;
}

._3uM8FdTspzqm {
	display: flex;
	align-items: center;
	min-height: 160px;
	padding-right: 50px;

	border-right: 1px dashed #bababa;
}

._2ByHVGqkvZ6J {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 160px;
	padding-left: 50px;
}

._2f4op0tVEb8B {
	margin: 0 0 13px 0;

	font-weight: 300;
	font-size: 57px;
	line-height: 120%;
}

@media screen and (max-width: 600px) {
	._2SyeMLeY5weJ {
		flex-direction: column;
	}

	._3uM8FdTspzqm {
		width: 160px;
		justify-content: center;
		padding: 0 0 40px 0;
		min-height: auto;

		border-right: none;
		border-bottom: 1px dashed #bababa;
	}

	._3uM8FdTspzqm svg {
		width: 48px;
		height: 48px;
	}

	._2ByHVGqkvZ6J {
		padding: 40px 0 0 0;
		align-items: center;
	}

	._2f4op0tVEb8B {
		margin-bottom: 20px;

		font-size: 24px;
		font-weight: 400;
	}
}
