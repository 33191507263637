

._3ElfOOKPvMXW {
	position: relative;

	padding: 55px 40px 40px 40px;
}

._1m7V_f-ejAM7 {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.X_oc13nLAzN2 {
	margin-bottom: 18px;

	color: var(--gray80);
	font-weight: 500;
	font-size: 21px;
}

._3ZTAl4QYv2X1 {
	height: 54px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 18px;
}

button._1s54uz192-e9 {
	font-weight: 500;
}

button.n1ldWQB_O2jU,
button.n1ldWQB_O2jU:hover {
	color: var(--gray70);

	background: var(--gray05);
}

label._3sXRTLVAQDui {
	display: flex;
	align-items: flex-start;
	margin: 0;

	border-bottom: 1px solid var(--gray10);

	line-height: 50px;
}

label._3sXRTLVAQDui._3GzkjATYrbWK {
	border-bottom: none;
}

span._1kxhDlanQVT5 {
	position: relative;

	width: 100%;
	padding: 10px 0;

	color: var(--gray90);
	font-weight: 400;
	font-size: 15px;
	text-transform: capitalize;
}

span._1kxhDlanQVT5._2Tp-wMW6jiPl div {
	color: rgba(0, 0, 0, 0.38);
}

._3tPQbiR65D73 {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

._2KWA0hjTE5w9 {
	color: var(--text-light);
}

._3tPQbiR65D73:not(:last-of-type) {
	margin-bottom: 10px;
}

span._gwpI2OjYJFr {
	position: absolute;

	width: 71%;
	margin-top: 1px;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

span._2t4iyFYP8gMa {
	margin-right: 15px;
	margin-left: auto;
}

label._3sXRTLVAQDui._3GzkjATYrbWK span._1kxhDlanQVT5 {
	text-transform: none;
}

span.KEP43JK5_w0i {
	padding: 0;
	margin-top: 10px;
	margin-right: 15px;
}

span.KEP43JK5_w0i._1sQPyeE4_GMM {
	color: var(--base);
}

span.WzwuTEjI1jXT:hover,
span._1sQPyeE4_GMM.WzwuTEjI1jXT:hover {
	background-color: rgba(50, 144, 255, 0.04);
}
