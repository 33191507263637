

._91Qqxoro8yLa {
	position: relative;

	display: flex;
	align-items: center;
	padding: 16px 45px;

	border-radius: 8px;

	color: #ffffff;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;

	background: var(--base10);
}

._91Qqxoro8yLa._31KtFKG58MgW {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

._3fb4YOtpCj6B {
	left: 15px;
	top: 15px;
}

._3fb4YOtpCj6B,
._1_5vpgHaDGqN {
	position: absolute;

	display: flex;
	justify-content: center;
	align-items: center;

	color: #ffffff;
}

._1_5vpgHaDGqN {
	top: 50%;
	right: 10px;

	width: 24px;
	height: 24px;

	border-radius: 50%;

	transform: translateY(-50%);
	background: rgba(255, 255, 255, 0.2);
}

._1_5vpgHaDGqN svg {
	transform: scale(0.6);
}

._1bQkfnVb_ixn._31KtFKG58MgW > div {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}
