.mzwuD4FgMxQg {
	display: flex;
	align-items: center;
}

._11spASIf4IY_ {
	width: 40px;
	height: 40px;
	margin-right: 14px;
}

._3sql9Jl14tNl {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin-right: 14px;

	color: var(--gray50);
}

._1XIRD1X2O089 {
	display: flex;
	flex-direction: column;

	color: var(--gray70);
}

._2H_GvqtdgiSK {
	margin-bottom: 4px;

	font-size: 18px;
	line-height: 1.2;
}

._1Qb8gAGjnXMU {
	padding-right: 20px;
}

.mzwuD4FgMxQg._1majUSDTj1pS {
	padding: 12px;

	border: 1px solid #add3ff;
	border-radius: 4px;

	background-color: var(--base90);
}

.mzwuD4FgMxQg.sdTJRrMwwAPh {
	padding: 18px;

	background-color: var(--white);
}

._1majUSDTj1pS._1mJFJYPuEfGk,
div.sdTJRrMwwAPh._1mJFJYPuEfGk {
	background-color: #fffdcc;
}

._1majUSDTj1pS._1mJFJYPuEfGk {
	border: 1px solid #efea6c;
}

._1majUSDTj1pS._2DlZ4FBcutjP {
	border: 1px solid var(--success60);

	background-color: #e7f7e3;
}

div.sdTJRrMwwAPh._2DlZ4FBcutjP {
	background-color: var(--success);
}

._1majUSDTj1pS._3UPu6elldAVO {
	border: 1px solid var(--error60);

	background-color: #fdf2f2;
}

div.sdTJRrMwwAPh._3UPu6elldAVO {
	background-color: var(--error);
}

._1majUSDTj1pS ._1Qb8gAGjnXMU {
	font-size: 14px;
	line-height: 1.5;
}

._1majUSDTj1pS ._1Qb8gAGjnXMU p,
.sdTJRrMwwAPh ._1Qb8gAGjnXMU p {
	margin: 0;
}

._1majUSDTj1pS ._1Qb8gAGjnXMU a,
.sdTJRrMwwAPh ._1Qb8gAGjnXMU a {
	color: var(--base);
	text-decoration: underline;
}

.sdTJRrMwwAPh ._1Qb8gAGjnXMU {
	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;
}

._1majUSDTj1pS._2DlZ4FBcutjP ._1Qb8gAGjnXMU,
._1majUSDTj1pS._2DlZ4FBcutjP ._3sql9Jl14tNl {
	color: #479732;
}

._1majUSDTj1pS._3UPu6elldAVO ._1Qb8gAGjnXMU,
._1majUSDTj1pS._3UPu6elldAVO ._3sql9Jl14tNl {
	color: #be1d1d;
}

.sdTJRrMwwAPh._2DlZ4FBcutjP ._1XIRD1X2O089,
.sdTJRrMwwAPh._2DlZ4FBcutjP ._3sql9Jl14tNl,
.sdTJRrMwwAPh._3UPu6elldAVO ._1XIRD1X2O089,
.sdTJRrMwwAPh._3UPu6elldAVO ._3sql9Jl14tNl {
	color: var(--white);
}

button._3eUomFLFBFMt {
	margin-left: auto;
	padding: 6px 8px 6px 14px;

	border: 1px solid #add3ff;

	color: #3290ff;

	background-color: var(--white);
}

button._3eUomFLFBFMt:hover {
	background-color: var(--gray05);
}

button._3eUomFLFBFMt > svg {
	margin-left: 6px;
}

@media screen and (max-width: 600px) {
	.mzwuD4FgMxQg.sdTJRrMwwAPh {
		border: 1px solid #add3ff;
		border-radius: 4px;

		background-color: var(--base90);
	}

	div.sdTJRrMwwAPh:not(._1mJFJYPuEfGk, ._2DlZ4FBcutjP, ._3UPu6elldAVO) ._3sql9Jl14tNl {
		color: var(--selected);
	}

	div.sdTJRrMwwAPh._1mJFJYPuEfGk {
		border: 1px solid #efea6c;
	}
}
