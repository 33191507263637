

._2H7TVGns11gS {
	padding-left: 0;
}

._3KimD31AtpWu {
	margin-left: 10px;
	padding-left: 10px;

	border-left: 1px solid var(--line-separator);
}

._8jd6_JJXX70u {
	margin-left: auto;
}

._2R6ksU5dc93N {
	color: var(--text-link);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;

	cursor: pointer;
}

._3vqnRiPzHmYC,
.o9qDFB5k5WO2 {
	color: var(--text-link);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;

	cursor: pointer;
}
