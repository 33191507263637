div._20Luu1GHtXol {
	overflow: hidden !important;

	box-shadow: none;
}

.peLCALveINRS {
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

._14InGSnl8yOb {
	color: #b7b7b7 !important;
}

._2I46Gb8fO-9R {
	padding: 0 0 25px !important;
}

.ILwoPs8v3-l- {
	padding: 40px 40px 0;
	margin: 0 0 25px 0;

	font-size: 36px !important;
	line-height: 42px !important;
	font-weight: 300 !important;
	color: var(--common-gray) !important;
}

._4GAMXwdUqRQW {
	display: flex;
	justify-content: space-between;
	padding: 0 40px;
}

._1sVLrN441pKW {
	margin-left: 0;
}

button.xu6KgJ5KyseN {
	min-width: auto;
	max-width: 100%;
	padding: 6px 0;

	text-transform: none;
	color: #9a9a9a;
	font-weight: 500;
	font-size: 13px;
	font-family: inherit;
}

._2bw1s1qPc3qI {
	height: 3px !important;

	background-color: var(--common-blue) !important;
}

.xu6KgJ5KyseN:not(:last-of-type) {
	margin-right: 20px;
}

._3k2o1qo-Jgjg {
	color: var(--common-blue) !important;
}

._2DVUsbgCHzHU {
	padding: 0 40px;
}

._2DVUsbgCHzHU pre {
	white-space: pre-line;
}

.RTOj-iif4usO {
	padding: 0 40px;
	margin: 25px 0;

	color: var(--common-blue);
	font-weight: 300;
	font-size: 31px;
}

._3hGtUOAo5DVl {
	display: flex;
	align-items: center;
}

.NbrAU_bDJib9 {
	padding: 0 40px;
}

._8IXirwBOuPR9 {
	flex: 70%;
	align-items: start;
}

._2e-CGF1UaQ3O {
	margin-left: auto;
	flex: 30%;
}

.FrCY9G43jXvZ {
	justify-content: flex-end;
}

._3kqclKi6Kt5Z {
	padding: 40px;
}

.Qo-RrbxGQbPj {
	margin: 0;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 22px;
	line-height: 26px;
}

._27L9yZA7TKAQ {
	line-height: 22px;
	color: var(--common-gray);
}

._1ft1ka8_DlaA {
	position: relative;
	top: -3px;

	margin-left: 10px;
}

._1ft1ka8_DlaA > div {
	font-family: inherit;
}

._2DVUsbgCHzHU > div {
	max-height: calc(100vh - 240px);
	overflow-y: scroll;
}

._2DVUsbgCHzHU > div::-webkit-scrollbar {
	width: 8px;
}

._2DVUsbgCHzHU > div::-webkit-scrollbar-thumb {
	display: block;

	border-radius: 20px;

	background-color: var(--gray10);
}

.nJ8WQ_hhxlDn ._2DVUsbgCHzHU > div {
	max-height: calc(100vh - 120px);
}

div._2yOdoUmqPSbt {
	min-width: 250px;
	padding: 5px 32px 5px 12px;

	border: 1px solid #e4e4e4;
	border-radius: 4px;

	font-size: 14px;
	line-height: 21px;

	background: #f0f0f0;
}

div._2yOdoUmqPSbt:focus {
	border-radius: 4px;
}

input._1Ehj9LGvvXwp:focus {
	border-radius: 4px;
}

li._1Nvkf09IdJuN {
	box-sizing: border-box;

	font-family: inherit;
	font-size: 14px;
}

li._1Nvkf09IdJuN._2Th7DXCxYApg,
li._1Nvkf09IdJuN._2Th7DXCxYApg:hover {
	background: #f0f0f0;
}

div._3fe93NqM6BN- {
	z-index: 1501 !important;
}

div.oAh4zXfb9OD7 {
	display: block;
	padding-left: 40px;
	padding-right: 55px;
}

div._1fCjj1U3qLyo {
	justify-content: flex-start;
	padding: 0;
}

._1yMIG220TWEX {
	margin-left: 30px;
	flex-wrap: wrap;
}

div._3KFao22t_ywf {
	position: static;
}

button._1M0xOp7kiui7 {
	border: 1px solid var(--gray10) !important;

	color: var(--gray80);

	background-color: var(--gray) !important;
}

button._2YmT87RSnL3M {
	border: 1px solid var(--base) !important;

	color: var(--gray80);

	background-color: var(--base) !important;
}

@media screen and (max-width: 600px) {
	._2I46Gb8fO-9R {
		position: relative !important;

		padding: 12px 40px 12px 24px !important;

		border-bottom: 0;

		background: #ededed;
	}

	.peLCALveINRS {
		height: 100vh;
	}

	.ILwoPs8v3-l- {
		padding: 0;

		color: var(--common-gray) !important;
		font-weight: 500 !important;
		font-size: 20px !important;
		line-height: 23px !important;
	}

	._2549LLkuOxAd {
		display: none;
	}

	._4GAMXwdUqRQW {
		margin: 0;
		padding: 0;
		flex-direction: column;
	}

	._2e-CGF1UaQ3O {
		order: 1;
		margin-left: 0;
	}

	._3Vg3MA7QM0Dy {
		flex: 1;

		color: var(--common-gray);
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;

		background: #ededed !important;
		opacity: 1 !important;
	}

	.vkfGP80MgSFv {
		background: #ffffff !important;
	}

	._3Vg3MA7QM0Dy:not(:last-of-type) {
		margin-right: 0;
		padding-right: 20px;
	}

	._8IXirwBOuPR9 {
		order: 2;
		padding: 0 20px;
	}

	._2DVUsbgCHzHU {
		padding: 0 20px;
		max-height: calc(100vh - 136px);
	}

	.RTOj-iif4usO {
		padding: 0 20px;
	}

	.NbrAU_bDJib9 {
		padding: 0 20px;
	}

	._1fCjj1U3qLyo {
		flex-direction: column;
		align-items: flex-start;
	}

	div.oAh4zXfb9OD7 {
		padding: 0 20px;
	}

	._1yMIG220TWEX {
		margin-left: 0;
		margin-top: 15px;
		width: calc(100vw - 25px);
		overflow: scroll;
		scrollbar-width: none;
		flex-wrap: nowrap;
	}

	::-webkit-scrollbar {
		width: 0;

		background: transparent;
	}

	._3KFao22t_ywf {
		display: none;
	}
}
