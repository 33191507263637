

div._2dFsxsc-oD09 {
	top: 50%;

	max-width: 600px !important;

	margin: 0 auto !important;
	padding: 28px 28px 40px 28px;

	transform: translateY(-50%);
	overflow: visible;
}

div._3qosIa3y4Vvr {
	z-index: 1503 !important;
}

._1BLzE8AIlvLW {
	display: inline-block;
	margin-bottom: 28px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 22px;
	line-height: 31px;
}
