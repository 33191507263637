div._3LACdiwcsIpx {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 440px;
	padding: 20px;

	border-radius: 20px;

	color: var(--gray80);
	font-size: 20px;
	line-height: 28px;
	text-align: center;
}

._3LACdiwcsIpx svg {
	margin-bottom: 20px;
}

._1_71WLPbMKah {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
}

button._3ucCzxU_IrQB:not(:last-child) {
	margin-right: 12px;
}

button._2y0maC73C5Ed,
button._2y0maC73C5Ed:hover {
	color: var(--gray50);

	background: var(--gray05);
}

@media screen and (max-width: 600px) {
	div._3LACdiwcsIpx {
		min-height: unset;
		margin: 70px auto !important;
		max-width: calc(100% - 64px) !important;
	}
}
