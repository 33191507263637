

._2-W8aGpkqMa3 {
	width: 420px;
}

._2-W8aGpkqMa3 > span > svg {
	color: var(--text-pale);

	transform: scale(0.6) !important;
}

._2T_hn8gSGXLs {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 1;
}

._2T_hn8gSGXLs > div {
	background-color: var(--gray05);
}

._1Dfh3yet_msE {
	margin: 50px 0 5px 32px;

	font-weight: 700;
	font-size: 20px;
}

._3JaEqcX8OC5r {
	margin: 0 32px;
}

div.H1oLdjTRaH2T,
button.H1oLdjTRaH2T,
button.H1oLdjTRaH2T:hover {
	margin-top: 16px;

	background-color: var(--brand-3-0);
}

._2mNhcqhduRg6 {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 32px;
}

._2lRMlQoJ8ZjK {
	margin-top: 15px;

	overflow-wrap: break-word;
	color: var(--error-text);
}

div.LCIuFEVhqJLm {
	margin-top: 19px;
}
