button.X3XUSBAJkicp {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

div._2s-4dojhezuf {
	max-width: 460px;

	border-radius: 20px;
}

button._2IQXcV_QbBOf {
	position: absolute;
	top: 8px;
	right: 8px;

	width: 32px;
	min-width: auto;
	height: 32px;
	padding: 0;

	border-radius: 50%;

	background: #f6f6f6;
}

button._2IQXcV_QbBOf:hover {
	background: #f6f6f6;
}

button._2IQXcV_QbBOf:before,
button._2IQXcV_QbBOf:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 50%;
	height: 2px;

	background: var(--gray30);
	transform: translate(-50%, -50%) rotate(45deg);
}

button._2IQXcV_QbBOf:after {
	transform: translate(-50%, -50%) rotate(-45deg);
}

@media screen and (max-width: 600px) {
	div._21xnCT4hBRTn {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div._2s-4dojhezuf {
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}
}
