.EZlmS3H_h1JU {
	display: flex;
	flex-wrap: wrap;
}

._3YOS3G1Uj_YF {
	display: flex;
	align-items: center;

	padding: 11px 15px;

	border-radius: 100px;

	color: var(--gray80);
	font-weight: 500;
	font-size: 14px;
	line-height: 100%;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	cursor: pointer;
	background-color: var(--meal-categories-button-background);

	transition: color 0.15s, box-shadow 0.15s;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

._3YOS3G1Uj_YF:hover {
	box-shadow: 4px 9px 38px rgba(0, 0, 0, 0.1);
}

._3YOS3G1Uj_YF:not(:last-of-type) {
	margin-right: 8px;
}

._3eKCDO-qqirl {
	padding: 8px 15px 8px 8px;

	color: var(--success-20);
}

._3KNmpqDrIZnM {
	display: flex;
	align-items: center;

	margin-right: 6px;
}

@media screen and (max-width: 600px) {
	._3YOS3G1Uj_YF {
		margin-top: 16px;

		background-color: var(--gray05);
	}

	._3YOS3G1Uj_YF:not(:last-of-type) {
		margin-right: 16px;
	}

	._3eKCDO-qqirl {
		color: var(--gray80);

		background-color: var(--success90);
	}
}
