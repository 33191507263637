

._1gcv-qjx6syM {
	width: 100%;
	max-width: 1100px;
	margin: 0 auto 12px;

	border-radius: 4px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
	background: #ffffff;

	transition: box-shadow 0.15s;
	overflow: hidden;
}

._1gcv-qjx6syM:hover,
._1oiFm_MOP1U_ {
	box-shadow: 0 2px 13px rgba(0, 0, 0, 0.12);
}

.QZRZclfaoHHM {
	border-top: 1px solid #d9d9d9;
}

._3qv_jCDkzd6j {
	display: flex;
	overflow-x: auto;

	padding: 28px 40px;
}
