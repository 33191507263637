

div._24-8UJ6BBb7E {
	display: none;
}

._2GV40iLmuvXL {
	display: none;
}

._8fSEKX-s5Iyi > span {
	font-size: 14px;
}

._3Mzgen0gk9E1 {
	pointer-events: none;
}

span._2KrMaoRJZE17 {
	font-weight: 700;
	font-size: 14px;
	color: var(--text-base);
}

.XF5vyFtsaVb_ {
	min-width: 280px;
	max-width: 280px;
	padding-bottom: 0;
}

._3wxPl5y9ZlMZ {
	color: var(--gray80);
}

._19kgM9wjWk0T {
	z-index: 10;
}

.QmDDFXGDX9WQ {
	flex-basis: 270px;
}

._2JvkA1PP8Fr0 {
	width: 54%;
}

._3zVIbOrvfBlO {
	color: var(--text-mid);
	font-weight: 400;
	font-size: 13px;
	line-height: 100%;
}

div.zPxwbBztQ4BG.zPxwbBztQ4BG > span {
	width: 28px;
	height: 28px;

	border: 1px solid transparent;
	border-radius: 50%;

	color: var(--gray50);

	transform: inherit;
	background: var(--gray05) !important;

	transition: color 0.15s, border 0.15s;
}

div.zPxwbBztQ4BG > span:hover {
	border: 1px solid var(--gray10);

	color: var(--gray80);
}

div.zPxwbBztQ4BG > span:before,
div.zPxwbBztQ4BG > span:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 46%;
	height: 1px;

	background: currentColor;
	transform: translate(-50%, -50%) rotate(45deg);
}

div.zPxwbBztQ4BG > span:after {
	transform: translate(-50%, -50%) rotate(-45deg);
}

div.zPxwbBztQ4BG > span > svg {
	display: none;
}

._10hb-wYvBmlJ {
	margin-bottom: 12px;

	box-shadow: none;
}

div._1tSbMTdt5pz4 {
	min-height: 84px;
}

._2KJjF-mvRC78 {
	color: var(--brand-1-0);
	font-size: 13px;
}

div._2XL4Qt13tPfN {
	font-weight: 700;
	font-size: 24px;
}

._31A6jaxSGa9J {
	border-top: 1px dashed var(--line-dot-line) !important;
}

div._2ZDSMiFi5GzE {
	min-height: 19px;

	border-top: 1px dashed var(--line-dot-line);
}

div.n0nHQXgN_wq2 {
	top: 29px;

	color: var(--error-text);
	font-weight: 400;
	font-size: 13px;
}

div._1NqTTWbOcB36 {
	bottom: 7px;

	color: var(--error-text);
	font-weight: 400;
	font-size: 13px;
}

.n0nHQXgN_wq2 > div {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin: 0 auto;

	background-color: var(--bg-white);
}

._32VM-0x7JrHx {
	transform: scale(0.9);
}

div.ZLZvi9DK62pz {
	min-width: 100px;

	font-size: 13px;
}

.ZLZvi9DK62pz > div {
	font-weight: 700;
}

.ZLZvi9DK62pz > div:first-child {
	margin-bottom: 4px;
}

.ZLZvi9DK62pz > div:not(:first-child) {
	margin-bottom: 0;
}

._2664jE4lLaTm {
	align-items: flex-start;
}

.yZcivJXpsH5u {
	min-width: 66px;
}

._3paCsIL2INeG {
	justify-content: center;
}
