

._2O8ntL-JHSv8 {
	width: 100%;

	border-spacing: 0;

	font-size: 14px;
}

._2O8ntL-JHSv8 td {
	height: 47px;
	padding: 0 12px;
}

td._38PnAWZMacsM,
td._9iFSn-TkoiQD {
	color: var(--base);
	font-weight: 500;
}

td.Ik20z7Yvmw63 {
	font-weight: 500;
}

._2O8ntL-JHSv8 thead {
	color: #ffffff;
	font-weight: 500;

	background: var(--base);
}

._2O8ntL-JHSv8 tbody tr {
	color: var(--gray90);

	cursor: pointer;
	background: #ffffff;
}

._2O8ntL-JHSv8 tbody tr._2oncYGUlMoYr {
	background: var(--success90);
}

._23ztUqQqN72X {
	display: flex;
	align-items: center;

	font-weight: 500;
}

._23ztUqQqN72X svg {
	padding-top: 5px;
	flex-shrink: 0;
}
