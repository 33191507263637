

._3puC9spNwIc9 {
	display: flex;
	margin: 20px 0 20px;
	max-width: 1100px;
}

._2hjkppZj6bUd {
	display: flex;
	align-items: center;
	max-height: 37px;

	padding-left: 16px;

	border-radius: 18px;

	cursor: pointer;

	background-color: var(--success);
}

._24HW0nOv7UNx {
	display: flex;
	align-items: center;
	padding: 10px 16px 10px 8px;

	font-size: 14px;
	color: #ffffff;
	text-decoration: underline;
}

._24HW0nOv7UNx > svg {
	margin-right: 8px;
}

.e_N7SrJVi9K6 {
	padding-left: 0;
}
