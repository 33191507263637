._3mZYOq5unqlA {
	margin: 0 auto 12px;
	padding: 24px 20px;
	max-width: 1100px;

	border-radius: 4px;

	color: #ffffff;

	background-color: var(--thanks-banner-color);
}

.dgSkxpgBL6Mv {
	margin: 0;

	line-height: 24px;
	font-size: var(--thanks-banner-font-size);
}

._3BZv1QMWEgQM {
	color: var(--cabinet-action);
	text-decoration: underline;
}

.n_vn7Yhz0ZPo {
	position: absolute;
	left: 20px;
	bottom: -24px;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;

	border-radius: 50%;

	background: #5fca43;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 600px) {
	._3mZYOq5unqlA {
		position: relative;

		padding: 20px 20px 44px;
		margin: 0;

		border-radius: 0;

		color: #727272;
		font-size: 15px;
		line-height: 150%;

		background: #ffffff;
	}

	.dgSkxpgBL6Mv {
		margin-bottom: 12px;

		color: #313131;
		font-size: 20px;
		line-height: 150%;
		font-weight: 400;
	}
}
