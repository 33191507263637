._3jAXU0QwiQux {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;

	border-radius: inherit;

	background: var(--brand-1-4);
}
