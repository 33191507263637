

button._10bHWJWXxi8D {
	border-color: var(--gray05);

	color: var(--gray70);
	font-weight: 500;

	font-size: 17px;
	line-height: 20px;

	background: var(--gray05);
}

button._10bHWJWXxi8D:hover,
button._10bHWJWXxi8D:focus,
button._10bHWJWXxi8D:active {
	border-color: var(--gray05);

	background: var(--gray05);
}

button._10jr-9RhZPYh {
	font-size: inherit;
}

button._3wHNfZeB525S {
	background: #ffffff;
}

button._3wHNfZeB525S:hover,
button._3wHNfZeB525S:focus,
button._3wHNfZeB525S:active {
	background: #ffffff;
}

button._3o9To9qXn3gB {
	border-color: var(--base);

	color: #ffffff;

	background: var(--base);
}

button._3o9To9qXn3gB:hover,
button._3o9To9qXn3gB:focus,
button._3o9To9qXn3gB:active {
	border-color: var(--base);

	color: #ffffff;

	background: var(--base);
}

button._36y4Nx4i4VW3 {
	text-transform: uppercase;
}

button.vaKpiqGFLMCa {
	padding: 0;

	border-radius: 50%;
}

button.vaKpiqGFLMCa._3zWAf3iN4xeI {
	width: 36px;
	min-width: 36px;
	height: 36px;

	line-height: 36px;
}

button.vaKpiqGFLMCa._2zRaoDaC5Xop {
	width: 28px;
	min-width: 28px;
	height: 28px;

	line-height: 28px;
}

button.vaKpiqGFLMCa._21GHEbSUZ6QS {
	width: 44px;
	min-width: 44px;
	height: 44px;

	line-height: 44px;
}
