.i258rI1_vLsv {
	padding: 40px 28px 28px;
}

._3XZh0oFl-BNj {
	margin-bottom: 10px;

	font-weight: 500;
	font-size: 22px;

	color: var(--dark-gray);
}

._5pGjiUMnFd52 {
	margin-top: 10px;

	font-size: 15px;
	color: var(--common-gray);
}

.gY4Y3zmUm5Em.EjP9qRX7IlTO {
	text-decoration: line-through;
}

@media screen and (max-width: 600px) {
	._3XZh0oFl-BNj {
		font-size: 18px;
	}

	._5pGjiUMnFd52 {
		margin-top: 16px;

		font-size: 14px;
	}
}

.p7_SUkrVSoz3 {
	display: flex;
	justify-content: flex-end;
	margin-top: 28px;
}

button._2w8_FrKPtc2M {
	margin-right: 12px;

	color: #777777;

	background: #ededed;
}

button._2w8_FrKPtc2M:hover {
	color: #777777;

	background: #ededed;
}

.Dd2GCv65IsSN {
	color: var(--dark-red);
	font-size: 16px;
	font-weight: 500;
}

._3w1Ut6ZKmzkL ._3XZh0oFl-BNj {
	color: #009660;
}

@media screen and (max-width: 600px) {
	.i258rI1_vLsv {
		padding: 20px;
	}

	.p7_SUkrVSoz3 button {
		font-size: 13px;
	}
}
