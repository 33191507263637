

._1p39GsAmtu08 {
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	height: 44px;

	border-right: 1px solid rgba(255, 255, 255, 0.2);

	color: #ffffff;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	line-height: 1.5;

	opacity: 0.5;

	cursor: default;
}

._1p39GsAmtu08._2o_fAgiPI0BJ,
._1p39GsAmtu08._3kKw18GCMFAm {
	opacity: 1;
}

._1p39GsAmtu08._3kKw18GCMFAm {
	border-right: none;

	color: var(--base);

	background: var(--white);
}

._3S__t7vkcn_- {
	margin-left: 8px;
}

._1p39GsAmtu08._2o_fAgiPI0BJ:hover ._3S__t7vkcn_-,
._1p39GsAmtu08._3kKw18GCMFAm:hover ._3S__t7vkcn_- {
	text-decoration: underline;
}

.u32d5F-CgWFa {
	cursor: pointer;
}
