

._3bmbmJ5mV2SQ {
	position: absolute;
	right: 0;
	bottom: -13px;
	left: 0;
	z-index: 2;

	padding: 0 4px;

	color: var(--error-20);
	font-size: 14px;
	text-align: center;

	transform: translateY(100%);
}

._4X3OVvLN5LIb {
	padding: 0 4px;

	background: #ffffff;
}
