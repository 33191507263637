._1Hx5n3wV95Qt {
	padding: 40px;
}

._3FUlcO5QXTfE {
	height: 100%;
	min-height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

._2yzUrhd5_rDN {
	color: #b7b7b7 !important;
}

._2VQVSFfa_5Qv {
	display: flex;
}

.j4ZYgCWm1hZe {
	display: flex;
	flex-direction: column;
}

.zlXwbuR_-mRl {
	position: relative;

	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin-right: 2px;
}

._13y3B9Zdchez {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;

	border: 1px solid rgba(50, 144, 255, 0.6);

	background: rgba(50, 144, 255, 0.07);

	transition: height 100ms;
}

._2F8u2Bsv2cu5 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 56px;
	padding: 0 10px;

	color: #ffffff;

	font-weight: var(--compareFares-heading-item-font-weight);
	line-height: 19px;

	background: var(--common-blue);
}

._3XdDd-NvdU8z {
	display: var(--compareFares-serviceClass-display);
}

.FDh5-lKX4cE5 {
	background: var(--dark-violet);
}

._2laWOWT9NyhY {
	position: relative;
	top: 2px;
}

._3LxfEBtiUPq7 {
	flex: 25%;

	min-width: 270px;
}

.M9U-nRVnhqQD ._3LxfEBtiUPq7 {
	position: relative;
	left: 10px;

	display: flex;
	align-items: center;
	min-width: 280px;
}

._31qMQ_a9Gkpt {
	min-width: 0;
	flex: 75%;
}

._31qMQ_a9Gkpt:first-child > div > div {
	overflow-y: visible;
}

.M9U-nRVnhqQD ._31qMQ_a9Gkpt .slick-track {
	display: flex;
	align-items: center;
}

._3rwPy86mez1p ._31qMQ_a9Gkpt .slick-list {
	display: flex;
}

._3rwPy86mez1p ._31qMQ_a9Gkpt .slick-track {
	display: flex;
	align-content: flex-start;
}

._31qMQ_a9Gkpt .slick-prev svg {
	position: relative;
	left: -1px;
}

._31qMQ_a9Gkpt .slick-next svg {
	position: relative;
	left: -1px;
}

._31qMQ_a9Gkpt .slick-prev,
._31qMQ_a9Gkpt .slick-next {
	top: 30px;
	left: -85px;

	display: flex !important;

	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	background: #ededed;
}

._31dipaMo2WOQ {
	display: flex;
	justify-content: flex-end;
	margin: 12px 10px 0 0;
}

._1DFJ1HUNcxzs {
	display: flex;

	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	cursor: pointer;

	background: #ededed;
}

._2nK4fqfBBTt6 {
	margin-left: 15px;

	transform: rotate(180deg);
}

._31qMQ_a9Gkpt .slick-next {
	left: -42px;

	transform: rotate(180deg) translate(0, 50%);
}

._31qMQ_a9Gkpt .slick-disabled {
	opacity: 0.5;
}

._31qMQ_a9Gkpt .slick-prev:before,
._31qMQ_a9Gkpt .slick-next:before {
	display: none;
}

.FnJAZZQQq_ct {
	margin-top: 5px;
}

._2ysxijyMSw_R {
	margin-right: 5px;
}

.mYhMzIv7W5Fb {
	margin-top: 200px;
}

._13If6CSmqIXP {
	min-height: 40px;

	color: var(--common-blue);
	font-weight: 300;
	font-size: 30px;
	line-height: 36px;
	text-align: left;
	white-space: pre-line;
}

._13If6CSmqIXP,
._3l2PzMYyPujV {
	padding: 30px 0 20px;

	border-bottom: 1px solid #ededed;
}

._3l2PzMYyPujV {
	min-height: 87px;
}

._2tVVFstNcfZ_ {
	display: flex;
	justify-content: center;
	align-items: center;

	line-height: 16px;
	font-size: 14px;
	color: var(--dark-gray);
}

._39PqJUzbkBAP {
	max-width: 178px;
}

._1xo84ElLWq3I {
	display: flex;
	justify-content: center;
	align-items: center;
}

.p4Wk9Skxb8Le {
	padding: 0 10px;
	justify-content: flex-start;
}

._20g9pE5-aAZv {
	margin-top: 15px;
}

.M9U-nRVnhqQD {
	padding: 10px 0;
	display: flex;

	border-bottom: 1px solid #ededed;
}

.M9U-nRVnhqQD:nth-of-type(even) {
	background: #f6f6f6;
}

._25NUkBfwG70_ {
	justify-content: flex-start;
	padding-left: 12px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}

._3mu5TEGas31T {
	margin-top: 45px;
	display: inline-block;

	font-weight: 500;
	text-decoration: none;
}

._1SL-Kzbhr-Io {
	position: relative;

	top: -1px;

	display: inline-block;
	margin-left: 10px;

	transform: rotate(180deg);
}

._2pWkMkT_KQjy {
	top: 0;

	margin: 0 10px 0 0;

	transform: none;
}

._3NCYBLY1lz75 {
	margin: 40px 0 20px;

	color: var(--common-blue);
	font-weight: 300;
	font-size: 31px;
	line-height: 36px;
}

._17EhNeZ76Azs {
	margin: 0;
	padding: 0;

	color: var(--dark-gray);
	font-size: 14px;
	line-height: 21px;

	list-style-type: none;
}

.cXzCYORswmlF:not(:last-of-type) {
	margin-bottom: 20px;
}

._1t09TDaxks2a {
	margin: 0;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 22px;
	line-height: 26px;
}

.DuavA9cUZ1LM {
	line-height: 22px;
	color: var(--common-gray);
}

._1TtT2q4ZbHq1 {
	width: 100%;
}

@media (max-width: 1024px) {
	.ILkDqhiz93wl {
		display: none;
	}

	div._1Hx5n3wV95Qt {
		padding: 0;
		height: 100%;

		box-shadow: none;
	}

	._2VPQ9nUAfA3s {
		padding: 20px;
	}

	._2F8u2Bsv2cu5 {
		font-size: 21px;
		line-height: 25px;
		font-weight: 300;
	}

	._31qMQ_a9Gkpt .slick-prev,
	._31qMQ_a9Gkpt .slick-next {
		top: 65%;

		width: 40px;
		height: 40px;
	}

	._31qMQ_a9Gkpt .slick-prev {
		left: 0;
		z-index: 1;
	}

	._31qMQ_a9Gkpt .slick-next {
		right: 0;
		left: auto;
	}

	._3LxfEBtiUPq7 {
		flex: 60%;

		font-size: 14px;
		font-weight: 600;
	}

	._13If6CSmqIXP {
		padding-bottom: 10px;

		font-size: 27px;
	}

	._1TtT2q4ZbHq1 {
		width: auto;
		height: 178px;
		margin: 0 auto;
		margin-top: 20px;
	}

	._3mu5TEGas31T {
		display: none;
	}

	.zlXwbuR_-mRl {
		margin-right: 0;
	}

	._1z_7NK6-timo .slick-next,
	._1z_7NK6-timo .slick-prev {
		top: 28px;

		border: 2px solid rgba(255, 255, 255, 0.2);

		background: transparent;
	}

	._1z_7NK6-timo .slick-prev {
		left: 10px;
	}

	._1z_7NK6-timo .slick-next {
		right: 10px;
	}

	._1z_7NK6-timo .slick-next svg *,
	._1z_7NK6-timo .slick-prev svg * {
		fill: #ffffff;
	}

	._39PqJUzbkBAP {
		max-width: unset;
	}
}
