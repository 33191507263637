

.eT0os1eNL2u_ {
	padding: 30px;
}

._2ePZSl5Et3Zt {
	margin: 0;

	font-size: 28px;
	line-height: 35px;
}

._1v09qB8-QJM3 {
	margin-top: 15px;
	padding-right: 30px;

	color: var(--light-gray);
	font-size: 18px;
	line-height: 130%;
}

._2FNxBuktwPqR {
	margin-top: 35px;
}

._2VruMfLDEQrP {
	margin-top: 20px;
	margin-bottom: 0;

	color: var(--link-color);
	font-size: 15px;
	line-height: 22px;

	cursor: pointer;
}

button._1AeEpND2triW {
	margin-top: 40px;
	height: 64px;
	width: 100%;

	color: #595959;
	font-size: 17px;
	font-weight: 500;
	line-height: 64px;
}

button._1AeEpND2triW,
button._1AeEpND2triW:hover {
	background-color: #e4e4e4;
}
