

._3PptWoQJXdJS {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.p6Ziw_4Cyaea {
	min-width: auto;
	height: 34px;
	margin-left: 12px;
	padding: 0 16px;

	border: 1px solid transparent;

	font-weight: 500;

	font-size: 14px;
	line-height: 34px;
}

div.p6Ziw_4Cyaea {
	margin-left: 15px;
}

button.p6Ziw_4Cyaea._3FBPP6r7xVjd {
	border: 1px solid #add3ff;

	color: var(--text-link);

	background: var(--base90);
}

button.p6Ziw_4Cyaea._3FBPP6r7xVjd:hover {
	opacity: 0.8;
}

._25vRrMAQfgie {
	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
	color: var(--text);
}

._2eqQHW1v9Fd1 {
	color: var(--gray80);
}
