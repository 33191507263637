

._1jnjYbuCD_DL {
	display: flex;
	justify-content: space-between;
}

._1xFsotnDQd9w {
	width: 8px;
	height: 8px;

	border-radius: 50%;

	cursor: pointer;
	background: #b3b3b3;
}

._1VWbXi5qtWb1 {
	background: #4d4d4d;
}
