._3gVhWRQViaK3 {
	display: inline-flex;
	align-items: center;
	height: 40px;
	margin-right: 12px;
	margin-bottom: 10px;
	padding: 0 12px 0 21px;

	border-radius: 100px;

	color: var(--base-15);

	font-weight: 600;
	font-size: 16px;

	cursor: pointer;
	background: var(--base90);
}

._2bHihv8S2NXn {
	padding: 0 21px;

	pointer-events: none;
}

._3gVhWRQViaK3 > svg {
	display: none;
}

._5TK2yBAUD-Te {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	margin-left: 10px;

	border-radius: 50%;

	color: var(--gray30);

	background: #ffffff;
}

@media (max-width: 1024px) {
	._3gVhWRQViaK3 {
		position: relative;

		width: 100%;
		height: auto;
		margin: 0;
		padding: 16px;

		border-radius: 0;

		color: #ffffff;
		font-weight: 500;
		font-size: 16px;

		background: var(--base);
	}

	._3gVhWRQViaK3:first-child {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	._3gVhWRQViaK3:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	._3gVhWRQViaK3:not(:first-child) {
		border-top: 1px solid #6fb1ff;
	}

	._5TK2yBAUD-Te {
		position: absolute;
		right: 16px;

		color: #ffffff;

		background: #5ba6ff;
	}

	._3gVhWRQViaK3 > svg {
		display: block;
		margin-right: 10px;
	}
}
