

.voDO7ngZvDsT {
	margin-left: 8px;

	color: var(--base);
}

.x9SSIsip_WMt._35xAlb3C8Iwd,
.x9SSIsip_WMt._35xAlb3C8Iwd:hover,
.x9SSIsip_WMt._35xAlb3C8Iwd:focus {
	border: 1px solid var(--base);

	font-size: 14px;

	color: var(--base);
}
