

._2oBgkLEIuBzA {
	margin: 0;

	font-weight: 500;
	font-size: 21px;
}

._1HWyv3PXQEWS {
	padding: 40px;
}

.YQiQPpKghd_j {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 1;
}

.YQiQPpKghd_j > div {
	background-color: var(--gray05);
}

._2SPMXULn3fue {
	margin: 28px 0;
}

._2TjWz5WvKI2t {
	align-self: flex-end;
}

._1-tMqwIX5XHh {
	width: 120px;
}

button._1kfAd_qq0ONh {
	border: none;
}
