._3zwUy3lSSbCZ {
	display: flex;
	justify-content: space-between;
	max-width: 1100px;
	margin: 0 auto;
	margin-bottom: 12px;
	padding: 22px 20px;

	border-radius: 4px;

	background: linear-gradient(180deg, #ff6767 0%, #e44545 100%);
}

._3DzJLXWlH4q- {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 12px;

	border-radius: 7px;

	background: #ffffff;
}

@media screen and (max-width: 600px) {
	._3DzJLXWlH4q- {
		padding: 5px 5px;
		width: 94px;
		height: 38px;
	}
}

.SWODhDjUJpCT {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;

	color: #ffffff;
}

button._3cKzsp1Dh05L {
	border-radius: 7px;

	background: rgba(0, 0, 0, 0.2);
}

button._3cKzsp1Dh05L:focus,
button._3cKzsp1Dh05L:active,
button._3cKzsp1Dh05L:hover {
	background: rgba(0, 0, 0, 0.1);
}
