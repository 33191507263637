._3PObM_U2_TxJ {
	display: flex;
}

._3PObM_U2_TxJ:not(:only-child) {
	margin-top: 44px;
}

._15SiGjbfyyd6 {
	max-width: 33%;
}

._1E_vhH8kFCB8 {
	display: inline-flex;
}

._3Ys-dAqcACYD {

	display: inline-block;

	max-width: 125px;

	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;

	list-style-type: none;
}

.JkC6SgPjWp47 {
	display: inline-block;
}

.JkC6SgPjWp47:not(:last-of-type) {
	margin-right: 3px;
}

._2dzyXT8aeiRe {
	font-size: 16px;
	font-weight: 600;
	color: var(--service-totalPrice-color);
	text-decoration: none;
}

._2ZLaax23lU1g {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 80px;
	min-height: 80px;

	box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

._2V9bD2t6AXM8 {
	padding: 24px 40px 40px;

	display: flex;
	justify-content: flex-end;
}

._2l2OM94-hKDo {
	display: flex;
	align-items: center;
	height: 32px;
	margin-left: 20px;

	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: var(--common-blue);
	text-decoration: none;

	cursor: pointer;
}

._2l2OM94-hKDo svg {
	height: 20px;
	width: 20px;
	margin-right: 4px;
}

@media screen and (max-width: 600px) {
	._3PObM_U2_TxJ {
		flex-direction: column;
		align-items: center;
	}
}
