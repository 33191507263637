

._13_6tRGuLTjH {
	display: flex;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 12px;
	overflow-y: auto;
}

button.DgdNKNfC0e1z {
	flex-shrink: 0;
	width: 58px;
	height: 58px;

	border-radius: 50%;

	color: var(--brand1);
	font-weight: 700;
	font-size: 16px;

	background: var(--brand1-5);
}

button.DgdNKNfC0e1z.TLtXk2xAo1yF {
	color: #ffffff;

	background: var(--brand1);
}

button.DgdNKNfC0e1z:not(:last-child) {
	margin-right: 20px;
}

.GsY9XNCVxuLu {
	color: var(--text-light);
	font-size: 14px;
	line-height: 150%;
}

._13_6tRGuLTjH._123dCCsjh08q .GsY9XNCVxuLu {
	display: none;
}

._26TJrAeugiME {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
	padding: 12px 0;

	border-bottom: 1px solid #d9d9d9;

	color: var(--brand1);
	font-weight: 500;
	font-size: 14px;
}

._1R8HnAdp3_70 {
	color: var(--text);
	font-size: 16px;
	font-weight: 700;
}

._2tchOIO56KYD {
	width: 40px;
	height: 70px;
	margin-right: 20px;
	flex-shrink: 0;
}

._2tchOIO56KYD svg {
	width: 40px;
	height: 70px;
}
