.UbO2oh43cc5w {
	margin-top: 28px;
	padding: 20px;

	border-radius: 4px;

	background-color: #f9f9f9;
}

._18IhfY-4uMB6 {
	margin: 0;

	font-size: 14px;
	color: var(--dark-gray);
}

._2S8ShrbpVlIe {
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
}

._2S8ShrbpVlIe > div {
	flex: 1;
}

._2S8ShrbpVlIe > div:first-child {
	margin-right: 10px;
}

._2S8ShrbpVlIe > div:last-child {
	margin-left: 10px;
}

._2S8ShrbpVlIe > div > div:after {
	border-bottom: 2px solid var(--common-blue);
}

._2S8ShrbpVlIe ._37KRHyfxbHHJ._2BMjQYgU_pFV {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	._2S8ShrbpVlIe {
		flex-direction: column;
	}

	._2S8ShrbpVlIe > div:first-child {
		margin-right: 0;
	}

	._2S8ShrbpVlIe > div:last-child {
		margin-left: 0;
		margin-top: 10px;
	}
}
