

._16QonvfVUdf8 {
	position: relative;

	height: auto;

	padding-left: 35px;

	color: var(--common-blue);
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;

	cursor: pointer;
}

._16QonvfVUdf8:before {
	content: '';

	position: absolute;
	left: 0;

	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOSA0SDE4VjJIMTZWNEg4VjJINlY0SDVDMy44OSA0IDMuMDEgNC45IDMuMDEgNkwzIDIwQzMgMjEuMSAzLjg5IDIyIDUgMjJIMTlDMjAuMSAyMiAyMSAyMS4xIDIxIDIwVjZDMjEgNC45IDIwLjEgNCAxOSA0Wk04Ljk5OTk4IDExSDYuOTk5OThWMTNIOC45OTk5OFYxMVpNMTMgMTFIMTFWMTNIMTNWMTFaTTE3IDExVjEzSDE1VjExSDE3Wk01LjAwMDAxIDIwSDE5VjlINS4wMDAwMVYyMFoiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==) center no-repeat;
}

._2p-zLlYW0VeS {
	position: relative;

	width: 100%;
	height: 100%;
	padding: 20px;
	padding-top: 0;
}

._3KHNWpj3WxtL {
	position: relative;

	height: 100%;
}
