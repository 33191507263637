

._103u1NpbKCwx {
	display: flex;
}

.TRvjTkH3xRdU {
	width: 125px;
	height: 85px;
	flex-shrink: 0;
	margin-right: 15px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzcyIiBoZWlnaHQ9IjI3MSIgdmlld0JveD0iMCAwIDM3MiAyNzEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2QpIj4KPHBhdGggZD0iTTIuNzQwMjMgMzQuNTM2M1YyLjk1MTE3SDM0LjMyNTQiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMy45NDgxNCIvPgo8cGF0aCBkPSJNMzM1Ljk2MyAyLjk1MTE3TDM2Ny41NDggMi45NTExN0wzNjcuNTQ4IDM0LjUzNjMiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMy45NDgxNCIvPgo8cGF0aCBkPSJNMzY3LjU0OCAyMzUuMTAyTDM2Ny41NDggMjY2LjY4N0wzMzUuOTYzIDI2Ni42ODciIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMy45NDgxNCIvPgo8cGF0aCBkPSJNMzQuMzI1NCAyNjYuNjg3TDIuNzQwMjMgMjY2LjY4N0wyLjc0MDIzIDIzNS4xMDIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMy45NDgxNCIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2QiIHg9IjAuNzY2NjAyIiB5PSIwLjk3NzExMiIgd2lkdGg9IjM3MC4zMzUiIGhlaWdodD0iMjY5LjI2MyIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIvPgo8ZmVPZmZzZXQgZHg9IjEuNTc5MjYiIGR5PSIxLjU3OTI2Ii8+CjxmZUNvbG9yTWF0cml4IHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjQgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvdyIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvdyIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K) no-repeat center;
	background-size: contain;
}

._1r6vQEjxldSx {
	margin: 8px;
	width: calc(100% - 16px);
	height: calc(100% - 16px);

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA4IiBoZWlnaHQ9Ijc0IiB2aWV3Qm94PSIwIDAgMTA4IDc0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTA3Ljk5OSAwLjM5NTYzSDAuMzk5NDE0VjczLjYwNDVIMTA3Ljk5OVYwLjM5NTYzWiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyKSIvPgo8cGF0aCBkPSJNMTA3Ljk5OSA1OC4yODE2SDAuMzk5NDE0VjczLjYwNDRIMTA3Ljk5OVY1OC4yODE2WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTQyLjk2MzMgOS45Mjk2OUg5LjU5Mzc1VjQ5Ljc2ODlINDIuOTYzM1Y5LjkyOTY5WiIgZmlsbD0iI0U0RTRFNCIvPgo8cGF0aCBkPSJNNjcuNDc5NCA5LjkyOTY5SDUwLjQ1NDFWMTIuNjUzN0g2Ny40Nzk0VjkuOTI5NjlaIiBmaWxsPSIjRTRFNEU0Ii8+CjxwYXRoIGQ9Ik02Ny40Nzk0IDI0LjU3MTVINTAuNDU0MVYyNy4yOTU2SDY3LjQ3OTRWMjQuNTcxNVoiIGZpbGw9IiNFNEU0RTQiLz4KPHBhdGggZD0iTTY3LjQ3OTQgMzkuMjEzM0g1MC40NTQxVjQxLjkzNzRINjcuNDc5NFYzOS4yMTMzWiIgZmlsbD0iI0U0RTRFNCIvPgo8cGF0aCBkPSJNOTAuMjkzMyAxNC42OTY5SDUwLjQ1NDFWMjAuMTQ1SDkwLjI5MzNWMTQuNjk2OVoiIGZpbGw9IiNFNEU0RTQiLz4KPHBhdGggZD0iTTkwLjI5MzMgMjkuMzM4Nkg1MC40NTQxVjM0Ljc4NjdIOTAuMjkzM1YyOS4zMzg2WiIgZmlsbD0iI0U0RTRFNCIvPgo8cGF0aCBkPSJNOTAuMjkzMyA0My45ODAzSDUwLjQ1NDFWNDkuNDI4NEg5MC4yOTMzVjQzLjk4MDNaIiBmaWxsPSIjRTRFNEU0Ii8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXIiIHgxPSIxMDgiIHkxPSI0NC4zMjA5IiB4Mj0iNDcuMzIxNCIgeTI9Ii03LjIwMTkzIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiNGRkY4QkEiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRkZGRUY4Ii8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==) no-repeat center;
	background-size: contain;
}

.TG2yPP5J_Spt {
	font-weight: 400;
	line-height: 1.3;
}
