._1VmV3AAVBInj {
	margin-right: 8px;
	display: flex;
	align-items: center;
}

._1BL2iskOf70c {
	position: relative;
}

._1BL2iskOf70c:nth-child(2) {
	margin: 0 30px;
}

.BhrKwPjblfiJ {
	position: absolute;
	right: -14px;
	top: 7px;

	z-index: 1000;

	height: 28px;

	border-radius: 50%;
	border: 4px solid #ffffff;

	cursor: pointer;

	background-color: #ffffff;
}

.BhrKwPjblfiJ svg {
	height: 20px;
	width: 20px;
}

.BhrKwPjblfiJ svg path {
	transition: all 0.2s;

	fill: #cdcdcd;
}

.BhrKwPjblfiJ:hover svg path {
	fill: #9a9a9a;
}

._1BL2iskOf70c button._3UI9ghtxHjIo {
	justify-content: center;
}

._1BL2iskOf70c button._1oPV-59rKm6m {
	border-color: #3c5b96;

	color: #ffffff;

	background-color: #3c5b96;
}

._1BL2iskOf70c > button {
	padding: 7px 17px;
	justify-content: flex-start;
	align-items: center;

	font-weight: 600;
	line-height: 22px;
}

._1BL2iskOf70c > button._2iaGoIeMcC43 {
	border-color: #59adeb;

	color: #ffffff;

	background-color: #59adeb;
}

._2kwlUfRtBhQz {
	opacity: 0.5;
}

.BhrKwPjblfiJ._1WMWcjPhlYow svg path {
	fill: var(--cabinet-action);
}

._1BL2iskOf70c > button._28NpNIgG3VvP {
	border: 1px solid rgba(0, 0, 0, 0.23);

	color: var(--dark-gray);
}

._1BL2iskOf70c > button._28NpNIgG3VvP._1oPV-59rKm6m,
._1BL2iskOf70c > button._28NpNIgG3VvP._2iaGoIeMcC43 {
	border-color: inherit;

	color: #ffffff;
}

@media screen and (max-width: 600px) {
	._1BL2iskOf70c:nth-child(2) {
		margin: 20px 0;
	}

	._1BL2iskOf70c button {
		width: 100%;
		justify-content: center;
	}
}
