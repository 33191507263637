

button.Dg-uus8zHyaz {
	padding: 20px 27px;

	color: var(--base);

	background: var(--gray05);
}

button.Dg-uus8zHyaz.mYXtMsQpTAYk {
	background: var(--base80);
}

._28CmomJWLng6 {
	color: var(--gray80);
	font-size: 16px;
	line-height: 24px;
}

._28CmomJWLng6.mYXtMsQpTAYk {
	color: var(--base);
}

span._2VXwCmMyaqQ4.mYXtMsQpTAYk {
	color: var(--base);
}
