

._2y1NEs2ccjIb {
	display: flex;
	align-items: center;
}

._23vilO_O51-L {
	margin-right: 10px;

	color: var(--gray50);

	transform: translateY(1px);
}

._2ep7tRFatN9r {
	flex-basis: 25%;
	margin-left: auto;

	font-weight: 400;
	font-size: 15px;
	text-align: right;
	text-decoration: none;
}
