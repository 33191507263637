

.iQ-qjiRs7_XF {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 28px;
}

._3EKAzLYYGH6v {
	display: flex;
	margin-bottom: 20px;

	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
	text-align: center;

	color: var(--dark-gray);
}

._112iH8et-QTy {
	display: flex;
	flex-direction: column;
	align-items: center;
}

button._2ey9DRXwcuVU,
button.dCq4B4x3m7Ik {
	width: 200px;
	height: 40px;

	font-size: 15px;
}

button.dCq4B4x3m7Ik {
	margin-top: 12px;

	font-weight: 400;
	color: #777777;

	background: #ededed;
}

button.dCq4B4x3m7Ik:hover {
	background: #e0e0e0;
}
