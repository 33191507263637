

._3G_3bzECVhE9 {
	position: relative;
	z-index: 0;

	display: flex;
	align-items: flex-end;

	background: var(--base15);
	background-position: center center;
	background-size: cover;
}

._2KqaHxm8Jt0L {
	cursor: pointer;
}

._17xgQTeWx8c_ {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 10;

	width: 100%;
	height: 61%;

	background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
}

.rTxKRMKFv1bQ {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;

	flex-grow: 1;
	padding: 24px;

	color: var(--white);
	text-align: center;
}

._2Y0NpcNs81AI {
	font-size: 18px;
	font-weight: 700;
	line-height: 1.2;

	text-transform: uppercase;
}

._2Y0NpcNs81AI._1AiJyzmXqw1L {
	font-size: 25px;
}

.nqI2L4-ng-8e {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.nqI2L4-ng-8e ._2Y0NpcNs81AI:not(:last-child):after {
	content: '\2022';

	display: inline-block;
	padding: 0 5px;
}

.BrTkkrBvQBwl {
	margin-top: 4px;

	font-size: 17px;
	line-height: 1.3;
}
