.SX15i0GIGqzB {
	position: relative;

	flex-grow: 1;
	margin-right: 4px;
}

._31Lnu_JXw1kM {
	display: inline-flex;
	align-items: center;

	cursor: pointer;
	overflow: hidden;
}

._3Dv9EUQmeNdK {
	position: absolute;

	z-index: 10;

	width: 100%;
	margin-top: 2px;
	padding: 4px;

	border-radius: 4px;

	color: var(--brand-1-0);
	font-weight: 500;
	font-size: 15px;

	background: #ffffff;
}

._2E9hJySeX-lX {
	padding: 12px;

	cursor: pointer;

	transition: background-color 0.2s;
}

._2E9hJySeX-lX:hover {
	background: var(--brand1-5);
}

._3Z8hCRQaB6a8 {
	position: absolute;
	right: 12px;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: calc(12px * 2);
	height: calc(12px * 2);

	color: #ffffff;

	cursor: pointer;

	transition: transform 0.15s;

	pointer-events: none;
}

._1HuECqXqYBvP {
	transform: rotate(180deg);
}

@media screen and (max-width: 600px) {
	._3Dv9EUQmeNdK {
		position: relative;
	}

	._31Lnu_JXw1kM {
		justify-content: center;

		font-size: 16px;
	}

	._31Lnu_JXw1kM:focus {
		color: var(--text-base);
	}

	.SX15i0GIGqzB {
		margin-right: 0;
		margin-top: 6px;
	}
}
