._1VDum-Yd_eBE {
	display: flex;
	align-items: center;
	margin: 26px 4px 0 0;
	width: 100%;
}

._3H31oUB004jd {
	display: flex;
	margin-right: 18px;
}

._20s3KCP2kIjY {
	color: #ffffff;
	font-size: 15px;
	font-weight: 500;

	cursor: pointer;
}

._3JP1txAfm-Ql {
	z-index: 1501 !important;
}

._1TNXcVnCo-VA {
	display: flex;
	align-items: center;
	margin-right: 50px;

	color: var(--gray);
}

._34qd4XUXtVJJ {
	max-width: 250px;

	cursor: pointer;
}

._2xy2ykV_sHXK {
	margin-left: 5px;

	line-height: 15px;

	cursor: pointer;
}

div._3-LU55kE224o {
	color: #ffffff;

	background: none;
	background-color: rgba(255, 255, 255, 0.2);
}

._3-LU55kE224o input {
	color: #ffffff;
}

div._3-LU55kE224o:hover {
	background-color: rgba(255, 255, 255, 0.4);
}

._17LKf8PTJWjZ {
	color: #ffffff;
	font-weight: 500;
}

.t_1FoIxYtmDY {
	width: 120px;
	margin-right: 8px;
}

div._3NbJmeOFFXDt {
	width: 100%;
	height: var(--input-height);

	border-radius: 2px;

	cursor: pointer !important;
	background-color: rgba(255, 255, 255, 0.2);
}

._3NbJmeOFFXDt > fieldset {
	display: none;
}

div._3NbJmeOFFXDt:hover {
	background-color: rgba(255, 255, 255, 0.4);
}

div._1ZOiFY5wWIQb {
	background-color: #ffffff;
}

input.yTOkRvNyXHUF {
	color: #ffffff;

	cursor: pointer;
}

._1ZOiFY5wWIQb input.yTOkRvNyXHUF {
	color: #000000;
}

div._3NbJmeOFFXDt:before {
	background: none;
}

.XytjdikZ3JO1 svg path {
	color: #ffffff;

	fill: #ffffff;
}

._1ZOiFY5wWIQb .XytjdikZ3JO1 svg path {
	color: var(--gray30);

	fill: var(--gray30);
}

._1ZOiFY5wWIQb .h60ksFPtxRg1 svg path {
	fill: var(--gray50);
}

._1ZOiFY5wWIQb .h60ksFPtxRg1 svg {
	transform: rotate(180deg);
}

._2FQzc_h4quXT {
	margin-left: 12px;
}

div._3NbJmeOFFXDt._3vPYN928_k7n:before {
	background: none;
}

div._3vPYN928_k7n {
	border-bottom: 0;
}

._3vPYN928_k7n .XytjdikZ3JO1 svg {
	display: block;
}

._3MkFLkmp1nrI {
	width: 712px;
	margin-top: 1px;
	overflow-y: visible !important;
	overflow-x: visible !important;
}

.clC1ThEfNbs6 {
	width: 100%;
}

button.h60ksFPtxRg1 {
	margin-right: 5px;
}

@media screen and (max-width: 600px) {
	._1VDum-Yd_eBE {
		flex-direction: column;
		align-items: flex-start;
	}

	._3H31oUB004jd {
		margin-right: 0;
		margin-bottom: 12px;
		width: 100%;
	}

	._3H31oUB004jd > div {
		flex: 1;
	}

	._3H31oUB004jd input {
		width: 100%;
		max-width: 100%;
		min-width: 0;
	}
}
