.tB_VPi1UDQ2R {
	width: 100%;
	max-width: 374px;
	margin: 0 auto;

	border: 1px solid #e5e5e5;

	border-radius: 0;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);

	background: #ffffff;
}

.tB_VPi1UDQ2R:not(:last-of-type) {
	margin-bottom: 16px;
}

._12YfRqjL0yyH {
	padding: 16px 20px 20px;

	border-bottom: 1px dashed #cccccc;
}

._2PM69_zZH2z- {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 12px;

	color: var(--base-30);
	font-size: 14px;
}

._36pGMHGksav_ ._12YfRqjL0yyH {
	padding: 16px 20px 10px;
}

.tB_VPi1UDQ2R.Qeh27unCj3Vm ._12YfRqjL0yyH {
	opacity: 0.4;
}

._2VWxQkISO7ma {
	color: var(--error);
}

@media screen and (max-width: 320px) {
	._12YfRqjL0yyH {
		padding: 16px 12px 20px;
	}
}

button.HjHooizwL72e {
	display: flex;
	justify-content: center;
	align-items: center;

	min-width: 150px;
	min-height: 44px;

	margin: 16px auto;
	padding: 0 12px;

	border-radius: 100px;

	color: var(--results-mobileFlight-price-text-color);
	font-size: 14px;
	line-height: 18px;

	cursor: pointer;

	background: var(--results-mobileFlight-price-color);
}

._177GPgjBNYyI {
	padding-bottom: 3px;
	align-self: flex-end;
}

._29wuKNy6sYkr {
	padding: 0;
	margin-left: 8px;

	font-size: 25px;
	font-weight: 900;
	color: var(--results-mobileFlight-price-price-color);
}

._2nYunVGo_XM6 ._1Qo9ahoL38YD {
	font-size: 14px;
	font-weight: 400;
}

._iB39pECTKbk {
	margin-bottom: 16px;

	color: var(--gray50);
	font-size: 14px;
	text-align: center;
}

._1DsKfqkEqn1I {
	display: flex;
	align-items: center;
}

._1DsKfqkEqn1I:after {
	display: none;
	width: 24px;
	height: 24px;
	margin-left: 4px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMiAxLjQxTDEwLjU5IDBMNiA0LjU4TDEuNDEgMEwwIDEuNDFMNiA3LjQxTDEyIDEuNDFaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) no-repeat center center;
	content: '';
}

.tB_VPi1UDQ2R svg.recommended {
	bottom: -33px;
}

._36pGMHGksav_ svg.recommended {
	bottom: -176px;
}
