

.FRzC7pU2OvHd.L6eZ3FDIo1UI {
	flex: 1;
	min-height: 54px;
}

.FRzC7pU2OvHd.L6eZ3FDIo1UI:not(:first-child) {
	border-top: 1px solid var(--line-separator);
}

.TiCpBfpqxcTD {
	display: none;
	margin-right: 8px;
}
