

._2Yqq_Z042Bfz {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	width: 100%;

	border-radius: 4px;

	color: #ffffff;

	background-color: var(--common-blue);
}

._1EcaMX9SksRl {
	align-self: stretch;
	padding: 16px 16px 14px 16px;
}

._31vV865jV_z9 {
	display: flex;
	align-items: center;

	opacity: 0.6;
}

._1FcOEoKhh1sz {
	display: flex;
	align-items: center;
	margin-right: 9px;
}

._1FcOEoKhh1sz svg {
	width: 36px;
	height: 36px;
}

._10kPTneoZ7iV {
	font-size: 39px;
	font-weight: 600;
	text-transform: uppercase;
}

.N5PdIsfMW4Dl {
	margin-left: 9px;

	font-size: 13px;
	line-height: 130%;
}

._2hOX4WjRHziG {
	margin-top: 7px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

._2NGj8C38zUUi {
	display: flex;
	align-items: center;

	font-size: 20px;
	font-weight: 600;
	line-height: 120%;
	text-transform: uppercase;
}

._2Fmt7gy56Wz1 {
	margin-left: 6px;
}

._15j0ADCC3ZC- {
	display: flex;
	align-items: flex-end;

	font-size: 13px;
	line-height: 140%;
}

._3-DhW1IKyDlF {
	margin-left: 5px;

	font-size: 20px;
	font-weight: 600;
	line-height: 120%;
	text-transform: uppercase;
}

.QSKtFV3QdHan {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	flex-grow: 1;

	min-height: 118px;
	padding: 16px;

	background-color: var(--base15);
	background-size: cover;
	background-repeat: no-repeat;
}

._2xg3Lu2Nxqiz {
	position: absolute;

	top: 8px;
	left: 50%;

	transform: translate(-50%, 0);
	opacity: 0.4;
}

._2xg3Lu2Nxqiz svg {
	width: 72px;
	height: 72px;
}

._3YsZB1klyRf4 {
	display: flex;
	align-items: center;
}

._3YsZB1klyRf4 svg {
	width: 28px;
	height: 28px;
}

._2XgliY_-zom5 {
	margin-left: 5px;

	font-size: 20px;
	line-height: 100%;
}

.ClerBQUEX_A- {
	display: flex;
	align-items: center;

	font-size: 13px;
	font-weight: 500;
	line-height: 28px;

	cursor: pointer;
}

.ClerBQUEX_A- svg {
	margin-left: 4px;
}
