._2WpOGbLOmIHx {
	position: relative;

	width: 100%;

	background-color: var(--quickSearchForm-background);
}

@media screen and (max-width: 600px) {
	._2WpOGbLOmIHx {
		height: auto;

		background-color: var(--quickSearchForm-background-mobile);
	}
}

._1rXaJiRPVLV5 {
	position: relative;
	z-index: 6;
}

._10Xd8oEM25iq {
	position: relative;

	height: 60px;
	padding: 0 calc((100vw - 1100px) / 2);
	display: flex;
	align-items: center;

	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07);
}

@media screen and (max-width: 600px) {
	._10Xd8oEM25iq {
		height: 46px;
		display: block;

		box-shadow: none;
	}
}

._1grR_xXtpP74 {
	position: absolute;
	top: 60px;
	left: 0;
	z-index: 1;

	width: 100%;

	border-top: 1px solid rgba(255, 255, 255, 0.2);

	background: var(--common-blue);
}

@media screen and (max-width: 600px) {
	._1grR_xXtpP74 {
		position: static;

		border-top: none;
	}
}

._2QmpfsefaEI2 {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	cursor: pointer;
	opacity: 0.7;
	background-color: var(--dark-gray);

	transition: opacity 0.2s;
}

._3ixtnoqeYVY8 {
	z-index: -99999;

	opacity: 0;

	pointer-events: none;
}

.myZ_Ch9ogfA- {
	z-index: -99999;

	opacity: 0;

	animation-duration: 150ms;
	animation-name: _1iNPGuztjUSk;
}

._3ZIsRoQkozsP {
	position: relative;

	display: flex;
	padding: 13px 20px;

	background: var(--quickSearchForm-background-mobile);
}

._32ofKxcsF2Jd {
	margin-left: auto;

	font-size: 14px;
	font-weight: 400;
	color: var(--quickSearchForm-summary-trigger-color);
}

@media screen and (max-width: 600px) {
	._32ofKxcsF2Jd {
		margin: 0 auto;

		color: #ffffff;
	}

	._3ZIsRoQkozsP:before {
		position: absolute;
		top: -1px;

		width: calc(100% - 40px);

		border-top: 1px solid #ffffff;
		content: '';
	}
}

@keyframes _1iNPGuztjUSk {
	from {
		z-index: 1;

		opacity: 1;
	}

	to {
		z-index: -999999;

		opacity: 0;
	}
}
