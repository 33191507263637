._3IX1UxkuabSS {
	padding: 0 50px;
}

._1dlG3fGWrtZ- > span {
	font-family: inherit;
}

span._2rSVnNhWY5qD._1dlG3fGWrtZ- {
	color: var(--common-blue);
}

.TejD8-AHofcy {
	color: var(--common-gray);
}

.TejD8-AHofcy .pi1cGOpFyzPd {
	color: var(--common-blue);
	text-decoration: underline;
}

.dkjZvBoXmXAF {
	margin: 0;
	padding-bottom: 28px;

	border-bottom: 1px solid #ededed;

	font-size: 18px;
	line-height: 25px;
	color: #777777;
}

.cBT_QDjnaBkT > form {
	margin: 20px 0 40px;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media screen and (max-width: 600px) {
	._3IX1UxkuabSS {
		padding: 0;
	}
}
