

._27Nlk7Ykr1Uu {
	margin-top: 12px;
	padding: 20px 28px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	border-radius: 0 0 4px 4px;

	background-color: #f9f9f9;
}

._33A0iq531Xs7 {
	font-weight: 500;
	font-size: 16px;
}

._3oUf_ZSiZoos {
	display: flex;
	align-items: center;
}

._2a13F39z0Mjp {
	margin-right: 16px;

	font-size: 16px;
}
