

._3sX3lSRgAvcc {
	display: flex;
	flex-direction: column;
}

._3PAgzdIm_r77 {
	position: relative;

	min-height: 100px;
	display: flex;
	align-items: center;
	padding: 25px 20px;

	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 8px 8px 0 0;

	color: var(--white);
	font-weight: 700;
	font-size: 19px;
	line-height: 1.3;

	background: var(--bg-elements);
}

._2_UvqyK3Ky6V {
	padding: 16px 20px 20px;

	border-radius: 0 0 8px 8px;

	color: var(--white);
	font-size: 15px;
	line-height: 1.3;
	white-space: break-spaces;

	background-color: var(--white);
}

._1CfTAaekp2KE {
	margin-bottom: 12px;

	color: var(--text-base);
	line-height: 1.4;
	white-space: break-spaces;
}

._1CfTAaekp2KE a {
	color: var(--brand-1-0);
	text-decoration: underline;
}

.hDeLtLcP2PiW {
	display: flex;
	align-items: center;
	margin-bottom: 28px;

	color: var(--text-light);
}

.hDeLtLcP2PiW svg {
	margin-right: 8px;
}

._6TgBMtgecFS7 {
	margin-bottom: 28px;
}

._3FRZXwEzRsQv {
	display: flex;
	align-items: center;
	margin-top: auto;
}

._3FMrdcxTQj32 {
	margin-right: 12px;
	margin-left: auto;

	font-weight: 500;
	font-size: 18px;
	line-height: 1.3;
}

._1O3RVfEhPlwE {
	display: flex;
	align-items: center;
	margin-left: auto;

	font-weight: 500;
	font-size: 13px;
}

._2S5vO9B-Abpi {
	padding-left: 8px;

	font-weight: 700;
	font-size: 25px;
	line-height: 1.5;
}

button._1T9xN0SThD-H {
	font-size: 14px;
	line-height: 1.4;
	color: var(--brand-1-0);

	background-color: var(--bg-fill);
}

button._1T9xN0SThD-H:hover {
	color: var(--brand-1-0);

	background-color: var(--bg-fill);
}

button._1T9xN0SThD-H._1Zpn7nY52ykK {
	margin-left: -8px;

	background: var(--white);
}
