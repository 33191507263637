

div._28mZENxM_ISe {
	margin-top: 150px;
}

.DfotDeIhMY6B {
	height: 100px;
	padding: 0 40px;

	border-radius: 4px 4px 0 0;
	border-bottom: 1px solid #d9d9d9;

	color: var(--common-gray);
	font-size: 28px;
	line-height: 100px;

	background-color: #f9f9f9;
}

._3GsvFWeHeeY8 {
	padding: 32px 40px 40px;

	color: var(--common-gray);
	font-size: 16px;
	line-height: 25px;
}

.P150Zerv_Z3w {
	margin-top: 15px;

	overflow-wrap: anywhere;
	font-style: italic;
}

.XOY5bXxiI7-i {
	margin-top: 32px;

	text-align: right;
}
