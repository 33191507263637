._3MslX8kD-ueh {
	z-index: 0;

	margin-left: 20px;
}

._2fMYG4Opoptc {
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
}

@media screen and (max-width: 600px) {
	._3MslX8kD-ueh {
		margin-top: 12px;
		margin-left: 0;
	}
}
