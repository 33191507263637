

.uzXV4EyJPaM3 {
	width: 100%;
}

._3bVRQJgBGVX- input {
	padding: 0 0 0 12px;

	color: var(--success);
	font-size: 16px;
	font-family: monospace;
	text-transform: uppercase;
}

._3bVRQJgBGVX- fieldset {
	border: none;
}
