

div._1207kCi9kQWl {
	margin-top: 26px;
}

div._1207kCi9kQWl:first-child {
	margin-top: 20px;
}

button.SjnjH9YAmJe4,
div.SjnjH9YAmJe4 {
	margin-top: 30px;

	background: var(--common-orange);
}

button.SjnjH9YAmJe4 {
	height: 48px;
}

button.SjnjH9YAmJe4:hover {
	background: var(--common-orange);
}

div._3LKds5AmyUlA div {
	background: var(--error);
}

div.ue68vko0OQns:not(._3LKds5AmyUlA) div {
	color: var(--white);

	background: var(--success-20);
}

div._1KZu85MagU7P {
	top: 86px;
}

div._1KZu85MagU7P._1Yc6LU7QtLp9 {
	top: 10px;
}

div.Ow7BPd3-t-qy {
	position: fixed;
}
