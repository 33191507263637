

.aDJrI8P-E9-y {
	max-width: 600px;
	margin: 0 20px 8px 20px;
	padding: 13px 20px 16px 20px;

	border-radius: 8px;

	color: #414141;

	background: #ffffff;
}

.hvxq2NE4a73u {
	display: flex;
	flex-direction: column;
}

._2tKFmiKQQ0Dl {
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 40px;
	font-weight: 600;
	line-height: 150%;
}

._1kRazhGZrBzH {
	display: flex;
	align-items: center;
	margin: 0 8px;

	transform: rotate(180deg);

	overflow: hidden;
}

._1kRazhGZrBzH:after,
._1kRazhGZrBzH:before {
	max-width: 45%;

	color: #bababa;

	font-size: 18px;
	line-height: 24px;
	letter-spacing: 3px;

	transform: translateY(-6px);
	content: '...............................';
	overflow: hidden;
}

._1kRazhGZrBzH svg {
	min-width: 24px;
	margin: 0 4px;

	color: #bababa;

	transform: rotate(180deg) scale(1.2);
}

._3dpqXo9uY_vb,
._2fYWsCSE1sAc {
	display: flex;
	justify-content: space-between;

	font-size: 13px;
	line-height: 150%;
}

._3dpqXo9uY_vb {
	position: relative;
	top: -10px;

	margin-bottom: 4px;

	color: #9a9a9a;
	font-size: 13px;
}

._3dpqXo9uY_vb > span,
._2fYWsCSE1sAc > span {
	max-width: calc(50% - 8px);
}

._3dpqXo9uY_vb > span:last-child,
._2fYWsCSE1sAc > span:last-child {
	text-align: right;
}

._1Kw6ovKYTd-D {
	display: flex;
	justify-content: space-between;

	line-height: 150%;
	font-weight: 500;
}

.LgWmL4peJRoV {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
}

._13YLfpZwxs1f {
	display: flex;
	align-items: center;

	font-size: 14px;
	line-height: 16px;
}

._23ISjQLBhmvp {
	display: flex;
	align-items: center;
	margin-right: 6px;
}

.PkHAnO3Vdiby {
	display: flex;
	align-items: center;
	padding: 10px 20px;

	border-top: 1px dashed rgba(255, 255, 255, 0.4);
	border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
}

._2Cmt4kVrp7pS {
	height: 36px;
	width: 36px;
	margin-right: 18px;

	border-radius: 2px;

	background-position: center;
	background-size: cover;
}

._1EEAEcIhjF_S {
	display: flex;
	flex-direction: column;

	font-size: 12px;
	line-height: 150%;
}

._1EEAEcIhjF_S ._2wYtiusJSD9S {
	margin-top: 2px;

	font-size: 16px;
	font-weight: 500;
}

._2NH1M7p0SavC {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px 12px;
}

._3GdyGXia9gO7 {
	display: flex;
}

._2NJ3t7ku8-D9 {
	display: flex;
	flex-direction: column;

	font-size: 20px;
	line-height: 150%;
	font-weight: 500;
}

._2y6J16O1lta4 {
	margin-bottom: 2px;

	font-size: 12px;
	line-height: 150%;
	font-weight: 400;
}

._3GdyGXia9gO7 ._2NJ3t7ku8-D9:first-child {
	margin-right: 20px;
}

.zz0TZZWUYQiP {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: -15px;
	margin-right: -12px;

	color: #727272;
	font-size: 16px;
	line-height: 20px;
}

span._3_W-zFZoMYte {
	position: relative;
	top: -1px;
}

span._3CCmwvF_sItB._3_W-zFZoMYte {
	color: var(--common-blue);
}
