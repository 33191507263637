._3upfymivUTL3 {
	padding-top: 20px;

	border-top: 1px dashed var(--gray20);
}

._3upfymivUTL3:last-child {
	padding-bottom: 20px;

	border-bottom: 1px dashed var(--gray20);
}

._3upfymivUTL3 + ._3upfymivUTL3 {
	margin-top: 20px;
}

._29PEcCzqDb0E {
	display: flex;
	align-items: center;
	flex: 1;
	max-width: 33%;
}

._2hLMJQA7lFNW,
._1dVCmEBAaekh {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}

._2hLMJQA7lFNW._3KvbPgYqspZc {
	flex: 0 1 auto;
	margin-left: auto;
}

._3B_nMsjzl78_ {
	padding: 5px 8px;

	border-radius: 50%;

	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	color: var(--gray50);

	background: var(--gray05);
}

._3bNPDzpuy508 {
	margin-left: 14px;

	font-weight: 500;
	font-size: 14px;
	line-height: 1.4;
	color: var(--gray80);
}

._2phQZOpWhmj4 {
	display: flex;
}

.HvOdbVYWorcg {
	display: flex;
	flex: 1;
}

.HvOdbVYWorcg:not(:last-child) {
	margin-right: 12px;
}

span.R0onrfc0_DBf svg {
	display: none;
}

span.R0onrfc0_DBf > span {
	position: relative;
}

span.R0onrfc0_DBf > span:first-of-type:after {
	width: 16px;
	height: 16px;
	margin: 2px;

	border: 2px solid currentColor;
	border-radius: 50%;
	content: '';
}

span.R0onrfc0_DBf._3aB751T2i1Wf > span:first-of-type:before {
	content: '';

	position: absolute;
	top: 30%;
	left: 30%;
	right: 30%;
	bottom: 30%;

	border-radius: 50%;

	background: currentColor;
}

span.R0onrfc0_DBf._3aB751T2i1Wf {
	color: var(--base);
}

._34IdInFuPXYj {
	flex: 1;
}

@media screen and (max-width: 600px) {
	._3upfymivUTL3 {
		padding-top: 25px;
	}

	._3upfymivUTL3:last-child {
		padding-bottom: 25px;
	}

	._3upfymivUTL3 + ._3upfymivUTL3 {
		margin-top: 25px;
	}

	._2phQZOpWhmj4 {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	._29PEcCzqDb0E,
	._2la2WWKGGJbD {
		max-width: none;

		border-right: none;
	}

	._2hLMJQA7lFNW,
	._1dVCmEBAaekh {
		justify-content: flex-end;
	}
}
