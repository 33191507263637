

._1ahfWjREJ_K2 ._3RTZ-JTDSuQz {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;

	border: 1px solid transparent;

	color: var(--text-light);

	background-color: var(--bg-fill);

	transition: color 0.15s, border-color 0.15s;
}

._1ahfWjREJ_K2 ._3RTZ-JTDSuQz:hover {
	border-color: var(--bg-elements);

	color: var(--text-base);
}

._3RTZ-JTDSuQz svg {
	margin: 0;
}

._1ahfWjREJ_K2 ._3RTZ-JTDSuQz svg {
	transform: scale(0.6);
}

._1ahfWjREJ_K2 ._3RTZ-JTDSuQz:hover svg {
	color: var(--text-base);
}

div.yK90lURlXD15,
input.yK90lURlXD15 {
	padding-right: 0;
}

._1yw9sJg-pdxM {
	order: -1;
}

._3C9ldURdDQ6O {
	margin-bottom: 40px;
}

._8a7hshrf4A0G {
	margin-bottom: 20px;
}

._20cGEcQBK80d {
	margin-bottom: 20px;
}

._36UFpFTxviQB {
	font-weight: 400;
}

._1DF7xnofy076 {
	font-size: 13px;
	font-weight: 400;
}

._3JOAPewWj6yD {
	font-size: 16px;
}

button.B7u96Nj1eq_8 {
	font-size: 14px;
	font-weight: 500;
}

._35y1F_qbjGeB {
	color: var(--brand-1-0);

	background-color: var(--brand-1-5);
}

._35y1F_qbjGeB._2eGreLPZyc7c {
	opacity: 0.6;

	pointer-events: none;
}

.AKtu8A2kyC8X {
	margin-top: 0;
}

button._3nL2-LAARMVX.B7u96Nj1eq_8 {
	border: 1px solid var(--brand-1-3);

	background: var(--bg-white);
}

._2C9IAP7Lc--6 {
	min-width: 350px;
}

._3RyBZEZ9DkNw {
	overflow: inherit;
}

._3brEC4cp98_f {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 3;

	width: 100%;
}

div._3WjVitUjpnPa {
	max-width: 100%;
}

.UQUcAFrbzH5m input {
	padding-right: 40px !important;
}

._3C9ldURdDQ6O .XWRdzuwhOXTA label + div {
	padding-right: 0 !important;
}

._2rzGp5ZIj5Mw {
	display: none;
}
