._22Aqkscqt4MC {
	padding: 0 0 25px !important;
}

span._356sqGGen8Jr {
	font-size: 36px !important;
	line-height: 42px !important;
	font-weight: 300 !important;
	font-family: inherit;
	color: var(--common-gray) !important;
}

@media screen and (max-width: 600px) {
	._22Aqkscqt4MC {
		position: relative !important;

		padding: 12px 40px 12px 24px !important;

		border-bottom: 1px solid #d9d9d9;

		background: #ededed;
	}

	span._356sqGGen8Jr {
		font-weight: 400 !important;
		font-size: 20px !important;
		line-height: 30px !important;
		color: var(--modalHeading-color) !important;
	}
}
