div._1cjl4_mvQv9I {
	top: 50%;

	max-width: 600px !important;

	margin: 0 auto;
	padding: 28px 28px 40px 28px;

	transform: translateY(-50%);
	overflow: visible;
}

._1NhpDX31h2nh {
	display: inline-block;
	margin-bottom: 28px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 22px;
	line-height: 31px;
}

._2x2kad7AzHJX {
	margin-top: 40px;
}

@media screen and (max-width: 600px) {
	._2x2kad7AzHJX {
		margin-top: 0;
	}
}
