._28NCFKsmNyvP {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 446px;
}

@media screen and (max-width: 600px) {
	._28NCFKsmNyvP {
		min-width: auto;
	}
}

div._1PyMfL1QDqOD {
	margin-bottom: 18px;
}

._3KnvaN5KPQp0 .MuiInput-formControl:hover:before {
	border-bottom-color: var(--base) !important;
}

._1ijF82x-O7Nx:hover:before {
	border-bottom-color: var(--base) !important;
}

div.kz0AOUE-8z5K,
button.kz0AOUE-8z5K {
	max-width: 120px;
	margin-left: auto;
	margin-top: 28px;
}
