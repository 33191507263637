

label._3-96P6SnvsTj {
	margin-right: 0;

	color: var(--gray80);
	line-height: 24px;
}

._3-96P6SnvsTj._1PfApNyJzvk6,
span._2He06Rt-tJ2p._1PfApNyJzvk6 {
	color: var(--base);
}

span._2He06Rt-tJ2p:hover {
	background: rgba(50, 144, 255, 0.1);
}
