

._23eHZeCN46lg {
	color: var(--error);
	font-weight: 500;
}

._1aOMq3FDj4AF {
	color: var(--error);
}

._23eHZeCN46lg._1S8rxyYU05mL {
	background: var(--errorBG);
}
