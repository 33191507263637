.gKWNtg3AANGw {
	position: relative;

	padding: 30px 32px 40px;

	border-radius: 20px;

	background-color: var(--white);
}

.XS6PEJ8_gxyk {
	position: absolute;
	top: 28px;
	right: 32px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	background-color: #f6f6f6;
	cursor: pointer;
}

.XS6PEJ8_gxyk:before,
.XS6PEJ8_gxyk:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 50%;
	height: 1px;

	background-color: #262626;
	transform: translate(-50%, -50%) rotate(45deg);
}

.XS6PEJ8_gxyk:after {
	transform: translate(-50%, -50%) rotate(-45deg);
}

._1O3lkyvC1hz0 {
	margin-bottom: 30px;

	color: #262626;
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

._3wA_sincMObk {
	display: flex;
	justify-content: flex-end;
	margin-top: 24px;
}

._3wA_sincMObk button {
	margin-right: 12px;

	border-radius: 4px;
}

._3wA_sincMObk button:last-child {
	margin-right: 0;
}

button._1pSz7nS7j84- {
	color: var(--gray50);

	background-color: #f6f6f6;
}

button._1pSz7nS7j84-:hover {
	background-color: var(--gray05);
}

@media screen and (max-width: 600px) {
	.gKWNtg3AANGw {
		padding: 0;
	}

	.XS6PEJ8_gxyk {
		display: none;
	}
}
