

._3-qb8uhGL-Bx {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;

	height: 54px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 12px 20px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;

	background: var(--bg-white);
}

.ZaWiowvZGU30 {
	display: flex;
	align-items: center;

	font-weight: 600;
	font-size: 24px;
}

._1RQAF96UM19P,
button._1RQAF96UM19P {
	margin-left: 4px;
}

button._1RQAF96UM19P {
	font-size: 18px;
	font-weight: 700;
	text-decoration: underline;
}

button._37DzaoJ3Z0k5 {
	background: var(--white);
}

button._3LdvcG7PySlF {
	width: 32px;
	min-width: auto;
	height: 32px;
	padding: 0;

	border-radius: 50%;

	color: var(--base);

	background: var(--bg-fill);
}

button._3LdvcG7PySlF:hover {
	background: var(--bg-fill);
}

button._3LdvcG7PySlF:focus {
	background: var(--bg-fill);
}

button._3LdvcG7PySlF:active {
	background: var(--bg-fill);
}

button._3LdvcG7PySlF.JXxS_btznm7_ {
	color: var(--gray30);
}

._1Du1qElSrWL_ ._3LdvcG7PySlF:not(:last-of-type) {
	margin-right: 8px;
}

._1Du1qElSrWL_ {
	display: flex;
}

.JXxS_btznm7_ {
	position: static;

	margin-left: 8px;
}

.JXxS_btznm7_ > div {
	height: 32px;
	width: 32px;
}

button._1NQRU_vhUV0y {
	position: absolute;
	top: 8px;
	right: 8px;

	width: 32px;
	min-width: auto;
	height: 32px;
	padding: 0;

	background: #f6f6f6;
}

button._1NQRU_vhUV0y:before,
button._1NQRU_vhUV0y:after {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;

	width: 50%;
	height: 2px;

	transform: translate(-50%, -50%) rotate(45deg);
	background: var(--gray30);
	content: '';
}

button._1NQRU_vhUV0y:after {
	transform: translate(-50%, -50%) rotate(-45deg);
}

button._1NQRU_vhUV0y:hover {
	background: #f6f6f6;
}
