

._12ySPe16bqwU._3jkRuVjA_ybG {
	order: 0;

	border-top: none !important;
}

._12ySPe16bqwU._3jkRuVjA_ybG .X0EYRa9ZYcTD {
	color: var(--text-base);
	font-weight: 700;
	line-height: 1.2;
}
