.Srw60pIWcwR7 {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 48px;
	margin-right: 20px;
	padding-right: 20px;

	border-right: 1px solid #d9d9d9;

	color: var(--common-gray);
	font-weight: 600;
	font-size: 22px;
}

div._3vEIzJWENkS0 {
	padding-top: 30px;
	padding-bottom: 100px;
}

._3vEIzJWENkS0 > div {
	flex-basis: 50%;
	max-width: 540px;
}

._3vEIzJWENkS0 > div:first-child {
	margin-right: 20px;
}

@media (max-width: 1024px) {
	div._3vEIzJWENkS0 {
		padding-bottom: 70px;
	}

	._3vEIzJWENkS0 > div:first-child {
		margin-right: 0;
	}
}
