.ZDDsIF5HZqly {
	z-index: 1501 !important;
}

._39P_ywF4ueeF {
	height: 84px;

	line-height: 84px;
	font-size: 21px;
	color: var(--dark-gray);
}

._1mkAGRzdprAD {
	margin-top: 84px;
	padding: 0;
}

._3zORfDKrEcLR,
._3whPRDGXl-Ye {
	margin-top: 0;
}

._1hG29DjKPbq3 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 40px;
	padding-right: 48px;
}

.m_CmYLWzOCFU {
	max-width: 500px;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;
}

.m_CmYLWzOCFU > span {
	max-width: 100%;
	overflow: hidden;

	text-overflow: ellipsis;
}

._63k602mfVP38 {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	min-width: 412px;
	max-width: 412px;

	white-space: nowrap;
}

._3rB1qGDLBfIA {
	margin-left: 20px;
	padding-left: 20px;
	height: 32px;

	border-left: 1px solid #b6b6b6;

	line-height: 32px;
}

button._3PH6wtvDUqgx,
button._1rE_ydWmoO-J {
	width: 183px;
	height: 48px;

	border-radius: 2px;

	font-size: 14px;
}

button._1rE_ydWmoO-J {
	margin-right: 7px;

	color: #9a9a9a;

	background-color: #ededed;
}

button._1rE_ydWmoO-J:hover {
	background-color: #dadada;
}

._3AwhjlS9ToRy {
	margin-top: 29px;

	font-size: 21px;
}

@media (max-width: 1024px) {
	._1hG29DjKPbq3 {
		padding-left: 20px;
		height: 100%;

		white-space: nowrap;
	}

	._1hG29DjKPbq3 div:first-of-type {
		overflow: visible;
	}
}

._2wqqoRMcpmhd {
	height: 84px;

	border-radius: 8px 8px 0 0;

	color: #1f1f1f;
	font-weight: 600;

	font-size: 24px;
	line-height: 84px;
	text-align: center;

	background: #ededed;
}

div._3tTYHN2DsOvN {
	max-width: 100%;
	overflow: hidden;
}
