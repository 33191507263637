

._1XupOTEZe8m5 {
	position: relative;

	display: flex;
	flex: 0 1 0;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 135px;
	margin: 0 12px;
}

.RSjfv8JazX2u {
	position: absolute;
	top: 35px;
	bottom: 0;
	left: 0;
	z-index: 2;

	width: 12px;
	height: 12px;
	margin: auto;

	border: 1px solid var(--gray20);
	border-radius: 50%;

	background: #ffffff;
}

._2H6GRgo6AzBy {
	left: auto;
	right: 0;
}

._1XupOTEZe8m5:before {
	position: absolute;
	top: 35px;
	bottom: 0;
	z-index: 1;

	width: 100%;
	height: 1px;
	margin: auto;

	border-top: 1px solid var(--gray20);
	content: '';
}

._3BiMTt3hdtCU {
	z-index: 2;
	position: relative;
	top: 16px;

	padding: 0 5px;

	color: var(--base-30);
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;

	background: #ffffff;
}

._2JXY-Lv9xI_1 ._3BiMTt3hdtCU {
	color: var(--gray30);
}
