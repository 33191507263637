

button._2CMTVdzt_isf {
	border-color: transparent;
}

._190zo2tWj8m_ {
	background: var(--warningBG);
}

/* stylelint-disable */
.FA_rG4n6JXTA {
}
