

._3odo87hbRlo2 {
	position: relative;

	display: flex;
	justify-content: space-between;
	padding: 32px 0 20px;
}

._1SBD9Rv_nm6f {
	position: relative;

	display: flex;
	align-items: center;
	padding: 5px;
}

._1_9nTAgskCDh {
	display: flex;
	justify-content: center;
	width: 230px;

	font-weight: 500;
	font-size: 26px;
	line-height: 26px;
	text-align: center;
}

._1rgcUj5vzXh9 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 38px;
	height: 38px;

	border-radius: 50%;

	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	cursor: pointer;
	background: var(--gray);
	background: var(--schedule-list-control-bg, var(--gray));
}

.cBD9Nscs3mZs svg {
	transform: rotate(-180deg);
}

._5xxelJn5Bq2- {
	opacity: 0.5;

	pointer-events: none;
}

._3qoPgj_qrQ3W {
	margin: 0 0 0 5px;
}
