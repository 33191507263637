

._3Nfx3E5ILgdI {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;

	display: none;

	background: #ffffff;
}

._284Le7vR3mAE._3Nfx3E5ILgdI {
	display: flex;
	flex-direction: column;
}
