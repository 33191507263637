._2n_8lbac8hjo {
	margin-top: 28px;

	display: flex;
}

._2n_8lbac8hjo ._10XsY38BkvFQ {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 100%;
	padding: 0 16px;

	border-radius: 4px;

	color: #ffffff;
	font-size: 14px;
	font-weight: 600;
	font-family: inherit;

	background: var(--base);
}

.m32BcfzPdKeJ {
	display: flex;
	align-items: center;
	justify-content: center;
}

._10XsY38BkvFQ .m32BcfzPdKeJ {
	color: #ffffff;
}

._3M7eiBhHRwm4 {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 8px;
}

._6a-f7X4tML14 {
	color: var(--gray80);
	font-weight: 600;
	font-size: 24px;
}

@media screen and (max-width: 600px) {
	._2n_8lbac8hjo {
		margin-top: 25px;
	}
}
