.cYLPM6_19cko {
	padding: 6px 16px;

	cursor: pointer;

	transition: background-color 0.2s;
}

.cYLPM6_19cko:hover {
	background-color: #f6f6f6;
}

._3f_9w0SkHYwu {
	display: flex;
}

._2W6M69kV5Alw {
	display: flex;
	justify-content: space-between;
	align-items: center;

	line-height: 20px;
}

.D365gBX9fBn- {
	color: var(--autocomplete-option-label);
	font-weight: 500;
	font-size: 16px;

	text-transform: capitalize;
	text-align: left;
}

._256Q5dn-ddwL {
	width: 40px;

	color: var(--autocomplete-option-code);
	font-weight: var(--autocomplete-option-code-weight);
	font-size: 12px;

	white-space: nowrap;
	text-align: right;
	text-transform: uppercase;
	text-overflow: ellipsis;
	overflow: hidden;
}

._39UITmfodyFw {

	display: none;
	width: auto;

	color: var(--common-blue);

	color: var(--autocomplete-option-stops-color, var(--common-blue));
	text-transform: uppercase;
}

@media screen and (max-width: 600px) {
	._39UITmfodyFw {
		display: block;
	}
}

.tEjXqGq7K63Q {
	margin-top: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 12px;
	line-height: 16px;
	white-space: nowrap;
}

._22Xwm5GfU1yy ._38ikOlicJRZV {
	color: var(--dark-gray);
}

._22Xwm5GfU1yy ._3va4xA9JWP2M {
	color: var(--light-gray);
}

._1Ot1AruGJ4Ba {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.cYLPM6_19cko {
		padding: 6px 20px;
	}
}
