

._2Nfr2nOSHEZU {
	display: flex;
	width: 100%;
	max-width: 1100px;
	min-height: 95px;
	margin: 0 auto 5px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	cursor: pointer;
	background: #ffffff;
}

.FLvNtqu-2Bnn {
	display: flex;
	flex-direction: column;
	width: 640px;
}

._2Cm-CObPEYEA {
	display: flex;
	flex-direction: column;
	flex: 0 1 640px;

	border-right: 1px dashed #cccccc;
}

._2Cm-CObPEYEA._8PzqHCuLQue1 {
	flex: 0 1 440px;
}

.hiQ6EIADykw2 {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 31px;
	padding: 8px 4px;

	border-top: 1px solid var(--gray10);
}

._25PYhkQ5LPHr {
	min-height: 46px;
}

._3kygPl4L6lQQ {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-end;
	min-height: 100%;
	min-width: 190px;

	padding: 0 12px;

	color: var(--base-30);
}

._1lgQh09vCVXP {
	margin: 20px 0;
}

.SbklJnydPqP4 {
	color: var(--brand-1-0);
	font-weight: 700;
}

._30_d_N-0taHs {
	border-right: 1px dashed #cccccc;

	font-size: 16px;
	font-weight: 700;
}

._2kzgKfca4l1K {
	flex: 1;
}

._3EJWvrMYgX-G {
	font-size: 14px;
	font-weight: 400;
}

._6bpSLygYkJbb {
	font-size: 23px;
	font-weight: 900;
}

._2kzgKfca4l1K:hover {
	text-decoration: underline;
}

._2Mk_9sY4fQPR {
	display: block;
	width: 460px;
}

button._3BGNaMpkX1nr {
	display: none;
}

._3DxA6bhNMhJs {
	left: -16px;

	display: flex;
	align-self: center;
	margin-top: 4px;

	color: var(--gray50);
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
}

div._1YA6nEjHL9yL {
	padding: 0 !important;
	min-height: unset !important;
}

._3DxA6bhNMhJs._2NIjW3h7X190 {
	left: 0;
}

._2Mk_9sY4fQPR .slick-track,
._2Mk_9sY4fQPR .slick-slider,
._2Mk_9sY4fQPR .slick-list,
._2Mk_9sY4fQPR .slick-slide,
._2Mk_9sY4fQPR .slick-slide > div,
._2Mk_9sY4fQPR .slick-slide > div > div {
	height: 100% !important;
}

._2Mk_9sY4fQPR .slick-track .slick-slide:not(:last-of-type) {
	border-right: 1px dashed #cccccc;
}

div._3uJBBkmQx-Sr {
	overflow: hidden;
}
