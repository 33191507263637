

._2sarfOv4gsW3 {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
}

.Y0hnlBSKfPx0 {
	margin: 0 30px;

	color: var(--base);
	font-weight: 500;
	font-size: 24px;
	line-height: 1.3;
	text-decoration: underline;

	cursor: pointer;
}

._1xZxaKjkS045 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 38px;
	height: 38px;

	border-radius: 50%;

	color: #262626;

	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	background: var(--white);
}

._1xZxaKjkS045 svg {
	width: 30%;
	height: 30%;

	transform: translateX(2px);
}

._1xZxaKjkS045._11rA4g9HAG__ svg {
	transform: rotate(180deg) translateX(2px);
	box-shadow: 4px -7px -26px rgba(0, 0, 0, 0.1);
}

._3vPv8RhkOKhj {
	position: absolute;
	top: 100%;
	left: 50%;
	z-index: 1;

	transform: translate(-50%, 14px);
}

._1LDCLfbi9qEj {
	opacity: 0.5;

	pointer-events: none;
}
