

._21JH7panoPie {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 28px;
}

.QorO1Gq1dAqR {
	padding: 0;
}

._2nQLmv_hgkVT {
	margin: 22px 0;

	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	text-align: center;
}

button._3ylvnwLb7brI {
	min-width: 200px;
}

._3ylvnwLb7brI:first-of-type {
	margin-bottom: 12px;
}

button._2-C8-mQZ7o1s,
button._2-C8-mQZ7o1s:hover {
	color: var(--common-gray);

	background-color: #ededed;
}
