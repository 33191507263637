

div._175PGH0LURle {
	border: none;

	background: transparent;
}

._2G8iESyK0CdT {
	display: flex;
	justify-content: center;
	margin: 0 30px 25px;

	color: #000000;
	font-weight: 500;
	font-size: 26px;
	line-height: 1;
}

div._3KZv7TVn6mh8,
div._3sl11sLNXPBP {
	top: 17px;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;

	border-radius: 50%;

	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	background: var(--gray);
	background: var(--calendar-bg, var(--gray));
}

div._3KZv7TVn6mh8 {
	left: 50%;

	transform: translateX(-150px);
}

div._3sl11sLNXPBP {
	right: 50%;

	transform: translateX(150px);
}

div._3KZv7TVn6mh8 svg {
	transform: rotate(-180deg) translate(1px, 1px);
}

div._3sl11sLNXPBP svg {
	transform: translate(1px, -1px);
}

div._3PQd_P-Hzr6S {
	display: none;
}
