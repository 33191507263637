

._1hqtkRjx72e7 {
	padding: 32px 32px 80px;
	max-height: 68vh;
	overflow: auto;
}

._3MvdzGp2HYA3 {
	display: flex;
	margin-bottom: 20px;
	align-items: center;
}

._2j6XJtDBUcPO {
	display: flex;
	flex: 1;
}

._2byAbj9PhkBN {
	width: 100%;
}

._2byAbj9PhkBN input {
	width: 100%;
	height: 45px;
	margin-right: 12px;
	padding: 11px 12px;
	padding-right: 37px;

	font-size: 17px;

	background: var(--white);
}

.dfbx_Pqg5lGP {
	flex: 1;
	margin-right: 12px;
}

.dfbx_Pqg5lGP > div {
	max-height: 134px;
}

div._1xV9PFuFgbnF {
	border: 1px solid var(--base);
}

div._1xV9PFuFgbnF,
._1xV9PFuFgbnF > div {
	background: var(--white);
}

.SqmbvRUUkLxX {
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.SqmbvRUUkLxX svg {
	color: var(--gray50);
}

.SqmbvRUUkLxX div {
	background-color: var(--base90);
}

.SqmbvRUUkLxX div:first-child {
	border-top-right-radius: 4px;
}

.SqmbvRUUkLxX div:last-child {
	border-bottom-right-radius: 4px;
}

div.Hs8D_4Pt3L39 {
	position: relative;

	width: 100%;

	flex: 1;

	height: var(--input-height);
	margin-right: 12px;

	font-size: 17px;

	background: var(--base90);

	cursor: pointer;
}

div.Hs8D_4Pt3L39,
._2byAbj9PhkBN input {
	border: 1px solid var(--brand-1-3);
	border-radius: 4px;

	color: var(--gray90);
}

._4pNfLdPx63Rp {
	width: 130px;
	margin-right: 12px;
}

div._1cCi-QbwAuPU {
	border-color: var(--base);
}

._1cCi-QbwAuPU input {
	border: 0;
}

._2FgVmY0T3sw9 {
	position: absolute;

	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 11px 12px;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

._2FgVmY0T3sw9 > div:first-child {
	width: 85%;
	overflow: hidden;

	text-overflow: ellipsis;
}

.BMQmEIYnhkbs {
	margin-right: 12px;

	color: var(--gray50);
}

._2mHykFyFQ4H2 {
	color: var(--gray50);
	text-overflow: ellipsis;
	overflow: hidden;
}

.Hs8D_4Pt3L39 input {
	width: 100%;
}

._2nc26wmeuN7z {
	position: absolute;
	top: 14px;
	right: 37px;

	color: var(--gray50);
	font-size: 17px;
}

._2haPhlVOjrgn {
	position: absolute;
	top: 11px;
	right: 9px;
}

._2haPhlVOjrgn svg path {
	color: var(--gray30);
}

.WCMl5p1hEPaR svg {
	transform: rotate(180deg);
}

._1vXoS528AuiO {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 10px;

	border-bottom: 1px solid var(--gray10);

	color: var(--gray90);
	font-size: 16px;
	white-space: nowrap;

	cursor: pointer;
}

._1vXoS528AuiO:hover {
	background-color: var(--base90);
}

._1vXoS528AuiO > div:first-child {
	word-wrap: break-word;
	word-break: normal;
	white-space: normal;
}

._31ieoOl_yjUd {
	color: var(--gray50);
}

input._3AcsR_CMZFf4 {
	width: 151px;

	font-size: 26px;
	font-weight: 500;
}

._2imXPUAWEuXk {
	min-width: 36px;
}

._3iZWpL_ADOmP {
	background: var(--base90);
}

._1C1CqGviT3Yz {
	border: 1px solid var(--brand-1-3);
	border-radius: 4px;

	background: var(--base90);
}

.A6qa8pzn1wxF {
	width: 100px;
}

button._1869h01lsbEK {
	display: flex;

	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;

	border-radius: 50%;
	border: 1px solid var(--base80);

	color: var(--base);

	background: var(--base90);
}

button._29V4ZQ3AhAdY {
	border: 1px solid var(--error60);

	background: var(--searchForm-removeButton-background);
}

button._29V4ZQ3AhAdY svg path {
	fill: var(--error);
}

button._1869h01lsbEK svg {
	width: 20px;
	height: 20px;
}

._1FnOW-7WxJb5 {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	transform: translateY(48px);
}

._1FnOW-7WxJb5 button:not(:last-child) {
	margin-right: 8px;
}

button._1lWaH3fDxQR2 {
	color: var(--gray70);

	background: var(--gray05);
}

button._1lWaH3fDxQR2:hover {
	background: var(--gray10);
}

button._3TuqKQeqxqqu {
	color: #ffffff;

	background: var(--common-orange);
}

button._3TuqKQeqxqqu:hover {
	background: var(--dark-orange);
}

button._215Fqtrt3fJA {
	opacity: 0.6;

	pointer-events: none;
}
