/* stylelint-disable */
._37XrJ3HLwEjp {
}

/* stylelint-enable */
._1d_Na1oNGouu {

	padding: 28px 30px;
	height: 350px;
}

@media (max-width: 1024px) {
	._1d_Na1oNGouu {
		padding: 0 14px 60px 14px;
		max-width: 450px;
		height: auto;
	}
}

@media screen and (max-width: 600px) {
	._1d_Na1oNGouu {
		max-width: 100%;
	}
}

._3H1Fhw8UP0t9 {

	padding-left: 0;
	padding-right: 0;
}

@media (max-width: 1024px) {
	._3H1Fhw8UP0t9 {
		padding-left: 50px;
	}
}

._37XrJ3HLwEjp:after {
	left: 38px;
}
