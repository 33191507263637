.r-YUxNxtmoBe:focus {
	position: relative;
	z-index: 1;

	background: transparent;
}

.r-YUxNxtmoBe:focus:-ms-input-placeholder, ._1tzoWCzXZ1Db:-ms-input-placeholder {
	opacity: 0;
}

.r-YUxNxtmoBe:focus::placeholder,
._1tzoWCzXZ1Db::placeholder {
	opacity: 0;
}

._1tzoWCzXZ1Db {
	color: transparent;
}

._3olTrsPXYd3i {
	padding-right: 42px;

	text-overflow: ellipsis;
}

.PS34VwXqDUus {
	text-overflow: ellipsis;
}

@media screen and (max-width: 600px) {
	.r-YUxNxtmoBe {
		padding-left: 44px;
	}
}

._343VqCGQsY7m {
	position: absolute;
	top: 50%;
	right: 0;
	z-index: 1;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 33px;
	height: var(--input-height);
	padding: 0;

	border: none;

	color: #ffffff;

	transform: translateY(-50%);

	cursor: pointer;
	opacity: 0.5;

	background: none;

	transition: all 0.15s;
}

._343VqCGQsY7m:hover {
	opacity: 1;
}

._2Nkpe9LJ1GqM {
	opacity: 1;
}

@media screen and (max-width: 600px) {
	._343VqCGQsY7m._3Oehp0mSigxI {
		right: 0;

		width: 32px;
		height: 32px;

		border-radius: 50%;

		background: var(--common-blue);
		transform: translate(61%, -50%);
		opacity: 1;
	}
}

._2Nkpe9LJ1GqM svg path {
	fill: var(--common-blue);
}

@media screen and (max-width: 600px) {
	._1p4d8tKuSvAk {
		cursor: pointer;
	}

	._1p4d8tKuSvAk .r-YUxNxtmoBe {
		padding: 0;

		font-size: 21px;
		font-weight: 600;
		color: rgba(255, 255, 255, 0.7);
	}

	._1p4d8tKuSvAk ._1tzoWCzXZ1Db {
		color: transparent;
	}

	.kxwgBU2qZzJ_:not(._1p4d8tKuSvAk):before {
		content: '';

		position: absolute;
		top: 50%;
		left: 8px;

		width: 24px;
		height: 24px;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxnIG9wYWNpdHk9IjAuNSI+CgkJPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDlDNSA1LjEzIDguMTMgMiAxMiAyQzE1Ljg3IDIgMTkgNS4xMyAxOSA5QzE5IDE0LjI1IDEyIDIyIDEyIDIyQzEyIDIyIDUgMTQuMjUgNSA5Wk05LjUgOUM5LjUgMTAuMzggMTAuNjIgMTEuNSAxMiAxMS41QzEzLjM4IDExLjUgMTQuNSAxMC4zOCAxNC41IDlDMTQuNSA3LjYyIDEzLjM4IDYuNSAxMiA2LjVDMTAuNjIgNi41IDkuNSA3LjYyIDkuNSA5WiIgZmlsbD0id2hpdGUiLz4KCTwvZz4KPC9zdmc+Cg==) center no-repeat;
		transform: translateY(-50%);
	}
}
