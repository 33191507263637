div._14fDYV0EOBUu {
	max-width: 675px;
	padding: 40px;
}

._5T7LOgdno49f {
	margin-bottom: 53px;

	color: #353535;
	font-weight: 600;
	font-size: 24px;
}

._5T7LOgdno49f span {
	text-transform: capitalize;
}

._1Q4ZwDx5MHOz {
	display: flex;
	align-items: flex-end;
}

._1Q4ZwDx5MHOz svg {
	flex-shrink: 0;
	margin-right: 20px;
	margin-bottom: 5px;
}

._2ghPd8VZXhzZ {
	display: flex;
	align-items: flex-end;
	flex: 1;
}

div._tvSVMW6y1Hz {
	margin-right: 20px;
}

button.ZTjYLottLlSR {
	border: none;

	color: #ffffff;

	background: var(--base);
}

button.ZTjYLottLlSR:hover {
	background: var(--base);
}

@media screen and (max-width: 600px) {
	._1Q4ZwDx5MHOz {
		display: block;
	}

	div._tvSVMW6y1Hz {
		margin-right: 0;
		margin-bottom: 12px;
	}

	button.ZTjYLottLlSR {
		width: 100%;
	}

	._2ghPd8VZXhzZ {
		align-items: center;
	}
}
