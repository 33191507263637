._2wk-mUMvgFiU {
	display: flex;
	margin-top: 22px;
}

@media screen and (max-width: 600px) {
	._2BvXLNsfyfwe > div:first-of-type {
		font-size: 18px;
	}
}

@media screen and (max-width: 600px) {
	._2wk-mUMvgFiU {
		flex-direction: column;
	}
}

._2wk-mUMvgFiU > button:first-of-type {
	margin-right: 8px;
}

@media screen and (max-width: 600px) {
	._2wk-mUMvgFiU > button:first-of-type {
		margin-right: 0;
		margin-bottom: 8px;
	}
}

button.uj15Liq5DZoa,
button.uj15Liq5DZoa:hover,
button.uj15Liq5DZoa:focus,
button.uj15Liq5DZoa:active {
	padding: 14px 53px;

	border-radius: 4px !important;

	color: var(--gray70);
	font-weight: 500;

	background: var(--gray05);
}

button.grrhNXNBhUOQ,
button.grrhNXNBhUOQ:focus,
button.grrhNXNBhUOQ:hover {
	color: #ffffff;

	background: var(--active);
}
