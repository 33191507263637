header._2Wxnk-QFrK8h {
	margin-bottom: 28px;
	height: 56px;

	background-color: var(--common-blue);
}

._3ncvHelyYAp0 {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;

	background-color: rgba(0, 0, 0, 0.15);
}

header._2Wxnk-QFrK8h > div > ._2Tm9xidCPaZY {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	max-width: 1100px;
	height: 100%;
	min-height: unset;
	width: 100%;
	padding: 0;
}

h1._2XsCQr9uxOoq {
	margin: 0;

	font-family: Ubuntu, sans-serif;
	font-size: 24px;
}

._2Tm9xidCPaZY > div {
	display: flex;
	flex-direction: row;
	align-items: center;
}

._2Tm9xidCPaZY .p7LmwUhDL1ue {
	margin-right: 16px;
	margin-left: -12px;
}

.HpatbyPNJXfz > div:first-child {
	margin-right: 10px;
}

._3UNkVjM3j4nO {
	cursor: pointer;
}

._3UNkVjM3j4nO > p > span {
	font-size: 12px;
	font-weight: 500;
}

div._3urAmRwvsTwk ._2YPYcBc_6WRT > span {
	color: #ffffff;
	font-size: 15px;
	font-weight: 500;
}

._3UNkVjM3j4nO > p > span:last-child {
	margin-left: 8px;
}

.HpatbyPNJXfz > div:first-child > p > span {
	height: 28px;
	padding: 0 8px;

	font-size: 12px;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.7);
}

.HpatbyPNJXfz > div:first-child > p > span:first-child {
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

._3UNkVjM3j4nO > p {
	display: flex;
	align-items: center;
}

div._2PEhWfZL303- {
	z-index: 1301;
}

._2PEhWfZL303- > div:last-child {
	background-color: var(--common-blue);
}

._2PEhWfZL303- > div:last-child > div > ul {
	padding-top: 0;
	padding-bottom: 0;
}

._2PEhWfZL303- > div:last-child > div:last-child {
	padding: 18px 22px;

	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

div._3urAmRwvsTwk {
	padding: 30px 22px;
}

._3urAmRwvsTwk ._2awhOjr58UhK {
	margin-right: 0;

	font-size: 24px;
	color: #ffffff;
}

._3urAmRwvsTwk ._2YPYcBc_6WRT {
	padding: 0 22px;
}

@media (max-width: 1024px) {
	header._2Wxnk-QFrK8h > div > ._2Tm9xidCPaZY {
		padding: 0 20px;
	}

	._2PEhWfZL303- > div:last-child > div:last-child {
		padding: 30px 22px;
	}
}

@media screen and (max-width: 600px) {
	header._2Wxnk-QFrK8h {
		max-height: 48px;
	}

	h1._2XsCQr9uxOoq {
		font-size: 20px;
	}

	._2Tm9xidCPaZY .p7LmwUhDL1ue {
		margin-right: 4px;
	}
}
