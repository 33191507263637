._2vcnH3cQzdLk {
	position: relative;

	display: flex;
	align-items: center;

	font-size: 14px;
	color: #ffffff;
}

@media screen and (max-width: 600px) {
	._12rnohWT4xBm {
		display: block;
		padding-top: 8px;
		padding-bottom: 8px;

		border-bottom: 1px solid rgba(255, 255, 255, 0.4);

		line-height: 28px;
	}

	span._12rnohWT4xBm:not(:last-of-type) {
		margin-right: 0;
	}
}

.ovoxHN6womdn {
	position: absolute;
	left: -55px;
	z-index: 1;

	width: 300px;

	margin-top: 4px;

	border-radius: 2px;

	line-height: 20px;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);

	background-color: #ffffff;
}

._22xchHJqY9NO {
	line-height: 20px;
}

._2QrpPIsAU8wM {
	opacity: 0.7;
	cursor: pointer;
}

._2hogQ1-gmkmz {
	height: 20px;
	width: 1px;

	margin: 0 16px;

	background-color: rgba(255, 255, 255, 0.3);
}

._1UiQxfrMDtkO {
	line-height: 20px;
}

@media screen and (max-width: 600px) {
	._1UiQxfrMDtkO {
		display: flex;
		align-self: center;
		margin-top: 7px;
		padding-bottom: 8px;
		padding-left: 0;

		line-height: 20px;
	}

	._1UiQxfrMDtkO:after {
		display: none;
	}

	._2MfcIaFYLQs7 {
		flex-basis: 100%;
	}
}
