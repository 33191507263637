

.gxoo2lVnyZYi {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 460px;

	padding: 20px;
	margin-bottom: 15px;

	border-radius: 8px;

	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);

	background: var(--white);
}

._2DDUKwuNxlb6 {
	margin-bottom: 20px;

	font-weight: 700;
	font-size: 18px;
	line-height: 1.4;
}

.EqOHg8c2P4am {
	padding-top: 16px;
	flex: 0;
}

.ASsmZgtIzyLz {
	color: var(--gray50);
}

@media (max-width: 1024px) {
	.gxoo2lVnyZYi {
		margin-left: 0;
	}
}
