

._2PmEVy7dIaGT {
	padding: 21px 27px;

	border-radius: 2px;

	background-color: #ededed;
}

.rrTAuEZ5x6IH {
	color: var(--gray80);
}

._3Shlh56QPx-m {
	margin-left: 20px;
}

._3-EVNb1goffZ {
	color: var(--error-20);
}
