._1NKW3bZaca3N {
	display: flex;
	justify-content: center;

	background-color: var(--white);
}

.G7eiw46eRkVz {
	position: sticky;
	top: 0;
	z-index: 3;
}

._15b8LqVp2Koy {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	max-width: 1140px;
	width: 100%;
	height: 80px;
}

._2jOTNvjmQgSo {
	position: absolute;
	left: 20px;

	font-weight: 600;
	font-size: 24px;
	line-height: 100%;
	color: #333333;
}

._2iLlEmhHl525 {
	display: flex;
	align-items: center;
}

._21hoVarHdoJ8 {
	margin-right: auto;
}

._2GM4uQnLYzu8 {
	margin-left: auto;
}

@media (max-width: 1024px) {
	._15b8LqVp2Koy {
		justify-content: space-between;
	}

	._2jOTNvjmQgSo {
		position: static;
	}

	._2iLlEmhHl525 {
		margin-right: 30px;
	}
}
