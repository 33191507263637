

div.Ln_ZIpOH-DeM {
	background: var(--gray05);
}

.Ln_ZIpOH-DeM.JO0b3Ql-R46e {
	overflow-y: scroll;
}

div._1S7oZsiNK9JB {
	z-index: 10;
	top: 26px;
}
