._2q9UokOG9gSF {
	padding: 40px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

label._3FBQ1CYF6Dq2 {
	display: flex;
	margin-right: 0;
	margin-bottom: 8px;
	height: 64px;
	padding-left: 13px;

	background: var(--gray05);
}

label._3FBQ1CYF6Dq2._2xY2tdV6Bv4P {
	color: var(--base);

	background: var(--base80);
}

._2qfccA07Gaf0 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-right: 12px;
}

div._26l4IbaBKfGK {
	opacity: 0.6;

	transition: opacity 0.3s;

	pointer-events: none;
}

label._3FBQ1CYF6Dq2._2xY2tdV6Bv4P div._26l4IbaBKfGK {
	opacity: 1;

	pointer-events: auto;
}

span._bW19Ins1IY8 {
	color: #9a9a9a;
}

span._bW19Ins1IY8._2HgQPZXtWgg5 {
	color: var(--base);
}

@media screen and (max-width: 600px) {
	._2q9UokOG9gSF {
		padding: 0 16px;
	}

	label._3FBQ1CYF6Dq2 {
		margin: 0;
		padding: 0;

		background: none;
	}

	label._3FBQ1CYF6Dq2:not(:last-child) {
		border-bottom: 1px solid var(--gray10);
	}

	label._3FBQ1CYF6Dq2._2xY2tdV6Bv4P {
		color: var(--base);

		background: none;
	}
}
