

._3Pc8JN0OT56G {
	box-shadow: 4px 9px 38px rgba(0, 0, 0, 0.1);
	overflow: visible !important;
}

div._126x_hX68vJu {
	padding: 50px 60px;
}

div._2_8U3nu4Wqkk {
	padding-right: 69px;
}

._1MKjQg4tsDx8 {
	background: var(--base90);
}

._3Jq1f4xllRU3,
._1ka7mRNkCJLW {
	top: 50%;

	border-radius: 50%;
	border: 1px solid rgba(242, 242, 242, 1);
}

._3Jq1f4xllRU3 {
	transform: translate(-78px, -50%);
}

._1ka7mRNkCJLW {
	transform: translate(37px, -50%);
}

._3QkqNRnqwuGh {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;

	border-radius: 50%;

	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	background: var(--gray);
}

._3QkqNRnqwuGh._1yAoPjaFMlA5 svg {
	transform: rotate(-180deg);
}

div._3XMqVjSIetf7,
div._1aqpLOJQSPn5 {
	position: relative;

	color: var(--gray);

	background: var(--base);
}

._3XMqVjSIetf7:after,
._1aqpLOJQSPn5:after {
	content: '';

	position: absolute;
	top: 3px;
	right: 4px;

	width: 9px;
	height: 8px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgNUg5TTkgNUw1LjMzMzMzIDFNOSA1TDUuMzMzMzMgOSIgc3Ryb2tlPSJ3aGl0ZSIvPgo8L3N2Zz4K) no-repeat center;
	background-size: contain;
}

._1aqpLOJQSPn5:after {
	transform: rotate(-180deg);
}

._1w2Lv6lq6r8B,
._22mEcZ1YRj9E {
	font-weight: 500;
	line-height: 24px;
	color: #292929;
	color: var(--schedule-calendar-month-color, #292929);
}

._22mEcZ1YRj9E {
	color: #9a9a9a;
	color: var(--schedule-calendar-year-color, #9a9a9a);
}

div.t5JV7GiFY2Py,
div._27eGoqDtPlAt {
	width: 40px;
	height: 40px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

div._27eGoqDtPlAt span {
	border-bottom: 0;

	font-size: 12px;
	line-height: 18px;
	color: rgba(154, 154, 154, 1);
	color: var(--schedule-calendar-weekday-color, rgba(154, 154, 154, 1));
}

.t5JV7GiFY2Py {
	color: rgba(41, 41, 41, 1);
	color: var(--schedule-calendar-day-color, rgba(41, 41, 41, 1));
	font-size: 13px;
	font-weight: 500;
	line-height: 19px;
}

.t5JV7GiFY2Py._7mTUUA_WFPRf {
	visibility: hidden;
	opacity: 0;

	pointer-events: none;
}

.t5JV7GiFY2Py._1fQV0FvArrF- {
	color: inherit;
}

.t5JV7GiFY2Py.mKJTD5xQqG72 {
	color: inherit;
}
