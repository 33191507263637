._1XvB4T1pCKnN {
	border-radius: 4px;

	background: #ffffff;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

._1XvB4T1pCKnN:not(:last-of-type) {
	margin-bottom: 12px;
}

._21RPLaCpdDk1 {
	display: flex;
	justify-content: space-between;
	align-items: center;

	border-radius: 4px 4px 0 0;

	background: #f9f9f9;
}

._21RPLaCpdDk1:only-child {
	border-radius: 4px;
}

._1QxI0L2V-_NE {
	padding: 14px 20px;
	display: flex;
	align-items: center;

	font-size: 21px;
	font-weight: 600;
	text-transform: capitalize;
}

._1i0PYSfScbJ8,
._1UDVBmvRbt6D,
._3gvCu8Ecrbpf {
	display: flex;
	align-items: center;
}

._1i0PYSfScbJ8 {
	margin-right: 20px;
}

._1UDVBmvRbt6D {
	margin-right: 12px;
}

._3Eg_bpbVluCJ {
	display: flex;
	align-items: center;
}

._2Hle1QwAkIpO {
	display: flex;
	align-items: center;

	font-size: 21px;
	font-weight: 600;
}

._1fmTA5JOb5Vo {
	display: flex;
	align-items: center;
	margin-left: 28px;
	padding-right: 20px;
}

.Y5txPU9V55Qr {
	display: flex;
	align-items: center;
	margin-left: 16px;
}

._2BLuZybbzPkf {
	opacity: 0.3;

	filter: grayscale(1);

	pointer-events: none;
}

._68rIlB70IGaO {
	width: 340px;
	margin-right: -20px;
	padding: 10px 10px 10px 50px;

	border-left: 1px dashed #d9d9d9;

	font-size: 14px;
	line-height: 18px;

	background: #fff9c2 url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjY2Njk5MiA5LjAwMDA4QzAuNjY2OTkyIDQuNDAwMDggNC40MDAzMyAwLjY2Njc0OCA5LjAwMDMzIDAuNjY2NzQ4QzEzLjYwMDMgMC42NjY3NDggMTcuMzMzNyA0LjQwMDA4IDE3LjMzMzcgOS4wMDAwOEMxNy4zMzM3IDEzLjYwMDEgMTMuNjAwMyAxNy4zMzM0IDkuMDAwMzMgMTcuMzMzNEM0LjQwMDMzIDE3LjMzMzQgMC42NjY5OTIgMTMuNjAwMSAwLjY2Njk5MiA5LjAwMDA4Wk05LjgzMzY2IDguMTY2NzVWMTMuMTY2N0g4LjE2Njk5VjguMTY2NzVIOS44MzM2NlpNOS4wMDAzMSAxNS42NjY3QzUuMzI1MzIgMTUuNjY2NyAyLjMzMzY1IDEyLjY3NTEgMi4zMzM2NSA5LjAwMDA4QzIuMzMzNjUgNS4zMjUwOCA1LjMyNTMyIDIuMzMzNDEgOS4wMDAzMSAyLjMzMzQxQzEyLjY3NTMgMi4zMzM0MSAxNS42NjcgNS4zMjUwOCAxNS42NjcgOS4wMDAwOEMxNS42NjcgMTIuNjc1MSAxMi42NzUzIDE1LjY2NjcgOS4wMDAzMSAxNS42NjY3Wk05LjgzMzY2IDQuODMzNDFWNi41MDAwOEg4LjE2Njk5VjQuODMzNDFIOS44MzM2NloiIGZpbGw9IiM5QTlBOUEiLz4KPC9zdmc+Cg==) no-repeat 18px center;
}

button._2rgFrtGduSYQ {
	display: flex;
	align-items: center;
	padding: 0 12px;
	height: 36px;

	border-radius: 8px;

	font-size: 13px;
	line-height: 36px;
}

button._2rgFrtGduSYQ,
button._2rgFrtGduSYQ:hover {
	background-color: #ffffff;
}

button._2rgFrtGduSYQ:disabled {
	cursor: not-allowed;

	pointer-events: auto;
}

button._2RLShNCMcuVb:disabled {
	cursor: wait;

	opacity: 1;

	pointer-events: auto;
}

._3gvCu8Ecrbpf {
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

._2RLShNCMcuVb ._3gvCu8Ecrbpf {
	animation: _3PRKqt3okW8M 0.9s infinite linear;
}

._2n95J04XYUWC {
	margin-left: 8px;

	height: 36px;
}

._2n95J04XYUWC img {
	height: 100%;

	cursor: pointer;
}

.VJTQnG7pJFjW img {
	cursor: not-allowed;
}

._10Ir29o17u9P {
	display: flex;
	flex-wrap: wrap;
	padding: 17px 17px 0;

	border-top: 1px dashed #d9d9d9;
}

._2iYL9QYBathS > div {
	flex-basis: 35%;
}

._1DscsJy9boZZ {
	display: flex;
	justify-content: center;
	padding: 20px 0;
}

.gKF84M5yp2HU {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	padding: 16px 20px;

	border-top: 1px dashed #d9d9d9;
}

._2wW4o0ZrlDv- {
	color: #9a9a9a;
	font-weight: 500;
	font-size: 14px;

	cursor: pointer;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

._2wW4o0ZrlDv-:hover {
	text-decoration: underline;
}

._1oUwxxR56qDJ {
	font-weight: 500;
	font-size: 14px;
	color: var(--cabinet-action);

	cursor: pointer;
}

._39oUkCj3h7F3 {
	display: flex;
	align-items: center;
}

._3rfgRODgDE_j {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin-left: auto;

	border-radius: 50%;

	color: #ffffff;

	cursor: pointer;
	background: var(--common-blue);
}

._1apB-l2Bn218,
._3KcRfiMm9T_E,
button._1ASMXvdHA9_E {
	opacity: 0.5;
	cursor: not-allowed;
}

.ISvRhIxLjigN {
	margin-left: auto;

	cursor: pointer;
}

button.dhTgnxZLxkxt {
	margin-left: auto;
}

@media (max-width: 768px) {
	._1fmTA5JOb5Vo {
		justify-content: space-between;
		margin-top: 20px;
		margin-left: 0;
		padding: 16px 20px 16px 0;

		border-top: 1px dashed #d9d9d9;
	}

	.gKF84M5yp2HU {
		width: auto;
		padding: 0 20px;

		border-top: none;
	}

	._1lSfHcVdE782 {
		display: flex;
		align-items: center;
	}

	._68rIlB70IGaO {
		width: 270px;
		margin-top: -16px;
		margin-bottom: -16px;
		padding-left: 40px;

		background-position-x: 13px;
	}
}

@media screen and (max-width: 769px) {
	._10Ir29o17u9P {
		flex-direction: column;
	}

	._2iYL9QYBathS > div {
		flex-basis: 100%;
	}
}

@media screen and (max-width: 600px) {
	._1XvB4T1pCKnN {
		border-radius: 8px;
	}

	._1XvB4T1pCKnN:not(:last-of-type) {
		margin-bottom: 12px;
	}

	._21RPLaCpdDk1 {
		padding: 16px 20px;

		border-radius: 8px 8px 0 0;
		border-bottom: 1px dashed #bababa;

		background-color: #ffffff;
	}

	._1QxI0L2V-_NE {
		font-size: 18px;
		line-height: 27px;
	}

	._3Eg_bpbVluCJ {
		padding-right: 0;
	}

	._68rIlB70IGaO {
		width: 100%;
		margin: -16px 0;

		border-left: 0;
	}

	._1i0PYSfScbJ8 {
		position: relative;
		top: 3px;

		display: block;
	}

	._2Hle1QwAkIpO {
		font-size: 18px;
		line-height: 24px;
	}

	._10Ir29o17u9P {
		padding: 20px;

		border-top: none;
	}

	._1fmTA5JOb5Vo {
		justify-content: space-between;

		padding: 16px 20px;
		margin-left: 0;

		border-top: 1px dashed #d9d9d9;
	}

	._2FTOb88GhPjl {
		padding-left: 0;
		padding-right: 0;
	}

	._1lSfHcVdE782 {
		width: 100%;
		justify-content: space-between;
	}

	._2n95J04XYUWC {
		display: flex;
		justify-content: flex-end;
		max-width: 50%;
		margin-left: 0;
		height: 44px;
	}

	button._2rgFrtGduSYQ {
		flex: 1;
		max-width: 50%;
		height: 44px;

		line-height: 44px;

		background: #fafafa;
	}

	button._2rgFrtGduSYQ:hover {
		background: #fafafa;
	}

	.gKF84M5yp2HU {
		padding: 16px 20px;
		flex-direction: row-reverse;
		justify-content: space-between;

		border-top: 1px dashed #d9d9d9;
	}
}

@keyframes _3PRKqt3okW8M {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
