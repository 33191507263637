

._2cOoPOVnXf6g {
	display: flex;
}

.OrqAsTOXzlwl {
	display: block;
}

._2vQI0V3OtabJ {
	position: relative;

	display: inline-block;
	padding: 20px;

	border: 1px solid #cccccc;
	border-radius: 4px;

	font-size: 13px;

	background: #ffffff;
}

._19DEuFuFzn4N,
._2cVx0HoYrLwS {
	position: absolute;

	font-size: 21px;

	cursor: pointer;
}

._19DEuFuFzn4N {
	right: 20px;
}
