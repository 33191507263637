

._3X3vihf7-E6v {
	padding: 50px;
	min-height: calc(100vh - 80px);

	background: var(--refundVoidServices-background);
}

._28c3WJ5kTEaO {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 1;
}

._28c3WJ5kTEaO > div {
	background-color: var(--gray05);
}

._1tljt-EALLJY {
	display: flex;
	width: -moz-fit-content;
	width: fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin: 0 auto;
	flex-wrap: wrap;
}

._1tljt-EALLJY > div {
	width: 363px;
	margin-top: 10px;
}

._1tljt-EALLJY > div:not(:last-child) {
	margin-right: 20px;
}

._3KFVVOy_NTy3 {
	padding: 40px;
}

.TtdEtlIwg-fU {
	margin-bottom: 18px;
}

.Y_OzT62Z-uyx {
	align-self: flex-end;
}

._3k-RLkocUoYT {
	width: 120px;
}
