._3bHGddAztxMP {
	display: flex;
	align-items: center;
	height: 20px;
	margin: 0;

	font-size: 14px;
	color: #777777;
}

@media screen and (max-width: 600px) {
	._3bHGddAztxMP {
		padding: 12px 20px 12px 12px;
		height: auto;

		line-height: 150%;

		background-color: #ffffff;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
	}

	._3bHGddAztxMP:not(:first-of-type) {
		border-top: 1px dashed #bababa;
	}

	._3bHGddAztxMP:first-of-type {
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}

	._3bHGddAztxMP:last-of-type {
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	._3bHGddAztxMP:only-child {
		border-radius: 4px;
	}

	._3bHGddAztxMP svg {
		flex-shrink: 0;
		margin-right: 12px;
	}
}
