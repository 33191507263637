._2A0B-IsdRh3p {
	display: flex;
	flex-direction: column;
	padding: 20px;

	border-radius: 8px;

	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);

	background: var(--white);
}

.SRNYgX18nx9U {
	display: flex;
	align-items: center;

	font-size: 18px;
	font-weight: 700;
	line-height: 1.4;
}

._1iUCXFrIpCyH {
	padding-top: 20px;
	display: flex;
	justify-content: center;
	flex: 1;
}

.-MPGSLnD5qGh {
	padding-top: 16px;
	flex: 0;
}

._123FgRvji_Ur {
	margin-left: 12px;
}

@media screen and (max-width: 600px) {
	.SRNYgX18nx9U {
		flex-direction: column;
		align-items: flex-start;

		font-size: 20px;
	}

	._123FgRvji_Ur {
		margin-left: 0;
		margin-top: 10px;
	}
}
