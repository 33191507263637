
/* stylelint-disable */
.hChVDEZFSFiD {
}

/* stylelint-enable */
button._2E2WmoRQphir,
button._2E2WmoRQphir:hover {
	display: inline-flex;
	align-items: center;

	border: 1px solid var(--brand1-3);

	color: var(--base);

	background: var(--brand1-5);
}

button._2E2WmoRQphir:not(:last-child) {
	margin-right: 8px;
}
