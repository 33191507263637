._1cJsaJ7iyjbf {
	max-width: 900px;
	width: 100%;
	margin: 0 auto;
	padding-top: 30px;
}

._2f0Xgkph_kV_ {
	margin-top: 12px;
	padding: 24px;

	border-radius: 4px;

	background-color: #ffffff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);

	transition: box-shadow 0.15s;
}

._2f0Xgkph_kV_:hover {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 0 25px rgba(0, 0, 0, 0.12);
}

._3C0ZKiueOFfW {
	margin: 0;

	font-size: 20px;
	line-height: 28px;
}

._1EwRnhIxsLf7 {
	margin-top: 24px;
	display: flex;
	align-items: center;
}

._3EDym_-NcTSe {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

._2AWJIfStq1n0 {
	margin: 0 0 0 20px;

	color: var(--common-gray);
	font-size: 14px;
	line-height: 20px;
	white-space: pre-wrap;
}

._214VGJduIiLL {
	margin-top: 12px;
	height: 211px;
	max-width: 710px;

	background-image: url('https://cdn.websky.aero/content/frontend/images/receipt-example.png');
	background-size: contain;
	background-repeat: no-repeat;
}

._3wiCIWcBnVA_ {
	max-width: none;

	border-radius: 4px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);

	transition: box-shadow 0.15s;
}

._3wiCIWcBnVA_:hover {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 0 25px rgba(0, 0, 0, 0.12);
}

div._2X4siufrGJYV {
	max-width: 1250px;
}

@media screen and (max-width: 600px) {
	._1cJsaJ7iyjbf {
		padding-top: 0;
	}

	._3wiCIWcBnVA_,
	._3wiCIWcBnVA_:hover {
		border-radius: 0;

		box-shadow: none;
	}

	._2f0Xgkph_kV_,
	._2f0Xgkph_kV_:hover {
		margin-top: 0;
		padding: 20px;

		border-radius: 0;

		background-color: transparent;
		box-shadow: none;
	}

	._3C0ZKiueOFfW {
		color: #414141;
	}

	._1EwRnhIxsLf7 {
		margin-top: 16px;
	}

	._3EDym_-NcTSe {
		display: none;
	}

	._2AWJIfStq1n0 {
		margin-left: 0;

		font-size: 15px;
		line-height: 150%;
	}

	div._LIxW4g4YVmf {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	div._LIxW4g4YVmf div._2X4siufrGJYV {
		margin: 0;
		width: 100%;

		border-radius: 0;
	}
}
