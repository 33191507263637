
/* end mixins */
div._2j7yeMQ2O5mg {
	overflow-y: auto;

	background: var(--gray05);
}

button._2CpnXzIkuP03 {
	width: 100%;
	height: 49px;
	margin-top: 12px;

	color: #ffffff;
	line-height: 49px;

	background: var(--active);
}

button._2CpnXzIkuP03:hover {
	background: var(--active);
}

button._2pA20HbKiwMx {
	border: 1px solid var(--active);

	color: var(--active);

	background: #ffffff;
}

button._385UvpRNTJAL {
	border: none;

	background: none;
}

._3SO73w8NSDri {
	text-align: right;
}
