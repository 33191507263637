

._1baQq35a0iI2 {
	width: 420px;
}

._1baQq35a0iI2 > span > svg {
	color: var(--text-pale);

	transform: scale(0.6) !important;
}

._1rPe-ppcL1TI {
	margin: 50px 0 0 32px;

	font-weight: 700;
	font-size: 20px;
}

._3dqKxKLiZ3ku {
	margin: 0 32px;
}

div._2Hvkz1K04dg3,
button._2Hvkz1K04dg3,
button._2Hvkz1K04dg3:hover {
	margin-top: 16px;

	background-color: var(--brand-3-0);
}

._3-zY9XCX8nW7 {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 32px;
}

._2FCZftzCBfc_ {
	margin-top: 15px;

	overflow-wrap: break-word;
	color: var(--error-text);
}

div._2gMgGNlh2M_F {
	margin-top: 10px;
}
