

._3veSPs5pvWmE {
	font-size: 15px;
	color: var(--common-gray);
}

._3SPQ_VkGuhg_ {
	font-size: 13px;
	line-height: 24px;

	animation: _1NDUy6qFA4sO 0.3s ease;
}

._1JW0LoWDIpBA {
	margin-left: 3px;

	color: var(--cabinet-action);

	cursor: pointer;
}

.fX2BcM4CKzJV {
	display: flex;
	align-items: center;

	color: var(--common-green);
}

.fX2BcM4CKzJV svg {
	margin-right: 8px;
}

._1U5P__sZeP_H {
	display: flex;
	align-items: center;
}

._1U5P__sZeP_H svg {
	margin-right: 8px;
}

._1U5P__sZeP_H span {
	margin: 0 4px;
}

@keyframes _1NDUy6qFA4sO {
	from {
		transform: translateY(10px);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}
