._3JMMuLMnBi8V {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 40px 0;
}

._1Pfsz_B4Vg2_ {
	display: flex;
	align-items: center;

	font-weight: 600;
	font-size: 24px;
	color: var(--gray80);
}

._1Pfsz_B4Vg2_ svg {
	margin-right: 14px;
}

.EyMy1SZbykAy {
	font-weight: 500;
	font-size: 15px;
	color: var(--base);

	cursor: pointer;
}

._2Scn7wRmmyPX {
	display: flex;
	flex-direction: column;
	width: 100%;
}

._2Scn7wRmmyPX > div {
	margin: 0;
	max-width: 100%;
}

._2Scn7wRmmyPX > div:not(:last-child) {
	border-bottom: 1px dashed var(--gray20);
}

._1T-yKsfVyCwK {
	display: flex;
	margin-bottom: 8px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

._1lTM5nYlE2pI {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	min-width: 200px;
	padding: 0 12px;

	border-left: 1px dashed var(--gray20);

	color: var(--base-30);
	font-size: 14px;
}

._2TmEwxvdcc8M {
	margin-top: 8px;

	font-weight: 600;
	font-size: 23px;
}

.OfVTFVujXxFz {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	padding: 21px 16px;

	color: var(--base-30);
	font-size: 14px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

.CJFdU9eI7KUN {
	margin-top: 0;
}

._1QjiF1nb9Rs- {
	display: flex;
	align-items: flex-end;

	color: var(--base-30);
	font-size: 19px;
}

.e3rGmlyYYKAz {
	margin-top: 0;
	margin-left: 12px;

	font-size: 36px;
}

._3lKyTO1_uwsD ._3v7imVLadZUN {
	margin-bottom: 0;

	border-bottom: 1px solid #f4f4f4;
}

._3lKyTO1_uwsD .OfVTFVujXxFz:not(:last-of-type) {
	margin-bottom: 0;

	border-bottom: 1px solid #f4f4f4;
}

@media screen and (max-width: 600px) {
	._3JMMuLMnBi8V {
		margin-top: 0;
		margin-bottom: 16px;
	}

	._1Pfsz_B4Vg2_ {
		font-size: 20px;
	}

	._1Pfsz_B4Vg2_ svg {
		display: none;
	}

	._1T-yKsfVyCwK {
		flex-direction: column;
	}

	._2Scn7wRmmyPX > div:not(:last-of-type) {
		margin: 0;

		box-shadow: none;
	}

	._1lTM5nYlE2pI {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 18px 12px;

		border-left: none;
	}

	._2TmEwxvdcc8M {
		margin-top: 0;
	}

	._1QjiF1nb9Rs- {
		font-size: 18px;
	}

	.e3rGmlyYYKAz {
		font-size: 30px;
	}
}
