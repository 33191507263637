

._2AMc_0_2cULF {
	position: relative;

	max-width: 480px;
	min-height: 375px;
	margin: 50px auto;

	border-radius: 4px;

	box-shadow: 0 24px 84px rgba(0, 0, 0, 0.13);
	overflow: hidden;
}

._1wmjlMyiADuj {
	margin: 0;
}

div._3qVejqSY84Ci {
	width: 100%;
	height: 4px;

	background-color: var(--results-loader-progress-background);

	transform: none;
}

div.ZdarTZNCH1Oy {
	background-color: var(--results-loader-progress-color);
}

._1ST0_IdA9TNy {
	max-height: 150px;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

._1XAdIwGjY9tR {
	background: rgba(43, 180, 106, 0.48);
}

._3itaatooZuG4 {
	background: rgba(183, 0, 0, 0.3);
}

._3ewkN0jrsL9c {
	width: 100%;
	height: 50px;
	margin-top: 33px;
}

._1Am34BZ9Nn4u {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTY4IiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMTY4IDQ4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTM0LjQgNDhIMTM5LjJMMTUxLjIgMjcuNzg5NUgxNjQuNEMxNjYuMzkyIDI3Ljc4OTUgMTY4IDI2LjA5NjggMTY4IDI0QzE2OCAyMS45MDMyIDE2Ni4zOTIgMjAuMjEwNSAxNjQuNCAyMC4yMTA1SDE1MS4yTDEzOS4yIDBMMTM0LjQgMEwxNDAuNCAyMC4yMTA1SDEyNy4yTDEyMy42IDE1LjE1NzlIMTIwTDEyMi40IDI0TDEyMCAzMi44NDIxSDEyMy42TDEyNy4yIDI3Ljc4OTVIMTQwLjRMMTM0LjQgNDhaIiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB5PSI2Ljg1NzE4IiB3aWR0aD0iNTUuNzE0MyIgaGVpZ2h0PSIzNC4yODU3IiByeD0iMy40Mjg1NyIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iNC4yODUxNiIgeT0iMTMuNzE0NCIgd2lkdGg9IjQ3LjE0MjkiIGhlaWdodD0iNi44NTcxNCIgZmlsbD0iIzEyQTQ0QyIvPgo8cmVjdCB4PSI0LjI4NTE2IiB5PSIxMy43MTQ0IiB3aWR0aD0iNDcuMTQyOSIgaGVpZ2h0PSI2Ljg1NzE0IiBmaWxsPSJ3aGl0ZSIgZmlsbC1vcGFjaXR5PSIwLjYiLz4KPGNpcmNsZSBjeD0iMTA0LjU3MSIgY3k9IjI0LjAwMDEiIHI9IjQuMjg1NzEiIGZpbGw9IndoaXRlIi8+CjxjaXJjbGUgY3g9IjcwLjI4NTciIGN5PSIyNC4wMDAxIiByPSI0LjI4NTcxIiBmaWxsPSJ3aGl0ZSIvPgo8Y2lyY2xlIGN4PSI4Ny40MjgzIiBjeT0iMjQuMDAwMSIgcj0iNC4yODU3MSIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==) center no-repeat;
}

._2MPO7sJX4RFL {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTY4IiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMTY4IDQ4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTM0LjQgNDhIMTM5LjJMMTUxLjIgMjcuNzg5NUgxNjQuNEMxNjYuMzkyIDI3Ljc4OTUgMTY4IDI2LjA5NjggMTY4IDI0QzE2OCAyMS45MDMyIDE2Ni4zOTIgMjAuMjEwNSAxNjQuNCAyMC4yMTA1SDE1MS4yTDEzOS4yIDBMMTM0LjQgMEwxNDAuNCAyMC4yMTA1SDEyNy4yTDEyMy42IDE1LjE1NzlIMTIwTDEyMi40IDI0TDEyMCAzMi44NDIxSDEyMy42TDEyNy4yIDI3Ljc4OTVIMTQwLjRMMTM0LjQgNDhaIiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB5PSI2Ljg1NzE4IiB3aWR0aD0iNTUuNzE0MyIgaGVpZ2h0PSIzNC4yODU3IiByeD0iMy40Mjg1NyIgZmlsbD0id2hpdGUiLz4KPHJlY3Qgb3BhY2l0eT0iMC4zIiB4PSI0LjI4NTE2IiB5PSIxMy43MTQ0IiB3aWR0aD0iNDcuMTQyOSIgaGVpZ2h0PSI2Ljg1NzE0IiBmaWxsPSIjQjcwMDAwIi8+CjxjaXJjbGUgY3g9IjEwNC41NzEiIGN5PSIyNC4wMDAxIiByPSI0LjI4NTcxIiBmaWxsPSIjQjcwMDAwIi8+CjxjaXJjbGUgY3g9IjcwLjI4NTciIGN5PSIyNC4wMDAxIiByPSI0LjI4NTcxIiBmaWxsPSIjQjcwMDAwIi8+CjxjaXJjbGUgY3g9Ijg3LjQyODMiIGN5PSIyNC4wMDAxIiByPSI0LjI4NTcxIiBmaWxsPSIjQjcwMDAwIi8+Cjwvc3ZnPgo=) center no-repeat;
}

._3QJbgLGsz7Cm {
	position: relative;
	bottom: -40px;

	display: flex;
	justify-content: center;
	width: 100%;
	min-height: 80px;
}

.uXoiyQm24icm {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iODAiIHZpZXdCb3g9IjAgMCA4MCA4MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNDAiIGN5PSI0MCIgcj0iNDAiIGZpbGw9IiMyQkI0NkEiLz4KPHBhdGggZD0iTTI3IDQwLjk4MDRMMzUuMzkxOSA1MEw1NCAzMCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==) center no-repeat;
}

._3WLqfsnpVAT5 {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iODAiIHZpZXdCb3g9IjAgMCA4MCA4MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNDAiIGN5PSI0MCIgcj0iNDAiIGZpbGw9IiNCNzAwMDAiLz4KPHBhdGggZD0iTTI3IDQwLjk4MDRMMzUuMzkxOSA1MEw1NCAzMCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==) center no-repeat;
}

._1hlmbA_3_I_b {
	display: flex;
	flex-direction: column;
	align-items: center;
}

h1._3QWLQwBEaPK7 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 67px 0 40px;
	padding: 0 40px;

	font-size: 24px;
	font-weight: 500;
	line-height: 37px;
	text-align: center;

	color: #313131;
}

button._2NJchubUJ-iP {
	margin-bottom: 30px;
}
