._1s6x4Lhf_3zh {
	position: relative;

	display: inline-flex;
	flex-grow: 1;
	justify-content: space-between;
	align-items: center;
	height: 56px;
	padding-right: 40px;
	max-width: 345px;

	border-radius: var(--service-border-radius);

	color: #ffffff;
	font-size: 13px;

	cursor: pointer;
	background: var(--service-background-color);
}

._1s6x4Lhf_3zh:not(:last-of-type) {
	margin-right: 10px;
}

._1s6x4Lhf_3zh svg path {
	fill: #9a9a9a;
}

._1zb472YeCjYf {
	display: inline-flex;
	align-items: center;
	padding-left: 10px;
	height: 100%;
	width: 100%;
}

._2yDLDav4oLP5 {
	border: var(--service-hasProduct-border);

	color: var(--service-hasProduct-color);

	background-color: var(--service-hasProduct-background);
}

._2yDLDav4oLP5 ._1zb472YeCjYf {
	-webkit-text-decoration: var(--service-hasProduct-text-decoration);
	        text-decoration: var(--service-hasProduct-text-decoration);
}

._1s6x4Lhf_3zh:not(._2yDLDav4oLP5) svg path {
	fill: var(--service-icon-color);
}

._3WyztvaZDJwI {
	margin-left: 12px;
	margin-right: 10px;
}

.Nfb4pMl9PFnj {
	font-weight: 600;
	font-size: 14px;
}

._10_m54Zp0viB {
	font-size: 14px;
	color: #ffffff;

	opacity: 0.7;
}

._2yDLDav4oLP5 ._10_m54Zp0viB {
	color: var(--common-blue);

	opacity: 1;
}

._3JRnilXWn0i9 {
	position: absolute;
	top: 0;
	right: 8px;
	bottom: 0;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTEzIDEzSDE3VjExSDEzVjdIMTFWMTFIN1YxM0gxMVYxN0gxM1YxM1oiIGZpbGw9IndoaXRlIi8+CjxtYXNrIGlkPSJtYXNrMCIgbWFzay10eXBlPSJhbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMiIgeT0iMiIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTEzIDEzSDE3VjExSDEzVjdIMTFWMTFIN1YxM0gxMVYxN0gxM1YxM1oiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMCkiPgo8L2c+Cjwvc3ZnPgo=) center no-repeat;
}

._3tmM3fXWsbaz {
	width: 50%;
	padding-right: 10px;

	color: #777777;
	text-align: left;
}

._2iFJjzsF9_IL {
	font-weight: 600;
}

.QO3-AthzQiWj {
	font-weight: 600;
	color: var(--common-blue);
}

.frh9RgyZIRUS {
	display: flex;
	align-items: center;

	width: 32px;
	height: 32px;
}

._3Eda-wZcKbqO {
	display: flex;
}

.j0_D_yPMpwIg {
	position: relative;

	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
}

._3gLe-lVVCv9C {
	display: flex;
	height: 100%;
	align-items: center;
}

._2iG6DWxD0ZTG {
	font-size: 16px;
	color: #ffffff;
}

._2yDLDav4oLP5 ._2iG6DWxD0ZTG {
	color: var(--baggage-additional-selected-money-color);
}

._21061grRLFJZ ._3JRnilXWn0i9 {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTEzIDEzSDE3VjExSDEzVjdIMTFWMTFIN1YxM0gxMVYxN0gxM1YxM1oiIGZpbGw9IndoaXRlIi8+CjxtYXNrIGlkPSJtYXNrMCIgbWFzay10eXBlPSJhbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMiIgeT0iMiIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTEzIDEzSDE3VjExSDEzVjdIMTFWMTFIN1YxM0gxMVYxN0gxM1YxM1oiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMCkiPgo8L2c+Cjwvc3ZnPgo=);
}

._2yDLDav4oLP5 ._3JRnilXWn0i9,
._1EjcEJP9qK6b ._3JRnilXWn0i9 {
	position: absolute;
	top: 0;
	right: 8px;
	bottom: 0;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40NyA2LjQ3IDIgMTIgMkMxNy41MyAyIDIyIDYuNDcgMjIgMTJDMjIgMTcuNTMgMTcuNTMgMjIgMTIgMjJDNi40NyAyMiAyIDE3LjUzIDIgMTJaTTE1LjU5IDE3TDE3IDE1LjU5TDEzLjQxIDEyTDE3IDguNDFMMTUuNTkgN0wxMiAxMC41OUw4LjQxIDdMNyA4LjQxTDEwLjU5IDEyTDcgMTUuNTlMOC40MSAxN0wxMiAxMy40MUwxNS41OSAxN1oiIGZpbGw9IiM5QTlBOUEiLz4KPG1hc2sgaWQ9Im1hc2swIiBtYXNrLXR5cGU9ImFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIyIiB5PSIyIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIgMTJDMiA2LjQ3IDYuNDcgMiAxMiAyQzE3LjUzIDIgMjIgNi40NyAyMiAxMkMyMiAxNy41MyAxNy41MyAyMiAxMiAyMkM2LjQ3IDIyIDIgMTcuNTMgMiAxMlpNMTUuNTkgMTdMMTcgMTUuNTlMMTMuNDEgMTJMMTcgOC40MUwxNS41OSA3TDEyIDEwLjU5TDguNDEgN0w3IDguNDFMMTAuNTkgMTJMNyAxNS41OUw4LjQxIDE3TDEyIDEzLjQxTDE1LjU5IDE3WiIgZmlsbD0id2hpdGUiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swKSI+CjwvZz4KPC9zdmc+Cg==) center no-repeat;
}

._19wguvoTX4ED,
._1EjcEJP9qK6b._2yDLDav4oLP5 {
	padding-right: 15px;
}

._19wguvoTX4ED ._3JRnilXWn0i9 {
	position: absolute;
	top: 0;
	right: 8px;
	bottom: 0;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTEzIDExVjE3SDExVjExSDEzWk0xMiAyMEM3LjU4OTk5IDIwIDMuOTk5OTkgMTYuNDEgMy45OTk5OSAxMkMzLjk5OTk5IDcuNTkgNy41ODk5OSA0IDEyIDRDMTYuNDEgNCAyMCA3LjU5IDIwIDEyQzIwIDE2LjQxIDE2LjQxIDIwIDEyIDIwWk0xMyA3VjlIMTFWN0gxM1oiIGZpbGw9IiMzMjkwRkYiLz4KPC9zdmc+Cg==) center no-repeat;
}

._1k5sLJtW8slg {
	font-size: 16px;
	font-weight: 600;
	color: var(--service-totalPrice-color);
	text-decoration: none;
}

._2Jwr9J9JI-19 {
	margin-left: auto;
	margin-right: 25px;
}

div._2Jwr9J9JI-19 > div._249uHpgTAKh4 > span {
	color: var(--common-blue);
}

._34zKxo5QXNxI {
	overflow-y: auto;
	overflow-x: hidden;

	min-width: 820px;
}

@media screen and (max-width: 600px) {
	._1s6x4Lhf_3zh {
		width: 100%;
		max-width: unset;
	}

	._1s6x4Lhf_3zh:not(:last-child) {
		margin-bottom: 10px;
		margin-right: 0;
	}
}
