

/* stylelint-disable property-no-vendor-prefix */

/* stylelint-disable no-descending-specificity */
._1vci0GHwb0Ky {
	margin-bottom: 20px;
	margin-top: 50px;
	padding: 20px;

	background-color: var(--gray);
}

._2-EtlyZphT54 {
	display: flex;
	align-items: center;
	padding: 12px 0 32px;
}

._1LJu6Atk8wI9 {
	flex: 1;
	margin: 0;

	color: var(--gray80);
	font-weight: 700;
	font-size: 24px;
	line-height: 1;
	text-align: left;
}

.BaX1_TITJc5_ {
	margin: 0 0 9px;

	color: var(--gray80);
	font-weight: 500;
	font-size: 17px;
	line-height: 1;
}

._--8JTVhrdGHB {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin-right: 14px;

	border-radius: 50%;

	color: var(--gray);

	background-color: var(--base);
}

._--8JTVhrdGHB svg {
	display: inline-block;
	width: 20px;
	height: 20px;

	fill: currentColor;
}

._-3EqCikXnHfG {
	background-color: var(--selected2);
}

._3MQ7WQgH-HSR {
	margin-top: 12px;
	padding-top: 16px;

	border-top: 1px solid var(--gray20);
}

._3MQ7WQgH-HSR ._2vW8Cx6XHf5U {
	width: 100%;
}

.xtcQYpV7omME {
	--table-border-color: var(--gray20);
	width: 100%;

	border: 0;
	border-collapse: collapse;

	table-layout: auto;
	background-color: var(--gray);
}

.xtcQYpV7omME th {
	height: 47px;
	padding: 0;

	border: 0;

	color: var(--gray);
	font-weight: 700;
	font-size: 14px;
	line-height: 1.3;
	white-space: nowrap;
	text-align: left;

	background-color: var(--base);
}

.xtcQYpV7omME td {
	padding: 0;

	border: 0;

	color: var(--gray90);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
	text-align: left;
}

._2aUrJ0d76Ktd {
	border: 1px solid var(--table-border-color);
}

._2aUrJ0d76Ktd th,
._2aUrJ0d76Ktd td {
	border: 1px solid var(--table-border-color);
}

.KGHi7WqKm1vS {
	border: 1px solid var(--table-border-color);
}

.KGHi7WqKm1vS th,
.KGHi7WqKm1vS td {
	border-bottom: 1px solid var(--table-border-color);
}

.KGHi7WqKm1vS th {
	border-left: 1px solid var(--table-border-color);
	border-right: 1px solid var(--table-border-color);
}

._2LjEKWi6vCXP {
	--table-border-color: var(--base50);
}

._1K68gcdNwcer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: 47px;
	padding: 10px 12px;

	color: inherit;
	font-weight: inherit;
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;

	background-color: transparent;
}

._1K68gcdNwcer svg {
	display: inline-block;
	width: 24px;
	height: 24px;

	color: inherit;
}

._1K68gcdNwcer a {
	color: var(--base);
	text-decoration: none;
}

._1K68gcdNwcer a:hover {
	text-decoration: underline;
}

.kLYzdZBEb5is {
	padding: 0;
}

a._1K68gcdNwcer,
button._1K68gcdNwcer {
	border: 0;

	text-decoration: none;

	outline: 0;
	cursor: pointer;

	transition: background-color 150ms ease;
}

a._1K68gcdNwcer:hover,
button._1K68gcdNwcer:hover {
	background-color: var(--base-15);
}

._3tVt1TgqFSAg {
	white-space: nowrap;
}

._4Hrb-k6XckZw {
	text-transform: uppercase;
}

._3hNQVjiyi5kK,
._2_E9U4GuKSA5 {
	color: var(--error);
}

._2AoHr6U6zRtV,
._3nKEDDpGvEt1 {
	color: var(--success);
}

.Git5g9lIaUbh {
	justify-content: flex-end;

	text-align: right;
}

._34BThypfAq6K {
	justify-content: center;

	text-align: center;
}

._1z6MrLbgNjXA {
	justify-content: flex-start;

	text-align: left;
}

._1xRiAR-Ga9n5 ._1K68gcdNwcer {
	justify-content: center;

	text-align: center;
}

._1C-cAIpB1yRh {
	table-layout: fixed;
}

.RaDDd3qoxqmK {
	background-color: var(--gray05);
}

._1wURTL5KJWBm {
	background-color: var(--base90);
}

.xtcQYpV7omME ._2jZB0c_jlgoe {
	width: 100%;
}

.xtcQYpV7omME ._3Js4PjA58BVR {
	min-height: 47px;
	padding-left: 12px;

	border: 0;
}

.xtcQYpV7omME ._3Js4PjA58BVR:focus {
	box-shadow: inset 0 0 0 1px var(--base50);
	background-color: var(--base90);
}

.i1audavNyotT {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	margin: 0;
	padding: 0;

	border: 1px solid var(--base80);
	border-radius: 50%;

	color: var(--base);
	text-decoration: none;

	outline: 0;
	cursor: pointer;
	background-color: var(--base80);

	transition: all 150ms ease;
}

.i1audavNyotT svg {
	display: inline-block;
	width: 12px;
	height: 12px;

	color: inherit;

	fill: currentColor;
}

.i1audavNyotT:hover {
	border: 1px solid var(--base);

	color: var(--gray);

	background-color: var(--base);
}

.i1audavNyotT:hover svg {
	fill: var(--gray);
}

.xtcQYpV7omME th .i1audavNyotT {
	border-color: var(--gray);

	color: var(--gray);

	background-color: transparent;
}

.xtcQYpV7omME th .i1audavNyotT:hover {
	color: var(--base);

	background-color: var(--gray);
}

.xtcQYpV7omME th .i1audavNyotT:hover svg {
	fill: var(--base);
}

.bkSKJmW-V9NI {
	display: flex;
	align-items: center;
	margin-left: 11px;

	outline: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-ms-user-select: none;
	    user-select: none;
}

.bkSKJmW-V9NI + .bkSKJmW-V9NI {
	margin-top: 12px;
}

input._3lSy6xCanBcv {
	display: none;
}

._1QYH0lcwU0kL {
	position: relative;

	display: inline-block;
	flex-shrink: 0;
	width: 18px;
	height: 18px;

	border: 2px solid var(--gray30);
	border-radius: 4px;

	background-color: var(--gray);

	transition: all 150ms ease;
}

._1QYH0lcwU0kL:after {
	position: absolute;
	left: 1px;

	display: block;
	width: 10px;
	height: 6px;

	border-bottom: 2px solid var(--white);
	border-left: 2px solid var(--white);

	transform: rotate(-45deg) scale(0.9);
	opacity: 0;

	transition: all 150ms ease;
	content: '';
}

._3lSy6xCanBcv:checked + ._1QYH0lcwU0kL {
	border-color: var(--base);

	background-color: var(--base);
}

._3lSy6xCanBcv:checked + ._1QYH0lcwU0kL:after {
	opacity: 1;
}

.uTzPYEGDKo3J {
	display: inline-block;
	flex: 1;
	margin-left: 8px;
	padding-top: 1px;

	color: var(--gray80);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.2;
}

._3lSy6xCanBcv:checked ~ .uTzPYEGDKo3J {
	color: var(--base);
}

._3lSy6xCanBcv._8qxm6-3VHiOw + ._1QYH0lcwU0kL,
._1RaEwfRQVbt2 + ._1QYH0lcwU0kL {
	border-color: var(--error);
}

._3lSy6xCanBcv._8qxm6-3VHiOw:checked + ._1QYH0lcwU0kL,
._1RaEwfRQVbt2:checked + ._1QYH0lcwU0kL {
	border-color: var(--error);

	background-color: var(--error);
}

._3lSy6xCanBcv._8qxm6-3VHiOw ~ .uTzPYEGDKo3J,
._1RaEwfRQVbt2 ~ .uTzPYEGDKo3J {
	color: var(--error);
}

._3lSy6xCanBcv._8qxm6-3VHiOw:checked ~ .uTzPYEGDKo3J,
._1RaEwfRQVbt2:checked ~ .uTzPYEGDKo3J {
	color: var(--error);
}

._3lSy6xCanBcv:disabled + ._1QYH0lcwU0kL {
	border-color: var(--main-actions-formCheckbox-input-disabled-color);

	background-color: var(--main-actions-formCheckbox-input-disabled-bg);
}

._3lSy6xCanBcv:disabled + ._1QYH0lcwU0kL:after {
	border-left-color: var(--main-actions-formCheckbox-input-disabled-color);
	border-bottom-color: var(--main-actions-formCheckbox-input-disabled-color);
}

._3lSy6xCanBcv:disabled ~ .uTzPYEGDKo3J {
	color: var(--main-actions-formCheckbox-input-disabled-color);
}

._2qix_jB3QLg9 {
	display: flex;
	justify-content: space-between;
	margin: 21px 0;
}

._1lb71UuaNVyJ {
	width: 49%;
}

._2jZB0c_jlgoe {
	position: relative;

	display: flex;
	flex-direction: column;

	text-align: left;

	outline: 0;
}

._3Js4PjA58BVR {
	display: block;
	flex: 1 1 100%;
	order: 2;
	max-width: 100%;
	min-height: 44px;
	padding: 11px 10px 12px;

	border: 1px solid var(--gray10);
	border-radius: 0;

	color: var(--gray90);
	font-weight: 400;
	font-size: 16px;
	line-height: 1.2;

	outline: 0;
	background-color: var(--gray);

	transition: all 150ms ease;

	-webkit-appearance: none;
	appearance: none;
}

._1DUrk5wng9ts {
	padding: 12px 0;

	border-width: 0 0 1px;
}

select._3Js4PjA58BVR {
	--image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='25' height='24'><path d='M7.625 10L12.625 15L17.625 10H7.625Z' fill='rgb(179,179,179)'/></svg>");
	padding-right: 32px;

	cursor: pointer;
	background-image: var(--image);
	background-repeat: no-repeat;
	background-position: right 12px center;
}

._3Js4PjA58BVR::-webkit-input-placeholder {
	color: var(--gray50);
}

._3Js4PjA58BVR::-moz-placeholder {
	color: var(--gray50);
}

._1qTtLijdLnoE {
	display: block;
	order: 1;
	margin: 0 0 3px;

	color: var(--gray50);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.2;

	outline: 0;

	transition: color 150ms ease;
	-webkit-user-select: none;
	-ms-user-select: none;
	    user-select: none;
}

._3Js4PjA58BVR:not(._1KK7eQ7MOMpo):not(._1Zz20Lx2fZsv):focus {
	border-color: var(--base);
}

._3Js4PjA58BVR:not(._2xCWD_Hq-b4s):not(._8qxm6-3VHiOw):not(._3J7k03Zm1Gnp):not(._1sANHmoVke2c):not(._3SIhkGDHmkdi):not(._2SGKSezsym5X):not(._1KK7eQ7MOMpo):not(._1Zz20Lx2fZsv):focus
	+ ._1qTtLijdLnoE {
	color: var(--base);
}

._3J7k03Zm1Gnp,
._3Js4PjA58BVR._1sANHmoVke2c {
	border-color: var(--success);

	background-color: var(--success90);
}

._3J7k03Zm1Gnp + ._1qTtLijdLnoE,
._3Js4PjA58BVR._1sANHmoVke2c + ._1qTtLijdLnoE {
	color: var(--success-20);
}

._3SIhkGDHmkdi,
._3Js4PjA58BVR._2SGKSezsym5X {
	border-color: var(--warning);

	background-color: var(--warning20);
}

._3SIhkGDHmkdi + ._1qTtLijdLnoE,
._3Js4PjA58BVR._2SGKSezsym5X + ._1qTtLijdLnoE {
	color: var(--error);
}

._3Js4PjA58BVR:disabled,
._1KK7eQ7MOMpo,
._3Js4PjA58BVR._1Zz20Lx2fZsv {
	border-color: var(--gray05);

	color: var(--gray50);

	cursor: default;
	background-color: var(--gray05);
}

._1DUrk5wng9ts:disabled,
._1nYOiDrJ0U7b,
._1DUrk5wng9ts._1Zz20Lx2fZsv {
	background-color: var(--gray);
}

.tvyBmvYmv3O6 {
	order: 3;
	display: block;
	margin: 4px 0 0;

	font-size: 13px;
	line-height: 1.2;
	font-weight: 400;
	color: var(--error);
}

._2xCWD_Hq-b4s,
._3Js4PjA58BVR._8qxm6-3VHiOw {
	border-color: var(--error);

	background-color: var(--warning20);
}

._2xCWD_Hq-b4s + ._1qTtLijdLnoE,
._3Js4PjA58BVR._8qxm6-3VHiOw + ._1qTtLijdLnoE {
	color: var(--error);
}

._2jZB0c_jlgoe .bkSKJmW-V9NI {
	order: 2;
	flex: 1 1 100%;
	max-width: 100%;
	margin-top: 5px;
}

._1rsWmRZyrx-r {
	display: flex;
	align-items: center;
}

._1rsWmRZyrx-r > div:not(:first-child) {
	margin-left: 10px;
}

._1Ew8jOsxkIt1 {
	display: flex;
	width: 277px;
	justify-content: space-between;
	margin-top: 16px;
}

._2ickkOOdQrq9 {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

._3LCqCHRKZYvH {
	margin-top: 10px;
}

._1WEuV0KbZMr1 {
	margin-bottom: 20px;
}

.Zr_RmW6Oskeq {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 0 20px;
}

.Zr_RmW6Oskeq > div {
	margin-top: 20px;
}

._3768_zjIDLrt > div {
	flex-grow: 1;
}

._2vW8Cx6XHf5U {
	--button-bg: var(--active);
	--button-color: var(--gray);
	display: inline-block;
	padding: 13px 32px;

	border: 1px solid var(--button-bg);
	border-radius: 4px;

	color: var(--button-color);
	font-weight: 700;
	font-size: 17px;
	line-height: 1.2;
	text-align: center;
	text-decoration: none;
	vertical-align: top;

	outline: 0;
	cursor: pointer;
	background-color: var(--button-bg);

	transition: all 150ms ease;
	-webkit-user-select: none;
	-ms-user-select: none;
	    user-select: none;
}

._2vW8Cx6XHf5U:not(:disabled):not(._1Zz20Lx2fZsv):not(._1jJWvNIOHHGW):hover {
	--button-color: var(--gray);
	--button-bg: var(--active-30);
	text-decoration: none;

	box-shadow: 2px 4px 10px var(--main-actions-formButton-notDisabled-shadow);
}

._2vW8Cx6XHf5U:not(:disabled):not(._1Zz20Lx2fZsv):not(._1jJWvNIOHHGW):active {
	--button-color: var(--gray);
	--button-bg: var(--active-50);
	box-shadow: none;
}

._3UXQ4dyQdvm5 {
	--button-bg: var(--gray05);
	--button-color: var(--gray70);
	font-weight: 500;
}

._3UXQ4dyQdvm5:not(:disabled):not(._1Zz20Lx2fZsv):not(._1jJWvNIOHHGW):hover {
	--button-color: var(--gray70);
	--button-bg: var(--gray10);
	box-shadow: none;
}

._3UXQ4dyQdvm5:not(:disabled):not(._1Zz20Lx2fZsv):not(._1jJWvNIOHHGW):active {
	--button-color: var(--gray70);
	--button-bg: var(--gray20);
	box-shadow: none;
}

._3YjRUsGyAgQi {
	--button-bg: var(--base);
	--button-color: var(--gray);
}

._3YjRUsGyAgQi:not(:disabled):not(._1Zz20Lx2fZsv):not(._1jJWvNIOHHGW):hover {
	--button-color: var(--gray);
	--button-bg: var(--base-15);
	box-shadow: 2px 4px 10px var(--main-actions-formButton-notDisabled-shadow);
}

._3YjRUsGyAgQi:not(:disabled):not(._1Zz20Lx2fZsv):not(._1jJWvNIOHHGW):active {
	--button-color: var(--gray);
	--button-bg: var(--base-30);
	box-shadow: none;
}

._2vW8Cx6XHf5U:disabled,
._2vW8Cx6XHf5U._1Zz20Lx2fZsv,
._1jJWvNIOHHGW {
	--button-bg: var(--gray10);
	--button-color: var(--gray30);
}

._2-sQsP5fNyJH {
	padding: 11px 16px;

	font-size: 14px;
	line-height: 1.2;
}

label._2eFQQbKIYjgY {
	padding-bottom: 5px;

	color: var(--gray50);
	font-weight: 400;
	font-size: 19px;
}

div._1cXJgLkH8SaL {
	height: 26px;
	padding-top: 9px;
	padding-left: 10px;

	border: 1px solid var(--gray10);

	line-height: 26px;

	background: var(--white);
}

div._1cXJgLkH8SaL:focus {
	background: var(--white);
}

._1qtdUjeGMpeb {
	border: 0;
}

._2FWLngjt9Hex svg path {
	fill: var(--base);
}

div._2M5Gj3Mk2Rdt {
	margin-top: 19px;
}

div._1qtdUjeGMpeb {
	border: none;
}

._3ZOVOFn3qgJi {
	background-color: var(--white);
}

._1sew3uwAAdgI {
	align-items: normal;
}

._28ypYXxdBW4b {
	color: var(--base);
}

._2bdXDIoq3vEs {
	position: absolute;
	bottom: -17px;

	order: 3;

	color: var(--error);
	font-size: 13px;
}
