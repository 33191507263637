._3D8kRIzqFE_6 {
	margin-top: 0;
	margin-bottom: 24px;

	font-size: 28px;
	color: var(--dark-gray);
	font-weight: 500;
}

._12LlcU1RW_4A {
	display: flex;
	justify-content: space-between;
	padding: 28px;
	max-width: 780px;

	border-radius: 4px;

	background-color: #ffffff;
}

.oOtKzAutKDsr {
	width: 54%;
}

._1kuFoq5NdSfX {
	display: flex;
	flex-direction: column;
	justify-content: center;

	margin-bottom: 17px;
}

._22MY3oxKB9yQ {
	margin-bottom: 4px;

	color: #727272;
	font-size: 14px;
}

.JRLyj5QQW0hG {
	margin-top: 12px;
}

._285vGagFuB-S {
	font-size: 27px;
	font-weight: 700;
	line-height: 37px;
	color: #292929;
	text-transform: capitalize;
}

._1kuFoq5NdSfX h1 {
	margin: 0;

	color: var(--dark-gray);
	font-size: 31px;
}

._1kuFoq5NdSfX [role='progressbar'] {
	height: 8px;
	margin-top: 8px;
	margin-bottom: 8px;

	border-radius: 4px;

	background-color: #ededed;
}

._1kuFoq5NdSfX ._21j1i9ViMqQ3 {
	border-radius: 4px;

	background-color: #506dd2;
}

._3DMKO0lE8mv1 {
	display: flex;
	flex-wrap: wrap;
}

._68p2Ra7jkFai ._1kuFoq5NdSfX ._21j1i9ViMqQ3 {
	background-color: #ff9a01;
}

._27ZSJ-_FdZl6 ._1kuFoq5NdSfX ._21j1i9ViMqQ3 {
	background-color: #727272;
}

._2S0rEhFwgyDL ._1kuFoq5NdSfX ._21j1i9ViMqQ3 {
	background-color: #414141;
}

._2wKkUB155lo7,
._19m4P6JNZ42P {
	color: #727272;
	font-size: 14px;
}

._2wKkUB155lo7:not(:last-of-type),
._19m4P6JNZ42P:not(:last-of-type) {
	padding-bottom: 5px;
}

._2wKkUB155lo7 span,
._19m4P6JNZ42P span {
	padding-left: 0;
}

._3l5V0BzAFpth {
	position: relative;

	height: 20px;
	padding-right: 9px;
	margin-right: 8px;

	font-size: 13px;
	line-height: 200%;

	color: var(--common-blue);
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
	white-space: nowrap;

	cursor: pointer;
}

._3l5V0BzAFpth:not(:last-child):after {
	content: '';

	position: absolute;
	top: -2px;

	margin-left: 8px;
	height: 20px;

	border-right: 1px solid #ededed;
}

._10dBttKtDVxV {
	height: 20px;
	margin: 0 8px;

	border: 1px solid #ededed;
}

._1kdqw1hN-kMA {
	margin-top: 12px;
	flex-direction: column;
}

._1kuFoq5NdSfX h1 span {
	margin-right: 8px;

	font-size: 49px;
}

._2PEJKLQoA5oR {
	margin: 0 0 20px;
	height: 24px;

	font-weight: 500;
	font-size: 22px;
}

._3EmVo6Q8vlZd {
	display: flex;
	flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
	._12LlcU1RW_4A {
		padding: 20px;
	}

	.oOtKzAutKDsr {
		width: 100%;
	}

	._22MY3oxKB9yQ {
		color: #292929;
		font-size: 28px;
	}

	._3aIlQrorMSez {
		font-weight: 700;
		font-size: 48px;
	}

	._1kuFoq5NdSfX ._21j1i9ViMqQ3 {
		background: linear-gradient(180deg, #ffbb0c 0%, #e57900 100%);
	}

	._1kuFoq5NdSfX ._2wKkUB155lo7 {
		font-size: 14px;
		color: #727272;
	}

	._3l5V0BzAFpth {
		font-size: 15px;
		text-decoration: none;
	}

	._12LlcU1RW_4A > div:first-child {
		margin-right: 0;
		display: flex;
		max-width: 100%;
		flex-direction: column;
	}

	._12LlcU1RW_4A > div > div:last-child {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	._12LlcU1RW_4A._1kdqw1hN-kMA > div:nth-child(2) > div {
		justify-content: flex-start;
	}

	._12LlcU1RW_4A._1kdqw1hN-kMA > div:nth-child(2) > div > div > div > div > button > span > span > span > div > div {
		margin-bottom: 0;

		text-transform: none;
		font-weight: 500;
	}

	._12LlcU1RW_4A._1kdqw1hN-kMA > ._2PEJKLQoA5oR {
		margin: 0;
	}

	._1kuFoq5NdSfX {
		margin-bottom: 20px;
	}

	._1kuFoq5NdSfX h1 {
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
	}

	._1kuFoq5NdSfX h1 span {
		display: flex;
	}

	._3EmVo6Q8vlZd {
		justify-content: center;
		margin-top: 20px;
	}
}

div.d_mPdJ4Eq7Et {
	display: flex;
	align-items: center;
}

div._1ApysAh-5qsK {
	min-width: 420px;
	max-width: 420px;
}

._1fH2qDLzUr02 {
	padding: 44px 48px;
}

._3gR6AfYAUHZ1 {
	margin-bottom: 28px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 20px;
	line-height: 1.6;
}

._3FvyX3CGv6Fl {
	margin-bottom: 40px;

	color: var(--sureModal-text-color);
	font-size: 17px;
	line-height: 1.7;
}

._67DZUdptLPKM {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.XBMyHzenBB2u {
	color: var(--sureModal-cancel-color);

	background-color: var(--sureModal-cancel-bg);
}

button.XBMyHzenBB2u:hover {
	background-color: var(--sureModal-cancel-bg);
}

._67DZUdptLPKM button:not(:last-child) {
	margin-right: 12px;
}

@media screen and (max-width: 600px) {
	div.d_mPdJ4Eq7Et {
		flex-direction: column;
		justify-content: flex-end;
	}

	div._1ApysAh-5qsK {
		width: 100%;
		min-width: auto;
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}

	._1fH2qDLzUr02 {
		padding: 24px 28px;
	}
}
