

._1VMeAjVMYlqN {
	display: flex;
	margin: 8px 0;
}

._3EtZe9KK9l1j {
	flex: 1;
}

div._3EtZe9KK9l1j:last-child {
	margin-right: 0;
}

._3EtZe9KK9l1j._3ZKX9gc2tvSW {
	background: #ffffff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

._3ZKX9gc2tvSW .QBMk-wGiT2oB {
	color: var(--common-blue);
	text-decoration: underline;
}
