

div._1QNwKDcQVdIr:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;

	border-radius: 4px;

	background: #cccccc;

	animation: _21SRRfZJ4EmL infinite 2s;
	content: '';
}

@keyframes _21SRRfZJ4EmL {
	from {
		background-color: var(--gray20);
	}

	50% {
		background-color: var(--gray10);
	}

	to {
		background-color: var(--gray20);
	}
}
