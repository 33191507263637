

._4n7QzUsxB2op {
	padding: 68px 20px 20px;
}

div._2-52PrP4leNt {
	max-width: 640px;

	border-radius: 20px;
}

._3qn5M6znnqRM {
	width: 100%;
	margin-right: 98px;

	border-spacing: 0;

	font-size: 14px;
}

._3qn5M6znnqRM td {
	height: 47px;
	padding: 0 12px;
}

td._3rHOrMADkeCu,
td._2PyzOoQh-FD0 {
	font-weight: 500;
	color: var(--base);
}

._3qn5M6znnqRM thead {
	color: #ffffff;
	font-weight: 500;

	background: var(--base);
}

._3qn5M6znnqRM tbody tr {
	color: var(--gray90);

	cursor: pointer;
	background: #ffffff;
}

._3qn5M6znnqRM tbody tr._2gZHkUyroyqh {
	background: var(--success90);
}

._2JJ-X6DWG7l4 {
	height: 52px;
	padding: 0 12px;

	color: var(--gray80);
	font-weight: 700;
	font-size: 16px;
	line-height: 52px;

	background: #ebf4ff;
}

._33CG2Ew-KtYs {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 52px;

	border-bottom: 1px dashed var(--gray10);

	color: var(--gray80);
	font-weight: 500;
	font-size: 15px;
}

._3MEGXWzRQa3J {
	text-transform: capitalize;
}

.kTtaLujZmP_K {
	display: flex;
	align-items: center;
}

._3DM8TaOFUBAR {
	width: 24px;
	height: 24px;
	margin-right: 14px;

	border-radius: 50%;

	color: var(--gray50);
	font-size: 14px;
	line-height: 24px;
	text-align: center;

	background: var(--gray05);
}

._3YsosRd99ArP {
	position: absolute;
	right: 0;
}

._1KxgknQf5C20 {
	display: flex;
	justify-content: flex-end;
}

._1oftzK1VDh3N {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 24px 0;
}

._2T7sI-Xgnwkd {
	display: flex;
	align-items: center;
}

._2T7sI-Xgnwkd a {
	font-weight: 500;
	text-decoration: none;
}

.t4ymPyZRM2We {
	color: var(--gray80);
	font-weight: 700;
	font-size: 22px;
}

._1nqXzgM3wbHa {
	margin-left: 12px;
	padding-left: 12px;

	border-left: 1px solid var(--gray20);
}

button._2mvp38KUSIXu,
button._2mvp38KUSIXu:hover {
	margin-right: 12px;

	color: var(--gray70);

	background: var(--gray05);
}

button.tqIYyApS0jxB {
	color: #ffffff;

	background: var(--base);
}

.YWbCTh36MNTt {
	z-index: 1501 !important;
}
