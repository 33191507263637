

._3l9VZoGxqXeQ {
	top: 0;
	left: 114px;
}

._3lWJTB4Qi2D1 {
	position: absolute;
	top: -55px;
	right: 0;

	color: var(--dark-gray);
	font-size: 12px;
}

._1Q-K1GC5-0op {
	position: relative;

	display: inline-flex;
	margin-top: 80px;
}

.L_HIO7vasd60 {
	display: flex;
}

.nzxrJGARkw1w {
	width: 114px;
	height: 32px;

	border-right: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;

	color: var(--light-gray);
	font-weight: 600;
	font-size: 13px;
	line-height: 32px;
	text-align: center;
}

div.Om4njrYY865S {
	background: rgba(34, 144, 245, 0.1);
}

.nzxrJGARkw1w:not(._2F_gX8K4PTa2) {
	cursor: pointer;
}

.nzxrJGARkw1w:hover:not(._2F_gX8K4PTa2).Om4njrYY865S {
	color: #ffffff;

	background: #2290f5;
}

._1zszPZqHEcmC {
	position: relative;

	color: var(--dark-gray);
}

._1zszPZqHEcmC:before {
	position: absolute;
	top: -1px;
	left: 8px;

	width: 8px;
	height: 12px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wIDBIOC4yNVYxMkw0LjEyNSA4LjYyNUwwIDEyVjBaIiBmaWxsPSIjRkZCODAwIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

.nzxrJGARkw1w ._3tIfBzeevmCB {
	padding: 0;
}

._2s3gvhVjY9VQ {
	position: relative;
}

._2s3gvhVjY9VQ:after {
	position: absolute;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 14px;
	height: 14px;
	margin: auto;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgBldJND8FAEAbgqfqmREiERBzExdHZmRs/yk90k0gEcUEQX1Ua3snOJuuA7SRPmzR9O9vZdeiz4tCEGiTgCSexhEC/6BihBrRhATvYy/McVKEIK9iYnVrQhyx9ryR0oETGF0d/QmaYVxVzcenKP8wtgiGHIM2XMkzJvi7gcbAChwjBGzgcdCl6hRy8kto32+L99blbBgqwtgzmdccJqf3xLEIx6XjmjjxiPkoDmMH9R4j33IeHHsxW7kNSUz6SGjsXH0s+wylpEOiHZvF57JEaVh3G0smRlb30i28kaCe1Az1r+QAAAABJRU5ErkJggg==) center center no-repeat;
	content: '';
}
