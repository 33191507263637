

.XRV54QDTvN7u {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 24px;
}

._5TefDrvJhD0T {
	color: var(--baggage-additional-money-color);
	font-size: 22px;
	font-weight: 600;
}

._3MYvQ5RgFEyZ {
	font-size: 15px;
}

button._1zcBycuwkIdG {
	min-height: 48px;

	border-radius: 24px;

	color: #ffffff;

	font-weight: 600;
	font-size: 13px;
	line-height: 18px;

	background-color: var(--baggage-additional-money-color);
}

.fP6S8EVpoVHg ._5TefDrvJhD0T {
	color: #686868;
}

.fP6S8EVpoVHg button._1zcBycuwkIdG {
	color: var(--dark-gray);

	background-color: #ededed;
}
