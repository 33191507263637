div._2OjpQs6G0aRr {
	z-index: 1600 !important;
}

div.jbB0LBYwsYYS {
	top: 50%;

	width: 100%;
	max-width: 760px;

	transform: translate(0, -60%);
}

.lYMTn1kDJsbX {
	padding: 28px;

	color: #ffffff;
	font-weight: 600;
	font-size: 24px;

	background: #5ba6ff;
}

.Xn_vbbMYrkvT {
	display: flex;
	flex-direction: column;
	padding: 40px 20px 40px 32px;

	background: #f0f0f0;
}

button._3iMCxiGkZ_0q {
	margin-top: 20px;
	margin-left: auto;
	padding: 12px 50px;

	border-radius: 4px;

	font-weight: 500;
	font-size: 15px;
	line-height: 17px;

	background: #3290ff;
}

button._3iMCxiGkZ_0q[disabled] {
	background: #b1b1b1 !important;
}

@media screen and (max-width: 600px) {
	div.jbB0LBYwsYYS {
		top: 0;

		width: 100%;

		transform: none;
	}

	.Xn_vbbMYrkvT {
		height: calc(100% - 53px); /* header height */
		padding: 20px 0 0;
	}

	.lYMTn1kDJsbX {
		padding: 14px 18px;

		font-size: 20px;
		line-height: 25px;
	}

	button._3iMCxiGkZ_0q {
		width: 100%;
		min-height: 60px;
		margin-top: auto;

		border-radius: 0;

		background: var(--common-orange);
	}

	button._3iMCxiGkZ_0q[disabled] {
		background: #b1b1b1 !important;
	}

	button._3iMCxiGkZ_0q:hover {
		background: var(--dark-orange);
	}
}
