

._2FbsrwugicR9 {
	z-index: 1;

	background-image: none !important;
}

._274jF3f7VNYL {
	z-index: -1;
}

._1-hUU_H0LxuL {
	z-index: unset;
}
