._1Rp735o3-nGo {
	flex: 1;
	padding: 0;

	list-style: none;
}

._3pjJE-365R9D {
	display: flex;
	margin-bottom: 8px;

	font-size: 19px;
}

._3pjJE-365R9D:last-child {
	margin-bottom: 0;
}

._3MxHdCRBuIi_ {
	display: flex;
}

._3MxHdCRBuIi_ div {
	text-align: center;
}

._3MxHdCRBuIi_ div:not(:last-child) {
	padding-right: 28px;
}

@media screen and (max-width: 600px) {
	._3pjJE-365R9D {
		display: block;

		font-size: 16px;
	}

	._3pjJE-365R9D:not(:first-child) {
		margin-top: 20px;
	}

	._3MxHdCRBuIi_ div:not(:last-child) {
		padding-right: 15px;
	}

	._3MxHdCRBuIi_ {
		padding-top: 5px;
	}
}

.SlCjf7Vykrkg {
	flex: 1;

	min-width: 150px;

	font-weight: 600;

	white-space: nowrap;
	text-transform: uppercase;
	text-overflow: ellipsis;
	overflow: hidden;
}

.lsoiyk6G5gVi {
	font-weight: 600;
}

@media screen and (max-width: 600px) {
	.lsoiyk6G5gVi {
		font-weight: 400;
	}
}
