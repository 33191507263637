

._1A9bu6nWZV6o {
	padding: 20px;
}

._14cWonAj2aaJ {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 280px 1fr;
}

.vrFY2kcmeOfH {
	grid-area: 'sidebar';
}

div._3S3Vw_GWGR0t,
div._3S3Vw_GWGR0t > div {
	width: 25px;
	height: 25px;

	background-size: 15px;
}

div._3nuHghGV6sFV {
	background-color: var(--brand-1-1);
}

div._2QBX3Kfa8TU6 {
	background-color: var(--brand-1--1);
}

._2kZ_puIi6mLh {
	position: relative;

	grid-area: 'content';
}

._3V8MQh-Fuf78 svg {
	width: 24px;
	height: 24px;

	pointer-events: none;
}

._3fATz5P3yjt9 {
	width: 100%;

	border: 0;
	border-collapse: collapse;

	table-layout: fixed;
	background-color: var(--gray);
}

._3fATz5P3yjt9 tr:not(:last-of-type) {
	border-bottom: 1px solid var(--line-separator);
}

._24KM5moTy3PA svg {
	display: inline-block;
	width: 24px;
	height: 24px;

	color: inherit;
}

._3fATz5P3yjt9 th {
	height: 47px;
	padding: 0;

	border: 0;

	color: var(--gray);
	font-weight: 700;
	font-size: 14px;
	line-height: 1.3;
	white-space: nowrap;
	text-align: left;

	background-color: var(--base);
}

._3fATz5P3yjt9 th.z8-NoTdmR4sY {
	width: 13%;
}

._3fATz5P3yjt9 td {
	padding: 0;

	border: 0;

	color: var(--gray90);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
	text-align: left;
}

._3fATz5P3yjt9 td._3Q9fh4xhpyPQ > div {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	opacity: 0;

	transition: opacity 0.2s;
}

td._3Q9fh4xhpyPQ svg {
	margin: 8px;

	color: var(--text-light);
}

._3fATz5P3yjt9 tr:hover {
	background: var(--brand-1-5);
}

._3fATz5P3yjt9 tr:hover td._3Q9fh4xhpyPQ > div {
	opacity: 1;
}

._24KM5moTy3PA {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: 47px;
	padding: 10px 12px;

	flex: 0;

	color: inherit;
	font-weight: inherit;
	font-size: 14px;
	font-family: inherit;
	line-height: inherit;

	background-color: transparent;
}

._24KM5moTy3PA a {
	text-decoration: none;
	color: inherit;
}

._3L5Nz-YBlLFb {
	flex: 1;
}

._3id9biYxNukc {
	cursor: pointer;
}

._3MfaQ5LewxFW {
	background: var(--base90);
}

a._24KM5moTy3PA,
button._24KM5moTy3PA {
	border: 0;

	text-decoration: none;

	outline: 0;
	cursor: pointer;

	transition: background-color 150ms ease;
}

a._24KM5moTy3PA:hover,
button._24KM5moTy3PA:hover {
	background-color: var(--base-15);
}

._295xNggdb0rq {
	white-space: nowrap;
}

.bDCccf7iRVtE {
	text-transform: uppercase;
}

._3o28CyDIpyF_,
._9CUmrE70Ldds {
	color: var(--error);
}

._2lFHQovQSCRK,
._2PCekUQxxiaa {
	color: var(--success);
}

.W-bvawbNo-wb {
	justify-content: flex-end;

	text-align: right;
}

._34Mh-iCEvghr {
	justify-content: center;

	text-align: center;
}

._39GxTc6ynP0T {
	justify-content: flex-start;

	text-align: left;
}

._3V8MQh-Fuf78 {
	display: flex;
	justify-content: center;
	align-items: center;
}

._2lQKR3iMrHO7 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 2px;
	padding: 5px;

	border: 1px solid var(--gray05);
	border-radius: 4px;

	color: var(--gray);
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;

	outline: 0;
	box-shadow: none;
	cursor: pointer;
	background-color: var(--base10);

	transition: background-color 150ms ease;
}

._2lQKR3iMrHO7:not(.j-0InjrsIhXP):not([disabled]):hover {
	background-color: var(--base-15);
}

._2lQKR3iMrHO7:not(.j-0InjrsIhXP):not([disabled]):active {
	background-color: var(--base-30);
}

.j-0InjrsIhXP,
._2lQKR3iMrHO7[disabled] {
	cursor: default;
	opacity: 0.5;
}

._35zdoXf709G4 {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 18px;
}

._2qy2TW5Q3vnx {
	display: inline-block;
	width: 36px;
	height: 36px;
	margin: 0 10px;
	padding: 4px;

	border: 1px solid var(--gray20);
	border-radius: 2px;

	color: var(--gray50);
	font-weight: 400;
	font-size: 17px;
	font-family: inherit;
	line-height: 1.6;
	text-align: center;

	outline: 0;
	background-color: var(--gray);

	transition: border-color 150ms ease;
}

._2qy2TW5Q3vnx:focus {
	border-color: var(--base);
}

._2vSXiz9J6GlL {
	display: inline-block;

	color: var(--gray90);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
}

.OkNnfLPsbOJo {
	width: 100%;
	height: 50px;

	line-height: 50px;
	text-align: center;

	background: var(--gray);
}

._23EbCZyoFVYC {
	margin-bottom: 28px;
}

._1VSnJ-bTkAbw {
	color: var(--error);
	font-weight: 500;
}

button._3S3Vw_GWGR0t {
	padding: 0;
}

div._3S3Vw_GWGR0t {
	margin-right: 7px;
}

.wDTYAIXcjt_T {
	visibility: collapse;
	opacity: 0;

	transition: opacity 0.4s ease, visibility 0s 0.4s;
}

button._33H_XaG5BS66 {
	padding: 4px 0;
}
