

._1DVbsA9E6Ol5 {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	width: 35%;
	min-width: 35%;
}

._1quc7WqT7ilR {
	align-items: flex-start;
}

._1XWvZf5H3B09 {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	max-width: 100%;
}

._1quc7WqT7ilR ._1XWvZf5H3B09 {
	flex-direction: row-reverse;
}

._1125WBNEifmI {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	max-width: 100px;
	overflow: hidden;

	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: right;
}

._1quc7WqT7ilR ._1125WBNEifmI {
	text-align: left;
}

._1G6cYnyR7qm5 {
	width: 100%;
	margin-bottom: 6px;

	color: var(--gray70);
	font-weight: 500;
	font-size: 13px;
	text-overflow: ellipsis;
	overflow: hidden;
}

._1FHuoOfcziMB {
	width: 100%;

	color: var(--gray70);
	font-size: 12px;
	text-overflow: ellipsis;
	overflow: visible;
}

._2CYWewUrhe9W ._1G6cYnyR7qm5,
._2CYWewUrhe9W ._1FHuoOfcziMB {
	color: var(--gray50);
}

._2LwD6R-k07gY {
	margin-left: 10px;

	font-size: 44px;
	font-weight: 600;
	color: var(--gray90);
}

._2CYWewUrhe9W ._2LwD6R-k07gY {
	color: var(--gray30);
}

._1quc7WqT7ilR ._2LwD6R-k07gY {
	margin-right: 10px;
	margin-left: 0;
}

._1xLKYtffUeIQ {
	display: flex;
	flex-direction: column;
}

._2DT-1EQ7m48E {
	margin-bottom: 3px;

	color: var(--base-30);
	font-weight: 600;
	font-size: 56px;
}

._2CYWewUrhe9W ._2DT-1EQ7m48E {
	color: var(--gray30);
}

._2GSlF8ImjCYS {
	font-size: 16px;
	color: var(--base-15);
	text-align: right;
	text-transform: capitalize;
}

._1quc7WqT7ilR ._2GSlF8ImjCYS {
	text-align: left;
}
