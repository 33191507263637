

._34zwOrjPMhTZ {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 18px 20px;

	background: var(--common-blue);
}

._18DpZDaK4RWS {
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
}

._3vs4yV73P_zz {
	height: 24px;
	width: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBvcGFjaXR5PSIwLjMiIGN4PSIxMiIgY3k9IjEyIiByPSIxMiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTE2LjU5IDguNTg5ODRMMTIgMTMuMTY5OEw3LjQxIDguNTg5ODRMNiA5Ljk5OTg0TDEyIDE1Ljk5OThMMTggOS45OTk4NEwxNi41OSA4LjU4OTg0WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==) center no-repeat;

	transition: all 0.15s;
}

._3zmUaHF18u2O {
	transform: rotate(-180deg);
}

.RlsSVQNXYKvN {
	padding: 28px 0;

	border-top: 1px solid rgba(255, 255, 255, 0.2);

	background: var(--common-blue);
}

._1CBccUVjvE4- {
	padding: 0 20px;
	margin-bottom: 28px;
	display: flex;
	align-items: center;
}

._2rq7CTIHEcf5 {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 36px;
	width: 36px;
	margin-right: 12px;

	border-radius: 50%;

	font-size: 17px;
	line-height: 20px;
	color: var(--common-blue);
	font-weight: 600;

	background-color: #ffffff;
}

._26N1iMgDyiIa {
	display: flex;
	flex-direction: column;

	color: #ffffff;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

._1-YATw7eakoQ {
	font-size: 13px;
	font-weight: 400;

	opacity: 0.7;
}

.RlsSVQNXYKvN ._1Ey-Zh4xz-h- {
	padding: 11px 24px;
}

.RlsSVQNXYKvN div._1ac7c6WIhfhv {
	background-color: rgba(0, 0, 0, 0.08);
}

._1Ey-Zh4xz-h- span {
	color: #ffffff;
	font-size: 15px;
	line-height: 22px;
	font-weight: 500;
	font-family: inherit;
}

.RlsSVQNXYKvN ._1Ey-Zh4xz-h- .s3JSu0YhZ6jq > svg {
	width: 20px;
	height: 20px;
}

._1Ey-Zh4xz-h- .s3JSu0YhZ6jq {
	margin-right: 0;
	min-width: 50px;

	font-size: 20px;
	color: #ffffff;

	opacity: 0.7;
}

._1Ey-Zh4xz-h- ._21P5-7T-LKMn {
	padding: 0 24px 0 0;
	overflow: hidden;

	text-overflow: ellipsis;
	color: #ffffff;
}
