

._3I3yVmeKEMa7 {
	display: flex;
	align-items: center;
}

._3BKZZmecJiMr {
	flex-direction: column;
	align-items: flex-end;
}

._1BLQeJ6NtIXd {
	color: var(--active);
}

.sgFTzQZXJU4g {
	color: var(--base);
}

._238cm7YLFVAF {
	position: relative;

	display: flex;
	align-items: center;
	min-width: auto;
	justify-content: center;
	flex: 1;

	border-left: 1px dashed var(--gray20);

	color: var(--base-30);
	font-size: 14px;
}

._16j4a1dmSkm1 {
	font-weight: 500;
	font-size: 18px;
	color: var(--active);
	text-align: right;
	text-decoration: line-through;
	line-height: 18px;
}

._1UN2gl07fiq6 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	width: 100%;
	padding: 20px 16px;
}

._2rbfo70Sy53x ._1UN2gl07fiq6 {
	flex-direction: column;
	align-items: center;
	justify-content: center;

	color: var(--gray50);
	font-size: 15px;
}

._2aUrqhpC-0Zu {
	margin-left: 12px;

	font-weight: 600;
	font-size: 26px;
}

.ii1rn2XojzdI {
	color: var(--base-30);
}

._2rbfo70Sy53x ._2aUrqhpC-0Zu {
	margin: 12px 0;

	font-size: 40px;
}

._1DubolJIETPW ._2nxmgQNlSF9Z {
	padding-left: 6px;

	font-weight: 400;
	font-size: 14px;
}

._3ZsZC5OZwvKI {
	margin-top: 4px;

	color: var(--gray50);
	font-size: 14px;
}

._35IQmxL68_nb {
	position: absolute;
	top: 0;
	bottom: 0;
	left: -14px;

	width: 28px;
	height: 28px;
	margin: auto;

	color: var(--active);
}

button._2mYvV8d4nV2_ {
	border: none;

	color: var(--base-30);

	background: rgba(50, 144, 255, 0.15);
}

._1IKMjyenCVSG {
	margin-top: 8px;

	color: var(--error);
	font-size: 14px;
}
