._1K0KTO8h2JJc {
	display: flex;
	flex-direction: column;
	padding: 0;
}

._3QeOXdwWbf_m,
._3wmyPrlzO7Xv {
	padding: 0 50px;
}

._3QeOXdwWbf_m {
	font-size: 18px;
	line-height: 24px;
	color: var(--common-gray);
}

._3wmyPrlzO7Xv {
	display: flex;
	align-items: flex-end;
	margin-top: 20px;
}

._3xKfHzoFzySB {
	display: flex;
	align-items: center;
}

._3wmyPrlzO7Xv ._3F8FRBPrQ5OM {
	width: 250px;
}

._3wmyPrlzO7Xv label._2xl8OVQUf7hv {
	color: #f44336;
}

._3wmyPrlzO7Xv ._28sO7TX41V2a {
	position: relative;

	margin-left: 20px;

	border-radius: 4px;
}

._2itOhwmFYgap {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	margin-top: 30px;
	padding: 0 20px;

	background-color: #f9f9f9;
}

._2OsbvSL_p6am {
	margin-top: -20px;
	margin-left: -20px;

	height: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
}

._2rGr2e2Az7PA {
	position: relative;
	top: -10px;

	width: 40px;
	height: 40px;
	margin-left: 20px;

	border-radius: 50%;

	background-color: var(--common-blue);
}

.kUfIpfLZBY8B {
	background-color: var(--common-green);
}

.WIlmV3J5XCoP {
	width: 40px;
	height: 40px;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKKSURBVHgBtZZLaxNRGIa/ialak9pag9p6KwUrgqI7K7pQoeBC0IULXQguBUE3/gD/gVtdCC5diRdciAtRXChSWy/tLohSJQqi0dJom2R83pwTiZGYTDvzwsMJme9877nNnC+wDhSG4SaaERiCflgFy2AePkMBpoMgyLfLFbQx2kEzCn2QhpQ3Svu2+fc3uI/xs0iGGGVpjsB6b1KGlzADRRIWfdxKmkHYCbsh5wfwFS4T96WtIUm2+Vl1wxw8pOMH60D03UtzFNbBT7hO34mWhnTYTHPY3PJMwwQdflkEkUMDPQZj5lbnGjme1J+nGgIzNPugCi8IehrVrDaDICjBDX7e9flPkzv3jyE6ZH5mdJi0JYoct2geQA9c/MuQEWyl0QH4Aa8sPt0EHZxhPA7+MUTbzS3la0a2YDGJXDp0V8yt3KmaIc5rzc+OgHcWs8g5Ze7EbsBrl2aod60CHR39ReqeuVmOyjDjDQuWnN6Y2749jYbfLTl9gi4YkKFe/jJrXbaERG4ZyqtX3z2dztCSl7xqrjpBFU7QCktI/jLQoSnJsGTuNlhuyWnEG+ZlqKtEy9pjyUmfzXSjoU5pzpJT/QZ6lOIEzZq790J/Y8Qqch6nUYlSwGu8/i3VS69lHSAgZTGJXNqmc+Zmd1X/1ZLjrFpkzj/ot/h0HraYm93t5tF0wbCnz5YoclyAPIzDxlZBWRhSqQGrIYjoY77fJXgPb2GsXYduGATtZy7KB4HY/fAcPsIUnGiOaVUmai/X+Od6ZXQpq+hdYC+qDXGqFLRHB+AsZH28qoYzxM5YFGl20OuXKaM61O91AFWYhxLMQhEm4eT/cna0R37GIvRUPKpXVN4/hjuN5WAr/QakWztb10DkIQAAAABJRU5ErkJggg==) center no-repeat;

	animation: ALouMaV6yYqU 0.9s infinite linear;

	pointer-events: none;
}

._3F0AcAC4lcxG {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
}

@media screen and (max-width: 600px) {
	._2rGr2e2Az7PA {
		margin-left: 0;
	}

	._3QeOXdwWbf_m {
		padding: 0;

		line-height: 28px;
	}

	._3wmyPrlzO7Xv {
		overflow: hidden;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0;
	}

	._3wmyPrlzO7Xv ._3xKfHzoFzySB {
		display: flex;
		align-items: center;
		width: 100%;
	}

	._2OsbvSL_p6am {
		margin-top: -10px;
		margin-left: -25px;
	}

	._3wmyPrlzO7Xv ._28sO7TX41V2a {
		top: -18px;

		width: 100%;
		margin-top: 32px;
		margin-left: 0;
	}

	._3wmyPrlzO7Xv ._3F8FRBPrQ5OM {
		width: 100%;

		font-size: 17px;
	}

	._2itOhwmFYgap {
		height: auto;
		margin: 0;
		padding: 0;
		justify-content: flex-start;

		background-color: #ffffff;
	}
}

@keyframes ALouMaV6yYqU {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
