._3uX9CjlFIjNC {
	position: relative;

	margin-top: 16px;

	border-bottom: 1px solid #d9d9d9;
}

._3uX9CjlFIjNC._1BthTHdmS196 {
	border-bottom: none;
}

._3KPjbNnrwR9g {
	justify-content: center;
}

._2zn3w1EmQ16W {
	justify-content: right;
}

span._34OjJStA-GQB {
	height: 3px;

	background-color: var(--selected-tab-color);
}

._3MEYq6sJZfL_ span._34OjJStA-GQB {
	display: none;
}

button._3MEYq6sJZfL_ {
	min-width: auto;
	margin: 0 16px;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;

	font-family: inherit;
	font-size: 13px;
	font-weight: 500;
	text-transform: none;
	color: #9a9a9a;
}

button._3MEYq6sJZfL_._3zl-5DD37LNG {
	height: 44px;
	padding: 0 16px;

	border-radius: 100px;

	color: var(--gray70);
	font-size: 17px;

	font-family: 'Roboto', sans-serif;
	line-height: 44px;
	letter-spacing: normal;

	background: var(--gray05);
}

button._3jvuXUz8fHBG {
	color: var(--selected-tab-color);
}

button._3zl-5DD37LNG._3jvuXUz8fHBG {
	color: #ffffff;

	background: var(--base);
}

button._2IrkpfOavBMx[disabled] {
	margin-bottom: -22px;
}

.IuK80ApdUDES {
	max-width: 100%;
	overflow: hidden;
}

.LVFCtCgZqGvF {
	max-width: 100%;
	overflow: hidden;

	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 15px;
	font-weight: var(--tabs-route-font-weight);
	text-transform: uppercase;
	line-height: 21px;
	color: var(--common-gray);
}

._3zl-5DD37LNG .LVFCtCgZqGvF {
	color: var(--gray70);
	font-weight: 500;
	font-size: 17px;
	line-height: 44px;
	text-transform: none;
}

._3jvuXUz8fHBG .LVFCtCgZqGvF {
	color: var(--selected-tab-color);
}

._3zl-5DD37LNG._3jvuXUz8fHBG .LVFCtCgZqGvF {
	color: #ffffff;
}

._2bR4RpfYpSUh {
	margin-bottom: 12px;

	font-size: 14px;
}

span._3fHNVGS4qN9t:not(:last-child):after {
	content: ',';
}

@media (max-width: 1024px) {
	._2czgZeSDdAQm::-webkit-scrollbar {
		display: none;
		width: 0;
	}
}
