

.lauxFj9ePBl_ {
	display: flex;
	flex-direction: column;
	padding-bottom: 65px;
	overflow-y: auto;
}

._2Sc0ityaDHq- {
	padding: 20px;

	border-bottom: 1px solid #e4e4e4;
}

._2Sc0ityaDHq- a {
	display: flex;
	align-items: center;

	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
	text-decoration: none;
}

._2Sc0ityaDHq- a > svg {
	height: 20px;
	width: 20px;
	margin-left: 4px;
}

._2yJ2iKJzaVyM {
	padding: 27px 20px 20px;
}

._2yJ2iKJzaVyM:not(:last-child) {
	border-bottom: 1px solid #e4e4e4;
}

.KYnGkSdN0xyt {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
}

._22Obn-dAuuco {
	font-weight: 600;
	font-size: 17px;
	line-height: 25px;
	color: #292929;
}

.KYnGkSdN0xyt ._3RThJfGzPTAw {
	display: flex;
	align-items: center;
	padding: 0 8px 0 16px;

	border-radius: 24px;

	color: #ffffff;
	font-weight: 500;
	line-height: unset;

	background: var(--common-blue);
}

.KYnGkSdN0xyt ._3RThJfGzPTAw:hover {
	background: var(--common-blue);
}

._3RThJfGzPTAw._1ZshB2eV9pme {
	color: #292929;

	background: #f0f0f0;
}

.KYnGkSdN0xyt ._2ys_FyRJgmH3 {
	padding: unset;
}

._3RThJfGzPTAw._1ZshB2eV9pme:hover {
	background: #f0f0f0;
}

._2HsMkEVY2g7f {
	display: flex;
	flex-direction: column;
}

._2HsMkEVY2g7f > div {
	font-family: inherit;
}

._2HsMkEVY2g7f > div svg {
	margin-right: 8px;
}

._2HsMkEVY2g7f > div:not(:last-child) {
	margin-bottom: 12px;
}

._2pNt1WyA3awS {
	margin-bottom: 12px;
}

div._3SwBjfUwWC-- {
	text-transform: capitalize;
}

div._2pNt1WyA3awS,
div._3SwBjfUwWC-- {
	padding: 12px 30px 12px 12px;

	border-radius: 4px;

	background: #f0f0f0;
}

._1oFmlozyoVc3 {
	display: inline-block;

	height: 24px;
	margin-left: 8px;
}

._1oFmlozyoVc3._1cOqfp7R7dLh {
	transform: rotate(45deg);

	fill: #9a9a9a;
}

._1oFmlozyoVc3.GcEVED2tRGPN {
	fill: #ffffff;
}

._1oFmlozyoVc3._3OdoWdjRIIZ3 {
	transform: rotate(90deg) scale(0.7) translate(0, 1px);
}

.Ewe8d0UYT50n {
	position: fixed;
	bottom: 0;

	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 11px 20px;
	height: 48px;

	background: var(--common-orange);
}

span._3lWGAQB-Ya0t,
span._253LQfqoRQE3 {
	color: #ffffff;
	font-size: 17px;
	line-height: 25px;
	font-weight: 600;

	background-color: transparent;

	cursor: pointer;
}

.Ewe8d0UYT50n span._3lWGAQB-Ya0t {
	font-weight: 400;
}
