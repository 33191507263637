._3VQxwHSYEFp {
	margin: 0;
	padding: 0;

	list-style-type: none;
}

button.fXydX3dF5WeX {
	position: relative;

	z-index: 1;

	flex: 1;

	box-sizing: content-box;
	min-width: 29px;
	max-width: 72px;
	height: 44px;

	margin-right: 8px;

	border-right: 2px;

	border-radius: 4px;

	color: #ffffff;

	background: var(--seat-standart);
}

/* unavailable */
button.fXydX3dF5WeX._1yHseIlHgYSg {
	background: var(--seat-busy);

	pointer-events: none;
}

.fXydX3dF5WeX._3bBo4Ve7PBnj {
	max-width: 48px;

	opacity: 0;

	pointer-events: none;
}

/* regular */
.fXydX3dF5WeX {
	background: var(--seat-standart);
}

.fXydX3dF5WeX._3bZpYfmCIytd {
	z-index: 1002;

	background: var(--selected);
}

.fXydX3dF5WeX._3bZpYfmCIytd._2iaaKngg7wms {
	z-index: 1002;

	background: var(--selected2);
}

/* business */
button.t4gS0fdbv0YJ {
	background: var(--seat-business);
}

button._2MIkTP2hhylo {
	background: var(--seat-extra);
}

button.fXydX3dF5WeX.FjVc3Tt4lnCR,
button._2MIkTP2hhylo.FjVc3Tt4lnCR,
button._2MIkTP2hhylo.FjVc3Tt4lnCR._1yHseIlHgYSg,
button.t4gS0fdbv0YJ.FjVc3Tt4lnCR,
button.t4gS0fdbv0YJ.FjVc3Tt4lnCR._1yHseIlHgYSg {
	background: var(--seat-occupaied);
}

.fXydX3dF5WeX:hover,
.t4gS0fdbv0YJ:hover {
	background: var(--seat-hover);
}

.fXydX3dF5WeX.FjVc3Tt4lnCR:hover {
	background: var(--seat-occupaied-hover);
}

.uJWT7PMK82bl.D4LIySKEhEnD {
	background-color: #ffffff;
}

.fXydX3dF5WeX:last-child {
	margin-right: 0;
}

._3k3RS5Ryl-wi {
	position: absolute;
	left: 50%;

	font-weight: 500;
	font-size: 14px;
	line-height: 14px;

	transform: translate(-50%, 0);

	transition: opacity 0.15s;
}

.fXydX3dF5WeX ._3k3RS5Ryl-wi {
	color: var(--gray70);
}

._2L7zVg7z4sFE ._3k3RS5Ryl-wi {
	display: none;
}

.FjVc3Tt4lnCR ._3k3RS5Ryl-wi {
	color: #ffffff;
	font-size: 22px;
	font-weight: 700;
}

.FjVc3Tt4lnCR ._3k3RS5Ryl-wi._3X_QHU2iHbUY {
	font-size: 14px;
	font-weight: 500;
}

.fXydX3dF5WeX._3bZpYfmCIytd ._3k3RS5Ryl-wi {
	color: #ffffff;
}

.FjVc3Tt4lnCR.fXydX3dF5WeX:hover ._3k3RS5Ryl-wi {
	opacity: 0;
}

.fXydX3dF5WeX:not(._3bBo4Ve7PBnj):not(._1yHseIlHgYSg):not(.FjVc3Tt4lnCR):hover ._3k3RS5Ryl-wi {
	color: #ffffff;
}

.FjVc3Tt4lnCR.fXydX3dF5WeX:not(._3vzbSnWFpcJt):hover:after {
	position: absolute;
	top: -7px;
	right: 0;
	bottom: 0;
	left: 0;

	width: 16px;
	height: 16px;

	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjY2NjMgNC43NzMyNUwxMS43MjYzIDMuODMzMjVMNy45OTk2NyA3LjU1OTkyTDQuMjczMDEgMy44MzMyNUwzLjMzMzAxIDQuNzczMjVMNy4wNTk2NyA4LjQ5OTkyTDMuMzMzMDEgMTIuMjI2Nkw0LjI3MzAxIDEzLjE2NjZMNy45OTk2NyA5LjQzOTkyTDExLjcyNjMgMTMuMTY2NkwxMi42NjYzIDEyLjIyNjZMOC45Mzk2NyA4LjQ5OTkyTDEyLjY2NjMgNC43NzMyNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

._2L7zVg7z4sFE.FjVc3Tt4lnCR.fXydX3dF5WeX:hover:after {
	top: 0;
	left: -1px;
}

._1yvF0TAUo0UR.fXydX3dF5WeX._1yHseIlHgYSg:not(._3bBo4Ve7PBnj):after,
._3TSzaq3gZlk9.fXydX3dF5WeX._1yHseIlHgYSg:not(._3bBo4Ve7PBnj):after {
	position: absolute;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjk5OTcgMTkuMzU3MUMxNS43ODk5IDE5LjM1NzEgMTguODU2OSAxNi4yODY4IDE4Ljg1NjkgMTIuNUMxOC44NTY5IDguNzEzMTYgMTUuNzg5OSA1LjY0MjgyIDExLjk5OTcgNS42NDI4MkM4LjIxMjg2IDUuNjQyODIgNS4xNDI1OCA4LjcxMzE2IDUuMTQyNTggMTIuNUM1LjE0MjU4IDE2LjI4NjggOC4yMTI5MiAxOS4zNTcxIDExLjk5OTcgMTkuMzU3MVpNMTAuMjg1NCA5LjA3MTM5QzExLjI3MTUgMTAuMDU5OSAxMS45OTk3IDEyLjUgMTcuMTQyNiAxMi41QzE3LjE0MjYgMTUuMzM1OSAxNC44MzU3IDE3LjY0MjggMTEuOTk5NyAxNy42NDI4QzkuMTYzODEgMTcuNjQyOCA2Ljg1Njg2IDE1LjMzNTkgNi44NTY4NiAxMi41QzYuODU2ODYgMTIuNSA4Ljg1MjQgMTEuMjkyOSAxMC4yODU0IDkuMDcxMzlaIiBmaWxsPSIjQTVBNUE1Ii8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

._3TSzaq3gZlk9.fXydX3dF5WeX._1yHseIlHgYSg:not(._3bBo4Ve7PBnj):after {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuODU2NzYgMTkuMzU3MUgxNy4xNDI3QzE4LjA5MDEgMTkuMzU3MSAxOC44NTY3IDE4LjU4OTUgMTguODU2NyAxNy42NDI5VjEyLjVDMTguODU2NyA4LjcxMzIgMTUuNzg2NyA1LjY0MjgyIDExLjk5OTcgNS42NDI4MkM4LjIxMjg0IDUuNjQyODIgNS4xNDI1OCA4LjcxMzIgNS4xNDI1OCAxMi41VjE3LjY0MjlDNS4xNDI1OCAxOC41ODk1IDUuOTA5MjggMTkuMzU3MSA2Ljg1Njc2IDE5LjM1NzFaTTExLjk5OTcgNy4zNTcxOEMxMS45OTk3IDcuMzU3MTggMTEuOTk5NyAxMi41NTM2IDE3LjE0MjYgMTIuNTAwMUMxNy4xNDI2IDE1LjM0MDIgMTQuODM5MiAxNy42NDMgMTEuOTk5NyAxNy42NDNDOS4xNjAzMiAxNy42NDMgNi44NTY3NiAxNS4zNDAyIDYuODU2NzYgMTIuNTAwMUM2Ljg1Njc2IDEyLjUgMTEuOTk5NyAxMi41NTM2IDExLjk5OTcgNy4zNTcxOFoiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==);
}

/* wide seat map */
button._2L7zVg7z4sFE {
	width: 25px;
	max-width: 25px;
	min-width: 25px;
	height: 25px;
	margin: 2px;
	padding: 0;

	border-radius: 50%;

	background: var(--seat-standart) none;
}

button.fXydX3dF5WeX._2L7zVg7z4sFE._1yHseIlHgYSg {
	border: 1px solid #dadada;

	background: #ffffff;
}

.FjVc3Tt4lnCR._2L7zVg7z4sFE,
button.FjVc3Tt4lnCR._2L7zVg7z4sFE._1yHseIlHgYSg,
.fXydX3dF5WeX._2L7zVg7z4sFE:hover {
	background: var(--seat-occupaied) none;
}

._2L7zVg7z4sFE._1yHseIlHgYSg._2MIkTP2hhylo,
._2L7zVg7z4sFE._1yHseIlHgYSg.t4gS0fdbv0YJ,
._2L7zVg7z4sFE._1yHseIlHgYSg {
	background: #dadada;
}

._2L7zVg7z4sFE.t4gS0fdbv0YJ {
	height: 30px;
	padding: 0;

	border-radius: 0 0 50% 50%;

	background: var(--seat-business);
}

._2L7zVg7z4sFE._2MIkTP2hhylo {
	background: var(--seat-extra);
}

.FjVc3Tt4lnCR._2L7zVg7z4sFE:after {
	position: absolute;
	top: -1px;
	right: 0;
	bottom: 0;
	left: 1px;

	width: 16px;
	height: 16px;

	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAyMSAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNDgxNzcgMTIuODAyMUwxLjYzMjg5IDcuOTUzMjJMMC4wMTY2MDE2IDkuNTY5NTFMNi40ODE3NyAxNi4wMzQ3TDIwLjMzNTcgMi4xODA3NUwxOC43MTk0IDAuNTY0NDUzTDYuNDgxNzcgMTIuODAyMVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
	background-size: contain;
	content: '';
}

._2L7zVg7z4sFE.t4gS0fdbv0YJ.FjVc3Tt4lnCR:after {
	top: 0;
}

@media (min-width: 1024px) {
	button.fXydX3dF5WeX._2Xwfj23FoalK:not(._2L7zVg7z4sFE) {
		max-width: 48px;
	}
}

@media only screen and (max-width: 420px) {
	._2L7zVg7z4sFE {
		margin: 1px;

		width: 20px;
		height: 20px;
	}

	._2L7zVg7z4sFE.t4gS0fdbv0YJ {
		height: 25px;
	}
}
