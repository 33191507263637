

div._22BWyFeI76NZ {
	background: transparent;
}

._2Uxj3SeYn0y- ._16pmVs_xjzCS:not(:last-of-type) {
	padding-bottom: 20px;
	margin-bottom: 20px;

	border-bottom: 1px solid var(--gray20);
}

p.nFDT8gXQimO5 {
	margin-top: 20px;

	font-size: 13px;
	line-height: 17px;
	color: var(--gray50);
	text-align: center;
}

p._3rIKDn8a8f5F {
	color: var(--error);
	font-weight: 500;
}
