._20Ur77I2IM4U {
	line-height: 20px;
	font-size: 16px;
	font-weight: 500;

	list-style-type: none;
}

._20Ur77I2IM4U:not(:last-of-type) {
	margin-bottom: 12px;
}

@media screen and (max-width: 600px) {
	._20Ur77I2IM4U {
		display: flex;
		justify-content: space-between;

		line-height: 150%;
		font-weight: 400;

		color: #292929;
	}

	._20Ur77I2IM4U > span:last-child {
		font-weight: 500;
	}
}
