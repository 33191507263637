

._3RDEW2gKviZi {
	display: flex;
	align-items: center;

	font-weight: 700;
	font-size: 24px;
	line-height: 100%;
}

._3MPVqD1txsWy {
	color: var(--gray80);
}

._1lWh5dXFyKPU {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	margin-right: 16px;

	border-radius: 50%;

	color: #ffffff;

	background: var(--base10);
}
