.v3xFXeGU6-Ti {
	padding: 28px;
}

._3iQgEFSciMWa {
	margin: 0 0 16px;

	color: var(--dark-gray);
	font-size: 22px;
	line-height: 31px;
}

.vGKVWW8adgvC {
	display: flex;
}

._20oEt5GdNwdC {
	padding: 19px 0;
	display: flex;
	align-items: center;
}

._20oEt5GdNwdC:not(:last-of-type) {
	border-top: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
}

._20oEt5GdNwdC:last-of-type {
	border-bottom: 1px solid #e4e4e4;
}

._1mDjECi2AlB7 {
	line-height: 19px;
	font-weight: 600;
	color: #292929;
}

.Yk1tQlduKFOZ {
	margin-left: 10px;

	color: #727272;
	font-size: 15px;
	line-height: 18px;
}

@media screen and (max-width: 600px) {
	.v3xFXeGU6-Ti {
		padding: 0;
	}

	.Yk1tQlduKFOZ {
		margin-left: 0;
	}

	.vGKVWW8adgvC {
		flex-direction: column;
	}
}
