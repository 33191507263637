._1AFAcqkew7hR {
	padding: 40px 34px;
}

._3X-nGMYlRMPE {
	margin-bottom: 12px;

	color: var(--gray80);
	font-weight: 700;
	font-size: 21px;
	line-height: 140%;
}

._1CvR6qzIlVHO {
	margin-bottom: 20px;

	color: var(--gray50);
	font-size: 15px;
	line-height: 150%;
}

._1CvR6qzIlVHO > span:not(:last-child):after {
	content: ', ';
}

div._1NDp_ZMTy5ZJ {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 68px;
	margin: 0;

	border-top: 1px dashed var(--gray20);
}

._5jsULbiK2Vv6 {
	margin-top: 29px;

	opacity: 1;
}

div._1NDp_ZMTy5ZJ:after,
div._5jsULbiK2Vv6:after {
	background: var(--gray20);
}

div._1NDp_ZMTy5ZJ:before,
div._5jsULbiK2Vv6:before {
	background: #ffffff;
	opacity: 1;
}

div._1NDp_ZMTy5ZJ._1Kh2rTc-Xedv:after,
div._5jsULbiK2Vv6._1Kh2rTc-Xedv:after {
	background: var(--base);
}

div._1NDp_ZMTy5ZJ:last-child {
	border-bottom: 1px dashed var(--gray20);
}

div.CYzD1pQIkk62 {
	opacity: 1;
}

._2bg7-5yrqqEl {
	display: flex;
	align-items: center;

	font-size: 15px;
	color: var(--gray80);
	font-weight: 500;
	text-transform: capitalize;
}

._1b2jRAxps5I5 {
	width: 24px;
	height: 24px;
	margin-right: 14px;

	border-radius: 50%;

	color: var(--gray50);
	line-height: 24px;
	text-align: center;

	background: var(--gray05);
}

._2-4wqebWZRkT {
	height: 52px;
	padding: 0 10px;

	color: var(--gray80);
	font-weight: 700;
	font-size: 16px;
	line-height: 52px;

	background: #ebf4ff;
}

._2EDaUyFvR_M4 {
	margin-top: 29px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

._380ESk3WUhYZ {
	font-weight: 500;
	color: var(--base);
	font-size: 15px;

	opacity: 1;
}

button._1NUOzqhhpETV,
button._1NUOzqhhpETV:hover,
button._1NUOzqhhpETV:focus {
	margin-right: 12px;

	color: var(--gray70);

	background: var(--gray05);
}

button._3rt7KPIe7t-V,
button._3rt7KPIe7t-V:hover,
button._3rt7KPIe7t-V:focus {
	background: var(--base);
}

@media screen and (max-width: 600px) {
	._1AFAcqkew7hR {
		padding: 0;
	}

	._2EDaUyFvR_M4 {
		justify-content: space-between;
	}

	button._1NUOzqhhpETV,
	button._3rt7KPIe7t-V {
		width: 49%;
	}
}
