

._3Y9K0ytLxXAO {
	position: relative;

	display: flex;
	flex-direction: column;
	width: 114px;
}

._1eYnnA4HFCMp {
	position: absolute;
	bottom: 100%;

	flex-direction: row;
	width: auto;
	padding-left: 112px;

	border: 1px solid #d9d9d9;
}

.V7qd6TvT28jc {
	flex-direction: column;
}

._15h6x1nJUxyw {
	display: flex;
}

.V7qd6TvT28jc ._15h6x1nJUxyw {
	flex-direction: column;
}

._2FCgSL0ruVP9 {
	width: 114px;
	height: 32px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 13px;
	line-height: 32px;
	text-align: center;

	background: #f8f8f8;
}

._1eYnnA4HFCMp ._2FCgSL0ruVP9 {
	border: none;
	border-left: 1px solid #d9d9d9;
}

.V7qd6TvT28jc ._2FCgSL0ruVP9 {
	border: 1px solid #d9d9d9;
	border-top: none;
}

.Cranlb2OeVzS {
	padding-left: 6px;

	color: var(--light-gray);
	font-weight: 500;
}

._3fD00E6SJLYM {
	position: absolute;

	display: flex;
	align-items: center;
}

._1eYnnA4HFCMp ._3fD00E6SJLYM {
	bottom: 100%;

	margin-bottom: 9px;
}

.V7qd6TvT28jc ._3fD00E6SJLYM {
	top: 50px;
	left: -73px;

	flex-direction: row-reverse;

	transform: rotate(-90deg);
}

._1i1cI9_GOJPI {
	padding: 0 12px;

	font-size: 14px;
	font-weight: 500;
}

.V7qd6TvT28jc ._1i1cI9_GOJPI {
	padding-right: 0;
}

._1eYnnA4HFCMp ._1i1cI9_GOJPI {
	padding-left: 0;
}

.sxShtzBLyq62 {
	display: flex;
}

._3-t0PpLCT_Pf,
._3BqC4f8tw9cE {
	width: 8px;
	height: 12px;
	display: block;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjQxIDBMMCAxLjQxTDQuNTggNkwwIDEwLjU5TDEuNDEgMTJMNy40MSA2TDEuNDEgMFoiIGZpbGw9IiNDRENEQ0QiLz4KPC9zdmc+Cg==);
	cursor: pointer;
}

._3BqC4f8tw9cE {
	transform: rotate(-180deg);
}

._3-t0PpLCT_Pf {
	margin-left: 18px;
}
