

._3SJH_upJ1elZ {
	width: 410px;
	max-width: 410px;
	margin: 50px auto;
	padding: 40px 60px;
	box-sizing: border-box;

	border-radius: 16px;

	background: #ffffff;
}

.j9DyPTAcF8PR {
	width: 100%;

	text-align: center;
}

._3M9ULkm-PHHq {
	margin-bottom: 20px;
}

.C3_vn2Kk51rp {
	margin-bottom: 12px;

	color: var(--gray80);
	font-weight: 500;
	font-size: 30px;
}

._3HHNQhYgRTDe {
	font-size: 16px;
	color: var(--gray50);
}

form._2KDwuuaFRKB_ {
	margin-top: 20px;
	width: 100%;
}

form._2KDwuuaFRKB_ button,
form._2KDwuuaFRKB_ button:hover {
	width: 100%;
	height: 48px;

	border-radius: 4px;

	line-height: 48px;

	background: var(--active);
}

._2IXtJUulUk18 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 48px;
	margin-top: 20px;

	text-align: center;
}

._20kc8Mf4L605 {
	margin-top: 12px;

	font-size: 13px;
}

.icQL57dTJmvt {
	justify-content: center;

	color: var(--base);
	font-size: 16px;
	text-align: center;
}

.icQL57dTJmvt a {
	color: var(--base);
}

._3bsVJHIsiQtX div[aria-hidden='true'] {
	visibility: hidden;
}

._2Mm7G0CXcI8M {
	padding: 0 1px;
}

._1hJfK9ZJA_QN {
	display: flex;
	justify-content: center;
	margin-top: 12px;
}
