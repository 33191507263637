

div.XNrxLZ_pbMCx {
	width: 34px;
	height: 34px;

	background: var(--base);
}

div.XNrxLZ_pbMCx div {
	width: 28px;
	height: 28px;
	margin: 3px;

	background-size: 20px 20px;
}

._1AzjW38YyrxO svg {
	color: var(--gray15);
}

._22qPVSS3mao0 {
	display: flex;
	align-items: center;
	width: 100%;
	height: 96px;
	padding: 0 20px;
	justify-content: space-between;

	background: var(--white);
}

._2i9tk2eqS55r {
	display: flex;
}

._19n9V270h6jf {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;

	border-radius: 100px;

	background: var(--queues-ownerIcon-background);
}

._19n9V270h6jf svg path {
	color: var(--gray30);
}

._2CqUCWbuOjrN {
	margin-left: 8px;

	color: var(--text-base);
	font-weight: 500;
	line-height: 32px;
}

button.ur3um_pCwHjc {
	min-width: unset;
	height: 34px;
	margin-left: 8px;

	border: 0;

	background: var(--base90);
}

._1n2TJH6KnuZc {
	width: 1px;
	height: 24px;
	margin: 0 20px;

	background: var(--gray30);
}

button.XNrxLZ_pbMCx {
	width: 34px;
	min-width: 0;
	height: 34px;
	padding: 0;

	border: 0;

	border-radius: 100px;

	background: var(--base90);
}

button.XNrxLZ_pbMCx svg {
	color: var(--base);

	transform: scale(0.8);
}

._1AzjW38YyrxO {
	margin: 0 12px;
}

.ELTrFyvWUXYq input,
.ELTrFyvWUXYq {
	height: 48px;

	border: none;

	font-size: 18px;

	background-color: var(--base90);
}

div._2ieAJUTAk1Xz {
	flex-grow: 1;
	margin-left: 21px;
	width: auto;
}

.ELTrFyvWUXYq input {
	padding: 0 9px;
}

._2gT8foCQ6uTB {
	box-sizing: content-box;

	outline: 1px solid var(--base);
}

._2ieAJUTAk1Xz fieldset {
	display: none;
}

._2S-MP_Z95OED {
	display: flex;
	min-width: 355px;
	align-items: center;
}
