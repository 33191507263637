

._18Nn5lyG-5TT {
	max-height: 100%;
	padding-top: 56px;

	box-shadow: none;
	transform: none;
}

._2I1uvAobkcfm {
	position: fixed;
	top: 0;

	display: flex;
	align-items: center;
	width: 100%;
	height: 56px;

	border-bottom: 1px solid #d9d9d9;

	background-color: #ededed;

	transition: background-color 0.2s;
}

._2NeTnjXX7TY2 {
	background-color: #ffffff;
}

._3cXJ_IfiqM-3 {
	height: 100%;
	width: 100%;

	overflow-y: auto;
	padding-top: 8px;
}

._3KTl65qXtVh6 {
	padding-left: 20px;

	border: none;

	font-weight: 600;
	font-size: 24px;

	line-height: 34px;

	background: none;

	-webkit-appearance: none;

	        appearance: none;
}

._3KTl65qXtVh6:-ms-input-placeholder {
	color: #9a9a9a;
}

._3KTl65qXtVh6::placeholder {
	color: #9a9a9a;
}

._2TPhjTAvr1Ht {
	position: absolute;
	right: 52px;

	color: #9a9a9a;
	font-size: 12px;
	line-height: 56px;
	text-align: right;

	transition: opacity 0.2s;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
	pointer-events: none;
}

._2GcmiqhoHSdH {
	opacity: 0;
}

._1ZNPyH8xl_Nf {
	position: absolute;
	top: 50%;
	right: 12px;

	display: flex;

	transform: translateY(-50%);
}

/* stylelint-disable */
._3atGbYmMF2cW {
	z-index: 1501 !important; /* for .mui-fixed */

	padding-right: 0 !important; /* for .mui-fixes */
}

/* stylelint-enable */
