._2oBnEGzElupx {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

._1WDVk8rJ5E36 {
	padding-bottom: 16px;

	border-bottom: 1px dashed #a5a5a5;

	font-weight: 600;
	font-size: 19px;
}

._1WDVk8rJ5E36:not(:first-child) {
	margin-top: 20px;
}

._2FIrLbD48c8g {
	width: 100%;
	max-width: 1100px;
	margin: 15px 0;
}

@media (max-width: 1024px) {
	._2FIrLbD48c8g {
		max-width: 560px;
		margin: 0 auto;
	}
}

@media screen and (max-width: 600px) {
	._2oBnEGzElupx:not(:first-child) {
		margin-top: 16px;
	}

	._2FIrLbD48c8g {
		display: flex;
		justify-content: space-between;

		max-width: 374px;
		margin: 0 auto;
		padding: 12px 0;
	}

	._1WDVk8rJ5E36 {
		max-width: 374px;
		width: 100%;
		margin: 0 auto;
	}
}
