button._28I0H_MFSijC {
	border-color: #ffffff;

	color: var(--base);
	font-weight: 500;
	font-size: 15px;

	background: #ffffff;
}

._1K9CKCV_xlNe {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-height: 50px;
	padding: 0 20px;
}

button.Qrlufn7pN8cD {
	color: #9a9a9a;
	color: var(--exchange-calendar-clear-button-color, #9a9a9a);

	background-color: #ededed;

	background-color: var(--exchange-calendar-clear-button-bg, #ededed);
}

button.Qrlufn7pN8cD:hover {
	background-color: #e1e1e1;
	background-color: var(--exchange-calendar-clear-button-hover-bg, #e1e1e1);
}

._1K9CKCV_xlNe button {
	margin-left: 10px;
}

._28I0H_MFSijC svg {
	margin-right: 6px;
}

/* stylelint-disable */
._3O8IX0036Rt4 {
}

._2_1TalXw7_1L {
}

._3QrvNcdiIfZj {
}

._2_yUblwOPSOc {
}

._27EUX1leZzFv {
}

._2e9UxjT_Iyox {
}

._1PNWyXlJ-W7l {
}

div._1Q8kmC2pao0Z {
	border-radius: 50%;

	background: #d6e9ff;
}

div._1Q8kmC2pao0Z > div {
	color: #292929;
	font-weight: 500;
}

._2pnfJllZOxDc {
	margin-bottom: 0;
}

@media (max-width: 1024px) {
	button._28I0H_MFSijC {
		flex: 1;

		height: 48px;

		border-color: var(--base15);
		border-radius: 0;

		color: #ffffff;
		font-weight: 400;
		font-size: 14px;

		background: var(--base15);
	}

	button._28I0H_MFSijC:focus:hover {
		background: var(--base15);
	}

	._1K9CKCV_xlNe {
		position: absolute;
		bottom: 0;
		z-index: 5;

		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 48px;
		margin: 0;
		padding: 0 20px;

		background: #ededed;

		background: var(--exchange-calendar-footer-buttons, #ededed);
	}

	button._3rxBprtX2SKw {
		color: #313131;

		background: transparent;

		background: var(--exchange-calendar-footer-buttons-bg, transparent);
	}

	button._3rxBprtX2SKw:hover {
		background: transparent;
		background: var(--exchange-calendar-footer-buttons-bg-hover, transparent);
	}
}

@media screen and (max-width: 600px) {
	._2pnfJllZOxDc {
		padding-bottom: 60px;
	}
}
