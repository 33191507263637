

._2IJymAzvfAwb {
	padding: 40px;
	width: 800px;
}

._38mxR8eClE_j {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 1;
}

._38mxR8eClE_j > div {
	background-color: var(--gray05);
}

._1GFJGHKLnH8J {
	margin: 28px 0;
}

.JU1EfPL0f5Q1 {
	margin-bottom: 30px;

	font-size: 20px;
	font-weight: 700;
}

._30E17dcPFRc7 {
	display: flex;
	justify-content: space-between;
	align-items: center;

	cursor: pointer;
}

._3LdKYKt6LrkF {
	padding-bottom: 5px;

	border-bottom: 2px solid var(--gray70);
}

._30E17dcPFRc7:hover {
	background-color: var(--gray01);
}

._30E17dcPFRc7 div {
	font-weight: 700;
	font-size: 18px;
}

._30E17dcPFRc7 svg {
	transform: rotate(90deg);
}

._30E17dcPFRc7 svg path {
	fill: var(--base);
}

._30E17dcPFRc7._1YcI0lhadul9 svg {
	transform: rotate(-90deg);
}

._2uMYZMUm7V4q {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 2fr 2fr 1.5fr 1.5fr 1.5fr;
	padding: 6px 0;

	border-bottom: 1px solid var(--line-separator);

	font-size: 14px;
}

._2uMYZMUm7V4q > div {
	word-break: break-word;
}

._2uMYZMUm7V4q > div:nth-child(3),
._2uMYZMUm7V4q > div:nth-child(4),
._2uMYZMUm7V4q > div:nth-child(5),
._2uMYZMUm7V4q > div:nth-child(6) {
	text-align: center;
}

._2vH2AUEK1P83 {
	font-weight: 600;
}

.EpOyrLDDCy5z {
	font-weight: 700;
	color: var(--base-30);
}

._1XahNlqrAWZI > div:not(:first-child) {
	margin-top: 20px;
}

._1M3Do60upnVd {
	grid-column: 4/-1;

	font-weight: 600;
}
