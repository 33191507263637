._3fE5jy8nXE8w {
	height: 154px;

	overflow: hidden;

	border-radius: 4px;

	background: url('https://cdn.websky.aero/content/frontend/images/stub-order.jpg') center 60%;
	background-size: cover;
}

._2kEVyuq6XcWf {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	height: 100%;
	padding: 30px 20px;

	background: linear-gradient(90.31deg, var(--common-blue) 22.61%, rgba(50, 144, 255, 0) 84.91%);
}

._2kEVyuq6XcWf div {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

._2kEVyuq6XcWf div p {
	margin: 0;
	display: flex;
	align-items: center;

	color: #ffffff;
	font-size: 17px;
}

@media screen and (max-width: 600px) {
	._3fE5jy8nXE8w {
		height: 80vh;

		background-position: 35% center;
	}

	._2kEVyuq6XcWf {
		align-items: unset;

		background: linear-gradient(180.79deg, #3391ff 2.09%, rgba(50, 139, 243, 0) 89.97%);
	}

	._2kEVyuq6XcWf div {
		flex-direction: column;
		align-items: flex-end;
	}

	._2kEVyuq6XcWf div p {
		font-size: 20px;
		line-height: 140%;
		font-weight: 300;
	}
}
