

._2a1tbk8Zo_AG {
	position: absolute;
	z-index: 1;

	width: 100%;
	min-width: 200px;
	max-height: 540px;
	margin-top: 4px;

	padding: 8px 0;

	border-radius: 2px;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
	background-color: #ffffff;
	overflow-y: auto;
}

._2-Ujavqk74Lh {
	padding: 8px 16px;
}

button.P7T7Jo8L8Eva {
	width: 100%;
	height: 40px;

	border: none;
	border-radius: 2px;

	color: #ffffff;

	font-weight: 600;
	font-size: var(--autocomplete-dropdown-allDirections-button-fz);
	line-height: 40px;
	text-align: center;
	text-transform: uppercase;

	cursor: pointer;

	background-color: var(--common-blue);

	background-color: var(--autocomplete-dropdown-allDirections-button-bg, var(--common-blue));

	-webkit-appearance: none;

	        appearance: none;
}
