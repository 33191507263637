

.O1kP_rcGYRO5 {
	background: transparent;
}

._3e0cPlv1joAR._1aKpaJ9aiUWR {
	display: none;
}

._22MCFlwJMvd5 {
	color: #ffffff;

	background: var(--success);
}

._22MCFlwJMvd5:before {
	position: relative;
	top: -1px;
	left: 2px;

	background-image: url(data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjIwIiBoZWlnaHQ9IjIxIiB2aWV3Qm94PSIwIDAgMjAgMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTkuOTkxMDIgMi4xNjY5OUM1LjM5MTAyIDIuMTY2OTkgMS42NjYwMiA1LjkwMDMzIDEuNjY2MDIgMTAuNTAwM0MxLjY2NjAyIDE1LjEwMDMgNS4zOTEwMiAxOC44MzM3IDkuOTkxMDIgMTguODMzN0MxNC41OTkzIDE4LjgzMzcgMTguMzMyNyAxNS4xMDAzIDE4LjMzMjcgMTAuNTAwM0MxOC4zMzI3IDUuOTAwMzMgMTQuNTk5MyAyLjE2Njk5IDkuOTkxMDIgMi4xNjY5OVpNOS45OTkzNSAxNy4xNjdDNi4zMTYwMiAxNy4xNjcgMy4zMzI2OCAxNC4xODM3IDMuMzMyNjggMTAuNTAwM0MzLjMzMjY4IDYuODE2OTkgNi4zMTYwMiAzLjgzMzY2IDkuOTk5MzUgMy44MzM2NkMxMy42ODI3IDMuODMzNjYgMTYuNjY2IDYuODE2OTkgMTYuNjY2IDEwLjUwMDNDMTYuNjY2IDE0LjE4MzcgMTMuNjgyNyAxNy4xNjcgOS45OTkzNSAxNy4xNjdaTTkuMTY2MDIgNi4zMzM2NkgxMC40MTZWMTAuNzA4N0wxNC4xNjYgMTIuOTMzN0wxMy41NDEgMTMuOTU4N0w5LjE2NjAyIDExLjMzMzdWNi4zMzM2NloiIGZpbGw9IiNmZmZmZmYiLz4KPC9zdmc+Cg==);
}

._10733kMrErDS {
	max-width: 100%;
	width: 100%;
}

._2YKRc9yBdXOi {
	max-width: 100%;
	padding: 0 60px;
}
