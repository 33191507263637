

._1yYBao8LrBIa {
	position: relative;

	padding: 0 12px 16px;

	border-radius: 4px;

	color: black;

	background: #ffffff;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

._348eX1FuKIZC {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.SjrNfqUHb-qn {
	margin-top: 8px;
}

._1edpEV6MVjUs {
	position: relative;

	padding: 16px 0;

	border-bottom: 1px dashed #bababa;
}

._1edpEV6MVjUs:last-of-type {
	padding-bottom: 0;

	border-bottom: none;
}

._3OoVKBv3gMhG {
	margin-left: 32px;
}

._3OoVKBv3gMhG:not(:last-child) {
	margin-bottom: 4px;
}

._11absg4ud_BV {
	position: absolute;
	left: 0;
	top: 18px;

	height: 20px;
	width: 20px;
}

._3LR_zMbOHJEi {
	transform: rotate(180deg);
}

._2Pqm5A94dtra {
	display: flex;
	justify-content: flex-end;
	padding-top: 16px;
	margin-top: 16px;

	border-top: 1px dashed #bababa;

	color: var(--mobileStepbar-footer-color);
	font-size: 15px;
	line-height: 22px;
	font-weight: 500;
}

._1y9XMt22jpWg {
	display: flex;
	align-items: center;

	font-size: 15px;
	line-height: 22px;
	font-weight: 600;
	color: #292929;
}

._1y9XMt22jpWg.Z7y1DPCXjQvw {
	text-decoration: underline;
	color: var(--mobileStepbar-header-flights-color);
}

._16I5PLGg1W6u {
	max-width: 100%;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sfETQdPhNU_6 {
	font-size: 14px;
	line-height: 21px;
	color: var(--mobileStepbar-segment-dates-color);
}

._3-laougowhnT {
	display: flex;
	justify-content: space-between;

	font-size: 14px;
	line-height: 21px;
	color: #727272;
}

._3wDifSULsC7u {
	color: #292929;
	font-weight: 600;
}

._1NAmhCe7M7jr {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: 32px;

	font-size: 14px;
	line-height: 21px;
	color: var(--mobileStepbar-passenger-color);
	white-space: nowrap;
}

.KRq2mFoNLr_3 {
	max-width: 100%;
	overflow: hidden;

	text-overflow: ellipsis;
}

._2uxNlupgxFQF {
	margin-left: 15px;

	color: #727272;
}

._3Y_vQGkOCzbK {
	font-size: 14px;
	line-height: 21px;
	color: var(--mobileStepbar-passenger-info-color);
}

._1v8RK1WRj8f1 {
	display: flex;
	flex-direction: column;

	font-size: 14px;
	line-height: 21px;
	color: #727272;
}

._3pJY3tXdfGwX {
	color: var(--mobileStepbar-header-baggage-color);
}

.V-awgwusppha {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	font-size: 14px;
	line-height: 21px;
	color: var(--mobileStepbar-baggage-color);
}

._2SkDNvYdrN_4 {
	color: #727272;
}

.RgsR4f9B92pj {
	max-width: 75%;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.lOFs7eR5Pkl8:last-child {
	border-bottom: none;
}

._1_u-sokGvVUF {
	display: flex;
	justify-content: space-between;
	align-items: center;

	color: var(--mobileStepbar-extras-color);
	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
}

._1_u-sokGvVUF ._3oGGp62_hoO5 {
	color: #292929;
}

._3oGGp62_hoO5._1CZW7XxzOYvY {
	color: var(--mobileStepbar-extras-money-notSelected-color);
}
