div._3-jtZ_eSanDM {
	position: relative;
	z-index: 1301;

	display: flex;
	flex-direction: column;
	max-height: none;
	margin: 70px auto;

	border-radius: 8px;
	overflow: visible;
}

div._1yrG0rPM0HCK {
	border-radius: 0;
}

div._1mIWLYUY-u8M {
	border-radius: 16px;

	background: var(--gray);
}

@media screen and (max-width: 600px) {
	div._1mIWLYUY-u8M {
		border-radius: 0 !important;
	}

	._1mIWLYUY-u8M ._3-Yiq-gQTf8U {
		width: 28px;
		height: 28px;
		display: flex;
	}

	._1mIWLYUY-u8M ._3-Yiq-gQTf8U svg {
		position: relative;

		width: 28px;
		height: 28px;
	}
}

div._3XXu-6Dmtzt8 {
	padding: 20px;
}

div._3XXu-6Dmtzt8:after {
	display: none;
}

._3-Yiq-gQTf8U {
	position: absolute;

	top: -40px;
	right: -40px;
	z-index: 4;

	display: inline;

	color: #ffffff;

	cursor: pointer;
}

._1mIWLYUY-u8M ._3-Yiq-gQTf8U {
	top: 20px;
	right: 20px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--gray80);

	background: var(--gray05);
}

._1mIWLYUY-u8M ._3-Yiq-gQTf8U svg {
	transform: scale(0.8);
}

@media screen and (max-width: 600px) {
	div._3-jtZ_eSanDM {
		margin: 0 !important;
		height: auto;
		min-height: 100%;
		max-width: unset !important;
	}

	div._3XXu-6Dmtzt8 {
		padding: 0;
	}

	._3Vq7LoACrZ8H {
		position: relative !important;

		padding: 12px 40px 12px 24px !important;

		background: #ededed;
	}

	.N178cl8_RrFj {
		font-weight: 500;

		font-size: 20px;
		line-height: 23px;
		color: var(--common-gray);
	}

	._3-Yiq-gQTf8U {
		top: 11px;
		right: 10px;
		z-index: 1;

		color: var(--common-gray);
	}

	._2RthtmPyGQmr {
		top: 0;
		right: 0;

		padding: 3px 5px 1px 5px;

		color: #727272;

		background: #f0f0f0;
	}
}
