._2nzxTkhPBxxu {
	position: relative;
	z-index: 1;

	width: 100%;
}

._1AhU__mHffSl {
	margin-bottom: 12px;

	border-radius: 4px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

._1AhU__mHffSl img {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

._1QaLaAe8kBaJ {
	padding: 0 12px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	color: var(--gray80);
	font-weight: 700;
	font-size: 20px;
}

._3nGGRDSHTgBz {
	margin-bottom: 12px;

	border-radius: 4px;

	color: var(--common-gray);
	font-size: 14px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

._3WvtZ0JrFdzM ._3nGGRDSHTgBz {
	margin-bottom: 0;

	border-radius: 0;

	box-shadow: 7px 7px 12px rgba(0, 0, 0, 0.15);
}

._3WvtZ0JrFdzM ._3nGGRDSHTgBz:first-child {
	border-radius: 4px 4px 0 0;
}

._3WvtZ0JrFdzM ._3nGGRDSHTgBz:last-child {
	border-radius: 0 0 4px 4px;
}

._1I3evFZNbm4A {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 12px;

	color: var(--gray80);
}

._3l4LXmDlXtSE {
	position: relative;

	display: flex;
	flex-direction: column;

	padding-left: 60px;

	color: var(--gray80);
	font-weight: 700;

	font-size: 16px;
}

._3l4LXmDlXtSE span {
	position: absolute;
	top: 0;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;

	font-size: 14px;
}

._1TuP5PqgnHBm {
	margin-top: 5px;

	color: var(--gray70);
	font-weight: 400;
	font-size: 15px;
	line-height: 130%;
}

._2rIarFKkhb-Y {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 12px;

	border-top: 1px dashed var(--gray20);
}

._2CBRBbR5LrN_ {
	display: flex;
	align-items: baseline;

	color: var(--base);
	font-size: 14px;
}

._3eMWh9kSlgJD {
	font-weight: 700;
	font-size: 24px;
}

._30DTWQ7mn8zy {
	color: #55d590;
	font-weight: 500;
}

.wlm-k7u3BhmA ._3l4LXmDlXtSE:before,
.Ll5K2QS8FMD1 ._3l4LXmDlXtSE:before,
._1xzITLRyFYZ7 ._3l4LXmDlXtSE:before {
	position: absolute;
	left: 0;
	right: 0;

	margin-right: 15px;

	width: 44px;
	height: 44px;

	border-radius: 4px;

	content: '';
}

.wlm-k7u3BhmA ._3l4LXmDlXtSE:before {
	background: var(--seat-business);
}

.Ll5K2QS8FMD1 ._3l4LXmDlXtSE:before {
	background: var(--seat-extra);
}

._1xzITLRyFYZ7 ._3l4LXmDlXtSE:before {
	background: var(--seat-standart);
}

._99cxKMfyVZA7 {
	padding: 12px;

	border-top: 1px dashed #d1d1d1;
	border-radius: 0 0 4px 4px;

	color: var(--gray50);
	font-size: 14px;

	background: var(--warning20);
}

._2chamzrB_H1l {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	color: var(--gray80);
	font-weight: 600;
	font-size: 16px;
}

._2chamzrB_H1l svg {
	margin-right: 8px;

	color: #9a9a9a;
}

._3IBVsQ-0Nt8T {
	width: 100%;
}

._1td3I-yLqZRq {
	font-size: 15px;
	font-weight: 400;
	color: var(--gray50);
}

._1SfyHZyWsNlG {
	display: flex;
	justify-content: space-between;
}

._1SfyHZyWsNlG:not(:last-child) {
	padding-bottom: 12px;
}

._2ifGpPnCGxMu {
	display: block;
}

._2ifGpPnCGxMu .slick-track {
	display: flex;
	padding-bottom: 20px;
}

._2ifGpPnCGxMu .slick-track .slick-slide {
	display: flex;
	padding-top: 20px;
	padding-left: 20px;
	width: 75vw;
	height: 418px;
}

._35fpBfdURsqO {
	margin: 0 0 15px;
	padding: 0;
	display: flex;

	list-style-type: none;
}

.SaqhV1fNVGA_ {
	flex: 1;
	padding: 10px 20px;

	border-radius: 2px;

	text-align: center;
	color: #ffffff;

	background: #506dd2;

	cursor: pointer;
}

.SaqhV1fNVGA_:not(:last-of-type) {
	margin-right: 10px;
}

._3-2t8erqNt7- {
	position: relative;

	background: var(--common-blue);
}

._3-2t8erqNt7-:after {
	position: absolute;

	bottom: -8px;
	left: 50%;

	display: inline-block;

	width: 0;
	height: 0;

	border-style: solid;
	border-width: 9px 8px 0 8px;
	border-color: var(--common-blue) transparent transparent transparent;

	transform: translate(-50%, 0);

	content: '';
}

@media (max-width: 1024px) {
	._2nzxTkhPBxxu {
		width: 100%;
	}

	._1AhU__mHffSl {
		max-width: 370px;
		margin: 0 auto;
		margin-bottom: 12px;
	}

	._3nGGRDSHTgBz {
		width: 70%;
		height: 99%;
		margin: 0 auto;
		max-width: 370px;
		margin-bottom: 12px;
	}

	._3WvtZ0JrFdzM ._3nGGRDSHTgBz {
		box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
	}

	._3WvtZ0JrFdzM ._3nGGRDSHTgBz:first-of-type,
	._3WvtZ0JrFdzM ._3nGGRDSHTgBz:first-of-type img {
		border-radius: 4px 4px 0 0;
	}

	._3WvtZ0JrFdzM ._1I3evFZNbm4A {
		background: #f4f4f4;
	}

	._3WvtZ0JrFdzM ._3l4LXmDlXtSE {
		color: #414141;
		font-weight: 500;
		line-height: 24px;
	}

	._3WvtZ0JrFdzM ._3nGGRDSHTgBz:not(:first-of-type) ._1I3evFZNbm4A {
		border-top: 1px dashed #d9d9d9;
	}

	._35fpBfdURsqO {
		width: 70%;
		margin: 0 auto 20px;
	}
}

@media screen and (max-width: 600px) {
	._3nGGRDSHTgBz {
		width: 100%;
	}

	._35fpBfdURsqO {
		width: 100%;
		margin: 0 auto 20px;
	}
}
