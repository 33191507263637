._3utZXFFWIYs9 {
	width: 100%;
	max-width: 374px;
	margin: 0 auto 4px;

	border-radius: 4px;

	background: var(--mobileSummaryFlight--background);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

._3JKCKsc4ZPqN {
	border-radius: 0;

	background: var(--mobileSummaryFlight--background-flat);
}

.bYDqjEFJEFCS {
	display: flex;
	justify-content: center;

	padding: 12px 12px 4px;

	line-height: 150%;
	color: #ffffff;
	font-size: 17px;
	font-weight: 500;
}

._1Fi32dOH3eIB {
	line-height: normal;
	line-height: initial;
}

._3NZ61SulhurN {
	display: flex;
	align-items: center;
}

._915pr0mvqIXI {
	margin-left: 5px;
}

._2VJiAEHjgZwc {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	margin-left: 4px;

	cursor: pointer;
}

._1mpmk05O25fU {
	padding: 0 16px 12px;

	cursor: pointer;
}

.pKBn6_bIz5T- {
	padding: 18px 18px 24px;
}

._1mpmk05O25fU.waX9mQGIVQCC {
	opacity: 0.4;
}

._278GgU5PW3SA {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 6px 8px 6px 20px;

	border-top: 1px dashed rgba(255, 255, 255, 0.4);

	color: #ffffff;
	font-size: 14px;
	font-weight: 400;
}

.YpnkSmwETJJo {
	padding: 12px 20px;

	border-top: 1px dashed #cccccc;

	color: #2365b2;
	line-height: 18px;
}

._1AVOtyLd5SY3 {
	display: flex;
	align-items: center;

	font-size: 14px;
	line-height: 21px;
}

._3qG9fPP06l74 {
	font-size: 25px;
	line-height: 25px;
	color: #256cbf;
	font-weight: 900;
}

._3JKCKsc4ZPqN .selected {
	bottom: -39px;
}

._2QbAQ8Z2Y_m- ._2asB_tdjrJgK {
	font-size: 14px;
	font-weight: 400;
}

.ra_3aasJiYMO {
	margin-top: 6px;

	color: var(--gray50);
	font-weight: 400;
	font-size: 14px;
	text-align: right;
}

._27Kq9n0eMkVF {
	color: var(--error);
}

@media screen and (max-width: 600px) {
	div._1iL_eQ8w984F {
		max-height: calc(100% - 180px);
	}
}
