

div._1qvNLJLnXMJb {
	display: inline-flex;
	align-items: center;
	padding: 4px 7px 4px 4px;
	max-height: 30px;

	border-radius: 100px;

	color: #ffffff;
	font-size: 14px;
	line-height: 21px;
	font-weight: 700;
}

div._2QRliZDkbK1V {
	background: var(--active-30);
}

div._1ZjZLvXJgLWP {
	background: var(--success);
}

div._3i0ZGMJwWqby {
	background: var(--error);
}

div._10IQrmvj2BOE {
	color: var(--gray80);

	background: var(--warning);
}

._1DGr4H-N17yN {
	display: flex;
	margin-right: 5px;

	color: #ffffff;
}

._10IQrmvj2BOE ._1DGr4H-N17yN {
	color: inherit;
}
