.Epb9PdHAGIa_ {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 28px;
	flex-grow: 1;

	margin-top: 28px;
}

._2rTZu47_vvep {
	grid-template-columns: 1fr;
	justify-content: center;
}

@media (max-width: 1024px) {
	.Epb9PdHAGIa_:not(._2rTZu47_vvep) {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 600px) {
	.Epb9PdHAGIa_:not(._2rTZu47_vvep),
	._2rTZu47_vvep {
		grid-template-columns: 1fr;
		grid-gap: 17px;

		margin-top: 20px;
	}
}
