._2mpwf5uIpXVw {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 380px;

	border-radius: 8px;

	background: #ffffff;
}

._2mpwf5uIpXVw._2KTE9BPm9JqK {
	position: relative;
}

.K6lAT2h20Z-1 {
	display: flex;
	padding: 20px;
	justify-content: space-between;
	align-items: center;

	border-bottom: 1px dashed #dfdfdf;

	font-size: 21px;
	color: #1d1d1d;
	font-weight: 700;
}

.-JB1_f_-ToF0 {
	font-size: 22px;
	font-weight: 700;
	color: var(--common-blue);
}

._17U5z3GwQe10 {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;

	color: #1d1d1d;
	font-size: 14px;
	line-height: 20px;
}

._2UEKtqCxwRJq {
	font-size: 14px;
	color: #1d1d1d;
}

.P6ZzrGQSwZwB {
	position: relative;

	margin-bottom: 15px;
	padding-left: 15px;

	line-height: 160%;
}

.P6ZzrGQSwZwB:after {
	content: '\2022';

	position: absolute;
	left: 0;
	top: 0;

	color: #9a9a9a;
}

button._1koyTRYQgE4n {
	position: relative;

	width: 100%;
	height: 48px;
	margin-top: 20px;

	border: 1px solid var(--light-blue);

	border-radius: 4px;

	color: var(--common-blue);
	line-height: 48px;

	background: #ffffff;
}

button._1koyTRYQgE4n.F-nsOMYht_hW {
	position: relative;

	color: #ffffff;

	background: var(--common-blue);
}

button._1koyTRYQgE4n.F-nsOMYht_hW:after {
	position: absolute;
	left: 12px;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS40NjQxIDE3LjY3N0wyMi4wNzA1IDcuMDcwNjJMMTkuOTQ5MiA0Ljk0OTNMOS4zNDI3NiAxNS41NTU3TDQuMzkzNTggMTAuNjA2NUwyLjI3MjI2IDEyLjcyNzlMNy4yMjE0MyAxNy42NzdMNy4yMjEyNSAxNy42NzcyTDkuMzQyNTcgMTkuNzk4NUw5LjM0Mjc1IDE5Ljc5ODRMOS4zNDMzMyAxOS43OTg5TDExLjQ2NDYgMTcuNjc3NkwxMS40NjQxIDE3LjY3N1oiIGZpbGw9IiNmZmZmZmYiLz4KPC9zdmc+Cg==);
	content: '';
}

@media screen and (max-width: 600px) {
	._2mpwf5uIpXVw {
		min-width: 280px;
	}

	button._1koyTRYQgE4n {
		justify-content: space-between;
		padding-right: 34px;
		padding-left: 12px;

		border: none;
		border-radius: 2px;

		color: #ffffff;
		font-size: 17px;

		background: var(--common-orange);
	}

	button._1koyTRYQgE4n:after {
		position: absolute;
		right: 6px;
		left: auto;

		width: 24px;
		height: 24px;
		margin: 0;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5ODQgNkw4LjU4OTg0IDcuNDFMMTMuMTY5OCAxMkw4LjU4OTg0IDE2LjU5TDkuOTk5ODQgMThMMTUuOTk5OCAxMkw5Ljk5OTg0IDZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);
		content: '';
	}

	button._1koyTRYQgE4n:hover {
		background: var(--common-orange);
	}

	button._1koyTRYQgE4n.F-nsOMYht_hW {
		color: #ffffff;

		background: #5fca43;
	}

	button._1koyTRYQgE4n.F-nsOMYht_hW:after {
		right: 6px;
		left: auto;
	}

	button._1koyTRYQgE4n.F-nsOMYht_hW:hover {
		background: #5fca43;
	}

	.-JB1_f_-ToF0 {
		color: #ffffff;
	}
}
