._1oAMSjO284CQ:not(:last-of-type) {
	margin-bottom: 16px;
}

@media (max-width: 768px) {
	._1oAMSjO284CQ {
		max-width: 420px;
		margin: 0 auto;
	}
}

._34Mnhpl5fZw8 {
	display: flex;
	justify-content: space-between;
}

@media (max-width: 768px) {
	._34Mnhpl5fZw8 {
		flex-direction: column;
	}
}

._10RnuuZH6K9h {
	max-width: 280px;
}

@media (max-width: 768px) {
	._10RnuuZH6K9h {
		max-width: 100%;
	}
}

._13Mih860JRY2 {
	flex-grow: 1;
	margin: 0 12px;
}

@media (max-width: 768px) {
	._13Mih860JRY2 {
		margin: 13px 0;
	}
}

._27IuzD9BM6ov {
	margin-top: 16px;

	border-radius: 4px;

	font-weight: 500;
	font-size: 16px;

	background-color: #ffffff;
}
