

div.qhhK021pgw-d {
	border: 1px solid var(--success-icon) !important;
}

div.qhhK021pgw-d input,
div.qhhK021pgw-d:before {
	background: var(--success-bg) !important;
}

div._3DDS8dvaliT9 {
	border: 1px solid var(--warning-stroke-hover) !important;
}

div._3DDS8dvaliT9 input,
div._3DDS8dvaliT9:before {
	background: var(--warning-bg) !important;
}

div._3DDS8dvaliT9 input,
div.qhhK021pgw-d input {
	border: unset !important;
}

._2gDNRaS55Rl2 {
	width: 100%;
}

.jCyIfup3jZSw {
	color: var(--success-icon) !important;
}

._28McdHANYiuQ {
	color: var(--error-text) !important;
}
