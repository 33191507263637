._12zq3tbnIIkC {
	display: flex;
	margin-top: 15px;
	align-items: flex-end;
}

._1JeKcK3jcZWt {
	padding-bottom: 5px;
	width: 150px;
	margin-right: 15px;

	font-weight: 600;
	font-size: 18px;
	white-space: nowrap;
	text-transform: capitalize;
	text-overflow: ellipsis;
	overflow: hidden;
}

@media screen and (max-width: 600px) {
	._12zq3tbnIIkC {
		display: block;
		margin-bottom: 15px;
		padding: 25px 15px;

		border-radius: 4px;

		background: #ffffff;
	}

	div._1aQP8Vpgk7fS {
		margin-top: 10px;
	}
}
