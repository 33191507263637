.ihcxbM6QLi43 {
	display: flex;
	flex: 1;
	flex-basis: 32%;
	align-items: center;
	padding: 8px 12px;
	margin: 3px;
	max-width: 50%;

	border-radius: 4px;

	background: #ededed;
}

._2HmR5za6BbT7 {
	color: #9a9a9a;
}

._38zqoCTVpJeD {
	display: flex;
	flex-direction: column;
	margin-left: 12px;

	font-size: 15px;
	line-height: 20px;
	font-weight: 400;
	color: #777777;
}

._8Lc2KOxXcygV {
	font-size: 14px;
	line-height: 18px;
	color: #1d1d1d;
	font-weight: 500;
}

@media screen and (max-width: 769px) {
	.ihcxbM6QLi43 {
		max-width: 100%;
	}
}
