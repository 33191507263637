._3naqDhuTFLUj {
	display: flex;
	align-items: center;
	width: 100%;
}

._3naqDhuTFLUj.iEOOpcFyZrdh {
	color: var(--gray70);
	font-size: 15px;
	font-weight: 500;
}

.Rf3myngTh9b4 {
	color: #b3b3b3;
}

._3naqDhuTFLUj.iEOOpcFyZrdh .Rf3myngTh9b4 {
	color: var(--success);
}

._3naqDhuTFLUj._3R_AtbojPKqS ._2LDhj1AnsnVr {
	font-weight: 500;
}

._3naqDhuTFLUj._3R_AtbojPKqS ._204fxV9sqOLE,
._3naqDhuTFLUj._3R_AtbojPKqS ._3JW3suKtjhEP {
	margin-left: 12px;
}

._3naqDhuTFLUj._3R_AtbojPKqS ._3JW3suKtjhEP .c2SC5JemX4Ag,
._3naqDhuTFLUj._3R_AtbojPKqS ._2LDhj1AnsnVr .c2SC5JemX4Ag {
	display: inline-flex;
}

._2d3KSCkXhcqT {
	margin-left: auto;
}

button._12sBvzpGCC1e,
button._12sBvzpGCC1e:hover {
	display: inline-flex;
	align-items: center;

	border: 1px solid var(--brand1-3);

	color: var(--base);

	background: var(--brand1-5);
}

button._12sBvzpGCC1e svg {
	margin-left: 8px;
}

@media (max-width: 768px) {
	._3naqDhuTFLUj._3R_AtbojPKqS span._3JW3suKtjhEP,
	._3naqDhuTFLUj._3R_AtbojPKqS span._204fxV9sqOLE {
		display: block;
		margin: 0;
		padding-top: 6px;

		font-weight: 400;
	}
}
