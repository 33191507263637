

div._3rPbzsqfEXPe {
	padding: 28px;

	border: none;
	border-radius: 8px;

	box-shadow: var(--shdw-terminal);
}

div._21kvU9lJQNMF,
div._2n3A3RL0OHGA {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3px;

	color: var(--text-light);
}

div._21kvU9lJQNMF {
	transform: rotate(180deg);
}
