

._1d4hFBVxSk7a {
	display: flex;
	width: 100%;
	max-width: 1100px;
	margin-bottom: 8px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	cursor: pointer;
	background: #ffffff;
}

._3dT_xb_mq-_n {
	cursor: default;
}

._3c7IM2MVe8Q- {
	display: flex;
	flex-direction: column;
	flex: 0 1 899px;
}

.oudIKwBIr44J {
	flex: 1;
}

._6TyxM-9SjyYz {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 31px;
	padding: 8px 4px;

	border-top: 1px solid var(--gray10);
}

._1d4hFBVxSk7a._2POx_amb4trT ._3c7IM2MVe8Q- {
	opacity: 0.4;
}
