

._1lcBREBS_K3H {
	padding: 40px 48px 32px;
}

.qH39Ghof-w1k {
	margin: 0;

	line-height: 40px;
	font-size: 22px;
}

.m4keOybKFF-_ {
	margin: 20px 0 0 0;

	font-size: 18px;
	line-height: 150%;
	color: var(--common-gray);
}

._25v15Y-yfIru {
	margin-top: 40px;
	display: flex;
	justify-content: flex-end;
}

button.ZNM5xN1KxQQ2 {
	padding: 0 25px;
}
