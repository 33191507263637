._2NpxsbuHtafa {
	position: relative;

	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	justify-content: space-between;
	width: 360px;
	height: 490px;

	border-radius: 4px;

	line-height: 23px;

	background: #ffffff;
}

._24rBKYEBkY3X {
	border-radius: 8px;

	background-color: #ffffff;
}

._2NpxsbuHtafa:not(:last-of-type) {
	margin-right: 14px;
}

._2YXnoTKplXis {
	position: relative;

	display: flex;
	align-items: center;
	padding: 20px;
	height: 170px;

	border-top-right-radius: 4px;
	border-top-left-radius: 4px;

	color: #ffffff;

	font-style: normal;
	font-weight: 500;
	font-size: 41px;
	line-height: 48px;

	background: linear-gradient(180deg, rgba(50, 144, 255, 0.8) 0%, var(--common-blue) 100%);
}

._2uf_WlfPxSAh {
	display: inline-block;
	margin-bottom: 10px;
	padding: 5px 42px 5px 10px;

	border-radius: 4px;

	background: #ffffff;
}

._12wpNlHik5fK {
	width: 70px;
	height: 26px;
}

._1octh6EnVPFm {
	position: absolute;

	top: 11%;
	left: 48%;

	height: 198px;
}

._9hID0slF9VHF {
	position: absolute;

	top: 20%;
	left: 65%;

	height: 198px;
}

._19HxdE47V5FS {
	margin-bottom: 25px;

	font-size: 20px;
	font-weight: 600;
	line-height: 23px;
}

.taSK10EKawfn {
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 23px;
}

._3WsULcn6U9DI {
	position: relative;
}

._3O7usaycbubD {
	margin-left: 5px;

	font-size: 22px;
	font-weight: 500;

	color: var(--common-blue);
}

div.oIyVHYugusK- {
	top: 50%;

	max-width: 750px;
	width: 100%;

	transform: translate(0, calc(-50% - 52px));
}

.eaJtmXL1koDT {
	display: flex;
	align-items: center;
}

.TMz0vXcxpWec {
	color: var(--common-blue);
	font-size: 13px;
	line-height: 20px;
	font-weight: 400;
}

._2hK2rp54VC4q {
	display: flex;
	align-items: center;
	padding: 0 20px;
}

._38At1FZeCDt7 {
	padding: 0 20px;

	border-left: 1px solid var(--light-gray);

	font-weight: 600;
}

._33nD75XnAuuS {
	padding: 6px;

	line-height: 20px;
}

._33nD75XnAuuS svg {
	width: 17px;
	height: 17px;
}

._33nD75XnAuuS path:nth-child(2) {
	fill: var(--common-blue);
}

._3D28SuRFDMzW {
	display: flex;
	align-items: center;
	height: 36px;
}

._2V3-ViT2p00a {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	border-radius: 0 0 4px 4px;
}

._29luGH8Deep9 {
	margin: 15px;

	color: var(--common-blue);
	font-size: 20px;
	font-weight: 600;
}

._1D9uEvHAH2n0 {
	max-width: 680px;
}

._3B8AC2fobtLT {
	display: flex;
	align-items: center;
}

._2Hqwbpwp57g1 {
	display: inline-block;

	width: 24px;
	height: 24px;

	margin-left: 7px;

	cursor: pointer;
}

.Wl2B6VauiTrk {

	margin-right: 2px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEwQzAgNC40NyA0LjQ3IDAgMTAgMEMxNS41MyAwIDIwIDQuNDcgMjAgMTBDMjAgMTUuNTMgMTUuNTMgMjAgMTAgMjBDNC40NyAyMCAwIDE1LjUzIDAgMTBaTTEzLjU5IDE1TDE1IDEzLjU5TDExLjQxIDEwTDE1IDYuNDFMMTMuNTkgNUwxMCA4LjU5TDYuNDEgNUw1IDYuNDFMOC41OSAxMEw1IDEzLjU5TDYuNDEgMTVMMTAgMTEuNDFMMTMuNTkgMTVaIiBmaWxsPSIjOUE5QTlBIi8+Cjwvc3ZnPgo=) center no-repeat;
}

._30Rs9tRfV01V {
	position: relative;
	top: 1px;
	right: 1px;

	color: #9a9a9a;

	transform: rotate(-90deg) scale(0.85);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIGZpbGw9IiM5YTlhOWEiLz4KPHBhdGggZD0iTTE2LjU5IDguNTkwMDlMMTIgMTMuMTcwMUw3LjQxIDguNTkwMDlMNiAxMC4wMDAxTDEyIDE2LjAwMDFMMTggMTAuMDAwMUwxNi41OSA4LjU5MDA5WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
}

._1KfZqvcTeZD0 {
	color: var(--common-blue);
}

button._3nwenNcUxCYl {
	max-width: 185px;
	margin-right: 10px;
	padding: 15px 60px;

	font-weight: 400;
	font-size: 14px;

	line-height: 16px;
}

button.az0Eu9-OybZB {
	min-width: 120px;
	height: 44px;

	border-radius: 24px;

	line-height: 44px;
}

button.baMJ9HN3eiZk {
	color: #9a9a9a;

	background: var(--half-transparent-gray);
}

button.baMJ9HN3eiZk:hover {
	color: #9a9a9a;

	background: var(--half-transparent-gray);
}

._1_LBiETKUvmr {
	display: flex;
	justify-content: flex-end;
	height: 20%;
	padding: 32px 40px;

	border-radius: 5px;

	background: #ffffff;
}

div._17H1mAd4iqg9 {
	position: absolute;
	z-index: 2;

	height: 45px;

	border-bottom: none;

	background: transparent;
}

div._17H1mAd4iqg9 > div:last-child {
	color: #ffffff;
}

div._1MVTYYkdNPsV {
	margin-top: 0;
}

div._2U8Y4ii1HpHQ {
	max-width: 540px;

	background-color: transparent;
}

@media screen and (max-width: 600px) {
	div._17H1mAd4iqg9 > div:first-of-type {
		display: none;
	}

	div._1wlHPErG7ghh {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div._2DK56MNzxhsz {
		height: auto;
	}
}
