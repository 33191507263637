._3BJxfOi6r7mh {
	margin-top: 12px;
	padding: 17px 12px 17px 17px;

	border-radius: 4px;

	list-style-type: none;
	background: #f4f4f4;

	cursor: pointer;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

._3418JDjyT8Go {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

._1EcBY-9XUkGR {
	display: flex;
	align-items: center;

	color: var(--common-gray);
	font-size: 14px;
}

._1EcBY-9XUkGR > span {
	display: flex;
}

._1EcBY-9XUkGR svg {
	margin-left: 12px;
}

._3OBjU5WiGmpZ {
	margin: 20px 0 0;

	color: var(--common-gray);
	font-size: 15px;
}

@media screen and (max-width: 600px) {
	._3418JDjyT8Go {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	._1EcBY-9XUkGR {
		width: 100%;
		justify-content: space-between;
	}
}
