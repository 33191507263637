.YqfNr7id1ZGz {
	position: relative;

	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	justify-content: flex-start;
	min-width: 256px;
	max-width: 380px;
	min-height: 392px;
	padding: 22px 16px 16px 16px;
	overflow: visible;

	border-radius: 4px;
}

._3vJAGnQpD91B {
	opacity: 0.6;
	cursor: not-allowed;
}

._1iMe1Vk9KtMH {
	background: var(--results-faregroup-economy);
}

._2taXDzWA4IBW {
	background: var(--results-faregroup-business);
}

._3HHUzZdSdshK {
	min-height: 534px;

	border-radius: 8px;

	background-color: #ffffff;
}

._2Kwtx1Ytdj27 {
	color: var(--results-faregroup-economy-title);
	font-size: 21px;
	line-height: 28px;
	font-weight: 600;
	white-space: nowrap;
}

._3HHUzZdSdshK ._2Kwtx1Ytdj27 {
	font-size: 19px;
	color: var(--gray80);
}

._1lOnTyvSevUi {
	position: absolute;

	display: inline-flex;
	margin-left: 5px;

	cursor: pointer;
}

._3HHUzZdSdshK ._1lOnTyvSevUi {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	._3HHUzZdSdshK ._1lOnTyvSevUi svg {
		color: var(--gray80);
	}
}

._32OVKQ0cnFgP {
	margin: 12px 0 16px;
	min-height: 60px;

	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	grid-column-gap: 4px;

	column-gap: 4px;
}

._3HHUzZdSdshK ._32OVKQ0cnFgP {
	margin-bottom: 0;
	margin-top: 7px;
}

._7x_WVok0s8d2 {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	cursor: help;

	background-color: rgba(255, 255, 255, 0.3);
}

._3HHUzZdSdshK ._7x_WVok0s8d2 {
	background-color: var(--base);
}

._3HHUzZdSdshK._2taXDzWA4IBW ._7x_WVok0s8d2 {
	background: #61579f;
}

.YqfNr7id1ZGz .chevron {
	position: relative;
	top: 8px;
	left: -4px;

	color: var(--base);

	transform: scale(0.9);
}

.qu2ObpwUKTow {
	opacity: 0.6;
}

._3HHUzZdSdshK .qu2ObpwUKTow {
	background: var(--gray30);
	opacity: 1;
}

._1_JPEmwnb8_w {
	opacity: 1;
}

._3HHUzZdSdshK ._1_JPEmwnb8_w {
	opacity: 0.6;
}

.A4CL0HVV8DEL {
	margin-bottom: 16px;

	color: #ffffff;

	transform: scale(0.9);
}

._3HHUzZdSdshK .A4CL0HVV8DEL {
	color: var(--bright-blue);
}

.ydnHdODmdQ0X {
	position: absolute;
	bottom: 0;

	display: flex;
	align-items: center;
	margin-bottom: 5px;

	color: #ffffff;
	font-weight: 600;
	font-size: 11px;
	line-height: 16px;
}

.ydnHdODmdQ0X svg {
	transform: scale(0.9);
}

._3HHUzZdSdshK svg {
	color: #ffffff;
}

._3HHUzZdSdshK .ydnHdODmdQ0X {
	color: #ffffff;
}

.frmJkI06LfGS {
	margin: 0 -20px;
	padding: 16px 20px 0;

	border-top: 1px dashed rgba(255, 255, 255, 0.5);
}

._3HHUzZdSdshK .frmJkI06LfGS {
	padding-top: 0;

	border-top: none;
}

._33radYv9wMTh {
	display: flex;
	justify-content: space-between;
	align-items: center;

	line-height: 20px;

	list-style-type: none;
}

._3HHUzZdSdshK ._33radYv9wMTh {
	margin: 0;
	padding: 13px 0;

	color: var(--gray70);
}

._33radYv9wMTh:not(:first-of-type) {
	margin-top: 3px;
}

._3HHUzZdSdshK ._33radYv9wMTh:not(:first-of-type) {
	margin: 0;
}

._3HHUzZdSdshK ._33radYv9wMTh:not(:last-of-type) {
	border-bottom: 1px solid #e4e4e4;
}

._3FF2Zz5lfASo {
	margin-top: 12px;
}

div._3FF2Zz5lfASo svg {
	color: var(--gray50);
}

._3HHUzZdSdshK .LMs4T4ZvbJn- svg {
	color: var(--gray80);
}

._2deHuU6VamIa {
	opacity: 1;
}

._3HHUzZdSdshK ._2deHuU6VamIa .LMs4T4ZvbJn- svg {
	color: #5fca43;
}

._2kgsi5xfQLrp {
	opacity: 0.6;
}

._2A76DAIqJ16_ {
	color: var(--results-faregroup-option-color);
	font-size: 13px;
	white-space: nowrap;
}

._3HHUzZdSdshK ._2A76DAIqJ16_ {
	font-size: 14px;
	color: var(--gray70);
}

.LMs4T4ZvbJn- {
	display: flex;
	align-items: center;

	color: var(--results-faregroup-option-color);
	font-weight: 500;
	font-size: 13px;
	text-align: right;

	cursor: help;
}

._3HHUzZdSdshK .LMs4T4ZvbJn- {
	color: var(--common-blue);
}

.I6wdEIdXLhln {
	position: relative;
	top: -5px;
}

.rv9L8mK-Ubbk {
	padding: 8px 12px;

	border-radius: 2px;

	color: var(--gray70);
	font-size: 14px;
	line-height: 18px;

	cursor: pointer;

	background: var(--success90);
}

._3zGXRd4_sMoQ {
	display: block;
	margin-top: 5px;

	color: var(--base);
	font-weight: 600;
	line-height: 14px;
	text-decoration: none;
}

button.UFve43nVU50A {
	position: relative;

	display: flex;
	justify-content: space-between;

	margin-top: auto;
	padding: 0 12px;

	font-size: 17px;
	line-height: 25px;

	background-color: var(--results-faregroup-button-economy);
	box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

button.UFve43nVU50A:disabled {
	border: 1px solid rgba(255, 255, 255, 0.5);

	background: var(--gray30);
	box-shadow: none;
}

button:disabled .notIncluded {
	color: #ffffff;
}

button.UFve43nVU50A:hover {
	background-color: var(--results-faregroup-button-economy-hover);
}

._2taXDzWA4IBW button.UFve43nVU50A {
	background-color: var(--results-faregroup-button-business);
}

._2taXDzWA4IBW button.UFve43nVU50A:hover {
	background-color: var(--results-faregroup-button-business-hover);
}

._3HHUzZdSdshK._2taXDzWA4IBW button.UFve43nVU50A {
	background-color: var(--results-faregroup-button-business);
}

._3HHUzZdSdshK._2taXDzWA4IBW button.UFve43nVU50A:disabled {
	background-color: var(--gray30);
}

._3HHUzZdSdshK._2taXDzWA4IBW button.UFve43nVU50A:hover {
	background-color: var(--results-faregroup-button-business-hover);
}

._1_rw6VZVMMVQ {
	padding-right: 19px;

	font-size: 27px;
	line-height: 27px;
}

._1_rw6VZVMMVQ:not(._37YsmNHc6wkC):after {
	position: absolute;

	top: 50%;

	width: 28px;

	height: 28px;

	transform: translateY(-52%);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIGQ9Ik0yLjc3NDA1IDAuNjAwMDk4TDAuODAwMDQ5IDIuNTc0MUw3LjIxMjA1IDkuMDAwMUwwLjgwMDA0OSAxNS40MjYxTDIuNzc0MDUgMTcuNDAwMUwxMS4xNzQgOS4wMDAxTDIuNzc0MDUgMC42MDAwOThaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) no-repeat center;
	content: '';
}

._2tbuW8DLxolu .sYEsxQcAbRAb {
	padding-left: 6px;

	font-weight: 500;
	font-size: 17px;
}

div._2WmzOusHy1aN {
	height: 55px;
	padding-left: 0;

	font-size: 20px;
	font-weight: 500;
	line-height: 150%;
}

._2WmzOusHy1aN > div > div {
	display: flex !important;
	align-items: center;
}

div._1r_IAYLG4PGS {
	margin-top: 55px;
}

._2y7f4lT4mCwV {
	display: flex;
	justify-content: flex-end;
	margin-top: 12px;

	color: var(--gray50);
	font-size: 14px;
	line-height: 1.3;
}

@media only screen and (max-width: 360px) {
	.YqfNr7id1ZGz {
		width: 278px;
	}

	._1-1L7ZF3fu-C {
		width: 80px;
	}
}
