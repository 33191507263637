div._2qSfJhME-Orc {
	overflow: auto;
}

div.L-Z65FcxMPDs {
	padding-top: 56px;

	background: #262626 !important;
	overflow: auto !important;
}

@media screen and (max-width: 600px) {
	div._2qSfJhME-Orc {
		overflow: auto;
	}

	div.eLk2omVf1Nvu {
		display: inline;
		display: initial;
	}

	div.L-Z65FcxMPDs {
		overflow: hidden;
	}
}
