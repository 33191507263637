

._3QqDrLRje5ve {
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
}

._2hN89SB2Hlxa {
	font-size: 14px;
	color: var(--text-light);
}

._1QODj4ybohG2 {
	display: flex;
	grid-gap: 5px;
	gap: 5px;
	justify-content: space-between;
}

._28eujF1vRFDY {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 38px;
	padding: 10px;

	cursor: pointer;
	background: var(--bg-elements);
}

._1vveG_SM718- {
	color: white;

	background: var(--brand-1-0);
}
