._3pdO5UE5k3oy {
	display: flex;
	align-items: center;
	height: 80px;
}

.dVybQP9RErDF {
	display: flex;
	align-items: center;

	font-weight: 500;
}

._129tM2lRCKNq {
	display: flex;
	height: 100%;
	margin-right: 19px;

	font-size: 18px;
}

a._2Z3Dz7Lc-ZCc {
	text-decoration: none;
	color: var(--error);
}

.o4IjcYbSTE66 {
	margin-left: 19px;
	padding-left: 19px;

	border-left: 1px solid var(--gray10);

	color: var(--base);
}

._3nS1jEJzvWJr {
	font-size: 24px;
	font-weight: 700;
	color: var(--gray80);
}

button._1XCt5GOOp8cF,
button._1XCt5GOOp8cF:hover {
	background: var(--brand-3-0);
}

.nA-CM9pnPgjD {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;
	margin-left: 28px;

	border-radius: 50%;

	cursor: pointer;
	background: var(--gray05);
}

.nA-CM9pnPgjD svg {
	width: 16px;
	height: 16px;

	color: var(--gray50);
}

@media (max-width: 1024px) {
	button._1XCt5GOOp8cF {
		margin-right: 50px;
	}
}
