div._2933hIj-hgtF {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 0;

	width: 100%;
	padding-left: 16px;
	padding-right: 30px;

	color: rgba(255, 255, 255, 0.6);
	line-height: var(--input-height);
	text-overflow: ellipsis;
	white-space: nowrap;

	cursor: text;

	pointer-events: none;

	overflow: hidden;
}

._2H78qAt7or6c {
	border-radius: 2px;

	background-color: #ffffff;
}

._-9DkZ7FOoRNy {
	padding-right: 42px;
}

@media screen and (max-width: 600px) {
	._2933hIj-hgtF {
		padding-left: 44px;
	}

	div.I6jjuIUJjfMe {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		justify-content: center;
		padding: 0;

		line-height: normal;
	}

	.I6jjuIUJjfMe .h9jh3ekGoP2G {
		margin: 0;

		font-size: 26px;
		font-weight: 600;

		opacity: 1;
	}

	.I6jjuIUJjfMe ._3Nki8s0bNDgw {
		max-width: 90%;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
		font-size: 12px;
		font-weight: 400;

		opacity: 0.7;
	}

	._3XBMvLcdOk_5.I6jjuIUJjfMe .h9jh3ekGoP2G {
		font-size: 16px;
	}
}

._2aVq-9y5wj8n {
	font-size: 15px;
	font-weight: 500;
	color: var(--light-gray);
}

.cy31Mc4RB4Oc {
	position: absolute;
	right: 18px;
	top: 50%;

	display: flex;
	margin-top: -10px;
	width: 20px;
	height: 20px;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJcSURBVHgBrVRdq2FhFF627RyzxcShOeUjQxlx4STJpXLnZ/glfoLiQrhyJ7kWpdy50wxmGDTKoZAZoTG+m7XeOJ05n47mqdfeve9ej7We91lLBE8gGAzyMplMv1qtrpbLpWK32wnb7RZwTTebzR+RSDQIh8O9p2JFDzdCodAHDLxZr9cSJAIkhftPIqbngfh7IpHoPUsYiUTsGPCRAtRqNRgMBjCZTCCXy9n5cDiEfr8PhUIBBoMBI0Y0k8lk8xFhLBaz4AcWenc4HGC1WuElFItFyGazsFgsAKv5kU6nv9G+mH7i8bgOH/aLiwvw+Xyg1+vhNRiNRrDZbFAqlah8JSYxrdVqvzk6lEgkn3ieB7vdDkqlEk6FVqsFv9/PSsdMb7xeLy/OZDK6/X6vFwQBXC4XvBVmsxmq1SqMRiOxVCpdcVj/NWX3mmYvwel0sttHi11xqJtA2ikUCjgXbrebLoZI3/OoH2M6WuMckMUoQ8Q7XiwWw//AwZPAoX4L0pBSPhetVuvYSVPScEYazmYzOBedTueuHTms/RdlSI4/F9gYjBDXgEMj36KOG0p5Pp/DW5FKpVh/o5cXlUqlx6GZN5jhF8pyMpnciXsKGo0GRKNRpj8uNiBY66GPBkjUoffxeHxS+ThhIBAIsCQQzXa7zcbYP+OrXq/TtLGQlS4vL4HakXC0FpWWz+chl8tBuVyGw3edbrf79cjxaMCiBXQ4OC1oeIECSApygUqlYn9Ce7TwnKT6jD08vB8veq6kXq+n4zjuGl8F6iaNRkPkC7IZXsBPj8dzizNx+zDuL+21NUIz0btFAAAAAElFTkSuQmCC) center no-repeat;

	animation: _23fJaRyZe0ZG 0.9s infinite linear;

	pointer-events: none;
}

._3Nki8s0bNDgw {
	font-weight: 500;
	font-size: 15px;
	text-transform: capitalize;
	color: #ffffff;
}

.h9jh3ekGoP2G {
	margin-left: 8px;

	font-size: 15px;
	text-transform: capitalize;
	color: #ffffff;

	opacity: 0.6;
}

@keyframes _23fJaRyZe0ZG {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
