

div._2kDy3MbOfTv_ {
	z-index: 1600;

	opacity: 1;
}

div._3fpdgQJYuIlj {
	position: relative;

	max-width: 400px;

	margin-bottom: 0;
	padding: 0;

	border-radius: 4px;

	box-shadow: 0 2px 24px rgba(0, 0, 0, 0.15);
	background: var(--base);
}

div._3fpdgQJYuIlj._2qZzTFvajVxA {
	background: var(--selected2);
}

._3fpdgQJYuIlj:after {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;

	width: 0;
	height: 0;
	margin: auto;

	border: 5px solid transparent;
	border-top: 5px solid var(--base);

	transform: translateY(100%);
	content: '';
}

._3fpdgQJYuIlj._2qZzTFvajVxA:after {
	border-top-color: var(--selected2);
}

._3FW-D1-HD73p:after {
	top: -10px;
	bottom: auto;

	transform: rotate(180deg);
}

.ZG0Tgh-tPE3x._3fpdgQJYuIlj:after {
	border-top-color: var(--base15);
}

._12RCoAsIlOY1 {
	display: flex;
	padding: 8px;

	color: #ffffff;
	font-weight: 700;
	font-size: 27px;
}

._12RCoAsIlOY1 > div {
	height: 100%;

	line-height: 33px;
}

._2M6II37sdHIH {
	display: flex;
	flex-direction: column;
	align-items: center;

	font-weight: 500;
	font-size: 14px;
	line-height: normal;
}

._12RCoAsIlOY1 > div:not(:last-child) {
	padding-right: 10px;
	margin-right: 10px;

	border-right: 1px dashed rgba(255, 255, 255, 0.5);
}

._2LuYwXwwx-Mb {
	padding: 8px;

	border-radius: 0 0 4px 4px;

	color: #ffffff;
	font-weight: 500;
	font-size: 14px;
	line-height: 120%;

	background: var(--base15);
}

._37DJpqR87GvE {
	position: relative;
}

._2LuYwXwwx-Mb ul {
	font-weight: 400;
}
